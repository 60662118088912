import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {Tooltip} from "@mui/material";

export default function EditFlagCellInput(params) {
  const {id, api, value, field} = params;

  const handleDeleteIconClick = function (event) {
    // set "real" value
    api.setEditCellValue({id, field, value: null}, event)

    api.commitCellChange(
      params,
      event
    )

    // Back to view mode after we are done.
    api.setCellMode(
      params.id,
      params.field,
      'view'
    )
  }

  return (
    <>
      {!value ? (
        /*
        Ability to add flags here.
        */
        <></>
      ) : (
        <Tooltip title={window.gettext('Delete flag')}>
          <DeleteForeverIcon onClick={handleDeleteIconClick}/>
        </Tooltip>
      )}

    </>
  )
}