import {createContext, useContext, useEffect, useState} from "react";
import IssueService from "../services/IssueService";
import {useParams} from "react-router-dom";
import IssueSpentTimeService from "../services/IssueSpentTimeService";


export const IssueSpentTimeContext = createContext({
  spentTimeEntries: undefined,
  refreshEntries: undefined,
})


export function useIssueSpentTime() {
  return useContext(IssueSpentTimeContext);
}

export default function IssueSpentTimeProvider(props) {
  let [entries, setEntries] = useState(null)
  const issue_id = useParams().issue_id

  const refreshEntries = () => {
    IssueSpentTimeService.list(
      issue_id
    ).then(setEntries)
  }

  useEffect(() => {
    refreshEntries()
  }, []);

  return (
    <IssueSpentTimeContext.Provider
      value={
        {
          'spentTimeEntries': entries,
          'refreshSpentTimeEntries': () => {
            refreshEntries()
          }
        }
      }
    >
      {props.children}
    </IssueSpentTimeContext.Provider>
  )
}
