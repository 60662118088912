import EditValveOnValve from "./EditValve/EditValveOnValve";
import EditValveOnRadiator from "./EditValve/EditValveOnRadiator";

export default function EditValve(
  valveTypes,
  radiatorValveTypes,
) {
  return (params) => {
    // Valve
    if (params.row.node.type === 'valve') {
      return EditValveOnValve(valveTypes)(params)
    }

    // Radiator
    if (params.row.node.type === 'radiator') {
      return EditValveOnRadiator(radiatorValveTypes)(params)
    }
  }
}