import CSVImporter from "../../../../components/import/csv/CSVImporter";

import { LayoutView } from "../../../../layout";

export default function ImportCSVNippleView() {
  return (
    <LayoutView header={window.gettext("Import Nipples from CSV document")}>
      <CSVImporter
        importerApiEndpoint="/api/import/csv/nipple/" />
    </LayoutView>
  )
}