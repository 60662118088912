import BaseCleaner from "./BaseCleaner";
import {NODE_TYPE_VALVE} from "../DataGetter";

export default class ValveLoopCleaner extends BaseCleaner {
  nodeTypes = [NODE_TYPE_VALVE]
  omitFields = ['type_id', 'valve_loop']

  matchParams = (params) => {
    // Must be 'valve_type' as field
    if (params.field !== 'valve_type') {
      return false
    }

    // Must have property: 'field_override' and
    // it needs to be set to: 'valve_loop'
    if (!params.hasOwnProperty('field_override') || params.field_override !== 'valve_loop') {
      return false
    }

    return true
  }

  matchData = (data) => {
    // Match data only if we have the 'loop' setting
    return data.hasOwnProperty('loop') === true
  }
}