import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import getColumnWidth from "../GetColumnWidth";

export default function IdColumnDefinition() {
  return {
    field: 'id',
    width: getColumnWidth('id'),
    hide: true,
    getRowClassName: getRowClassName,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'node.id'
      )
    }),
    headerName: window.gettext('Id'),
  }
}
