import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import ConfirmableButton from "../../../../../../layout/ConfirmableButton";
import SelfCheckTaskItemSpecService from "../../../../../../services/SelfCheckTaskItemSpecService";
import SortableButtons from "../../../../../../utils/SortableButtons";

import {
  ChipEnableCommenting,
  ChipTaskItemSpecProjectRow,
  ChipTaskItemSpecType,
} from "../../chips";
import { IconDelete, IconEdit, IconTaskItem } from "../../icons";
import { ModalCreateTaskItemSpec } from "../../modals";

export function TaskItemsTypeItemGroup({
  jobSpec,
  onDeleteTaskItemSpecIconClick,
  project,
  postUpdate,
  sortSuccessCallback,
  taskSpec,
}) {
  return (
    <Collapse in={true} timeout="auto" unmountOnExit>
      {/* Task item specs for this task spec */}
      <List
        component="div"
        sx={{
          pl: 4,
        }}
      >
        {taskSpec.item_specs.map((taskItemSpec, index) => (
          <ListItem
            key={taskItemSpec.id}
            secondaryAction={
              <>
                {/* Sortable buttons */}
                <SortableButtons
                  index={index}
                  items={taskSpec.item_specs}
                  currentItem={taskItemSpec}
                  service={SelfCheckTaskItemSpecService}
                  serviceParams={[project.id, jobSpec.id, taskSpec.id]}
                  successCallback={sortSuccessCallback}
                />

                {/* Edit Task Item Spec */}
                <ModalCreateTaskItemSpec
                  project={project}
                  jobSpec={jobSpec}
                  taskSpec={taskSpec}
                  taskItemSpecId={taskItemSpec.id}
                  postUpdate={postUpdate}
                >
                  <IconButton edge="end">
                    <Tooltip
                      title={window.gettext("Edit task item specification")}
                    >
                      <IconEdit />
                    </Tooltip>
                  </IconButton>
                </ModalCreateTaskItemSpec>

                {/* Delete task item spec*/}
                <ConfirmableButton>
                  <IconButton
                    edge="end"
                    onClick={() => {
                      onDeleteTaskItemSpecIconClick(taskItemSpec);
                    }}
                  >
                    <IconDelete color="error" />
                  </IconButton>
                </ConfirmableButton>
              </>
            }
          >
            {/* Icon */}
            <ListItemIcon>
              <IconTaskItem />
            </ListItemIcon>

            {/* Info */}
            <ListItemText>
              <Stack
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {/* Name of task item spec */}
                <Typography component="span">{taskItemSpec.name}</Typography>

                {/* Connected to project row */}
                <ChipTaskItemSpecProjectRow taskItemSpec={taskItemSpec} />

                {/* Task item type */}
                <ChipTaskItemSpecType taskItemSpec={taskItemSpec} />

                {/* Enable commenting */}
                <ChipEnableCommenting taskItemSpec={taskItemSpec} />
              </Stack>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
}
