import { Card, Divider, Modal, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import CustomerForm from "./CustomerForm";
import CustomerService from "../services/CustomerService";
import { removeKeysWithValue } from "../services/BaseService";

export default function CreateCustomerModal({open, onClose, onCreate, initValues}) {
  const {enqueueSnackbar} = useSnackbar();

  function handleSubmit(values) {
    removeKeysWithValue(values, ["", null]);
    
    console.log("Trying to create customer with values:", values)

    CustomerService.create(values)
    .then(
      (response) => {
        enqueueSnackbar(
          window.gettext('Customer created'),
          {
            'variant': 'success'
          }
        )
        console.log("Successfully created customer")

        // run onCreate callback and pass new customer.
        if(onCreate) {
          onCreate(response)
        }
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext('Error while trying to create customer'),
          {
            'variant': 'error'
          }
          )
        
        if (rejection.hasOwnProperty('response') && rejection.response.hasOwnProperty('data')) {
          console.error("Failed to created customer\n", rejection.response.data)
        } else {
          console.error("Failed to created customer")
        }
      }
    )
  }

  return (
    <Modal
        open={open}
        onClose={onClose}
        sx={{display:"flex", justifyContent:"center", alignItems:"center"}}
      >
        <Card sx={{width:"100%", maxWidth:600, p:2}}>
          <Typography variant="h2" sx={{userSelect:"none", mb:1}}>
            {window.gettext("Create customer")}
          </Typography>
          <Divider sx={{mb:2}} />
          <CustomerForm onSubmit={handleSubmit} initValues={initValues} />
        </Card>
      </Modal>
  )
}
