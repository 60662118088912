import EntityText from "../../entity/EntityText";

export function LocationLabel({entity, locationTree}) {
  return (
    <>
      {
        (['address_coded_address', 'address_house_number', 'address_entrance']).includes(entity.type) && (
          <>
            {
              (locationTree || []).map((e, i) => {
                if (e.type === 'system') {
                  return
                }

                return (
                  <span key={'location_' + String(i)}>
                    <EntityText entity={e}/>
                    <span>&nbsp;</span>
                  </span>
                )
              })
            }
          </>
        )
      }
      <EntityText entity={entity}/>
    </>
  )
}