import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InstanceManipulator from "../../../../../../components/InstanceManipulator";
import LoadingIndicator from "../../../../../../components/layout/LoadingIndicator";
import ProjectService from "../../../../../../components/services/ProjectService";
import SystemService from "../../../../../../components/services/SystemService";
import SystemForm from "../../../../../../components/system/SystemForm";
import SystemReadOnlyCheckbox from "../../../../../../components/system/SystemReadOnlyCheckbox";

import { LayoutCard, LayoutView } from "../../../../../../layout";

export default function SystemEditView() {
  const navigate = useNavigate();
  const { project_id, system_id } = useParams();

  const [system, setSystem] = useState(null);
  const [project, setProject] = useState(null);

  let postUpdate = (project) => {
    navigate(
      "/projects/" + String(project_id) + "/systems/" + String(system_id) + "/"
    );
  };

  const fetchSystem = () => {
    SystemService.fetch(project_id, system_id).then(setSystem);
  };

  useEffect(() => {
    // Fetch systems for project
    fetchSystem();
    ProjectService.fetch(project_id).then(setProject);
  }, [project_id, system_id]);

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 1,
        system: system,
      }}
      header={window.gettext("Edit System")}
    >
      {!project || !system ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Options */}
          <LayoutCard
            title={window.gettext("Options")}
            sx={{
              width: 616,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <SystemReadOnlyCheckbox
                system={system}
                onSystemChanged={setSystem}
              />

              {window.gettext("Read only")}
            </Stack>
          </LayoutCard>

          {/* System */}
          <LayoutCard
            title={window.gettext("System")}
            sx={{
              width: 616,
            }}
          >
            <InstanceManipulator
              service={SystemService}
              urlParams={[project_id, system_id]}
              postUpdate={postUpdate}
              mode="edit"
              form={
                <SystemForm submitButtonText={window.gettext("Save system")} />
              }
            />
          </LayoutCard>
        </>
      )}
    </LayoutView>
  );
}
