import BaseFieldValidator from "../BaseFieldValidator";
import {NODE_TYPE_PIPE} from "../../../data/DataGetter";

export default class PipeLengthValidator extends BaseFieldValidator {
  fieldName = 'length'
  nodeTypes = [NODE_TYPE_PIPE]

  getFieldErrors = (row) => {
    if (row.node.item === null){
      return [
        this.error(
          row,
          window.gettext('Could not find pipe on this node')
        )
      ]
    }
    // If length is null or undefined
    if (row.node.item.length === null || row.node.item.length === undefined) {
      return [
        this.error(
          row,
          window.gettext('Length of pipe must be set')
        )
      ]
    }

    // If length is 0
    if (row.node.item.length === 0) {
      return [
        this.error(
          row,
          window.gettext('Length of pipe must be more than zero')
        )
      ]
    }

    return undefined
  }
}