import BaseFieldValidator from "../BaseFieldValidator";
import {NODE_TYPE_PIPE} from "../../../data/DataGetter";

export default class PipeDimensionValidator extends BaseFieldValidator {
  fieldName = 'dimension'
  nodeTypes = [NODE_TYPE_PIPE]

  getFieldErrors = (row) => {
    if (row.node.item === null){
      return [
        this.error(
          row,
          window.gettext('Could not find pipe on this node')
        )
      ]
    }

    // If length is null or undefined
    if (row.node.item.dimension === null || row.node.item.dimension === undefined) {
      return [
        this.error(
          row,
          window.gettext('Dimension of pipe must be set')
        )
      ]
    }

    return undefined
  }
}