import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import InstanceManipulator from "../../../../../components/InstanceManipulator";
import LoadingIndicator from "../../../../../components/layout/LoadingIndicator";
import PipeService from "../../../../../components/services/PipeService";
import ProjectService from "../../../../../components/services/ProjectService";
import SystemService from "../../../../../components/services/SystemService";
import SystemForm from "../../../../../components/system/SystemForm";

import { LayoutCard, LayoutView } from "../../../../../layout";

export default function SystemCreateView() {
  const navigate = useNavigate();

  const { project_id } = useParams();
  const [project, setProject] = useState(null);

  let postCreate = (system) => {
    // After a system is created
    PipeService.create({}, project_id, system.id).then((node) => {
      navigate(
        "/projects/" +
          String(project_id) +
          "/systems/" +
          String(system.id) +
          "/"
      );
    });
  };

  useEffect(() => {
    if (project_id === undefined) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }, [project_id]);

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 1,
      }}
      header={window.gettext("New system")}
    >
      {project === null ? (
        <LoadingIndicator />
      ) : (
        <LayoutCard
          sx={{
            maxWidth: 616,
          }}
        >
          <InstanceManipulator
            service={SystemService}
            urlParams={[project_id]}
            postCreate={postCreate}
            mode="create"
            form={
              <SystemForm submitButtonText={window.gettext("Save system")} />
            }
          />
        </LayoutCard>
      )}
    </LayoutView>
  );
}
