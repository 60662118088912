import PropTypes from "prop-types";
import EntityText from "./EntityText";
import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import {useEffect, useState} from "react";
import getEntityStatus from "../utils/getEntityStatus";

import DoneIcon from '@mui/icons-material/Done';
export default function EntityStatusTasks(props) {
  let {entityStatus, entityData, jobData, showDoneIcon} = props
  let [tasksDone, setTasksDone] = useState(null)
  let [taskSpecs, setTaskSpecs] = useState(null)


  useEffect(() => {
    if (!jobData || !entityData) {
      return
    }

    if (!entityStatus) {
      entityStatus = getEntityStatus(entityData, jobData)
    }

  }, [entityData, jobData, getEntityStatus])

  useEffect(() => {
    if(!entityStatus){
      return
    }

    setTasksDone(entityStatus.tasks_done)
    setTaskSpecs(entityStatus.task_specs)
  }, [entityStatus])

  return (
    <>
      {taskSpecs?.length === 0 ? (
        <>

        </>
      ) : (
        <>
          <span>
            {tasksDone?.length} / {taskSpecs?.length}
          </span>
          {(showDoneIcon === true) && (
            <>
              <DoneIcon />
            </>
          )}
        </>
      )}
    </>
  )
}

EntityStatusTasks.propTypes = {
  entityData: PropTypes.object,
  jobData: PropTypes.object,
  showDoneIcon: PropTypes.bool,
}