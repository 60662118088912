import { Form, FormFieldFactory } from "../../../../../../../../../form";

export function HierarchyPWSDishwasherForm({
  defaultName,
  onSubmit,
  submitButtonText = window.gettext("Add entity"),
}) {  
  const formFieldFactory = new FormFieldFactory();

  const formFields = [
    formFieldFactory.textField("name", window.gettext("Name"), false, defaultName),
  ];

  return (
    <Form
      formFields={formFields}
      onSubmit={onSubmit}
      submitButtonText={submitButtonText}
    />
  );
}
