import BaseCleaner from "./BaseCleaner";
import {NODE_TYPE_VALVE} from "../DataGetter";

export default class WhiteSpaceCleaner extends BaseCleaner {

  performClean = (node, params, data) => {
    let cleanedData = {}

    Object.entries(data).map(([key, value]) => {
      let newValue = value;
      if (typeof (value) === 'string') {
        newValue = newValue.trim()
      }
      return cleanedData[key] = newValue
    });

    return cleanedData
  }
}