import {useEffect, useState} from "react";
import SystemService from "../services/SystemService";
import {FormControl, FormHelperText, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

export default function SystemSelector(props) {
  let [project, setProject] = useState(null);
  let [systems, setSystems] = useState(null);
  let [systemId, setSystemId] = useState("");
  let [helperText, setHelperText] = useState("");
  let [label, setLabel] = useState(window.gettext('System'));

  useEffect(() => {
    if (!project) {
      return
    }

    SystemService.list(project.id).then(setSystems)
  }, [project])

  useEffect(() => {
    if (!props.project) {
      return
    }

    setProject(props.project)
  }, [props.project])

  useEffect(() => {
    setHelperText(props.helperText)
  }, [props.helperText])

  const handleChange = function (e){
    setSystemId(e.target.value)
  }

  useEffect(() => {
    if(!props.onChange){
      return
    }

    if(!systemId){
      return
    }

    props.onChange(
      systems.find((system) => (system.id === systemId))
    )
  }, [props.onChange, systemId, systems])

  return (
    <>
      {(systems) && (
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {label}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={systemId}
            label={label}
            onChange={handleChange}
          >
            {systems.map((system) => (
              <MenuItem value={system.id} key={"system-" + String(system.id)}>
                {system.name}
              </MenuItem>
            ))}
          </Select>

          {helperText && (
            <FormHelperText>
              {helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </>
  )
}