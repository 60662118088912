import { Button, Chip, List, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LayoutCard } from "../../../../../../../../layout";
import {
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_ENTRANCE,
} from "../../../../entity";
import { useHierarchyContext } from "../../../context";
import { getComponentsHeaders } from "../../../helper";
import { NavigationEntranceListItem } from "./components";
import {
  getDoneEntities,
  getFlaggedEntities,
  getPartlyDoneEntities,
  sortEntrances,
} from "./helper";

export function HierarchyNavigationSystem() {
  const [searchParams] = useSearchParams();

  const { entityType, jobData, hierarchyViewOptions } = useHierarchyContext();

  const [collapsedEntrances, setCollapsedEntrances] = useState({});

  const [apartmentEntities, setApartmentEntities] = useState([]);
  const [entranceEntities, setEntranceEntities] = useState([]);

  useEffect(() => {
    if (hierarchyViewOptions?.show_navigation_option.value) {
      let apartmentEntities = jobData.entities.filter(
        (entity) => entity.type === ENTITY_TYPE_APARTMENT
      );

      if (searchParams.get("done_only") === "true") {
        apartmentEntities = getDoneEntities(apartmentEntities, jobData);
      }

      if (searchParams.get("flagged_only") === "true") {
        apartmentEntities = getFlaggedEntities(apartmentEntities, jobData);
      }

      if (searchParams.get("partly_done_only") === "true") {
        apartmentEntities = getPartlyDoneEntities(apartmentEntities, jobData);
      }

      setApartmentEntities(apartmentEntities);

      setEntranceEntities(
        sortEntrances(
          jobData.entities.filter(
            (entity) => entity.type === ENTITY_TYPE_ENTRANCE
          )
        )
      );
    } else {
      setApartmentEntities([]);
      setEntranceEntities([]);
    }
  }, [
    jobData.entities,
    hierarchyViewOptions?.show_navigation_option.value,
    searchParams.get("done_only"),
    searchParams.get("flagged_only"),
    searchParams.get("partly_done_only"),
  ]);

  function openAll() {
    const collapsedEntrances = {};

    entranceEntities.forEach((entranceEntity) => {
      collapsedEntrances[entranceEntity.id] = true;
    });

    setCollapsedEntrances(collapsedEntrances);
  }

  return (
    <>
      {entranceEntities.length > 0 && (
        <LayoutCard
          title={getComponentsHeaders(entityType).navigation}
          menuItems={
            <Stack
              sx={{
                alignItems: "center",
                flexDirection: "row",
                gap: 1,
                px: 1,
              }}
            >
              <Chip
                label={window
                  .ngettext(
                    "%(number)d apartment",
                    "%(number)d apartments",
                    apartmentEntities.length
                  )
                  .replace("%(number)d", apartmentEntities.length)}
              />

              <Button onClick={openAll}>{window.gettext("Open all")}</Button>
            </Stack>
          }
          noPadding
        >
          <List
            component="nav"
            sx={{
              p: 0,
            }}
          >
            {entranceEntities.map((entranceEntity) => (
              <NavigationEntranceListItem
                key={entranceEntity.id}
                apartmentEntities={apartmentEntities.filter(
                  (apartmentEntity) =>
                    apartmentEntity.location.entrance.short_code ===
                    entranceEntity.data.short_code
                )}
                jobData={jobData}
                entranceEntity={entranceEntity}
                open={collapsedEntrances[entranceEntity.id]}
                setOpen={(open) =>
                  setCollapsedEntrances((prev) => {
                    return { ...prev, [entranceEntity.id]: open };
                  })
                }
              />
            ))}
          </List>
        </LayoutCard>
      )}
    </>
  );
}
