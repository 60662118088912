import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import {Link as RouterLink} from "react-router-dom";
import DateTime from "../../../../values/DateTime";
import {Box, Chip, Icon, Tooltip} from "@mui/material";
import List from "@mui/material/List";
import PropTypes from "prop-types";
import VerifiedIcon from "@mui/icons-material/Verified";

export default function MeasurementsList(props) {
  let {measurements, projectId, systemId, jobSpecId, jobId, latestMeasurement} = props

  console.log('JobSpecId', jobSpecId)

  return (
    <>
      {measurements && (
        <List>
          {measurements.map((measurement) => (
              <ListItem
                key={"job_" + String(jobId) + "_measurement_" + String(measurement.id)}
              >
                <>
                  {latestMeasurement && (
                    <Box
                      sx={{mr: 1}}
                    >
                      {latestMeasurement?.id === measurement.id ? (
                        <>
                          <Tooltip
                            title={window.gettext('Latest measurement')}
                          >
                            <VerifiedIcon/>
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Icon/>
                        </>
                      )}
                    </Box>
                  )}
                  <Link
                    to={"/projects/" + String(projectId) + "/systems/" + String(systemId) + "/job-specs/" + String(jobSpecId) + "/job/" + String(jobId) + "/measurements/" + String(measurement.id) + "/"}
                    component={RouterLink}
                  >
                    <DateTime value={measurement.created} showTime={true}/>
                  </Link>
                </>
                {(measurement.opportunities_count > 0) && (
                  <>
                    <Tooltip title={
                      window.gettext('Number of opportunities on this measurement')
                    }>
                      <Chip
                        sx={{ml: 2}} variant="outlined"
                        label={String(measurement.opportunities_count)}
                      />
                    </Tooltip>
                  </>
                )}
              </ListItem>
            )
          )}
        </List>
      )}
    </>
  )
}

MeasurementsList.propTypes = {
  measurements: PropTypes.array,
  projectId: PropTypes.number,
  systemId: PropTypes.number,
  jobSpecId: PropTypes.number,
  jobId: PropTypes.number,
  latestMeasurement: PropTypes.object,
}