export default class BaseCleaner {
  nodeTypes = undefined
  omitFields = undefined

  matchNode = (node) => {
    if(this.nodeTypes === undefined){
      return true
    }

    return this.nodeTypes.indexOf(node.type) >= 0;
  }

  matchParams = (params) => {
    return true
  }

  matchData = (data) => {
    return true
  }

  match = (node, params, data) => {
    if(!this.matchNode(node)){
      return false
    }

    if(!this.matchParams(params)){
      return false
    }

    if(!this.matchData(data)){
      return false
    }

    return true
  }

  /**
   *
   * @param node
   * @param params
   * @param data object representing all data.
   * @return {{}}
   */
  performClean = (node, params, data) => {
    let cleanedData = data

    // If there is a omitFields list, use that and remove those keys
    if(this.omitFields !== undefined){
      cleanedData = this.getObjectWithoutKeys(cleanedData, this.omitFields)
    }

    return cleanedData
  }

  getObjectWithoutKeys = (data, omitFieldsList) => {
    let cleanedData = {}

    Object.entries(data).map(([key, value]) => {
      // Throw away these two fields
      if(omitFieldsList.indexOf(key) >= 0){
        return undefined
      }

      // keep other values
      cleanedData[key] = value
      return undefined
    })

    return cleanedData
  }

  clean = (node, params, data) => {
    if(!this.match(node, params, data)){
      return data
    }

    return this.performClean(node, params, data)
  }
}
