import BaseValidator from "../BaseValidator";

export default class PumpPressureValidator extends BaseValidator{
  getSystemDataErrors = (systemData) => {
    if(!systemData.hasOwnProperty('pump_pressure')){
      return [
        this.error(
          this.ERROR_TYPE_SYSTEM_DATA,
          window.gettext('Could not find pump pressure setting on system')
        )
      ]
    }

    if(systemData.pump_pressure === null){
      return [
        this.error(
          this.ERROR_TYPE_SYSTEM_DATA,
          window.gettext('Pump pressure not set on system')
        )
      ]
    }

    if(systemData.pump_pressure === 0){
      return [
        this.error(
          this.ERROR_TYPE_SYSTEM_DATA,
          window.gettext('Pump pressure can not be set to zero')
        )
      ]
    }
  }
}