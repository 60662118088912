import { Stack } from "@mui/material";

import { useLayoutSizeTrackerContext } from "./context";
import { LayoutViewDesktop, LayoutViewMobile } from "./view";

import logo from "../assets/logo/assemblin/assemblin_energioptimering.svg";

export function LayoutView({
  children,
  header,
  breadcrumbs,
  noPadding,
  maxWidth,
  noPaddingSubmenu,
  printHeaderContent,
  topMenuItems,
  submenuItems,
  sx,
}) {
  const { isMobile } = useLayoutSizeTrackerContext();

  const params = {
    header,
    breadcrumbs,
    noPadding,
    noPaddingSubmenu,
    printHeaderContent,
    topMenuItems,
    submenuItems,
    sx: {
      maxWidth,
      ...sx,
    },
  };

  return (
    <>
      <Stack
        sx={{
          display: "none",
          displayPrint: "block",
          flexDirection: "row",
          justifyContent: "space-between",
          width: 150,
          mx: 2,
        }}
      >
        <img src={logo} />

        {printHeaderContent}
      </Stack>

      {!isMobile ? (
        <LayoutViewDesktop {...params}>{children}</LayoutViewDesktop>
      ) : (
        <LayoutViewMobile {...params}>{children}</LayoutViewMobile>
      )}
    </>
  );
}
