/**
 * Compares the option and value ids.
 *
 * @param {Object} option - The option to compare.
 * @param {Object} value - The value to compare.
 * @returns {Boolean} - True if the ids are equal, false otherwise.
 */
export function compareOptionValueIds(option, value) {
  if (value === null) {
    return false;
  }

  return option?.id === value?.id;
}
