import { Alert, Chip, List, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { LayoutCard } from "../../../../../../../../layout";
import { ENTITY_TYPE_APARTMENT } from "../../../../entity";
import { useHierarchyContext } from "../../../context";
import { getComponentsHeaders } from "../../../helper";
import { NavigationApartmentListItem } from "./components";
import {
  getDoneEntities,
  getFlaggedEntities,
  getPartlyDoneEntities,
} from "./helper";

export function HierarchyNavigationEntrance() {
  const [searchParams] = useSearchParams();

  const { entityType, focusEntityData, jobData, hierarchyViewOptions } =
    useHierarchyContext();

  const [apartmentEntities, setApartmentEntities] = useState([]);

  useEffect(() => {
    if (focusEntityData && hierarchyViewOptions?.show_navigation_option.value) {
      let apartmentEntities = jobData.entities.filter(
        (entity) => entity.type === ENTITY_TYPE_APARTMENT
      );

      if (searchParams.get("done_only") === "true") {
        apartmentEntities = getDoneEntities(apartmentEntities, jobData);
      }

      if (searchParams.get("flagged_only") === "true") {
        apartmentEntities = getFlaggedEntities(apartmentEntities, jobData);
      }

      if (searchParams.get("partly_done_only") === "true") {
        apartmentEntities = getPartlyDoneEntities(apartmentEntities, jobData);
      }

      setApartmentEntities(apartmentEntities);
    } else {
      setApartmentEntities([]);
    }
  }, [
    focusEntityData,
    hierarchyViewOptions?.show_navigation_option.value,
    jobData.entities,
    searchParams.get("done_only"),
    searchParams.get("flagged_only"),
    searchParams.get("partly_done_only"),
  ]);

  return (
    <>
      {apartmentEntities.length > 0 && (
        <LayoutCard
          title={getComponentsHeaders(entityType).navigation}
          menuItems={
            <Stack
              sx={{
                alignItems: "center",
                flexDirection: "row",
                px: 1,
              }}
            >
              <Chip
                label={window
                  .ngettext(
                    "%(number)d apartment",
                    "%(number)d apartments",
                    apartmentEntities.length
                  )
                  .replace("%(number)d", apartmentEntities.length)}
              />
            </Stack>
          }
          noPadding
        >
          <List
            component="nav"
            sx={{
              p: 0,
            }}
          >
            {apartmentEntities.map((apartmentEntity) => (
              <NavigationApartmentListItem
                key={apartmentEntity.id}
                jobData={jobData}
                entity={apartmentEntity}
              />
            ))}

            {apartmentEntities.length === 0 && (
              <Alert severity="info">
                {window.gettext("There are no apartments to display")}
              </Alert>
            )}
          </List>
        </LayoutCard>
      )}
    </>
  );
}
