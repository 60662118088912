import { useEffect } from "react";

import {
  PLACEMENT_BOTTOM,
  PLACEMENT_LEFT,
  PLACEMENT_RIGHT,
  PLACEMENT_TOP,
  useLayoutPlacementContext,
} from "../context";

function getUseLayoutPlacementHook(key) {
  return function ({ cleanupCallback, initialHidden = false }) {
    const layoutPlacementContext = useLayoutPlacementContext();

    useEffect(() => {
      // Set initial hidden state
      layoutPlacementContext[key].setHidden(initialHidden);

      // Cleanup on unmount
      return () => {
        layoutPlacementContext[key].cleanup();
      };
    }, []);

    // Reset hidden state
    function resetHidden() {
      if (layoutPlacementContext[key].hidden === initialHidden) {
        return;
      }

      layoutPlacementContext[key].setHidden(initialHidden);
    }

    // Use cleanup callback if provided
    function setElement(children, hidden, callback = cleanupCallback) {
      layoutPlacementContext[key].setElement(children, hidden, callback);
    }

    // Return content and content setter
    return {
      element: layoutPlacementContext[key].element,
      clear: layoutPlacementContext[key].cleanup,
      hidden: layoutPlacementContext[key].hidden,
      setCleanupCallback: layoutPlacementContext[key].setCleanupCallback,
      setElement,
      setHidden: layoutPlacementContext[key].setHidden,
      resetHidden,
    };
  };
}

// Layout bottom hook
export function useLayoutBottom(params = {}) {
  const useLayoutPlacementHook = getUseLayoutPlacementHook(PLACEMENT_BOTTOM);

  return useLayoutPlacementHook(params);
}

// Layout left hook
export function useLayoutLeft(params = {}) {
  const useLayoutPlacementHook = getUseLayoutPlacementHook(PLACEMENT_LEFT);

  return useLayoutPlacementHook(params);
}

// Layout right hook
export function useLayoutRight(params = {}) {
  const useLayoutPlacementHook = getUseLayoutPlacementHook(PLACEMENT_RIGHT);

  return useLayoutPlacementHook(params);
}

// Layout top hook
export function useLayoutTop(params = {}) {
  const useLayoutPlacementHook = getUseLayoutPlacementHook(PLACEMENT_TOP);

  return useLayoutPlacementHook(params);
}

// Layout content hook
export function useLayoutPlacement() {
  const layoutPlacementContext = useLayoutPlacementContext();

  return {
    bottomElement: layoutPlacementContext[PLACEMENT_BOTTOM].element,
    leftElement: layoutPlacementContext[PLACEMENT_LEFT].element,
    rightElement: layoutPlacementContext[PLACEMENT_RIGHT].element,
    topElement: layoutPlacementContext[PLACEMENT_TOP].element,

    bottomHidden: layoutPlacementContext[PLACEMENT_BOTTOM].hidden,
    leftHidden: layoutPlacementContext[PLACEMENT_LEFT].hidden,
    rightHidden: layoutPlacementContext[PLACEMENT_RIGHT].hidden,
    topHidden: layoutPlacementContext[PLACEMENT_TOP].hidden,
  };
}
