import getCustomerRepresentation from "./getCustomerRepresentation";

export default function Customer({customer}) {
  return (
    <>
      {customer !== undefined && customer !== null && (
        <>
          {getCustomerRepresentation(customer)}
        </>
      )}
    </>
  )
}