import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import RadiatorTypeSearchService from "../../../services/RadiatorTypeSearchService";

import {
  compareOptionValueIds,
  getHandleChangeCallback,
  getHandleInputChangeCallback,
  getOptionLabelName,
} from "./helper";

export function RadiatorSearchField({ onChange, label }) {
  const [inputValue, setInputValue] = useState(undefined);

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    // If input value is empty set options to empty array with only the current value
    if (!inputValue) {
      setOptions(value ? [value] : []);

      return;
    }

    RadiatorTypeSearchService.search(inputValue).then((response) => {
      setOptions(response);
    });
  }, [inputValue]);

  const handleChange = useCallback(
    getHandleChangeCallback(setValue, onChange),
    []
  );

  const handleInputChange = useCallback(
    getHandleInputChangeCallback(setInputValue),
    []
  );

  return (
    <Autocomplete
      id="auto"
      fullWidth={true}
      options={options}
      value={value}
      getOptionLabel={getOptionLabelName}
      isOptionEqualToValue={compareOptionValueIds}
      onChange={handleChange}
      onInputChange={handleInputChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
