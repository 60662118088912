import BaseInfoTabSection from "../layout/information/base_info/BaseInfoTabSection";
import BaseInfoTabSectionItem from "../layout/information/base_info/BaseInfoTabSectionItem";
import LoadingIndicator from "../layout/LoadingIndicator";
import DateTime from "../values/DateTime";
import TimeDuration from "../values/TimeDuration";
import MultilineText from "../layout/MultilineText";
import {useIssue} from "./IssueContext";
import ProjectLink from "../project/ProjectLink";

export default function IssueBaseInfo({}) {
  // Issue options
  const {issue} = useIssue()

  return (
    <>
      {!issue ? (
        <>
          <LoadingIndicator/>
        </>
      ) : (
        <>
          <BaseInfoTabSection title={window.gettext("Issue information")}>
            <BaseInfoTabSectionItem title={window.gettext("Issue number")}>
              {issue.number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Status")}>
              {issue.status_label}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Assigned to")}>
              {issue.assigned_to_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Category")}>
              {issue.category_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Created")}>
              <DateTime value={issue.created}/>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Created by")}>
              {issue.created_by_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Updated")}>
              <DateTime value={issue.changed}/>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Updated by")}>
              {issue.updated_by_name}
            </BaseInfoTabSectionItem>
            {issue.spent_time_minutes !== undefined && (
              <BaseInfoTabSectionItem title={window.gettext("Spent time")}>
                <TimeDuration minutes={issue.spent_time_minutes}/>
              </BaseInfoTabSectionItem>
            )}
            <BaseInfoTabSectionItem title={window.gettext("Description")}>
              <MultilineText>
                {issue.description}
              </MultilineText>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Note")}>
              <MultilineText>
                {issue.note}
              </MultilineText>
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          {/* Location */}
          <BaseInfoTabSection title={window.gettext("Location")}>
            <BaseInfoTabSectionItem title={window.gettext("Apartment")}>
              {issue.apartment_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Apartment number")}>
              {issue.apartment_number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Story")}>
              {issue.story}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Project")}>
              <ProjectLink
                id={issue.project}
              >
                {issue.project_name}
              </ProjectLink>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("System")}>
              {issue.system_name}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          {/* Contact information */}
          <BaseInfoTabSection title={window.gettext("Contact information")}>
            <BaseInfoTabSectionItem title={window.gettext("Phone number")}>
              {issue.feedback_phone_number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Email")}>
              {issue.feedback_email}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>
        </>
      )}
    </>
  )
}