import {ListItem, ListItemText, Menu} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import JobDatagridPresetService from "../../../../services/JobDatagridPresetService";

export default function JobDatagridPresetMenu({onHiddenColumnsChange}) {
  let [presets, setPresets] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const activatePreset = (presetId) => {
    // Get preset
    const chosen_preset = findPreset(presetId)

    let hiddenColumns = {}

    // If no preset, reset the hidden columns
    if (chosen_preset !== null) {
      // Make the hidden columns object
      hiddenColumns = Object.fromEntries(
        chosen_preset.column_settings.map(
          (column_setting) => {
            return [
              column_setting.field,
              column_setting.hide
            ]
          }
        )
      )
    }

    onHiddenColumnsChange(
      hiddenColumns
    )
  }

  const findPreset = (presetId) => {
    if (!presetId) {
      return null
    }

    return presets.find((preset) => {
      return preset.id === parseInt(presetId);
    }) || null
  }

  const handlePresetSelect = (presetId) => {
    handleClose()
    localStorage.setItem('self_check_job_table_view_preset_id', parseInt(presetId))
    activatePreset(presetId)
  }

  useEffect(() => {
    JobDatagridPresetService.list().then(
      setPresets
    )

    return () => {
      setPresets(null);
    }
  }, [])

  return (
    <>
      {presets && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="outlined"
            startIcon={<MenuIcon/>}
          >
            {window.gettext('Presets')}
          </Button>
          <Menu
            data-test-id="system-build-preset-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              'sx': {
                'minWidth': '200px'
              }
            }}
          >
            <ListItem
              button
              onClick={
                () => handlePresetSelect(null)
              }
            >
              <ListItemText
                primary={window.gettext('None')}
              />
            </ListItem>
            {presets.map((preset, index) => {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    handlePresetSelect(preset.id)
                  }}
                >
                  <ListItemText
                    primary={preset.name}
                    secondary={preset.description || undefined}
                  />
                </ListItem>
              )
            })}
          </Menu>
        </>
      )}
    </>
  )
}