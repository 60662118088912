import {createContext, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SelfCheckJobInstallationReportService from "../../../../../services/SelfCheckJobInstallationReportService";
import SelfCheckJobInstallationReportProfileBlacklistCommentService from "../../../../../services/SelfCheckJobInstallationReportProfileBlacklistCommentService";

export const JobInstallationReportContext = createContext({
  report: undefined,
  reloadReport: undefined,
  getEntityId: undefined,
  blacklistedComments: undefined,
  removeBlacklistedComment: undefined,
  fetchBlacklistedComments: undefined,
})

export default function JobInstallationReportProvider({children}) {
  let {profile_id} = useParams()
  let [report, setReport] = useState(null)
  let [blacklistedComments, setBlacklistedComments] = useState(null)

  const fetchReport = useCallback(() => {
      setReport(null)

      SelfCheckJobInstallationReportService.fetch(
        profile_id,
      ).then(
        setReport
      )
    },
    [profile_id]
  )

  const fetchBlacklistedComments = () => {
    SelfCheckJobInstallationReportProfileBlacklistCommentService.list(
      profile_id,
    ).then(
      setBlacklistedComments
    )
  }

  useEffect(() => {
    if (!profile_id) {
      return
    }

    fetchReport()

    fetchBlacklistedComments()

  }, [fetchReport, profile_id])

  const handleRemoveBlacklistedComment = (comment) => {
    console.log('remove comment', comment)

    return SelfCheckJobInstallationReportProfileBlacklistCommentService.destroy(
      profile_id,
      comment.id,
    )
  }

  const getEntityId = (entity) => {
    return (entity.type) + '_' + entity.id
  }

  return (
    <JobInstallationReportContext.Provider
      value={
        {
          'report': report,
          'reloadReport': () => {
            fetchReport()
          },
          'getEntityId': getEntityId,
          'blacklistedComments': blacklistedComments,
          'removeBlacklistedComment': handleRemoveBlacklistedComment,
          'fetchBlacklistedComments': fetchBlacklistedComments,
        }
      }
    >
      {children}
    </JobInstallationReportContext.Provider>
  )
}
