import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { ENTITY_TYPE_SYSTEM } from "../entity";
import {
  HierarchyBottomDrawer,
  HierarchyBreadcrumbs,
  HierarchyContent,
  HierarchyUnknownSystemType,
} from "./components";
import {
  HierarchyContextProvider,
  HierarchyPWSContextProvider,
  HierarchyRSContextProvider,
} from "./context";

export function JobHierarchy({
  entityId,
  entityType,
  fetchOptions,
  jobData,
  project,
  refreshJobData,
  setJobData,
  system,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!jobData || entityType === ENTITY_TYPE_SYSTEM) {
      return;
    }

    // Check if the entity exists in the jobData
    const validEntityParams = jobData.entities.find(
      (entity) => entity.id === entityId && entity.type === entityType
    );

    if (!validEntityParams) {
      const params = {};

      if (searchParams.get("visit_day")) {
        params["visit_day"] = searchParams.get("visit_day");
      }

      if (searchParams.get("done_only")) {
        params["done_only"] = searchParams.get("done_only");
      }

      if (searchParams.get("flagged_only")) {
        params["flagged_only"] = searchParams.get("flagged_only");
      }

      if (searchParams.get("partly_done_only")) {
        params["partly_done_only"] = searchParams.get("partly_done_only");
      }

      setSearchParams(params);
    }
  }, [jobData?.entities]);

  return (
    <>
      {!!jobData && !!project && !!system && (
        <HierarchyContextProvider
          project={project}
          system={system}
          jobData={jobData}
          entityId={entityId}
          entityType={entityType}
          fetchOptions={fetchOptions}
          refreshJobData={refreshJobData}
          setJobData={setJobData}
        >
          {system.type === "radiator" ? (
            <HierarchyRSContextProvider>
              <HierarchyBreadcrumbs />
              <HierarchyContent />
              <HierarchyBottomDrawer />
            </HierarchyRSContextProvider>
          ) : system.type === "potable_water" ? (
            <HierarchyPWSContextProvider>
              <HierarchyBreadcrumbs />
              <HierarchyContent />
              <HierarchyBottomDrawer />
            </HierarchyPWSContextProvider>
          ) : (
            <HierarchyUnknownSystemType />
          )}
        </HierarchyContextProvider>
      )}
    </>
  );
}
