import BaseService from "./BaseService";

class SelfCheckJobInstallationProfileOptionsService extends BaseService {
}

export default new SelfCheckJobInstallationProfileOptionsService('/api/',
  [
    'installation-report-profile',
    'options',
  ]
)