import BaseService from "../BaseService";

class SelfCheckJobMeasurementOpportunityService extends BaseService {
  preSendDataTransformer = (data) => {
    if(data.hasOwnProperty('pump_displayed_flow')){
      // Convert into whole Kronor
      data['pump_displayed_flow'] = data['pump_displayed_flow'] || null
    }
    return data
  }
}

export default new SelfCheckJobMeasurementOpportunityService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurements',
    'opportunity'
  ],

)