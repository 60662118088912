import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

export const PLACEMENT_BOTTOM = "bottom";
export const PLACEMENT_LEFT = "left";
export const PLACEMENT_RIGHT = "right";
export const PLACEMENT_TOP = "top";

const LayoutPlacementContext = createContext(null);

// Layout placement context provider hook
export function useProvideLayoutPlacementContext() {
  const [bottom, setBottom] = useState(null);
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const [top, setTop] = useState(null);

  const [bottomHidden, setBottomHidden] = useState(null);
  const [leftHidden, setLeftHidden] = useState(null);
  const [rightHidden, setRightHidden] = useState(null);
  const [topHidden, setTopHidden] = useState(null);

  const placementRef = useRef({
    [PLACEMENT_BOTTOM]: {},
    [PLACEMENT_LEFT]: {},
    [PLACEMENT_RIGHT]: {},
    [PLACEMENT_TOP]: {},
  });

  const getPlacementObject = useCallback(
    (refKey, elementState, onSetElement, hiddenState, onSetHidden) => {
      function defaultCleanupCallback() {
        setElement(null);
        onSetHidden(null);
      }

      function cleanup() {
        if (!placementRef.current[refKey].cleanupCallback) {
          return;
        }

        placementRef.current[refKey].cleanupCallback(defaultCleanupCallback);

        setCleanupCallback(null);
      }

      function setCleanupCallback(cleanupCallback) {
        placementRef.current[refKey].cleanupCallback = cleanupCallback;
      }

      function setElement(
        element,
        hidden,
        cleanupCallback = defaultCleanupCallback
      ) {
        if (element === undefined) {
          return;
        }

        if (hidden !== undefined) {
          onSetHidden(hidden);
        }

        onSetElement(element);
        setCleanupCallback(element === null ? null : cleanupCallback);
      }

      function setHidden(hidden) {
        if (hidden === undefined) {
          return;
        }

        onSetHidden(hidden);
      }

      return {
        element: elementState,
        hidden: hiddenState,

        cleanup,

        setCleanupCallback,
        setElement,
        setHidden,
      };
    },
    []
  );

  return {
    [PLACEMENT_BOTTOM]: getPlacementObject(
      PLACEMENT_BOTTOM,
      bottom,
      setBottom,
      bottomHidden,
      setBottomHidden
    ),
    [PLACEMENT_LEFT]: getPlacementObject(
      PLACEMENT_LEFT,
      left,
      setLeft,
      leftHidden,
      setLeftHidden
    ),
    [PLACEMENT_RIGHT]: getPlacementObject(
      PLACEMENT_RIGHT,
      right,
      setRight,
      rightHidden,
      setRightHidden
    ),
    [PLACEMENT_TOP]: getPlacementObject(
      PLACEMENT_TOP,
      top,
      setTop,
      topHidden,
      setTopHidden
    ),
  };
}

// Layout placement context provider
export function LayoutPlacementContextProvider({ children, ...props }) {
  const context = useProvideLayoutPlacementContext(props);

  return (
    <LayoutPlacementContext.Provider value={context}>
      {children}
    </LayoutPlacementContext.Provider>
  );
}

// Layout placement context hook
export function useLayoutPlacementContext() {
  const context = useContext(LayoutPlacementContext);

  if (!context) {
    throw new Error(
      "useLayoutPlacementContext has to be used within <LayoutPlacementContextProvider>"
    );
  }

  return context;
}
