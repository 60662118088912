import getTaskItemSpecFieldId from "./getTaskItemSpecFieldId";
import getTaskSpecFieldId from "./taskSpecFieldId";

export default function getCompleteEntityListFromJobData(jobData) {
  return jobData.entities.map(
    (entity, index) => {
      const base_data = {
        'id': String(index) + '_' + entity.type + '_' + String(entity.id)
      }

      /*
      * Creates an object representing all the values on the entity
      * {
      *   'task_item_x' : true/false/null
      * }
      * */
      const task_item_data = Object.fromEntries(
        entity.task_item_specs.map(
          (task_item_spec) => {
            // Get the task item
            const task_item = entity.task_items.find(
              (task_item) => {
                return task_item.spec_id === task_item_spec.id
              }
            )

            return [
              getTaskItemSpecFieldId(task_item_spec),
              // Done from task item or null
              task_item ? task_item : null,
            ]
          }
        )
      )

      /*
      * Creates an object representing all the values on the entity
      * {
      *   'task_x' : true/false/null
      * }
      *
      * Goes through specs because if the actual task is not defined
      * we still want a value for it. (null) and a key (task_x)
      * */
      const task_data = Object.fromEntries(
        entity.task_specs.map(
          (task_spec) => {
            // Getting the task on the entity based on the spec
            // If entity_task is defined there IS a task for this spec
            // Contains only id and spec_id
            const entity_task = entity.tasks.find(
              (entity_task) => {
                return entity_task.spec_id === task_spec.id
              }
            )

            // Get the task data.
            const task_data = jobData.tasks.find(
              (job_task) => {
                if (!entity_task) {
                  return false
                }

                return job_task.id === entity_task.id
              }
            )

            return [
              getTaskSpecFieldId(task_spec, 'done'),
              task_data ? task_data : null,
            ]
          }
        )
      )

      /**
       * Not all tasks/task-items are represented in the 'checkable' field
       * Only the ones relevant for this task/task-item
       */

      return {
        ...base_data,
        'checkable': {
          ...task_item_data,
          ...task_data,
        },
        ...{
          'entity': entity,
          'job': jobData,
        }
      }
    }
  )
}