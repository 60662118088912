import Transformer from "../Transformer";

export default class ExactResistanceTransformer extends Transformer{
  key = 'exact_resistance'
  valueTransformer = (value) => {
    if (value === "") {
     return null
    }

    return parseInt(value)
  }
}