import { LayoutPlacementContextProvider } from "./LayoutPlacementContext";
import { LayoutSizeTrackerContextProvider } from "./LayoutSizeTrackerContext";

export function LayoutContextProvider({ children }) {
  return (
    <LayoutPlacementContextProvider>
      <LayoutSizeTrackerContextProvider>
        {children}
      </LayoutSizeTrackerContextProvider>
    </LayoutPlacementContextProvider>
  );
}
