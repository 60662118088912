import BaseService from "./BaseService";

class SystemRoomService extends BaseService {}

export default new SystemRoomService('/api/',
  [
    'projects',
    'systems',
    'rooms',
  ]
)