import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";

export default function ProjectLink({id, name, children}) {
  if (!id) {
    return null
  }

  return (
    <Link
      key={"project_link_" + String(id)}
      component={RouterLink}
      to={"/projects/" + String(id) + "/"}
    >
      {(children) ? (
        <>
          {children}
        </>
      ) : (
        <>
          {name || id}
        </>
      )}
    </Link>
  )
}
