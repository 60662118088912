import { InputAdornment, TextField } from "@mui/material";

export function FactoryTextField({ formField, hasError, helperText }) {
  return ({ field }) => {
    return (
      <TextField
        {...field}
        InputProps={{
          endAdornment: formField.options.endAdornmentText ? (
            <InputAdornment position="end">
              {formField.options.endAdornmentText}
            </InputAdornment>
          ) : undefined,
        }}
        variant="standard"
        data-test-id={formField.name}
        required={formField.required}
        label={formField.label}
        error={hasError}
        helperText={helperText}
        {...formField.options.props}
      />
    );
  };
}
