import BaseService from "./BaseService";

class SystemHeatCurveService extends BaseService {}

export default new SystemHeatCurveService('/api/',
  [
    'projects',
    'systems',
    'heat-curves',
  ]
)