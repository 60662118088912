import React, {useState} from "react";
// material
import {
  Stack,
  Button,
  TextField, Alert,
} from '@mui/material';

import {useLocation, useNavigate} from "react-router-dom";

import { useUser } from "../../context";

// ----------------------------------------------------------------------

function useQuery() {
  let {search} = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export default function LoginForm(props) {
  const { login } = useUser();

  let [username, setUsername] = useState("")
  let [password, setPassword] = useState("")
  let [error, setError] = useState()
  let navigate = useNavigate()

  let query = useQuery()

  let handleUsernameChange = function (event) {
    setError(undefined)
    setUsername(event.target.value)
  }

  let handlePasswordChange = function (event) {
    setError(undefined)
    setPassword(event.target.value)
  }

  const handleLogin = (event) => {
    event.preventDefault();

    login(username, password, {
      onRejection: (rejection) => {
        if (rejection.response.data.detail !== undefined) {
          setError(rejection.response.data.detail);
        } else {
          setError("ERROR 0001");
        }
      },
      onSuccess: (user) => {
        // Go to ?redirect=xxx param or the start page as defined in the user profile or the root.
        navigate(
          query.get("redirect") || user?.profile?.start_page || "/"
        );
      },
    });
  };

  return (
    <form noValidate data-test-id="login_form" onSubmit={handleLogin}>
      <Stack spacing={3}>
        {/*  Error message  */}
        {error !== undefined ? (
          <Alert data-test-id="login-error-message" severity="error">{error}</Alert>
        ) : (<></>)}

        <TextField
          fullWidth
          data-test-id="login_form_username"
          name="username" // Name is used to set password inside state
          autoComplete="username"
          label={window.gettext("Username")}
          value={username}
          onChange={handleUsernameChange}
        />
        <TextField
          fullWidth
          autoComplete="password"
          data-test-id="login_form_password"
          name="password" // Name is used to set password inside state
          type="password"
          label={window.gettext("Password")}
          value={password}
          onChange={handlePasswordChange}
        />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          data-test-id="login_form_submit"
          disabled={username === "" || password === ""}
        >
          {window.gettext("Log in")}
        </Button>
      </Stack>
    </form>
  )
}