export default class FieldValidationError {
  constructor(
    errorType,
    fieldName,
    row,
    errorMessage
  ) {
    this.errorType = errorType
    this.fieldName = fieldName
    this.row = row
    this.errorMessage = errorMessage
  }

  repr = () => {
    return {
      'type': this.errorType,
      'field': this.fieldName,
      'rowIndex': this.row.id,
      'nodeId': this.row.node.id,
      'message': this.errorMessage,
    }
  }
}