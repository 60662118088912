import {useContext} from "react";
import {Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import Helper from "../../../../../../utils/Helper";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import IconButton from "@mui/material/IconButton";
import {useSnackbar} from "notistack";
import {JobInstallationReportContext} from "../JobInstallationReportContext";
import MultilineText from "../../../../../../layout/MultilineText";

export default function JobReportInstallationProfileBlacklistedCommentsList() {
  let {blacklistedComments, removeBlacklistedComment, fetchBlacklistedComments} = useContext(JobInstallationReportContext)
  let {enqueueSnackbar} = useSnackbar()

  const handleRemoveFromBlacklistButtonClick = (blacklistedComment) => {
    removeBlacklistedComment(blacklistedComment).then(
      () => {
        enqueueSnackbar(
          window.gettext('Comment removed from blacklist'),
          {
            'variant': 'success'
          }
        )

        fetchBlacklistedComments()
      }
    )
  }

  return (
    <>
      {(blacklistedComments?.length || 0) === 0 ? (
        <>
          <Alert severity={'info'}>
            {window.gettext('No blacklisted comments could be found')}
          </Alert>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {window.gettext('Name')}
                  </TableCell>
                  <TableCell>
                    {window.gettext('Comment')}
                  </TableCell>
                  <TableCell>
                    {window.gettext('Remove')}
                    <Helper
                      title={window.gettext('Remove from the blacklist')}
                      ml
                    >
                      {window.gettext('If removed this comment will be visible in the list of radiators with comments for this profile')}
                    </Helper>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(blacklistedComments || []).map(
                  (blacklistedComment, i) => {
                    return (
                      <TableRow
                        key={'blacklisted_comment_' + i}
                      >
                        <TableCell>
                          {blacklistedComment.entity_label}
                        </TableCell>
                        <TableCell>
                          <MultilineText>
                            {blacklistedComment?.task_item?.comment || blacklistedComment?.task?.comment}
                          </MultilineText>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={
                              () => {
                                handleRemoveFromBlacklistButtonClick(blacklistedComment)
                              }
                            }
                          >
                            <RestoreFromTrashIcon color={"success"}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>

  )
}