import FlagIcon from "@mui/icons-material/Flag";
import { Button } from "@mui/material";

import { ACTION_SET_FLAG } from "../GridRowAction";

export function RemoveFlagsNodeButton({ dispatchActionCallback }) {
  const handleOnClick = () => {
    dispatchActionCallback(ACTION_SET_FLAG, {
      flag: null,
      include_self: true,
    });
  };

  console.log("first");

  return (
    <Button
      variant="contained"
      color="warning"
      startIcon={<FlagIcon />}
      onClick={handleOnClick}
    >
      {window.gettext("Remove flags")}
    </Button>
  );
}
