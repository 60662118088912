import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../../../../../../components/layout/LoadingIndicator";
import JobCalculationReportSummary from "../../../../../../../../../../../components/project/self_check/job/report/calculation/JobCalculationReportSummary";
import ProjectService from "../../../../../../../../../../../components/services/ProjectService";
import SelfCheckJobService from "../../../../../../../../../../../components/services/SelfCheckJobService";
import Helper from "../../../../../../../../../../../components/utils/Helper";

import {
  LayoutCard,
  LayoutView,
} from "../../../../../../../../../../../layout";

export default function JobReportCalculationView() {
  const { job_id, project_id, system_id } = useParams();

  const [project, setProject] = useState(null);
  const [report, setReport] = useState(null);

  useEffect(fetchProject, [project_id]);
  useEffect(fetchReportCalculation, [job_id, project_id, system_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  function fetchReportCalculation() {
    if (!project_id || !system_id || !job_id) {
      return;
    }

    SelfCheckJobService.report_calculation(project_id, system_id, job_id).then(
      (response) => {
        setReport(response);
      }
    );
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Calculation report")}
    >
      {!report ? (
        <LoadingIndicator />
      ) : (
        <LayoutCard
          title={
            <>
              {window.gettext("Calculated values summary")}

              <Helper
                title={window.gettext(
                  "Summary of the saved calculated values for this job"
                )}
                ml
              ></Helper>
            </>
          }
          noPadding
        >
          <JobCalculationReportSummary report={report} />
        </LayoutCard>
      )}
    </LayoutView>
  );
}
