import React, {useEffect, useRef, useState} from 'react'
import objectDiff from "./objectdiff/ObjectDiff";
import PropTypes from "prop-types";

function cleanObject(obj) {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => {
        if (v === undefined) {
          return false
        }
        if (v === "") {
          return false
        }

        return true
      })
      .map(([k, v]) => [k, v === Object(v) ? cleanObject(v) : v])
  );
}

export default function InstanceManipulator(props) {
  let [formInstance, setFormInstance] = useState(undefined);
  let [form_errors, setFormErrors] = useState();
  let [mode, setMode] = useState(undefined)

  // For reference, so we only send the data that has changed.
  let object_instance = useRef();

  useEffect(() => {
    if (!props.mode) {
      return
    }

    setMode(props.mode)
  }, [props.mode])

  const create = (data, urlParams) => {
    if (props.service.hasOwnProperty('preSendDataTransformer')) {
      data = props.service.preSendDataTransformer(data)
    }

    props.service.create(
      data,
      ...urlParams
    )
      .then((instance) => {
        if (props.postCreate !== undefined) {
          props.postCreate(instance)
        }
      }, (rejection) => {
        alert('Error while creating project');
      });
  }

  const update = (data, urlParams) => {
    if (props.service.hasOwnProperty('preSendDataTransformer')) {
      data = props.service.preSendDataTransformer(data)
    }

    if (props.hasOwnProperty('preSendDataTransformer')) {
      data = props.preSendDataTransformer(data)
    }

    props.service.update(
      data,
      ...urlParams
    ).then(
      (instance) => {
        // redirect users to the project page
        if (props.postUpdate !== undefined) {
          props.postUpdate(instance)
        }
      }, (rejection) => {
        alert('Error while saving project');
      });
  }

  const onSubmit = (data) => {
    let post_data

    if(props.postData !== undefined) {
      post_data = { ...props.postData };
    }

    if (mode === "edit") {
      // Get difference between the objects.
      post_data = objectDiff({ ...post_data, ...data }, object_instance.current )

      console.log('Submitting data', {'post_data': post_data, 'data': data, 'object_instance': object_instance.current})
      if (Object.keys(post_data).length === 0) {
        setFormErrors(
          {
            'detail': window.gettext('Seems like nothing has changed, change data before save or go back.')
          }
        )
        return
      }

      update(post_data, props.urlParams || [])
    } else {
      // Only send non-empty data.
      post_data = cleanObject({ ...post_data , ...data })

      create(post_data, props.urlParams || [])
    }
  };

  useEffect(() => {
    if(!mode){
      return
    }
    
    if (mode === "edit") {
      // If there is an ID we are to get the instance from.
      props.service.fetch(
        ...props.urlParams
      ).then(
        (instance) => {
          if(props.hasOwnProperty('preViewTransformer')){
            instance = props.preViewTransformer(instance)
          }

          if(props.hasOwnProperty('onFetched')){
            props.onFetched(instance)
          }

          // This is usefull for any form fields that allows empty values
          // If the backend returns null values, we will convert them to empty string for
          // the defined keys
          // When the user selects an empty value (if the form allows it), it will be an empty string
          // Thus later when comparing the form data with the instance data, it will be the same.
          // If this is omitted the form will think that the user has changed the value
          if(props.hasOwnProperty('convertNullValuesToEmptyStringAfterFetch')){
            props.convertNullValuesToEmptyStringAfterFetch.forEach((key) => {
              if(instance.hasOwnProperty(key) && instance[key] === null){
                instance[key] = ""
              }
            })
          }

          object_instance.current = instance

          const form_instance = React.cloneElement(
            props.form,
            {
              'instance': instance,
              'errors': form_errors,
              'onSubmit': onSubmit
            }
          )

          setFormInstance(form_instance)
        }
      )
    } else {
      // No instance, just create the form instance.
      const form_instance = React.cloneElement(
        props.form,
        {
          'onSubmit': onSubmit
        }
      )

      setFormInstance(form_instance)
    }
  }, [mode, props.urlParams, props.form])

  return (
    <>
      {
        formInstance === undefined ? (
          <></>
        ) : (
          {...formInstance}
        )
      }
    < />
  )
}

InstanceManipulator.MODE_CREATE = 'create'
InstanceManipulator.MODE_EDIT = 'edit'

InstanceManipulator.propTypes = {
  mode: PropTypes.string,
  form: PropTypes.object,
  service: PropTypes.object,
  urlParams: PropTypes.array,
  postUpdate: PropTypes.func,
  postCreate: PropTypes.func,
}