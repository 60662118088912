import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

/*
* props.value = the value, always in meters per second
* props.precision = how many decimal points we should display
* */
export default function Speed(props) {
  const defaultPrecision = 3
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(defaultPrecision)
  let suffix = "m/s"


  // Set the rounded string value
  useEffect(() => {
    // Convert from input value to millimeters
    let value = props.value

    setDisplayValue(value)
  }, [props.value, precision])

  // setting precision
  useEffect(() => {
    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} />
  )
}