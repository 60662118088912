export const CONTAINER_TYPES = {
  SECTION: {
    props: {},
    headerProps: {
      variant: "h5",
    },
    innerProps: {
      sx: {
        gap: 1,
      },
    },
    childProps: {},
    uniqueChildProps: [],
  },

  GROUP: {
    props: {},
    headerProps: {
      variant: "h6",
    },
    innerProps: {
      sx: {
        gap: 1,
      },
    },
    childProps: {},
    uniqueChildProps: [],
  },
};
