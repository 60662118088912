import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  Tooltip
} from "@mui/material";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InformationGridItem from "../info/InformationGridItem";
import Volume from "../../../values/Volume";
import Date from "../../../values/Date";
import Energy from "../../../values/Energy";
import Pressure from "../../../values/Pressure";
import Flow from "../../../values/Flow";
import Temperature from "../../../values/Temperature";
import Choice from "../../../values/Choice";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FetchCalculationsButton from "../FetchCalculationsButton";
import React, {useEffect, useState} from "react";
import {changeColumnSettingsProperty, getSettingsForColumn} from "../ColumnSettings";
import PresetMenu from "./PresetMenu";
import GroupingColumnDefinition from "../column_definitions/columns/GroupingColumnDefinition";
import QuickSettings from "./QuickSettings";
import {MAIN_MENU_HEIGHT} from "../../../menu/Menu";
import SystemItemHistory from "./SystemItemHistory";
import NodeSearch from "./NodeSearch";
import Helper from "../../../utils/Helper";
import SystemReadOnlyCheckbox from "../../SystemReadOnlyCheckbox";

export const BOTTOM_DRAWER_HANDLE_HEIGHT = 64;
const ENABLE_NODE_SEARCH = false
const ENABLE_SYSTEM_ITEM_HISTORY = false

export default function BottomDrawer(props) {
  let [columnSettings, setColumnSettings] = useState(props.columnSettings)
  let [settings, setSettings] = useState(props.settings)
  let [showSettings, setShowSettings] = useState(false)
  let [settingsAccordionExpanded, setSettingsAccordionExpanded] = React.useState(undefined);
  let [bottomDrawerOpen, setBottomDrawerOpen] = useState(false)
  let [project, setProject] = useState(undefined)
  let [columns, setColumns] = useState(undefined)
  let [system, setSystem] = useState(undefined)
  let [nodes, setNodes] = useState([])
  let [calculations, setCalculations] = useState(undefined)
  let [systemChoices, setSystemChoices] = useState(undefined)
  let [validationResults, setValidationResults] = useState(undefined)
  //let [autoRefreshCalculations, setAutoRefreshCalculations] = useState(false)

  let [showTreeView, setShowTreeView] = useState(undefined)

  const handleChangeSettingsAccordion = (panel) => (event, isExpanded) => {
    setSettingsAccordionExpanded(isExpanded ? panel : false);
  };

// Change height of table depending on the left over room in the viewport
  const adjustTableHeight = function (accountForDrawer) {
    let newTableHeight = window.innerHeight

    // Header
    newTableHeight -= MAIN_MENU_HEIGHT

    if (accountForDrawer) {
      // Bottom drawer
      newTableHeight -= document.querySelector('.bottom-app-drawer-paper').clientHeight
    } else {
      // Account for drawer handle
      newTableHeight -= BOTTOM_DRAWER_HANDLE_HEIGHT
    }

    props.setTableHeightCallback(
      newTableHeight
    )
  }

  useEffect(
    () => {
      setProject(props.project)
      setValidationResults(props.validationResults)
      setSystem(props.system)
      setCalculations(props.calculations)
      setColumns(props.columns)
      setSystemChoices(props.systemChoices)
    }, [
      props.project,
      props.validationResults,
      props.system,
      props.calculations,
      props.columns,
      props.systemChocies,
    ]
  )

  useEffect(() => {
    setNodes(props.nodes)
  }, [props.nodes])

  // Overrides settings object with new value
  const applyNewSettings = function (settings_to_apply) {
    setSettings({
      ...settings,
      ...settings_to_apply
    })
  }

  useEffect(() => {
    props.columnSettingsChangeCallback(columnSettings || [])

    const treeViewColumn = columnSettings.find((col) => {
      return col.field === '__tree_data_group__'
    })

    if (treeViewColumn) {
      setShowTreeView(!treeViewColumn.settings.hide)
    } else {
      // Could not find a tree view column in settings, defaulting to the value of GroupingColumnDefinition default value
      setShowTreeView(!GroupingColumnDefinition().hide)
    }

  }, [columnSettings])

  useEffect(() => {
    props.settingsChangeCallback(settings || [])
  }, [settings])

  useEffect(() => {
    if (system === undefined) {
      return
    }

    props.onSystemChanged(system)
  }, [system])

  return (
    <>
      {/* Contents of the drawer when expanded, the not expanded is below */}
      <Drawer
        anchor="bottom"
        open={bottomDrawerOpen}
        variant="persistent"
        className="bottom-app-drawer"
      >
        <>
          <Paper
            className="bottom-app-drawer-paper"
            style={{'padding': 'em'}}
          >
            {/* Contents of the drawer when expanded */}
            <Grid
              container
            >
              <Grid item>
                <IconButton
                  onClick={
                    () => {
                      setBottomDrawerOpen(!bottomDrawerOpen)
                      adjustTableHeight(false)
                    }
                  }
                  data-test-id="hide-bottom-app-drawer-button"
                >
                  <KeyboardArrowDownIcon/>
                </IconButton>
              </Grid>
            </Grid>
            {/* System and project information */}
            {
              project !== undefined && system !== undefined && (
                <Grid container spacing={0}>
                  <Grid item xs={11} container>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("Project")}
                        value={project.name}
                      />
                      <InformationGridItem
                        title={window.gettext("Pump")}
                        value={system.pump_type}
                      />

                      <InformationGridItem
                        title={window.gettext("Volume")}
                        obj={calculations}
                        keyPath="total_volume"
                        component={<Volume/>}
                      />
                    </Grid>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("Project start")}
                        value={project.created}
                        component={<Date/>}
                      />
                      <InformationGridItem
                        title={window.gettext("Pump operation setting")}
                        value={system.pump_operation_setting}
                      />
                      <InformationGridItem
                        title={window.gettext("Effect")}
                        obj={calculations}
                        keyPath="total_effect"
                        component={<Energy unit="auto"/>}
                      />
                    </Grid>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("System")}
                        value={system.name}
                      />
                      <InformationGridItem
                        title={window.gettext("Pump pressure")}
                        value={system.pump_pressure}
                        component={<Pressure/>}
                      />
                      <InformationGridItem
                        title={window.gettext("Flow")}
                        obj={calculations}
                        keyPath="total_flow"
                        component={<Flow/>}
                      />
                    </Grid>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("Return temperature")}
                        value={system.return_temperature}
                        component={<Temperature/>}
                      />
                      <InformationGridItem
                        title={window.gettext("Least favorable node")}
                        obj={calculations}
                        keyPath="least_favorable_node.name"
                      />
                      <InformationGridItem
                        title={window.gettext("Pressure drop on root node")}
                        obj={calculations}
                        keyPath="root_node.pressure_drop"
                        component={<Pressure unit="auto"/>}
                      />
                    </Grid>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("Outflow temperature")}
                        value={system.outflow_temperature}
                        component={<Temperature/>}
                      />
                      <InformationGridItem
                        title={window.gettext("Desired room temperature")}
                        value={parseFloat(system.room_temperature)}
                        component={<Temperature precision={1}/>}
                      />
                      <InformationGridItem
                        title={window.gettext("System type")}
                        value={system.type}
                        component={<Choice choices={systemChoices} namespace="type"/>}
                      />
                    </Grid>
                    <Grid item container spacing={1}>
                      <InformationGridItem
                        title={window.gettext("Highest resistance")}
                        obj={calculations}
                        keyPath="least_favorable_node.accumulated_pressure_drop"
                        component={<Pressure unit="auto"/>}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={window.gettext('Settings')}>
                      <IconButton aria-label="settings" data-test-id="table-settings-button" onClick={() => {
                        setShowSettings(!showSettings)
                      }}>
                        <SettingsIcon/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )
            }
            {/* System settings*/}
            {
              columns !== undefined && showSettings === true && (
                <>
                  <Accordion
                    expanded={settingsAccordionExpanded === 'table-settings-column-visibility'}
                    onChange={handleChangeSettingsAccordion('table-settings-column-visibility')}
                    data-test-id="table-settings-columns-visibility-accordion"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                    >
                      <Typography
                        sx={{width: '20%', flexShrink: 0}}
                      >
                        {window.gettext("Columns")}
                      </Typography>
                      <Typography
                        sx={{color: "text.secondary"}}
                      >
                        {window.gettext("Control what columns should be visible or be hidden")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControl
                        component="fieldset"
                        variant="standard"
                        data-test-id="settings-columns-visibility-form"
                      >
                        <FormGroup>
                          <Grid container spacing={0} key="column_visibility">
                            {
                              columns.filter(
                                (column) => {
                                  // If anything but 'false' we should show it. 'hideable' is a special made up thing
                                  return column.hideable !== false
                                }
                              ).map((column, index) => {
                                let columnSetting = getSettingsForColumn(columnSettings, column.field) || {
                                  'settings': {
                                    'hide': column.hide
                                  }
                                }

                                return (
                                  <Grid
                                    item
                                    xs={4}
                                    key={index}
                                  >
                                    <FormControlLabel
                                      key={column.field}
                                      control={
                                        <Checkbox
                                          data-test-id={"settings-columns-visibility-" + String(column.field)}
                                          name={column.field}
                                          checked={!columnSetting.settings.hide}
                                          onChange={(event) => {
                                            setColumnSettings(changeColumnSettingsProperty(columnSettings, event.target.name, 'hide', !event.target.checked))
                                          }}
                                        />
                                      }
                                      label={column.headerName}
                                    />
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </FormGroup>
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>

                  {/*
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                    >
                      <Typography
                        sx={{width: '20%', flexShrink: 0}}
                      >
                        {window.gettext("View settings")}
                      </Typography>
                      <Typography
                        sx={{color: "text.secondary"}}
                      >
                        {window.gettext("General settings for calculation datagrid")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    </AccordionDetails>
                  </Accordion>
                  */}
                </>
              )
            }
          </Paper>
        </>
      </Drawer>
      {/* Contents of the drawer when not expanded */}
      <AppBar
        position="fixed"
        sx={{top: 'auto', bottom: 0}}
        data-test-id="bottom-app-drawer-handle"
        style={
          {
            zIndex: "auto",
            backgroundColor: '#dddddd',
            color: '#000000'
          }
        }
      >
        <Toolbar variant="regular">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            {/* the left part */}
            <Grid item>
              <Grid
                container
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      setBottomDrawerOpen(!bottomDrawerOpen)
                      adjustTableHeight(true)
                    }}
                    data-test-id="show-bottom-app-drawer-button"
                  >
                    <KeyboardArrowUpIcon/>
                  </IconButton>
                </Grid>
                <Grid item>
                  {/* Tree view toggle button */}
                  <FormControl
                    component="fieldset"
                    variant="standard"
                    data-test-id="settings-view-settings"
                  >
                    {showTreeView !== undefined && (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showTreeView === true}
                            />
                          }
                          onChange={(event) => {
                            const show_tree_view = event.target.checked

                            const treeColumnSettings = columnSettings.find((col) => {
                              return col.field === GroupingColumnDefinition().field
                            })

                            // If there is no tree column settings, add that to the array
                            if (!treeColumnSettings) {
                              columnSettings.push(
                                {
                                  field: GroupingColumnDefinition().field,
                                  settings: {
                                    hide: GroupingColumnDefinition().hide
                                  }
                                }
                              )
                            }

                            setColumnSettings(
                              columnSettings.map((column) => {
                                if (column.field === '__tree_data_group__') {
                                  // replace the settings.hide param
                                  return {
                                    ...column,
                                    ...{
                                      settings: {
                                        ...column.settings,
                                        ...{
                                          'hide': !show_tree_view,
                                        }
                                      }
                                    }
                                  }
                                }

                                return column
                              })
                            )
                          }}
                          label={window.gettext('Tree')}
                        />
                      </FormGroup>
                    )}
                  </FormControl>
                </Grid>
                {/**
                 System history
                 */}
                {(ENABLE_SYSTEM_ITEM_HISTORY === true) && (
                  <>
                    <Grid item>
                      <SystemItemHistory
                        refreshSystemNodes={props.refreshSystemNodesCallback}
                        system={system}
                        nodes={nodes}
                      />
                    </Grid>
                  </>
                )}

                {/**
                 The preset menu
                 */}
                <Grid item>
                  <PresetMenu
                    onSettingsChange={(newSettings) => {
                      applyNewSettings(newSettings)
                    }}
                    onColumnSettingsChange={(value) => {
                      setColumnSettings(value)
                    }}
                  />
                </Grid>
                {/* Quick settings */}
                <Grid item>
                  <QuickSettings
                    onSystemChange={setSystem}
                    system={system}
                  />
                </Grid>
                {/* Write lock*/}
                {system?.write_lock === true && (
                  <>
                    <Grid item>
                      <SystemReadOnlyCheckbox
                        system={system}
                        onSystemChanged={props.onSystemChanged}
                      />
                      {window.gettext('Read only')}
                      <Helper
                        title={
                          window.gettext('System is in read only mode')
                        }
                        ml
                      >
                        {window.gettext('Systems in read only mode can not be altered in any way')}
                      </Helper>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            {/* The right part*/}
            <Grid item>
              <Grid
                container
                alignItems="center"
                spacing={2}
              >
                {/*
          <Tooltip>
            <Switch
              checked={autoRefreshCalculations}
              onChange={(event) => {
                const checked = event.target.checked
                setAutoRefreshCalculations(checked)
                props.autoRefreshCalculationsChangeCallback(checked)
              }}
              color="secondary"
            />
          </Tooltip>
          */}

                <Grid item>
                  {(ENABLE_NODE_SEARCH === true) && (
                    <>
                      <NodeSearch
                        setSearchMode={props.setSearchMode}
                        dataGridApiRef={props.dataGridApiRef}
                        system={system}
                        nodes={nodes}
                      />
                    </>
                  )}
                </Grid>
                <Grid item>
                  <FetchCalculationsButton
                    disabled={props.disableFetch}
                    refreshButtonOnClickCallback={props.refreshSystemCalculationsCallback}
                    validationResults={validationResults}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  )
}
