import {
  Avatar,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Children, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function DesktopMenuItem({
  collapsed,
  icon,
  label,
  to,
  onClick,
  children,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith(to)) {
      setOpen(true);
    } else if (open) {
      setOpen(false);
    }
  }, [location, collapsed]);

  const hasChildren = Children.count(children) > 0;

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (to) {
      navigate(to);
    } else if (hasChildren && !collapsed) {
      setOpen((s) => !s);
    }
  }

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        sx={{
          t: 1,
          px: collapsed ? 1 : 2,
        }}
      >
        <ListItemIcon>
          <Avatar
            sx={{
              bgcolor: "primary.main",
              width: 36,
              height: 36,
            }}
          >
            {icon}
          </Avatar>
        </ListItemIcon>

        {!collapsed && <ListItemText primary={label} />}
      </ListItemButton>

      {hasChildren && (
        <Collapse in={open}>
          <List>{children}</List>
        </Collapse>
      )}
    </>
  );
}
