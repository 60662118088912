export const ACTION_DELETE_NODE = 0
export const ACTION_CREATE_PIPE = 1
export const ACTION_CREATE_VALVE = 2
export const ACTION_CREATE_RADIATOR = 3
export const ACTION_SHOW_COPY_DIALOG = 4
export const ACTION_COPY_NODE = 5

export default class GridRowActionCallbackPayload {
  action = undefined
  focusRowId = undefined

  constructor(action, focusRowId) {
    this.action = action
    this.focusRowId = focusRowId
  }

  setFocusRowId = (id) => {
    this.focusRowId = id
  }
}