import {createContext, useContext, useEffect, useState} from "react";
import IssueService from "../services/IssueService";
import {useParams} from "react-router-dom";
import ChoicesService from "../services/ChoicesService";


export const IssueContext = createContext({
  issue: undefined,
  issue_id: undefined,
  refreshIssue: undefined,
})


export function useIssue() {
  return useContext(IssueContext);
}

export default function IssueProvider(props) {
  const [issue, setIssue] = useState(null)
  const issue_id = useParams().issue_id

  const refreshIssue = () => {
    IssueService.fetch(issue_id).then(setIssue);
  }

  useEffect(() => {
    refreshIssue()
  }, []);

  return (
    <IssueContext.Provider
      value={
        {
          'issue': issue,
          'issue_id': issue_id,
          'refreshIssue': () => {
            refreshIssue()
          },
        }
      }
    >
      {props.children}
    </IssueContext.Provider>
  )
}
