import * as Sentry from "@sentry/react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { subscribe } from "valtio";

import store from "../state";

import { getUserRepresentation } from "../components/user";

export const LOCAL_STORAGE_ACCESS_TOKEN = "access-token";
export const LOCAL_STORAGE_REFRESH_TOKEN = "refresh-token";

const UserContext = createContext(null);

export function useUser() {
  const userContext = useUserContext();

  return userContext;
}

// User context provider hook
function useProvideUserContext() {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [user, setUser] = useState(store.user);

  useEffect(() => {
    // Subscribe to store changes
    const unsubscribe = subscribe(
      store,
      () => {
        if (!store.user) {
          return;
        }

        setUser(store.user);

        const user = {
          id: store.user.id,
          username: store.user.username,
          email: store.user.email,
        };

        Sentry.setUser(user);
      },
      []
    );

    return () => {
      unsubscribe();
    };
  });

  useEffect(() => {
    // If there is no user, and there is what looks like an access token.
    // get the current user from the API, this will also refresh access-tokens if they have a valid refresh-token.
    if (user === null && localStorage.getItem("access-token")) {
      axios.get("/api/users/me/").then((response) => {
        if (response.data === null) {
          return;
        }

        store.user = response.data;
        setUser(response.data);
      });
    } else {
      store.user = user;
    }
  }, [user]);

  const login = useCallback(
    (username, password, { onErrorUsersMe, onRejection, onSuccess } = {}) => {
      return axios
        .post("/api/auth/token/", {
          username: username,
          password: password,
        })
        .then(
          (response) => {
            // Store the tokens in local storage
            localStorage.setItem(
              LOCAL_STORAGE_ACCESS_TOKEN,
              response.data.access
            );
            localStorage.setItem(
              LOCAL_STORAGE_REFRESH_TOKEN,
              response.data.refresh
            );

            // Get the user from the API
            axios.get("/api/users/me/").then(
              (response) => {
                enqueueSnackbar(
                  window
                    .gettext("Welcome %(user)s")
                    .replace("%(user)s", getUserRepresentation(response.data)),
                  {
                    variant: "success",
                  }
                );

                setUser(response.data);

                if (onSuccess) {
                  onSuccess(response.data);
                }
              },
              (error) => {
                if (onErrorUsersMe) {
                  onErrorUsersMe(error);
                } else {
                  console.error("Api users me error.", error);
                }
              }
            );
          },
          (rejection) => {
            if (onRejection) {
              onRejection(rejection);
            }
          }
        );
    },
    [enqueueSnackbar, setUser]
  );

  const logout = useCallback(
    ({ onError, onSuccess } = {}) => {
      // Null user in the global store
      axios
        .post("/api/auth/token/blacklist/", {
          refresh: localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN),
        })
        .then(
          (response) => {
            enqueueSnackbar(
              window
                .gettext("%(user)s logged out")
                .replace("%(user)s", getUserRepresentation(user)),
              {
                variant: "success",
              }
            );

            // Remove the tokens from local storage
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

            setUser(null);

            if (onSuccess) {
              onSuccess(response.data);
            }

            navigate("/login");
          },
          (error) => {
            // Remove the tokens from local storage
            localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
            localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

            if (onError) {
              onError(error);
            } else {
              console.error("Api logout error.", error);
            }
          }
        );
    },
    [enqueueSnackbar, navigate, setUser, user]
  );

  const putUser = useCallback(
    (userId, userData) => {
      console.log('SAVE USER DATA', userId, userData);
      return axios.put(
        '/api/users/me/profile/',
        userData
      )
    },
    [setUser]
  );

  return {
    login,
    logout,
    user,
    putUser
  };
}

// User context provider
export function UserContextProvider({ children, ...props }) {
  const context = useProvideUserContext(props);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}

// User context hook
function useUserContext() {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error(
      "useUserContext has to be used within <UserContextProvider>"
    );
  }

  return context;
}
