import { Alert, Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import ProjectService from "../../../../../../../../components/services/ProjectService";

import { JobsReportDetailTable } from "../../../../../../../../components/project";
import { LayoutView } from "../../../../../../../../layout";

export default function JobsReportDetailView() {
  const { project_id, job_spec_id } = useParams();
  const [searchParams] = useSearchParams();

  const [jobIds, setJobsIds] = useState(undefined);
  const [project, setProject] = useState(null);

  const [showEntrances, setShowEntrances] = useState(false);

  useEffect(() => {
    setJobsIds(searchParams.getAll("job_id"));
  }, [searchParams]);

  useEffect(() => {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }, [project_id]);

  function handleOnChangeShowEntrances(event) {
    setShowEntrances(event.target.checked);
  }

  if (jobIds === undefined) {
    return null;
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Detailed report")}
      submenuItems={
        <FormControlLabel
          control={
            <Checkbox
              checked={showEntrances === true}
              onChange={handleOnChangeShowEntrances}
            />
          }
          label={window.gettext("Show entrances")}
          sx={{
            pl: 1,
          }}
        />
      }
    >
      {jobIds.length === 0 ? (
        <Alert severity="error">
          {window.gettext("Missing reference to job id(s) in URL")}
        </Alert>
      ) : (
        <JobsReportDetailTable
          projectId={project_id}
          jobSpecId={job_spec_id}
          jobIds={jobIds}
          showEntrances={showEntrances}
        />
      )}
    </LayoutView>
  );
}
