import { useSnackbar } from "notistack";
import { cloneElement, useCallback, useEffect, useState } from "react";

import Loading from "../../../layout/Loading";
import SelfCheckJobService from "../../../services/SelfCheckJobService";

let lastRequestTimeStamp = null;

export default function JobWrapper({
  children,
  entityType,
  entityId,
  jobId,
  options,
  project,
  system,
  setOptions,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [jobFullData, setJobFullData] = useState(null);

  const fetchJobFullData = useCallback(
    (fetchJobFullDataCallbackOptions) => {
      if (
        fetchJobFullDataCallbackOptions &&
        fetchJobFullDataCallbackOptions.hasOwnProperty("clearJobData") &&
        fetchJobFullDataCallbackOptions.clearJobData === true
      ) {
        setJobFullData(null);
      }

      /*
       * To encapsulate the sent-at timestamp
       * */
      const requestSentAt = new Date().toISOString();

      lastRequestTimeStamp = requestSentAt;

      const responseCallback = (sentAt) => {
        return (response) => {
          if (!lastRequestTimeStamp) {
            setJobFullData(response);
            return;
          }

          // If this is not the last request that was sent, do not bother.
          if (lastRequestTimeStamp !== sentAt) {
            return;
          }

          setJobFullData(response);
        };
      };

      SelfCheckJobService.full(
        project.id,
        system.id,
        jobId,
        options.get_query_params(entityType, entityId)
      ).then(responseCallback(requestSentAt), (reason) => {
        if (reason.response.status === 404) {
          enqueueSnackbar(
            window.gettext("Error while trying to get job information"),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar(
            window.gettext(
              "Error while trying to get job information, please contact your system administrator"
            ),
            {
              variant: "error",
            }
          );
        }
      });
    },
    [project.id, system.id, jobId, options, enqueueSnackbar]
  );

  useEffect(() => {
    fetchJobFullData();
  }, [options, project.id, system.id, fetchJobFullData]);

  const refreshJobDataCallback = (callbackOptions) => {
    fetchJobFullData(callbackOptions);
  };

  const taskStateChangeCallback = (response, refresh_all_job_data) => {
    let newJobFullData = JSON.parse(JSON.stringify(jobFullData));

    console.log(newJobFullData);
    console.log("taskStateChangeCallback", response);
    // Todo: Should update all the items

    // setting Entities
    // Will try to add or change the task_item from what we get in the response
    // Will also try to change the task to be appropriate
    // This will be an approximation of the real state as it might have changed
    // on the server or some other complex rules.
    newJobFullData.entities = newJobFullData.entities.map((entity) => {
      if (entity.id !== response.entity_id || entity.type !== response.type) {
        return entity;
      }

      let entity_task =
        entity.tasks.find((entity_task) => {
          return entity_task.id === response.id;
        }) || null;

      // The task was not in the list of tasks on the entity. adding it.
      if (entity_task === null) {
        entity.tasks.push(response);
        newJobFullData.tasks.push(response);
      }

      // if no items, add a list
      if (!entity.task_items) {
        entity.task_items = [];
      }

      // What about the new items, we should add them.
      response.items
        .filter((response_item) => {
          return (
            entity.task_items.find((item) => item.id === response_item.id) ===
            undefined
          );
        })
        .forEach((new_item) => {
          entity.task_items.push(new_item);
        });

      // Go through all items and update the task items from response data.
      entity.task_items = entity.task_items.map((entity_task_item) => {
        let task_item =
          response.items.find(
            (response_item) => response_item.id === entity_task_item.id
          ) || null;

        // Copy information in the response to this particual task item
        // Replace the data with new data from response
        if (task_item) {
          entity_task_item.done = task_item.done;
          entity_task_item.done_time = task_item.done_time;
          entity_task_item.comment = task_item.comment;
        }

        return entity_task_item;
      });

      return entity;
    });

    // Loop through and replace the important data
    newJobFullData.tasks.map((job_task) => {
      if (job_task.id === response.id) {
        job_task.done = response.done;
        job_task.comment = response.comment;
        job_task.flag = response.flag;
        job_task.done_time = response.done_time;
      }
      return job_task;
    });

    // Setting the incremented job data
    setJobFullData(newJobFullData);

    // Call to get a full new copy of the jobData.
    if (refresh_all_job_data === false) {
      // Will not get a new copy of the job data in this case.
    } else {
      fetchJobFullData();
    }
  };

  const taskItemStateChangeCallback = (response, refresh_all_job_data) => {
    let newJobFullData = JSON.parse(JSON.stringify(jobFullData));

    // setting Entities
    // Will try to add or change the task_item from what we get in the response
    // Will also try to change the task to be appropriate
    // This will be an approximation of the real state as it might have changed
    // on the server or some other complex rules.
    newJobFullData.entities = newJobFullData.entities.map((entity) => {
      // Ignore things that does not match with the response.
      // Since we loop though all the old versions of the entities
      // we want to leave them intact.
      if (entity.type !== response.type || entity.id !== response.entity_id) {
        return entity;
      }

      // Find the task_item in the entity.
      const task_item =
        entity.task_items.find((task_item) => {
          return response.id === task_item.id;
        }) || undefined;

      // New task, add the response for this task item.
      if (task_item === undefined) {
        entity.task_items.push(response);
      }

      // Go through and change the task_item for the matching task item.
      entity.task_items = entity.task_items.map((task_item) => {
        if (response.id === task_item.id) {
          // Setting done state and comment
          task_item.comment = response.comment;
          task_item.done = response.done;
          task_item.done_time = response.done_time;
        }
        return task_item;
      });

      // Find the task in the entity.
      const entity_task =
        entity.tasks.find((task) => {
          return response.task.id === task.id;
        }) || undefined;

      // New task, add the response for this task item.
      if (entity_task === undefined) {
        // on the task we really just care about spec_id and id.
        entity.tasks.push(response.task);

        // Add to the task definitions on the job data
        // This is where we store the data
        newJobFullData.tasks.push(response.task);
      }

      // Go through and change the task for the matching task on the task-item in the response
      newJobFullData.tasks = newJobFullData.tasks.map((task) => {
        if (response.task.id === task.id) {
          // Setting states on the task from the response.
          task.comment = response.task.comment;
          task.done = response.task.done;
          task.flag = response.task.flag;
        }
        return task;
      });

      return entity;
    });

    // Setting the incremented job data
    setJobFullData(newJobFullData);

    // Call to get a full new copy of the jobData.
    if (refresh_all_job_data === false) {
      // Will not get a new copy of the job data in this case.
    } else {
      fetchJobFullData();
    }
  };

  return (
    <Loading notNull={[jobFullData, system, project]}>
      {cloneElement(children, {
        entityType: entityType,
        entityId: entityId,
        fetchOptions: options,
        jobData: jobFullData,
        options: options,
        project: project,
        refreshJobData: refreshJobDataCallback,
        setJobData: setJobFullData,
        setOptions: setOptions,
        system: system,
        taskItemStateChange: taskItemStateChangeCallback,
        taskStateChange: taskStateChangeCallback,
      })}
    </Loading>
  );
}
