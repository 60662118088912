import { Tooltip } from "@mui/material";

import OpportunitySummary from "../../../../measurement/opportunity/OpportunitySummary";

import { useHierarchyContext, useHierarchyRSContext } from "../../../context";

export function HierarchyRSBottomDrawer() {
  const { latestOpportunity } = useHierarchyRSContext();

  return (
    <>
      {latestOpportunity && (
        <>
          <Tooltip
            title={window.gettext(
              "Current opportunity in use for measurements"
            )}
          >
            <span>
              <OpportunitySummary opportunity={latestOpportunity} hideDate />
            </span>
          </Tooltip>
        </>
      )}
    </>
  );
}
