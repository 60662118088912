import CSVImporter from "../../../../components/import/csv/CSVImporter";

import { LayoutView } from "../../../../layout";

export default function ImportCSVRadiatorValveDimensionView() {
  return (
    <LayoutView header={window.gettext("Import Radiator valve dimensions from CSV document")}>
      <CSVImporter importerApiEndpoint="/api/import/csv/radiator-valve-dimension/" />
    </LayoutView>
  )
}