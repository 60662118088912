import React, {useEffect, useState} from "react";
import {Form, FormFactory} from "../form";
import ChoicesService from "../services/ChoicesService";
import IssueCategoryService from "../services/IssueCategoryService";
import UserService from "../services/UserService";
import ApartmentSearchService from "../services/ApartmentSearchService";
import ProjectService from "../services/ProjectService";
import SystemService from "../services/SystemService";

export default function IssueForm(
  {
    instance,
    onSubmit,
  }
) {
  const [formSections, setFormSections] = useState([])
  const [issueChoices, setIssueChoices] = useState(undefined)
  const [users, setUsers] = useState(undefined)
  const [projects, setProjects] = useState(undefined)
  const [systems, setSystems] = useState(undefined)
  const [issueCategories, setIssueCategories] = useState(undefined)

  useEffect(() => {
    if (
      !projects ||
      !users ||
      !issueChoices ||
      !issueCategories
    ) {
      return
    }

    const form_factory = new FormFactory(
      instance
    );

    setFormSections(
      [
        form_factory.section(
          window.gettext("Issue information"),
          [
            // Note
            form_factory.choicesField("status", window.gettext("Status"), false, "",
              {
                choices: issueChoices.status,
              }
            ),
            // Category
            form_factory.choicesField("category", window.gettext("Category"), false, "",
              {
                choices: form_factory.getChoicesFromObjectList(issueCategories),
                blankOptionEnabled: true,
              }
            ),
            // assigned to
            form_factory.choicesField("assigned_to", window.gettext("Assigned to"), false, "",
              {
                choices: form_factory.getChoicesFromObjectList(
                  users,
                  'label'
                ),
              }
            ),

            form_factory.textArea("note", window.gettext("Note")),
            form_factory.textArea("description", window.gettext("Description")),
          ]
        ),
        form_factory.section(
          window.gettext("Location"),
          [
            // Apartment number
            form_factory.textField("apartment_number", window.gettext("Apartment number"), false, ""),
            // Story
            form_factory.textField("story", window.gettext("Story"), false, ""),
            form_factory.autocompleteField(
              "apartment",
              window.gettext("Apartment"),
              false,
              "",
              {
                searchServiceMethod: ApartmentSearchService.list,
                searchParamName: 'query',
                fetchServiceMethod: ApartmentSearchService.list,
                fetchParamName: 'id',
                currentValueLabel: instance?.apartment_name
              }
            ),
            form_factory.autocompleteField(
              "project",
              window.gettext("Project"),
              false,
              "",
              {
                choices: [
                  {
                    'id': '',
                    'label': '- ' + window.gettext('None') + ' -'
                  }
                ].concat(form_factory.getAutocompleteOptionsListFromChoices(
                    form_factory.getChoicesFromObjectList(projects)
                  )
                ),
                blankOptionEnabled: true,
              }
            ),
          ].concat(
            // Only if we have systems set we shall show the system field
            // This should be the case when the issue has a project set
            !systems ? [] : [
              form_factory.choicesField("system", window.gettext("System"), false, "", {
                choices: form_factory.getChoicesFromObjectList(systems),
                blankOptionEnabled: true,
              }),
            ]
          )
        ),
        form_factory.section(
          window.gettext("Contact information"),
          [
            // Feedback phone number
            form_factory.textField("feedback_phone_number", window.gettext("Feedback phone number"), false, ""),
            // Feedback email
            form_factory.textField("feedback_email", window.gettext("Feedback email"), false, ""),
          ]
        )
      ]
    )
  }, [
    instance,
    users,
    issueChoices,
    issueCategories,
    projects,
    systems,
  ]);

  useEffect(() => {
    ChoicesService.issueChoices().then(setIssueChoices)
    IssueCategoryService.list().then(setIssueCategories)
    UserService.list().then(setUsers)
    ProjectService.list().then(setProjects)
  }, [])

  useEffect(() => {
    if (!instance?.project) {
      return
    }

    SystemService.list(
      instance.project
    ).then(setSystems)
  }, [instance.project]);

  return (
    <Form
      formSections={formSections}
      onSubmit={onSubmit}
    />

  )
}
