import IdColumnDefinition from "./column_definitions/columns/IdColumnDefinition";
import NameColumnDefinition from "./column_definitions/columns/NameColumnDefinition";
import ParentColumnDefinition from "./column_definitions/columns/ParentColumnDefinition";
import AddressColumnDefinition from "./column_definitions/columns/AddressColumnDefinition";
import RoomColumnDefinition from "./column_definitions/columns/RoomColumnDefinition";
import SurveyingApartmentNumberColumnDefinition
  from "./column_definitions/columns/SurveyingAparmentNumberColumnDefinition";
import LocalApartmentNumberColumnDefinition from "./column_definitions/columns/LocalAparmentNumberColumnDefinition";
import ConnectionColumnDefinition from "./column_definitions/columns/ConnectionColumnDefinition";
import DimensionColumnDefinition from "./column_definitions/columns/DimensionColumnDefinition";
import PipeTypeColumnDefinition from "./column_definitions/columns/PipeTypeColumnDefinition";
import ValveTypeColumnDefinition from "./column_definitions/columns/ValveTypeColumnDefinition";
import LengthColumnDefinition from "./column_definitions/columns/LengthColumnDefinition";
import ActionsColumnDefinition from "./column_definitions/columns/ActionsColumnDefinition";
import KVSColumnDefinition from "./column_definitions/columns/KVSColumnDefinition";
import RadiatorColumnDefinition from "./column_definitions/columns/RadiatorColumnDefinition";
import ExactResistanceColumnDefinition from "./column_definitions/columns/ExactResistanceColumnDefinition";
import AdditionalResistanceColumnDefinition from "./column_definitions/columns/AdditionalResistanceColumnDefinition";
import EffectColumnDefinition from "./column_definitions/columns/EffectColumnDefinition";
import FlowColumnDefinition from "./column_definitions/columns/FlowColumnDefinition";
import PressureDropColumnDefinition from "./column_definitions/columns/PresureDropColumnDefinition";
import AvailablePressureColumnDefinition from "./column_definitions/columns/AvailablePressureColumnDefinition";
import KVValueColumnDefinition from "./column_definitions/columns/KVValueColumnDefinition";
import NippleColumnDefinition from "./column_definitions/columns/NippleColumnDefinition";
import PressureDropPerMeterColumnDefinition from "./column_definitions/columns/PressureDropPerMeterColumnDefinition";
import SpeedColumnDefinition from "./column_definitions/columns/SpeedColumnDefinition";
import EffectPercentageColumnDefinition from "./column_definitions/columns/EffectPercentageColumnDefinition";
import FlagColumnDefinition from "./column_definitions/columns/FlagColumnDefinition";
import NoteColumnDefinition from "./column_definitions/columns/NoteColumnDefinition";
import CustomFlowColumnDefinition from "./column_definitions/columns/CustomFlowColumnDefinition";

function getColumnDefinitions(
  actionCallbackFunction,
  nodes,
  pipeDimensions,
  pipeTypes,
  valveTypes,
  systemRooms,
  radiatorValveTypes,
  radiatorValveDimensions,
) {
  /**
   * When a column is added, it needs to be added in the SystemBuildPresetColumnSettings model
   */
  return [
    ActionsColumnDefinition(actionCallbackFunction, nodes),
    FlagColumnDefinition(),
    IdColumnDefinition(),
    NameColumnDefinition(nodes),
    ParentColumnDefinition(nodes),
    AddressColumnDefinition(),
    RoomColumnDefinition(systemRooms),
    LocalApartmentNumberColumnDefinition(),
    SurveyingApartmentNumberColumnDefinition(),
    ConnectionColumnDefinition(),
    PipeTypeColumnDefinition(pipeTypes),
    DimensionColumnDefinition(pipeDimensions, radiatorValveDimensions),
    ValveTypeColumnDefinition(valveTypes, radiatorValveTypes),
    LengthColumnDefinition(),
    KVSColumnDefinition(),
    CustomFlowColumnDefinition(),
    RadiatorColumnDefinition(nodes),
    ExactResistanceColumnDefinition(),
    AdditionalResistanceColumnDefinition(),
    EffectPercentageColumnDefinition(),
    NoteColumnDefinition(),
    // Calculated fields
    EffectColumnDefinition(),
    FlowColumnDefinition(),
    PressureDropColumnDefinition(),
    AvailablePressureColumnDefinition(),
    KVValueColumnDefinition(),
    NippleColumnDefinition(),
    PressureDropPerMeterColumnDefinition(),
    SpeedColumnDefinition(),
  ]
}

export default getColumnDefinitions