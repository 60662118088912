import { Chip, Tooltip } from "@mui/material";

export function ChipTaskItemSpecProjectRow({ taskItemSpec }) {
  return (
    <>
      {taskItemSpec.project_row && (
        <Tooltip
          title={window.gettext("Connected to project row of this type")}
        >
          <Chip label={taskItemSpec.project_row.label} />
        </Tooltip>
      )}
    </>
  );
}
