import PropTypes from 'prop-types';
import {CssBaseline} from '@mui/material';
import {createTheme, StyledEngineProvider} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";
import palette from './palette';
import {svSE} from "@mui/material/locale";

ThemeConfig.propTypes = {
  children: PropTypes.node
};

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({xs, sm, md, lg}) {
  return {
    '@media (max-width:600px)': {
      fontSize: pxToRem(xs)
    },
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm)
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md)
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg)
    }
  };
}

export default function ThemeConfig({children}) {
  const theme = createTheme(
    {
      palette: palette,
      typography: {
        fontFamily: ['Open Sans', 'Arial'].join(","),
        h1: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 35, sm: 35, md: 35, lg: 35}),
        },
        h2: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 30, sm: 30, md: 30, lg: 30}),
        },
        h3: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 25, sm: 25, md: 25, lg: 25}),
        },
        h4: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 20, sm: 20, md: 20, lg: 20}),
        },
        h5: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 18, sm: 18, md: 18, lg: 18}),
        },
        h6: {
          fontWeight: 900,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 15, sm: 15, md: 15, lg: 15}),
        },
        subheading: {
          fontSize: '1.3rem',
          fontWeight: 600,
          fontFamily: ['Raleway', 'Arial Bold'].join(","),
          ...responsiveFontSizes({xs: 15, sm: 15, md: 15, lg: 15}),
        },
      },
    },
    svSE,
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
