import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import AutocompleteInput from "../../edit_cell/AutocompleteInput";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import WarningIcon from '@mui/icons-material/Warning';
import {Alert, Tooltip} from "@mui/material";

export default function DimensionColumnDefinition(
  pipeDimensions,
  radiatorValveDimensions,
) {
  return {
    field: 'dimension',
    width: getColumnWidth('dimension'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Dimension'),
    editable: true,
    valueGetter: (params => {
      // Get Name of the dimension,
      return ValueGetter(
        [
          ValueGetterConf('valve', 'node.item.type.dimension.name'),
          ValueGetterConf('pipe', 'node.item.dimension.name'),
          ValueGetterConf('radiator', 'node.item.valve_dimension.dimension'),
        ]
      ).get(params.row) || null
    }),
    renderEditCell: (params) => {
      let options
      let label

      // Default label
      label = window.gettext('Dimension')

      // Validation
      if (params.row.node.type === 'pipe') {
        // If there is no type (pipe_type) on pipe, we abort here and show an error message.
        // Users should NOT be able to choose dimension if the type is not selected.
        if (params.row.node.item.type === null) {
          return (
            <div
              data-error="pipe-need-type-before-dimension"
              style={{width: '100%'}}
            >
              <Tooltip
                title={window.gettext('Type needs to be chosen before you can choose a dimension')}
              >
                <Alert severity="info">
                  {window.gettext('No type')}
                </Alert>
              </Tooltip>
            </div>
          )
        }
      }

      // If this type is a Radiator, use special dimensions
      if (params.row.node.type === 'radiator') {
        options = radiatorValveDimensions.map(
          (option) => {
            return {
              'id': option.id,
              'name': option.dimension,
            }
          }
        )
      } else {
        // Other cases, use pipeDimensions
        options = pipeDimensions
      }

      // If in case we are on a pipe we need to filter out the options depending on what type the user has chosen
      if (params.row.node.type === 'pipe') {
        // Only show options that have the same type as the current node->item
        options = pipeDimensions.filter(
          (pipeDimension) => {
            return pipeDimension.type.id === params.row.node.item.type.id
          }
        )
      }

      // Getting the current selected id
      const selectedOptionId = ValueGetter(
        [
          ValueGetterConf('valve', 'node.item.type.dimension.id'),
          ValueGetterConf('pipe', 'node.item.dimension.id'),
          ValueGetterConf('radiator', 'node.item.valve_dimension.id'),
        ]
      ).get(params.row) || undefined


      return <AutocompleteInput
        {...params}
        selectedOptionId={selectedOptionId}
        options={options}
        label={label}
      />
    }
  }
}