import BaseInfoTabSection from "../../../../components/layout/information/base_info/BaseInfoTabSection";
import BaseInfoTabSectionItem from "../../../../components/layout/information/base_info/BaseInfoTabSectionItem";
import TimeDuration from "../../../../components/values/TimeDuration";
import {LayoutCard} from "../../../../layout";
import IssueSpentTimeEntriesTable from "../../spent_time/IssueSpentTimeEntriesTable";
import IssueSpentTimeEntriesByUserTable from "../../spent_time/IssueSpentTimeEntriesByUserTable";
import IssueSpentTimeAddEntryForm from "../../spent_time/IssueSpentTimeAddEntryForm";
import {useIssue} from "../../../../components/issue_tracker/IssueContext";
import {useIssueSpentTime} from "../../../../components/issue_tracker/IssueSpentTimeContext";

export default function IssueSpentTime({}) {
  // Spent time entries
  const {issue} = useIssue()
  const {spentTimeEntries, refreshSpentTimeEntries} = useIssueSpentTime()

  return (
    <>
      <BaseInfoTabSection
        title={window.gettext("Overview")}
      >
        <BaseInfoTabSectionItem
          title={window.gettext("Spent time")}
        >
          <TimeDuration
            minutes={issue?.spent_time_minutes}
          />
        </BaseInfoTabSectionItem>
      </BaseInfoTabSection>
      <LayoutCard
        title={window.gettext("Add spent time")}
      >
        <IssueSpentTimeAddEntryForm/>
      </LayoutCard>
      {
        (!!spentTimeEntries && spentTimeEntries?.length > 0) && (
          <>
            <LayoutCard
              title={window.gettext("Spent time entries")}
            >
              <IssueSpentTimeEntriesTable
                entries={spentTimeEntries}
              />
            </LayoutCard>
            <LayoutCard
              title={window.gettext("Spent time by user")}
            >
              <IssueSpentTimeEntriesByUserTable
                entries={spentTimeEntries}
              />
            </LayoutCard>
          </>
        )
      }
    </>
  )
}