export default class BaseGetter {
  getKey = (params) => {
    return undefined
  }
  getValue = (params) => {
    return undefined
  }

  getDataObject = (params) => {
    const key = this.getKey(params)
    const value = this.getValue(params)

    if (key === undefined) {
      return undefined
    }

    let data = {}
    data[key] = value

    return data
  }
}