import {useEffect, useState} from "react";
import WarningIcon from '@mui/icons-material/Warning';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import FlagIcon from '@mui/icons-material/Flag';
import AddAlertIcon from "@mui/icons-material/AddAlert";

export default function Flag(props) {
  let [flagType, setFlagType] = useState()
  let [color, setColor] = useState('inherit')

  useEffect(() => {
    setFlagType(props.type)
  }, [props.type])

  useEffect(() => {
    if (!props.color) {
      return
    }

    setColor(props.color)
  }, [props.color])


  return (
    <>
      {(flagType) && (
        <>
          {flagType === "warning" && (
            <WarningIcon color={color} fontSize={props?.fontSize || ""}/>
          )}
          {flagType === "scissors" && (
            <ContentCutIcon color={color} fontSize={props?.fontSize || ""}/>
          )}
          {flagType === "flag" && (
            <FlagIcon color={color} fontSize={props?.fontSize || ""}/>
          )}
          {flagType === 'move_up' && (
            <MoveUpIcon color={color} fontSize={props?.fontSize || ""}/>
          )}
          {flagType === 'add_alert' && (
            <AddAlertIcon color={color} fontSize={props?.fontSize || ""}/>
          )}
        </>
      )}
    </>
  )
}

Flag.flags = {
  'warning': 'warning',
  'scissors': 'scissors',
  'flag': 'flag',
  'move_up': 'move_up',
  'add_alert': 'add_alert',
}