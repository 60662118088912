import BaseService from "./BaseService";

class SelfCheckJobFullService extends BaseService {
}

const selfCheckJobFullService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'full',
  ]
)

const selfCheckJobCopyInstalledValuesService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'copy-calculated-to-installed-values',
  ]
)

const selfCheckJobReportCalculationService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'report/calculation',
  ]
)

const selfCheckJobReportMeasurementService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'report/measurement/profile',
  ]
)

const selfCheckJobReportMeasurementOpportunitiesService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'report/measurement-opportunities/profile',
  ]
)

const baseSelfCheckJobService = new SelfCheckJobFullService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
  ]
)


class SelfCheckJobService extends BaseService {
  full = (project_id, system_id, job_id, ...args) => {
    return selfCheckJobFullService.fetch(
      ...[
        project_id,
        system_id,
        job_id,
      ],
      ...args
    )
  }

  freeze = (project_id, system_id, job_id) => {
    return baseSelfCheckJobService.fetch(
      ...[
        project_id,
        system_id,
        job_id,
        'freeze-system-calculation',
      ]
    )
  }

  userDefinedValues = (project_id, system_id, job_id, data) => {
    return baseSelfCheckJobService.update(
      data,
      ...[
        project_id,
        system_id,
        job_id,
        'user-defined-values',
      ]
    )
  }

  copy_all_calculated_values_to_installed_values = (project_id, system_id, job_id) => {
    return selfCheckJobCopyInstalledValuesService.post(
      {},
      project_id,
      system_id,
      job_id
    )
  }

  report_calculation = (project_id, system_id, job_id, entityType, entityId) => {
    let query_string = undefined

    let params = []

    if (entityType && entityId) {
      params.push('start_point_' + entityType + '=' + String(entityId))
    }

    if(params.length > 0){
      query_string = '?' + params.join('&')
    }

    return selfCheckJobReportCalculationService.fetch(
      project_id,
      system_id,
      job_id,
      query_string || undefined,
    )
  }

  report_measurement = (project_id, system_id, job_id, profile_id) => {
    return selfCheckJobReportMeasurementService.fetch(
      project_id,
      system_id,
      job_id,
      profile_id,
    )
  }

  report_measurement_opportunities = (project_id, system_id, job_id, profile_id) => {
    return selfCheckJobReportMeasurementOpportunitiesService.fetch(
      project_id,
      system_id,
      job_id,
      profile_id,
    )
  }
}

export default new SelfCheckJobService('/api/',
  [
    'projects',
    'systems',
    'self-check/specs',
    'jobs',
  ]
)