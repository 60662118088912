import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../components/layout/LoadingIndicator";
import JobWrapper from "../../../../../../components/project/self_check/job/JobWrapper";
import ProjectService from "../../../../../../components/services/ProjectService";
import SystemService from "../../../../../../components/services/SystemService";

import {
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_SYSTEM,
} from "../../../../../../components/project/self_check/job/entity";
import { JobHierarchy } from "../../../../../../components/project/self_check/job/hierarchy";
import { SelfCheckOptions } from "../../../../../../components/project/self_check/job/utils/SelfCheckOptions";
import {
  DEFAULT_MAX_WIDTH,
  LayoutView,
  MOBILE_MAX_WIDTH,
} from "../../../../../../layout";

export default function JobDetailsHierarchyView() {
  const { project_id, system_id, job_id } = useParams();
  const [searchParams] = useSearchParams();

  const [project, setProject] = useState(null);
  const [system, setSystem] = useState(null);

  const [entityId, setEntityId] = useState(undefined);
  const [entityType, setEntityType] = useState(undefined);

  const [options, setOptions] = useState(undefined);

  useEffect(fetchProject, [project_id]);
  useEffect(fetchSystem, [system_id]);

  useEffect(() => {
    let entityId = searchParams.get("entity_id");
    let entityType = searchParams.get("entity_type") || ENTITY_TYPE_SYSTEM;
    let visitDayId = searchParams.get("visit_day");

    const options = new SelfCheckOptions(true, undefined, false);

    if (entityType === ENTITY_TYPE_SYSTEM) {
      entityId = system_id;

      options.ignore_nodes = true;
      options.ignore_potable_water_entities = true;
    }

    if (entityType === ENTITY_TYPE_ENTRANCE) {
      options.ignore_nodes = true;
      options.ignore_potable_water_entities = true;
    }

    if (visitDayId) {
      options.apartment_visit_day = visitDayId;
    }

    setEntityType(entityType);
    setEntityId(entityId);

    setOptions(options);
  }, [
    searchParams.get("entity_id"),
    searchParams.get("entity_type"),
    searchParams.get("visit_day"),
  ]);

  function fetchProject() {
    ProjectService.fetch(project_id).then(setProject);
  }

  function fetchSystem() {
    SystemService.fetch(project_id, system_id).then(setSystem);
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Self Check")}
      maxWidth={MOBILE_MAX_WIDTH}
    >
      {!project || !system || !options ? (
        <LoadingIndicator />
      ) : (
        <JobWrapper
          entityType={entityType}
          entityId={parseInt(entityId)}
          jobId={parseInt(job_id)}
          options={options}
          project={project}
          system={system}
          setOptions={setOptions}
        >
          <JobHierarchy />
        </JobWrapper>
      )}
    </LayoutView>
  );
}
