import BaseService from "../BaseService";

class SelfCheckJobMeasurementService extends BaseService {
}

export default new SelfCheckJobMeasurementService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurements',
  ]
)