import React, {useEffect, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";

export default function AutocompleteInput(props) {
  const {id, api, field, options, label, selectedOptionId} = props;

  const handleChange = function (event, newValue) {
    // Change the value we display
    if (newValue === undefined) {
      return
    }

    let newValueId = undefined

    if(newValue){
      newValueId = newValue.id
    }

    api.setEditCellValue({id, field, value: newValueId}, event)
  }

  const value = options.find((option) => {
    return option.id === selectedOptionId
  }) || null

  return (
    <>
      <Autocomplete
        id="auto"
        options={options}
        value={value}
        fullWidth={true}
        onChange={handleChange}
        getOptionLabel={(option) => {
          return option.name
        }}
        openOnFocus={true}
        renderInput={
          (params) => <TextField
            {...params}
            label={label}
            autoFocus
          />
        }
      />
    </>
  )
}