import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";

import ProjectList from "../../../components/project/ProjectList";
import UserPermission from "../../../components/user/UserPermission";
import getUserHasPermission from "../../../components/user/getUserHasPermission";
import store from "../../../state";

import { LayoutView } from "../../../layout";

export default function ProjectsBasicListView(props) {
  return (
    <LayoutView
      header={window.gettext("Projects")}
      submenuItems={
        getUserHasPermission(
          store.user,
          UserPermission.permissions.project_add
        ) && (
          <Button
            data-test-id="project-create-link"
            variant="contained"
            component={RouterLink}
            to="/projects/create/"
          >
            {window.gettext("Create project")}
          </Button>
        )
      }
    >
      <ProjectList />
    </LayoutView>
  );
}
