const build_django_permission = (model, action, app_name) => {
  app_name = app_name || 'drp'
  return app_name + '.' + action + '_' + model
}

const action_view = 'view'
const action_add = 'add'
const action_delete = 'delete'
const action_change = 'change'

const model_measurement_opportunity = 'measurementopportunity'
const model_system_heat_curve = 'systemheatcurve'
const model_system_heat_curve_point = 'systemheatcurvepoint'
const model_system = 'system'
const model_node = 'node'
const model_radiator = 'radiator'
const model_job = 'job'
const model_project = 'project'
const model_job_spec = 'jobspec'
const model_project_row = 'projectrow'
const model_project_membership = 'projectmembership'
const model_auth_user = 'user'
const model_installed_value = 'installedvalue'
const model_measurement_report_profile = 'measurementreportprofile'
const model_measurement_report_profile_blacklisted_entity = 'measurementreportprofileblacklistedentity'
const model_measurement_report_profile_hidden_entity = 'measurementreportprofilehiddenentity'
const model_installation_report_profile = 'installationreportprofile'
const installation_report_profile_blacklisted_comment = 'installationreportprofileblacklistedcomment'
const model_apartment = 'apartment'
const model_issue = 'issue'
const model_issue_comment = 'issuecomment'
const model_issue_file = 'issuefile'
const model_issue_spent_time = 'issuespenttime'

export const DjangoPermissions = {

  // Measurement Opportunity
  measurement_opportunity_add: build_django_permission(model_measurement_opportunity, action_add),
  measurement_opportunity_delete: build_django_permission(model_measurement_opportunity, action_delete),
  measurement_opportunity_view: build_django_permission(model_measurement_opportunity, action_view),
  measurement_opportunity_change: build_django_permission(model_measurement_opportunity, action_change),

  // System Heat Curve
  system_heat_curve_add: build_django_permission(model_system_heat_curve, action_add),
  system_heat_curve_delete: build_django_permission(model_system_heat_curve, action_delete),
  system_heat_curve_view: build_django_permission(model_system_heat_curve, action_view),
  system_heat_curve_change: build_django_permission(model_system_heat_curve, action_change),

  // System Heat Curve Point
  system_heat_curve_point_add: build_django_permission(model_system_heat_curve_point, action_add),
  system_heat_curve_point_delete: build_django_permission(model_system_heat_curve_point, action_delete),
  system_heat_curve_point_view: build_django_permission(model_system_heat_curve_point, action_view),
  system_heat_curve_point_change: build_django_permission(model_system_heat_curve_point, action_change),

  // Project
  project_add: build_django_permission(model_project, action_add),
  project_delete: build_django_permission(model_project, action_delete),
  project_view: build_django_permission(model_project, action_view),
  project_change: build_django_permission(model_project, action_change),

  project_change_stage: 'change_project_stage',
  project_view_list_of_other_users_projects: 'view_list_of_other_users_projects',


  // System
  system_add: build_django_permission(model_system, action_add),
  system_delete: build_django_permission(model_system, action_delete),
  system_view: build_django_permission(model_system, action_view),
  system_change: build_django_permission(model_system, action_change),

  // Radiator
  radiator_add: build_django_permission(model_radiator, action_add),
  radiator_delete: build_django_permission(model_radiator, action_delete),
  radiator_view: build_django_permission(model_radiator, action_view),
  radiator_change: build_django_permission(model_radiator, action_change),
  radiator_view_statistics: 'drp.view_statistics_radiators',

  // Radiator
  node_add: build_django_permission(model_node, action_add),
  node_delete: build_django_permission(model_node, action_delete),
  node_view: build_django_permission(model_node, action_view),
  node_change: build_django_permission(model_node, action_change),

  system_copy: 'copy_system',
  system_view_calculation: 'view_calculation',
  system_change_stage: 'change_system_stage',
  system_remove_write_lock: 'change_system_stage',
  system_remove_write_lock_within_cooldown_period: 'change_system_stage',

  // Job
  job_add: build_django_permission(model_job, action_add),
  job_delete: build_django_permission(model_job, action_delete),
  job_view: build_django_permission(model_job, action_view),
  job_change: build_django_permission(model_job, action_change),

  job_copy_all_calculated_values_to_installed_values: 'copy_calculated_values_to_installed_values',

  // Job Spec
  job_spec_add: build_django_permission(model_job_spec, action_add),
  job_spec_delete: build_django_permission(model_job_spec, action_delete),
  job_spec_view: build_django_permission(model_job_spec, action_view),
  job_spec_change: build_django_permission(model_job_spec, action_change),

  // Project Row
  project_row_add: build_django_permission(model_project_row, action_add),
  project_row_delete: build_django_permission(model_project_row, action_delete),
  project_row_view: build_django_permission(model_project_row, action_view),
  project_row_change: build_django_permission(model_project_row, action_change),

  // project_membership
  project_membership_add: build_django_permission(model_project_membership, action_add),
  project_membership_delete: build_django_permission(model_project_membership, action_delete),
  project_membership_view: build_django_permission(model_project_membership, action_view),
  project_membership_change: build_django_permission(model_project_membership, action_change),

  // auth_user
  auth_user_add: build_django_permission(model_auth_user, action_add, 'auth'),
  auth_user_delete: build_django_permission(model_auth_user, action_delete, 'auth'),
  auth_user_view: build_django_permission(model_auth_user, action_view, 'auth'),
  auth_user_change: build_django_permission(model_auth_user, action_change, 'auth'),

  // installed_values
  installed_value_add: build_django_permission(model_installed_value, action_add),
  installed_value_delete: build_django_permission(model_installed_value, action_delete),
  installed_value_view: build_django_permission(model_installed_value, action_view),
  installed_value_change: build_django_permission(model_installed_value, action_change),

  // Measurement Report Profile
  measurement_report_profile_add: build_django_permission(model_measurement_report_profile, action_add),
  measurement_report_profile_delete: build_django_permission(model_measurement_report_profile, action_delete),
  measurement_report_profile_view: build_django_permission(model_measurement_report_profile, action_view),
  measurement_report_profile_change: build_django_permission(model_measurement_report_profile, action_change),

  // measurement_report_profile_blacklisted_entity
  measurement_report_profile_blacklisted_entity_add: build_django_permission(model_measurement_report_profile_blacklisted_entity, action_add),
  measurement_report_profile_blacklisted_entity_delete: build_django_permission(model_measurement_report_profile_blacklisted_entity, action_delete),
  measurement_report_profile_blacklisted_entity_view: build_django_permission(model_measurement_report_profile_blacklisted_entity, action_view),
  measurement_report_profile_blacklisted_entity_change: build_django_permission(model_measurement_report_profile_blacklisted_entity, action_change),

  // Measurement report profile hidden entity
  measurement_report_profile_hidden_entity_add: build_django_permission(model_measurement_report_profile_hidden_entity, action_add),
  measurement_report_profile_hidden_entity_delete: build_django_permission(model_measurement_report_profile_hidden_entity, action_delete),
  measurement_report_profile_hidden_entity_view: build_django_permission(model_measurement_report_profile_hidden_entity, action_view),
  measurement_report_profile_hidden_entity_change: build_django_permission(model_measurement_report_profile_hidden_entity, action_change),

  // Installation report profile
  installation_report_profile_add: build_django_permission(model_installation_report_profile, action_add),
  installation_report_profile_delete: build_django_permission(model_installation_report_profile, action_delete),
  installation_report_profile_view: build_django_permission(model_installation_report_profile, action_view),
  installation_report_profile_change: build_django_permission(model_installation_report_profile, action_change),

  // Installation report profile blacklisted entity
  installation_report_profile_blacklisted_comment_add: build_django_permission(installation_report_profile_blacklisted_comment, action_add),
  installation_report_profile_blacklisted_comment_delete: build_django_permission(installation_report_profile_blacklisted_comment, action_delete),
  installation_report_profile_blacklisted_comment_view: build_django_permission(installation_report_profile_blacklisted_comment, action_view),
  installation_report_profile_blacklisted_comment_change: build_django_permission(installation_report_profile_blacklisted_comment, action_change),

  // Apartment
  apartment_add: build_django_permission(model_apartment, action_add),
  apartment_delete: build_django_permission(model_apartment, action_delete),
  apartment_view: build_django_permission(model_apartment, action_view),
  apartment_change: build_django_permission(model_apartment, action_change),

  // Issue
  issue_add: build_django_permission(model_issue, action_add),
  issue_delete: build_django_permission(model_issue, action_delete),
  issue_view: build_django_permission(model_issue, action_view),
  issue_change: build_django_permission(model_issue, action_change),

  // Issue comment
  issue_comment_add: build_django_permission(model_issue_comment, action_add),
  issue_comment_delete: build_django_permission(model_issue_comment, action_delete),
  issue_comment_view: build_django_permission(model_issue_comment, action_view),
  issue_comment_change: build_django_permission(model_issue_comment, action_change),

  // Issue file
  issue_file_add: build_django_permission(model_issue_file, action_add),
  issue_file_delete: build_django_permission(model_issue_file, action_delete),
  issue_file_view: build_django_permission(model_issue_file, action_view),
  issue_file_change: build_django_permission(model_issue_file, action_change),

  // Issue spent time
  issue_spent_time_add: build_django_permission(model_issue_spent_time, action_add),
  issue_spent_time_delete: build_django_permission(model_issue_spent_time, action_delete),
  issue_spent_time_view: build_django_permission(model_issue_spent_time, action_view),
  issue_spent_time_change: build_django_permission(model_issue_spent_time, action_change),
}