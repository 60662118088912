import { createContext, useContext, useEffect, useState } from "react";

const LayoutToolbarContext = createContext(null);

// Layout toolbar context provider hook
export function useProvideLayoutToolbarContext({ placement }) {
  const [toolbarExist, setToolbarExist] = useState(false);
  const [toolbarElement, setToolbarElement] = useState(null);

  useEffect(() => {
    if (toolbarElement === null) {
      // Remove toolbar element
      placement.clear();
      setToolbarExist(false);

      return;
    }

    // Set toolbar element
    placement.setElement(toolbarElement);
    setToolbarExist(true);
  }, [toolbarElement]);

  function hideToolbar() {
    placement.setHidden(true);
  }

  function setToolbar(element, hidden) {
    if (hidden !== undefined) {
      // Set hidden state if provided
      placement.setHidden(hidden);
    }

    if (element === toolbarElement) {
      return;
    }

    setToolbarElement(element);
  }

  function showToolbar() {
    placement.setHidden(false);
  }

  function onToolbarMount() {
    // Reset hidden state of placement
    placement.resetHidden();
  }

  function cleanup() {
    // Remove toolbar element
    setToolbarElement(null);
    // Reset hidden state of placement
    placement.resetHidden();
  }

  return {
    toolbarExist,
    toolbarHidden: placement.hidden,
    toolbarElement,

    hideToolbar,
    onToolbarMount,
    setToolbar,
    showToolbar,

    cleanup,
  };
}

// Layout toolbar context provider
export function LayoutToolbarContextProvider({ children, placement }) {
  const context = useProvideLayoutToolbarContext({ placement });

  return (
    <LayoutToolbarContext.Provider value={context}>
      {children}
    </LayoutToolbarContext.Provider>
  );
}

// Layout toolbar context hook
export function useLayoutToolbarContext() {
  const context = useContext(LayoutToolbarContext);

  if (!context) {
    throw new Error(
      "useLayoutToolbarContext has to be used within <LayoutToolbarContextProvider>"
    );
  }

  return context;
}
