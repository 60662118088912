import PropTypes from "prop-types";
import DateTime from "../../../../../values/DateTime";
import Temperature from "../../../../../values/Temperature";
import {Box, Grid} from "@mui/material";

export default function OpportunitySummary({opportunity, hideDate}) {
  return (
    <>
      <Grid
        container
        spacing={2}
      >
        {!(hideDate === true) && (
          <>
            <Grid item>
              <DateTime value={opportunity?.created}/>
            </Grid>
          </>
        )}
        <Grid item>
          <Temperature value={parseFloat(opportunity?.outside_temperature)} precision={1}/>
        </Grid>
        <Grid item>
          {parseInt(opportunity?.outflow_temperature)}
          <span>/</span>
          {parseInt(opportunity?.return_temperature)}
        </Grid>
      </Grid>
    </>
  )
}