import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import reportWebVitals from './reportWebVitals';
import {BrowserTracing} from "@sentry/tracing";
import {BrowserRouter as Router} from 'react-router-dom';

if (typeof window.gettext === 'undefined') {
  window.gettext = function (str) {
    return str;
  }
}

if (typeof window.ngettext === 'undefined') {
  window.gettext = function (str, str_plural, number) {
    return str;
  }
}

if (typeof window.interpolate === 'undefined') {
  window.gettext = function (formats, data) {
    return formats[0];
  }
}

// If no django, create one.
if (typeof window.django === 'undefined') {
  window.django = {};
}


// Getting the sentry dsn url from environment

let sentry_dsn = undefined;
let app_tag_name = undefined;


try {
  if (process !== undefined) {
    sentry_dsn = process.env.REACT_APP_SENTRY_DSN
    app_tag_name = String(process.env.REACT_APP_TAG_NAME || "unknown")
  }
} catch (e) {
  console.warn('Could not find process to get environment variables')
}

// Set up sentry event reporting, if there is a sentry_dsn configured.
if (sentry_dsn !== undefined && app_tag_name !== undefined) {
  Sentry.init({
    dsn: sentry_dsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,

    release: app_tag_name,

    // Ignore some errors
    ignoreErrors : [
      // ResizeObserver loop error.
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

