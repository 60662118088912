import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../components/layout/LoadingIndicator";
import StyledTableRow from "../../../../../components/layout/StyledTableRow";
import ProjectService from "../../../../../components/services/ProjectService";
import SystemService from "../../../../../components/services/SystemService";
import UserPermission from "../../../../../components/user/UserPermission";

import { LayoutCard, LayoutView } from "../../../../../layout";

export default function SystemDetailView() {
  const { system_id, project_id } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState(null);
  const [system, setSystem] = useState(null);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (project_id === undefined || system_id === undefined) {
      return;
    }

    // Fetch systems for project
    SystemService.fetch(project_id, system_id).then(setSystem);
    ProjectService.fetch(project_id).then(setProject);
  }, [project_id, system_id]);

  function handleConfirmDeleteSystem() {
    SystemService.destroy(project_id, system_id).then(() => {
      navigate("/projects/" + String(project_id) + "/");
    });
  }

  const handleCloseConfirmDeleteDialogOpen = () => {
    setConfirmDeleteDialogOpen(true);
  };

  const handleCloseConfirmDeleteDialogClose = () => {
    setConfirmDeleteDialogOpen(false);
  };

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 1,
      }}
      header={window.gettext("System Details")}
      submenuItems={
        <>
          <UserPermission permission={UserPermission.permissions.system_delete}>
            <Button
              onClick={handleCloseConfirmDeleteDialogOpen}
              startIcon={<DeleteIcon />}
              color="error"
              data-test-id="system-delete-button"
              variant="outlined"
            >
              {window.gettext("Delete")}
            </Button>
          </UserPermission>

          <UserPermission permission={UserPermission.permissions.system_change}>
            <Button
              component={RouterLink}
              startIcon={<EditIcon />}
              data-test-id="system-edit-button"
              variant="outlined"
              to={
                "/projects/" +
                String(project_id) +
                "/systems/" +
                String(system_id) +
                "/edit/"
              }
            >
              {window.gettext("Edit")}
            </Button>
          </UserPermission>

          <UserPermission
            permission={UserPermission.permissions.system_view_calculation}
          >
            <Button
              component={RouterLink}
              data-test-id="system-build-table-button"
              variant="outlined"
              to={
                "/projects/" +
                String(project_id) +
                "/systems/" +
                String(system_id) +
                "/build/table/"
              }
            >
              {window.gettext("System Builder")}
            </Button>
          </UserPermission>

          <Button
            component={RouterLink}
            data-test-id="system-build-table-button"
            variant="outlined"
            to={
              "/projects/" +
              String(project_id) +
              "/systems/" +
              String(system_id) +
              "/locations/"
            }
          >
            {window.gettext("Handle locations")}
          </Button>
        </>
      }
    >
      {!project || !system ? (
        <LoadingIndicator />
      ) : (
        <LayoutCard title={system.name} noPadding>
          <TableContainer>
            <Table>
              <TableBody>
                <StyledTableRow>
                  <TableCell>{window.gettext("Outflow temperature")}</TableCell>
                  <TableCell data-test-id="outflow_temperature">
                    {system.outflow_temperature}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>{window.gettext("Return temperature")}</TableCell>
                  <TableCell data-test-id="return_temperature">
                    {system.return_temperature}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>{window.gettext("Pump type")}</TableCell>
                  <TableCell data-test-id="pump_type">
                    {system.pump_type}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    {window.gettext("Pump operation setting")}
                  </TableCell>
                  <TableCell data-test-id="pump_operation_setting">
                    {system.pump_operation_setting}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>{window.gettext("Pump pressure")}</TableCell>
                  <TableCell data-test-id="pump_pressure">
                    {system.pump_pressure / 1000} kPa
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    {window.gettext("Radiator valve KVS Value")}
                  </TableCell>
                  <TableCell data-test-id="radiator_valve_kvs_value">
                    {system.radiator_valve_kvs_value}
                  </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                  <TableCell>
                    {window.gettext("Additional resistance")}
                  </TableCell>
                  <TableCell data-test-id="additional_resistance">
                    {system.additional_resistance}
                  </TableCell>
                </StyledTableRow>
                {system.note !== "" && (
                  <StyledTableRow>
                    <TableCell>{window.gettext("Note")}</TableCell>
                    <TableCell>
                      <p data-test-id="note" style={{ whiteSpace: "pre-wrap" }}>
                        {system.note}
                      </p>
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Confirm delete project dialog*/}
          <Dialog
            open={confirmDeleteDialogOpen}
            onClose={handleCloseConfirmDeleteDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {window.gettext("Really delete project?")}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {window.gettext(
                  "This action can not be undone and all data related to the project will be for ever lost."
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                data-test-id="system-delete-confirm-button"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={handleConfirmDeleteSystem}
              >
                {window.gettext("Confirm delete")}
              </Button>
              <Button
                onClick={handleCloseConfirmDeleteDialogClose}
                variant="contained"
              >
                {window.gettext("No, do not delete system")}
              </Button>
            </DialogActions>
          </Dialog>
        </LayoutCard>
      )}
    </LayoutView>
  );
}
