import BaseService from "./BaseService";

class IssueSpentTimeService extends BaseService {
}

export default new IssueSpentTimeService('/api/',
  [
    'issues',
    'spent-time',
  ]
)