import {useEffect, useState} from "react";
import NippleService from "../../../../services/NippleService";
import {FormControl} from "@mui/material";
import NippleSelector from "../../../../self_check/NippleSelector";

export default function JobDatagridInstalledNippleField({id, field, value, api}) {
  let [nippleOptions, setNippleOptions] = useState([])
  let [selectedNipple, setSelectedNipple] = useState("")

  useEffect(() => {
    NippleService.cache_list().then(setNippleOptions)
  }, [])

  useEffect(() => {
    setSelectedNipple(value)
  }, [value])

  const handleOnChange = (event, newSelectedNippleId) => {
    setSelectedNipple(newSelectedNippleId)

    api.setEditCellValue({id, field, value: newSelectedNippleId}, event)
  }

  return (
    <>
      <FormControl
        fullWidth
      >
        <NippleSelector
          value={selectedNipple}
          defaultOpen={true}
          autoFocus={true}
          onChange={handleOnChange}
        />
      </FormControl>
    </>
  )
}