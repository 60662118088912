import {
  Assignment,
  AssignmentLate,
  AssignmentTurnedIn,
  HorizontalRule,
} from "@mui/icons-material";

import Helper from "../../../../../utils/Helper";

export function EntityDoneStatusIcon({ entityStatus }) {
  return (
    <>
      {entityStatus.has_checkable() ? (
        <>
          {entityStatus.is_done() ? (
            <Helper
              fullOpacity
              icon={<AssignmentTurnedIn color="success" />}
              title={window.gettext("Done")}
            />
          ) : entityStatus.is_some_task_items_done_but_not_tasks() ? (
            <Helper
              title={window.gettext("Task has been started but not finished")}
              fullOpacity
              icon={<AssignmentLate color={"warning"} />}
            >
              {window.gettext(
                "One or more task items has been completed but task remains not done on this entity"
              )}
            </Helper>
          ) : (
            <Helper
              title={window.gettext("Task has not been started")}
              fullOpacity
              icon={<Assignment color={"disabled"} />}
            >
              {window.gettext("Task has not been started on this entity")}
            </Helper>
          )}
        </>
      ) : (
        <HorizontalRule color={"disabled"} />
      )}
    </>
  );
}
