import {
  Avatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export function DesktopMenuSubItem({ collapsed, icon, label, onClick }) {
  return (
    <ListItemButton
      onClick={onClick}
      sx={{ height: 52, t: 1, px: collapsed ? 1 : 2 }}
    >
      <ListItemIcon sx={{ p: "2px" }}>
        <Avatar sx={{ bgcolor: "primary.light", width: 32, height: 32 }}>
          {icon}
        </Avatar>
      </ListItemIcon>

      {!collapsed && <ListItemText primary={label} />}
    </ListItemButton>
  );
}
