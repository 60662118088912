import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import EditLocalApartmentNumber from "../../../../../../apartment/EditLocalApartmentNumber";
import Entity from "../../../entity/Entity";

import { LayoutCard } from "../../../../../../../layout";
import { useHierarchyContext } from "../../context";
import { getComponentsHeaders } from "../../helper";

export function HierarchyEntityInformation() {
  const { entityType, focusEntityData, hierarchyViewOptions, project, system } =
    useHierarchyContext();

  return (
    <>
      {hierarchyViewOptions?.show_information_option.value && (
        <LayoutCard
          title={getComponentsHeaders(entityType).entity_information}
          noPadding
        >
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{window.gettext("Name")}</TableCell>
                  <TableCell>
                    <Entity entityData={focusEntityData} />
                  </TableCell>
                </TableRow>
                {focusEntityData.entity.type === "address_apartment" && (
                  <TableRow>
                    <TableCell>{window.gettext("Local apt. nr.")}</TableCell>
                    <TableCell>
                      <EditLocalApartmentNumber
                        localApartmentNumber={
                          focusEntityData.entity.data.local_apartment_number
                        }
                        projectId={project.id}
                        systemId={system.id}
                        apartmentId={focusEntityData.entity.id}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </LayoutCard>
      )}
    </>
  );
}
