import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";

export function FetchedLink({ children, color, loadingElement = <></>, to }) {
  return (
    <>
      {children === undefined || to === undefined ? (
        loadingElement
      ) : (
        <MaterialLink component={RouterLink} color={color} to={to}>
          {children}
        </MaterialLink>
      )}
    </>
  );
}
