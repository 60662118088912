import {
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_RADIATOR,
  ENTITY_TYPE_SYSTEM,
  ENTITY_TYPE_VALVE,
} from "../../entity";

export function getComponentsHeaders(entity_type) {
  switch (entity_type) {
    // Common Entities
    case ENTITY_TYPE_APARTMENT:
      // Apartment
      return {
        entity_information: window.gettext("Apartment information"),
        task_interface: window.gettext("Apartment tasks"),
        task_interface_linked: window.gettext("Units tasks"),
        navigation: window.gettext("Units"),
        navigation_details: window.gettext("Unit details"),
      };
    case ENTITY_TYPE_ENTRANCE:
      // Entrance
      return {
        entity_information: window.gettext("Entrance information"),
        task_interface: window.gettext("Entrance tasks"),
        task_interface_linked: window.gettext("Apartments tasks"),
        navigation: window.gettext("Apartments"),
        navigation_details: window.gettext("Apartment details"),
      };
    case ENTITY_TYPE_SYSTEM:
      // System
      return {
        entity_information: window.gettext("System information"),
        task_interface: window.gettext("System tasks"),
        task_interface_linked: window.gettext("Entrances tasks"),
        navigation: window.gettext("Entrances"),
        navigation_details: window.gettext("Entrance details"),
      };
    // Radiator System
    case ENTITY_TYPE_RADIATOR:
      // Radiator
      return {
        entity_information: window.gettext("Radiator information"),
        task_interface: window.gettext("Radiator tasks"),
        temperature_measurements: window.gettext("Temperature measurements"),
        nipple_and_kv: window.gettext("Nipple and KV"),
      };
    case ENTITY_TYPE_VALVE:
      // Valve
      return {
        entity_information: window.gettext("Valve information"),
        task_interface: window.gettext("Valve tasks"),
        temperature_measurements: window.gettext("Temperature measurements"),
        nipple_and_kv: window.gettext("Nipple and KV"),
      };
    default:
      // Default
      return {
        entity_information: window.gettext("Entity information"),
        task_interface: window.gettext("Entity tasks"),
        task_interface_linked: window.gettext("Linked entities tasks"),
        navigation: window.gettext("Linked entities"),
        navigation_details: window.gettext("Linked entity details"),
      };
  }
}
