import BaseService from "./BaseService";

class ApartmentSearchService extends BaseService {

}

export default new ApartmentSearchService('/api/',
  [
    'apartment/search'
  ]
)