export function getUserRepresentationShort(user) {
  if (!user) {
    return ''
  }

  let representation = ""

  if (user.first_name) {
    representation += user.first_name.substring(0, 1)
  }

  if (user.last_name) {
    representation += user.last_name.substring(0, 1)
  }

  if (!user.last_name && !user.first_name) {
    representation += user.username.substring(0, 1)
  }

  return representation
}