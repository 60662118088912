import {
  Box,
  Button,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import InstanceManipulator from "../../../../../InstanceManipulator";
import HeatCurve from "../../../../../heat_curve/HeatCurve";
import LoadingIndicator from "../../../../../layout/LoadingIndicator";
import MultilineText from "../../../../../layout/MultilineText";
import UserPermission from "../../../../../user/UserPermission";
import Pressure from "../../../../../values/Pressure";
import Temperature from "../../../../../values/Temperature";
import { JobMeasurementReportContext } from "./JobMeasurementReportContext";
import JobMeasurementReportMedianTemperatureDistribution from "./JobMeasurementReportMedianTemperatureDistribution";
import { MedianTemperatureReportTable } from "./MedianTemperatureReportTable";
import OutsideAcceptedRangeSummaryTable from "./OutsideAcceptedRangeSummaryTable";
import OutsideAcceptedRangeTable from "./OutsideAcceptedRangeTable";
import JobReportMeasurementProfileBlacklistedEntitiesList
  from "./profile/JobReportMeasurementProfileBlacklistedEntitiesList";
import JobReportMeasurementProfileManipulator from "./profile/JobReportMeasurementProfileManipulator";

import { LayoutCard } from "../../../../../../layout";
import { CenteredModal, useModalHandler } from "../../../../../layout/modal";
import OpportunitiesTemperaturesChart from "../../measurement/opportunity/OpportunitiesTemperaturesChart";

export default function JobMeasurementReport() {
  const {job_id, job_spec_id, project_id, system_id} = useParams();

  const {enqueueSnackbar} = useSnackbar();

  const {report, reloadReport, opportunitiesReport} = useContext(JobMeasurementReportContext);

  const editProfileModalHandler = useModalHandler();

  const [allLocations, setAllLocations] = useState([]);
  const [
    outsideAcceptedRangeTableShowHidden,
    setOutsideAcceptedRangeTableShowHidden,
  ] = useState(false);

  useEffect(() => {
    if (!report) {
      return;
    }

    const getDescendants = (r) => {
      return [[r]].concat(r?.children.map(getDescendants)).flat();
    };

    setAllLocations(getDescendants(report.report));
  }, [report]);

  function onEditProfileUpdate() {
    enqueueSnackbar(window.gettext("Report profile saved"), {
      variant: "success",
    });

    editProfileModalHandler.close();
    reloadReport();
  }

  return (
    <>
      {!report ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Profile */}
          <LayoutCard title={window.gettext("Profile")} doNotPrint noPadding>
            <TableContainer>
              <Table>
                <TableBody>
                  {/* Profile name */}
                  <TableRow>
                    <TableCell>{window.gettext("Name")}</TableCell>

                    <TableCell>{report.profile.name}</TableCell>
                  </TableRow>

                  {/* Profile note */}
                  {report.profile.note !== "" && (
                    <TableRow>
                      <TableCell>{window.gettext("Note")}</TableCell>

                      <TableCell>
                        <MultilineText>{report.profile.note}</MultilineText>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              sx={{
                flexDirection: "row",
                gap: 1,
                p: 1,
              }}
            >
              {/* Change profile button */}
              <Button
                component={RouterLink}
                to={
                  "/projects/" +
                  String(project_id) +
                  "/systems/" +
                  String(system_id) +
                  "/job-specs/" +
                  String(job_spec_id) +
                  "/job/" +
                  String(job_id) +
                  "/report/measurement/"
                }
                variant="outlined"
              >
                {window.gettext("Change profile")}
              </Button>

              {/* Edit profile button */}
              <UserPermission
                permission={
                  UserPermission.permissions.measurement_report_profile_change
                }
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={editProfileModalHandler.open}
                  sx={{
                    maxWidth: 250,
                  }}
                >
                  {window.gettext("Edit profile")}
                </Button>
              </UserPermission>
            </Stack>
          </LayoutCard>

          {/* System information */}
          <LayoutCard title={window.gettext("System information")} noPadding>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>{window.gettext("Project name")}</TableCell>

                    <TableCell>{report.project.name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{window.gettext("System name")}</TableCell>

                    <TableCell>{report.system.name}</TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>{window.gettext("Pump pressure")}</TableCell>

                    <TableCell>
                      <Pressure value={report.system.pump_pressure}/>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {window.gettext("Calculated outflow temp")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={report.system.outflow_temperature}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {window.gettext("Calculated return temp")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={report.system.return_temperature}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {window.gettext("Desired room temperature")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={report.system.room_temperature}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {window.gettext("Accepted temperature interval")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={report.profile.median_accepted_threshold_low}
                        precision={1}
                      />

                      <span> - </span>

                      <Temperature
                        value={report.profile.median_accepted_threshold_high}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      {window.gettext("Median temperature")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={report.report.room_temperature?.median}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </LayoutCard>

          {/* Temperature distribution */}
          <LayoutCard
            title={window.gettext("Temperature distribution")}
            noPadding
          >
            <JobMeasurementReportMedianTemperatureDistribution
              report={report.report}
              allLocations={allLocations}
              reportSettings={report.profile}
            />
          </LayoutCard>

          {/* Median temperatures */}
          <LayoutCard title={window.gettext("Median temperatures")} noPadding>
            <MedianTemperatureReportTable
              report={report.report}
              reportSettings={report.profile}
            />
          </LayoutCard>

          {/* Outside accepted interval summary */}
          <LayoutCard
            title={window.gettext("Outside accepted interval summary")}
            noPadding
          >
            <OutsideAcceptedRangeSummaryTable
              report={report}
              reportSettings={report.profile}
            />
          </LayoutCard>

          {/* Locations outside accepted interval */}
          <LayoutCard
            title={window.gettext("Locations outside accepted interval")}
            noPadding
          >
            <UserPermission
              permission={
                UserPermission.permissions
                  .measurement_report_profile_hidden_entity_view
              }
            >
              <Box
                sx={{
                  displayPrint: "none",
                  p: 1,
                }}
              >
                <Checkbox
                  checked={outsideAcceptedRangeTableShowHidden}
                  onClick={(e) => {
                    setOutsideAcceptedRangeTableShowHidden(
                      !outsideAcceptedRangeTableShowHidden
                    );
                  }}
                />

                <span>{window.gettext("Show hidden locations")}</span>
              </Box>
            </UserPermission>

            <OutsideAcceptedRangeTable
              allLocations={allLocations}
              reportProfile={report.profile}
              project_id={report.project.id}
              system_id={report.system.id}
              job_id={report.job.id}
              showHidden={outsideAcceptedRangeTableShowHidden}
            />
          </LayoutCard>

          {/* Heat curve */}
          {report.last_heat_curve && (
            <LayoutCard
              title={window.gettext("Heat curve")}
              noPadding
            >
              <HeatCurve heat_curve={report.last_heat_curve}/>
            </LayoutCard>
          )}

          {/* Opportunities */}
          <LayoutCard title={window.gettext("Outside, outflow and return -temperatures")}>
            <OpportunitiesTemperaturesChart
              opportunities={opportunitiesReport?.opportunities}
              referenceReturnTemperature={opportunitiesReport?.system?.return_temperature}
              referenceOutflowTemperature={opportunitiesReport?.system?.outflow_temperature}
            />
          </LayoutCard>

          {/* Blacklisted entities */}
          <UserPermission
            permission={
              UserPermission.permissions
                .measurement_report_profile_blacklisted_entity_view
            }
          >
            <LayoutCard
              title={window.gettext("Blacklisted items")}
              doNotPrint
              noPadding
            >
              <JobReportMeasurementProfileBlacklistedEntitiesList/>
            </LayoutCard>
          </UserPermission>

          {/* Edit profile modal */}
          <UserPermission
            permission={
              UserPermission.permissions.measurement_report_profile_change
            }
          >
            <CenteredModal modalHandler={editProfileModalHandler}>
              <JobReportMeasurementProfileManipulator
                mode={InstanceManipulator.MODE_EDIT}
                postUpdate={onEditProfileUpdate}
              />
            </CenteredModal>
          </UserPermission>
        </>
      )}
    </>
  );
}
