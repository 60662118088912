import { List, ListItem, Stack } from "@mui/material";

import { TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE } from "../../../../../../job_specification";
import CheckableCommentCellIndicator from "../../../../../datagrid/CheckableCommentCellIndicator";

import {
  HierarchyTaskItemCheckbox,
  HierarchyTaskItemText,
} from "../task_items";

export function HierarchyTaskTypeItemGroup({
  entity,
  handleCheckboxClick,
  handleOnTextSave,
  handleOnCommentSave,
  hasError,
  isSaving,
  taskItems,
}) {
  return (
    <List
      sx={{
        width: "100%",
        pl: 2,
      }}
    >
      {/* Task items */}
      {taskItems.map((taskItemData) => (
        <ListItem
          key={taskItemData.task_item_spec.id}
          sx={{
            pr: 0,
          }}
        >
          <Stack
            sx={{
              gap: 1,
            }}
          >
            {/* Checkbox and comment */}
            <Stack
              sx={{
                flexDirection: "row",
              }}
            >
              {/* Checkbox */}
              <HierarchyTaskItemCheckbox
                isSaving={isSaving}
                hasError={hasError}
                spec={taskItemData.task_item_spec}
                visible={true}
                checked={taskItemData?.task_item?.done === true}
                clickable={true}
                title={taskItemData.task_item_spec.name}
                onClick={(data) => {
                  handleCheckboxClick(
                    taskItemData.task_item_spec,
                    false,
                    entity.type,
                    entity.id,
                    taskItemData?.task_item,
                    !(taskItemData?.task_item?.done === true),
                    data
                  );
                }}
              />

              {/* Comment */}
              {taskItemData.task_item_spec.enable_commenting && (
                <CheckableCommentCellIndicator
                  comment={taskItemData?.task_item?.comment}
                  onSave={(newComment, successCallback) => {
                    handleOnCommentSave(
                      newComment,
                      successCallback,
                      taskItemData.task_item_spec,
                      false,
                      entity.type,
                      entity.id,
                      taskItemData?.task_item
                    );
                  }}
                />
              )}
            </Stack>

            {/* Textfield, only if task item spec type is text */}
            {taskItemData.task_item_spec.type ===
              TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE && (
              <HierarchyTaskItemText
                isSaving={isSaving}
                hasError={hasError}
                entity={entity}
                originalText={taskItemData?.task_item?.text}
                onSave={(newText, successCallback) => {
                  handleOnTextSave(
                    newText,
                    successCallback,
                    taskItemData.task_item_spec,
                    false,
                    entity.type,
                    entity.id,
                    taskItemData?.task_item
                  );
                }}
              />
            )}
          </Stack>
        </ListItem>
      ))}
    </List>
  );
}
