import { Flag } from "@mui/icons-material";

export function EntityFlagStatusIcon({ entityStatus }) {
  return (
    <>
      {entityStatus?.flagged_tasks.length > 0 ? (
        <Flag color="error" />
      ) : (
        <Flag color="disabled" />
      )}
    </>
  );
}
