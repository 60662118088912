import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import AdaptableMultilineTextField from "../form/AdaptableMultilineTextField";
import MultilineText from "../layout/MultilineText";
import SystemHeatCurveService from "../services/SystemHeatCurveService";
import UserPermission from "../user/UserPermission";
import HeatCurveTable from "./HeatCurveTable";

export default function HeatCurve(props) {
  const { heat_curve, system_id, project_id, allow_edit } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);

  const [heatCurve, setHeatCurve] = useState(heat_curve);
  const [heatCurveComment, setHeatCurveComment] = useState("");

  useEffect(() => {
    setHeatCurveComment(heatCurve?.comment);
  }, [heatCurve]);

  function onHeatCurveUpdate(newHeatCurve) {
    setHeatCurve(newHeatCurve);
  }

  function handleOnClickSaveCommentButton(e) {
    SystemHeatCurveService.update(
      {
        comment: heatCurveComment,
      },
      project_id,
      system_id,
      heatCurve.id
    ).then((updatedHeatCurve) => {
      onHeatCurveUpdate(updatedHeatCurve);
      enqueueSnackbar(window.gettext("Comment saved", { variant: "success" }));
    });
  }

  function handleOnChangeComment(e) {
    setHeatCurveComment(e.target.value);
  }

  // Just a placeholder
  return (
    <>
      <Divider />

      {editMode === true ? (
        <UserPermission
          permission={UserPermission.permissions.system_heat_curve_change}
        >
          <Stack
            sx={{
              gap: 1,
              p: 1,
            }}
          >
            <Typography variant={"h5"}>
              {window.gettext("Edit comment")}
            </Typography>

            <AdaptableMultilineTextField
              label={window.gettext("Comment")}
              onChange={handleOnChangeComment}
              value={heatCurveComment}
            />

            <Button
              variant={"contained"}
              onClick={handleOnClickSaveCommentButton}
              startIcon={<SaveIcon />}
            >
              {window.gettext("Save comment")}
            </Button>
          </Stack>

          <Divider />
        </UserPermission>
      ) : (
        <>
          {heatCurve.comment !== "" && (
            <Box
              sx={{
                p: 1,
              }}
            >
              <Typography variant={"h5"}>
                {window.gettext("Comment")}
              </Typography>

              <MultilineText>{heatCurve.comment}</MultilineText>
            </Box>
          )}

          <Divider />
        </>
      )}

      <HeatCurveTable
        project_id={project_id}
        system_id={system_id}
        heat_curve={heatCurve}
        edit_mode={editMode}
        onUpdate={onHeatCurveUpdate}
      />

      {allow_edit === true && (
        <UserPermission
          anyPermission={[
            UserPermission.permissions.system_heat_curve_point_change,
            UserPermission.permissions.system_heat_curve_point_add,
            UserPermission.permissions.system_heat_curve_change,
          ]}
        >
          <Box
            sx={{
              p: 2,
            }}
          >
            {!editMode ? (
              <UserPermission
                anyPermission={[
                  UserPermission.permissions.system_heat_curve_point_change,
                  UserPermission.permissions.system_heat_curve_point_add,
                  UserPermission.permissions.system_heat_curve_change,
                ]}
              >
                <Button
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                  startIcon={<EditIcon />}
                >
                  {window.gettext("Edit heat curve")}
                </Button>
              </UserPermission>
            ) : (
              <Button
                onClick={() => {
                  setEditMode(!editMode);
                }}
                color={"warning"}
                variant={"contained"}
                startIcon={<CloseIcon />}
              >
                {window.gettext("Exit edit mode")}
              </Button>
            )}
          </Box>
        </UserPermission>
      )}
    </>
  );
}

HeatCurve.props = {
  heat_curve: PropTypes.shape({
    points: PropTypes.array,
  }),
  system_id: PropTypes.number,
  project_id: PropTypes.number,
  allow_edit: PropTypes.bool,
};
