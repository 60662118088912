import { useLayoutSizeTrackerContext } from "../context";

export function useLayoutSize() {
  const layoutSizeTrackerContext = useLayoutSizeTrackerContext();

  return {
    bottomHeight: layoutSizeTrackerContext.bottomHeight,
    bottomRef: layoutSizeTrackerContext.bottomRef,
    height: layoutSizeTrackerContext.height,
    isMobile: layoutSizeTrackerContext.isMobile,
    leftHeight: layoutSizeTrackerContext.leftHeight,
    leftRef: layoutSizeTrackerContext.leftRef,
    mainHeight: layoutSizeTrackerContext.mainHeight,
    mainRef: layoutSizeTrackerContext.mainRef,
    mainWidth: layoutSizeTrackerContext.mainWidth,
    preferredLayout: layoutSizeTrackerContext.preferredLayout,
    rightHeight: layoutSizeTrackerContext.rightHeight,
    rightRef: layoutSizeTrackerContext.rightRef,
    topHeight: layoutSizeTrackerContext.topHeight,
    topRef: layoutSizeTrackerContext.topRef,
    width: layoutSizeTrackerContext.width,

    setPreferredLayout: layoutSizeTrackerContext.setPreferredLayout,
  };
}
