import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button } from "@mui/material";
import { useState } from "react";

import CopyNodeFromDialog from "../CopyNodeFromDialog";
import { ACTION_COPY_NODE } from "../GridRowAction";

export function CopyNodeFromButton({ dispatchActionCallback, nodes, row }) {
  const [targetNode, setTargetRow] = useState(undefined);

  function handleOnClick(event) {
    setTargetRow(row);
  }

  // This function is called from within the dialog to select target node
  function handleOnClickImport(
    project,
    system,
    node,
    keepNames,
    replace,
    replaceWith
  ) {
    dispatchActionCallback(ACTION_COPY_NODE, {
      name_replacements:
        replace === ""
          ? undefined
          : {
              [replace]: replaceWith,
            },
      keep_names: keepNames,
      project_id: project?.id,
      system_id: system?.id,
      node_id: node?.id,
    });
  }

  return (
    <>
      {/* Button */}
      <Button
        variant="contained"
        onClick={handleOnClick}
        startIcon={<ContentCopyIcon />}
      >
        {window.gettext("Copy from")}...
      </Button>

      {/* Dialog */}
      <CopyNodeFromDialog
        closeDialogCallback={() => setTargetRow(undefined)}
        onClickImport={handleOnClickImport}
        row={targetNode}
      />
    </>
  );
}
