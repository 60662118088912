import { Chip, Tooltip } from "@mui/material";

export function ChipJobSpecTaskSpecCount({ jobSpec }) {
  return (
    <Tooltip
      title={window.gettext(
        "Number of task specifications on this job specification"
      )}
    >
      <Chip label={jobSpec.task_specs.length} />
    </Tooltip>
  );
}
