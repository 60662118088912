import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import InstanceManipulator from "../../../components/InstanceManipulator";
import LoadingIndicator from "../../../components/layout/LoadingIndicator";
import ProjectDetailProjectBaseInfoTabContent from "../../../components/project/ProjectDetailProjectBaseInfoTabContent";
import ProjectDetailProjectStageTabContent from "../../../components/project/ProjectDetailProjectStageTabContent";
import { TabPanel } from "../../../components/project/ProjectDetailViewTabPanel";
import ProjectMembers from "../../../components/project/ProjectMembers";
import ProjectRowForm from "../../../components/project/ProjectRowForm";
import ProjectRows from "../../../components/project/ProjectRows";
import getProjectRepresentation from "../../../components/project/getProjectRepresentation";
import ChoicesService from "../../../components/services/ChoicesService";
import ProjectRowService from "../../../components/services/ProjectRowService";
import ProjectService from "../../../components/services/ProjectService";
import SystemList from "../../../components/system/SystemList";
import UserPermission from "../../../components/user/UserPermission";
import getUserHasPermission from "../../../components/user/getUserHasPermission";
import store from "../../../state";

import { ProjectDetailSelfCheckTabContent } from "../../../components/project/tab_content";
import { LayoutCard, LayoutView } from "../../../layout";
import {
  TAB_BASE_INFO,
  TAB_PROJECT_MEMBERS,
  TAB_PROJECT_ROWS,
  TAB_PROJECT_STAGE,
  TAB_SELF_CHECK,
  TAB_SYSTEM,
} from "./tabs";

const TOOLBAR_STACK_PROPS = {
  flexDirection: "row",
  p: 1,
  gap: 1,
};

export default function ProjectDetailView() {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [project, setProject] = useState({ id: undefined });

  const [tabsValue, setTabsValue] = useState(0);
  const [projectRowChoices, setProjectRowChoices] = useState(undefined);
  const [projectAddressChoices, setProjectAddressChoices] = useState(undefined);
  const [rows, setRows] = useState(undefined);
  const [addresses, setAddresses] = useState(undefined);
  const [projectChoices, setProjectChoices] = useState(undefined);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  useEffect(() => {
    // Fetch project
    fetchProject();
  }, [project_id]);

  useEffect(() => {
    ChoicesService.projectRowChoices().then(setProjectRowChoices);
    ChoicesService.projectChoices().then(setProjectChoices);
    ChoicesService.projectAddressChoices().then(setProjectAddressChoices);
  }, []);

  useEffect(() => {
    // When project is updated update rows
    setRows(project.rows);
    setAddresses(project.addresses);
  }, [project]);

  useEffect(() => {
    let queryStringTabNumber = searchParams.get("tab");

    // select tab
    if (queryStringTabNumber !== null && queryStringTabNumber !== undefined) {
      queryStringTabNumber = parseInt(queryStringTabNumber);
      setTabsValue(queryStringTabNumber);
    }
  }, [searchParams]);

  function fetchProject() {
    ProjectService.fetch(project_id, {'systems_radiator_count': true}).then(setProject);
  }

  function handleCloseConfirmDeleteDialogOpen() {
    setConfirmDeleteDialogOpen(false);
  }

  function handleConfirmDeleteProject() {
    ProjectService.destroy(project_id).then((response) => {
      navigate("/projects/");
    });
  }

  function handleTabsChange(event, newValue) {
    console.log("tabs change", newValue);

    // Change URL to also include the tab=x
    navigate("?tab=" + String(newValue));

    setTabsValue(newValue);
  }

  function tabProps(index, permission) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      display:
        permission !== undefined && getUserHasPermission(store.user, permission)
          ? "initial"
          : "none",
    };
  }

  const postCreate = function (createdRow) {
    let newRows = rows.map((row) => row);
    console.log("after create");

    newRows.push(createdRow);

    setRows(newRows);
  };

  return (
    <LayoutView
      header={getProjectRepresentation(project)}
      noPaddingSubmenu
      submenuItems={
        <Stack
          sx={{
            width: "100%",
          }}
        >
          <Tabs
            variant="scrollable"
            value={tabsValue}
            onChange={handleTabsChange}
          >
            {/* Base info */}
            <Tab
              label={window.gettext("Base info")}
              {...tabProps(TAB_BASE_INFO)}
            />

            {/* Systems */}
            <Tab label={window.gettext("Systems")} {...tabProps(TAB_SYSTEM)} />

            {/* Project rows / Project components */}
            <Tab
              label={window.gettext("Project components")}
              {...tabProps(
                TAB_PROJECT_ROWS,
                UserPermission.permissions.project_row_view
              )}
            />

            {/* Self check */}
            <Tab
              label={window.gettext("Self check")}
              {...tabProps(TAB_SELF_CHECK)}
            />

            {/* Project stage */}
            <Tab
              label={window.gettext("Project stage")}
              {...tabProps(
                TAB_PROJECT_STAGE,
                UserPermission.permissions.project_change_stage
              )}
            />

            {/* Project members */}
            <Tab
              label={window.gettext("Project members")}
              {...tabProps(
                TAB_PROJECT_MEMBERS,
                UserPermission.permissions.project_membership_view
              )}
            />
          </Tabs>

          {tabsValue === TAB_BASE_INFO ? (
            <>
              {getUserHasPermission(
                store.user,
                UserPermission.permissions.project_change
              ) && (
                <Stack {...TOOLBAR_STACK_PROPS}>
                  <Button
                    component={RouterLink}
                    variant="outlined"
                    to={"/projects/" + project.id + "/edit/"}
                  >
                    {window.gettext("Edit project")}
                  </Button>
                </Stack>
              )}
            </>
          ) : tabsValue === TAB_SYSTEM ? (
            <>
              {getUserHasPermission(
                store.user,
                UserPermission.permissions.system_add
              ) && (
                <Stack {...TOOLBAR_STACK_PROPS}>
                  <Button
                    component={RouterLink}
                    variant="outlined"
                    to={"/projects/" + String(project.id) + "/systems/create/"}
                  >
                    {window.gettext("New system")}
                  </Button>
                </Stack>
              )}
            </>
          ) : null}
        </Stack>
      }
    >
      {project.id === undefined || projectChoices === undefined ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Base info */}
          <TabPanel value={tabsValue} index={TAB_BASE_INFO} name={"base-info"}>
            <ProjectDetailProjectBaseInfoTabContent project={project} />
          </TabPanel>

          {/* Systems */}
          <TabPanel value={tabsValue} index={TAB_SYSTEM} name={"systems"}>
            <LayoutCard title={window.gettext("Systems")}>
              <SystemList project_id={project.id} />
            </LayoutCard>
          </TabPanel>

          {/* Project rows / Project components */}
          <TabPanel
            value={tabsValue}
            index={TAB_PROJECT_ROWS}
            name={"project-components"}
          >
            {projectRowChoices !== undefined && (
              <>
                <LayoutCard title={window.gettext("Project rows")} noPadding>
                  <ProjectRows
                    rows={rows}
                    categoryChoices={projectRowChoices.category}
                    projectId={project_id}
                    refreshProjectCallback={() => {
                      fetchProject();
                    }}
                  />
                </LayoutCard>

                <LayoutCard title={window.gettext("Create project component")}>
                  <InstanceManipulator
                    service={ProjectRowService}
                    urlParams={[project_id]}
                    postCreate={postCreate}
                    mode="create"
                    form={
                      <ProjectRowForm
                        submitButtonText={window.gettext("Create row")}
                        categoryChoices={projectRowChoices.category}
                      />
                    }
                  />
                </LayoutCard>
              </>
            )}
          </TabPanel>

          {/* Self check */}
          <TabPanel
            value={tabsValue}
            index={TAB_SELF_CHECK}
            name={"self_check"}
          >
            <ProjectDetailSelfCheckTabContent project={project} />
          </TabPanel>

          {/* Project stage */}
          <TabPanel
            value={tabsValue}
            index={TAB_PROJECT_STAGE}
            name={"self_check"}
          >
            <ProjectDetailProjectStageTabContent project={project} />
          </TabPanel>

          {/* Project members */}
          <TabPanel
            value={tabsValue}
            index={TAB_PROJECT_MEMBERS}
            name={"project_members"}
          >
            <ProjectMembers project={project} />
          </TabPanel>
        </>
      )}

      {/*
      Confirm delete project dialog
      */}
      <Dialog
        open={confirmDeleteDialogOpen}
        onClose={handleCloseConfirmDeleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {window.gettext("Really delete project?")}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {window.gettext(
              "This action can not be undone and all data related to the project will be for ever lost."
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            data-test-id="project-confirm-delete-button"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={handleConfirmDeleteProject}
          >
            {window.gettext("Confirm delete")}
          </Button>

          <Button
            onClick={handleCloseConfirmDeleteDialogOpen}
            variant="contained"
          >
            {window.gettext("No, do not delete project")}
          </Button>
        </DialogActions>
      </Dialog>
    </LayoutView>
  );
}

/*
Unused functions
const postCreateAddress = function (createdAddress) {
  let newAddresses = addresses.map((row) => row);

  newAddresses.push(createdAddress);

  setAddresses(newAddresses);
};

Unused tab panels

<TabPanel value={tabsValue} index={6} name={"addresses"}>
<Typography variant="h4">{window.gettext("Addresses")}</Typography>
{projectAddressChoices !== undefined && (
  <>
    <ProjectAddresses
      addresses={addresses}
      addressTypeChoices={projectAddressChoices.type}
      projectId={project.id}
    />
    <InstanceManipulator
      service={ProjectAddressService}
      urlParams={[project_id]}
      postCreate={postCreateAddress}
      mode="create"
      form={
        <ProjectAddressForm
          submitButtonText={window.gettext("Create address")}
          addressTypeChoices={projectAddressChoices.type}
        />
      }
    />
  </>
)}
</TabPanel>

<TabPanel value={tabsValue} index={7} name={"settings"}>
<Typography variant="h4">{window.gettext("Settings")}</Typography>

<Stack
  spacing={1}
  direction="column"
  justifyContent="flex-start"
  alignItems="flex-start"
>
  {getUserHasPermission(store.user, "change_project") && (
    <Button
      data-test-id="project-edit-button"
      to={"/projects/" + String(project.id) + "/edit/"}
      component={RouterLink}
      variant="outlined"
      startIcon={<EditIcon />}
    >
      {window.gettext("Edit project information")}
    </Button>
  )}

  {getUserHasPermission(store.user, "delete_project") && (
    <Button
      data-test-id="project-delete-button"
      variant="outlined"
      color="error"
      startIcon={<DeleteIcon />}
      onClick={() => setConfirmDeleteDialogOpen(true)}
    >
      {window.gettext("Delete")}
    </Button>
  )}
</Stack>
</TabPanel>
*/
