import Entity from "../../../entity/Entity";

import { useHierarchyContext } from "../../context";
import {
  HierarchyEntityInformation,
  HierarchyEntitySiblings,
  HierarchyNavigation,
  HierarchyTaskInterface,
  HierarchyTaskInterfaceLinkedEntities,
} from "../components";
import { HierarchyPWSAddEntity } from "../components/potable_water_system";
import { HierarchyEntityRSAddressApartment } from "./radiator_system";

export function HierarchyEntityAddressApartment() {
  const { focusEntityData, refreshJobData, system } = useHierarchyContext();

  function handleAddedEntity(entity) {
    refreshJobData();
  }

  return (
    <>
      {system.type === "radiator" ? (
        <HierarchyEntityRSAddressApartment />
      ) : null}

      <HierarchyEntitySiblings>
        <Entity entityData={focusEntityData} />
      </HierarchyEntitySiblings>

      <HierarchyEntityInformation />

      <HierarchyTaskInterface />

      <HierarchyTaskInterfaceLinkedEntities
        newEntityForm={
          system.type === "potable_water" ? (
            <HierarchyPWSAddEntity onSuccess={handleAddedEntity} />
          ) : null
        }
      />

      <HierarchyNavigation />
    </>
  );
}
