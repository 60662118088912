import { Divider, Stack, Typography } from "@mui/material";

export function LayoutCardDesktop({
  children,
  borderRadius = 2,
  mb = 2,
  menuItems,
  doNotPrint,
  headerVariant = "h3",
  noPadding,
  title,
  titleExtra,
  sx,
  ...props
}) {
  return (
    <Stack
      sx={{
        backgroundColor: "background.paper",
        borderRadius: borderRadius,
        boxShadow: 1,
        mb: mb,
        "@media print": {
          display: doNotPrint ? "none" : "initial",
          borderRadius: 0,
          boxShadow: 0,
          breakInside: "avoid",
        },
        ...sx,
      }}
      {...props}
    >
      {title && (
        <>
          <Divider
            sx={{
              display: "none",
              "@media print": {
                display: "block",
              },
            }}
          />

          <Stack
            sx={{
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 1,
            }}
          >
            <Stack
              sx={{
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography
                variant={headerVariant}
                sx={{
                  p: 1,
                }}
              >
                {title}
              </Typography>

              {titleExtra}
            </Stack>

            <Stack
              sx={{
                flexDirection: "row",
                gap: 1,
                "@media print": {
                  display: "none",
                },
              }}
            >
              {menuItems}
            </Stack>
          </Stack>

          <Divider />
        </>
      )}

      <Stack
        sx={{
          height: "100%",
          p: noPadding ? 0 : 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}
