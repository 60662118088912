import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../components/layout/LoadingIndicator";
import JobWrapper from "../../../../../components/project/self_check/job/JobWrapper";
import JobDatagrid from "../../../../../components/project/self_check/job/datagrid/JobDatagrid";
import ProjectService from "../../../../../components/services/ProjectService";
import SystemService from "../../../../../components/services/SystemService";

import { SelfCheckOptions } from "../../../../../components/project/self_check/job/utils/SelfCheckOptions";
import { LayoutView } from "../../../../../layout";

export default function JobDetailsDatagridView() {
  const { project_id, system_id, job_id } = useParams();

  const [project, setProject] = useState(null);
  const [system, setSystem] = useState(null);

  const [options, setOptions] = useState(new SelfCheckOptions());

  useEffect(fetchProject, [project_id]);
  useEffect(fetchSystem, [system_id]);

  function fetchProject() {
    ProjectService.fetch(project_id).then(setProject);
  }

  function fetchSystem() {
    SystemService.fetch(project_id, system_id).then(setSystem);
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Self Check")}
    >
      {!project || !system ? (
        <LoadingIndicator />
      ) : (
        <JobWrapper
          jobId={parseInt(job_id)}
          options={options}
          project={project}
          system={system}
          setOptions={setOptions}
        >
          <JobDatagrid />
        </JobWrapper>
      )}
    </LayoutView>
  );
}
