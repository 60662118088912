import { useContext } from "react";

import { HierarchyContext } from "../HierarchyContext";

export function useHierarchyContext() {
  const context = useContext(HierarchyContext);

  if (!context) {
    throw new Error(
      "useHierarchyContext has to be used within <HierarchyContextProvider>"
    );
  }
  return context;
}
