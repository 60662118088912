import React from "react";
import {TextField} from "@mui/material";

export default function EditNote({id, value, field, api}) {
  const handleValueChange = function (event) {
    api.setEditCellValue({id, field, value: event.target.value})
  }

  return (
    <>
      <TextField
        value={value}
        autoFocus={true}
        onChange={handleValueChange}
      />
    </>
  )
}