import getEntityStatus from "../utils/getEntityStatus";

export default function EntityCommentsCount({entityData}) {
  const entityStatus = getEntityStatus(entityData, entityData.job)

  return (
    <>
      {entityStatus.comments_count() || 0}
    </>
  )
}