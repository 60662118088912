import BaseService from "./BaseService";
class SelfCheckJobMeasurementReportProfileBlacklistService extends BaseService {
}

export default new SelfCheckJobMeasurementReportProfileBlacklistService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-profiles',
    'blacklist'
  ]
)