import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack, TextField,
} from "@mui/material";
import {useEffect, useState} from "react";
import {FormFactory} from "../../classes";

export function FactoryAutocompleteField({formField, helperText}) {
  const [options, setOptions] = useState(undefined)

  const handleInputChange = function (event, newValue) {
    if(formField.options.searchServiceMethod === undefined){
      console.log('No service method')
      return
    }

    formField.options.searchServiceMethod(
      {
        [formField.options.searchParamName]: newValue
      }
    ).then((response) => {
      setOptions(response)
    })
  }

  useEffect(() => {
    if(formField.options.choices){
      console.log('choices', formField.options.choices)
      setOptions(formField.options.choices)
    }
  }, [formField.options]);

  return ({field}) => {
    let currentOption = []
    /**
     * If the value is set AND we supply a currentValueLabel.
     * AND
     * if in the options we don't have an option with the same ID as the value
     */
    if (!!field.value && !!formField.options.currentValueLabel && !(options || []).find((option) => option.id === field.value)) {
      // Add the current value to the options
      currentOption = [
        {
          id: field.value,
          label: formField.options.currentValueLabel
        }
      ]
    }

    const allOptions = (options || []).concat(
      currentOption
    )

    return (
      <Stack {...formField.options.props}>
        <Autocomplete
          {...field}
          onChange={(event, newValue) => {
            field.onChange(newValue?.id || null)
          }}
          value={field.value || ""}
          options={allOptions}
          fullWidth={true}
          onInputChange={handleInputChange}
          getOptionLabel={(option) => {
            // In case the option is an empty string (normal for when the field is unset)
            if(option === ''){
              return ''
            }
            // If it is an integer, it is the ID of the option
            if (Number.isInteger(option)) {
              return allOptions.find((o) => o?.id === option)?.label || ''
            }

            return option.label || '-unknown-'
          }}
          autoComplete
          renderInput={
            (params) => <TextField
              {...params}
              label={formField.label}
            />
          }
        />
        {helperText && (
          <>
            <FormHelperText>{helperText}</FormHelperText>
          </>
        )}
      </Stack>
    )
  }
}
