import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../components/layout/LoadingIndicator";
import ProjectService from "../../../../../../components/services/ProjectService";
import SelfCheckJobSpecService from "../../../../../../components/services/SelfCheckJobSpecService";
import SelfCheckTaskItemSpecService from "../../../../../../components/services/SelfCheckTaskItemSpecService";
import SelfCheckTaskSpecService from "../../../../../../components/services/SelfCheckTaskSpecService";

import {
  JobSpecContent,
  JobSpecSubmenuItems,
} from "../../../../../../components/project";
import { LayoutView } from "../../../../../../layout";

export default function JobSpecDetailsView() {
  const { project_id, job_spec_id } = useParams();
  const [jobSpec, setJobSpec] = useState(null);
  const [project, setProject] = useState(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!project_id || !job_spec_id) {
      return;
    }

    fetchJobSpec();
  }, [project_id, job_spec_id]);

  function fetchJobSpec() {
    SelfCheckJobSpecService.fetch(project_id, job_spec_id).then(setJobSpec);

    ProjectService.fetch(project_id).then(setProject);
  }

  function postCreate() {
    fetchJobSpec();
  }

  function postImport() {
    fetchJobSpec();
  }

  function postUpdate() {
    fetchJobSpec();
  }

  function sortSuccessCallback() {
    fetchJobSpec();
  }

  function handleDeleteTaskItemSpecIconClick(taskItemSpec) {
    SelfCheckTaskItemSpecService.destroy(
      project_id,
      job_spec_id,
      taskItemSpec.task_spec_id,
      taskItemSpec.id
    ).then((response) => {
      fetchJobSpec();

      enqueueSnackbar(window.gettext("Task item spec deleted"), {
        variant: "success",
      });
    });
  }

  function handleDeleteTaskSpecIconClick(taskSpec) {
    SelfCheckTaskSpecService.destroy(project_id, job_spec_id, taskSpec.id).then(
      (response) => {
        fetchJobSpec();

        enqueueSnackbar(window.gettext("Task spec deleted"), {
          variant: "success",
        });
      }
    );
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Job Specification Builder")}
      submenuItems={
        <JobSpecSubmenuItems
          jobSpec={jobSpec}
          project={project}
          postImport={postImport}
        />
      }
    >
      {jobSpec === null || project === null ? (
        <LoadingIndicator />
      ) : (
        <JobSpecContent
          jobSpec={jobSpec}
          onDeleteTaskItemSpecIconClick={handleDeleteTaskItemSpecIconClick}
          onDeleteTaskSpecIconClick={handleDeleteTaskSpecIconClick}
          project={project}
          postCreate={postCreate}
          postUpdate={postUpdate}
          sortSuccessCallback={sortSuccessCallback}
        />
      )}
    </LayoutView>
  );
}
