import { Alert, Button, Divider, Link, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import InstanceManipulator from "../../../../InstanceManipulator";
import Sheet from "../../../../layout/Sheet";
import SelfCheckJobSpecForm from "../../../../self_check/SelfCheckJobSpecForm";
import SelfCheckJobSpecService from "../../../../services/SelfCheckJobSpecService";
import UserPermission from "../../../../user/UserPermission";
import Helper from "../../../../utils/Helper";

import { ModalStartJob } from "./components";
import { LayoutCard } from "../../../../../layout";

export function ProjectDetailSelfCheckTabJobSpecs({
  project,
  fetchProjectJobs,
}) {
  const [jobSpecs, setJobSpecs] = useState(null);

  useEffect(() => {
    if (!project) {
      return;
    }

    fetchJobSpecs();
  }, [project]);

  function fetchJobSpecs() {
    SelfCheckJobSpecService.list(project.id).then(setJobSpecs);
  }

  function postCreate(jobSpec) {
    SelfCheckJobSpecService.list(project.id).then(setJobSpecs);
  }

  return (
    <>
      <LayoutCard
        title={
          <>
            {window.gettext("Job specifications")}
            <Helper
              title={window.gettext("Create job for or view specification")}
              ml
            >
              {window.gettext(
                "Create a new job for this specification or view how the specification is configured"
              )}
            </Helper>
          </>
        }
      >
        {jobSpecs !== null && (
          <>
            {jobSpecs.length === 0 ? (
              <>
                <Alert severity="info">
                  {window.gettext("No job specifications exists yet")}
                </Alert>
              </>
            ) : (
              <>
                <Stack
                  sx={{
                    gap: 2,
                  }}
                >
                  {jobSpecs.map((jobSpec, index) => (
                    // Job Specification.
                    <Stack
                      key={index}
                      sx={{
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography variant="h5">{jobSpec.name}</Typography>

                      <Stack
                        sx={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          flexBasis: 358,
                          gap: 1,
                        }}
                      >
                        {/* Details about job spec.*/}
                        <Button
                          variant={"outlined"}
                          sx={{
                            flexBasis: 175,
                          }}
                        >
                          <Link
                            component={RouterLink}
                            to={
                              "/projects/" +
                              String(project.id) +
                              "/self-check/job-specs/" +
                              String(jobSpec.id)
                            }
                          >
                            {window.gettext("Configuration")}
                          </Link>
                        </Button>

                        {/* Start job button with modal */}
                        <ModalStartJob
                          project={project}
                          jobSpec={jobSpec}
                          postCreate={() => {
                            fetchJobSpecs();
                            fetchProjectJobs();
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{
                              flexBasis: 175,
                            }}
                          >
                            {window.gettext("Start new job")}
                          </Button>
                        </ModalStartJob>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </>
            )}
          </>
        )}
      </LayoutCard>

      {/* Create new job specification */}
      <UserPermission permission={UserPermission.permissions.job_spec_add}>
        <LayoutCard title={window.gettext("Create new job specification")}>
          <InstanceManipulator
            service={SelfCheckJobSpecService}
            urlParams={[project.id]}
            postCreate={postCreate}
            mode="create"
            form={
              <SelfCheckJobSpecForm
                submitButtonText={window.gettext("Create job specification")}
              />
            }
          />
        </LayoutCard>
      </UserPermission>
    </>
  );
}
