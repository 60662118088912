import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueSetter from "../../ValueSetter";
import getColumnWidth from "../GetColumnWidth";
import EditName from "../../edit_cell/EditName";

export default function NameColumnDefinition(
  nodes,
) {
  return {
    field: 'name',
    width: getColumnWidth('name'),
    editable: true,
    hideable: false,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'node.name'
      )
    }),
    valueSetter: ValueSetter('node.name'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Name'),
    renderEditCell : EditName(
      nodes,
    )
  }
}