import {GridEvents} from "@mui/x-data-grid-pro";
import ShiftTabEvent from "./CellKeyDown/ShiftTabEvent";
import EventData from "./CellKeyDown/EventData";
import TabEvent from "./CellKeyDown/TabEvent";
import RightArrowEvent from "./CellKeyDown/RightArrowEvent";
import EnterEvent from "./CellKeyDown/EnterEvent";

export default function CellKeyDownEventBinder(
  apiRef,
) {
  return apiRef.current.subscribeEvent(
    GridEvents.cellKeyDown,
    (params, event, details) => {

      // Check if this event is a keydown
      if (event.type !== 'keydown') {
        return
      }

      // Go through all the events we have, see what is applicable and execute that if applicable
      [
        ShiftTabEvent(),
        TabEvent(),
        RightArrowEvent(),
        EnterEvent(),
      ].map(
        (eventExecutor) => {
          const eventData = EventData(event, params, apiRef, details,)
          // Did not want to execute something on this event
          if (!eventExecutor.trigger(eventData)) {
            return undefined
          }

          // Execute event
          const result = eventExecutor.execute(eventData)

          // Deal with aftermath of event
          if (result.preventDefault === true) {
            event.nativeEvent.preventDefault()
          }
        }
      )
    }
  )
}