import {Grid} from "@mui/material";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {useEffect, useState} from "react";
import Loading from "../../../../../layout/Loading";

export default function JobMeasurementReportMedianTemperatureDistribution({report, allLocations, reportSettings}) {
  let [chartData, setChartData] = useState(null)
  let [distributionConfiguration, setDistributionConfiguration] = useState(null)

  useEffect(() => {
    if (!reportSettings) {
      return
    }

    setDistributionConfiguration(
      {
        'min': reportSettings.temperature_distribution_low * 10,
        'max': reportSettings.temperature_distribution_high * 10,
        'step': reportSettings.temperature_distribution_step * 10,
        'types': reportSettings.temperature_distribution_types,
      }
    )
  }, [reportSettings])

  useEffect(
    () => {
      if (!distributionConfiguration || !report || !allLocations) {
        return
      }

      let medianValues = allLocations.filter(
        (location) => {
          if (!distributionConfiguration.types) {
            return true
          }
          return distributionConfiguration.types.includes(location.entity.type)
        }
      ).map(
        (location) => {
          return location.room_temperature.median
        }
      )

      let temperature = distributionConfiguration.min


      const getStepConfig = (temperature) => {
        let min = (temperature) - (distributionConfiguration.step / 2)
        if (min < distributionConfiguration.min) {
          min = distributionConfiguration.min
        }

        let max = (temperature) + (distributionConfiguration.step / 2)
        if (max > distributionConfiguration.max) {
          max = distributionConfiguration.max
        }

        return {
          'label': temperature / 10,
          'min': min / 10,
          'max': max / 10,
        }
      }

      let steps = []
      steps.push(
        getStepConfig(
          distributionConfiguration.min
        )
      )
      while (temperature < distributionConfiguration.max) {
        temperature += distributionConfiguration.step
        if (temperature > distributionConfiguration.max) {
          temperature = distributionConfiguration.max
        }

        steps.push(
          getStepConfig(temperature)
        )
      }

      setChartData(
        steps.map(
          (step) => {
            return {
              'label': step.label,
              'median': medianValues.filter(
                (median) => {
                  return median >= step.min && median < step.max
                }
              ).length
            }
          }
        )
      )
    }
    ,
    [report, allLocations, distributionConfiguration]
  )

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid
          item
          sx={
            {
              mr: 2,
              mt: 2
            }
          }
        >
          <Loading
            notNull={chartData}
          >
            <ResponsiveContainer
              width={'100%'} height={500}
            >
              <LineChart
                data={chartData}
              >
                <CartesianGrid strokeDasharray="3"/>
                <XAxis dataKey="label" label={window.gettext('Temperature')} height={80}/>
                <YAxis label={window.gettext('Quantity')} orientation={'left'} width={90} interval={1}/>
                <Line type="monotone" dataKey="median" stroke="#8884d8"/>
              </LineChart>
            </ResponsiveContainer>
          </Loading>
        </Grid>

      </Grid></>
  )
}