import {TextField} from "@mui/material";
import {useState} from "react";

export default function JobDatagridMeasurementInstanceTemperatureField(props) {
  let {id, field, value, api} = props

  let [displayValue, setDisplayValue] = useState(value)

  const handleOnChange = function (event) {
    // Change the value we display
    setDisplayValue(event.target.value)

    // set "real" value
    api.setEditCellValue({id, field, value: event.target.value}, event)
  }

  const handleOnKeyDown = function (event) {
    if (["Enter", "Tab"].includes(event.code)) {
      // Save
      api.commitCellChange(
        props,
        event
      )

      // Back to view mode after we are done.
      api.setCellMode(
        props.id,
        props.field,
        'view'
      )

      // Do not propagate event
      event.stopPropagation()
    }
  }

  return (
    <>
      <TextField
        type={"number"}
        autoFocus={true}
        onKeyDown={handleOnKeyDown}
        onChange={handleOnChange}
        value={displayValue || ""}
      />
    </>
  )
}