import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import store from "../../state";

export default function HomeView() {
  const navigate = useNavigate()

  useEffect(() => {
    // If there is no user and no refresh-token, we should redirect to login page instead
    if (store.user === null && !localStorage.getItem("refresh-token")) {
      // Redirect if user is not logged in to /login/
      navigate("/login/")
    }

    // Redirect logged in users to /projects
    if (store.user !== null && localStorage.getItem("refresh-token")) {
      // Redirect users to /projects/
      navigate("/projects/")
    }
  })

  return (
    <>
    </>
  )
}