import getEntityStatus from "../../../../../utils/getEntityStatus";

export function getDoneEntities(entities, jobData) {
  return entities.filter((entity) => {
    const entityStatus = getEntityStatus(
      { entity: entity, job: jobData.job },
      jobData
    );

    return entityStatus.is_done();
  });
}

export function getFlaggedEntities(entities, jobData) {
  return entities.filter((entity) => {
    const entityStatus = getEntityStatus(
      { entity: entity, job: jobData.job },
      jobData
    );

    return entityStatus.flagged_tasks.length > 0;
  });
}

export function getPartlyDoneEntities(entities, jobData) {
  return entities.filter((entity) => {
    const entityStatus = getEntityStatus(
      { entity: entity, job: jobData.job },
      jobData
    );

    if (
      entityStatus.has_checkable() &&
      entityStatus.is_some_task_items_done_but_not_tasks()
    ) {
      return true;
    }

    return false;
  });
}
