import {createContext, useContext, useEffect, useState} from "react";
import IssueService from "../services/IssueService";
import {useParams} from "react-router-dom";
import ChoicesService from "../services/ChoicesService";


export const IssueChoicesContext = createContext({
  issueChoices: undefined,
})


export function useIssueChoices() {
  return useContext(IssueChoicesContext);
}

export default function IssueChoicesProvider(props) {
  const [issueChoices, setIssueChoices] = useState(undefined)

  useEffect(() => {
    ChoicesService.issueChoices().then(setIssueChoices)
  }, []);

  return (
    <IssueChoicesContext.Provider
      value={
        {
          'issueChoices': issueChoices,
        }
      }
    >
      {(issueChoices !== undefined && props.children) && (
        <>
          {props.children}
        </>
      )}
    </IssueChoicesContext.Provider>
  )
}
