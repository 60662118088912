import { LayoutAppDesktop, LayoutAppMobile } from "./app";
import { useLayoutSizeTrackerContext } from "./context";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import store from "../state";
import { subscribe } from "valtio";

export function LayoutApp({ children }) {
  const { isMobile } = useLayoutSizeTrackerContext();
  const [drpErrorResponse, setDRPErrorResponse] = useState(null)

  const {enqueueSnackbar} = useSnackbar();

  let unsubscribe;

  useEffect(() => {
      // Subscribe to store changes
      unsubscribe = subscribe(store, () => {
        setDRPErrorResponse(store.drp_error_from_request_response || null)
      })

      return () => {
        unsubscribe();
      }
    }
  )

  useEffect(() => {
    if (drpErrorResponse) {
      enqueueSnackbar(
        drpErrorResponse.error + ": " + drpErrorResponse.details,
        {
          variant: "error",
        }
      );
    }
  }, [drpErrorResponse]);

  return (
    <>
      {!isMobile ? (
        <LayoutAppDesktop>{children}</LayoutAppDesktop>
      ) : (
        <LayoutAppMobile>{children}</LayoutAppMobile>
      )}
    </>
  );
}
