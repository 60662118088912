import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

export const UNIT_PASCAL = 'pa'

export const UNIT_KILO_PASCAL = 'kpa'

export const UNIT_AUTO = 'auto'

/*
* props.value = the value in pascals
* props.precision = how many decimal points we should display
* props.unit = kpa/pa
* */
export default function Pressure(props) {
  const defaultUnit = UNIT_KILO_PASCAL

  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(0)
  let [unit, setUnit] = useState(defaultUnit)
  let [suffix, setSuffix] = useState("")


  // Set the rounded string value
  useEffect(() => {
    // Convert from pascals to KiloPascals
    let value = parseInt(props.value)

    if (unit === UNIT_PASCAL) {
      // Do nothing
    } else {
      value = value / 1000
    }

    // Display string, fixed points
    setDisplayValue(
      value
    )
  }, [props.value, precision, unit])

  useEffect(() => {
    if(props.unit === UNIT_AUTO){
      setUnit(props.value >= 1000 ? UNIT_KILO_PASCAL : UNIT_PASCAL)
    }else{
      setUnit(props.unit === undefined ? defaultUnit : props.unit)
    }
  }, [props.unit, props.value, defaultUnit])

  // setting precision
  useEffect(() => {
    let defaultPrecision = 1

    if (unit === UNIT_PASCAL) {
      if (props.precision === undefined) {
        defaultPrecision = 0
      }

      setSuffix("Pa")
    }

    if (unit === UNIT_KILO_PASCAL) {
      if (props.precision === undefined) {
        defaultPrecision = 1
      }

      setSuffix("kPa")
    }

    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [unit, props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} additionalSuffix={props.additionalSuffix} />
  )
}