import BaseService from "./BaseService";

class InstalledValueService extends BaseService {}

export default new InstalledValueService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'node',
    'installed-values',
  ]
)