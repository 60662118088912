import RowCountValidator from "./system/RowCountValidator";
import PumpPressureValidator from "./system/PumpPressureValidator";
import FieldsValidator from "./rows/FieldsValidator";
import LeafNodesAreRadiatorValidator from "./system/LeafNodesAreRadiatorValidator";

export default class SystemValidator {
  constructor() {
    this.systemValidators = [
      new RowCountValidator(),
      new PumpPressureValidator(),
      new LeafNodesAreRadiatorValidator(),
    ]

    this.rowValidators = [
      new FieldsValidator(),
    ]
  }

  validators = () => {
    return [].concat(
      this.systemValidators
    ).concat(
      this.rowValidators
    )
  }

  /**
   * This is being called in SystemBuildTable getValidationResults
   *
   * @param rows
   * @param systemData
   * @return {{warnings: *[], errors: *[]}}
   */
  validate = (
    rows,
    systemData
  ) => {
    const errors = this.validators()
      // Get all errors for all validators
      .map(
        (validator) => {
          return validator.getErrors(rows, systemData)
        }
      )
      // Flatten the array since we at this stage have a multi dimensional array
      .flat()
      // Filter out errors that are faulty in some way
      .filter(
        (error) => {
          return error !== undefined
        }
      )
      // Get the object representation of the error
      .map(
        (error) => {
          return error.repr()
        }
      )

    const warnings = this.validators()
      // Get all errors for all validators
      .map(
        (validator) => {
          return validator.getWarnings(rows, systemData)
        }
      )
      // Flatten the array since we at this stage have a multi dimensional array
      .flat()
      // Filter out errors that are faulty in some way
      .filter(
        (error) => {
          return error !== undefined
        }
      )
      // Get the object representation of the error
      .map(
        (error) => {
          return error.repr()
        }
      )

    return {
      'warnings': warnings,
      'errors': errors,
    }
  }
}