/**
 * @param eventData
 * @param place {"first"|"last"|number}
 */
import GetColumnIndex from "./GetColumnIndex";

export default function GetColumnAtIndex(eventData, place) {
  const columns = eventData.apiRef.current.getVisibleColumns()

  if(place === "first"){
    place = 0
  }

  if(place === "last"){
    place = columns.length - 1
  }

  if(place === undefined){
    place = GetColumnIndex(eventData)
  }

  if(place >= columns.length){
    return undefined
  }

  return columns[place]
}
