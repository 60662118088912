export default class EntityStatus {
  task_specs = []
  tasks = []
  tasks_done = []
  flagged_tasks = []
  commented_tasks = []

  task_items_specs = []
  task_items = []
  task_items_done = []
  commented_task_items = []

  measurement_status = {}

  entityData = undefined

  constructor(
    task_specs,
    tasks,
    tasks_done,
    flagged_tasks,
    commented_tasks,
    task_item_specs,
    task_items,
    task_items_done,
    commented_task_items,
    measurement_status,
    entityData
  ) {
    this.task_specs = task_specs
    this.tasks = tasks
    this.tasks_done = tasks_done
    this.flagged_tasks = flagged_tasks
    this.commented_tasks = commented_tasks

    this.task_items_specs = task_item_specs
    this.task_items = task_items
    this.task_items_done = task_items_done
    this.commented_task_items = commented_task_items
    this.measurement_status = measurement_status
    this.entityData = entityData
  }

  has_checkable = () => {
    if (this.task_specs.length > 0) {
      return true
    }

    if (this.task_items_specs.length > 0) {
      return true
    }

    return false
  }

  /**
   * Checks if the entity is Done
   * Can check if
   *  All tasks are done
   *  All task items are done
   *
   *  Defaults to check Only tasks.
   *
   * @param task_items = undefined
   * @param tasks = undefined
   * @returns {boolean}
   */
  is_done = (task_items, tasks) => {
    // If we should count task items
    if (task_items === undefined) {
      task_items = false
    }

    // If we should count tasks
    if (tasks === undefined) {
      tasks = true // Default to check tasks
    }

    // Check if task items are done, if Not done abort and return false
    if (task_items) {
      if (!this.is_task_items_done()) {
        return false
      }
    }

    // Check if tasks are done, if Not done abort and return false
    if (tasks) {
      if (!this.is_tasks_done()) {
        return false
      }
    }

    return true
  }

  is_tasks_done = () => {
    return this.task_specs.length === this.tasks_done.length
  }

  get_task_items_not_done_count = () => {
    return this.task_items.length - this.get_task_items_done_count()
  }

  get_task_items_done_count = () => {
    return this.task_items_done.length
  }

  is_task_items_done = () => {
    console.error('This seems to be broken, be sure to debug this later.')
    return this.get_task_items_not_done_count() === 0
  }

  is_some_task_items_done_but_not_tasks = () => {
    /**
     * If there are more than one Task-Item that has been marked as done
     * AND
     * Not all Tasks are done
     *
     * This is because if we have marked one or more Task-Items as done (they are labeled as 'No entry' - tasks)
     * Done in this case is a Negative.
     *
     * So if we have One or more Negatively marked Task-Items we are not happy.
     * But if we have marked the acutal Task as Done, we Are happy (this is labeled as 'Apartment done')
     *
     * Similar functionality is done in the installation report
     */
    if (this.get_task_items_done_count() > 0) {
      if (!this.is_tasks_done()) {
        return true
      }
    }

    return false
  }

  comments_count = () => {
    return this.commented_tasks.length + this.commented_task_items.length
  }

  get_task_spec = (id) => {
    return this.entityData.job.task_specs.find(
      (task_spec) => {
        return task_spec.id === id
      }
    )
  }

  get_task_item_spec = (id) => {
    return this.entityData.job.task_item_specs.find(
      (task_item_spec) => {
        return task_item_spec.id === id
      }
    )
  }

  comments = () => {
    return [
      ...this.commented_task_items.map(
        (commented_task_item) => {
          return {
            'task_item': commented_task_item,
            'comment': commented_task_item.comment,
            'spec': this.get_task_item_spec(commented_task_item.spec_id),
          }
        }
      ),
      ...
        this.commented_tasks.map(
          (commented_task) => {
            return {
              'task': commented_task,
              'comment': commented_task.comment,
              'spec': this.get_task_spec(commented_task.spec_id),
            }
          }
        ),
    ]
  }
}