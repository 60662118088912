const get_details = function (error){
   if(!error.response){
    console.error('Error response is not valid')
    return
  }

  if(!error.response.hasOwnProperty('data')){
    console.error('Could not find data on error response')
    return
  }

  if (!error.response.data.hasOwnProperty('details')) {
    console.error('Could not find details on response')
    return
  }

  return error.response.data.details
}

export default function getErrorResponseMessage(error, default_message) {
  default_message = default_message || window.gettext('Unknown error')

  return get_details(error) || default_message
}