import SystemValidationError from "./SystemValidationError";

export default class BaseValidator {
  ERROR_TYPE_SYSTEM = 'system'
  ERROR_TYPE_ROWS = 'rows'
  ERROR_TYPE_SYSTEM_DATA = 'system_data'
  ERROR_TYPE_FIELD = 'field'

  getErrors = (rows, systemData) => {
    return [].concat(
      this.getRowsErrors(rows)
    ).concat(
      this.getSystemDataErrors(systemData)
    )
  }

  getWarnings = (rows, systemData) => {
    return [].concat(
      this.getRowsWarnings(rows)
    )
  }

  getRowsWarnings = (rows) => {
    return []
  }

  getRowsErrors = (rows) => {
    return []
  }

  getSystemDataErrors = (systemData) => {
    return []
  }

  error = (errorType, errorMessage, rows) => {
    return new SystemValidationError(
      errorType,
      errorMessage,
      rows,
    )
  }
}