import { Alert, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import Loading from "../../../../../../layout/Loading";
import MultilineText from "../../../../../../layout/MultilineText";
import { ReportInstallationProfileContext } from "../../../ReportInstallationProfileContext";

export default function JobReportInstallationProfileList() {
  const { project_id, system_id, job_spec_id, job_id } = useParams();

  const { listProfiles } = useContext(ReportInstallationProfileContext);
  const [profiles, setProfiles] = useState(null);

  useEffect(() => {
    listProfiles().then(setProfiles);
  }, [listProfiles]);

  return (
    <Loading notNull={[profiles]}>
      {profiles?.length === 0 ? (
        <Alert severity={"info"}>
          {window.gettext("No installation report profiles found")}
        </Alert>
      ) : (
        <>
          {profiles?.map((profile, index) => {
            return (
              <Stack key={profile.id}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      my: 2,
                    }}
                  />
                )}

                <Grid container direction={"column"} spacing={2}>
                  <Grid item>
                    <Typography variant={"h4"}>{profile.name}</Typography>
                  </Grid>
                  <Grid item>
                    <MultilineText>{profile.note}</MultilineText>
                  </Grid>
                </Grid>

                <Button
                  variant={"outlined"}
                  component={Link}
                  to={
                    "/projects/" +
                    project_id +
                    "/systems/" +
                    system_id +
                    "/job-specs/" +
                    job_spec_id +
                    "/job/" +
                    job_id +
                    "/report/installation/profile/" +
                    profile.id
                  }
                >
                  {window.gettext("View with this profile")}
                </Button>
              </Stack>
            );
          })}
        </>
      )}
    </Loading>
  );
}
