import React from "react";

export default function DumbWrap(props) {
  let {children} = props

  let text = children

  if (typeof (children) === "string") {
    const breakBeforeWords = [
      'temperatur',
    ]

    breakBeforeWords.forEach((breakBeforeWord) => {
      text = text.replaceAll(breakBeforeWord, '&shy;' + breakBeforeWord)
    })
  }

  return (
    <>
      <span dangerouslySetInnerHTML={{'__html': text}}></span>
    </>
  )
}
