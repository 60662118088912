export class FormFieldArguments {
  /**
   *
   * @param {{default_value}, {name: string}, {label: string}, {required: boolean}} values
   */
  constructor(values) {
    this.name = values["name"];
    this.label = values["label"];
    this.required = values["required"] || false;
    this.default_value = values["default_value"];
  }
}
