import { Stack, Typography } from "@mui/material";

import LoginForm from "../../components/user/LoginForm";

export default function LoginView() {
  return (
    <Stack
      sx={{
        maxWidth: 500,
        width: "100%",
        mt: 10,
        mx: "auto",
        p: 6,
        gap: 1,
      }}
    >
      <Stack>
        <Typography variant="h1">{window.gettext("Log in")}</Typography>

        <Typography>
          {window.gettext("Enter your login detail below")}
        </Typography>
      </Stack>

      <LoginForm />
    </Stack>
  );
}
