import { CircularProgress, Dialog, Typography } from "@mui/material";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import hash from "object-hash";
import { useCallback, useEffect, useState } from "react";

import InstanceManipulator from "../../InstanceManipulator";
import PipeForm from "../../node/pipe/PipeForm";
import ChoicesService from "../../services/ChoicesService";
import PipeDimensionService from "../../services/PipeDimensionService";
import PipeService from "../../services/PipeService";
import PipeTypeService from "../../services/PipeTypeService";
import RadiatorService from "../../services/RadiatorService";
import RadiatorValveDimensionService from "../../services/RadiatorValveDimensionService";
import RadiatorValveTypeService from "../../services/RadiatorValveTypeService";
import SystemRoomService from "../../services/SystemRoomService";
import SystemService from "../../services/SystemService";
import ValveService from "../../services/ValveService";
import ValveTypeService from "../../services/ValveTypeService";
import getErrorResponseMessage from "../../utils/get_error_response_message";
import getObjectById from "../utils/getObjectById";
import BindEvents from "./BindEvents";
import getColumnDefinitions from "./ColumnDefinitions";
import {
  changeColumnSettingsProperty,
  getColumnsWithAppliedColumnSettings,
  saveColumnSettings,
} from "./ColumnSettings";
import CustomGridToolbar from "./CustomGridToolbar";
import GetCellClassName from "./GetCellClassName";
import GetRowClassName from "./GetRowClassName";
import { getRows, getRowsWithCalculations, getRowsWithErrors } from "./GetRows";
import {
  ACTION_COPY_NODE,
  ACTION_CREATE_PIPE,
  ACTION_CREATE_RADIATOR,
  ACTION_CREATE_VALVE,
  ACTION_DELETE_NODE,
  ACTION_SET_FLAG,
} from "./GridRowAction";
import GridRowActionCallbackPayload from "./GridRowActionCallbackPayload";
import NoItemOnNodeNotificationActionFactory from "./NoItemOnNodeNotificationActionFactory";
import BottomDrawer, {
  BOTTOM_DRAWER_HANDLE_HEIGHT,
} from "./bottom_drawer/BottomDrawer";
import GroupingColumnDefinition from "./column_definitions/columns/GroupingColumnDefinition";
import DataGetter from "./data/DataGetter";
import SystemValidator from "./validation/SystemValidator";

function loadSettingsFromLocalStorage(key, defaultValue) {
  const system_build_settings = localStorage.getItem(key) || undefined;

  if (system_build_settings === undefined) {
    return defaultValue;
  }

  let parsed = undefined;

  try {
    parsed = JSON.parse(system_build_settings);
  } catch (err) {
    // Silently fail
    return defaultValue;
  }

  if (parsed === undefined) {
    return defaultValue;
  }

  return parsed;
}

export function SystemBuildRadiatorTable({ system, setSystem, project }) {
  const { enqueueSnackbar } = useSnackbar();

  const apiRef = useGridApiRef();

  const [nodes, setNodes] = useState(undefined);
  const [calculations, setCalculations] = useState(undefined);
  const [rows, setRows] = useState(undefined);
  const [pipeTypes, setPipeTypes] = useState(undefined);
  const [pipeDimensions, setPipeDimensions] = useState(undefined);
  const [systemChoices, setSystemChoices] = useState(undefined);
  const [radiatorValveTypes, setRadiatorValveTypes] = useState(undefined);
  const [radiatorValveDimensions, setRadiatorValveDimensions] =
    useState(undefined);
  const [valveTypes, setValveTypes] = useState(undefined);
  const [systemRooms, setSystemRooms] = useState(undefined);
  const [columns, setColumns] = useState(undefined);
  const [validationResults, setValidationResults] = useState(undefined);
  const [tableHeight, setTableHeight] = useState(
    window.innerHeight - 108 - BOTTOM_DRAWER_HANDLE_HEIGHT
  );
  const [autoRefreshCalculations, setAutoRefreshCalculations] = useState(false);
  const [nodesHash, setNodesHash] = useState(undefined);
  const [systemDataHash, setSystemDataHash] = useState(undefined);
  const [groupingColDef, setGroupingColDef] = useState(
    GroupingColumnDefinition()
  );
  const [disableFetchButton, setDisableFetchButton] = useState(false);
  const [rowBufferCount, setRowBufferCount] = useState(3);
  const [showLoadingSplash, setShowLoadingSplash] = useState(false);
  const [settings, setSettings] = useState(
    loadSettingsFromLocalStorage("system_build_settings", {})
  );
  const [columnSettings, setColumnSettings] = useState(
    loadSettingsFromLocalStorage("column_settings", [])
  );

  const addNodes = useCallback(
    (...nodesToAdd) => {
      let allNewNodes = [];

      nodes.map((node) => {
        allNewNodes.push(node);
        return undefined;
      });

      while (nodesToAdd.length > 0) {
        const nodeToAdd = nodesToAdd.shift();
        // Go through each and every node in the list of nodes
        // Find the node where 'id' in existing node matches the parent.id of the node to add
        for (let i = 0; i < allNewNodes.length; i += 1) {
          const node = allNewNodes[i];

          // If we match with parent.id splice in new node after this index
          if (node.id === nodeToAdd.parent.id) {
            allNewNodes.splice(i + 1, 0, nodeToAdd);
            break;
          }
        }
      }

      setNodes(allNewNodes);
    },
    [nodes]
  );

  const addChildNodeWithService = useCallback(
    (service, parentNode, extra_data) => {
      // Default to empty object

      return new Promise((resolve, reject) => {
        // Using the service supplied and create a node with parent node
        service
          .create(
            {
              ...{
                parent_node: parentNode.id,
              },
              ...(extra_data || {}),
            },
            project.id,
            system.id
          )
          .then(
            (data) => {
              // After creation, fetch node data
              SystemService.node(project.id, system.id, data.node.id).then(
                (nodeData) => {
                  // Resolve promise with node that we fetched from API
                  // This has parent and more info.
                  resolve(nodeData);
                }
              );
            },
            // On error, reject promise
            (reason) => {
              reject(reason);
            }
          );
      });
    },
    [project.id, system.id]
  );

  const getValidationResults = useCallback(
    (rows) => {
      return new SystemValidator().validate(rows, system);
    },
    [system]
  );

  const refreshSystemNodes = useCallback(
    (callback) => {
      // then fetch new ones.
      SystemService.nodes(project.id, system.id).then((data) => {
        setNodes(data);

        // Call callback if defined.
        if (callback) {
          callback.call();
        }
      });
    },
    [project.id, system.id]
  );

  const refreshSystemCalculations = useCallback(() => {
    setDisableFetchButton(true);
    console.log("refresh sys calcs");

    SystemService.calculations(project.id, system.id).then(
      (calculationResults) => {
        setDisableFetchButton(false);
        setCalculations(calculationResults);
        enqueueSnackbar(window.gettext("Calculation refreshed"), {
          variant: "success",
        });
      },
      (error) => {
        setDisableFetchButton(false);

        enqueueSnackbar(
          getErrorResponseMessage(
            error,
            window.gettext("Error while getting calculation results")
          ),
          {
            variant: "error",
          }
        );

        if (
          error.response &&
          error.response.data &&
          error.response.data.error === "NodeCalculationError"
        ) {
          enqueueSnackbar(error.response.data.exception.details, {
            variant: "warning",
          });

          enqueueSnackbar(
            window.gettext("Problem occurred at node:") +
              " " +
              getObjectById(nodes, error.response.data.node_id).name,
            {
              variant: "warning",
            }
          );
        }
      }
    );
  }, [enqueueSnackbar, nodes, project.id, system.id]);

  const rowActionCallback = useCallback(
    (action) => {
      console.log("rowActionCallback received", action);
      setShowLoadingSplash(true);

      let payload = new GridRowActionCallbackPayload(action, undefined);

      // Create pipe
      if (action.action_type === ACTION_CREATE_PIPE) {
        addChildNodeWithService(
          PipeService,
          action.row.node,
          action.extra_data
        ).then((newPipeNode) => {
          addNodes(newPipeNode);

          payload.setFocusRowId(newPipeNode.id);

          if (action.callback !== undefined) {
            action.callback.call(action, payload);
          }

          setShowLoadingSplash(false);
        });
      }

      // Create valve
      if (action.action_type === ACTION_CREATE_VALVE) {
        addChildNodeWithService(ValveService, action.row.node).then(
          (newValveNode) => {
            addNodes(newValveNode);

            payload.setFocusRowId(newValveNode.id);

            if (action.callback !== undefined) {
              action.callback.call(action, payload);
            }

            setShowLoadingSplash(false);
          }
        );
      }

      // Create radiator (create radiator with pipe, and without pipe)
      if (action.action_type === ACTION_CREATE_RADIATOR) {
        if (action.extra_data.omit_pipe === true) {
          // If we are to omit pipe when creating radiator
          // add it directly to this node
          addChildNodeWithService(RadiatorService, action.row.node).then(
            (newRadiatorNode) => {
              addNodes(newRadiatorNode);

              payload.setFocusRowId(newRadiatorNode.id);

              if (action.callback !== undefined) {
                action.callback.call(action, payload);
              }

              setShowLoadingSplash(false);
            }
          );
        } else {
          // Add pipe, then radiator.
          addChildNodeWithService(PipeService, action.row.node).then(
            (newPipeNode) => {
              addChildNodeWithService(RadiatorService, newPipeNode).then(
                (newRadiatorNode) => {
                  // Add both the nodes we just created
                  addNodes(newPipeNode, newRadiatorNode);

                  payload.setFocusRowId(newRadiatorNode.id);

                  if (action.callback !== undefined) {
                    action.callback.call(action, payload);
                  }

                  setShowLoadingSplash(false);
                }
              );
            }
          );
        }
      }

      // Delete node
      if (action.action_type === ACTION_DELETE_NODE) {
        const deletedNode = Object.assign(action.row.node);

        SystemService.delete_node(
          project.id,
          system.id,
          action.row.node.id,
          action.extra_data
        ).then((response) => {
          payload.setFocusRowId(deletedNode.parent.id);

          enqueueSnackbar(
            window.interpolate(
              window.ngettext(
                "Deleted %s node",
                "Deleted %s nodes",
                response.deleted_count
              ),
              [response.deleted_count]
            ),
            {
              variant: "success",
            }
          );

          // After refresh system nodes call is done, exec callback
          refreshSystemNodes(() => {
            if (action.callback !== undefined) {
              action.callback.call(action, payload);
            }

            setShowLoadingSplash(false);
          });
        });
      }

      if (action.action_type === ACTION_COPY_NODE) {
        console.log("copy node", action);

        const {
          keep_names,
          name_replacements,
          node_id,
          parent,
          project_id,
          system_id,
        } = action.extra_data;

        SystemService.copy_node(
          {
            name_replacements: name_replacements,
            keep_names: keep_names,
            // In case we have specified the parent in extra_data, let us use that parent.id as parentId
            parent: parent?.id || action.row.node.id, // Node id of the new parent
          },
          project_id || project.id,
          system_id || system.id,
          node_id || action.row.node.id // node id,
        ).then((response) => {
          payload.setFocusRowId(response.node.id);

          // After refresh system nodes call is done, exec callback
          refreshSystemNodes(() => {
            // Set to new copied node (the first one)
            if (action.callback !== undefined) {
              action.callback.call(action, payload);
            }

            setShowLoadingSplash(false);
          });
        });
      }

      if (action.action_type === ACTION_SET_FLAG) {
        console.log("set flag node", action);

        const { flag, include_self } = action.extra_data;

        SystemService.update_descendants(
          {
            flag: flag,
          },
          project.id,
          system.id,
          action.row.node.id, // node id
          include_self
        ).then((response) => {
          payload.setFocusRowId(action.row.node.id);

          // After refresh system nodes call is done, exec callback
          refreshSystemNodes(() => {
            // Set to new copied node (the first one)
            if (action.callback !== undefined) {
              action.callback.call(action, payload);
            }

            setShowLoadingSplash(false);
          });
        });
      }
    },
    [
      addChildNodeWithService,
      addNodes,
      enqueueSnackbar,
      project.id,
      refreshSystemNodes,
      system.id,
    ]
  );

  useEffect(() => {
    refreshSystemNodes();

    PipeDimensionService.fetch().then(setPipeDimensions);
    PipeTypeService.fetch().then(setPipeTypes);
    ValveTypeService.fetch().then(setValveTypes);
    RadiatorValveTypeService.fetch().then(setRadiatorValveTypes);
    SystemRoomService.fetch(project.id, system.id).then(setSystemRooms);
    ChoicesService.systemChoices().then(setSystemChoices);
    RadiatorValveDimensionService.fetch().then(setRadiatorValveDimensions);
  }, [project.id, refreshSystemNodes, system.id]);

  // When the source data changes we need to set new columns
  useEffect(() => {
    // each time nodes or rows update we need to send new column definitions
    // because otherwise we work with old scopes.
    if (
      !nodes ||
      !pipeDimensions ||
      !pipeTypes ||
      !valveTypes ||
      !radiatorValveTypes ||
      !systemRooms ||
      !columnSettings ||
      !radiatorValveDimensions
    ) {
      return;
    }

    setColumns(
      getColumnsWithAppliedColumnSettings(
        columnSettings,
        getColumnDefinitions(
          rowActionCallback,
          nodes,
          pipeDimensions,
          pipeTypes,
          valveTypes,
          systemRooms,
          radiatorValveTypes,
          radiatorValveDimensions
        )
      )
    );
  }, [
    rows,
    nodes,
    pipeDimensions,
    pipeTypes,
    valveTypes,
    radiatorValveTypes,
    rowActionCallback,
    systemRooms,
    columnSettings,
    radiatorValveDimensions,
  ]);

  // Save column settings when they are changed
  useEffect(() => {
    saveColumnSettings(columnSettings);
  }, [columnSettings]);

  // Only for Tree data column
  // If we have set the tree data group column in column settings, we need to update the groupingColDef
  useEffect(() => {
    // Copy it so we can set it later as new object with setter
    const newGroupingColDef = Object.assign(groupingColDef);

    // Get settings from columnSettings
    const groupingColumnSettings = columnSettings.find((s) => {
      return s.field === "__tree_data_group__";
    });

    // If we do not find the setting a settings, apply it to the groupingColCof that is set on the DataGrid component
    if (
      !groupingColumnSettings ||
      !groupingColumnSettings.hasOwnProperty("settings")
    ) {
      return;
    }

    // If we have set a width change, apply that
    // Parse width setting to be integer, could come back as string of float.
    const width = parseInt(groupingColumnSettings.settings["width"], 10);

    // If with is a valid integer, set/update the with param.
    if (Number.isInteger(width)) {
      console.log("Setting with width", width);
      newGroupingColDef.width = width;
    }

    console.log("ColumnSettings", groupingColumnSettings.settings);

    newGroupingColDef.hide = groupingColumnSettings.settings["hide"];
    setGroupingColDef(newGroupingColDef);
  }, [columnSettings, groupingColDef]);

  // Grouping column definition changed (for instance if we show/hide the grouping)
  useEffect(() => {
    console.log("Groping col def changed", groupingColDef);
  }, [groupingColDef]);

  // General settings
  useEffect(() => {
    localStorage.setItem("system_build_settings", JSON.stringify(settings));
  }, [settings]);

  /**
   * When nodes or calculations change
   *
   * 1. Set rows for datagrid to display
   */
  useEffect(() => {
    if (nodes !== undefined) {
      // Nothing is undefined, set the rows.
      let newRows = getRows(nodes);

      newRows = getRowsWithCalculations(newRows, calculations);

      let newValidationResults = undefined;

      // If there is a system, add validation errors to the rows
      if (system !== undefined) {
        newValidationResults = getValidationResults(newRows);

        newRows = getRowsWithErrors(newRows, newValidationResults);

        setValidationResults(newValidationResults);
      }

      // Get hash for nodes to be used to check if we should refresh or not
      const newNodesHash = hash(nodes || []);

      // Get hash for system data to be used to check if we should refresh or not
      const newSystemDataHash = hash(system || {});

      // If:
      // No validation errors
      // AutoRefreshCalculations is set to true
      if (
        newValidationResults !== undefined &&
        newValidationResults.errors.length === 0 &&
        autoRefreshCalculations
      ) {
        // Only fetch new calculations if any of the values in the nodes or the system data has changed.
        if (
          newNodesHash !== nodesHash ||
          newSystemDataHash !== systemDataHash
        ) {
          refreshSystemCalculations();
        }
      }

      setSystemDataHash(newSystemDataHash);
      setNodesHash(newNodesHash);

      // Get validation results from the validation engine
      setRows(newRows);
    }
  }, [
    autoRefreshCalculations,
    calculations,
    getValidationResults,
    nodes,
    nodesHash,
    refreshSystemCalculations,
    system,
    systemDataHash,
  ]);

  // Bind events using apiRef subscribeEvent
  useEffect(() => {
    // If there are no apiRef, rows or columns
    // or
    // If the events are already bound, just ignore.
    if (!rows || rows.length === 0 || !apiRef || !columns) {
      return;
    }

    // Set focus on the first row when the rows have loaded.
    // todo: Need to make sure this is only done once, not every time rows are updated
    //apiRef.current.setCellFocus(rows[0].id, 'actions')

    // Return the unsubscribe function
    return BindEvents(apiRef);
  }, [apiRef, rows, columns]);

  // Get path for node
  const getTreeDataPath = useCallback(
    (row) => {
      // TeeDataPath needs to contain also the current item id, not only the ancestors
      const path = [row.node.id].concat(row.node.ancestors);

      // Make the list be names of nodes
      return path
        .map((id) => {
          const node = getObjectById(nodes, id);

          if (!node) {
            console.warn(
              "Could not find node, most probably it has been deleted and is not present in the nodes"
            );
            console.log("Will use ID for this row");
            console.log("row", row);
            return String(id);
          }

          // Return the name so that the Name of the node is the visible part in the grouping.
          return node.name;
        })
        .reverse();
    },
    [nodes]
  );

  const postPipeCreate = useCallback(
    (response) => {
      refreshSystemNodes();
    },
    [refreshSystemNodes]
  );

  const refreshNode = useCallback(
    (node_id, callback) => {
      SystemService.node(project.id, system.id, node_id).then(
        (refreshedNode) => {
          let allNewNodes = [];
          nodes.map((node) => {
            if (node.id === refreshedNode.id) {
              allNewNodes.push(refreshedNode);
              return undefined;
            }

            allNewNodes.push(node);
            return undefined;
          });

          setNodes(allNewNodes);

          if (callback !== undefined) {
            callback();
          }
        }
      );
    },
    [nodes, project.id, system.id]
  );

  const setSearchMode = useCallback(
    (enable) => {
      console.log("search mode", enable);

      if (!enable) {
        setRowBufferCount(0);
      }

      setRowBufferCount(rows.length || 0);
    },
    [rows]
  );

  /**
   * Update names on nodes that have this as parent
   */
  const updateNameAndParent = useCallback(
    (changedNode) => {
      // Update 'name' on 'parent' where the parent.id matches this changedNode.id
      let allNewNodes = [];

      nodes.map((node) => {
        // If this node is the same as the parent node, change the name on the parent node here
        if (node.parent && node.parent.id === changedNode.id) {
          node.parent.name = changedNode.name;
        }

        if (node.id === changedNode.id) {
          node.name = changedNode.name;
        }

        allNewNodes.push(node);
        return undefined;
      });

      setNodes(allNewNodes);
    },
    [nodes]
  );

  /**
   * When we save a node, this is called.
   */
  const handleCellEditCommit = useCallback(
    async (params) => {
      /*
      Wanted to show loading splash but could not due to keyboard navigation was interrupted.
      //setShowLoadingSplash(true)
      * */

      try {
        const dataGetter = new DataGetter();

        // Get node from nodes array
        const originalNode = getObjectById(nodes, params.id);

        const data = dataGetter.getData(originalNode, params);

        // If there is nothing in the data, there is no point in sending it.
        if (Object.keys(data).length === 0) {
          console.log("No data, aborting");
          return;
        }

        // In case of parent we use a special endpoint.
        if (["parent", "flag"].includes(params.field)) {
          // Parent field is a special one
          SystemService.update_node(
            data,
            project.id,
            system.id,
            originalNode.id
          ).then((response) => {
            // Easy way to redo-order
            refreshSystemNodes();
          });
        } else {
          // Other fields
          let service = undefined;

          // In case of rooms, we change to room_id
          if (params.field === "room") {
            // If we are trying to add a new room, run a blocking call to create that room,
            // Then use the ID we get back and set that to room_id on data and execute
            if (
              typeof params.value === "object" &&
              params.value.hasOwnProperty("new_value") &&
              params.value.hasOwnProperty("input_value")
            ) {
              // Create room on system
              await SystemRoomService.create(
                {
                  name: params.value.input_value,
                },
                project.id,
                system.id
              ).then((new_room_response) => {
                // Setting room_id on data, we can now call with a valid room_id

                // New room and its id
                // Set the newly created room id to response in data.
                // This is a bit of a hack and if we do similar things
                data.room_id = new_room_response.id;

                enqueueSnackbar(window.gettext("New room created"), {
                  variant: "success",
                });

                // Refresh system rooms, is done async, response might get back _after_ the patch is returned.
                SystemRoomService.fetch(project.id, system.id).then(
                  setSystemRooms
                );
              });
            }
          }

          console.log("Data to send", data);

          // Use the correct service depending on node type
          if (originalNode.type === "pipe") {
            service = PipeService;
          }
          if (originalNode.type === "radiator") {
            service = RadiatorService;
          }
          if (originalNode.type === "valve") {
            service = ValveService;
          }

          if (service === undefined) {
            console.error(
              "Could not determine service name for node with type " +
                String(originalNode.type)
            );
            return;
          }

          const originalNodeItem = originalNode.item;

          /* Button in the notification that there is no item for the node */

          if (originalNodeItem === null || originalNodeItem === undefined) {
            // error notification
            enqueueSnackbar(
              window.gettext(
                "Error while trying to save node, if this problem is recurring, contact your system administrator"
              ),
              {
                variant: "error",
              }
            );
            // Notification with action button to create item
            enqueueSnackbar(
              window.gettext(
                "Could not find connected item for node, type: " +
                  String(originalNode.type)
              ),
              {
                variant: "error",
                action: NoItemOnNodeNotificationActionFactory(
                  project,
                  system,
                  originalNode,
                  (response) => {
                    enqueueSnackbar(
                      window.gettext(
                        "Created a new item (" +
                          response.node.type +
                          ') for node, you can now use "' +
                          response.node.name +
                          '" as a normal node'
                      ),
                      {
                        variant: "success",
                      }
                    );
                    refreshSystemNodes();
                  }
                ),
              }
            );

            // Abort
            return;
          }

          // If there are more than 0 changed things on the node
          service.update(data, project.id, system.id, originalNodeItem.id).then(
            (changedItem) => {
              // In case of name change, update name on this and parent.
              if (params.field === "name") {
                updateNameAndParent(changedItem.node);
              }

              // Refresh node
              refreshNode(changedItem.node.id, () => {
                // User feedback
                enqueueSnackbar(window.gettext("Node saved"), {
                  variant: "success",
                });
              });
            },
            (error) => {
              // reloading the node from server since we had problems saving it
              // and it might be updated on the frontend
              // get the original data from server
              refreshNode(originalNode.id, () => {
                // Show error message to user
                enqueueSnackbar(getErrorResponseMessage(error), {
                  variant: "error",
                });
              });
            }
          );
        }
      } catch (error) {
        setShowLoadingSplash(false);
        console.error("Error while saving", error);
        console.info("Erroring params", params);
      }
    },
    [
      enqueueSnackbar,
      nodes,
      project,
      system,
      refreshNode,
      refreshSystemNodes,
      updateNameAndParent,
    ]
  );

  const handleColumnVisibilityChange = useCallback(
    (params, event, details) => {
      setColumnSettings(
        changeColumnSettingsProperty(
          columnSettings,
          params.field,
          "hide",
          !params.isVisible
        )
      );
    },
    [columnSettings]
  );

  const handleColumnWidthChange = useCallback(
    (params, event, details) => {
      const newColumnSettings = changeColumnSettingsProperty(
        columnSettings,
        params.colDef.field,
        "width",
        params.width
      );

      // Setting the new settings object, including the new with for the field
      setColumnSettings(newColumnSettings);
    },
    [columnSettings]
  );

  return (
    <>
      {rows === undefined || columns === undefined ? (
        <>
          {/* Loading ... */}
          <h1>Loading...</h1>
        </>
      ) : (
        <>
          {
            /* If there are nodes, show the datagrid */
            rows.length > 0 ? (
              <>
                <div
                  data-test-id="system-build-datagrid-container"
                  style={{
                    width: "100%",
                    height: String(tableHeight) + "px",
                  }}
                >
                  <DataGridPro
                    apiRef={apiRef}
                    sx={{
                      fontSize: "12px",
                    }}
                    treeData={!groupingColDef.hide}
                    getTreeDataPath={getTreeDataPath}
                    defaultGroupingExpansionDepth={-1}
                    groupingColDef={groupingColDef}
                    autoHeight={false}
                    getRowClassName={GetRowClassName}
                    getCellClassName={GetCellClassName}
                    columns={columns}
                    rows={rows}
                    rowBuffer={
                      rowBufferCount
                    } /* How many rows we should render extra, this value needs to be high for the search to work.*/
                    pagination={false}
                    onColumnVisibilityChange={handleColumnVisibilityChange}
                    onCellEditCommit={handleCellEditCommit}
                    onColumnWidthChange={handleColumnWidthChange}
                    disableSelectionOnClick
                    disableChildrenSorting={false}
                    hideFooter
                    components={{ Toolbar: CustomGridToolbar }}
                  />
                </div>
              </>
            ) : (
              <>
                {/* Create root pipe form, in case rows are loaded byt there are none */}
                <Typography variant="h1">
                  {window.gettext("Create root pipe")}
                </Typography>
                <Typography variant="subtitle">
                  {window.gettext(
                    "Create a new root pipe that the system can start from..."
                  )}
                </Typography>
                <InstanceManipulator
                  service={PipeService}
                  urlParams={[project.id, system.id]}
                  postCreate={postPipeCreate}
                  mode="create"
                  form={<PipeForm />}
                />
              </>
            )
          }
        </>
      )}
      <BottomDrawer
        project={project}
        dataGridApiRef={apiRef}
        validationResults={validationResults}
        setTableHeightCallback={setTableHeight}
        system={system}
        nodes={nodes}
        calculations={calculations}
        columns={columns}
        disableFetch={disableFetchButton}
        refreshSystemCalculationsCallback={refreshSystemCalculations}
        refreshSystemNodesCallback={refreshSystemNodes}
        columnSettings={columnSettings}
        columnSettingsChangeCallback={(newColumnSettings) => {
          setColumnSettings(newColumnSettings);
        }}
        settings={settings}
        onSystemChanged={setSystem}
        settingsChangeCallback={(newSettings) => {
          setSettings(newSettings);
        }}
        systemChoices={systemChoices}
        autoRefreshCalculationsChangeCallback={(newState) => {
          setAutoRefreshCalculations(newState);
        }}
        setSearchMode={setSearchMode}
      />
      <Dialog
        open={showLoadingSplash}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            width: "50px",
            height: "100px",
          },
        }}
      >
        <CircularProgress />
      </Dialog>
    </>
  );
}
