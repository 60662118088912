import BaseService from "./BaseService";

class RadiatorService extends BaseService {}

export default new RadiatorService('/api/',
  [
    'projects',
    'systems',
    'radiators',
  ]
)