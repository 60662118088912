import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../../../../../../../components/layout/LoadingIndicator";
import ProjectService from "../../../../../../../../../../../../components/services/ProjectService";
import SelfCheckJobMeasurementOpportunityService from "../../../../../../../../../../../../components/services/measurement/SelfCheckJobMeasurementOpportunityService";
import DateTime from "../../../../../../../../../../../../components/values/DateTime";
import Temperature from "../../../../../../../../../../../../components/values/Temperature";

import {
  LayoutCard,
  LayoutView,
} from "../../../../../../../../../../../../layout";

export default function MeasurementOpportunityView(props) {
  const { project_id, system_id, job_id, measurement_id, opportunity_id } =
    useParams();

  const [opportunity, setOpportunity] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(fetchMeasurementOpportunity, [
    job_id,
    measurement_id,
    opportunity_id,
    project_id,
    system_id,
  ]);
  useEffect(fetchProject, [project_id]);

  function fetchMeasurementOpportunity() {
    if (
      !job_id ||
      !measurement_id ||
      !opportunity_id ||
      !project_id ||
      !system_id
    ) {
      return;
    }

    SelfCheckJobMeasurementOpportunityService.fetch(
      project_id,
      system_id,
      job_id,
      measurement_id,
      opportunity_id
    ).then(setOpportunity);
  }

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Measurement opportunity")}
    >
      {!opportunity || !project ? (
        <LoadingIndicator />
      ) : (
        <LayoutCard noPadding>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>{window.gettext("Created")}</TableCell>

                  <TableCell>
                    <DateTime value={opportunity.created} />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{window.gettext("Start")}</TableCell>

                  <TableCell>
                    {opportunity.start ? (
                      <DateTime value={opportunity.start} />
                    ) : (
                      <>
                        <span>-</span>
                      </>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{window.gettext("End")}</TableCell>

                  <TableCell>
                    {opportunity.end ? (
                      <DateTime value={opportunity.end} />
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{window.gettext("Outside temperature")}</TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.outside_temperature)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{window.gettext("Outflow temperature")}</TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.outflow_temperature)}
                    />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>{window.gettext("Return temperature")}</TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.return_temperature)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </LayoutCard>
      )}
    </LayoutView>
  );
}
