import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useEffect, useState} from "react";
import {IconButton} from "@mui/material";
import * as PropTypes from "prop-types";


export default function SortableButtons(props) {
  let [service, setService] = useState()
  let [items, setItems] = useState()
  let [serviceParams, setServiceParams] = useState()
  let [currentItem, setCurrentItem] = useState()
  let [index, setIndex] = useState()

  useEffect(() => {
    if (!props.service) {
      return
    }

    setService(props.service)
  }, [props.service])

  useEffect(() => {
    if (!props.items) {
      return
    }

    setItems(props.items)
  }, [props.items])

  useEffect(() => {
    if (props.index === undefined) {
      return
    }

    setIndex(props.index)
  }, [props.index])

  useEffect(() => {
    if (!props.currentItem) {
      return
    }

    setCurrentItem(props.currentItem)
  }, [props.currentItem])

  useEffect(() => {
    if (!props.serviceParams) {
      return
    }

    setServiceParams(props.serviceParams)
  }, [props.serviceParams])

  return (
    <>
      {(currentItem !== undefined && index !== undefined && service !== undefined && items.length > 1) && (
        <>
          {index > 0 ? (
            <IconButton
              onClick={
                () => {
                  let ids = items.map((task_spec) => (task_spec.id))

                  const this_id = currentItem.id

                  const fromIndex = ids.indexOf(this_id);
                  const toIndex = fromIndex - 1;
                  const element = ids.splice(fromIndex, 1)[0];
                  ids.splice(toIndex, 0, element);

                  console.log('service params', serviceParams)

                  service.setOrder(
                    ...serviceParams,
                    ids
                  ).then(
                    (response) => {
                      if (props.hasOwnProperty('successCallback')) {
                        props.successCallback()
                      }
                    }
                  )
                }
              }
            >
              <ArrowDropUpIcon color="secondary"/>
            </IconButton>
          ) : (
            <IconButton>
              <ArrowDropUpIcon color="lowtone"/>
            </IconButton>
          )}

          {index < (items.length - 1) ? (
            <IconButton
              onClick={
                () => {
                  let ids = items.map((task_spec) => (task_spec.id))

                  const this_id = currentItem.id

                  const fromIndex = ids.indexOf(this_id);
                  const toIndex = fromIndex + 1;
                  const element = ids.splice(fromIndex, 1)[0];
                  ids.splice(toIndex, 0, element);

                  service.setOrder(
                    ...serviceParams,
                    ids
                  ).then(
                    (response) => {
                      if (props.hasOwnProperty('successCallback')) {
                        props.successCallback()
                      }
                    }
                  )
                }
              }
            >
              <ArrowDropDownIcon color="secondary"/>
            </IconButton>
          ) : (
            <IconButton>
              <ArrowDropDownIcon color="lowtone"/>
            </IconButton>
          )}
        </>
      )}
    </>
  )
}

SortableButtons.propTypes = {
  service: PropTypes.object,
  index: PropTypes.number,
  currentItem: PropTypes.object,
  serviceParams: PropTypes.array,
}