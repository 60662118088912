import BaseService from "./BaseService";
class SelfCheckJobMeasurementProfileService extends BaseService {
  preSendDataTransformer = (data) => {
    // set just the values (ignore they keys), data comes in like {key: value, key2; value2} we want [value, value2]
    if(data?.outside_threshold_show_entity_types){
      data['outside_threshold_show_entity_types'] = Object.values(data.outside_threshold_show_entity_types)
    }
    if(data?.outside_threshold_summary_types){
      data['outside_threshold_summary_types'] = Object.values(data.outside_threshold_summary_types)
    }
    if(data?.temperature_distribution_types){
      data['temperature_distribution_types'] = Object.values(data.temperature_distribution_types)
    }
    if(data?.median_temperature_entity_types){
      data['median_temperature_entity_types'] = Object.values(data.median_temperature_entity_types)
    }

    if(data?.median_accepted_threshold_high === ""){
      delete data['median_accepted_threshold_high']
    }

    if(data?.median_accepted_threshold_low === ""){
      delete data['median_accepted_threshold_low']
    }

    if(data?.temperature_distribution_high === ""){
      delete data['temperature_distribution_high']
    }

    if(data?.temperature_distribution_low === ""){
      delete data['temperature_distribution_low']
    }

    if(data?.temperature_distribution_step === ""){
      delete data['temperature_distribution_step']
    }

    return data
  }
}

export default new SelfCheckJobMeasurementProfileService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-profiles',
  ]
)