import { IconButton, Tooltip } from "@mui/material";

import Flag from "../../../../../flag/Flag";
import Link from "../../../../../layout/Link";
import AddSimpleRadiatorModalButton from "../../../../../node/radiator/AddSimpleRadiatorModalButton";
import TaskItemService from "../../../../../services/self_check/TaskItemService";
import TaskService from "../../../../../services/self_check/TaskService";
import UserPermission from "../../../../../user/UserPermission";
import Flow from "../../../../../values/Flow";
import KVValue from "../../../../../values/KVValue";
import Nipple from "../../../../../values/Nipple";
import Temperature from "../../../../../values/Temperature";
import { TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE } from "../../../job_specification";
import getTaskItemSpecFieldId from "../../utils/getTaskItemSpecFieldId";
import sendCheckableRequest from "../../utils/sendCheckableRequest";
import getTaskSpecFieldId, {
  TASK_SPEC_FIELD_PREFIX,
} from "../../utils/taskSpecFieldId";
import CheckableCommentCellIndicator from "../CheckableCommentCellIndicator";
import JobCheckableCheckbox from "../JobCheckableCheckbox";
import JobDatagridEntityName from "../JobDatagridEntityName";
import JobDatagridField from "../JobDatagridField";
import JobDatagridInstalledNippleField from "../JobDatagridInstalledNippleField";
import JobDatagridMeasurementInstanceCommentCell from "../JobDatagridMeasurementInstanceCommentCell";
import JobDatagridMeasurementInstanceTemperatureField from "../JobDatagridMeasurementInstanceTemperatureField";
import TextTaskItemCellIndicator from "../TextTaskItemCellIndicator";

function taskItemValueGetterFactory(task_item_spec, property) {
  return (params) => {
    const value = params.row.checkable[getTaskItemSpecFieldId(task_item_spec)];

    if (property === undefined) {
      return value;
    }

    if (!value) {
      return value;
    }

    if (!value.hasOwnProperty(property)) {
      return undefined;
    }

    return value[property];
  };
}

function taskValueGetterFactory(task_item, suffix, property) {
  return (params) => {
    const value = params.row.checkable[getTaskSpecFieldId(task_item, suffix)];

    if (property === undefined) {
      return value;
    }

    if (!value) {
      return value;
    }

    if (!value.hasOwnProperty(property)) {
      return undefined;
    }

    return value[property];
  };
}

export function getColumnDefinitions({
  enqueueSnackbar,
  fetchJobData,
  iconSize,
  jobData,
  options,
  project,
  renderCheckableCell,
  system,
  taskItemStateChange,
  taskSpecEntityTypeChoices,
  taskStateChange,
}) {
  const iconWidth = iconSize === "large" ? 50 : iconSize === "medium" ? 40 : 35;

  function renderCheckableCell(params) {
    const parseFieldName = () => {
      const field = params.field;
      const regex = new RegExp(/^([-a-z]+)_([\d]+)_?([\w]*)$/);
      const match = regex.exec(field);
      const prefix = match[1];
      const spec_id = parseInt(match[2], 10);
      const suffix = match[3];

      return {
        prefix: prefix,
        spec_id: spec_id,
        suffix: suffix,
      };
    };

    const getOriginatesFromTaskSpec = () => {
      return TASK_SPEC_FIELD_PREFIX === parseFieldName().prefix;
    };

    const getService = () => {
      if (getOriginatesFromTaskSpec()) {
        return TaskService;
      }
      return TaskItemService;
    };

    const getTaskItemSpec = () => {
      const parsedFieldName = parseFieldName();

      // Get task item spec
      const entity_task_item_spec =
        params.row.entity.task_item_specs.find((task_item_spec) => {
          return task_item_spec.id === parsedFieldName.spec_id;
        }) || null;

      if (entity_task_item_spec === null) {
        console.error(
          "Could not find entity_task_item_spec",
          parsedFieldName,
          params
        );

        return entity_task_item_spec;
      }

      const task_item_spec =
        jobData.task_item_specs.find((job_task_item_spec) => {
          return job_task_item_spec.id === entity_task_item_spec.id;
        }) || null;

      return task_item_spec;
    };

    const getTaskSpec = () => {
      const parsedFieldName = parseFieldName();

      // Get task spec
      const entity_task_spec =
        params.row.entity.task_specs.find((task_spec) => {
          return task_spec.id === parsedFieldName.spec_id;
        }) || null;

      if (entity_task_spec === null) {
        console.error(
          "Could not find entity_task_spec",
          parsedFieldName,
          params
        );

        return entity_task_spec;
      }

      return (
        jobData.task_specs.find((job_task_spec) => {
          return job_task_spec.id === entity_task_spec.id;
        }) || null
      );
    };

    const getTaskResource = () => {
      const parsedFieldName = parseFieldName();

      // Get task spec
      const entity_task =
        params.row.entity.tasks.find((task_item) => {
          return task_item.spec_id === parsedFieldName.spec_id;
        }) || null;

      if (entity_task === null) {
        return null;
      }

      return (
        jobData.tasks.find((task) => {
          return entity_task.id === task.id;
        }) || null
      );
    };

    const getTaskItemResource = () => {
      const parsedFieldName = parseFieldName();

      // Get task spec
      const entity_task_item = params.row.entity.task_items.find(
        (task_item) => {
          return task_item.spec_id === parsedFieldName.spec_id;
        }
      );

      return entity_task_item || null;
    };

    const getResource = () => {
      /**
       * Should get resource, if there is no resource to be found return null
       *
       * The resource can be null if it has not been created yet
       */

      if (getOriginatesFromTaskSpec()) {
        return getTaskResource();
      }

      return getTaskItemResource();
    };

    const getSpec = () => {
      if (getOriginatesFromTaskSpec()) {
        //console.log('Getting TaskSpec')
        return getTaskSpec();
      }

      //console.log('Getting TaskItemSpec')
      return getTaskItemSpec();
    };

    const handleCheckboxOnClick = (e) => {
      // Getting the current state
      // Can be true/false or in the event of that there was no value
      // that could be present null
      const checkable = params.row.checkable[params.field];
      const current_done_state = checkable ? checkable.done : false;

      const data = {
        done: !current_done_state,
      };

      const resource = getResource();

      const refresh_all_job_data = false;

      let originatesFromTaskSpec = getOriginatesFromTaskSpec();

      send(
        getService(),
        getSpec(),
        resource,
        data,
        originatesFromTaskSpec
      ).then(
        (response) => {
          if (!originatesFromTaskSpec && taskItemStateChange) {
            taskItemStateChange(response, refresh_all_job_data);
          }

          if (originatesFromTaskSpec && taskStateChange) {
            taskStateChange(response, refresh_all_job_data);
          }

          // Previously we called the callback to refresh job data here
          // However since we do an incremental update int he taskItemStateChange callback
          // we do the fetching of all data after that has been done and leave the decision
          // of when we shall get all the data to that callback function.

          if (options.notice_feedback_task_done_changed) {
            enqueueSnackbar(
              response.done
                ? window.gettext("Marked task item as done")
                : window.gettext("Marked task as Not done"),
              {
                variant: "success",
              }
            );
          }
        },
        (reason) => {
          enqueueSnackbar(window.gettext(reason.response.data.details), {
            variant: "error",
          });
        }
      );
    };

    const handleFlagOnClick = (e) => {
      const task = params.row.checkable[params.field];

      let flag = "flag";

      if (task !== null) {
        flag = task.flag ? null : "flag"; // Toggles the flag between null and 'flag'
      }

      let originatesFromTaskSpec = getOriginatesFromTaskSpec();

      send(
        getService(),
        getSpec(),
        getResource(),
        {
          flag: flag,
        },
        originatesFromTaskSpec
      ).then((response) => {
        fetchJobData();
        enqueueSnackbar(
          !response.flag
            ? window.gettext("Removed flag on task")
            : window.gettext("Set flag on task"),
          {
            variant: "success",
          }
        );
      });
    };

    const send = (service, spec, resource, data, originates_from_task_spec) => {
      return sendCheckableRequest(
        project,
        system,
        jobData.job,
        service,
        spec,
        params.row.entity.type,
        params.row.entity.id,
        resource,
        data,
        originates_from_task_spec
      );
    };

    const handleOnCommentSave = (newComment, successCallback) => {
      const service = getService();
      const resource = getResource();

      let data = {
        comment: newComment || "",
      };

      send(
        service,
        getSpec(),
        resource,
        data,
        getOriginatesFromTaskSpec()
      ).then(
        (response) => {
          fetchJobData();
          successCallback();
        },
        (rejection) => {
          enqueueSnackbar(
            window.gettext(
              "Error while saving comment, please contact your system administrator"
            ),
            {
              variant: "error",
            }
          );
        }
      );
    };

    const handleOnTextSave = (newText, successCallback) => {
      const service = getService();
      const resource = getResource();

      let data = {
        text: newText || "",
      };

      send(
        service,
        getSpec(),
        resource,
        data,
        getOriginatesFromTaskSpec()
      ).then(
        (response) => {
          fetchJobData();
          successCallback();
        },
        (rejection) => {
          enqueueSnackbar(
            window.gettext(
              "Error while saving text, please contact your system administrator"
            ),
            {
              variant: "error",
            }
          );
        }
      );
    };

    const is_based_on_task_spec = params.field.startsWith(
      TASK_SPEC_FIELD_PREFIX
    );
    /* If this has a checkable value defined
     *
     * This indicates if there is a task/task-item defined for this field.
     * If the value is undefined this indicates there is not spec for this row for this task/task-item
     * If there is No spec for a given row+column that means there is no spec for this column+row thus we can't even save the data
     *
     * In short, if this value is True the user is able to interact with this task/task-item
     * */
    const is_relevant = params.row.checkable[params.field] !== undefined;

    let checked = false;
    let flag = null;
    let comment = null;
    let text = null;

    if (params.value !== null && params.value !== undefined) {
      checked = params.value.done;
      flag = params.value.flag;
      comment = params.value.comment;
      text = params.value.text;
    }

    return (
      <>
        {is_relevant && (
          <>
            <JobCheckableCheckbox
              checked={checked}
              spec={getSpec()}
              variant={
                is_based_on_task_spec ? "circle" : "square"
              } /* If this is based on a task-spec show circle */
              clickable={true} /* Used to be if this is based on a task-spec */
              fontSize={iconSize}
              visible={is_relevant}
              onClick={handleCheckboxOnClick}
            />
            <>
              {is_based_on_task_spec && (
                <IconButton onClick={handleFlagOnClick}>
                  {!flag ? (
                    <Flag type="flag" color="lowtone" fontSize={iconSize} />
                  ) : (
                    <>
                      <Flag type={flag} color="error" fontSize={iconSize} />
                    </>
                  )}
                </IconButton>
              )}
            </>
            <>
              {getSpec().type ===
                TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE && (
                <>
                  <TextTaskItemCellIndicator
                    text={text}
                    onSave={handleOnTextSave}
                    iconSize={iconSize}
                  />
                </>
              )}
            </>
            <>
              {getSpec().enable_commenting && (
                <CheckableCommentCellIndicator
                  comment={comment}
                  onSave={handleOnCommentSave}
                  iconSize={iconSize}
                  options={options}
                />
              )}
            </>
          </>
        )}
      </>
    );
  }

  /**
   * The columns need to be ordered so that the grouping works.
   * Every conf that is not next to the other will get its own master-header
   */
  return [
    ...[
      {
        field: "tools",
        headerName: window.gettext("Tools"),
        renderCell: (params) => {
          if (params.row.entity.type !== "radiator_node") {
            return null;
          }

          return (
            <>
              <UserPermission
                permissions={[
                  UserPermission.permissions.radiator_add,
                  UserPermission.permissions.node_add,
                ]}
              >
                <AddSimpleRadiatorModalButton
                  projectId={project.id}
                  systemId={system.id}
                  parentNodeId={params.row.entity?.data?.parent_node?.id}
                  entranceShortCode={
                    params.row.entity?.location?.entrance?.short_code
                  }
                  apartmentNumber={
                    params.row.entity?.location?.apartment
                      ?.surveying_apartment_number
                  }
                  onCreated={(response) => {
                    fetchJobData();
                  }}
                />
              </UserPermission>
            </>
          );
        },
      },
      {
        field: "entity_type",
        headerName: window.gettext("Type"),
        valueGetter: (params) => {
          const type_name = params.row.entity.type;

          return (
            taskSpecEntityTypeChoices[type_name] ||
            "[" + window.gettext("Unknown") + "]"
          );
        },
      },
      {
        field: "label",
        headerName: window.gettext("Name"),
        width: 140,
        valueGetter: (params) => {
          if (params.row.entity.type === "address_apartment") {
            return (
              params.row.entity.data.surveying_apartment_number ||
              params.row.entity.data.local_apartment_number ||
              params.row.entity.data.label
            );
          }

          return params.row.entity.data.label;
        },
        renderCell: (params) => {
          // IF we don't want to show links
          if (!options?.entity_links) {
            return <JobDatagridEntityName entity={params.row.entity} />;
          }

          // Relative link to hierarchy
          return (
            <>
              <Link
                to={
                  "hierarchy/?entity_type=" +
                  params.row.entity.type +
                  "&entity_id=" +
                  String(params.row.entity.id)
                }
              >
                <JobDatagridEntityName entity={params.row.entity} />
              </Link>
            </>
          );
        },
      },
      {
        field: "address_label",
        width: 250,
        headerName: window.gettext("Address"),
        valueGetter: (params) => {
          return params.row.entity.location.address_label || undefined;
        },
        renderCall: (params) => {
          return <>{params.value}</>;
        },
      },
      {
        field: "room",
        headerName: window.gettext("Room"),
        valueGetter: (params) => {
          const data = params.row.entity.data;
          if (data.hasOwnProperty("room") && data.room !== null) {
            return data.room.name;
          }

          return null;
        },
      },
      {
        field: "local_apartment_number",
        headerName: window.gettext("Local apt. nr."),
        valueGetter: (params) => {
          // If this is an apartment, return it.
          if (params.row?.entity?.type === "address_apartment") {
            return params?.row?.entity?.data?.local_apartment_number || "";
          }

          return "";

          /*
          if (!params.row.entity.location.apartment) {
            return undefined
          }

          return params.row.entity?.location?.apartment.local_apartment_number || undefined
           */
        },
        renderCall: (params) => {
          return <>{params.value}</>;
        },
        renderEditCell: (params) => {
          return <JobDatagridField {...params} />;
        },
        editable: true,
      },
      {
        field: "surveying_apartment_number",
        headerName: window.gettext("Land surveying apartment number"),
        valueGetter: (params) => {
          if (!params.row.entity.location.apartment) {
            return undefined;
          }
          return (
            params.row.entity.location.apartment.surveying_apartment_number ||
            undefined
          );
        },
        renderCall: (params) => {
          return <>{params.value}</>;
        },
      },
      {
        field: "note",
        headerName: window.gettext("Note"),
        valueGetter: (params) => {
          return params.row.entity?.data?.item?.note || "";
        },
        renderCell: (params) => {
          return (
            <>
              <Tooltip title={params.value}>
                <span>{params.value}</span>
              </Tooltip>
            </>
          );
        },
      },
      {
        field: "radiator",
        headerName: window.gettext("Radiator"),
        valueGetter: (params) => {
          const data = params.row.entity.data;
          if (data.hasOwnProperty("radiator") && data.radiator !== null) {
            return data.radiator;
          }
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }

          // This is a Radiator, thus we should get the radiator type name
          return params.value?.type?.name || window.gettext("Not defined");
        },
      },
    ],
    ...[
      {
        field: "measurement_inflow_temperature",
        headerName: window.gettext("Inflow temperature"),
        valueGetter: (params) => {
          return (
            params.row.entity.latest_measurement_instance?.inflow_temperature ||
            null
          );
        },
        renderCell: (params) => {
          return (
            <>
              <Temperature value={params.value || ""} precision={2} />
            </>
          );
        },
        renderEditCell: (params) => {
          return <JobDatagridMeasurementInstanceTemperatureField {...params} />;
        },
        editable: true,
      },
      {
        field: "measurement_return_temperature",
        headerName: window.gettext("Return temperature"),
        valueGetter: (params) => {
          return (
            params.row.entity.latest_measurement_instance?.return_temperature ||
            null
          );
        },
        renderCell: (params) => {
          return (
            <>
              <Temperature value={params.value || ""} precision={2} />
            </>
          );
        },
        renderEditCell: (params) => {
          return <JobDatagridMeasurementInstanceTemperatureField {...params} />;
        },
        editable: true,
      },
      {
        field: "measurement_room_temperature",
        headerName: window.gettext("Room temperature"),
        valueGetter: (params) => {
          return (
            params.row.entity.latest_measurement_instance?.room_temperature ||
            null
          );
        },
        renderCell: (params) => {
          return (
            <>
              <Temperature value={params.value || ""} precision={2} />
            </>
          );
        },
        renderEditCell: (params) => {
          return <JobDatagridMeasurementInstanceTemperatureField {...params} />;
        },
        editable: true,
      },
      {
        field: "measurement_comment",
        headerName: window.gettext("Comment"),
        valueGetter: (params) => {
          return params.row.entity.latest_measurement_instance?.comment || "";
        },
        renderCell: (params) => {
          if (
            !["radiator_node", "valve_node"].includes(params?.row?.entity?.type)
          ) {
            return <></>;
          }

          return (
            <JobDatagridMeasurementInstanceCommentCell
              projectId={project.id}
              systemId={system.id}
              jobId={jobData.job.id}
              iconSize={iconSize}
              options={options}
              {...params}
            />
          );
        },
        editable: false,
      },
    ],
    // Calculated values
    ...[
      /*
          Temp remove, might have later
      {
        'field': 'effect',
        'headerName': window.gettext('Effect'),
        'valueGetter': (params) => {
          if (params.row.entity.frozen_calculation) {
            return params.row.entity.frozen_calculation['effect'] || undefined
          }
          return undefined
        },
        'renderCell': (params) => {
          return (
            <>
              <Energy value={params.value}/>
            </>
          )
        }
      },
       */
      {
        field: "flow",
        headerName: window.gettext("Flow"),
        valueGetter: (params) => {
          if (params.row.entity.frozen_calculation) {
            return params.row.entity.frozen_calculation["flow"] || undefined;
          }
          return undefined;
        },
        renderCell: (params) => {
          return (
            <>
              <Flow value={params.value} />
            </>
          );
        },
      },
      {
        field: "kv",
        headerName: window.gettext("KV"),
        valueGetter: (params) => {
          if (params.row.entity.frozen_calculation) {
            return params.row.entity.frozen_calculation["kv"] || undefined;
          }
          return undefined;
        },
        renderCell: (params) => {
          return (
            <>
              <KVValue value={params.value} />
            </>
          );
        },
      },
      {
        field: "nipple",
        headerName: window.gettext("Nipple"),
        valueGetter: (params) => {
          if (params.row.entity.frozen_calculation) {
            return params.row.entity.frozen_calculation.nipple || undefined;
          }
          return undefined;
        },
        renderCell: (params) => {
          return <>{params.value && <span>{params.value.label}</span>}</>;
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }

          return params.value.label;
        },
      },
    ],
    ...[
      {
        field: "installed_kv",
        headerName: window.gettext("KV"),
        valueGetter: (params) => {
          if (!params.row.entity.installed_values) {
            return undefined;
          }

          const installed_values = params.row.entity.installed_values;

          if (installed_values.length === 0) {
            return undefined;
          }

          return installed_values[0].kv;
        },
        renderCell: (params) => {
          return (
            <>
              <KVValue value={params.value} />
            </>
          );
        },
        valueSetter: (params) => {
          let row = params.row;

          let value = params.value;

          if (!value) {
            value = null;
          } else {
            value = parseFloat(value);
          }

          let newInstalledValue = {
            kv: value,
          };

          let installed_values = row.entity?.installed_values || [];

          if (installed_values.length > 0) {
            newInstalledValue["nipple"] = installed_values[0].nipple;
          }

          installed_values.unshift(newInstalledValue);

          row.entity.installed_values = installed_values;

          return row;
        },
        editable: true,
      },
      {
        field: "installed_nipple",
        headerName: window.gettext("Nipple"),
        valueGetter: (params) => {
          if (!params.row.entity.installed_values) {
            return undefined;
          }

          const installed_values = params.row.entity.installed_values;

          if (installed_values.length === 0) {
            return undefined;
          }

          return installed_values[0].nipple || undefined;
        },
        renderCell: (params) => {
          return <>{params.value && <Nipple id={params.value} />}</>;
        },
        valueSetter: (params) => {
          let row = params.row;

          let newInstalledValue = {
            nipple: params.value || null,
          };

          let installed_values = row.entity?.installed_values || [];

          if (installed_values.length > 0) {
            newInstalledValue["kv"] = installed_values[0].kv;
          }

          installed_values.unshift(newInstalledValue);

          row.entity.installed_values = installed_values;

          return row;
        },
        valueFormatter: (params) => {
          if (!params.value) {
            return "";
          }

          return params.value;
        },
        renderEditCell: (params) => {
          return <JobDatagridInstalledNippleField {...params} />;
        },
        editable: true,
      },
    ],
    // Task and task-item columns
    ...jobData.task_specs
      .map((task_spec) => {
        return [
          // First the list of task-item-specs relevant to this task-spec
          ...jobData.task_item_specs
            .filter(
              /*
               * Only let the task_item_specs that are for the current task_spec
               * This will make all the task_items appear Before the task-done column
               * */
              (task_item_spec) => task_item_spec.task_spec_id === task_spec.id
            )
            .map((task_item_spec) => {
              let columns = [
                {
                  field: getTaskItemSpecFieldId(task_item_spec),
                  width: iconWidth * 2 + 20,
                  headerName: task_item_spec.name,
                  description: window.gettext("Task item"),
                  valueGetter: taskItemValueGetterFactory(task_item_spec),
                  renderCell: renderCheckableCell,
                  valueFormatter: (params) => {
                    if (!params.value) {
                      return "";
                    }

                    if (params.value.done) {
                      return "1";
                    } else {
                      return "0";
                    }
                  },
                },
              ];

              // Text field (if applicable)
              if (task_item_spec.type.startsWith("text-")) {
                columns.push({
                  field: getTaskItemSpecFieldId(task_item_spec) + "_text",
                  headerName:
                    task_item_spec.name + " " + window.gettext("text"),
                  description: window.gettext("Task item text"),
                  valueGetter: taskItemValueGetterFactory(task_item_spec),
                  valueFormatter: (params) => {
                    return params?.value?.text || "";
                  },
                  hide: true,
                });
              }

              if (task_item_spec.enable_commenting) {
                // Comment field
                columns.push({
                  field: getTaskItemSpecFieldId(task_item_spec) + "_comment",
                  headerName:
                    task_item_spec.name + " " + window.gettext("comment"),
                  description: window.gettext("Task item comment"),
                  valueGetter: taskItemValueGetterFactory(
                    task_item_spec,
                    "comment"
                  ),
                  hide: true,
                });
              }

              return columns;
            })
            .flat(),
          // And for the general things for each task (task-spec)
          ...[
            {
              field: getTaskSpecFieldId(task_spec, "done"),
              width: iconWidth * 3 + 20,
              headerName: window.gettext("Task done"),
              valueGetter: taskValueGetterFactory(task_spec, "done"),
              renderCell: renderCheckableCell,
              valueFormatter: (params) => {
                if (!params.value) {
                  return "";
                }

                let string_repr = "";
                if (params.value.done === true) {
                  string_repr += "1";
                } else {
                  string_repr += "0";
                }

                if (params.value.comment) {
                  // string_repr += ' | ' + params.value.comment
                }

                return string_repr;
              },
            },
            // Comment field
            {
              field: getTaskSpecFieldId(task_spec) + "_comment",
              hide: true,
              headerName: task_spec.name + " " + window.gettext("comment"),
              description: window.gettext("Task comment"),
              valueGetter: taskValueGetterFactory(task_spec, "done", "comment"),
            },
            // Flag field
            {
              field: getTaskSpecFieldId(task_spec) + "_flag",
              hide: true,
              headerName: task_spec.name + " " + window.gettext("flag"),
              description: window.gettext("Task flag"),
              valueGetter: taskValueGetterFactory(task_spec, "done", "flag"),
            },
          ],
        ];
      })
      .flat(),
    // More columns at the end.
    ...[],
  ];
}
