import { Comment } from "@mui/icons-material";
import { Typography } from "@mui/material";

import MultilineText from "../../../../../layout/MultilineText";

import { LayoutCard } from "../../../../../../layout";
import { CenteredModal, useModalHandler } from "../../../../../layout/modal";

export function EntityCommentStatusIcon({ entityStatus }) {
  const commentsModalHandler = useModalHandler();

  return (
    <>
      {entityStatus?.comments_count() > 0 ? (
        <>
          <Comment color="success" onClick={commentsModalHandler.open} />

          <CenteredModal modalHandler={commentsModalHandler} width="100%">
            <Typography
              variant="h3"
              sx={{
                pb: 1,
              }}
            >
              {window.gettext("Comments")}
            </Typography>

            {entityStatus?.comments()?.map((commentData, index) => (
              <LayoutCard key={index} title={commentData.spec.name}>
                <MultilineText>{commentData.comment}</MultilineText>
              </LayoutCard>
            ))}
          </CenteredModal>
        </>
      ) : (
        <Comment color="disabled" />
      )}
    </>
  );
}
