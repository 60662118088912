import { IconButton, ListItem, Stack } from "@mui/material";
import { useEffect, useState } from "react";

import Flag from "../../../../../../../flag/Flag";
import TaskItemService from "../../../../../../../services/self_check/TaskItemService";
import TaskService from "../../../../../../../services/self_check/TaskService";
import CheckableCommentCellIndicator from "../../../../datagrid/CheckableCommentCellIndicator";
import sendCheckableRequest from "../../../../utils/sendCheckableRequest";

import { useHierarchyContext } from "../../../context";
import { HierarchyTaskCheckbox } from "./task";
import {
  HierarchyTaskTypeItemGroup,
  HierarchyTaskTypeItemOptionList,
} from "./task_types";

const TASK_SPEC_TYPE_ITEM_GROUP = "item_group";
const TASK_SPEC_TYPE_ITEM_OPTION_LIST = "item_option_list";

export function HierarchyTaskInterfaceTaskListItem({ entity, entityTaskSpec }) {
  const { jobData, project, system, setJobData } = useHierarchyContext();

  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [jobDataEntity, setJobDataEntity] = useState();
  const [task, setTask] = useState();
  const [taskItems, setTaskItems] = useState();
  const [taskSpec, setTaskSpec] = useState();

  // Job Data Entity
  useEffect(() => {
    const jobDataEntity = jobData.entities.find(
      (jobEntity) =>
        jobEntity.id === entity.id && jobEntity.type === entity.type
    );

    setJobDataEntity(jobDataEntity);
  }, [jobData.entities, entity.id, entity.type]);

  // Task Spec
  useEffect(() => {
    // Will be null when the task is not created
    const taskSpec = jobData.task_specs.find(
      (taskSpec) => taskSpec.id === entityTaskSpec.id
    );

    setTaskSpec(taskSpec);
  }, [jobData.task_specs, jobData.tasks, entityTaskSpec.id]);

  // Task
  useEffect(() => {
    // Get the reference data (contains just task id and spec id)
    const entityTaskReferenceData = jobDataEntity?.tasks.find(
      (jobDataEntityTask) => jobDataEntityTask.spec_id === entityTaskSpec.id
    );

    // The real task
    const task = jobData.tasks.find(
      (jobDataTask) => jobDataTask.id === entityTaskReferenceData?.id
    );

    setTask(task || null);
  }, [jobData.tasks, jobDataEntity?.tasks, entityTaskSpec.id]);

  // Task Items
  useEffect(() => {
    // Finding entity on JobData
    const taskItems = entity.task_item_specs.map(
      (taskItemSpecReferenceData) => {
        const task_item_spec = jobData.task_item_specs.find(
          (taskSpec) => taskSpec.id === taskItemSpecReferenceData.id
        );

        const task_item = jobDataEntity?.task_items.find(
          (jobDataEntityTaskItem) =>
            jobDataEntityTaskItem.spec_id === task_item_spec.id
        );

        return {
          task_item: task_item || null,
          task_item_spec: task_item_spec,
        };
      }
    );

    const filteredTaskItems = taskItems.filter((task_item) => {
      // Filter out and show only task_item IF the task->taskSpec is the same as the taskSpec we are viewing for.
      return entityTaskSpec.id === task_item.task_item_spec.task_spec_id;
    });

    setTaskItems(filteredTaskItems);
  }, [jobData.task_item_specs, jobDataEntity?.task_items, entityTaskSpec.id]);

  function setJobDataTask(task) {
    // This function will update the jobData state with the new/updated task.
    // If the task is new, it will be added to the jobData state.
    // If the task is updated, it will be updated in the jobData state.
    // Task items will be added/updated as well on the correct entity.

    // Assume the task is new until proven otherwise
    let isNewTask = true;

    // jobData.tasks
    const tasks = jobData.tasks.map((jobDataTask) => {
      // Check if the task exists in jobData
      if (jobDataTask.id === task.id) {
        // If it exists, then it is not new
        isNewTask = false;
        return task;
      }

      return jobDataTask;
    });

    // Add the task to jobData.tasks if it is new
    if (isNewTask) {
      tasks.push(task);
    }

    // jobData.entities
    const entities = jobData.entities.map((jobDataEntity) => {
      if (
        jobDataEntity.id === task.entity_id &&
        jobDataEntity.type === task.type
      ) {
        // jobData.entities[x].tasks
        const entityTasks = jobDataEntity.tasks;

        if (isNewTask) {
          // Add the task reference to jobData.entities[x].tasks if it is new
          entityTasks.push({ id: task.id, spec_id: task.spec_id });
        }

        // Assume all task items is new until proven otherwise
        const isNewTaskItems = task.items.map((taskItem) => true);

        // jobData.entities[x].task_items
        const entityTaskItems = jobDataEntity.task_items.map(
          (jobDataEntityTaskItem) => {
            const updatedTaskItem = task.items.find((taskItem, index) => {
              if (taskItem.id === jobDataEntityTaskItem.id) {
                // If it exists, then it is not new
                isNewTaskItems[index] = false;

                return true;
              }

              return false;
            });

            if (updatedTaskItem) {
              // console.log("Updating task item", updatedTaskItem);

              return updatedTaskItem;
            }

            // console.log("Keeping task item", jobDataEntityTaskItem);
            return jobDataEntityTaskItem;
          }
        );

        task.items.forEach((taskItem, index) => {
          if (isNewTaskItems[index]) {
            // console.log("Adding task item", taskItem);

            // Add task item to jobData.entities[x].task_items if it is new
            entityTaskItems.push(taskItem);
          }
        });

        return {
          ...jobDataEntity,
          tasks: entityTasks,
          task_items: entityTaskItems,
        };
      }

      return jobDataEntity;
    });

    setJobData((prevJobData) => {
      return {
        ...prevJobData,
        entities,
        tasks,
      };
    });
  }

  const updateData = (
    spec,
    originates_from_task_spec,
    entity_type,
    entity_id,
    resource,
    data,
    successCallback,
    errorCallback
  ) => {
    if (isSaving) {
      return false;
    }

    function successCallback() {
      setIsSaving(false);
      setHasError(false);
    }

    function errorCallback() {
      setIsSaving(false);
      setHasError(true);
    }

    const service = originates_from_task_spec ? TaskService : TaskItemService;
    const query = "task_data_response=true";

    setIsSaving(true);
    setHasError(false);

    sendCheckableRequest(
      project,
      system,
      jobData.job,
      service,
      spec,
      entity_type,
      entity_id,
      resource,
      data,
      originates_from_task_spec,
      query
    )
      .then((successResponse) => {
        setIsSaving(false);

        if (successCallback !== undefined) {
          successCallback(successResponse);
        }
        setJobDataTask(successResponse);
      })
      .catch((errorResponse) => {
        setIsSaving(false);
        setHasError(true);

        if (errorCallback !== undefined) {
          errorCallback(errorResponse);
        }
      });

    return true;
  };

  const handleCheckboxClick = (
    spec,
    originates_from_task_spec,
    entity_type,
    entity_id,
    resource,
    done,
    data = {}
  ) => {
    // Handles click of checkbox for tasks and task items
    // originates_from_task_spec is True if we clicked a task.

    data.done = done;

    updateData(
      spec,
      originates_from_task_spec,
      entity_type,
      entity_id,
      resource,
      data
    );
  };

  const handleOnCommentSave = function (
    newComment,
    successCallback,
    spec,
    originates_from_task_spec,
    entity_type,
    entity_id,
    resource
  ) {
    const data = {
      comment: newComment,
    };

    updateData(
      spec,
      originates_from_task_spec,
      entity_type,
      entity_id,
      resource,
      data,
      (response) => {
        successCallback(response);
      }
    );
  };

  const handleOnTextSave = function (
    newText,
    successCallback,
    spec,
    originates_from_task_spec,
    entity_type,
    entity_id,
    resource
  ) {
    const data = {
      text: newText,
    };

    updateData(
      spec,
      originates_from_task_spec,
      entity_type,
      entity_id,
      resource,
      data,
      successCallback
    );
  };

  const handleFlagOnClick = function (
    spec,
    originates_from_task_spec,
    entity_type,
    entity_id,
    resource
  ) {
    let flag = "flag";

    if (resource !== null) {
      flag = resource.flag ? null : "flag"; // Toggles the flag between null and 'flag'
    }

    updateData(
      spec,
      originates_from_task_spec,
      entity_type,
      entity_id,
      resource,
      {
        flag,
      }
    );
  };

  if (
    jobDataEntity === undefined ||
    taskSpec === undefined ||
    task === undefined ||
    taskItems === undefined
  ) {
    return null;
  }

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <HierarchyTaskCheckbox
          isSaving={isSaving}
          hasError={hasError}
          spec={taskSpec}
          visible={true}
          checked={task?.done === true}
          clickable={true}
          title={taskSpec.name}
          onClick={() => {
            handleCheckboxClick(
              taskSpec,
              true,
              entity.type,
              entity.id,
              task,
              !(task?.done === true)
            );
          }}
        />

        <IconButton
          onClick={() => {
            handleFlagOnClick(taskSpec, true, entity.type, entity.id, task);
          }}
        >
          {!task?.flag ? (
            <Flag type="flag" color="lowtone" />
          ) : (
            <Flag type={task.flag} color="error" />
          )}
        </IconButton>

        {taskSpec.enable_commenting && (
          <CheckableCommentCellIndicator
            comment={task?.comment}
            onSave={(newComment, successCallback) => {
              handleOnCommentSave(
                newComment,
                successCallback,
                taskSpec,
                true,
                entity.type,
                entity.id,
                task
              );
            }}
          />
        )}
      </Stack>

      {taskSpec.type === TASK_SPEC_TYPE_ITEM_GROUP ? (
        // Item Group
        <HierarchyTaskTypeItemGroup
          isSaving={isSaving}
          hasError={hasError}
          entity={entity}
          taskItems={taskItems}
          handleCheckboxClick={handleCheckboxClick}
          handleOnTextSave={handleOnTextSave}
          handleOnCommentSave={handleOnCommentSave}
        />
      ) : taskSpec.type === TASK_SPEC_TYPE_ITEM_OPTION_LIST ? (
        // Item Option List
        <HierarchyTaskTypeItemOptionList
          isSaving={isSaving}
          hasError={hasError}
          entity={entity}
          taskItems={taskItems}
          updateData={updateData}
        />
      ) : null}
    </Stack>
  );
}
