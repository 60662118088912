import { useEffect, useRef, useState } from "react";

import { useHierarchyContext } from "./useHierarchyContext";

import SelfCheckJobMeasurementOpportunityLatestService from "../../../../../../services/measurement/SelfCheckJobMeasurementOpportunityLatestService";
import SelfCheckJobMeasurementService from "../../../../../../services/measurement/SelfCheckJobMeasurementService";
import SelfCheckJobMeasurementSummaryNodeService from "../../../../../../services/measurement/SelfCheckJobMeasurementSummaryNodeService";

import { ENTITY_TYPES_RADIATOR } from "../../../entity";

export function useProvideHierarchyRSContext() {
  const { focusEntityData, jobData, project, system } = useHierarchyContext();

  const [entity, setEntity] = useState(focusEntityData?.entity);
  const previousFocusEntity = useRef(undefined);

  const [measurementCalculation, setMeasurementCalculation] = useState();
  const [measurementLastInstance, setMeasurementLastInstance] = useState();
  const [installedValues, setInstalledValues] = useState([]);

  const [measurements, setMeasurements] = useState([]);
  const [latestOpportunity, setLatestOpportunity] = useState();
  const [systemFrozenCalculationValues, setSystemFrozenCalculationValues] =
    useState(null);
  const [
    systemUserDefinedCalculationValues,
    setSystemUserDefinedCalculationValues,
  ] = useState(null);

  const fetchMeasurements = () => {
    SelfCheckJobMeasurementService.list(
      project.id,
      system.id,
      jobData.job.id,
      "?opportunities=true"
    ).then(setMeasurements);

    fetchLatestMeasurementOpportunity();
  };

  const fetchLatestMeasurementOpportunity = () => {
    SelfCheckJobMeasurementOpportunityLatestService.fetch(
      project.id,
      system.id,
      jobData.job.id
    )
      .then(setLatestOpportunity)
      .catch((error) => {
        setLatestOpportunity(null);
      });
  };

  const fetchMeasurementSummary = (node_id) => {
    SelfCheckJobMeasurementSummaryNodeService.fetch(
      project.id,
      system.id,
      jobData.job.id,
      node_id
    ).then((response) => {
      // Setting measurement calculation to the last one
      setMeasurementCalculation(response?.last?.calculation || null);
      setMeasurementLastInstance(response?.last || null);
    });
  };

  useEffect(() => {
    if (!project || !system || !jobData?.job) {
      return;
    }

    fetchMeasurements();
  }, []);

  useEffect(() => {
    if (
      focusEntityData?.entity.id !== previousFocusEntity.current?.id ||
      focusEntityData?.entity.type !== previousFocusEntity.current?.type
    ) {
      setEntity(null);

      previousFocusEntity.current = focusEntityData?.entity;
    }

    // If the focus entity is not a radiator/valve, we do not want to set the entity
    if (
      !focusEntityData?.entity ||
      !ENTITY_TYPES_RADIATOR.includes(focusEntityData.entity.type)
    ) {
      return;
    }

    setEntity(focusEntityData.entity);
  }, [focusEntityData]);

  // Fetch measurements
  useEffect(() => {
    if (!entity) {
      setMeasurementCalculation(null);
      setMeasurementLastInstance(null);
      setInstalledValues([]);

      return;
    }

    // Set the new installed values
    setInstalledValues(entity?.installed_values || []);

    // Fetch the measurement summary
    fetchMeasurementSummary(entity.id);
  }, [entity]);

  useEffect(() => {
    if (!jobData) {
      return;
    }

    setSystemFrozenCalculationValues(
      jobData?.system_frozen_calculation || null
    );
    setSystemUserDefinedCalculationValues(
      jobData?.system_user_defined_calculation || null
    );
  }, [jobData]);

  return {
    fetchMeasurements,
    fetchMeasurementSummary,
    fetchLatestMeasurementOpportunity,
    installedValues,
    measurements,
    measurementCalculation,
    measurementLastInstance,
    latestOpportunity,
    setEntity,
    systemFrozenCalculationValues,
    systemUserDefinedCalculationValues,
  };
}
