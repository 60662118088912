import {createContext, useContext, useState} from "react";


export const IssueFilterContext = createContext({
  filters: undefined,
})

export function useSetFilters() {
  return useContext(IssueFilterContext);
}
export default function IssueFilterProvider(props) {
  let [filters, setFilters] = useState({
    'status': ['open', 'in_progress'],
    'watcher': 'me',
  })

  return (
    <IssueFilterContext.Provider
      value={
        {
          'issueListFilters': filters,
          'setIssueListFilters': (newFilters) => {
            console.log(newFilters)
            setFilters(newFilters)
          },
        }
      }
    >
      {props.children}
    </IssueFilterContext.Provider>
  )
}
