export function getRows(nodes) {
  return nodes.map(
    (node, index) => {
      return {
        'id': node.id,
        'rowIndex': index,
        'node': node,
        'errors': [],
        'warnings': [],
        'calculations': {}
      }
    }
  )
}

export function getRowsWithCalculations(rows, calculations) {
  if (calculations === undefined) {
    return rows
  }
  return rows.map(
    (row, index) => {
      // If there is calculations, add that to the rows
      // Loop through the nodes in the calculations, try to find the current node.
      // Add that calculation as a property.
      for (let i = 0; i < calculations.nodes.length; i += 1) {
        const nodeCalculation = calculations.nodes[i]
        if (nodeCalculation.id === row.node.id) {
          row.calculation = nodeCalculation
          break;
        }
      }

      return row
    }
  )
}

const pushErrorAtApplicableRow = function (rows, error, id) {
  // Add error to the applicable row where id matches the id on the row.

  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];

    if (row.id === id) {
      rows[i].errors.push(error);
      return true
    }
  }

  return false
}

const pushWarningAtApplicableRow = function (rows, warning, id) {
  // Add warning to the applicable row where id matches the id on the row.

  for (let i = 0; i < rows.length; i += 1) {
    const row = rows[i];

    if (row.id === id) {
      rows[i].warnings.push(warning);
      return true
    }
  }

  return false
}

export function getRowsWithErrors(rows, validationErrors) {
  validationErrors.errors
    .filter(
      (error) => {
        return error.type === 'field'
      }
    )
    .map(
      (error) => {
        pushErrorAtApplicableRow(rows, error, error.nodeId)
        return true;
      }
    )

  validationErrors.errors
    .filter(
      (error) => {
        return error.type === 'rows'
      }
    )
    .map(
      (rowsError) => {
        rowsError.rows.map(
          (rowsErrorRow) => {
            pushErrorAtApplicableRow(rows, rowsError, rowsErrorRow.node.id)
            return true;
          }
        )

        return true;
      }
    )

  // Deal with warnings
  validationErrors.warnings
    .filter(
      (error) => {
        return error.type === 'field'
      }
    )
    .map(
      (warning) => {
        pushWarningAtApplicableRow(rows, warning, warning.nodeId)
        return true;
      }
    )

  return rows
}