import {
  Add,
  AssignmentLate,
  ChevronLeft,
  ChevronRight,
  Logout,
} from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsightsIcon from "@mui/icons-material/Insights";
import {
  Box,
  Divider,
  IconButton,
  List,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import UserPermission from "../../user/UserPermission";

import { useUser } from "../../../context";
import { getUserRepresentation, getUserRepresentationShort } from "../../user";
import { DesktopMenuItem } from "./DesktopMenuItem";
import { DesktopMenuSubItem } from "./DesktopMenuSubItem";

import logo from "../../../assets/logo/logo.svg";

const LOGO_COLOR = "#f9e31d";

const LOCAL_STORAGE_KEY_COLLAPSED = "desktop-menu-collapsed";

export const DESKTOP_MENU_MIN_WIDTH = 52;
export const DESKTOP_MENU_MAX_WIDTH = 280;

function getLocalStorageCollapsed() {
  const collapsed = localStorage.getItem(LOCAL_STORAGE_KEY_COLLAPSED);

  return collapsed === "true";
}

export function DesktopMenu({ sx }) {
  const navigate = useNavigate();

  const { logout, user } = useUser();

  const [collapsed, setCollapsed] = useState(getLocalStorageCollapsed());

  function collapse() {
    localStorage.setItem(LOCAL_STORAGE_KEY_COLLAPSED, "true");
    setCollapsed(true);
  }

  function expand() {
    localStorage.setItem(LOCAL_STORAGE_KEY_COLLAPSED, "false");
    setCollapsed(false);
  }

  const handleLogoutClick = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Stack
      className="hidden-scrollbar"
      sx={{
        bgcolor: "background.paper",
        overflow: "scroll",
        borderRadius: 2,
        minWidth: collapsed ? DESKTOP_MENU_MIN_WIDTH : DESKTOP_MENU_MAX_WIDTH,
        width: collapsed ? DESKTOP_MENU_MIN_WIDTH : DESKTOP_MENU_MAX_WIDTH,
        ...sx,
      }}
    >
      <Stack
        sx={{
          bgcolor: "primary.main",
          color: LOGO_COLOR,
          userSelect: "none",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          px: collapsed ? 1 : 2,
          py: 1,
        }}
      >
        {/* Total size with margin 36px * 36 */}
        <img
          alt="Logo"
          src={logo}
          width={32}
          height={32}
          style={{
            margin: 2,
          }}
        />

        {!collapsed && <Typography variant="h4">Energioptimering</Typography>}
      </Stack>

      <List
        sx={{
          width: "100%",
          py: 2,
          flex: 1,
        }}
      >
        <Divider />

        {/* Account */}
        <Box height={150} justifyContent="space-between">
          <DesktopMenuItem
            collapsed={collapsed}
            icon={getUserRepresentationShort(user)}
            label={getUserRepresentation(user)}
            to="/account/"
          >
            <DesktopMenuSubItem
              collapsed={collapsed}
              label={window.gettext("Log out")}
              onClick={handleLogoutClick}
              icon={
                <Logout
                  sx={{
                    width: 18,
                    height: 18,
                  }}
                />
              }
            />
          </DesktopMenuItem>
        </Box>

        <Divider />

        {/* Projects */}
        <DesktopMenuItem
          collapsed={collapsed}
          icon={<AssignmentIcon />}
          label={window.gettext("Projects")}
          to="/projects/"
        >
          <DesktopMenuSubItem
            collapsed={collapsed}
            label={window.gettext("Create project")}
            icon={
              <Add
                sx={{
                  width: 22,
                  height: 22,
                }}
              />
            }
            onClick={() => navigate("/projects/create/")}
          />
        </DesktopMenuItem>

        {/* Statistics */}
        <UserPermission
          permission={UserPermission.permissions.radiator_view_statistics}
        >
          <DesktopMenuItem
            collapsed={collapsed}
            icon={<InsightsIcon />}
            label={window.gettext("Statistics")}
            to="/statistics/radiators/"
          />
        </UserPermission>

        {/* Issues */}
        <UserPermission permission={UserPermission.permissions.issue_view}>
          <DesktopMenuItem
            collapsed={collapsed}
            icon={<AssignmentLate />}
            label={window.gettext("Issues")}
            to="/issues/"
          />
        </UserPermission>
      </List>

      {/* Collapse/Expand button */}
      <IconButton
        onClick={collapsed ? expand : collapse}
        sx={{ borderRadius: 0 }}
      >
        {collapsed ? <ChevronRight /> : <ChevronLeft />}
      </IconButton>
    </Stack>
  );
}
