import BaseService from "./BaseService";

class SystemLocationsService extends BaseService {}

export default new SystemLocationsService('/api/',
  [
    'projects',
    'systems',
    'locations',
  ]
)