import {FIELD_TYPES} from "../constants";
import {FormField} from "./form_components";
import {FormFieldArguments} from "./FormFieldArguments";

export class FormFieldFactory {
  constructor(instance) {
    if (instance !== undefined) {
      this.instance = instance;
    } else {
      this.instance = {};
    }
  }

  field(fieldType, name, label, required, default_value, options) {
    if (!this.instance.hasOwnProperty(name)) {
      this.instance[name] = "";
    }

    let value = this.instance[name];

    // Use default value.
    if (value === null || value === "") {
      value = default_value;
    }

    if (value === null) {
      value = undefined;
    }

    return new FormField(fieldType, name, label, value, required, options);
  }

  booleanField = function (...args) {
    return this.field(FIELD_TYPES.BOOLEAN_FIELD, ...args);
  };

  choicesField = (...args) => {
    return this.field(FIELD_TYPES.CHOICES_FIELD, ...args);
  };

  currencyField = function (...args) {
    args = this.getFieldArguments(
      args,
      new FormFieldArguments({default_value: 0})
    );
    return this.field(FIELD_TYPES.CURRENCY_FIELD, ...args);
  };

  selectMultiple = function (...args) {
    return this.field(FIELD_TYPES.SELECT_MULTIPLE_FIELD, ...args);
  };

  decimalField = (...args) => {
    args = this.getFieldArguments(
      args,
      new FormFieldArguments({default_value: 0})
    );
    return this.field(FIELD_TYPES.DECIMAL_FIELD, ...args);
  };

  integerField = function (...args) {
    args = this.getFieldArguments(
      args,
      new FormFieldArguments({default_value: 0})
    );
    return this.field(FIELD_TYPES.INTEGER_FIELD, ...args);
  };

  textField = (...args) => {
    // Only to ensure backwards compatibility
    if (args[4] !== undefined && args[4].choices !== undefined) {
      return this.choicesField(...args);
    }
    return this.field(FIELD_TYPES.TEXT_FIELD, ...args);
  };

  autocompleteField = (...args) => {
    return this.field(FIELD_TYPES.AUTOCOMPLETE_FIELD, ...args);
  };

  textArea = function (...args) {
    return this.field(FIELD_TYPES.TEXT_AREA, ...args);
  };

  getFieldArguments = function (args, defaultArguments) {
    if (args.length < 3) {
      args.push(defaultArguments.required);
    }

    if (args.length < 4) {
      args.push(defaultArguments.default_value);
    }

    return args;
  };

  getChoicesFromObjectList = function (objectList, labelKey = 'name', valueKey = 'id') {
    let choices = {};
    for (let i = 0; i < objectList.length; i++) {
      choices[objectList[i][valueKey]] = objectList[i][labelKey];
    }
    return choices;
  }

  getAutocompleteOptionsListFromChoices = function (choicesObject) {
    /**
     * Gets list of choices as an array
     *
     * @type {*[]}
     */

    let options = []

    Object.entries(choicesObject).map(
      ([key, value], index) => {
        options.push(
          {
            'label': value,
            'id': parseInt(key),
          }
        )
      }
    )

    return options
  }
}
