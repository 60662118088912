import {Box, TableCell, TableRow} from "@mui/material";
import SelfCheckJobMeasurementReportProfileBlacklistService
  from "../../../../../services/SelfCheckJobMeasurementReportProfileBlacklistService";
import {useSnackbar} from "notistack";
import SelfCheckJobMeasurementReportProfileHideEntityService
  from "../../../../../services/SelfCheckJobMeasurementReportProfileHideEntityService";
import {useContext} from "react";
import {JobMeasurementReportContext} from "./JobMeasurementReportContext";
import get_class_name_for_target_type from "../../../../../utils/get_class_name_for_entity_type";
import {SelfCheckHierarchyViewLink} from "./SelfCheckHierarchyViewLink";
import EntityType from "../../entity_type/EntityType";
import MultilineText from "../../../../../layout/MultilineText";
import Temperature from "../../../../../values/Temperature";
import UserPermission from "../../../../../user/UserPermission";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function OutsideAcceptedRangeTableRow(
  {
    location,
    project_id,
    system_id,
    job_id,
    profile_id,
    hide_entity_id,
  }
) {
  let {reloadReport} = useContext(JobMeasurementReportContext)

  const {enqueueSnackbar} = useSnackbar();
  const handleBlacklistButtonClicked = (entity) => {
    SelfCheckJobMeasurementReportProfileBlacklistService.create(
      {
        'target_type': entity.type,
        'id': entity.id
      },
      project_id,
      system_id,
      job_id,
      profile_id,
    ).then(
      response => {
        enqueueSnackbar(
          window.gettext('Entity blacklisted'),
          {
            'variant': 'success'
          },
        )

        enqueueSnackbar(window.gettext('Reloading report'))
        reloadReport()
      },
      error => {
        enqueueSnackbar(
          window.gettext('Error while trying to blacklist entity'),
          {
            'variant': 'error',
          }
        )
      }
    )
  }

  const handleHideButtonClicked = (entity) => {
    SelfCheckJobMeasurementReportProfileHideEntityService.create(
      {
        'target_type': entity.type,
        'id': entity.id
      },
      project_id,
      system_id,
      job_id,
      profile_id,
    ).then(
      response => {
        enqueueSnackbar(
          window.gettext('Entity hidden'),
          {
            'variant': 'success'
          },
        )
        enqueueSnackbar(window.gettext('Reloading report'))
        reloadReport()
      },
      error => {
        enqueueSnackbar(
          window.gettext('Error while trying to hide entity'),
          {
            'variant': 'error',
          }
        )
      }
    )
  }
  const handleShowButtonClicked = (entity) => {
    SelfCheckJobMeasurementReportProfileHideEntityService.destroy(
      project_id,
      system_id,
      job_id,
      profile_id,
      hide_entity_id,
    ).then(
      response => {
        enqueueSnackbar(
          window.gettext('Entity removed from hidden list'),
          {
            'variant': 'success'
          },
        )
        enqueueSnackbar(window.gettext('Reloading report'))
        reloadReport()
      },
      error => {
        enqueueSnackbar(
          window.gettext('Error while trying remove entity from hidden list'),
          {
            'variant': 'error',
          }
        )
      }
    )
  }

  // IF Hide enity id is something that is not null, then it is to be hidden
  return (
    <TableRow
      className={get_class_name_for_target_type(location.entity.type)}
      key={'outside_accepted_range_table_row-' + String(location.entity.type) + '-' + String(location.entity.id)}
      sx={
        {
          textDecoration: hide_entity_id !== null ? 'line-through' : ''
        }
      }
    >
      <TableCell>
        <SelfCheckHierarchyViewLink
          project_id={project_id}
          system_id={system_id}
          job_id={job_id}
          entity={location.entity}
        />
      </TableCell>
      {/* Address */}
      <TableCell>
        {location?.entity?.data?.address?.verbose_label}
      </TableCell>
      <TableCell>
        <EntityType entityType={location.entity.type}/>
      </TableCell>
      <TableCell>
        {location.measurement_instances.map((measument_instance) => {
          return (
            <Box
              key={'measurement_instance_comment' + measument_instance.id}
            >
              <MultilineText>
                {measument_instance.comment}
              </MultilineText>
            </Box>
          )
        })}
      </TableCell>
      <TableCell>
        <Temperature value={location.room_temperature?.median} precision={1}/>
      </TableCell>
      <TableCell>
        <Temperature value={location.latest_measurement_instance?.inflow_temperature} precision={1}/>
      </TableCell>
      <TableCell>
        <Temperature value={location.latest_measurement_instance?.return_temperature} precision={1}/>
      </TableCell>
      <TableCell
        sx={
          {
            'displayPrint': 'none'
          }
        }
      >
        <UserPermission
          permission={UserPermission.permissions.measurement_report_profile_blacklisted_entity_add}
        >
          <IconButton
            onClick={() => {
              handleBlacklistButtonClicked(location.entity)
            }}
          >
            <DeleteIcon color={"error"}/>
          </IconButton>
        </UserPermission>
      </TableCell>
      <TableCell
        sx={
          {
            'displayPrint': 'none'
          }
        }
      >
        <UserPermission
          permission={UserPermission.permissions.measurement_report_profile_hidden_entity_add}
        >
          {hide_entity_id !== null ? (
            <IconButton
              onClick={() => {
                handleShowButtonClicked(location.entity)
              }}
            >
              <VisibilityIcon color={"warning"}/>
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleHideButtonClicked(location.entity)
              }}
            >
              <VisibilityOffIcon color={"warning"}/>
            </IconButton>
          )}
        </UserPermission>
      </TableCell>
    </TableRow>
  )
}