import BaseInfoTabSection from "../layout/information/base_info/BaseInfoTabSection";
import BaseInfoTabSectionItem from "../layout/information/base_info/BaseInfoTabSectionItem";
import LoadingIndicator from "../layout/LoadingIndicator";
import Choice from "../values/Choice";
import DateTime from "../values/DateTime";
import TimeDuration from "../values/TimeDuration";
import MultilineText from "../layout/MultilineText";

export default function IssueHistoryInfo({field_dict, issueChoices}) {
  return (
    <>
      {!field_dict ? (
        <>
          <LoadingIndicator/>
        </>
      ) : (
        <>
          <BaseInfoTabSection title={window.gettext("Issue information")}>
            <BaseInfoTabSectionItem title={window.gettext("Issue number")}>
              {field_dict.number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Status")}>
              <Choice
                choices={issueChoices}
                value={field_dict.status}
                namespace="status"
              />
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Category")}>
              {field_dict.category_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Created")}>
              <DateTime value={field_dict.created}/>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Created by")}>
              {field_dict.created_by_name}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Updated")}>
              <DateTime value={field_dict.changed}/>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Updated by")}>
              {field_dict.updated_by_name}
            </BaseInfoTabSectionItem>
            {field_dict.spent_time_minutes !== undefined && (
              <BaseInfoTabSectionItem title={window.gettext("Spent time")}>
                <TimeDuration minutes={field_dict.spent_time_minutes}/>
              </BaseInfoTabSectionItem>
            )}
            <BaseInfoTabSectionItem title={window.gettext("Description")}>
              <MultilineText>
                {field_dict.description}
              </MultilineText>
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Note")}>
              <MultilineText>
                {field_dict.note}
              </MultilineText>
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          {/* Location */}
          <BaseInfoTabSection title={window.gettext("Location")}>
            <BaseInfoTabSectionItem title={window.gettext("Apartment number")}>
              {field_dict.apartment_number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Story")}>
              {field_dict.story}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          {/* Contact information */}
          <BaseInfoTabSection title={window.gettext("Contact information")}>
            <BaseInfoTabSectionItem title={window.gettext("Phone number")}>
              {field_dict.feedback_phone_number}
            </BaseInfoTabSectionItem>
            <BaseInfoTabSectionItem title={window.gettext("Email")}>
              {field_dict.feedback_email}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>
        </>
      )}
    </>
  )
}