import {GridRowActionButton} from "../../GridRowActionButton";
import React, {useState} from "react";
import getRowClassName from "../GetRowClassName";
import getColumnWidth from "../GetColumnWidth";

class ToggleMenuProxy {
  callbacks = []

  register = function (rowId, callback) {
    this.callbacks[rowId] = callback;
  }

  callback = function (rowId, event) {
    this.callbacks[rowId](event)
  }
}

export default function ActionsColumnDefinition(
  actionCallbackFunction,
  nodes,
) {
  const toggleMenuProxy = new ToggleMenuProxy();

  return {
    field: 'actions',
    width: getColumnWidth('actions'),
    disableExport: true,
    getRowClassName: getRowClassName,
    headerName: window.gettext('Actions'),
    disableColumnMenu: true,
    sortable: false,
    toggleMenu: toggleMenuProxy,
    renderCell: (cellValues) => {
      return (
        <GridRowActionButton
          cellValues={cellValues}
          actionCallbackFunction={actionCallbackFunction}
          nodes={nodes}
          toggleMenuProxy={toggleMenuProxy}
        />
      )
    },
  }
}