import BaseValidator from "../BaseValidator";

export default class RowCountValidator extends BaseValidator {
  getRowsErrors = (rows) => {
    if (rows.length === 0) {
      return [
        this.error(
          this.ERROR_TYPE_SYSTEM,
          window.gettext('Number of rows is to less than minimum')
        )
      ]
    }
  }
}