import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import InstanceManipulator from "../InstanceManipulator";
import ChoicesService from "../services/ChoicesService";
import ProjectService from "../services/ProjectService";
import UserPermission from "../user/UserPermission";
import ProjectStageForm from "./ProjectStageForm";

import { LayoutCard } from "../../layout";

export default function ProjectDetailProjectStageTabContent({ project }) {
  const { enqueueSnackbar } = useSnackbar();

  const [localProject, setLocalProject] = useState(project);
  const [stageChoices, setStageChoices] = useState(null);

  useEffect(() => {
    ChoicesService.projectChoices().then((projectChoices) => {
      setStageChoices(projectChoices.stage);
    });
  }, []);

  function postUpdate(newProject) {
    setLocalProject(newProject);

    enqueueSnackbar(window.gettext("Project stage updated"), {
      variant: "success",
    });
  }

  return (
    <LayoutCard title={window.gettext("Change project stage")}>
      {stageChoices && localProject && (
        <UserPermission
          permission={UserPermission.permissions.project_change_stage}
        >
          <InstanceManipulator
            mode={"edit"}
            urlParams={[localProject.id]}
            service={ProjectService}
            postUpdate={postUpdate}
            form={
              <ProjectStageForm
                instance={localProject}
                stageChoices={stageChoices}
              />
            }
          />
        </UserPermission>
      )}
    </LayoutCard>
  );
}
