import React from "react";
import AddSimpleRadiatorModal from "./AddSimpleRadiatorModal";


export default function AddSimpleRadiatorModalButton(
  {
    projectId,
    systemId,
    parentNodeId,
    onCreated,
    children,
    entranceShortCode,
    apartmentNumber
  }
) {

  return (
    <>
      <AddSimpleRadiatorModal
        projectId={projectId}
        systemId={systemId}
        parentNodeId={parentNodeId}
        button={children || undefined}
        onCreated={onCreated}
        entranceShortCode={entranceShortCode}
        apartmentNumber={apartmentNumber}
      />
    </>
  )
}
