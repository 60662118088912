import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MeasurementsList from "../../../../../measurement/MeasurementsList";

import {
  useHierarchyContext,
  useHierarchyRSContext,
} from "../../../../context";


export function AccordionMeasurements() {
  const { project, system, jobData } = useHierarchyContext();
  const { measurements, latestOpportunity } = useHierarchyRSContext();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">{window.gettext("Measurements")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {measurements.length === 0 ? (
          <>
            <Alert severity={"info"}>
              {window.gettext("No measurements found")}
            </Alert>
          </>
        ) : (
          <>
            <MeasurementsList
              projectId={project?.id}
              systemId={system?.id}
              jobId={jobData?.job.id}
              jobSpecId={jobData?.job.spec_id}
              measurements={measurements}
              latestMeasurement={latestOpportunity?.measurement}
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
