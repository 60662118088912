import BaseService from "./BaseService";

class SelfCheckJobTaskItemService extends BaseService {}

export default new SelfCheckJobTaskItemService("/api/", [
  "projects",
  "systems",
  "self-check/jobs",
  "task-items",
]);
