import PropTypes from "prop-types";
import EntityText from "./EntityText";
import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";

export default function Entity(props) {
  let {link, entityData, fontSize, children} = props

  return (
    <>
      {!link ? (
        <>
          <EntityText
            entity={entityData.entity}
            fontSize={fontSize}
          />
        </>
      ) : (
        <>
          <Link
            component={RouterLink}
            to={link}
          >
            {children ? (
              <>
                {children}
              </>
            ) : (
              <EntityText
                entity={entityData.entity}
                fontSize={fontSize}
              />
            )}
          </Link>
        </>
      )}
    </>
  )
}

Entity.propTypes = {
  entityData: PropTypes.object,
}