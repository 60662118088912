import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DateTime from "../../../components/values/DateTime";
import TimeDuration from "../../../components/values/TimeDuration";
import DeleteIcon from "@mui/icons-material/Delete";
import IssueSpentTimeService from "../../../components/services/IssueSpentTimeService";
import {useSnackbar} from "notistack";
import {useState} from "react";
import {useIssue} from "../../../components/issue_tracker/IssueContext";
import {useIssueSpentTime} from "../../../components/issue_tracker/IssueSpentTimeContext";

export default function IssueSpentTimeEntriesTable({entries, allowDelete = true}) {
  // Snackbar
  const {enqueueSnackbar} = useSnackbar();
  const [deletedEntries, setDeletedEntries] = useState([])
  const {issue, refreshIssue} = useIssue()
  const {refreshSpentTimeEntries} = useIssueSpentTime()

  const handleDeleteButtonClicked = (entry_id) => {
    setDeletedEntries(
      deletedEntries.concat([entry_id])
    )

    IssueSpentTimeService.destroy(
      issue.id,
      entry_id
    ).then(
      () => {
        enqueueSnackbar(
          window.gettext('Spent time entry deleted'),
          {
            'variant': 'success'
          }
        )
        refreshIssue()
        refreshSpentTimeEntries()
      }
    )
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {window.gettext("Date")}
            </TableCell>
            <TableCell>
              {window.gettext("Duration")}
            </TableCell>
            <TableCell>
              {window.gettext("User")}
            </TableCell>
            <TableCell>
              &nbsp;
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map((entry, index) => (
              <TableRow
                key={index}
              >
                <TableCell>
                  <DateTime value={entry.created}/>
                </TableCell>
                <TableCell>
                  <TimeDuration
                    minutes={entry.minutes}
                  />
                </TableCell>
                <TableCell>
                  {entry.user_name}
                </TableCell>
                <TableCell>
                  <Button
                    startIcon={<DeleteIcon/>}
                    disabled={deletedEntries.includes(entry.id)}
                    color={"error"}
                    variant={"contained"}
                    onClick={
                      () => {
                        handleDeleteButtonClicked(entry.id)
                      }
                    }
                  >
                    {window.gettext('Delete')}
                  </Button>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}