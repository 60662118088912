import React, { useEffect, useState } from "react";
import { Alert, AlertTitle } from "@mui/material";

import ProjectRowService from "../services/ProjectRowService";
import SelfCheckTaskItemSpecService from "../services/SelfCheckTaskItemSpecService";

import { Form, FormFieldFactory } from "../form";

export default function SelfCheckTaskItemOptionListSpecForm({
  instance,
  jobSpec,
  taskSpec,
  taskItemSpecId,
  project,
  onSubmit,
  submitButtonText = window.gettext("Add option"),
}) {
  const [formFields, setFormFields] = useState([]);

  const [projectRowChoices, setProjectRowChoices] = useState({});
  const [taskItemSpec, setTaskItemSpec] = useState(undefined);

  useEffect(() => {
    if (!project || !taskSpec || !jobSpec || taskItemSpecId === undefined) {
      return;
    }

    if (taskItemSpecId === null) {
      setTaskItemSpec(null);
      return;
    }

    SelfCheckTaskItemSpecService.fetch(
      project.id,
      jobSpec.id,
      taskSpec.id,
      taskItemSpecId
    ).then((response) => {
      setTaskItemSpec(response);
    });
  }, [project, taskSpec, taskItemSpecId, jobSpec]);

  useEffect(() => {
    if (!project || !jobSpec || taskItemSpec === undefined) {
      return;
    }

    ProjectRowService.unused(project.id).then((response) => {
      let choices = Object.fromEntries(
        response.map((project_row) => {
          return [parseInt(project_row.id), project_row.label];
        })
      );

      if (taskItemSpec !== null) {
        choices[taskItemSpec.project_row.id] = taskItemSpec.project_row.label;
      }
      
      setProjectRowChoices(choices);
    });
  }, [project, jobSpec, taskItemSpec]);

  useEffect(() => {
    if (
      !projectRowChoices ||
      (instance && projectRowChoices[instance.project_row_id] === undefined)
    ) {
      return;
    }

    const form_field_factory = new FormFieldFactory(instance);

    setFormFields([
      // name
      form_field_factory.textField("name", window.gettext("Name"), true),
      // project_row_id
      form_field_factory.textField(
        "project_row_id", window.gettext("Project row"), true, "", {
          choices: projectRowChoices,
        }
      ),
    ]);
  }, [projectRowChoices, instance]);

  return (
    <>
      {formFields ? (
        <>
          {!Object.keys(projectRowChoices).length ? (
            <>
              <Alert severity="warning">
                <AlertTitle>
                  {window.gettext("No unused project rows to choose from")}
                </AlertTitle>
                {window.gettext(
                  'To be able to create a task item specification there needs to be a free "Project row" on this project'
                )}
              </Alert>
            </>
          ) : (
            <Form
              header={submitButtonText}
              formTestId="self-check-task-spec-form"
              formFields={formFields}
              submitButtonText={submitButtonText}
              onSubmit={onSubmit}
            />
          )}
        </>
      ) : (
        <>{window.gettext("Loading")}...</>
      )}
    </>
  );
}
