import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SelfCheckJobMeasurementReportProfileService from "../../../services/SelfCheckJobMeasurementReportProfileService";

export const ReportMeasurementProfileContext = createContext({
  listProfiles: undefined,
  getProfile: undefined,
  createProfile: undefined,
  deleteProfile: undefined,
  updateProfile: undefined,
  selfCheckJobMeasurementReportProfileService: undefined,
  selfCheckJobMeasurementReportProfileServiceParams: undefined,
})
export default function ReportMeasurementProfileProvider(props) {
  let {project_id, system_id, job_id, profile_id} = useParams()

  const listProfiles = () => {
    return SelfCheckJobMeasurementReportProfileService.fetch(
      project_id,
      system_id,
      job_id,
    )
  }

  const getProfile = (profileId) => {
    return SelfCheckJobMeasurementReportProfileService.fetch(
      project_id,
      system_id,
      job_id,
      profileId,
    )
  }

  const updateProfile = (profileId, data) => {
    return SelfCheckJobMeasurementReportProfileService.update(
      data,
      project_id,
      system_id,
      job_id,
      profileId,
    )
  }

  const createProfile = (data) => {
    return SelfCheckJobMeasurementReportProfileService.create(
      data,
      project_id,
      system_id,
      job_id,
    )
  }

  const deleteProfile = (profileId) => {
    return SelfCheckJobMeasurementReportProfileService.destroy(
      project_id,
      system_id,
      job_id,
      profileId,
    )
  }

  const addToBlacklist = (entity) => {
    console.error('Add to blacklist', entity)
  }

  return (
    <ReportMeasurementProfileContext.Provider
      value={
        {
          'listProfiles': listProfiles,
          'updateProfile': updateProfile,
          'createProfile': createProfile,
          'deleteProfile': deleteProfile,
          'getProfile': getProfile,
          'addToBlacklist': addToBlacklist,
          'selfCheckJobMeasurementReportProfileService': SelfCheckJobMeasurementReportProfileService,
          'selfCheckJobMeasurementReportProfileServiceParams': [
            project_id,
            system_id,
            job_id,
            profile_id || null,
          ],
        }
      }
    >
      {props.children}
    </ReportMeasurementProfileContext.Provider>
  )
}
