export default function getUserRepresentation(user){
  if(!user){
    return ''
  }

  let representation = ""

  if(user.first_name){
    representation += user.first_name
  }

  if(user.first_name && user.last_name){
    representation += ' '
  }

  if(user.last_name){
    representation += user.last_name
  }

  if(!user.last_name && !user.first_name){
    representation += user.username
  }

  return representation
}