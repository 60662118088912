import BaseService from "./BaseService";

class PipeService extends BaseService {}

export default new PipeService('/api/',
  [
    'projects',
    'systems',
    'pipes',
  ]
)