export default function valueRounder(value, decimals){
  if(decimals === null){
    return value
  }

  // Default to 0 decimals
  if(decimals === undefined){
    decimals = 0
  }

  // Whole numbers
  let factor = 1

  if(decimals > 0){
    factor = Math.pow(10, (decimals || 1))
  }

  // Multiply and divide by factor
  return Math.round(value * factor) / factor
}