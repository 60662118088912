import React from "react";
import getRowClassName from "../GetRowClassName";
import getColumnWidth from "../GetColumnWidth";
import Flag from "../../../../flag/Flag";
import ValueGetter from "../../ValueGetter";
import EditFlagCellInput from "../../edit_cell/EditFlagCellInput";
import Flow from "../../../../values/Flow";
import EditCustomFlowCell from "../../edit_cell/EditCustomFlowCell";

export default function CustomFlowColumnDefinition() {
  return {
    field: 'custom_flow',
    width: getColumnWidth('custom_flow'),
    disableExport: true,
    getRowClassName: getRowClassName,
    headerName: window.gettext('Custom flow'),
    disableColumnMenu: true,
    valueGetter: (params => {
      if(params.row.node.item.flow === undefined){
        return null
      }

      return params.row.node.item.flow
    }),
    editable: true,
    renderCell: (params) => {
      return (
        <>
          <Flow
            value={!params.value ? null : parseFloat(params.value)}
          />
        </>
      )
    },
    renderEditCell: (params) => {
      // For anything NOT radiators, just render an empty cell
      if(params.row.node.type !== 'radiator'){
        return <></>
      }

      return <EditCustomFlowCell {...params} />
    }
  }
}