export default function getColumnWidth(field) {
  const margin = 0

  const baseWidths = {
    "actions": 65,
    "id": 94,
    "name": 111,
    "parent": 109,
    "address": 116,
    "room": 106,
    "local_apartment_number": 122,
    "surveying_apartment_number": 129,
    "connection": 136,
    "dimension": 132,
    "pipe_type": 114,
    "valve_type": 123,
    "length": 112,
    "kvs": 130,
    "radiator": 122,
    "exact_resistance": 174,
    "additional_resistance": 155,
    "effect_percentage": 163,
    "effect": 110,
    "flow": 110,
    "custom_flow": 120,
    "pressure_drop": 123,
    "available_pressure": 156,
    "kv_value": 124,
    "nipple": 124,
    "pressure_drop_per_meter": 150,
    "speed": 115,
    "flag": 80,
    "note": 100,
  }

  if(!baseWidths.hasOwnProperty(field)){
    return undefined
  }

  return baseWidths[field] + margin
}