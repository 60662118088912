import { useEffect } from "react";

import { useLayoutToolbarContext } from "../context";

export function useLayoutToolbarControl() {
  const layoutToolbarContext = useLayoutToolbarContext();

  return {
    exist: layoutToolbarContext.toolbarExist,
    hidden: layoutToolbarContext.toolbarHidden,

    hide: layoutToolbarContext.hideToolbar,
    show: layoutToolbarContext.showToolbar,
  };
}

export function useLayoutToolbar() {
  const layoutToolbarContext = useLayoutToolbarContext();

  // Remove toolbar on unmount
  useEffect(() => {
    layoutToolbarContext.onToolbarMount();

    // Cleanup on unmount
    return () => {
      layoutToolbarContext.cleanup();
    };
  }, []);

  // Set toolbar
  function set(toolbar, show) {
    layoutToolbarContext.setToolbar(toolbar, show);
  }

  return {
    exist: layoutToolbarContext.toolbarExist,
    hidden: layoutToolbarContext.toolbarHidden,

    hide: layoutToolbarContext.hideToolbar,
    show: layoutToolbarContext.showToolbar,

    set,
  };
}
