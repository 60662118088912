import {useEffect, useState} from "react";

/*
* props.value = the value, always in centimeters
* props.precision = how many decimal points we should display
* props.unit = w/kw
* */
export default function Choice(props) {
  let [displayValue, setDisplayValue] = useState(undefined)
  let [choices, setChoices] = useState(props.choices)
  const namespace = props.namespace || undefined
  let [value, setValue] = useState(props.value)

  const getChoices = function (){
    if(namespace === undefined){
      return choices
    }

    return choices[namespace]
  }

  const getValueFromChoices = function () {
    const c = getChoices()

    return c[value]
  }

  useEffect(() => {
    if (props.value === undefined) {
      return
    }

    setValue(props.value)
  }, [props.value])

 useEffect(() => {
   if(props.choices === undefined){
     return
   }

   setChoices(props.choices)
  }, [props.choices])

  useEffect(() => {
    if(choices === undefined || value === undefined){
      return
    }

    setDisplayValue(
      getValueFromChoices()
    )
  }, [choices, value])

  return (
    <>
      {displayValue}
    </>
  )
}