import PropTypes from "prop-types";
import {useEffect, useState} from "react";

import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import {Chip} from "@mui/material";

import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function JobName(props) {
  let [showAsLink, setShowAsLink] = useState(false)
  let [jobNameComponent, setJobNameComponent] = useState("")
  let [job, setJob] = useState()
  let [system, setSystem] = useState()
  let [linkTarget, setLinkTarget] = useState("")
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (!props.link) {
      return
    }
    console.log('link', props.link)

    setShowAsLink(props.link === true)
  }, [props.link])

  useEffect(() => {
    if (!props.job) {
      return
    }

    setJob(props.job)
  }, [props.job])

  useEffect(() => {
    if (!props.system) {
      return
    }

    console.log('sys', props.system)

    setSystem(props.system)
  }, [props.system])

  useEffect(
    () => {
      if (!job || !system) {
        return
      }

      let newLinkTarget = '/projects/' + String(system.project) + '/systems/' + String(system.id) + '/jobs/' + String(job.id)

      // If on mobile go to the hierarchy-view
      if (isMobile) {
        newLinkTarget += '/hierarchy'
      }

      // Set new target string to be used in the 'to' attribute
      setLinkTarget(
        newLinkTarget
      )
    },
    [
      job,
      system,
      isMobile,
    ]
  )

  useEffect(
    () => {
      if (!system || !job) {
        return
      }

      setJobNameComponent(
        <>
          <>
            <>
              {system.name}
            </>
            {job.spec_name && (
              <>
                <span>
                  <span>&nbsp;-&nbsp;</span>
                  <span>{job.spec_name}</span>
                </span>
              </>
            )}
          </>
        </>
      )
    },
    [
      system,
      job,
    ]
  )

  return (
    <>
      {/* Link or job text "System - Spec name"*/}
      <>
        {showAsLink ? (
          <>
            <Link
              component={RouterLink}
              to={linkTarget}
            >
              {jobNameComponent}
            </Link>
          </>
        ) : (
          <>
            {jobNameComponent}
          </>
        )}
      </>
      {/* Number */}
      {job && (
        <Chip
          label={'#' + String(job.number)}
          sx={{ml: 1}}
        />
      )}
    </>
  )
}

JobName.propTypes = {
  'system': PropTypes.object,
  'job': PropTypes.object,
  'link': PropTypes.bool
}