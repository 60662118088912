import BaseService from "./BaseService";

class ProjectMemberService extends BaseService {

}

export default new ProjectMemberService('/api/',
  [
    'project',
    'members'
  ]
)