import { useSnackbar } from "notistack";

import InstanceManipulator from "../../../../InstanceManipulator";

import { CenteredModal } from "../../../../layout/modal";
import { SystemBulkCreateEntitiesService } from "../../../../services";
import { SystemBuildPotableWaterBulkCreateEntitiesForm } from "../forms";

export function SystemBuildPotableWaterBulkCreateEntitiesModal({
  modalHandler,
  project_id,
  system_id,
}) {
  const { enqueueSnackbar } = useSnackbar();

  function postCreate() {
    modalHandler.close();
    
    enqueueSnackbar(
      window.gettext("Added entities"), {
      variant: "success",
    });
  }

  return (
    <CenteredModal modalHandler={modalHandler}>
      <InstanceManipulator
        mode="create"
        service={SystemBulkCreateEntitiesService}
        urlParams={[project_id, system_id]}
        postCreate={postCreate}
        form={<SystemBuildPotableWaterBulkCreateEntitiesForm />}
      />
    </CenteredModal>
  );
}
