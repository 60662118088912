import BaseService from "./BaseService";
class SelfCheckJobMeasurementReportProfileHideEntityService extends BaseService {
}

export default new SelfCheckJobMeasurementReportProfileHideEntityService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-profiles',
    'hidden'
  ]
)