import BaseValidator from "../BaseValidator";
import PipeLengthValidator from "./fields/PipeLengthValidator";
import PipeDimensionValidator from "./fields/PipeDimensionValidator";
import PipeTypeValidator from "./fields/PipeTypeValidator";
import RadiatorTypeValidator from "./fields/RadiatorTypeValidator";
import ValveTypeValidator from "./fields/ValveTypeValidator";
import RadiatorValveTypeValidator from "./fields/RadiatorValveTypeValidator";
import RadiatorDimensionValidator from "./fields/RadiatorDimensionValidator";

export default class FieldsValidator extends BaseValidator {
  constructor() {
    super();
    this.validators = [
      new PipeLengthValidator(),
      new PipeDimensionValidator(),
      new PipeTypeValidator(),
      new RadiatorTypeValidator(),
      new ValveTypeValidator(),
      new RadiatorValveTypeValidator(),
      new RadiatorDimensionValidator(),
    ]
  }

  getRowsErrors = (rows) => {

    // See if they match (node type etc), if so, run validator
    // Flatten the results
    return rows
      .map(
        (row) => {
          return this.validators.map((validator) => {
            if (!validator.match(row)) {
              return undefined;
            }
            return validator.getFieldErrors(row)
          })
        }
      )
      .flat(4)
      .filter((error) => {
        return error !== undefined
      })
  }

  getRowsWarnings = (rows) => {
    // See if they match (node type etc), if so, run validator
    // Flatten the results
    return rows
      .map(
        (row) => {
          return this.validators.map((validator) => {
            if (!validator.match(row)) {
              return undefined;
            }
            return validator.getFieldWarnings(row)
          })
        }
      )
      .flat(4)
      .filter((error) => {
        return error !== undefined
      })
  }
}