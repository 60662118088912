import paramsValueRounder from "./ParamsValueRounder";

export default function paramsValueFormatter(decimals, decimalSeparator){
  return function (params) {
    const roundedValue = paramsValueRounder(decimals)(params)

    if(roundedValue === undefined){
      return ""
    }

    // If no custom separator specified, return the number
    if(decimalSeparator === undefined){
      return roundedValue
    }

    return String(roundedValue).replace('.', decimalSeparator)
  }
}