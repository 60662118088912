import {
  Alert,
  Button,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import StyledTableRow from "../../layout/StyledTableRow";

import { LayoutCard } from "../../../layout";

export default function CSVImporter(props) {
  const [csvFile, setCsvFile] = useState();
  const [responseData, setResponseData] = useState(undefined);
  const [inFlight, setInFlight] = useState(false);
  const [csvConfig, setCsvConfig] = useState(undefined);

  function handleCSVFileInputChange(event) {
    setCsvFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    setInFlight(true);
    setResponseData(undefined);
    console.log("submit", csvFile, props.importerApiEndpoint);
    let data = new FormData();

    data.append("attachment", csvFile);

    axios.post(props.importerApiEndpoint, data).then((response) => {
      console.log("Done.", response.data);
      setResponseData(response.data);
      setInFlight(false);
    });
    event.preventDefault();
  }

  useEffect(() => {
    axios.get(props.importerApiEndpoint).then((response) => {
      setCsvConfig(response.data);
    });
  }, [props.importerApiEndpoint]);

  return (
    <>
      <LayoutCard title={window.gettext("Instructions")}>
        <List>
          <ListItem>{window.gettext("Include line 1 with headers")}</ListItem>

          <ListItem>{window.gettext("Data starts at line #2")}</ListItem>

          <ListItem>
            {window.gettext("Extra columns to the right are ok")}
          </ListItem>
        </List>
      </LayoutCard>

      <LayoutCard title={window.gettext("CSV Format")}>
        {csvConfig === undefined ? (
          <Typography>{window.gettext("Loading...")}</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{window.gettext("Column index")}</TableCell>

                <TableCell>{window.gettext("Name")}</TableCell>

                <TableCell>{window.gettext("Description")}</TableCell>

                <TableCell>{window.gettext("Value type")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {csvConfig.columns.map((column, index) => {
                return (
                  <StyledTableRow key={index}>
                    <TableCell>{index}</TableCell>

                    <TableCell>{column.name}</TableCell>

                    <TableCell>{column.description}</TableCell>

                    <TableCell>{column.type}</TableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </LayoutCard>

      <LayoutCard title={window.gettext("Import")}>
        <form onSubmit={handleSubmit} data-test-id="importer-form">
          <input
            type="file"
            name="csv_file"
            onChange={handleCSVFileInputChange}
          />

          <Button
            type="submit"
            data-test-id="importer-form-submit"
            disabled={inFlight}
          >
            {window.gettext("Import file")}
          </Button>

          {responseData !== undefined && (
            <Alert severity="success">
              {window
                .ngettext(
                  "Successfully imported %(number)d row",
                  "Successfully imported %(number)d rows",
                  responseData.saved_items
                )
                .replace("%(number)d", String(responseData.saved_items))}
            </Alert>
          )}
        </form>
      </LayoutCard>
    </>
  );
}
