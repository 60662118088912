import React, {useEffect, useState} from "react";
import {Autocomplete, createFilterOptions, TextField} from "@mui/material";
import SystemRoomService from "../../../services/SystemRoomService";

const filter = createFilterOptions()

export default function AutocompleteWithCreateInput(props) {
  const {id, api, field, options, label, selectedOptionId} = props;

  const handleChange = function (event, newValue) {
    // Change the value we display
    if (newValue === undefined) {
      return
    }

    if(newValue.hasOwnProperty('new_value')){
      api.setEditCellValue({id, field, value: newValue}, event)
    }else{
      api.setEditCellValue({id, field, value: newValue.id}, event)
    }
  }

  const value = options.find((option) => {
    return option.id === selectedOptionId
  }) || undefined

  return (
    <>
      <Autocomplete
        id="auto"
        freeSolo
        options={options}
        value={value}
        fullWidth={true}
        onChange={handleChange}
        getOptionLabel={(option) => {
          return option.name
        }}
        filterOptions={
          (options, params) => {
            const filtered = filter(options, params);

            const {inputValue} = params;
            // Suggest the creation of a new value
            const isExisting = options.some((option) => inputValue === option.title);

            if (inputValue !== '' && !isExisting) {
              filtered.push({
                new_value: true,
                input_value: inputValue,
                name: window.gettext('Add') + ' "'+ String(inputValue) + '"',
              });
            }

            return filtered;
          }
        }
        openOnFocus={true}
        renderInput={
          (params) => <TextField
            {...params}
            label={label}
            autoFocus={true}
          />}
      />
    </>
  )
}