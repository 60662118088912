import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function KVSColumnDefinition() {
  return {
    field: 'kvs',
    width: getColumnWidth('kvs'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('KVS'),
    valueFormatter: paramsValueFormatter(3, ','),
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('valve', 'node.item.type.kvs_value'),
          ValueGetterConf('radiator', 'node.item.valve_type.kvs_value'),
        ]
      ).get(params.row) || null
    })
  }
}