import { ArrowCircleRight } from "@mui/icons-material";
import {
  Box,
  Button,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService from "../../../../../../../services/measurement/SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService";

import { useHierarchyContext, useHierarchyRSContext } from "../../../context";

export const HASH_FOCUSED_MEASUREMENT_FORM_FIELD =
  "focus-measurement-form-field";

export function HierarchyRSMeasurements({
  entity,
  showNextEntityButton = false,
}) {
  const { hash } = useLocation();

  const { enqueueSnackbar } = useSnackbar();

  const { jobData, nextEntity, project, setEntity, system } =
    useHierarchyContext();

  const { fetchMeasurementSummary, measurementLastInstance } =
    useHierarchyRSContext();

  const shouldGoToNextRef = useRef(false);
  const [requestInFlight, setRequestInFlight] = useState(false);

  const [comment, setComment] = useState("");
  const [inflowTemperature, setInflowTemperature] = useState("");
  const [returnTemperature, setReturnTemperature] = useState("");
  const [roomTemperature, setRoomTemperature] = useState("");

  useEffect(() => {
    setComment(measurementLastInstance?.comment || "");
    setInflowTemperature(measurementLastInstance?.inflow_temperature || "");
    setReturnTemperature(measurementLastInstance?.return_temperature || "");
    setRoomTemperature(measurementLastInstance?.room_temperature || "");
  }, [measurementLastInstance]);

  function handleClickNextEntity() {
    if (requestInFlight) {
      shouldGoToNextRef.current = true;

      return;
    }

    setEntity(nextEntity, HASH_FOCUSED_MEASUREMENT_FORM_FIELD);
  }

  function handleCommentChange(e) {
    setComment(e.target.value);
  }

  function handleOnBlur() {
    // Save instance when users click outside the field
    saveInstance();
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      saveInstance();
    }
  }

  function handleReturnTemperatureChange(e) {
    setReturnTemperature(e.target.value);
  }

  function handleOutflowTemperatureChange(e) {
    setInflowTemperature(e.target.value);
  }

  function handleOutsideTemperatureChange(e) {
    setRoomTemperature(e.target.value);
  }

  function hasChangeFromMeasurementInstance() {
    function compare(state, instanceValue) {
      return state === (instanceValue === null ? "" : instanceValue);
    }

    if (
      compare(inflowTemperature, measurementLastInstance?.inflow_temperature) &&
      compare(returnTemperature, measurementLastInstance?.return_temperature) &&
      compare(roomTemperature, measurementLastInstance?.room_temperature) &&
      compare(comment, measurementLastInstance?.comment)
    ) {
      return false;
    }

    return true;
  }

  function onSaved(instance) {
    fetchMeasurementSummary(instance.node.id);
  }

  function performPutRequest() {
    setRequestInFlight(true);

    return new Promise((resolve, reject) => {
      SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService.put(
        {
          room_temperature: roomTemperature,
          inflow_temperature: inflowTemperature,
          return_temperature: returnTemperature,
          comment: comment,
        },
        project.id,
        system.id,
        jobData.job.id,
        entity.id,
        "carry_over=true"
      ).then(
        (measurementLastInstance) => {
          setRequestInFlight(false);

          resolve(measurementLastInstance);
        },
        (rejection) => {
          setRequestInFlight(false);

          reject(rejection);
        }
      );
    });
  }

  function saveInstance() {
    if (
      comment === "" &&
      inflowTemperature === "" &&
      returnTemperature === "" &&
      roomTemperature === "" &&
      measurementLastInstance === null
    ) {
      return;
    }

    if (!hasChangeFromMeasurementInstance()) {
      return;
    }

    performPutRequest()
      .then((measurementLastInstance) => {
        console.log("putted", measurementLastInstance);

        onSaved(measurementLastInstance);

        if (shouldGoToNextRef.current) {
          shouldGoToNextRef.current = false;

          setEntity(nextEntity, HASH_FOCUSED_MEASUREMENT_FORM_FIELD);
        }
      })
      .catch((error) => {
        if (shouldGoToNextRef.current) {
          shouldGoToNextRef.current = false;
        }

        enqueueSnackbar(window.gettext("Unable to save measurement values"), {
          variant: "error",
        });
      });
  }

  return (
    <Stack
      sx={{
        gap: !showNextEntityButton || !nextEntity ? 1 : 2,
      }}
    >
      <form>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              gap: 1,
              justifyContent: "space-between",
            }}
          >
            <TextField
              autoFocus={hash === "#" + HASH_FOCUSED_MEASUREMENT_FORM_FIELD}
              fullWidth
              id="outlined-inflow-temp"
              inputProps={{ inputMode: "decimal" }}
              label={window.gettext("Inflow temp")}
              onBlur={handleOnBlur}
              onChange={handleOutflowTemperatureChange}
              onKeyDown={handleKeyDown}
              type="number"
              value={inflowTemperature}
              variant={"outlined"}
            />

            <TextField
              fullWidth
              id="outlined-return-temp"
              inputProps={{ inputMode: "decimal" }}
              label={window.gettext("Return temp")}
              onBlur={handleOnBlur}
              onChange={handleReturnTemperatureChange}
              onKeyDown={handleKeyDown}
              type="number"
              value={returnTemperature}
              variant={"outlined"}
            />

            <TextField
              fullWidth
              id="outlined-room-temp"
              inputProps={{ inputMode: "decimal" }}
              label={window.gettext("Room temp")}
              onBlur={handleOnBlur}
              onChange={handleOutsideTemperatureChange}
              onKeyDown={handleKeyDown}
              type="number"
              value={roomTemperature}
              variant={"outlined"}
            />
          </Stack>

          <TextField
            id="outlined-number"
            fullWidth
            label={window.gettext("Comment")}
            multiline
            onBlur={handleOnBlur}
            onChange={handleCommentChange}
            rows={4}
            value={comment}
            variant={"outlined"}
          />
        </Stack>
      </form>

      <Stack>
        {!showNextEntityButton || !nextEntity ? (
          <Box
            sx={{
              height: 4,
            }}
          >
            {requestInFlight && (
              <LinearProgress
                sx={{
                  width: "100%",
                }}
              />
            )}
          </Box>
        ) : (
          <Tooltip
            title={
              window.gettext("Go to next") +
              " (" +
              (nextEntity?.data?.label || "[Unknown]") +
              ")"
            }
          >
            <Button
              variant={"contained"}
              size={"large"}
              endIcon={!requestInFlight && <ArrowCircleRight />}
              onClick={handleClickNextEntity}
              sx={{
                height: 42.25,
              }}
            >
              {requestInFlight ? (
                <LinearProgress
                  sx={{
                    width: "100%",
                  }}
                />
              ) : (
                window.gettext("Go to next")
              )}
            </Button>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
