import {Alert, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import {LayoutCard} from "../../layout";
import IssueService from "../services/IssueService";
import Link from "@mui/material/Link";
import {IssueFilterContext} from "./IssueFilterContext";
import {useIssueChoices} from "./IssueChoicesContext";
import Choice from "../values/Choice";
import DateTime from "../values/DateTime";

export default function IssueList({filters}) {
  const [issues, setIssues] = useState([]);

  const {
    issueListFilters,
  } = useContext(IssueFilterContext);

  console.log(
    'IssueListFilters',
    issueListFilters
  )

  const {issueChoices} = useIssueChoices()


  useEffect(() => {
    // Fetch the data
    IssueService.list(
      issueListFilters
    ).then(
      (data) => {
        // This response is paginated
        // TODO: Implement pagination in a smart way
        setIssues(data.results)
      }
    );
  }, [issueListFilters]);

  return (
    <LayoutCard>
      {(issues !== undefined && issues.length === 0) ? (
        <>
          <Alert severity={'info'}>
            {window.gettext("No issues found")}
          </Alert>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {window.gettext("Issue number")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Status")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Project")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("System")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Apartment")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Created")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Created by")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Assigned to")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issues.map((issue, index) => {
                  return (
                    <TableRow key={'issue_row_' + String(index)}>
                      <TableCell>
                        <Link component={RouterLink} to={"/issues/" + String(issue.id) + '/'}>
                          {issue.number}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Choice
                          choices={issueChoices.status}
                          value={issue.status}
                        />
                      </TableCell>
                      <TableCell>
                        {issue.project_name}
                      </TableCell>
                      <TableCell>
                        {issue.system_name}
                      </TableCell>
                      <TableCell>
                        {issue.apartment_name}
                      </TableCell>
                      <TableCell>
                        <DateTime
                          value={issue.created}
                        />
                      </TableCell>
                      <TableCell>
                        {issue.created_by_name}
                      </TableCell>
                      <TableCell>
                        {issue.assigned_to_name}
                      </TableCell>
                    </TableRow>
                  )
                })}

              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </LayoutCard>
  );
}
