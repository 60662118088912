import { Button, Stack } from "@mui/material";

import { ModalImportJobSpec } from "./modals";

export function JobSpecSubmenuItems({ jobSpec, project, postImport }) {
  return (
    <Stack>
      {/* Import job spec button */}
      <ModalImportJobSpec
        jobSpec={jobSpec}
        project={project}
        postImport={postImport}
      >
        <Button variant="outlined">
          {window.gettext("Import Job Specification")}
        </Button>
      </ModalImportJobSpec>
    </Stack>
  );
}
