import BaseService from "./BaseService";

class SelfCheckProjectJobService extends BaseService {
}

export default new SelfCheckProjectJobService('/api/',
  [
    'projects',
    'jobs',
  ]
)