import BaseService from "../BaseService";

class TaskItemService extends BaseService {

}

export default new TaskItemService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'task-items',
  ]
)