import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_BATHROOM_FAUCET,
  ENTITY_TYPE_DISHWASHER,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_KITCHEN_FAUCET,
  ENTITY_TYPE_RADIATOR,
  ENTITY_TYPE_SHOWER,
  ENTITY_TYPE_SYSTEM,
  ENTITY_TYPE_TOILET,
  ENTITY_TYPE_VALVE,
  ENTITY_TYPE_WASHING_MACHINE,
} from "../../../entity";
import getCompleteEntityListFromJobData from "../../../utils/getCompleteEntityListFromJobData";

import { HierarchyViewOptionsList } from "../../classes";

export function useProvideHierarchyContext({
  project,
  system,
  jobData,
  entityId,
  entityType,
  fetchOptions,
  refreshJobData,
  setJobData,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [crumbs, setCrumbs] = useState([]);
  const [entities, setEntities] = useState([]);
  const [entityListLinkEntities, setEntityListLinkEntities] = useState(null);
  const [entityListLinkEntityTypes, setEntityListLinkEntityTypes] = useState(
    []
  );
  const [focusEntityData, setFocusEntityData] = useState(null);
  const [hierarchyViewOptionsList, setHierarchyViewOptionsList] = useState(
    new HierarchyViewOptionsList()
  );
  const [hierarchyViewOptions, setHierarchyViewOptions] = useState(undefined);
  const [nextEntity, setNextEntity] = useState(null);

  useEffect(() => {
    if (!entityType || !entityId || !entities) {
      return;
    }

    // Get entity from entities in jobData and set this as 'focusEntityData'
    // This entity is the one that we shall use for all major interactions
    let newFocusEntityData = entities.find((entity) => {
      if (entity.entity.type !== entityType) {
        return false;
      }

      return entity.entity.id === entityId;
    });

    // todo: It has been seen that the newFocusEntityData is undefined, why could that be?
    // If the focus entity actually is changed, set the new one.
    setFocusEntityData(newFocusEntityData);
  }, [entityId, entityType, entities]);

  /*
   * In the event that we have the system as starting point, system may not be in the response of entities
   * */
  useEffect(() => {
    if (entityType !== ENTITY_TYPE_SYSTEM) {
      return;
    }

    setEntityListLinkEntityTypes([ENTITY_TYPE_ENTRANCE]);
  }, [focusEntityData, entityType, system]);

  useEffect(() => {
    if (!focusEntityData || !entityType || !entities) {
      setCrumbs([]);
      return;
    }

    let newCrumbs = [];
    setEntityListLinkEntityTypes([]);

    /** So for the different things we should show differetn crumbs
     * As a rule, we shall the things "above" in hierarchy
     */
    // For Systems, show Entrances
    if (entityType === ENTITY_TYPE_SYSTEM) {
      setEntityListLinkEntityTypes([ENTITY_TYPE_ENTRANCE]);
    }

    // For Entrances, show Apartments
    if (entityType === ENTITY_TYPE_ENTRANCE) {
      setEntityListLinkEntityTypes([ENTITY_TYPE_APARTMENT]);
    }

    // For Apartments
    if (entityType === ENTITY_TYPE_APARTMENT) {
      if (system.type === "potable_water") {
        setEntityListLinkEntityTypes([
          ENTITY_TYPE_DISHWASHER,
          ENTITY_TYPE_BATHROOM_FAUCET,
          ENTITY_TYPE_KITCHEN_FAUCET,
          ENTITY_TYPE_SHOWER,
          ENTITY_TYPE_TOILET,
          ENTITY_TYPE_WASHING_MACHINE,
        ]);
      } else if (system.type === "radiator") {
        setEntityListLinkEntityTypes([ENTITY_TYPE_RADIATOR, ENTITY_TYPE_VALVE]);
      }
    }

    // Set Crumbs,
    if (
      entityType === ENTITY_TYPE_APARTMENT ||
      // Potable Water System
      entityType === ENTITY_TYPE_DISHWASHER ||
      entityType === ENTITY_TYPE_BATHROOM_FAUCET ||
      entityType === ENTITY_TYPE_KITCHEN_FAUCET ||
      entityType === ENTITY_TYPE_SHOWER ||
      entityType === ENTITY_TYPE_TOILET ||
      entityType === ENTITY_TYPE_WASHING_MACHINE ||
      // Radiator System
      entityType === ENTITY_TYPE_RADIATOR ||
      entityType === ENTITY_TYPE_VALVE
    ) {
      newCrumbs.push(
        entities.find((entity) => {
          return entity.entity.type === ENTITY_TYPE_ENTRANCE;
        })
      );
    }

    if (
      // Potable Water System
      entityType === ENTITY_TYPE_DISHWASHER ||
      entityType === ENTITY_TYPE_BATHROOM_FAUCET ||
      entityType === ENTITY_TYPE_KITCHEN_FAUCET ||
      entityType === ENTITY_TYPE_SHOWER ||
      entityType === ENTITY_TYPE_TOILET ||
      entityType === ENTITY_TYPE_WASHING_MACHINE ||
      // Radiator System
      entityType === ENTITY_TYPE_RADIATOR ||
      entityType === ENTITY_TYPE_VALVE
    ) {
      newCrumbs.push(
        entities.find((entity) => {
          return entity.entity.type === ENTITY_TYPE_APARTMENT;
        })
      );
    }

    newCrumbs.push(focusEntityData);

    setCrumbs(newCrumbs);
  }, [entityType, focusEntityData, system, entities]);

  useEffect(() => {
    if (!jobData || !entityId || !entityType) {
      return;
    }

    let newEntities = getCompleteEntityListFromJobData(jobData);

    setEntities(newEntities);
  }, [jobData, entityId, entityType]);

  useEffect(() => {
    if (!entities || !entityListLinkEntityTypes) {
      return;
    }

    setEntityListLinkEntities(
      entities.filter((entity) => {
        return entityListLinkEntityTypes.includes(entity.entity.type);
      })
    );
  }, [entities, entityListLinkEntityTypes]);

  useEffect(() => {
    setHierarchyViewOptions(hierarchyViewOptionsList[entityType]);

    setHierarchyViewOptions(hierarchyViewOptionsList[entityType]);
  }, [entityType, hierarchyViewOptionsList]);

  function updateHierarchyViewOptionsState() {
    setHierarchyViewOptionsList(new HierarchyViewOptionsList());
  }

  function updateSearchParams(newParams) {
    const params = {
      entity_id: newParams.entity_id || searchParams.get("entity_id"),
      entity_type: newParams.entity_type || searchParams.get("entity_type"),
      flagged_only: newParams.flagged_only || searchParams.get("flagged_only"),
      done_only: newParams.done_only || searchParams.get("done_only"),
      partly_done_only:
        newParams.partly_done_only || searchParams.get("partly_done_only"),
      visit_day: newParams.visit_day || searchParams.get("visit_day"),
    };

    // If we have no entity or want to remove entity, remove it from the search params
    if (
      params.entity_id === null ||
      params.entity_type === null ||
      newParams.entity_id === null ||
      newParams.entity_type === null
    ) {
      delete params.entity_id;
      delete params.entity_type;
    }

    // If we have no done_only or want to remove done_only, remove it from the search params
    if (params.done_only === null || newParams.done_only === null) {
      delete params.done_only;
    }

    // If we have no flagged_only or want to remove flagged_only, remove it from the search params
    if (params.flagged_only === null || newParams.flagged_only === null) {
      delete params.flagged_only;
    }

    // If we have no partly_done_only or want to remove partly_done_only, remove it from the search params
    if (
      params.partly_done_only === null ||
      newParams.partly_done_only === null
    ) {
      delete params.partly_done_only;
    }

    // If we have no visit_day or want to remove visit_day, remove it from the search params
    if (params.visit_day === null || newParams.visit_day === null) {
      delete params.visit_day;
    }

    setSearchParams(params);
  }

  function setEntity(entity, hash) {
    const params =
      entity?.id !== undefined && entity?.type !== undefined
        ? {
            entity_id: entity.id,
            entity_type: entity.type,
          }
        : {
            entity_id: null,
            entity_type: null,
          };

    updateSearchParams(params);

    if (hash) {
      window.location.hash = hash;
    }
  }

  function setDoneOnly(doneOnly) {
    const params = {
      done_only: doneOnly,
    };

    if (doneOnly === true) {
      params.partly_done_only = null;
    }

    updateSearchParams(params);
  }

  function setFlaggedOnly(flaggedOnly) {
    const params = {
      flagged_only: flaggedOnly,
    };

    updateSearchParams(params);
  }

  function setPartlyDoneOnly(partlyDoneOnly) {
    const params = {
      partly_done_only: partlyDoneOnly,
    };

    if (partlyDoneOnly === true) {
      params.done_only = null;
    }

    updateSearchParams(params);
  }

  function setVisitDay(visitDayId) {
    const params = {
      visit_day: visitDayId,
    };

    updateSearchParams(params);
  }

  return {
    crumbs,
    entityListLinkEntities,
    entityType,
    focusEntityData,
    fetchOptions,
    hasLinks: entityListLinkEntities?.length !== 0,
    hasTaskSpecifications: focusEntityData?.entity.task_specs.length > 0,
    hierarchyViewOptions,
    updateHierarchyViewOptionsState,
    setHierarchyViewOptions,
    jobData,
    nextEntity,
    setEntity,
    setNextEntity,
    setJobData,
    setDoneOnly,
    setFlaggedOnly,
    setPartlyDoneOnly,
    setVisitDay,
    project,
    refreshJobData,
    system,
  };
}
