import { createContext } from "react";
import { useProvideHierarchyRSContext } from "./hooks";

export const HierarchyPWSContext = createContext(null);

export function HierarchyPWSContextProvider({ children, ...props }) {
  const context = useProvideHierarchyRSContext(props);

  return (
    <HierarchyPWSContext.Provider value={context}>
      {children}
    </HierarchyPWSContext.Provider>
  );
}