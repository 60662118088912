import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

/*
* props.value = the value, always in centimeters
* props.precision = how many decimal points we should display
* props.unit = m/mm/cm
* */
export default function Length(props) {
  const defaultUnit = 'm'
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(1)
  let [unit, setUnit] = useState(defaultUnit)
  let [suffix, setSuffix] = useState("")


  // Set the rounded string value
  useEffect(() => {
    // Convert from input value to millimeters
    let value = props.value

    if(value === null){
      setDisplayValue(null)
      return
    }

    if (unit === 'cm') {
      value = props.value
    }

    if (unit === 'mm') {
      value = props.value * 10
    }

    if (unit === 'm') {
      value = value / 100
    }

    // Display string, fixed points
    setDisplayValue(
      value
    )
  }, [props.value, precision, unit])

  useEffect(() => {
    setUnit(props.unit === undefined ? defaultUnit : props.unit)
  }, [props.unit])

  // setting precision
  useEffect(() => {
    let defaultPrecision = 1

    if (props.precision === undefined) {
      if (unit === 'm') {
        defaultPrecision = 1
      }
      if (unit === 'cm') {
        defaultPrecision = 0
      }
      if (unit === 'mm') {
        defaultPrecision = 0
      }
    }

    setSuffix(unit)
    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [unit, props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} />
  )
}