class EventExecutorClass {
  name
  // If we should trigger on this event
  trigger
  // Function to execute once triggered
  execute

  constructor(name, trigger, execute, cellMode) {
    this.name = name
    this.trigger = trigger
    this.execute = execute
  }
}

export default function EventExecutor(
  name,
  trigger,
  execute,
) {
  return new EventExecutorClass(name, trigger, execute)
}