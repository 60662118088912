import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import IssueFilesService from "../services/IssueFilesService";


export const IssueFilesContext = createContext({
  files: undefined,
  refreshIssueFiles: undefined,
})


export function useIssueFiles() {
  return useContext(IssueFilesContext);
}

export default function IssueFilesProvider({children}) {
  let [files, setFiles] = useState(undefined)
  const issue_id = useParams().issue_id

  const refreshFiles = () => {
    IssueFilesService.list(
      issue_id
    ).then(setFiles)
  }

  useEffect(() => {
    refreshFiles()
  }, [issue_id]);

  return (
    <IssueFilesContext.Provider
      value={
        {
          'files': files,
          'refreshIssueFiles': () => {
            refreshFiles()
          }
        }
      }
    >
      {children}
    </IssueFilesContext.Provider>
  )
}
