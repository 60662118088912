import {Children} from "react";
import {LayoutCard} from "../../../../layout";
import {Stack} from "@mui/material";

export default function BaseInfoTabSection({title, children}) {
  // Returns a section with title as header, and children as content.
  // Returns null if none of the children has content.

  if (Children.toArray(children).every((child) => !child.props.children)) {
    return null;
  }

  return (
    <LayoutCard title={title}>
      <Stack spacing={1}>{children}</Stack>
    </LayoutCard>
  );
}

