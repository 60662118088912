import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Box, Grid, Tooltip} from "@mui/material";
import {cloneElement, useState} from "react";
import BasicModal from "../layout/BasicModal";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

export default function Helper({children, title, titleVariant, icon, fullOpacity, ml}) {
  let defaultIconOpacity = 0.4

  if (fullOpacity === true) {
    defaultIconOpacity = 1
  }
  const activeIconOpacity = 1

  let [iconOpacity, setIconOpacity] = useState(defaultIconOpacity)

  return (<>
    <BasicModal
      width={'90%'}
      toggleButton={<Box
        component={'span'}
        sx={
          {
            ml: (ml === true) ? 1 : (ml || 0),
            displayPrint: 'none',
            cursor: 'pointer'
          }
        }
      >
        <Tooltip title={title || ""}>
          {cloneElement((icon || <HelpOutlineIcon/>), {
              'onMouseOver': () => {
                setIconOpacity(activeIconOpacity)
              },
              'onMouseOut': () => {
                setIconOpacity(defaultIconOpacity)
              },
              'style': {
                'opacity': iconOpacity,
              }
            }
          )}

        </Tooltip>
      </Box>}
    >
      <>
        <Grid
          container
          direction={"column"}
          spacing={2}
        >
          {(!children) ? (<>
            <Grid item>
              <Box>
                {title}
              </Box>
            </Grid>
          </>) : (<>
            {title && (<Grid item>
              <Typography variant={titleVariant || "h4"}>
                {title}
              </Typography>
            </Grid>)}
            <Grid item>
              <Box>
                {children}
              </Box>
            </Grid>
          </>)}
        </Grid>
      </>
    </BasicModal>
  </>)
}

Helper.propTypes = {
  'title': PropTypes.string,
}