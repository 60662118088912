import { CONTAINER_TYPES } from "../constants";
import { FactoryStackContainer } from "./factory_containers";

export function FormContainerItem({
  formContainer,
  control,
  validationErrors,
}) {
  const props = {
    formContainer,
    control,
    validationErrors,
  };

  switch (formContainer.containerType) {
    case CONTAINER_TYPES.GROUP:
    case CONTAINER_TYPES.SECTION:
      return FactoryStackContainer(props);
  }

  console.error("Unknown container type", formContainer);
  return <></>;
}
