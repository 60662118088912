import {Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import SelfCheckJobMeasurementReportProfileBlacklistService
  from "../../../../../services/SelfCheckJobMeasurementReportProfileBlacklistService";
import {useSnackbar} from "notistack";
import Helper from "../../../../../utils/Helper";
import SelfCheckJobMeasurementReportProfileHideEntityService
  from "../../../../../services/SelfCheckJobMeasurementReportProfileHideEntityService";
import {useContext, useEffect, useState} from "react";
import {JobMeasurementReportContext} from "./JobMeasurementReportContext";
import UserPermission from "../../../../../user/UserPermission";
import OutsideAcceptedRangeTableRow from "./OutsideAcceptedRangeTableRow";


export function isMedianOutsideRange(median, low, high) {
  if (!median) {
    return
  }

  if (median < low) {
    return true
  }

  if (median > high) {
    return true
  }

  return false
}

export default function OutsideAcceptedRangeTable(
  {
    allLocations,
    project_id,
    system_id,
    job_id,
    reportProfile,
    showHidden
  }
) {
  let {reloadReport, getHideEntityId} = useContext(JobMeasurementReportContext)
  let [outsideRangeLocations, setOutsideRangeLocations] = useState()
  let [outsideRangeLocationElements, setOutsideRangeLocationElements] = useState()

  const {enqueueSnackbar} = useSnackbar();
  const handleBlacklistButtonClicked = (entity) => {
    SelfCheckJobMeasurementReportProfileBlacklistService.create(
      {
        'target_type': entity.type,
        'id': entity.id
      },
      project_id,
      system_id,
      job_id,
      reportProfile.id
    ).then(
      response => {
        enqueueSnackbar(
          window.gettext('Entity blacklisted'),
          {
            'variant': 'success'
          },
        )

        enqueueSnackbar(window.gettext('Reloading report'))
        reloadReport()
      },
      error => {
        enqueueSnackbar(
          window.gettext('Error while trying to blacklist entity'),
          {
            'variant': 'error',
          }
        )
      }
    )
  }

  const handleHideButtonClicked = (entity) => {
    SelfCheckJobMeasurementReportProfileHideEntityService.create(
      {
        'target_type': entity.type,
        'id': entity.id
      },
      project_id,
      system_id,
      job_id,
      reportProfile.id
    ).then(
      response => {
        enqueueSnackbar(
          window.gettext('Entity hidden'),
          {
            'variant': 'success'
          },
        )
        enqueueSnackbar(window.gettext('Reloading report'))
        reloadReport()
      },
      error => {
        enqueueSnackbar(
          window.gettext('Error while trying to hide entity'),
          {
            'variant': 'error',
          }
        )
      }
    )
  }

  useEffect(() => {
    if (!allLocations) {
      return
    }

    setOutsideRangeLocations(
      allLocations.filter(
        (location) => {
          if (!reportProfile.outside_threshold_show_entity_types.includes(location.entity.type)) {
            return false
          }

          return isMedianOutsideRange(
            location?.room_temperature?.median,
            reportProfile.median_accepted_threshold_low,
            reportProfile.median_accepted_threshold_high
          )
        }
      )
    )
  }, [allLocations])

  useEffect(() => {
    if (!outsideRangeLocations) {
      return
    }
    const elements = outsideRangeLocations.map(
      (location, i) => {
        const hide_entity_id = getHideEntityId(location.entity)

        if (hide_entity_id !== null && !showHidden) {
          return null
        }

        return (
          <OutsideAcceptedRangeTableRow
            key={i}
            location={location}
            project_id={project_id}
            system_id={system_id}
            job_id={job_id}
            profile_id={reportProfile.id}
            hide_entity_id={hide_entity_id}
            showHidden={showHidden}
          />
        )
      }
    ).filter(
      (element) => {
        return element !== null
      }
    )

    setOutsideRangeLocationElements(elements)


  }, [outsideRangeLocations, showHidden])

  return (
    <>
      {outsideRangeLocationElements && (
        <>
          {outsideRangeLocationElements.length > 0 ? (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Location')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Address')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Type')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Comment')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Median temp')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Inflow temp')}
                      </TableCell>
                      <TableCell>
                        {window.gettext('Return temp')}
                      </TableCell>
                      <TableCell
                        sx={
                          {
                            'displayPrint': 'none'
                          }
                        }
                      >
                        <UserPermission
                          permission={UserPermission.permissions.measurement_report_profile_blacklisted_entity_add}
                        >
                          {window.gettext('Remove')}
                          <Helper
                            title={window.gettext('Remove from calculation')}
                            ml
                          >
                            {window.gettext('This entity and all (if any) underlying entities will be excluded from the report')}
                          </Helper>
                        </UserPermission>
                      </TableCell>
                      <TableCell
                        sx={
                          {
                            'displayPrint': 'none'
                          }
                        }
                      >
                        <UserPermission
                          permission={UserPermission.permissions.measurement_report_profile_hidden_entity_add}
                        >
                          {window.gettext('Hide')}
                          <Helper
                            title={window.gettext('Hide from list of locations outside accepted interval')}
                            ml
                          >
                            {window.gettext('This specific location will be hidden in the list of locations outside accepted interval. It will still be used in the calculations for this report')}
                          </Helper>
                        </UserPermission>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {outsideRangeLocationElements}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>
              <Alert severity={'info'}>
                {window.gettext('There are no locations outside accepted range')}
              </Alert>
            </>
          )
          }
        </>
      )}
    </>
  )
}