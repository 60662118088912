import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";
import PropTypes from "prop-types";

/*
* props.value = the value, always in centimeters
* props.precision = how many decimal points we should display
* props.unit = m/mm/cm
* */
export default function Flow(props) {
  const defaultPrecision = 1
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(defaultPrecision)
  let suffix = window.gettext("l/h")


  // Set the rounded string value
  useEffect(() => {
    setDisplayValue(props.value)
  }, [props.value, precision])

  // setting precision
  useEffect(() => {
    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision}/>
  )
}

Flow.propTypes = {
  'value': PropTypes.number,
  'precision': PropTypes.number,
}