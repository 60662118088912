import {Alert, FormControl, Grid, IconButton, TextField} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import BasicModal from "../../../../layout/BasicModal";
import PropTypes from "prop-types";

function WrappedComponent(props) {
  let [open, setOpen] = useState(false)
  let [text, setText] = useState(null)
  let [originalComment, setOriginalComment] = useState(null)
  let [showForm, setShowForm] = useState(false)

  useEffect(() => {
    setOriginalComment(props.text)

    if (props.text === undefined) {
      return
    }

    setShowForm(!props.text)
    setText(props.text || '')
  }, [props.text])

  useEffect(() => {
    if(open === false){
      setText(originalComment)
    }
    // Reset text to original text when closing
  }, [open, originalComment])

  const handleCommentOnChange = (event) => {
    setText(event.target.value)
  }

  const handleSaveButtonClick = (event) => {
    console.log('SAVE', text)

    props.onSave(text, () => {setOpen(false)})
  }

  return (
    <>
      <>
        <BasicModal
          toggleButton={
            <IconButton
              title={text || null}
            >
              {(text) ? (
                /* There is a text */
                <TextSnippetIcon fontSize={props?.iconSize || "small"} color="primary"/>
              ) : (
                /* No text */
                <TextSnippetIcon fontSize={props?.iconSize || "small"} color="lowtone"/>
              )}
            </IconButton>
          }
          open={open}
          setOpen={setOpen}
          onClose={() => {
            setOpen(false)
          }}
        >
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {window.gettext('Text')}
                </Typography>
              </Grid>
              <Grid item>
                {(showForm) ? (
                  <>
                    <FormControl>
                      <TextField
                        id="comment"
                        multiline
                        label={window.gettext('Text')}
                        value={text || ""}
                        fullWidth
                        onChange={handleCommentOnChange}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    {(!text) ? (
                      <>
                        <Alert severity="info">
                          {window.gettext('No text')}
                        </Alert>
                      </>
                    ) : (
                      <>
                        <Typography variant="body" style={{whiteSpace: 'pre-wrap'}}>
                            {text}
                        </Typography>
                      </>
                    )}
                  </>
                )}

              </Grid>
              <Grid item>
                {(showForm) ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleSaveButtonClick}
                      >
                        {window.gettext('Save')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="error" onClick={() => {
                        setOpen(false)
                      }}>
                        {window.gettext('Cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Button
                      startIcon={<EditIcon/>}
                      variant="contained"
                      onClick={(e) => {
                        setShowForm(!showForm)
                      }}
                    >
                      <>
                        {!text ? (
                          <>
                            {window.gettext('Write text')}
                          </>
                        ) : (
                          <>
                            {window.gettext('Edit text')}
                          </>
                        )}
                      </>
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        </BasicModal>
      </>
    </>
  )
}

function comparisonFunction(prevProps, nextProps){
  // If the comment is the same, then we shall use the memoized version
  // If they differ somewhat, then we shall return False, forcing a re-rendered
  return prevProps.text === nextProps.text && prevProps.iconSize === nextProps.iconSize
}

export const TextTaskItemCellIndicator = React.memo(WrappedComponent, comparisonFunction)

export default TextTaskItemCellIndicator

TextTaskItemCellIndicator.propTypes = {
  text: PropTypes.string,
  onSave: PropTypes.func,
}
