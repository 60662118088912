import WithSuffix from "./WithSuffix";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function Temperature(props) {
  let {value} = props

  const defaultPrecision = 0
  let [precision, setPrecision] = useState(defaultPrecision)

  useEffect(() => {
    setPrecision(
      props.precision === undefined ? defaultPrecision : props.precision
    )
  }, [props.precision])

  if(typeof(value) === "string"){
    value = parseFloat(value)
  }

  return (
    <WithSuffix value={value} precision={precision} suffix="°C"/>
  )
}

Temperature.propTypes = {
  value: PropTypes.any,
  precision: PropTypes.number,
}