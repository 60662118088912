import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { ChipJobSpecTaskSpecCount } from "../chips";
import { IconAdd, IconJob } from "../icons";
import { ModalCreateTaskSpec } from "../modals";

export function ListItemJobSpec({ jobSpec, project, postCreate }) {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end">
          <ModalCreateTaskSpec
            project={project}
            jobSpec={jobSpec}
            postCreate={postCreate}
            taskSpecId={null}
          >
            <Tooltip title={window.gettext("Create new task specification")}>
              <IconAdd />
            </Tooltip>
          </ModalCreateTaskSpec>
        </IconButton>
      }
    >
      {/* Icon */}
      <ListItemIcon>
        <IconJob />
      </ListItemIcon>

      {/* Info */}
      <ListItemText>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          {/* Name of job spec */}
          <Typography component="span">{jobSpec.name}</Typography>

          {/* Number of task specs */}
          <ChipJobSpecTaskSpecCount jobSpec={jobSpec} />
        </Stack>
      </ListItemText>
    </ListItem>
  );
}
