import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function BottomDrawerToolbar({children, height, sticky, defaultExpanded}) {
  const toolbarHeight = height || 70
  let [toolbarExpanded, setToolbarExpanded] = useState((defaultExpanded === true || sticky === true))

  return (
    <>
      {!sticky && (
        <>
          <IconButton
            onClick={() => {
              setToolbarExpanded(!toolbarExpanded)
            }}
            sx={
              {
                zIndex: 200,
                position: 'fixed',
                bottom: (toolbarExpanded) ? toolbarHeight + 10 : 0,
                right: 0
              }
            }
          >
            {
              (toolbarExpanded) ? (
                <>
                  <KeyboardArrowDownIcon
                    size={"large"}
                  />
                </>
              ) : (
                <>
                  <MenuIcon
                    size={"large"}
                  />
                </>
              )
            }
          </IconButton>
        </>
      )}

      {!toolbarExpanded ? (
        <>
        </>
      ) : (
        <>
          <div
            style={
              {
                height: String(toolbarHeight) + 'px',
              }
            }
          >
            &nbsp;
          </div>
          <AppBar
            position="fixed"
            sx={
              {
                top: 'auto',
                bottom: 0,
              }
            }
            data-test-id="bottom-app-drawer-handle"
            style={
              {
                zIndex: "100",
                backgroundColor: '#dddddd',
                color: '#000000',
              }
            }
          >
            <Toolbar variant="regular">
              {children}
            </Toolbar>
          </AppBar>
        </>
      )}
    </>
  )
}