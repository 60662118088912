import { Alert, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";

import LoadingIndicator from "../../layout/LoadingIndicator";

export function SystemBuildPotableWaterTable({ locationData }) {
  const [rows, setRows] = useState(null);

  useEffect(() => {
    setRowsFixed(locationData);
  }, [locationData]);

  const columns = [
    {
      field: "id",
      headerName: window.gettext("ID"),
      width: 75,
    },
    {
      field: "address",
      headerName: window.gettext("Address"),
      width: 250,
    },
    {
      field: "surveying_apartment_number",
      headerName: window.gettext("Apartment number"),
      width: 150,
    },
    {
      field: "local_apartment_number",
      headerName: window.gettext("Local apartment number"),
      width: 200,
    },
  ];

  function setRowsFixed(data) {
    const rows = [];

    for (const street of data) {
      for (const houseNumber of street.children) {
        for (const entrance of houseNumber.children) {
          for (const apartment of entrance.children) {
            rows.push({
              id: apartment.data.id,
              surveying_apartment_number:
                apartment.data.surveying_apartment_number,
              local_apartment_number: apartment.data.local_apartment_number,
              //entrance_id: entrance.data.id,
              //entrance_name: entrance.data.name,
              //entrance_short_code: entrance.data.short_code,
              //house_number_id: houseNumber.data.id,
              //house_number: houseNumber.data.number,
              //street_id: street.data.id,
              //street_name: street.data.street_name,
              //street_code: street.data.street_code,
              address:
                street.data.street_name +
                  " " +
                  houseNumber.data.number +
                  entrance.data.name || "",
            });
          }
        }
      }
    }

    setRows(rows);
  }

  return (
    <Stack
      sx={{
        height: "calc(100vh - " + 160.5 + "px)",
        gap: 1,
      }}
    >
      {rows === null ? (
        <LoadingIndicator />
      ) : rows.length === 0 ? (
        <Alert severity="info">{window.gettext("No apartments found")}</Alert>
      ) : (
        <DataGridPro rows={rows} columns={columns} />
      )}
    </Stack>
  );
}
