import {LayoutCard, LayoutView} from "../../../layout";
import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Stack, Tab, Tabs} from "@mui/material";
import getUserHasPermission from "../../../components/user/getUserHasPermission";
import store from "../../../state";
import {TAB_BASE_INFO, TAB_EDIT, TAB_FILES, TAB_HISTORY, TAB_SPENT_TIME, TAB_WATCHERS} from "./tabs";
import {TabPanel} from "../../../components/project/ProjectDetailViewTabPanel";
import IssueBaseInfo from "../../../components/issue_tracker/IssueBaseInfo";
import IssueHistory from "./tabs/IssueHistory";
import IssueSpentTime from "./tabs/IssueSpentTime";
import IssueProvider from "../../../components/issue_tracker/IssueContext";
import IssueSpentTimeProvider from "../../../components/issue_tracker/IssueSpentTimeContext";
import UserPermission from "../../../components/user/UserPermission";
import IssueEdit from "../../../components/issue_tracker/IssueEdit";
import IssueCommentList from "../../../components/issue_tracker/IssueCommentList";
import IssueAddComment from "../../../components/issue_tracker/IssueAddComment";
import IssueCommentsProvider from "../../../components/issue_tracker/IssueCommentListContext";
import IssueSpentTimeAddEntryForm from "../spent_time/IssueSpentTimeAddEntryForm";
import IssueFilesProvider from "../../../components/issue_tracker/IssueFileContext";
import IssueFilesTab from "./tabs/IssueFilesTab";
import IssueFileList from "../../../components/issue_tracker/IssueFileList";
import IssueFileAdd from "../../../components/issue_tracker/IssueFileAdd";
import IssueWatchersProvider, {IssueWatchersContext} from "../../../components/issue_tracker/IssueWatchersContext";
import IssueWatcherList from "../../../components/issue_tracker/IssueWatchersList";
import IssueWatcherAdd from "../../../components/issue_tracker/IssueWatcherAdd";
import {addHideForPrintStyle} from "../../../components/layout/HideForPrint";
import IssueWatcherAddMeButton from "../../../components/issue_tracker/IssueWatcherAddMeButton";
import UserProvider from "../../../UserContext";

export default function IssueDetailPage() {
  const navigate = useNavigate();
  // Issue id from Router

  const [searchParams] = useSearchParams();

  // Tabs
  const [currentTab, setCurrentTab] = useState(
    parseInt(searchParams.get("tab") || TAB_BASE_INFO)
  )

  function handleTabsChange(event, newValue) {
    navigate("?tab=" + String(newValue));
    setCurrentTab(newValue);
  }

  function tabProps(index, permission) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tab-panel-${index}`,
      display:
        permission !== undefined && getUserHasPermission(store.user, permission)
          ? "initial"
          : "none",
    };
  }

  return (
    <IssueProvider>
      <LayoutView
        header={window.gettext('Issue details')}
        noPaddingSubmenu
        submenuItems={
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={handleTabsChange}
            >
              <Tab
                label={window.gettext("Base info")}
                {...tabProps(TAB_BASE_INFO)}
              />

              <Tab
                label={window.gettext("Files")}
                {...tabProps(TAB_FILES)}
              />
              <Tab
                label={window.gettext("History")}
                {...tabProps(TAB_HISTORY)}
              />
              <Tab
                label={window.gettext("Spent time")}
                {...tabProps(TAB_SPENT_TIME)}
              />
              <Tab
                label={window.gettext("Watchers")}
                {...tabProps(
                  TAB_WATCHERS
                )}
              />
              <Tab
                label={window.gettext("Edit")}
                {...tabProps(
                  TAB_EDIT,
                  UserPermission.permissions.issue_change
                )}
              />
            </Tabs>
          </Stack>
        }
      >
        <>
          {/* Base info */}
          <TabPanel
            value={currentTab}
            index={TAB_BASE_INFO}
            name={"base-info"}
          >
            <IssueBaseInfo/>
            <UserPermission
              permission={UserPermission.permissions.issue_spent_time_add}
            >
              <LayoutCard
                doNotPrint
                title={window.gettext("Add spent time")}
              >
                <IssueSpentTimeAddEntryForm/>
              </LayoutCard>
            </UserPermission>
            <IssueFilesProvider>
              <LayoutCard
                title={window.gettext("Files")}
              >
                <IssueFileList/>
              </LayoutCard>
              <LayoutCard
                title={window.gettext("Add file")}
              >
                <IssueFileAdd/>
              </LayoutCard>
            </IssueFilesProvider>
            <UserPermission
              permission={UserPermission.permissions.issue_comment_view}
            >
              <IssueCommentsProvider>
                <LayoutCard
                  title={window.gettext("Comments")}
                >
                  <IssueCommentList/>
                </LayoutCard>
                <UserPermission
                  permission={UserPermission.permissions.issue_comment_add}
                >
                  <LayoutCard
                    title={window.gettext("Add comment")}
                    doNotPrint
                  >
                    <IssueAddComment/>
                  </LayoutCard>
                </UserPermission>
              </IssueCommentsProvider>
            </UserPermission>

            <LayoutCard
              title={window.gettext("Watchers")}
            >
              <IssueWatchersProvider>
                <IssueWatcherList/>
                <UserProvider>
                  <IssueWatcherAddMeButton/>
                </UserProvider>
              </IssueWatchersProvider>
            </LayoutCard>
          </TabPanel>

          {/* Files */}
          <TabPanel
            value={currentTab}
            index={TAB_FILES}
            name={"files"}>
            <IssueFilesProvider>
              <IssueFilesTab/>
            </IssueFilesProvider>
          </TabPanel>
          {/* History */}
          <TabPanel
            value={currentTab}
            index={TAB_HISTORY}
            name={"history"}>
            <IssueHistory/>
          </TabPanel>

          {/* Spent time */}
          <TabPanel
            value={currentTab}
            index={TAB_SPENT_TIME}
            name={"spent-time"}
          >
            <IssueSpentTimeProvider>
              <IssueSpentTime/>
            </IssueSpentTimeProvider>
          </TabPanel>

          <TabPanel
            value={currentTab}
            index={TAB_WATCHERS}
            name={"watchers"}
          >
            <IssueWatchersProvider>
              <LayoutCard
                title={window.gettext("Watchers")}
                doNotPrint
              >
                <IssueWatcherList/>
              </LayoutCard>
              <LayoutCard
                title={window.gettext("Add or remove yourself as watcher")}
                doNotPrint
              >
                <UserProvider>
                  <IssueWatcherAddMeButton/>
                </UserProvider>
              </LayoutCard>
              <LayoutCard
                title={window.gettext("Add watcher")}
                doNotPrint
              >
                <IssueWatcherAdd/>
              </LayoutCard>
            </IssueWatchersProvider>
          </TabPanel>

          {/* Edit */}
          <TabPanel
            value={currentTab}
            index={TAB_EDIT}
            name={"edit"}
          >
            <IssueEdit/>
          </TabPanel>
        </>
      </LayoutView>
    </IssueProvider>
  )
}
