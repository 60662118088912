import PropTypes from "prop-types";
import EntityText from "./EntityText";
import {Link as RouterLink} from "react-router-dom";
import Link from "@mui/material/Link";
import {useEffect, useState} from "react";
import getEntityStatus from "../utils/getEntityStatus";

export default function EntityTaskFlagsCount({entityData}) {
  const entityStatus = getEntityStatus(entityData, entityData.job)

  return (
    <>
      {entityStatus.flagged_tasks.length}
    </>
  )
}