import { Chip, Tooltip } from "@mui/material";

import {
  TASK_ITEM_SPEC_TYPE_BOOLEAN,
  TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE,
} from "../../constants";

export function ChipTaskItemSpecType({ taskItemSpec }) {
  return (
    <>
      {taskItemSpec !== null && (
        <>
          {taskItemSpec.type === TASK_ITEM_SPEC_TYPE_TEXT_FILLED_MARKS_DONE && (
            <Tooltip
              title={window.gettext(
                "Text task item, will mark done when something is in the text field"
              )}
            >
              <Chip label={window.gettext("Text")} />
            </Tooltip>
          )}

          {taskItemSpec.type === TASK_ITEM_SPEC_TYPE_BOOLEAN && (
            <Tooltip title={window.gettext("Marked as done with checkbox")}>
              <Chip label={window.gettext("Boolean")} />
            </Tooltip>
          )}
        </>
      )}
    </>
  );
}
