import Button from "@mui/material/Button";
import SystemService from "../../services/SystemService";

export default function NoItemOnNodeNotificationActionFactory(project, system, node, postCreateCallback) {
  console.log(project, system)
  console.log('NODE', node)
  const handleButtonOnClick = (e) => {
    SystemService.create_item(
      project.id,
      system.id,
      node.id
    ).then(
      (response) => {
        console.log('done, created a new item.', response)

        postCreateCallback(response)
      }
    )
  }
  return (snackbarId) => (
    <>
      <Button onClick={handleButtonOnClick}>
        {window.gettext('Create item for node')}
      </Button>
    </>
  )
}