import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

export const UNIT_WATT = "w"

export const UNIT_KILOWATT = "kw"

export const UNIT_AUTO = "auto"

/*
* props.value = the value, always in centimeters
* props.precision = how many decimal points we should display
* props.unit = w/kw
* */
export default function Energy(props) {
  const defaultUnit = UNIT_WATT
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(1)
  let [unit, setUnit] = useState(props.unit || defaultUnit)
  let [suffix, setSuffix] = useState("")

  useEffect(() => {
    if (props.unit === UNIT_AUTO) {
      setUnit(props.value >= 1000 ? UNIT_KILOWATT : UNIT_WATT)
    }else{
      setUnit(props.unit)
    }
  }, [props.value, props.unit])


  // Set the rounded string value
  useEffect(() => {
    // Convert from input value to millimeters
    let value = props.value
    if (unit === UNIT_KILOWATT) {
      value = value / 1000
    }

    // Display string, fixed points
    setDisplayValue(
      value
    )
  }, [props.value, precision, unit])

  // setting precision
  useEffect(() => {
    let defaultPrecision = 0

    if (props.precision === undefined) {
      if (unit === UNIT_KILOWATT) {
        defaultPrecision = 1
      }
      if (unit === UNIT_WATT) {
        defaultPrecision = 0
      }
    }

    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [unit, props.precision])

  useEffect(() => {
    if (unit === UNIT_KILOWATT) {
      setSuffix('kW')
    }
    if (unit === UNIT_WATT) {
      setSuffix('W')
    }
  }, [unit])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} />
  )
}