import React from "react";
import FormFieldFactory from "../../../../../form/FormFieldFactory";
import Form from "../../../../../form/Form";

export default function MeasurementOpportunityForm(props) {

  const submit_button_text = props.submitButtonText || window.gettext("Save")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = [
    form_field_factory.integerField("outside_temperature", window.gettext("Outside temperature"), false, null),
    form_field_factory.integerField("outflow_temperature", window.gettext("Outflow temperature"), false, null),
    form_field_factory.integerField("return_temperature", window.gettext("Return temperature"), false, null),
    form_field_factory.integerField("pump_displayed_flow", window.gettext("Flow according to pump, litres per hour"), false, null),
  ]

  return (
    <Form
      formTestId="measurement_opportunity_form"
      formFields={form_fields}
      submitButtonText={submit_button_text}
      onSubmit={props.onSubmit}
    />
  )
}
