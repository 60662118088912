import React from "react";
import IssueCommentService from "../services/IssueCommentService";
import {useIssue} from "./IssueContext";
import InstanceManipulator from "../InstanceManipulator";
import IssueCommentForm from "./IssueCommentForm";
import {useIssueComments} from "./IssueCommentListContext";
import {useSnackbar} from "notistack";

export default function IssueAddComment({}) {
  const {issue} = useIssue()
  const {refreshIssueComments} = useIssueComments()
  const {enqueueSnackbar} = useSnackbar()

  if (!issue) {
    return null
  }

  return (
    <>
      <InstanceManipulator
        service={IssueCommentService}
        urlParams={[issue.id]}
        mode={'create'}
        form={<IssueCommentForm/>}
        postCreate={() => {
          // Toast we have created new comment
          enqueueSnackbar(window.gettext("Comment added"), {variant: 'success'})
          refreshIssueComments()
        }}
      />
    </>
  )
}