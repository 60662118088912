import { useForm } from "react-hook-form";
import { Button, Stack, Typography } from "@mui/material";

import { DEFAULT_FORM_PROPS } from "../constants";
import { renderComponentsStack } from "../helper";
import {useEffect} from "react";

export function Form({
  header,
  formSections,
  formFields,
  onSubmit,
  submitButtonText = window.gettext(DEFAULT_FORM_PROPS.SUBMIT_BUTTON_TEXT),
  submitButtonProps = DEFAULT_FORM_PROPS.SUBMIT_BUTTON_PROPS,
  submitDisabled = DEFAULT_FORM_PROPS.SUBMIT_DISABLED,
  formTestId = DEFAULT_FORM_PROPS.FORM_TEST_ID,
  submitButtonTestId = DEFAULT_FORM_PROPS.SUBMIT_BUTTON_TEST_ID,
  headerTypographyProps = DEFAULT_FORM_PROPS.HEADER_TYPOGRAPHY_PROPS,
  sectionsContainerProps = DEFAULT_FORM_PROPS.SECTIONS_CONTAINER_PROPS,
  fieldsContainerProps = DEFAULT_FORM_PROPS.FIELDS_CONTAINER_PROPS,
  childProps = DEFAULT_FORM_PROPS.CHILD_PROPS,
  uniqueChildProps = DEFAULT_FORM_PROPS.UNIQUE_CHILD_PROPS,
  onChange= undefined,
}) {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Stack
      component="form"
      noValidate
      onSubmit={handleSubmit(onSubmit)}
      data-test-id={formTestId}
    >
      {/* Header */}
      {header && <Typography {...headerTypographyProps}>{header}</Typography>}

      {/* Form sections or fields */}
      {formSections !== undefined ? (
        // Form sections
        <Stack {...sectionsContainerProps}>
          {renderComponentsStack(formSections, control, errors, childProps, uniqueChildProps)}
        </Stack>
      ) : (
        // Form fields
        <Stack {...fieldsContainerProps}>
          {renderComponentsStack(formFields, control, errors, childProps, uniqueChildProps)}
        </Stack>
      )}

      {/* Submit button */}
      <Button
        {...submitButtonProps}
        type="submit"
        disabled={submitDisabled}
        data-test-id={submitButtonTestId}
      >
        {submitButtonText}
      </Button>
    </Stack>
  );
}
