import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

export default function Currency(props) {
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(1)

  const suffix = "kr"

  useEffect(() => {
    // convert to major currency
    let value = props.value / 100

    

    // Display string, fixed points
    setDisplayValue(
      value
    )
  }, [props.value])

  // setting precision
  useEffect(() => {
    let defaultPrecision = 2

    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} thousandSeparator />
  )
}