export default class SystemValidationError {
  constructor(
    errorType,
    errorMessage,
  ) {
    this.errorType = errorType
    this.errorMessage = errorMessage
  }

  repr = () => {
    return {
      'type' : this.errorType,
      'message': this.errorMessage,
    }
  }
}