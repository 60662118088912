import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {useState} from "react";
import {TextField} from "@mui/material";
import ApartmentService from "../services/ApartmentService";
import UserPermission from "../user/UserPermission";
import {useSnackbar} from "notistack";
import IconButton from "@mui/material/IconButton";

export default function EditLocalApartmentNumber({onChange, localApartmentNumber, projectId, systemId, apartmentId}) {
  const [editMode, setEditMode] = useState(false)
  const [persistedValue, setPersistedValue] = useState(localApartmentNumber)
  const [value, setValue] = useState(localApartmentNumber)

  const {enqueueSnackbar} = useSnackbar()

  const handleEditButtonClick = () => {
    setValue(persistedValue)
    setEditMode(true)
  }
  const handleCloseButtonClick = () => {
    setEditMode(false)
  }

  const handleOnChange = (e) => {
    setValue(e.target.value)
    console.log(e.target.value)
  }

  const handleSave = () => {
    // Save
    ApartmentService.update(
      {
        'local_apartment_number': value,
      },
      projectId,
      systemId,
      apartmentId,
    ).then(
      (response) => {
        setPersistedValue(
          response.local_apartment_number
        )

        setEditMode(false)
      },
      (error) => {
        enqueueSnackbar(
          window.gettext('Failed to update apartment local number'),
          {
            variant: 'error',
          }
        )
      }
    )
  }

  const handleOnKeyDown = (e) => {
    // In case the user presses ESC
    if (e.code === "Escape") {
      setEditMode(false)
      return
    }
    // In case the user presses Enter
    if (e.code === "Enter") {
      handleSave()
      e.preventDefault()
    }
  }

  return (
    <>
      {(editMode) ? (
        <>
          <TextField
            autoFocus
            size={'small'}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            value={value}
          />

          {/* Save */}
          <IconButton
            color={'success'}
            onClick={handleSave}
          >
            <SaveIcon/>
          </IconButton>

          {/* Close/Cancel */}
          <IconButton
            color={'error'}
            onClick={handleCloseButtonClick}
          >
            <CloseIcon/>
          </IconButton>
        </>
      ) : (
        <>
          {
            persistedValue
          }

          <UserPermission
            permission={UserPermission.permissions.apartment_change}
          >
            <IconButton
              sx={
                {
                  marginLeft: '0.5em'
                }
              }
              onClick={handleEditButtonClick}
            >
              <EditIcon/>
            </IconButton>
          </UserPermission>
        </>
      )}

    </>
  )
}

EditLocalApartmentNumber.propTypes = {
  'localApartmentNumber': PropTypes.string,
  'projectId': PropTypes.number.isRequired,
  'systemId': PropTypes.number.isRequired,
  'apartmentId': PropTypes.number.isRequired,
}