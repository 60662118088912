import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function LocalApartmentNumberColumnDefinition(systemRooms) {
  return {
    field: 'local_apartment_number',
    width: getColumnWidth('local_apartment_number'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Local Apt Nr'),
    description: window.gettext('Local apartment number'),
    editable: false,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row, 'node.item.location.local_apartment_number'
      )
    })
  }
}