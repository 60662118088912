import { Divider, Stack } from "@mui/material";

import getEntityStatus from "../../../utils/getEntityStatus";

import { LayoutCard } from "../../../../../../../layout";
import { ENTITY_TYPE_ENTRANCE, ENTITY_TYPE_SYSTEM } from "../../../entity";
import { useHierarchyContext } from "../../context";
import { getComponentsHeaders } from "../../helper";
import { HierarchyEntity } from "./HierarchyEntity";
import {
  HierarchyNavigationEntrance,
  HierarchyNavigationSystem,
} from "./navigation";

export function HierarchyNavigation() {
  const {
    entityListLinkEntities,
    entityType,
    focusEntityData,
    hasLinks,
    jobData,
    hierarchyViewOptions,
  } = useHierarchyContext();

  if (entityType === ENTITY_TYPE_SYSTEM) {
    return <HierarchyNavigationSystem />;
  } else if (entityType === ENTITY_TYPE_ENTRANCE) {
    return <HierarchyNavigationEntrance />;
  }

  return (
    <>
      {(focusEntityData || entityType === ENTITY_TYPE_SYSTEM) &&
        hierarchyViewOptions?.show_navigation_option.value &&
        entityListLinkEntities && (
          <LayoutCard
            title={getComponentsHeaders(entityType).navigation}
            noPadding
          >
            {!hasLinks ? (
              <></>
            ) : (
              <Stack>
                {entityListLinkEntities.map((entityData) => {
                  const entityStatus = getEntityStatus(entityData, jobData);

                  return (
                    <Stack
                      key={entityData.id}
                      sx={{
                        mt: 1,
                        gap: 1,
                      }}
                    >
                      <Stack
                        sx={{
                          minHeight: 50,
                          justifyContent: "center",
                        }}
                      >
                        <HierarchyEntity
                          entityData={entityData}
                          entityStatus={entityStatus}
                          hierarchyViewOptions={hierarchyViewOptions}
                        />
                      </Stack>

                      <Divider
                        sx={{
                          my: 1,
                        }}
                      />
                    </Stack>
                  );
                })}
              </Stack>
            )}
          </LayoutCard>
        )}
    </>
  );
}
