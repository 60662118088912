import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import store from "../../../../../../../state";
import ConfirmableButton from "../../../../../../layout/ConfirmableButton";
import SelfCheckJobMeasurementOpportunityService from "../../../../../../services/measurement/SelfCheckJobMeasurementOpportunityService";
import UserPermission from "../../../../../../user/UserPermission";
import getUserHasPermission from "../../../../../../user/getUserHasPermission";
import Helper from "../../../../../../utils/Helper";
import DateTime from "../../../../../../values/DateTime";
import Flow from "../../../../../../values/Flow";
import Temperature from "../../../../../../values/Temperature";
import EditMeasurementOpportunityModal from "../EditMeasurementOpportunityModal";

export default function OpportunitiesTable({
  projectId,
  systemId,
  jobId,
  measurementId,
  opportunities,
  latestOpportunity,
  editable,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [editOpportunity, setEditOpportunity] = useState(undefined);
  const [opportunitiesList, setOpportunitiesList] = useState([]);

  useEffect(() => {
    setOpportunitiesList(opportunities);
  }, [opportunities]);

  function handleDeleteButtonClicked(opportunityToDelete) {
    console.log("delete", opportunityToDelete);
    SelfCheckJobMeasurementOpportunityService.destroy(
      projectId,
      systemId,
      jobId,
      opportunityToDelete.measurement.id,
      opportunityToDelete.id
    ).then((response) => {
      enqueueSnackbar(window.gettext("Measurement opportunity deleted"), {
        variant: "success",
      });

      setOpportunitiesList(
        opportunitiesList.filter((opportunity) => {
          return opportunity.id !== opportunityToDelete.id;
        })
      );
    });
  }

  function onClickRowEditButton(opportunity) {
    console.log("edit", opportunity);
    setEditOpportunity(opportunity);
  }

  function handleOpportunityChanged(changedOpportunity) {
    enqueueSnackbar(window.gettext("Measurement opportunity saved"), {
      variant: "success",
    });

    const newOpportunities = opportunitiesList.map((opportunity) => {
      if (opportunity.id === changedOpportunity.id) {
        return changedOpportunity;
      }
      return opportunity;
    });

    setOpportunitiesList(newOpportunities);
    setEditOpportunity(undefined);
  }

  return (
    <>
      {opportunities.length === 0 ? (
        <Alert severity={"info"}>
          {window.gettext("No opportunities on this measurement")}
        </Alert>
      ) : (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {latestOpportunity !== null && <TableCell></TableCell>}

                <TableCell>{window.gettext("Created")}</TableCell>

                <TableCell>{window.gettext("Outside temperature")}</TableCell>

                <TableCell>{window.gettext("Outflow temperature")}</TableCell>

                <TableCell>{window.gettext("Return temperature")}</TableCell>

                <TableCell>{window.gettext("Pump flow")}</TableCell>

                <TableCell>
                  {window.gettext("Start")}

                  <Helper
                    title={window.gettext("Time of first measurement instance")}
                    ml
                  >
                    {window.gettext(
                      "Time when the first measurement instance was added to this measurement opportunity"
                    )}
                  </Helper>
                </TableCell>

                <TableCell>
                  {window.gettext("End")}

                  <Helper
                    title={window.gettext("Time of last measurement instance")}
                    ml
                  >
                    {window.gettext(
                      "Time when the last measurement instance was added to this measurement opportunity"
                    )}
                  </Helper>
                </TableCell>

                {editable &&
                  (getUserHasPermission(
                    store.user,
                    UserPermission.permissions.measurement_opportunity_change
                  ) ||
                    getUserHasPermission(
                      store.user,
                      UserPermission.permissions.measurement_opportunity_delete
                    )) && <TableCell>{window.gettext("Tools")}</TableCell>}
              </TableRow>
            </TableHead>

            <TableBody>
              {opportunitiesList.map((opportunity) => (
                <TableRow key={"opportunity_" + String(opportunity.id)}>
                  {latestOpportunity !== null && (
                    <TableCell>
                      {latestOpportunity.id === opportunity.id && (
                        <Tooltip title={window.gettext("Latest opportunity")}>
                          <VerifiedIcon />
                        </Tooltip>
                      )}
                    </TableCell>
                  )}

                  <TableCell>
                    <DateTime value={opportunity.created} />
                  </TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.outside_temperature)}
                      precision={1}
                    />
                  </TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.outflow_temperature)}
                      precision={1}
                    />
                  </TableCell>

                  <TableCell>
                    <Temperature
                      value={parseFloat(opportunity.return_temperature)}
                      precision={1}
                    />
                  </TableCell>

                  <TableCell>
                    <Flow
                      value={opportunity.pump_displayed_flow}
                      precision={0}
                    />
                  </TableCell>

                  <TableCell>
                    {opportunity.start ? (
                      <DateTime value={opportunity.start} />
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>

                  <TableCell>
                    {opportunity.end ? (
                      <DateTime value={opportunity.end} />
                    ) : (
                      <span>-</span>
                    )}
                  </TableCell>

                  {(getUserHasPermission(
                    store.user,
                    UserPermission.permissions.measurement_opportunity_change
                  ) ||
                    getUserHasPermission(
                      store.user,
                      UserPermission.permissions.measurement_opportunity_delete
                    )) && (
                    <TableCell>
                      {getUserHasPermission(
                        store.user,
                        UserPermission.permissions
                          .measurement_opportunity_change
                      ) && (
                        <IconButton
                          onClick={() => {
                            onClickRowEditButton(opportunity);
                          }}
                        >
                          <EditIcon color={"success"} />
                        </IconButton>
                      )}

                      {getUserHasPermission(
                        store.user,
                        UserPermission.permissions
                          .measurement_opportunity_delete
                      ) && (
                        <ConfirmableButton
                          callbackAttributes={opportunity}
                          closeOnConfirm
                          body={window.gettext(
                            "Do you want to delete this measurement opportunity, this can not be undone."
                          )}
                        >
                          <IconButton
                            color="error"
                            aria-label="delete measurement opportunity"
                            component="span"
                            onClick={handleDeleteButtonClicked}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ConfirmableButton>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {editable && (
        <EditMeasurementOpportunityModal
          projectId={projectId}
          systemId={systemId}
          jobId={jobId}
          measurementId={measurementId}
          opportunity={editOpportunity}
          onClose={() => {
            setEditOpportunity(undefined);
          }}
          onChanged={handleOpportunityChanged}
        />
      )}
    </>
  );
}

OpportunitiesTable.propTypes = {
  opportunities: PropTypes.array,
  latestOpportunity: PropTypes.object,
};
