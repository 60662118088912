import React from "react";

import {LayoutCard} from "../../layout";
import {useIssue} from "./IssueContext";
import IssueForm from "./IssueForm";
import LoadingIndicator from "../layout/LoadingIndicator";
import InstanceManipulator from "../InstanceManipulator";
import IssueService from "../services/IssueService";
import {useSnackbar} from "notistack";
import ConfirmableButton from "../layout/ConfirmableButton";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

export default function IssueEdit({}) {
  const {issue, refreshIssue} = useIssue()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()

  const postSubmit = (issue) => {
    // After a issue is created
    // Toast save success
    enqueueSnackbar(
      window.gettext("Issue saved"),
      {
        variant: "success",
      }
    )

    refreshIssue()
  }

  return (
    <>
      <LayoutCard>
        {!issue ? (
          <LoadingIndicator/>
        ) : (
          <InstanceManipulator
            preSendDataTransformer={
              (data) => {
                // In case we change project, we need to null system. otherwise we will get an error on the backend
                if (!!data.project && parseInt(data.project) !== parseInt(issue.project)) {
                  data.system = null
                }
                return data
              }
            }
            convertNullValuesToEmptyStringAfterFetch={
              // Convert null values to empty string after fetch, so that they will compare nicely with the output of the form since
              // on the form, if a users selects an empty value, it will be an empty string.
              [
                'category',
                'project',
                'apartment',
                'system',
              ]
            }
            service={IssueService}
            urlParams={[issue.id]}
            postUpdate={postSubmit}
            mode="edit"
            form={
              <IssueForm/>
            }
          />
        )}
      </LayoutCard>
      <LayoutCard
        title={window.gettext("Delete")}
      >
        <ConfirmableButton
          variant="contained"
          closeOnConfirm
          color="secondary"
          body={window.gettext("Are you sure you want to delete this issue?")}
        >
          <Button
            color="error"
            variant={"contained"}
            aria-label="delete measurement opportunity"
            component="span"
            onClick={() => {
              IssueService.destroy(issue.id).then(() => {
                enqueueSnackbar(
                  window.gettext("Issue deleted"),
                  {
                    variant: "success",
                  }
                )
                navigate("/issues/")
              })
            }}
            startIcon={<DeleteIcon/>}
          >
            {window.gettext("Delete")}
          </Button>
        </ConfirmableButton>
      </LayoutCard>
    </>
  )

}
