import { useEffect, useRef } from "react";

import { useHierarchyRSContext } from "../../../context";
import { getComponentsHeaders } from "../../../helper";
import { LinkedEntitySectionCard } from "../HierarchyTaskInterfaceLinkedEntities";
import { HierarchyRSMeasurements } from "./HierarchyRSMeasurements";
import { HierarchyRSNippleKV } from "./HierarchyRSNippleKV";

export function HierarchyRSTaskInterfaceLinkedEntities({ entity }) {
  const previousEntity = useRef(entity);

  const { latestOpportunity, setEntity } = useHierarchyRSContext();

  useEffect(() => {
    if (entity?.id !== previousEntity.current?.id) {
      setEntity(null);

      previousEntity.current = entity;
    }

    setEntity(entity);
  }, [entity]);

  return (
    <>
      {latestOpportunity && (
        <LinkedEntitySectionCard
          title={getComponentsHeaders(entity.type).temperature_measurements}
        >
          <HierarchyRSMeasurements entity={entity} />
        </LinkedEntitySectionCard>
      )}

      <LinkedEntitySectionCard
        title={getComponentsHeaders(entity.type).nipple_and_kv}
      >
        <HierarchyRSNippleKV entity={entity} />
      </LinkedEntitySectionCard>
    </>
  );
}
