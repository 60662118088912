import Transformer from "../Transformer";

export default class AdditionalResistanceTransformer extends Transformer{
  key = 'additional_resistance'
  valueTransformer = (value) => {
    if (value === "") {
     return 0
    }

    return parseInt(value, 10)
  }
}