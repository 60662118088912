import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { useIssue } from "../../../../components/issue_tracker/IssueContext";
import IssueHistoryInfo from "../../../../components/issue_tracker/IssueHistoryInfo";
import LoadingIndicator from "../../../../components/layout/LoadingIndicator";
import ChoicesService from "../../../../components/services/ChoicesService";
import IssueCategoryService from "../../../../components/services/IssueCategoryService";
import IssueService from "../../../../components/services/IssueService";
import UserService from "../../../../components/services/UserService";
import DateTime from "../../../../components/values/DateTime";
import UserChoice from "../../../../components/values/UserChoice";

import { LayoutCard } from "../../../../layout";

export default function IssueHistory({}) {
  // History entries
  const [historyEntries, setHistoryEntries] = useState(undefined);
  const [users, setUsers] = useState(undefined);
  const [issueChoices, setIssueChoices] = useState(undefined);
  const [issueCategories, setIssueCategories] = useState(undefined);

  const { issue } = useIssue();

  // Issue id from Router

  useEffect(() => {
    if (!issue || !issueCategories) {
      return;
    }

    // Fetch the history data
    IssueService.listHistory(issue.id).then((data) => {
      // This response is paginated
      // TODO: Implement pagination in a smart way
      setHistoryEntries(data.results);
    });
    UserService.list().then(setUsers);
    ChoicesService.issueChoices().then(setIssueChoices);
  }, [issue, issueCategories]);

  useEffect(() => {
    IssueCategoryService.list().then(setIssueCategories);
  }, []);

  return (
    <>
      {historyEntries === undefined || !issueCategories ? (
        <>
          <LoadingIndicator />
        </>
      ) : (
        <>
          {historyEntries.length === 0 ? (
            <>
              <Alert severity="info">
                {window.gettext("No history for this issue.")}
              </Alert>
            </>
          ) : (
            <LayoutCard noPadding>
              {historyEntries.map((entry, index) => {
                entry["field_dict"]["category_name"] =
                  issueCategories.find((category) => {
                    return (
                      category.id ===
                      parseInt(entry["field_dict"]["category_id"])
                    );
                  })?.name || null;

                return (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 1,
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        {/* Left/Top */}
                        <Stack
                          sx={{
                            flexDirection: "row",
                            flexGrow: 1,
                            flexWrap: "wrap",
                            gap: 1,
                            justifyContent: "space-between",
                            flexBasis: "20rem",
                          }}
                        >
                          {/* Created Date */}
                          <Typography
                            sx={{
                              flexBasis: "8rem",
                              flexShrink: 0,
                              fontSize: "0.9rem",
                            }}
                          >
                            <DateTime value={entry.revision.date_created} />
                          </Typography>

                          {/* Created by */}
                          <Typography
                            sx={{
                              flexBasis: "8rem",
                              flexGrow: 1,
                              fontSize: "0.9rem",
                              maxWidth: "12rem",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <UserChoice
                              users={users}
                              id={entry.revision.user}
                            />
                          </Typography>
                        </Stack>

                        {/* Right/Bottom */}
                        <Stack
                          sx={{
                            flexBasis: "15rem",
                            flexGrow: 10,
                          }}
                        >
                          {/* Comment */}
                          <Typography
                            sx={{
                              fontSize: "0.8rem",
                            }}
                          >
                            {entry.revision.comment}
                          </Typography>
                        </Stack>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        p: 0,
                      }}
                    >
                      <IssueHistoryInfo
                        field_dict={entry.field_dict}
                        issueChoices={issueChoices}
                      />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </LayoutCard>
          )}
        </>
      )}
    </>
  );
}
