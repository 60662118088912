import React from "react";
import FormFieldFactory from "../form/FormFieldFactory";
import Form from "../form/Form";


export default function SelfCheckJobSpecForm(props) {

  const submit_button_text = props.submitButtonText || window.gettext("Create job specification")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = [
    form_field_factory.textArea('name', window.gettext("Name")),
  ]

  return (
    <Form
      formTestId="self-check-job-spec-form"
      formFields={form_fields}
      submitButtonText={submit_button_text}
      onSubmit={props.onSubmit}
    />
  )
}
