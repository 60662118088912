import BaseService from "./BaseService";

class IssueFilesService extends BaseService {
}

export default new IssueFilesService('/api/',
  [
    'issues',
    'files',
  ]
)