import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SelfCheckJobService from "../../../services/SelfCheckJobService";

export const JobContext = createContext({
  job: undefined,
})
export default function JobProvider(props) {
  let {project_id, system_id, job_spec_id, job_id} = useParams()
  let [job, setJob] = useState(null)

  useEffect(() => {
    if (!project_id || !system_id || !job_spec_id || !job_id) {
      return
    }

    SelfCheckJobService.fetch(
      project_id,
      system_id,
      job_spec_id,
      job_id,
    ).then(
      setJob
    )
  }, [project_id, system_id, job_id])

  return (
    <JobContext.Provider
      value={
        {
          'job': job,
        }
      }
    >
      {props.children}
    </JobContext.Provider>
  )
}
