/**
 * Returns a callback function that handles the change of a field.
 *
 * @param {Function} setValue - The function to set the value.
 * @param {Function} onChange - The function to call when the value changes.
 * @returns {Function} - The callback function.
 */
export function getHandleChangeCallback(setValue, onChange) {
  return (event, newValue) => {
    // Change the value we display
    if (newValue === undefined) {
      return;
    }

    setValue(newValue);
    onChange(newValue);
  };
}

/**
 * Returns a callback function that handles the input change of a field.
 *
 * @param {Function} setInputValue - The function to set the input value.
 * @returns {Function} - The callback function.
 */
export function getHandleInputChangeCallback(setInputValue) {
  return (event, newInputValue) => {
    setInputValue(newInputValue);
  };
}
