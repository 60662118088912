import React, {useContext} from "react";

const UserContext = React.createContext(undefined);
const UserSetUserContext = React.createContext(undefined);

export function useUser() {
  return useContext(UserContext);
}

export function useSetUser() {
  return useContext(UserSetUserContext);
}

class UserProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    }
  }

  changeUser = (user) => {
    console.log("setting user", user);

    this.setState(
      {
        user: user
      }
    )
  }

  render() {
    return (
      <UserContext.Provider value={{
        state: this.state,
        changeUser: this.changeUser
      }}>
        <UserSetUserContext.Provider value={this.changeUser}>
          {this.props.children}
        </UserSetUserContext.Provider>
      </UserContext.Provider>
    )
  }
}

export default UserProvider
