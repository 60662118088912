/**
 * Sorts an array of entrance entities based on their addresses.
 * The sorting criteria:
 * 1. Sorts by the alphabetic portion that comes before any digits in the 'address_label'.
 * 2. If the alphabetic portions are the same, sorts by the numerical portion of the 'address_label'.
 * 3. If the numerical portions are also the same, sorts by the last word in the 'address_label'.
 *
 * @param {Array} entrancesEntities - The array of entrance entities to be sorted.
 * @returns {Array} - The sorted array of entrance entities.
 */
export function sortEntrances(entrancesEntities) {
  return entrancesEntities.sort((a, b) => {
    // Extract the digits and the remaining alphabetic parts
    const aDigits = a.location.address_label.match(/\d+/g) || [];
    const bDigits = b.location.address_label.match(/\d+/g) || [];
    const aN = parseInt(aDigits.join(""), 10);
    const bN = parseInt(bDigits.join(""), 10);

    // Extract the first alphabetic part that comes before any digits
    const aFirst = a.location.address_label
      .substring(
        0,
        a.location.address_label.indexOf(aDigits[0]) ||
          a.location.address_label.length
      )
      .trim();
    const bFirst = b.location.address_label
      .substring(
        0,
        b.location.address_label.indexOf(bDigits[0]) ||
          b.location.address_label.length
      )
      .trim();

    if (aFirst === bFirst) {
      // If the alphabetic parts are equal, compare the numbers
      if (aN === bN) {
        // If numbers are equal, compare the last part of the address
        const aLast = a.location.address_label.split(" ").pop();
        const bLast = b.location.address_label.split(" ").pop();

        if (aLast === bLast) {
          // If the last parts are also equal, return 0 (no change in order)
          return 0;
        } else {
          // If the last parts are different, sort by the last part
          return aLast.localeCompare(bLast);
        }
      } else {
        // If numeric parts are different, sort by the numeric parts
        return aN - bN;
      }
    } else {
      // If alphabetic parts are different, sort by the alphabetic parts
      return aFirst.localeCompare(bFirst);
    }
  });
}
