import {Chip, FormControlLabel, TextField} from "@mui/material";
import React from "react";
import parsePhoneNumber from "libphonenumber-js";

/**
 * Field for phone number
 * */
export const PhoneNumberField = ({onChangeAndValid, value, country}) => {
  const [inputValue, setInputValue] = React.useState(value);
  const originalValue = value;
  const [isValid, setIsValid] = React.useState(undefined);
  const [validPhoneNumber, setValidPhoneNumber] = React.useState("");

  const validate = (numberToValidate) => {
    // Validate phone number
    const parsedPhoneNumber = parsePhoneNumber(numberToValidate, country);

    if (!parsedPhoneNumber) {
      setIsValid(undefined);
      setValidPhoneNumber('')
      return;
    }

    if (parsedPhoneNumber.isValid()) {
      console.log('valid');
      setIsValid(true);
      setValidPhoneNumber(parsedPhoneNumber.number)
      if(originalValue !== parsedPhoneNumber.number) {
        onChangeAndValid(parsedPhoneNumber);
      }
    } else {
      setValidPhoneNumber('')
      setIsValid(false);
    }
  }

  const handleOnChange = (event) => {
    setInputValue(event.target.value);
  }

  React.useEffect(() => {
    validate(inputValue);
  }, [inputValue]);

  return (
    <>
      <>
        <TextField
          value={inputValue}
          onChange={handleOnChange}
          color={isValid ? 'success' : 'error'}
        />
      </>
      <>
        {!!inputValue && (
          <>
            <Chip
              sx={
                {
                  marginRight: '1em',
                  marginLeft: '1em',
                }
              }
              label={
                (
                  isValid ? validPhoneNumber : 'Invalid'
                )
              }
              color={
                (
                  isValid ? 'success' : 'error'
                )
              }
            />
          </>
        )}
      </>
    </>
  );
}
