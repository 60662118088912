export default function getUserHasPermission(user, permission) {
  /**
   * Check if user has a specific permission in the user.permissions array.
   */
  if(!user || !user.hasOwnProperty('permissions') || !user.permissions || !permission){
    return false
  }

  let app_name = undefined

  if(permission.includes('.')){
    app_name = permission.split('.')[0]
    permission = permission.split('.')[1]
  }

  if(app_name === undefined){
    app_name = 'drp'
  }

  // Check if the user has the permission directly
  if(user.permissions.includes(app_name + '.' + permission)){
    return true
  }

  // Check if the Group permissions includes the permission we want
  if(user.group_permissions.includes(app_name + '.' + permission)){
    return true
  }

  return false
}