import CSVImporter from "../../../../components/import/csv/CSVImporter";

import { LayoutView } from "../../../../layout";

export default function ImportCSVRadiatorValveTypeView() {
  return (
    <LayoutView header={window.gettext("Import Radiator valve type from CSV document")}>
      <CSVImporter importerApiEndpoint="/api/import/csv/radiator-valve-type/" />
    </LayoutView>
  )
}