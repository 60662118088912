import React from "react";
import Form from "../form/Form";
import FormFieldFactory from "../form/FormFieldFactory";

export default function ProjectRowForm(props) {
  const submit_button_text = props.submitButtonText || window.gettext("Create project")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = [
    form_field_factory.textField('category', window.gettext("Category"), true, '', {choices: props.categoryChoices}),
    form_field_factory.booleanField('as_needed', window.gettext("As needed")),
    form_field_factory.booleanField('to_install', window.gettext("To install")),
    form_field_factory.currencyField('price', window.gettext("Price"), true),
    form_field_factory.integerField('count', window.gettext("Count"), true),
    form_field_factory.textField('custom_name', window.gettext("Name")),
  ]

  return (
    <Form
      formTestId="project-row-form"
      formFields={form_fields}
      onSubmit={props.onSubmit}
      submitButtonText={submit_button_text}
      submitButtonTestId="project-form-submit"
    />
  )
}