import React, {useEffect, useState} from "react";
import Form from "../form/Form";
import FormFieldFactory from "../form/FormFieldFactory";
import ChoicesService from "../services/ChoicesService";

export default function ProjectStageForm(props) {
  const form_field_factory = new FormFieldFactory(props.instance)

  let [formFields, setFormFields] = useState([])

  useEffect(() => {
    setFormFields(
      [
        form_field_factory.textField('stage', window.gettext("Project stage"), true, '',
          {
            choices: props.stageChoices,
          }
        ),
      ]
    )
  }, [])

  return (
    <Form
      formTestId="project-form"
      formFields={formFields}
      onSubmit={props.onSubmit}
      submitButtonText={window.gettext('Save project stage')}
      submitButtonTestId="project-form-submit"
    />
  )
}