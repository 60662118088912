import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

import OpportunitiesCollapsableList from "./OpportunitiesCollapsableList";
import OpportunitiesTable from "./OpportunitiesTable";

export default function OpportunitiesDetailedList({
  projectId,
  systemId,
  jobId,
  measurementId,
  opportunities,
  latestOpportunity,
  editable,
}) {
  const showTable = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <>
      {!showTable ? (
        <OpportunitiesCollapsableList
          projectId={projectId}
          systemId={systemId}
          jobId={jobId}
          measurementId={measurementId}
          opportunities={opportunities}
          latestOpportunity={latestOpportunity}
        />
      ) : (
        <OpportunitiesTable
          editable={editable}
          projectId={projectId}
          systemId={systemId}
          jobId={jobId}
          measurementId={measurementId}
          opportunities={opportunities}
          latestOpportunity={latestOpportunity}
        />
      )}
    </>
  );
}
