export const ACTION_DELETE_NODE = 0
export const ACTION_CREATE_PIPE = 1
export const ACTION_CREATE_VALVE = 2
export const ACTION_CREATE_RADIATOR = 3
export const ACTION_SHOW_COPY_DIALOG = 4
export const ACTION_COPY_NODE = 5
export const ACTION_SET_FLAG = 6

export default class GridRowAction{
  action_type = undefined
  row = undefined
  extra_data = undefined
  callback = undefined

  constructor(action_type, row, extra_data, callback) {
    this.action_type = action_type
    this.row = row
    this.extra_data = extra_data
    this.callback = callback
  }
}