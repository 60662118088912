import {Card, CardActions, CardContent} from "@mui/material";
import MultilineText from "../../../../../layout/MultilineText";
import HideForPrint from "../../../../../layout/HideForPrint";
import Button from "@mui/material/Button";

import SelfCheckJobInstallationProfileBlacklistCommentService from "../../../../../services/SelfCheckJobInstallationReportProfileBlacklistCommentService";
import {useSnackbar} from "notistack";

export default function InstallationReportCommentCard({comment, installationReportProfileId, onSuccess}) {
  let {enqueueSnackbar} = useSnackbar()

  const handleCommentBlacklistHideButtonClick = (profile_id, target) => {
    let data = {}

    if (target?.task_item) {
      data['task_item_id'] = target.task_item.id
    }

    if (target?.task) {
      data['task_id'] = target.task.id
    }

    SelfCheckJobInstallationProfileBlacklistCommentService.create(
      data,
      profile_id
    ).then(
      (response) => {
        enqueueSnackbar(
          window.gettext('Comment hidden'),
          {
            variant: 'success',
          }
        )

        if (onSuccess !== undefined) {
          onSuccess()
        }
      }
    )
  }

  return (
    <Card>
      <CardContent>
        <MultilineText>
          {comment.text}
        </MultilineText>
      </CardContent>

      <HideForPrint>
        <CardActions>
          <Button size={'small'} onClick={() => {
            console.log('hide', comment.target)
            handleCommentBlacklistHideButtonClick(
              installationReportProfileId,
              comment.target,
            )
          }}>
            {window.gettext('Hide')}
          </Button>
        </CardActions>
      </HideForPrint>
    </Card>
  )
}