import {Alert, FormControl, Grid, IconButton, TextField} from "@mui/material";
import CommentIcon from '@mui/icons-material/Comment';
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import BasicModal from "../../../../layout/BasicModal";
import PropTypes from "prop-types";

function WrappedComponent(props) {
  let [open, setOpen] = useState(false)
  let [comment, setComment] = useState(null)
  let [originalComment, setOriginalComment] = useState(null)
  let [showForm, setShowForm] = useState(false)

  useEffect(() => {
    setOriginalComment(props.comment)

    if (props.comment === undefined) {
      return
    }

    setShowForm(!props.comment)
    setComment(props.comment || '')
  }, [props.comment])

  useEffect(() => {
    if (comment === null) {
      return
    }
  }, [comment])

  useEffect(() => {
    if(open === false){
      setComment(originalComment)
    }
    // Reset comment to original comment when closing
  }, [open, originalComment])

  const handleCommentOnChange = (event) => {
    setComment(event.target.value)
  }

  const handleSaveButtonClick = (event) => {
    console.log('SAVE', comment)

    props.onSave(comment, () => {setOpen(false)})
  }

  return (
    <>
      <>
        <BasicModal
          toggleButton={
            props?.options?.show_comments === true ? (
              <Typography
                sx={{
                  height: !comment ? "100%" : "auto",
                  width: "100%",
                }}
              >
                {comment}
              </Typography>
            ) : (
              <IconButton title={comment || null}>
                {comment ? (
                  /* There is a comment */
                  <CommentIcon
                    color="primary"
                    fontSize={props?.iconSize || "small"}
                  />
                ) : (
                  /* No comment */
                  <CommentIcon
                    color="lowtone"
                    fontSize={props?.iconSize || "small"}
                  />
                )}
              </IconButton>
            )
          }
          open={open}
          setOpen={setOpen}
          onClose={() => {
            setOpen(false)
          }}
        >
          <>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {window.gettext('Comment')}
                </Typography>
              </Grid>
              <Grid item>
                {(showForm) ? (
                  <>
                    <FormControl>
                      <TextField
                        id="comment"
                        multiline
                        label={window.gettext('Comment text')}
                        value={comment || ""}
                        fullWidth
                        onChange={handleCommentOnChange}
                      />
                    </FormControl>
                  </>
                ) : (
                  <>
                    {(!comment) ? (
                      <>
                        <Alert severity="info">
                          {window.gettext('No comment')}
                        </Alert>
                      </>
                    ) : (
                      <>
                        {/*
                        Comment text
                        */}
                        <Typography variant="body" style={{whiteSpace: 'pre-wrap'}}>
                            {comment}
                        </Typography>
                      </>
                    )}
                  </>
                )}

              </Grid>
              <Grid item>
                {(showForm) ? (
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={handleSaveButtonClick}
                      >
                        {window.gettext('Save')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="error" onClick={() => {
                        setOpen(false)
                      }}>
                        {window.gettext('Cancel')}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Button
                      startIcon={<EditIcon/>}
                      variant="contained"
                      onClick={(e) => {
                        setShowForm(!showForm)
                      }}
                    >
                      <>
                        {!comment ? (
                          <>
                            {window.gettext('Write comment')}
                          </>
                        ) : (
                          <>
                            {window.gettext('Edit comment')}
                          </>
                        )}
                      </>
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        </BasicModal>
      </>
    </>
  )
}

function comparisonFunction(prevProps, nextProps){
  // If the comment is the same, then we shall use the memoized version
  // If they differ somewhat, then we shall return False, forcing a re-rendered
  return (
    prevProps.comment === nextProps.comment &&
    prevProps.iconSize === nextProps.iconSize &&
    prevProps.options?.show_comments === nextProps.options?.show_comments
  );
}

export const CheckableCommentCellIndicator = React.memo(WrappedComponent, comparisonFunction)

export default CheckableCommentCellIndicator

CheckableCommentCellIndicator.propTypes = {
  comment: PropTypes.string,
  onSave: PropTypes.func,
}
