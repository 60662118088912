import { COMPONENT_TYPES } from "../../constants";
import { FormComponent } from "./FormComponent";

export class FormContainer extends FormComponent {
  constructor(containerType, header = "", components = [], options) {
    super(COMPONENT_TYPES.CONTAINER, containerType, options);

    this.containerType = containerType;
    this.header = header;
    this.components = components;

    // OPTION direction: "row" | "column";
    if (this.options.direction !== undefined) {
      this.setDirection(this.options.direction);
    }

    // OPTION fill: true | false;
    if (this.options.fill !== undefined) {
      this.setFill(this.options.fill);
    }

    // OPTION wrap: true | false;
    if (this.options.wrap !== undefined) {
      this.setWrap(this.options.wrap);
    }

    // OPTION split: [num, num, ...];
    if (this.options.split !== undefined) {
      this.setSplit(this.options.split);
    }
  }

  setDirection(direction) {
    if (direction === "row") {
      this.options.innerProps.sx.flexDirection = "row";
    } else if (direction === "column") {
      this.options.innerProps.sx.flexDirection = "column";
    }
  }

  setFill(fill) {
    if (fill) {
      this.options.childProps.sx.flexGrow = 1;
    } else {
      this.options.childProps.sx.flexGrow = 0;
    }
  }

  setWrap(wrap) {
    if (wrap) {
      this.options.innerProps.sx.flexWrap = "wrap";
    } else {
      this.options.innerProps.sx.flexWrap = "nowrap";
    }
  }

  setSplit(split) {
    split.forEach((part, index) => {
      if (part !== undefined) {
        if(this.options.uniqueChildProps[index] === undefined) {
          this.options.uniqueChildProps[index] = {};
        }
        if(this.options.uniqueChildProps[index].sx === undefined) {
          this.options.uniqueChildProps[index].sx = {};
        }
        this.options.uniqueChildProps[index].sx.flexBasis = `${part}%`;
      }
    });
  }
}
