import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ProjectForm from "../../../../components/project/ProjectForm";
import {
  copyObject,
  removeKeysWithUnchangedValue,
  removeKeysWithValue,
} from "../../../../components/services/BaseService";
import ProjectService from "../../../../components/services/ProjectService";
import MissingPermissionMessage from "../../../../components/user/MissingPermissionMessage";
import UserPermission from "../../../../components/user/UserPermission";
import { LayoutView } from "../../../../layout";

export default function ProjectEditView() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] = useState();

  const { project_id } = useParams();

  const fetchProject = function () {
    function fixedSetProject(project) {
      const values = copyObject(project);
      removeKeysWithValue(values, null);
      setProject(values);
    }

    ProjectService.fetch(project_id).then(fixedSetProject);
  };

  useEffect(() => {
    // Fetch project
    fetchProject();
  }, [project_id]);

  function handleSubmit(values) {
    removeKeysWithUnchangedValue(values, project);

    console.log("Trying to update project values:", values);
    ProjectService.update(values, project_id).then(
      (response) => {
        if (Object.values(values).length > 0) {
          enqueueSnackbar(window.gettext("Project updated"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(window.gettext("No changes were made"), {
            variant: "success",
          });
        }
        console.log("Successfully updated project");
        navigate("/projects/" + String(response.id) + "/");
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext("Error while trying to update project"),
          {
            variant: "error",
          }
        );
        if (
          rejection.hasOwnProperty("response") &&
          rejection.response.hasOwnProperty("data")
        ) {
          console.error("Failed to update project\n", rejection.response.data);
        } else {
          console.error("Failed to update project");
        }
      }
    );
  }

  if (!project) {
    // TODO: Add skelton loader.
    return <div>Loading...</div>;
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
      }}
      header={window.gettext("Change project")}
    >
      <UserPermission
        permission={UserPermission.permissions.project_change}
        else={<MissingPermissionMessage />}
      >
        <ProjectForm
          onSubmit={handleSubmit}
          initValues={project}
          submitButtonText={window.gettext("Save project")}
        />
      </UserPermission>
    </LayoutView>
  );
}
