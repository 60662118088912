import { renderComponentsStack } from "../../helper";
import {
  FactoryBasicStackContainer,
  FactoryExpandableStackContainer,
} from "./components";

export function FactoryStackContainer({
  formContainer,
  control,
  validationErrors,
}) {
  const { header, components, options } = formContainer;
  const expanded = options.expanded !== undefined ? options.expanded : null;

  const props = {
    header,
    options,
  };
  const children = renderComponentsStack(
    components,
    control,
    validationErrors,
    options.childProps,
    options.uniqueChildProps
  );

  return (
    <>
      {expanded === null ? (
        <FactoryBasicStackContainer {...props}>
          {children}
        </FactoryBasicStackContainer>
      ) : (
        <FactoryExpandableStackContainer {...props} expanded={expanded}>
          {children}
        </FactoryExpandableStackContainer>
      )}
    </>
  );
}
