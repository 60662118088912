import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  BREAKPOINT_MOBILE,
  PREFERRED_LAYOUT_AUTO,
  PREFERRED_LAYOUT_MOBILE,
} from "../constants";

const LOCAL_STORAGE_KEY = "layout_size_tracker";

export const localStorageData = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEY)
) || {
  preferredLayout: undefined,
};

function saveToLocalStorage(key, value) {
  localStorageData[key] = value;

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageData));
}

const LayoutSizeTrackerContext = createContext(null);

function trackRefSize(ref, { setHeight, setWidth, storedHeight, storedWidth }) {
  if (!ref.current) {
    if (setHeight) {
      setHeight(() => 0);
    }

    if (setWidth) {
      setWidth(() => 0);
    }

    return;
  }

  const observer = new ResizeObserver((entries) => {
    const height = entries[0].contentRect.height;
    const width = entries[0].contentRect.width;

    if (setHeight && height !== storedHeight) {
      setHeight(() => height);
    }

    if (setWidth && width !== storedWidth) {
      setWidth(() => width);
    }
  });

  observer.observe(ref.current);

  return () => {
    if (ref.current) {
      observer.unobserve(ref.current);
    }
  };
}

export function useProvideLayoutSizeTrackerContext() {
  const bottomRef = useRef(null);
  const leftRef = useRef(null);
  const mainRef = useRef(null);
  const rightRef = useRef(null);
  const topRef = useRef(null);

  const [preferredLayout, setPreferredLayoutState] = useState(
    localStorageData.preferredLayout || PREFERRED_LAYOUT_AUTO
  );
  const [isMobile, setIsMobile] = useState(
    preferredLayout !== PREFERRED_LAYOUT_AUTO
      ? preferredLayout === PREFERRED_LAYOUT_MOBILE
      : window.innerWidth <= BREAKPOINT_MOBILE
  );

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);

  const [bottomHeight, setBottomHeight] = useState(0);
  const [leftHeight, setLeftWidth] = useState(0);
  const [mainHeight, setMainHeight] = useState(0);
  const [mainWidth, setMainWidth] = useState(0);
  const [rightHeight, setRightWidth] = useState(0);
  const [topHeight, setTopHeight] = useState(0);

  // Update isMobile
  useEffect(() => {
    if (preferredLayout !== PREFERRED_LAYOUT_AUTO) {
      setIsMobile(preferredLayout === PREFERRED_LAYOUT_MOBILE);

      return;
    }

    if (width <= BREAKPOINT_MOBILE && !isMobile) {
      console.log("Mobile detected");
      setIsMobile(true);
    } else if (window.innerWidth > BREAKPOINT_MOBILE && isMobile) {
      console.log("Desktop detected");
      setIsMobile(false);
    }
  }, [isMobile, preferredLayout, width]);

  // Track window size
  useEffect(() => {
    const updateDimension = () => {
      document.body.height = window.innerHeight;

      if (window.innerHeight !== height) {
        setHeight(window.innerHeight);
      }

      if (window.innerWidth !== width) {
        setWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [width, height]);

  // Track bottom height
  useEffect(() => {
    trackRefSize(bottomRef, {
      setHeight: setBottomHeight,
      storedHeight: bottomHeight,
    });
  }, [bottomHeight]);

  // Track left width
  useEffect(() => {
    return trackRefSize(leftRef, {
      setWidth: setLeftWidth,
      storedWidth: leftHeight,
    });
  }, [leftHeight]);

  // Track main size
  useEffect(() => {
    return trackRefSize(mainRef, {
      setHeight: setMainHeight,
      setWidth: setMainWidth,
      storedHeight: mainHeight,
      storedWidth: mainWidth,
    });
  }, [mainHeight, mainWidth]);

  // Track right width
  useEffect(() => {
    return trackRefSize(rightRef, {
      setWidth: setRightWidth,
      storedWidth: rightHeight,
    });
  }, [rightHeight]);

  // Track top size
  useEffect(() => {
    return trackRefSize(topRef, {
      setHeight: setTopHeight,
      storedHeight: topHeight,
    });
  }, [topHeight]);

  const setPreferredLayout = useCallback(
    (newPreferredLayout) => {
      if (newPreferredLayout === preferredLayout) {
        return;
      }

      saveToLocalStorage("preferredLayout", newPreferredLayout);
      setPreferredLayoutState(newPreferredLayout);
    },
    [preferredLayout]
  );

  return {
    bottomHeight,
    bottomRef,
    height,
    isMobile,
    leftHeight,
    leftRef,
    mainHeight,
    mainRef,
    mainWidth,
    preferredLayout,
    rightHeight,
    rightRef,
    topHeight,
    topRef,
    width,

    setPreferredLayout,
  };
}

export function LayoutSizeTrackerContextProvider({ children, ...props }) {
  const context = useProvideLayoutSizeTrackerContext(props);

  return (
    <LayoutSizeTrackerContext.Provider value={context}>
      {children}
    </LayoutSizeTrackerContext.Provider>
  );
}

export function useLayoutSizeTrackerContext() {
  const context = useContext(LayoutSizeTrackerContext);

  if (!context) {
    throw new Error(
      "useLayoutSizeTrackerContext has to be used within <LayoutSizeTrackerContextProvider>"
    );
  }

  return context;
}
