import BaseService from "./BaseService";

class RadiatorTypeSearchService extends BaseService {
  search = (query) => {
    return this.list(
      'radiator-types-search',
      '?query=' + query
    )
  }
}

export default new RadiatorTypeSearchService('/api/',
  []
)