import {TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import WarningIcon from '@mui/icons-material/Warning';

export default function EditNameTextField({autoFocus, id, nodes, onChange, value}) {
  let [isValid, setIsValid] = useState(true)
  let [validationErrors, setValidationErrors] = useState([])

  const getValidationErrors = function (name) {
    if (name === "") {
      return [
        window.gettext(
          'Name can not be empty'
        )
      ]
    }

    const nodeWithName = nodes.find((node) => {
      return node.name === name && node.id !== id
    })

    if (nodeWithName) {
      return [
        window.gettext(
          'Node with this name already exists on the system'
        )
      ]
    }
  }

  const handleOnChange = function (event) {
    const validationErrors = getValidationErrors(event.target.value)

    // Set valid or not
    setIsValid(!validationErrors)

    // set errors
    setValidationErrors(validationErrors)

    // Trigger onChange method
    onChange(event)
  }

  return (
    <>
      {!isValid && (
        <>
          <Tooltip
            open={true}
            title={validationErrors.join("\n")}
          >
            <WarningIcon
              color={"warning"}
            />
          </Tooltip>
        </>
      )}
      <>
        <TextField
          value={value}
          autoFocus={autoFocus}
          onChange={handleOnChange}
        />
      </>
    </>
  )
}