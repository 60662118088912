import { Link as RouterLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";

export function Link({ children, color, to }) {
  return (
    <MaterialLink component={RouterLink} color={color} to={to}>
      {children}
    </MaterialLink>
  );
}
