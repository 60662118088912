import {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import NippleService from "../services/NippleService";
import {Tooltip} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";


export function NippleRepresentation({nipple}) {
  return (
    <>
      {nipple?.label || ''}
    </>
  )
}

NippleRepresentation.propTypes = {
  'nipple': PropTypes.object,
}

/*
* props.value = the value, always in centimeters
* props.precision = how many decimal points we should display
* props.unit = m/mm/cm
* */
export default function Nipple({id, value}) {
  // can be set to object or undefined if not found and null until set
  let [nipple, setNipple] = useState(null)

  // This contains the list of nipples from the API
  let [nippleOptions, setNippleOptions] = useState(null)

  // This contains a sanitized version of the ID, as whatever can be passed to the component
  let [sanitizedNippleId, setSanitizedNippleId] = useState(undefined)

  // Sanitize the input value.
  useEffect(() => {
    if (id === null) {
      setSanitizedNippleId(null)
    }

    if (!id) {
      setSanitizedNippleId(undefined)
      return
    }

    if (typeof id === 'number') {
      setSanitizedNippleId(id)
    }
    if (typeof id === 'string') {
      setSanitizedNippleId(parseInt(id, 10))
    }
  }, [id]);

  // If a nipple object is passed, we should use it
  useEffect(() => {
    if (!value) {
      return
    }

    setNipple(value)

    return () => {
      setNipple(null);
    }
  }, [value])

  // Fetch the list of nipples
  useMemo(() => {
    NippleService.cache_list().then(
      setNippleOptions
    )
  }, [])

  // Find and set the nipple object from the list of nipples and the id
  useEffect(() => {
    if (!sanitizedNippleId) {
      return
    }

    if (!nippleOptions) {
      return
    }

    if (nippleOptions.length === 0) {
      return
    }

    // find the nipple object by comparing the id to the nipple id
    // in case we don't find the nipple, we set it to undefined
    setNipple(
      nippleOptions.find(
        (nippleOption) => {
          return nippleOption.id === sanitizedNippleId
        }
      ) || undefined
    )
  }, [sanitizedNippleId, nippleOptions])

  return (
    <>
      {(!nipple) ? (
        <>
          {(nipple === undefined) && (
            <>
              <Tooltip
                title="Unknown nipple, try to refresh the page or contact the administrator."
              >
                <HelpIcon/>
              </Tooltip>
            </>
          )}
        </>
      ) : (
        <NippleRepresentation
          nipple={nipple}
        />
      )}
    </>
  )
}


Nipple.propTypes = {
  'value': PropTypes.object,
  'id': PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}