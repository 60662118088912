import BaseService from "./BaseService";

class SystemCodedAddressService extends BaseService {}

export default new SystemCodedAddressService('/api/',
  [
    'projects',
    'systems',
    'coded-addresses',
  ]
)