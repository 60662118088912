import {
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_BATHROOM_FAUCET,
  ENTITY_TYPE_DISHWASHER,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_KITCHEN_FAUCET,
  ENTITY_TYPE_RADIATOR,
  ENTITY_TYPE_SHOWER,
  ENTITY_TYPE_SYSTEM,
  ENTITY_TYPE_TOILET,
  ENTITY_TYPE_VALVE,
  ENTITY_TYPE_WASHING_MACHINE,
} from "../entity";

export function getDefaultEntityLabel(entityType) {
  switch (entityType) {
    case ENTITY_TYPE_APARTMENT:
      return window.gettext("Apartment");
    case ENTITY_TYPE_BATHROOM_FAUCET:
      return window.gettext("Bathroom faucet");
    case ENTITY_TYPE_DISHWASHER:
      return window.gettext("Dishwasher");
    case ENTITY_TYPE_ENTRANCE:
      return window.gettext("Entrance");
    case ENTITY_TYPE_KITCHEN_FAUCET:
      return window.gettext("Kitchen faucet");
    case ENTITY_TYPE_RADIATOR:
      return window.gettext("Radiator");
    case ENTITY_TYPE_SHOWER:
      return window.gettext("Shower");
    case ENTITY_TYPE_SYSTEM:
      return window.gettext("System");
    case ENTITY_TYPE_TOILET:
      return window.gettext("Toilet");
    case ENTITY_TYPE_VALVE:
      return window.gettext("Valve");
    case ENTITY_TYPE_WASHING_MACHINE:
      return window.gettext("Washing machine");
    default:
      return "-";
  }
}

export function getEntityLabel(entityData, entity) {
  entity = entityData?.entity || entity;
  return entity?.data.label || getDefaultEntityLabel(entity.type);
}
