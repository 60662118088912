import { Card, Modal } from "@mui/material";

export function CenteredModal({
  modalHandler,
  open,
  onClose,
  children,
  width=undefined,
  maxWidth = 600,
  maxHeight = "100%",
  padding = 2,
  margin = 2,
}) {
  return (
    <Modal
      open={modalHandler !== undefined ? modalHandler.isOpen : open}
      onClose={modalHandler !== undefined ? modalHandler.close : onClose}
      sx={{
        maxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        padding: margin,
      }}
    >
      <Card
        sx={{
          overflowY: "scroll",
          width: width,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
          padding: padding,
        }}
      >
        {children}
      </Card>
    </Modal>
  );
}
