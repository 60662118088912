import { Box, CircularProgress } from "@mui/material";

import JobCheckableCheckbox from "../../../../../datagrid/JobCheckableCheckbox";

export function HierarchyTaskItemCheckbox({
  checked,
  clickable,
  hasError,
  isSaving,
  onClick,
  spec,
  title,
  visible,
}) {
  return (
    <Box>
      {/* Checkbox */}
      <JobCheckableCheckbox
        checked={checked}
        clickable={isSaving ? false : clickable}
        onClick={onClick}
        spec={spec}
        title={title}
        visible={visible}
      />

      {/* Saving indicator */}
      <Box
        sx={{
          position: "absolute",
          top: 10,
          left: 18,
          zIndex: -1,
        }}
      >
        {isSaving || hasError ? (
          // Saving indicator
          <CircularProgress
            variant={hasError ? "determinate" : "indeterminate"}
            value={hasError ? 100 : undefined}
            color={hasError ? "error" : "primary"}
            size={32}
          />
        ) : (
          // Placeholder
          <Box
            sx={{
              width: 32,
              height: 32,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
