import { COMPONENT_TYPES } from "../../constants";
import { renderContainer, renderField } from "./";

export function renderComponentsStack(
  formComponents,
  control,
  validationErrors,
  childProps = {},
  uniqueChildProps = []
) {
  {
    return formComponents.map((formComponent, index) => {
      formComponent.addProps(childProps);
      formComponent.addProps(uniqueChildProps[index]);

      if (formComponent.type === COMPONENT_TYPES.CONTAINER) {
        return renderContainer(formComponent, control, validationErrors, index);
      } else if (formComponent.type === COMPONENT_TYPES.FIELD) {
        return renderField(formComponent, control, validationErrors);
      } else {
        console.error("Unknown component type", formComponent.type);
        return null;
      }
    });
  }
}
