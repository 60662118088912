import {Button, Checkbox, FormControl, FormControlLabel, TextField} from "@mui/material";
import {useCallback, useEffect} from "react";

import {getUserRepresentation} from "../../components/user";
import {useUser} from "../../context";
import {
  DEFAULT_MAX_WIDTH,
  LayoutCard,
  LayoutView,
  PREFERRED_LAYOUT_AUTO,
  PREFERRED_LAYOUT_DESKTOP,
  PREFERRED_LAYOUT_MOBILE,
  useLayoutSize,
} from "../../layout";
import {PhoneNumberField} from "../../components/form/PhoneNumberField";

import {useSnackbar} from "notistack";

export default function AccountView() {
  const {logout, user, putUser} = useUser();
  const {enqueueSnackbar} = useSnackbar();

  const {preferredLayout, setPreferredLayout} = useLayoutSize();

  const handleLogoutClick = useCallback(() => {
    logout();
  }, [logout]);

  const handleCheckboxChangePreferredLayout = useCallback(
    (event) => {
      setPreferredLayout(event.target.value);
    },
    [setPreferredLayout]
  );

  const handleUserChange = useCallback(
    (data) => {
      if (!user) {
        return
      }
      putUser(user.id, data).then(
        (response) => {
          enqueueSnackbar(
            window.gettext("User profile saved"),
            {
              variant: "success",
            }
          );
        }
      );
    },
    [putUser, user]
  );

  useEffect(() => {
    console.log('USER', user)
  }, [user]);

  return (
    <LayoutView header={window.gettext("Account")} maxWidth={DEFAULT_MAX_WIDTH}>
      <LayoutCard title={getUserRepresentation(user)}>
        <Button onClick={handleLogoutClick}>{window.gettext("Log out")}</Button>
      </LayoutCard>

      <LayoutCard title={window.gettext("DRP interface")}>
        <FormControl
          sx={{
            flexDirection: "row",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={preferredLayout === PREFERRED_LAYOUT_MOBILE}
                onClick={handleCheckboxChangePreferredLayout}
                value={PREFERRED_LAYOUT_MOBILE}
              />
            }
            label={window.gettext("Mobile")}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={preferredLayout === PREFERRED_LAYOUT_AUTO}
                onClick={handleCheckboxChangePreferredLayout}
                value={PREFERRED_LAYOUT_AUTO}
              />
            }
            label={window.gettext("Auto")}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={preferredLayout === PREFERRED_LAYOUT_DESKTOP}
                onClick={handleCheckboxChangePreferredLayout}
                value={PREFERRED_LAYOUT_DESKTOP}
              />
            }
            label={window.gettext("Desktop")}
          />
        </FormControl>
      </LayoutCard>
      {(!!user) && (
        <>
          <LayoutCard title={window.gettext("Contact info")}>
            <FormControl
              sx={
                {
                  flexDirection: "row",
                  marginLeft: '1em',
                }
              }
            >
              {/*Field for phone number*/}
              <FormControlLabel
                control={
                  <PhoneNumberField
                    onChangeAndValid={(phoneNumber) => {
                      handleUserChange(
                        {
                          phone_number: phoneNumber.number,
                          phone_country_code: phoneNumber.country
                        }
                      );
                    }}
                    value={user?.profile?.phone_number || ""}
                    country={user?.profile?.phone_number || 'SE'}
                  />
                }
                label={window.gettext('Mobile')}
              />
            </FormControl>
          </LayoutCard>
        </>
      )}
    </LayoutView>
  );
}
