export function getSettingsForColumn(columnSettings, field) {
  // Return the matching setting object or undefined
  return columnSettings.find((columnSetting) => {
    // The the one that matches columSettings.field to field
    return columnSetting.hasOwnProperty('field') && columnSetting['field'] === field;
  }) || undefined
}

export function getColumnConfigSettingsValue(columnSettings, field, property, defaultValue = undefined) {
  const config = getSettingsForColumn(columnSettings, field)

  if (config === undefined) {
    return defaultValue
  }

  const settings = config['settings']

  // Return default value if settings property does not exist
  if (!settings.hasOwnProperty(property)) {
    return defaultValue
  }

  return settings[property]
}

export function changeColumnSettingsProperty(columnSettings, field, property_key, value) {
  // Generate a new array to replace with
  let newColumnSettings = columnSettings.map(
    (columnSetting) => {
      return columnSetting
    }
  )

  // If there is no current setting for the field. create one
  if (getSettingsForColumn(columnSettings, field) === undefined) {
    newColumnSettings.push(
      {
        'field': field,
        'settings': {},
      }
    )
  }

  // Setting property to value
  return newColumnSettings.map((columnSetting) => {
    if (columnSetting.field === field) {
      columnSetting['settings'][property_key] = value
    }
    return columnSetting
  })
}

export function saveColumnSettings(columnSettings){
  localStorage.setItem('column_settings', JSON.stringify(columnSettings))
}

export function getColumnsWithAppliedColumnSettings (columnSettings, columns) {
  // Apply settings from settings
  return columns.map(
    (column) => {
      // Get settings but default to empty settings object
      column.hide = getColumnConfigSettingsValue(columnSettings, column.field, 'hide', column.hide)
      column.width = parseInt(getColumnConfigSettingsValue(columnSettings, column.field, 'width', column.width), 10) || undefined
      return column;
    }
  )
}
