import { Route, Routes } from "react-router-dom";

import { AccountView } from "./account";
import { HomeView } from "./home";
import {
  ImportCSVNippleView,
  ImportCSVPipeDimensionView,
  ImportCSVPipeTypeView,
  ImportCSVRadiatorTypeView,
  ImportCSVRadiatorValveDimensionView,
  ImportCSVRadiatorValveTypeView,
  ImportCSVValveTypeView,
} from "./import";
import { LoginView } from "./login";
import { NotFoundView } from "./not_found";
import {
  JobDetailsDatagridView,
  JobDetailsHierarchyView,
  JobInstallationReportView,
  JobReportCalculationView,
  JobReportInstallationProfileSelectionView,
  JobReportMeasurementProfileSelectionView,
  JobReportMeasurementView,
  JobSpecDetailsView,
  JobsReportDetailView,
  MeasurementCreateView,
  MeasurementOpportunityView,
  MeasurementView,
  ProjectCreateView,
  ProjectDetailView,
  ProjectEditView,
  ProjectsBasicListView,
  ProjectsOverviewTableView,
  ProjectsSelfCheckFocusView,
  ProjectsView,
  SystemBuildTablePotableWaterView,
  SystemBuildTableView,
  SystemCreateView,
  SystemDetailView,
  SystemEditView,
  SystemLocationsView,
} from "./projects";
import StatisticsRadiatorsView from "./statistics/radiators/StatisticsRadiatorsView";
import IssuesIndexView from "./issues/IssuesIndexView";
import IssueDetailView from "./issues/id/IssueDetailView";

export default function Views() {
  return (
    <Routes>
      <Route path="/account/" element={<AccountView />} />

      <Route path="/" exact={true} element={<HomeView />} />

      <Route path="/import/">
        <Route path="csv/nipple/" element={<ImportCSVNippleView />} />
        <Route
          path="csv/pipe-dimension/"
          element={<ImportCSVPipeDimensionView />}
        />
        <Route path="csv/pipe-type/" element={<ImportCSVPipeTypeView />} />
        <Route
          path="csv/radiator-type/"
          element={<ImportCSVRadiatorTypeView />}
        />
        <Route
          path="csv/radiator-valve-dimension/"
          element={<ImportCSVRadiatorValveDimensionView />}
        />
        <Route
          path="csv/radiator-valve-type/"
          element={<ImportCSVRadiatorValveTypeView />}
        />
        <Route path="csv/valve-type/" element={<ImportCSVValveTypeView />} />
      </Route>

      <Route path="/login/" element={<LoginView />} />

      {/* Projects routes */}
      <Route path="/projects/">
        <Route path="" element={<ProjectsView />} />

        <Route path="create/" element={<ProjectCreateView />} />

        <Route path="basic-list/" element={<ProjectsBasicListView />} />
        <Route path="overview-table/" element={<ProjectsOverviewTableView />} />

        <Route
          path="self-check-focus/"
          element={<ProjectsSelfCheckFocusView />}
        />

        {/* Project detail routes */}
        <Route path=":project_id/">
          <Route path="" element=<ProjectDetailView /> />

          <Route path="edit/" element={<ProjectEditView />} />

          {/* Job spec routes */}
          <Route path="self-check/">
            <Route path="job-specs/">
              <Route path=":job_spec_id/">
                <Route path="" element={<JobSpecDetailsView />} />

                <Route path="report/">
                  <Route path="detail" element={<JobsReportDetailView />} />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* System routes */}
          <Route path="systems/">
            <Route path="create/" element={<SystemCreateView />} />

            <Route path=":system_id/">
              <Route path="" element={<SystemDetailView />} />

              <Route path="edit/" element={<SystemEditView />} />
              <Route path="locations/" element={<SystemLocationsView />} />

              <Route path="build/">
                <Route path="table/">
                  <Route path="" element={<SystemBuildTableView />} />

                  <Route
                    path="potable-water/"
                    element={<SystemBuildTablePotableWaterView />}
                  />
                </Route>
              </Route>

              <Route path="jobs/">
                <Route path=":job_id/">
                  <Route path="" element={<JobDetailsDatagridView />} />

                  <Route
                    path="hierarchy/"
                    element={<JobDetailsHierarchyView />}
                  />
                </Route>
              </Route>

              <Route path="job-specs/:job_spec_id/job/:job_id/">
                <Route path="report/">
                  <Route
                    path="calculation/"
                    element={<JobReportCalculationView />}
                  />

                  <Route path="installation/">
                    <Route
                      path=""
                      element={<JobReportInstallationProfileSelectionView />}
                    />

                    <Route path="profile/">
                      <Route
                        path=":profile_id/"
                        element={<JobInstallationReportView />}
                      />
                    </Route>
                  </Route>

                  <Route path="measurement/">
                    <Route
                      path=""
                      element={<JobReportMeasurementProfileSelectionView />}
                    />

                    <Route path="profile/">
                      <Route
                        path=":profile_id/"
                        element={<JobReportMeasurementView />}
                      />
                    </Route>
                  </Route>
                </Route>

                <Route path="measurements/">
                  <Route path="create/" element={<MeasurementCreateView />} />

                  <Route path=":measurement_id/">
                    <Route path="" element={<MeasurementView />} />

                    <Route path="opportunity/">
                      <Route
                        path=":opportunity_id/"
                        element={<MeasurementOpportunityView />}
                      />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      <Route path="/statistics/">
        <Route path="radiators/" element={<StatisticsRadiatorsView />} />
      </Route>

      {/* Issue tracker */}
      <Route path="/issues/">
        <Route path=":issue_id/" element={<IssueDetailView />} />
        <Route path="" element={<IssuesIndexView />} />
      </Route>

      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
}
