import {Checkbox} from "@mui/material";
import AutocompleteInput from "../AutocompleteInput";
import React from "react";

// The item type 'Valve'
export default function EditValveOnValve(valveTypes) {
  return (params) => {
    // Transform the opions
    let options = valveTypes.map((valveType) => {
      return {
        'id': valveType.id,
        'name': valveType.name.name,
      }
    })

    const handleValveUseAsLoopCheckboxChange = function (event) {
      // set overrides, this is picked up in the commit function in BuildTable
      params.field_override = 'valve_loop'
      params.value_override = event.target.checked

      // Trigger the commit function
      params.api.commitCellChange(
        params,
        event
      )

      // Back to view mode after we are done.
      params.api.setCellMode(
        params.id,
        params.field,
        'view'
      )
    }

    console.log('params', params)

    // Render clickable Checkbox and autocomplete dropdown
    return (
      <>
        <Checkbox
          checked={params.row.node.item.loop}
          onChange={handleValveUseAsLoopCheckboxChange}
        />
        <AutocompleteInput
          {...params}
          selectedOptionId={params.row.node.item.type_id}
          options={options}
          label={window.gettext('Valve type')}
        />
      </>
    )
  }
}
