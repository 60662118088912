import {FormControl, Grid, InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import SystemService from "../../../services/SystemService";
import {useSnackbar} from "notistack";

export default function QuickSettings(props) {
  let [system, setSystem] = useState(undefined)
  let [systemPumpPressure, setSystemPumpPressure] = useState(0)
  let [systemPumpPressureDisplayValue, setSystemPumpPressureDisplayValue] = useState("")
  let [systemReturnTemperature, setSystemReturnTemperature] = useState(0)
  let [systemOutflowTemperature, setSystemOutflowTemperature] = useState(0)
  const {enqueueSnackbar} = useSnackbar();

  const updateSystem = function (data, message) {
    SystemService.update(
      data,
      system.project_id,
      system.id,
    ).then((response) => {
      setSystem(response)
      enqueueSnackbar(
        message,
        {
          'variant': 'success'
        }
      )
    })
  }

  useEffect(() => {
    if (!props.system) {
      return
    }

    setSystem(props.system)

  }, [props.system])

  useEffect(() => {
    if (!system) {
      return
    }

    // call to update parent
    props.onSystemChange(system)

    if(!system.pump_pressure) {
      setSystemPumpPressureDisplayValue("")
    }else{
      setSystemPumpPressureDisplayValue(String(system.pump_pressure / 1000))
    }

    setSystemPumpPressure(system.pump_pressure || 0)
    setSystemOutflowTemperature(system.outflow_temperature || 0)
    setSystemReturnTemperature(system.return_temperature || 0)
  }, [system])

  useEffect(() => {
    // Update the pa value so we update the system
    const pumpPressure = parseInt(parseFloat(systemPumpPressureDisplayValue) * 1000)
    console.log('pump pressure', pumpPressure)
    setSystemPumpPressure(pumpPressure)
    setSystemPumpPressureDisplayValue(String(pumpPressure / 1000))
  }, [systemPumpPressureDisplayValue])

  const updateSystemPumpPressure = function () {
    if (system.pump_pressure === systemPumpPressure) {
      return
    }

    updateSystem(
      {
        'pump_pressure': systemPumpPressure
      },
      window.gettext('Pump pressure') + ' ' + window.gettext('saved')
    )
  }

  const updateSystemReturnTemperature = function () {
    if (system.return_temperature === systemReturnTemperature) {
      return
    }

    updateSystem(
      {
        'return_temperature': systemReturnTemperature
      },
      window.gettext('Return temperature') + ' ' + window.gettext('saved')
    )
  }


  const updateSystemOutflowTemperature = function () {
    if (system.outflow_temperature === systemOutflowTemperature) {
      return
    }

    updateSystem(
      {
        'outflow_temperature': systemOutflowTemperature
      },
      window.gettext('Outflow temperature') + ' ' + window.gettext('saved')
    )
  }


  const handleKeyDown = function (event) {
    if (event.type === 'keydown') {
      if (event.code === 'Enter') {
        switch (event.target.parentElement.parentElement.dataset['field']) {
          case 'pump_pressure':
            updateSystemPumpPressure()
            break
          case 'return_temperature':
            updateSystemReturnTemperature()
            break
          case 'outflow_temperature':
            updateSystemOutflowTemperature()
            break
          default:
            break
        }
      }
    }
  }

  return (
    <>
      {system !== undefined && (
        <Grid container spacing={2}>
          <Grid item>
            <FormControl>
              <TextField
                data-test-id='quick-settings-system-pump-pressure'
                data-field="pump_pressure"
                type="number"
                value={systemPumpPressureDisplayValue}
                onChange={(event) => {
                  setSystemPumpPressureDisplayValue(event.target.value)
                }}
                onKeyDown={handleKeyDown}
                onBlur={updateSystemPumpPressure}
                variant="standard"
                style={{width: '150px'}}
                size="small"
                label={window.gettext('Pump pressure')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">kPa</InputAdornment>
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <TextField
                data-test-id='quick-settings-system-outflow-temperature'
                data-field="outflow_temperature"
                type="number"
                value={systemOutflowTemperature}
                onChange={(event) => {
                  setSystemOutflowTemperature(event.target.value)
                }}
                onKeyDown={handleKeyDown}
                onBlur={updateSystemOutflowTemperature}
                variant="standard"
                size="small"
                style={{width: '150px'}}
                label={window.gettext('Outflow temperature')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>
                }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl>
              <TextField
                data-test-id='quick-settings-system-return-temperature'
                data-field="return_temperature"
                type="number"
                value={systemReturnTemperature}
                onChange={(event) => {
                  setSystemReturnTemperature(event.target.value)
                }}
                onKeyDown={handleKeyDown}
                onBlur={updateSystemReturnTemperature}
                variant="standard"
                size="small"
                style={{width: '150px'}}
                label={window.gettext('Return temperature')}
                InputProps={{
                  endAdornment: <InputAdornment position="end">°C</InputAdornment>
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  )
}