import { Stack, Typography } from "@mui/material";
import { useEffect } from "react";

import { Breadcrumbs } from "../../components/breadcrumbs";
import { useLayoutTop } from "../../layout";

function LayoutViewHeader({
  header,
  breadcrumbs,
  borderRadius = 2,
  headerVariant = "h2",
  noPaddingSubmenu,
  topMenuItems,
  submenuItems,
  submenuBorderBottom = undefined,
}) {
  return (
    <Stack
      sx={{
        displayPrint: "none",
        overflow: "hidden",
        bgcolor: "background.paper",
        flexShrink: 0,
        minHeight: 52,
        borderRadius: borderRadius,
      }}
    >
      <Stack
        sx={{
          bgcolor: "primary.main",
          color: "primary.contrastText",
          flexShrink: 0,
          flexDirection: "row",
          p: 1,
          gap: 1,
        }}
      >
        {typeof header === "object" ? (
          header
        ) : (
          <Typography variant={headerVariant}>
            {header ? header : window.gettext("Loading") + "..."}
          </Typography>
        )}

        {topMenuItems}
      </Stack>

      {breadcrumbs && (
        <Stack
          sx={{
            bgcolor: "primary.light",
            color: "primary.contrastText",
            flexShrink: 0,
            flexDirection: "row",
            borderRadius: borderRadius,
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            px: 1,
            py: 0.5,
            gap: 1,
          }}
        >
          <Breadcrumbs linkColor="primary.contrastText" {...breadcrumbs} />
        </Stack>
      )}

      {submenuItems && (
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            width: "100%",
            p: noPaddingSubmenu ? 0 : 1,
            borderBottom: submenuBorderBottom,
          }}
        >
          {submenuItems}
        </Stack>
      )}
    </Stack>
  );
}

export function LayoutViewDesktop({
  children,
  breadcrumbs,
  borderRadius = 2,
  header,
  headerVariant = "h2",
  noPaddingSubmenu,
  padding = 1,
  submenuItems,
  submenuBorderBottom = undefined,
  sx,
  topMenuItems,
}) {
  const layoutTop = useLayoutTop();

  useEffect(() => {
    layoutTop.setElement(
      <LayoutViewHeader
        breadcrumbs={breadcrumbs}
        borderRadius={borderRadius}
        header={header}
        headerVariant={headerVariant}
        noPaddingSubmenu={noPaddingSubmenu}
        submenuItems={submenuItems}
        submenuBorderBottom={submenuBorderBottom}
        topMenuItems={topMenuItems}
      />
    );
  }, [
    breadcrumbs,
    borderRadius,
    header,
    headerVariant,
    noPaddingSubmenu,
    submenuItems,
    submenuBorderBottom,
    topMenuItems,
  ]);

  return (
    <Stack
      className="hidden-scrollbar"
      sx={{
        boxSizing: "border-box",
        overflowY: "scroll",
        p: padding,
        "@media print": {
          overflowY: "visible",
        },
        ...sx,
      }}
    >
      {children}
    </Stack>
  );
}
