import { useContext } from "react";

import InstanceManipulator from "../../../../../../InstanceManipulator";
import { ReportMeasurementProfileContext } from "../../../ReportMeasurementProfileContext";
import JobReportMeasurementProfileForm from "./JobReportMeasurementProfileForm";

export default function JobReportMeasurementProfileManipulator({
  mode = InstanceManipulator.MODE_CREATE,
  postCreate,
  postUpdate,
}) {
  const {
    selfCheckJobMeasurementReportProfileService,
    selfCheckJobMeasurementReportProfileServiceParams,
  } = useContext(ReportMeasurementProfileContext);

  return (
    <InstanceManipulator
      form={
        <JobReportMeasurementProfileForm
          header={
            mode === InstanceManipulator.MODE_CREATE
              ? window.gettext("Create profile")
              : window.gettext("Edit profile")
          }
          submitButtonText={
            mode === InstanceManipulator.MODE_CREATE
              ? window.gettext("Create profile")
              : window.gettext("Save profile")
          }
        />
      }
      mode={mode || InstanceManipulator.MODE_CREATE}
      service={selfCheckJobMeasurementReportProfileService}
      urlParams={selfCheckJobMeasurementReportProfileServiceParams}
      postCreate={postCreate}
      postUpdate={postUpdate}
    />
  );
}
