import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

export function FactoryChoicesField({ formField, hasError, helperText}) {
  return ({ field }) => {
    return (
      <Stack {...formField.options.props}>
        <FormControl>
          <InputLabel variant="standard">{formField.label}</InputLabel>
          <Select
            {...field}
            variant="standard"
            label={formField.label}
            data-test-id={formField.name}
            error={hasError}
            required={formField.required}
          >
            <MenuItem
              key={-1}
              value={""}
              disabled={!(formField.options?.blankOptionEnabled === true)}
            >
              {formField.options.blankOptionLabel || (formField.options?.blankOptionEnabled === true ? window.gettext('None') : formField.label)}
            </MenuItem>
            {Object.entries(formField.options.choices).map(
              ([name, label], index) => {
                return (
                  <MenuItem key={index} value={name}>
                    {label}
                  </MenuItem>
                );
              }
            )}
          </Select>
        </FormControl>
        {helperText && (
          <>
            <FormHelperText>{helperText}</FormHelperText>
          </>
        )}
      </Stack>
    );
  };
}
