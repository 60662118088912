import { LayoutCard } from "../../../../../../../../layout";
import { useHierarchyRSContext } from "../../../context";
import {
  AccordionCalculatedValues,
  AccordionMeasurements,
} from "../../components/radiator_system";

export function HierarchyEntityRSSystem() {
  const { systemFrozenCalculationValues } = useHierarchyRSContext();

  return (
    <>
      {systemFrozenCalculationValues && (
        <LayoutCard noPadding>
          <AccordionCalculatedValues />
          <AccordionMeasurements />
        </LayoutCard>
      )}
    </>
  );
}
