import BaseService from "../BaseService";

class SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService extends BaseService {
}

export default new SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-opportunity-latest/current-instance/node',
  ]
)