import {
  Assignment,
  AssignmentLate,
  Menu,
  MenuOpen,
} from "@mui/icons-material";
import { Divider, IconButton, Stack } from "@mui/material";

import UserPermission from "../../user/UserPermission";

import { useUser } from "../../../context";
import { useLayoutToolbarControl } from "../../../layout";
import { getUserRepresentation, getUserRepresentationShort } from "../../user";
import { MobileMenuItem } from "./MobileMenuItem";

import logo from "../../../assets/logo/logo.svg";

const LOGO_COLOR = "#f9e31d";

export const MOBILE_MENU_HEIGHT = 48;

export function MobileMenu() {
  const { user } = useUser();

  const toolbarControl = useLayoutToolbarControl();

  return (
    <Stack
      sx={{
        bgcolor: "background.paper",
        borderTop: "1px solid lightgray",
        flexDirection: "row",
        overflow: "scroll",
        width: "100%",
        height: MOBILE_MENU_HEIGHT,
      }}
    >
      <Stack
        sx={{
          bgcolor: "primary.main",
          color: LOGO_COLOR,
          userSelect: "none",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          p: 1,
        }}
      >
        {/* Total size with margin 36px * 36 */}
        <img
          alt="Logo"
          src={logo}
          width={32}
          height={32}
          style={{
            margin: 2,
          }}
        />
      </Stack>

      <Stack
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          px: 4,
        }}
      >
        <Divider orientation="vertical" />

        {/* Account */}
        <MobileMenuItem
          icon={getUserRepresentationShort(user)}
          label={getUserRepresentation(user)}
          to="/account/"
        />

        <Divider orientation="vertical" />

        {/* Projects */}
        <MobileMenuItem
          icon={<Assignment />}
          label={window.gettext("Projects")}
          to="/projects/"
        />

        {/* Issue */}
        <UserPermission permission={UserPermission.permissions.issue_view}>
          <MobileMenuItem
            icon={<AssignmentLate />}
            label={window.gettext("Issues")}
            to="/issues/"
          />
        </UserPermission>

        <Divider orientation="vertical" />
      </Stack>

      {/* Toolbar */}
      {toolbarControl.exist && (
        <IconButton
          onClick={
            toolbarControl.hidden !== false
              ? toolbarControl.show
              : toolbarControl.hide
          }
          sx={{
            width: 64,
          }}
        >
          {toolbarControl.hidden !== false ? <Menu /> : <MenuOpen />}
        </IconButton>
      )}
    </Stack>
  );
}
