import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import JobContext from "../../../../../../../../../../../../../components/project/self_check/job/JobContext";
import ReportInstallationProfileContext from "../../../../../../../../../../../../../components/project/self_check/job/ReportInstallationProfileContext";
import InstallationReport from "../../../../../../../../../../../../../components/project/self_check/job/report/installation/InstallationReport";
import JobInstallationReportContext from "../../../../../../../../../../../../../components/project/self_check/job/report/installation/JobInstallationReportContext";
import ReloadInstallationReportButton from "../../../../../../../../../../../../../components/project/self_check/job/report/installation/ReloadInstallationReportButton";
import ProjectService from "../../../../../../../../../../../../../components/services/ProjectService";

import { LayoutView } from "../../../../../../../../../../../../../layout";

export default function JobInstallationReportView() {
  const { project_id } = useParams();

  const [project, setProject] = useState(null);

  useEffect(fetchProject, [project_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Installation report")}
      // submenuItems={<ReloadInstallationReportButton />}
    >
      <JobContext>
        <JobInstallationReportContext>
          <ReportInstallationProfileContext>
            <InstallationReport />
          </ReportInstallationProfileContext>
        </JobInstallationReportContext>
      </JobContext>
    </LayoutView>
  );
}
