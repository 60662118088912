import React from "react";
import SelfCheckJobMeasurementOpportunityService
  from "../../../../../services/measurement/SelfCheckJobMeasurementOpportunityService";
import BasicModal from "../../../../../layout/BasicModal";
import InstanceManipulator from "../../../../../InstanceManipulator";
import MeasurementOpportunityForm from "./MeasurementOpportunityForm";
import {useSnackbar} from "notistack";

export default function EditMeasurementOpportunityModal(props) {
  let {projectId, systemId, jobId, measurementId, opportunity} = props

  const {enqueueSnackbar} = useSnackbar()

  const saveOpportunity = () => {
    SelfCheckJobMeasurementOpportunityService.fetch(
      projectId,
      systemId,
      jobId,
      opportunity.measurement_id,
      opportunity.id,
    ).then(
      (response) => {
        if (props.onChanged !== undefined) {
          props.onChanged(response)
        }
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext('Error while trying to save measurement opportunity'),
          {
            variant: 'error'
          }
        )
      }
    )
  }

  const postUpdate = (changedOpportunity) => {
    if (props.onChanged !== undefined) {
      props.onChanged(changedOpportunity)
    }
  }

  return (
    <>
      <BasicModal
        width={'80%'}
        open={opportunity !== undefined}
        onClose={props.onClose}
      >
        <InstanceManipulator
          instance={opportunity}
          service={SelfCheckJobMeasurementOpportunityService}
          postUpdate={postUpdate}
          urlParams={
            [
              projectId,
              systemId,
              jobId,
              measurementId,
              opportunity?.id,
            ]
          }
          mode="edit"
          form={
            <MeasurementOpportunityForm
              submitButtonText={window.gettext("Save measurement opportunity")}
            />
          }
        />
      </BasicModal>
    </>
  )
}