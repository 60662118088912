import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import AutocompleteRadiatorSearchInput from "../../edit_cell/AutocompleteRadiatorSearchInput";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function RadiatorColumnDefinition(nodes) {
  return {
    field: 'radiator',
    width: getColumnWidth('radiator'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Radiator'),
    editable: true,
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('radiator', 'node.item.type.name'),
        ]
      ).get(params.row) || null
    }),
    renderEditCell: (params) => {
      const activeRadiators = nodes.filter(
        (node) => {
          return node.type === 'radiator'
        }
      ).map(
        (node) => {
          return node.item.type
        }
      )

      return <AutocompleteRadiatorSearchInput
        {...params}
        activeRadiators={activeRadiators}
        selectedOptionId={params.row.node.item.type_id}
        label={window.gettext('Radiator')}
      />
    }
  }
}
