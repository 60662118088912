import AddCircleIcon from "@mui/icons-material/AddCircle";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import GridRowAction, {
  ACTION_COPY_NODE,
  ACTION_CREATE_PIPE,
  ACTION_CREATE_RADIATOR,
  ACTION_CREATE_VALVE,
  ACTION_DELETE_NODE,
  ACTION_SET_FLAG,
} from "./GridRowAction";
import { CopyNodeButton } from "./action_buttons/CopyNodeButton";
import { CopyNodeFromButton } from "./action_buttons/CopyNodeFromButton";
import { CreateRadiatorButton } from "./action_buttons/CreateRadiatorButton";
import { DeleteNodeButton } from "./action_buttons/DeleteNodeButton";
import { RemoveFlagsNodeButton } from "./action_buttons/RemoveFlagsNodeButton";

const SUBMENU_COPY_NODE = "copy_node";
const SUBMENU_DELETE_NODE = "delete_node";
const SUBMENU_CREATE_RADIATOR = "create_radiator";

export function GridRowActionButton(props) {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [menuStatus, setMenuStatus] = useState(false);
  const [row, setRow] = useState(undefined);
  const [nodes, setNodes] = useState(undefined);

  const [submenuCopyNodeOpen, setSubmenuCopyNodeOpen] = useState(false);
  const [submenuDeleteNodeOpen, setSubmenuDeleteNodeOpen] = useState(false);
  const [submenuCreateRadiatorOpen, setSubmenuCreateRadiatorOpen] =
    useState(false);

  const menuItemDeleteRef = useRef();
  const menuItemCopyRef = useRef();
  const menuItemCreateRadiatorRef = useRef();

  useEffect(() => {
    if (props.cellValues.row === undefined) {
      console.error("Could not find row on cellValues.");
    }

    setRow(props.cellValues.row);
  }, [props.cellValues]);

  useEffect(() => {
    setNodes(props.nodes);
  }, [props.nodes]);

  useEffect(() => {
    if (row === undefined) {
      return;
    }

    props.toggleMenuProxy.register(row.id, callbackFunctionInRowActionButton);
  }, [props.toggleMenuProxy, row]);

  const callbackFunctionInRowActionButton = function (event) {
    console.log("yepp... current state; ", menuStatus);
    setMenuStatus(!menuStatus);
    setUserMenuAnchorEl(event.target);
  };

  const dispatchAction = function (action_type, extra_data) {
    console.log("dispatchAction", action_type, extra_data, row);

    props.actionCallbackFunction(
      new GridRowAction(action_type, row, extra_data || {}, (payload) => {
        // Set focus on row
        /*
          const coordinates = {
            rowIndex: payload.action.row.rowIndex,
            colIndex: 3
          }
          props.cellValues.api.scrollToIndexes(coordinates)
           */

        //props.cellValues.api.scrollToIndexes(payload.focusRowId, props.cellValues.field)

        setTimeout(() => {
          // Does not work atm
          props.cellValues.api.setCellFocus(
            payload.focusRowId,
            props.cellValues.field
          );
        }, 100);
      })
    );

    handleCloseMenu();
  };

  const handleActionButtonOnClickEvent = function (event, actionDataOverride) {
    let actionType = event.target.dataset.actionType;
    let actionData = event.target.dataset.actionData;

    if (actionType !== undefined) {
      actionType = parseInt(actionType, 10);
    }

    if (actionDataOverride !== undefined) {
      actionData = actionDataOverride;
    }

    dispatchAction(actionType || null, actionData);
  };

  const handleCloseMenu = function () {
    setMenuStatus(false);
  };

  const handleMenuButtonClick = function (event) {
    console.log("menuButtonClick", event.target.parentElement);
    handleMenuItemDispatchableEvent(event.target.parentElement, event);
  };

  /**
   * When we have an event that is dispatchable, a click or a keydown we fire this function
   * with the target-element as first argument, will then dispatch the proper action
   *
   * @param target
   */
  const handleMenuItemDispatchableEvent = function (target, event) {
    const actionId = target.dataset.actionId;
    let actionData = target.dataset.actionData;

    if (actionData !== undefined) {
      actionData = JSON.parse(actionData);
    }

    console.log(actionId, actionData);

    if (actionId === undefined) {
      console.log("No action id, aborting, do not dispatch.");
      return;
    }

    dispatchAction(parseInt(actionId), actionData);
  };

  const handleMenuListKeyDown = function (event) {
    console.log("menuItemKeydown", event);
    if (event.type === "keydown") {
      // Trigger the same action as if we would be clicking the button
      if (event.code === "Enter") {
        handleMenuItemDispatchableEvent(event.target, event);
      }

      // Close menu on Esc
      if (event.code === "Escape") {
        setMenuStatus(false);

        // Setting focus on the action cell, very hacky way though.
        // todo: fix this cluster of a hack
        setTimeout(() => {
          console.log("wanting to set cell focus", row.id);
          const otherRow = props.cellValues.api.getAllRowIds().find((rowId) => {
            return row.id !== rowId;
          });

          props.cellValues.api.setCellFocus(otherRow, "actions");
          props.cellValues.api.setCellFocus(row.id, "actions");
        }, 500);
        event.preventDefault();
        event.stopPropagation();
      }

      // Delete node?
      if (event.code === "Delete" || event.code === "Backspace") {
        console.log("Is delete/backspace, perhaps should delete?");
      }

      if (event.code === "ArrowRight") {
        console.log("perhaps open submenu?");
        if (event.target.dataset.submenu === SUBMENU_COPY_NODE) {
          console.log("open submentu copy node");
          setSubmenuCopyNodeOpen(true);
        }
        if (event.target.dataset.submenu === SUBMENU_DELETE_NODE) {
          console.log("open submentu delete node");
          setSubmenuDeleteNodeOpen(true);
        }
        if (event.target.dataset.submenu === SUBMENU_CREATE_RADIATOR) {
          setSubmenuCreateRadiatorOpen(true);
        }

        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  const handleToggleMenuButtonClick = function (event) {
    setUserMenuAnchorEl(event.currentTarget);
    setMenuStatus(!menuStatus);
  };

  return (
    <>
      {row === undefined ? (
        <></>
      ) : (
        <>
          {/* Button to click to open the menu */}
          <IconButton
            tabIndex={1}
            onClick={handleToggleMenuButtonClick}
            data-test-id={"grid-row-action-button-" + row.node.id}
          >
            <MenuIcon />
          </IconButton>

          {/* Menu container*/}
          <Popper
            open={menuStatus}
            anchorEl={userMenuAnchorEl}
            role={undefined}
            placement="right"
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: "left",
                }}
              >
                <Paper
                  data-test-id={"grid-row-action-menu-container-" + row.node.id}
                  style={{ zIndex: 99 }}
                >
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList
                      autoFocusItem={menuStatus}
                      id="grid-row-action-menu"
                      data-node-id={String(row.node.id)}
                      data-row-id={String(row.id)}
                      data-test-id="grid-row-action-menu"
                      onKeyDown={(e) => {
                        console.log("menu list key down", e);
                        handleMenuListKeyDown(e);
                        e.stopPropagation();
                      }}
                    >
                      {/* Create pipe */}
                      <MenuItem data-action-id={ACTION_CREATE_PIPE}>
                        <Button
                          variant="contained"
                          color="primary"
                          data-test-id="grid-row-new-pipe-button"
                          startIcon={<AddCircleIcon />}
                          onClick={handleMenuButtonClick}
                        >
                          {window.gettext("New pipe")}
                        </Button>
                      </MenuItem>

                      {/* Create pipe with prefix */}
                      <MenuItem
                        data-action-id={ACTION_CREATE_PIPE}
                        data-action-data={'{"name_prefix": "Stam"}'}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          data-test-id="grid-row-new-prefixed-pipe-button"
                          startIcon={<AddCircleIcon />}
                          onClick={handleMenuButtonClick}
                        >
                          {window.gettext("New stem")}
                        </Button>
                      </MenuItem>

                      {/* Create radiator */}
                      <MenuItem
                        data-action-id={ACTION_CREATE_RADIATOR}
                        data-submenu={SUBMENU_CREATE_RADIATOR}
                        ref={menuItemCreateRadiatorRef}
                      >
                        <CreateRadiatorButton
                          onClickCallback={handleActionButtonOnClickEvent}
                          dispatchActionCallback={dispatchAction}
                          open={submenuCreateRadiatorOpen}
                          openChangedCallback={(newState) => {
                            setSubmenuCreateRadiatorOpen(newState);
                            if (
                              newState === false &&
                              submenuCreateRadiatorOpen === true
                            ) {
                              menuItemCreateRadiatorRef.current.focus();
                            }
                          }}
                          row={row}
                        />
                      </MenuItem>

                      {/* Create valve */}
                      <MenuItem data-action-id={ACTION_CREATE_VALVE}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={<AddCircleIcon />}
                          data-test-id="grid-row-new-valve-button"
                          onClick={handleMenuButtonClick}
                        >
                          {window.gettext("New valve")}
                        </Button>
                      </MenuItem>

                      {/* Copy node*/}
                      <MenuItem
                        data-action-id={ACTION_COPY_NODE}
                        data-submenu={SUBMENU_COPY_NODE}
                        ref={menuItemCopyRef}
                      >
                        <CopyNodeButton
                          onClickCallback={handleActionButtonOnClickEvent}
                          dispatchActionCallback={dispatchAction}
                          nodes={nodes}
                          open={submenuCopyNodeOpen}
                          openChangedCallback={(newState) => {
                            // todo: here we should set focus on the copy menu item.
                            console.log("enwState", newState);
                            setSubmenuCopyNodeOpen(newState);
                            if (
                              newState === false &&
                              submenuCopyNodeOpen === true
                            ) {
                              console.log("try to set focus on menuitem");
                              menuItemCopyRef.current.focus();
                            }
                          }}
                          row={row}
                        />
                      </MenuItem>

                      {/* Copy node from */}
                      <MenuItem data-action-id={ACTION_COPY_NODE}>
                        <CopyNodeFromButton
                          dispatchActionCallback={dispatchAction}
                          nodes={nodes}
                          onClickCallback={handleActionButtonOnClickEvent}
                          row={row}
                        />
                      </MenuItem>

                      {/* Flag node */}
                      <MenuItem data-action-id={ACTION_SET_FLAG}>
                        <RemoveFlagsNodeButton
                          dispatchActionCallback={dispatchAction}
                        />
                      </MenuItem>

                      {/* Delete node*/}
                      <MenuItem
                        data-action-id={ACTION_DELETE_NODE}
                        data-submenu={SUBMENU_DELETE_NODE}
                        ref={menuItemDeleteRef}
                      >
                        <DeleteNodeButton
                          onClickCallback={handleActionButtonOnClickEvent}
                          dispatchActionCallback={dispatchAction}
                          open={submenuDeleteNodeOpen}
                          openChangedCallback={(newState) => {
                            // todo: here we should set focus on the copy menu item.
                            console.log("enwState", newState);
                            setSubmenuDeleteNodeOpen(newState);
                            if (
                              newState === false &&
                              submenuDeleteNodeOpen === true
                            ) {
                              console.log("try to set focus on menuitem");
                              menuItemDeleteRef.current.focus();
                            }
                          }}
                          row={row}
                        />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </>
  );
}
