import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import {Tooltip} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import EditValve from "../../edit_cell/EditValve";
import React from "react";
import getRowClassName from "../GetRowClassName";
import getColumnWidth from "../GetColumnWidth";

export default function ValveTypeColumnDefinition(
  valveTypes,
  radiatorValveTypes,
) {
  return {
    field: 'valve_type',
    width: getColumnWidth('valve_type'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Valve type'),
    editable: true,
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('valve', 'node.item.type.name.name'),
          ValueGetterConf('radiator', 'node.item.valve_type.name'),
        ]
      ).get(params.row) || null
    }),
    renderCell: (params) => {
      if (params.row.node.type === 'radiator') {
        return (
          <span>{params.value}</span>
        )
      }
      // If this is not a valve node, return empty
      if (params.row.node.type !== 'valve') {
        return <></>
      }

      // If we should show the checkbox
      let show_checkbox = false

      // If the checkbox is checked
      let is_checked;

      // If this is a valve, show checkbox and determine checked if it is treated as a loop
      if (params.row.node.type === 'valve') {
        show_checkbox = true;
        is_checked = params.row.node.item.loop === true
      }

      // Render Checkbox-icon (inside tooltip) And value (valve type name)
      return (
        <>
          {show_checkbox === true && (
            <Tooltip title={window.gettext('Treat as sub loop')}>
              {is_checked === true ? (
                <CheckBoxIcon fontSize="small"/>
              ) : (
                <CheckBoxOutlineBlankIcon fontSize="small"/>
              )}
            </Tooltip>
          )}
          <span>{params.value}</span>
        </>
      )
    },
    renderEditCell: EditValve(
      valveTypes,
      radiatorValveTypes,
    )
  }
}