import { Stack } from "@mui/material";
import { useEffect, useState } from "react";

import SelfCheckProjectJobService from "../../../services/SelfCheckProjectJobService";
import UserPermission from "../../../user/UserPermission";

import {
  ProjectDetailSelfCheckTabJobSpecs,
  ProjectDetailSelfCheckTabJobs,
} from "./components";

export function ProjectDetailSelfCheckTabContent(props) {
  const [project, setProject] = useState(null);

  const [jobs, setJobs] = useState(null);

  useEffect(() => {
    if (!props.project) {
      return;
    }
    if (!props.project.id) {
      return;
    }

    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    if (!project) {
      return;
    }

    fetchProjectJobs();
  }, [project]);

  function fetchProjectJobs() {
    SelfCheckProjectJobService.list(project.id).then(setJobs);
  }

  return (
    <>
      {project !== null && project.id !== null && (
        <Stack
          sx={{
            width: "100%",
            maxWidth: 1200,
          }}
        >
          {/* Self check */}
          <UserPermission permission={UserPermission.permissions.job_view}>
            <ProjectDetailSelfCheckTabJobs jobs={jobs} project={project} />
          </UserPermission>

          {/* Job specifications */}
          <UserPermission permission={UserPermission.permissions.job_spec_view}>
            <ProjectDetailSelfCheckTabJobSpecs
              project={project}
              fetchProjectJobs={fetchProjectJobs}
            />
          </UserPermission>
        </Stack>
      )}
    </>
  );
}
