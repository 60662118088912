import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import LoadingIndicator from "../../layout/LoadingIndicator";
import getProjectRepresentation from "../../project/getProjectRepresentation";
import ProjectService from "../../services/ProjectService";
import SystemService from "../../services/SystemService";

/* This is not used at the moment, code to make it work might be in BuildTable */
export default function CopyNodeFromDialog({
  closeDialogCallback,
  onClickImport,
  row,
}) {
  const [projects, setProjects] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(null);

  const [systems, setSystems] = useState(undefined);
  const [selectedSystem, setSelectedSystem] = useState(null);

  const [nodes, setNodes] = useState(undefined);
  const [selectedNode, setSelectedNode] = useState(null);

  const [keepNames, setKeepNames] = useState(false);
  const [replace, setReplace] = useState("");
  const [replaceWith, setReplaceWith] = useState("");

  useEffect(fetchProjects, []);
  useEffect(fetchSystems, [selectedProject]);
  useEffect(fetchNodes, [selectedSystem]);

  function fetchNodes() {
    if (selectedProject === null || selectedSystem === null) {
      if (nodes !== undefined) {
        setNodes(undefined);
      }

      return;
    }

    SystemService.nodes(selectedProject.id, selectedSystem.id).then(setNodes);
  }

  function fetchProjects() {
    ProjectService.list().then(setProjects);
  }

  function fetchSystems() {
    if (selectedProject === null) {
      if (systems !== undefined) {
        setSystems(undefined);
      }

      return;
    }

    SystemService.list(selectedProject.id).then(setSystems);
  }

  function handleOnClickBack() {
    if (selectedNode !== null) {
      setSelectedNode(null);
    }

    if (selectedSystem !== null) {
      setSelectedSystem(null);
    } else if (selectedProject !== null) {
      setSelectedProject(null);
    }
  }

  function handleOnClickImport() {
    onClickImport(
      selectedProject,
      selectedSystem,
      selectedNode,
      keepNames,
      replace,
      replaceWith
    );
  }

  function handleOnClickNode(node) {
    setSelectedNode(node);
  }

  function handleOnClickProject(project) {
    setSelectedProject(project);
  }

  function handleOnClickSystem(system) {
    setSelectedSystem(system);
  }

  function handleClose() {
    // Time out to ensure the dialog is closed before resetting the state.
    setTimeout(() => {
      setSelectedProject(null);
      setSelectedSystem(null);
      setSelectedNode(null);
    }, 100);

    closeDialogCallback();
  }

  return (
    <Dialog open={row !== undefined} onClose={handleClose}>
      <DialogTitle>
        {window.gettext("Import node to")} {row?.node.name}
      </DialogTitle>

      <DialogContent
        sx={{
          width: 600,
        }}
      >
        <DialogContentText>
          <Typography variant="body">
            {window.gettext("Import a node and all its children")}
          </Typography>
        </DialogContentText>
        <List
          sx={{
            overflowY: "scroll",
            maxHeight: 800,
            height: "70vh",
          }}
        >
          {projects === undefined ||
          (selectedProject !== null && systems === undefined) ||
          (selectedSystem !== null && nodes === undefined) ? (
            // Loading
            <LoadingIndicator />
          ) : selectedSystem !== null ? (
            // List of job specs
            nodes.map((node) => {
              return (
                <ListItemButton
                  key={node.id}
                  onClick={() => {
                    handleOnClickNode(node);
                  }}
                >
                  <Checkbox
                    checked={
                      (selectedNode && node.id === selectedNode.id) || false
                    }
                  />
                  {node.name}
                </ListItemButton>
              );
            })
          ) : selectedProject !== null ? (
            // List of job specs
            systems.map((system) => {
              return (
                <ListItemButton
                  key={system.id}
                  onClick={() => {
                    handleOnClickSystem(system);
                  }}
                >
                  {system.name}
                </ListItemButton>
              );
            })
          ) : (
            // List of projects
            projects.map((project) => {
              return (
                <ListItemButton
                  key={project.id}
                  onClick={() => {
                    handleOnClickProject(project);
                  }}
                >
                  {getProjectRepresentation(project)}
                </ListItemButton>
              );
            })
          )}
        </List>
      </DialogContent>

      <DialogActions
        sx={{
          flexDirection: "column",
          alignItems: "stretch",
          gap: 1,
        }}
      >
        <Stack
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            gap: 1,
          }}
        >
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Checkbox
              checked={keepNames}
              onChange={(e) => setKeepNames(e.target.checked)}
            />

            <Typography variant="body">
              {window.gettext("Keep names")}
            </Typography>
          </Stack>

          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
              pl: 1,
            }}
          >
            <Typography variant="body">{window.gettext("Replace")}</Typography>

            <TextField
              size="small"
              variant="standard"
              value={replace}
              onChange={(e) => setReplace(e.target.value)}
            />

            <Typography variant="body">{window.gettext("with")}</Typography>

            <TextField
              size="small"
              variant="standard"
              value={replaceWith}
              onChange={(e) => setReplaceWith(e.target.value)}
            />
          </Stack>
        </Stack>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {selectedProject !== null ? (
            // Back button
            <Button variant="outlined" onClick={handleOnClickBack}>
              {window.gettext("Back")}
            </Button>
          ) : (
            // Cancel button
            <Button variant="contained" color="error" onClick={handleClose}>
              {window.gettext("Cancel")}
            </Button>
          )}
          <Button
            onClick={handleOnClickImport}
            disabled={!selectedNode}
            variant="contained"
          >
            {window.gettext("Import")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
