import { Form, FormFactory } from "../../../../form";

export function SystemBuildPotableWaterBulkCreateEntitiesForm({
  onSubmit,
  header = window.gettext("Bulk add apartment entities"),
  submitButtonText = window.gettext("Add entities"),
}) {
  const formFactory = new FormFactory();

  const formSections = [
    formFactory.section(window.gettext("Kitchen faucet"), [
      formFactory.integerField(
        "kitchen_faucets",
        window.gettext("Count"),
        true,
        0
      ),
    ]),
    formFactory.section(window.gettext("Bathroom faucet"), [
      formFactory.integerField(
        "bathroom_faucets",
        window.gettext("Count"),
        true,
        0
      ),
    ]),
    formFactory.section(window.gettext("Shower"), [
      formFactory.integerField("showers", window.gettext("Count"), true, 0),
    ]),
    formFactory.section(window.gettext("Toilet"), [
      formFactory.integerField("toilets", window.gettext("Count"), true, 0),
    ]),
  ];

  return (
    <Form
      header={header}
      formSections={formSections}
      onSubmit={onSubmit}
      submitButtonText={submitButtonText}
    />
  );
}
