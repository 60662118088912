import CellFocusInEventBinder from "./events/CellFocusIn";
import CellKeyDownEventBinder from "./events/CellKeyDown";

export default function BindEvents(
  apiRef,
) {
  // Cell focus, jump right into edit mode
  let unsubscribe_events = []

  unsubscribe_events.push(CellFocusInEventBinder(apiRef))
  unsubscribe_events.push(CellKeyDownEventBinder(apiRef))

  // unsubscribe function
  return function () {
    // Go through all the unsubscribe events
    for(let i = 0; i < unsubscribe_events.length; i += 1){
      unsubscribe_events[i]()
    }
  }
}