import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueSetter from "../../ValueSetter";
import Pressure from "../../../../values/Pressure";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function ExactResistanceColumnDefinition() {
  return {
    field: 'exact_resistance',
    width: getColumnWidth('exact_resistance'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Exact pressure drop'),
    editable: true,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row, 'node.item.exact_resistance'
      )
    }),
    valueSetter: ValueSetter('node.item.exact_resistance'),
    renderCell: (params) => {
      return <Pressure value={params.value} unit="pa"/>
    }
  }
}
