import { Tune } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useCallback } from "react";

import IssueListFiltersForm from "./IssueListFiltersForm";

import { CenteredModal, useModalHandler } from "../layout/modal";

export default function IssueListFiltersButton() {
  const modalHandler = useModalHandler();

  const handleSubmit = useCallback(() => {
    modalHandler.close();
  }, [modalHandler]);

  return (
    <>
      <Button
        onClick={modalHandler.open}
        startIcon={<Tune />}
        variant="contained"
      >
        {window.gettext("Filters")}
      </Button>

      <CenteredModal modalHandler={modalHandler} width="100%">
        <IssueListFiltersForm onSubmit={handleSubmit} />
      </CenteredModal>
    </>
  );
}
