import {
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

// Toolbar above table
export default function CustomGridToolbar(){
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={
        {
          'delimiter': ';', // Cell delimiter
          'fileName': 'DRP-Export',
          'utf8WithBom': true,
        }
      }/>
    </GridToolbarContainer>
  )
}