import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../../../components/layout/LoadingIndicator";
import ProjectService from "../../../../../../../../components/services/ProjectService";
import SystemService from "../../../../../../../../components/services/SystemService";
import UserPermission from "../../../../../../../../components/user/UserPermission";

import { ImportCSVPotableWaterSystem } from "../../../../../../../../components/import/csv";
import {
  CenteredModal,
  useModalHandler,
} from "../../../../../../../../components/layout/modal";
import { LocationTreeService } from "../../../../../../../../components/services";
import {
  SystemBuildPotableWaterBulkCreateEntitiesModal,
  SystemBuildPotableWaterTable,
} from "../../../../../../../../components/system/potable_water";
import { LayoutCard, LayoutView } from "../../../../../../../../layout";

export default function SystemBuildTablePotableWaterView() {
  const { system_id, project_id } = useParams();

  const bulkCreateEntitiesModalHandler = useModalHandler();
  const importApartmentModalHandler = useModalHandler();

  const [locationData, setLocationData] = useState(null);
  const [system, setSystem] = useState(null);
  const [project, setProject] = useState(null);

  useEffect(() => {
    LocationTreeService.list(project_id, system_id, "?level=0").then(
      setLocationData
    );
  }, [project_id, system_id]);

  useEffect(() => {
    ProjectService.fetch(project_id).then(setProject);
  }, [project_id]);

  useEffect(() => {
    SystemService.fetch(project_id, system_id).then(setSystem);
  }, [system_id, project_id]);

  return (
    <>
      <LayoutView
        breadcrumbs={{
          project: project,
          projectTab: 1,
          system: system,
        }}
        header={window.gettext("System Build")}
        submenuItems={
          <UserPermission is_staff>
            <Button
              variant="outlined"
              color="primary"
              onClick={importApartmentModalHandler.open}
              sx={{
                maxWidth: 250,
              }}
            >
              {window.gettext("Import Apartments")}
            </Button>

            <Button
              variant="outlined"
              color="primary"
              onClick={bulkCreateEntitiesModalHandler.open}
              sx={{
                maxWidth: 400,
              }}
            >
              {window.gettext("Create apartment base entities")}
            </Button>
          </UserPermission>
        }
      >
        {!locationData ? (
          <LoadingIndicator />
        ) : (
          <LayoutCard noPadding>
            <SystemBuildPotableWaterTable locationData={locationData} />
          </LayoutCard>
        )}
      </LayoutView>

      {/* Import Apartment Modal */}
      <CenteredModal modalHandler={importApartmentModalHandler}>
        <ImportCSVPotableWaterSystem system_id={system_id} />
      </CenteredModal>

      {/* Bulk Create Entities Modal */}
      <SystemBuildPotableWaterBulkCreateEntitiesModal
        modalHandler={bulkCreateEntitiesModalHandler}
        project_id={project_id}
        system_id={system_id}
      />
    </>
  );
}
