import { useState } from "react";

export function useModalHandler(
  initOpen = false,
  onClose = undefined,
  onCancel = undefined,
  onOpen = undefined
) {
  const [isOpen, setIsOpen] = useState(initOpen);

  function open() {
    if (onOpen) {
      onOpen();
    }
    
    setIsOpen(true);
  }

  function cancel() {
    if (onCancel) {
      onCancel();
    }

    close();
  }

  function close() {
    if (onClose) {
      onClose();
    }

    setIsOpen(false);
  }

  return {
    open,
    cancel,
    close,
    isOpen,
  };
}
