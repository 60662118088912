import { Collapse, List } from "@mui/material";

import { LayoutCard } from "../../../../../layout";
import { ListItemJobSpec, ListItemTaskSpec } from "./list_items";

export function JobSpecContent({
  jobSpec,
  onDeleteTaskItemSpecIconClick,
  onDeleteTaskSpecIconClick,
  project,
  postCreate,
  postUpdate,
  sortSuccessCallback,
}) {
  return (
    <LayoutCard>
      {/* Job spec */}
      <ListItemJobSpec
        jobSpec={jobSpec}
        project={project}
        postCreate={postCreate}
      />

      {/* Task specs for this job spec */}
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" sx={{ pl: 4 }}>
          {jobSpec.task_specs.map((taskSpec, index) => (
            <ListItemTaskSpec
              key={taskSpec.id}
              index={index}
              jobSpec={jobSpec}
              onDeleteTaskItemSpecIconClick={onDeleteTaskItemSpecIconClick}
              onDeleteTaskSpecIconClick={onDeleteTaskSpecIconClick}
              project={project}
              postCreate={postCreate}
              postUpdate={postUpdate}
              sortSuccessCallback={sortSuccessCallback}
              taskSpec={taskSpec}
            />
          ))}
        </List>
      </Collapse>
    </LayoutCard>
  );
}
