import { Stack } from "@mui/material";

import { useLayoutPlacement, useLayoutSize } from "../../hooks";

export function LayoutAppPlacement({ children, padding = 1 }) {
  const layoutPlacement = useLayoutPlacement();

  const layoutSize = useLayoutSize();

  return (
    <Stack
      className="App"
      sx={{
        boxSizing: "border-box",
        position: "fixed",
        height: "100%",
        width: "100vw",
        maxWidth: "100vw",
        flexDirection: "row",
        alignItems: "stretch",
        "@media print": {
          position: "relative",
        },
      }}
    >
      {/* Left */}
      <Stack
        ref={layoutSize.leftRef}
        sx={{
          position: "relative",
          displayPrint: "none",
          ...(layoutPlacement.leftHidden === false
            ? { p: padding, pr: 0 }
            : { display: "none" }),
        }}
      >
        {layoutPlacement.leftElement}
      </Stack>

      {/* Middle column */}
      <Stack
        sx={{
          boxSizing: "border-box",
          flexGrow: 1,
          width: 0,
        }}
      >
        {/* Top */}
        <Stack
          ref={layoutSize.topRef}
          sx={{
            position: "relative",
            displayPrint: "none",
            ...(layoutPlacement.topHidden === false
              ? { p: padding, pb: 0 }
              : { display: "none" }),
          }}
        >
          {layoutPlacement.topElement}
        </Stack>

        {/* Main */}
        <Stack
          ref={layoutSize.mainRef}
          sx={{
            position: "relative",
            boxSizing: "border-box",
            flexGrow: 1,
            height: 0,
            width: "100%",
          }}
        >
          {children}
        </Stack>

        {/* Bottom */}
        <Stack
          ref={layoutSize.bottomRef}
          sx={{
            position: "relative",
            displayPrint: "none",
            ...(layoutPlacement.bottomHidden === false
              ? { p: padding, pt: 0 }
              : { display: "none" }),
          }}
        >
          {layoutPlacement.bottomElement}
        </Stack>
      </Stack>

      {/* Right */}
      <Stack
        ref={layoutSize.rightRef}
        sx={{
          position: "relative",
          displayPrint: "none",
          ...(layoutPlacement.rightHidden === false
            ? { p: padding, pl: 0 }
            : { display: "none" }),
        }}
      >
        {layoutPlacement.rightElement}
      </Stack>
    </Stack>
  );
}
