import SelfCheckGridViewButtonLink from "../../../../../self_check/job/utils/SelfCheckGridViewButtonLink";
import SelfCheckHierarchyButtonLink from "../../../../../self_check/job/utils/SelfCheckHierarchyButtonLink";
import SelfCheckJobCalculationReportButtonLink from "../../../../../self_check/job/utils/SelfCheckJobCalculationReportButtonLink";
import SelfCheckJobInstallationReportButtonLink from "../../../../../self_check/job/utils/SelfCheckJobInstallationReportButtonLink";
import SelfCheckJobMeasurementReportButtonLink from "../../../../../self_check/job/utils/SelfCheckJobMeasurementReportButtonLink";

export function ProjectDetailSelfCheckTabJobRSButtons({ job }) {
  return (
    <>
      <SelfCheckGridViewButtonLink job={job} />
      <SelfCheckHierarchyButtonLink job={job} />
      <SelfCheckJobCalculationReportButtonLink job={job} />
      <SelfCheckJobMeasurementReportButtonLink job={job} />
      <SelfCheckJobInstallationReportButtonLink job={job} />
    </>
  );
}
