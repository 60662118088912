import Button from "@mui/material/Button";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from "prop-types";

export default function SelfCheckHierarchyButtonLink(props) {
  let {projectId, system, job} = props

  let [dynamicSystem, setDynamicSystem] = useState(null)
  let [dynamicProjectId, setDynamicProjectId] = useState(null)


  useEffect(
    () => {
      if (!job || system || projectId) {
        return
      }

      if (!dynamicSystem) {
        setDynamicSystem(job?.system || null)
      }

      if (!dynamicProjectId) {
        setDynamicProjectId(job?.system?.project_id || null)
      }
    },
    [job, system, projectId]
  )

  // In case we supply system to the job
  useEffect(() => {
    if (!props.system) {
      return
    }

    setDynamicSystem(props.system)
  }, [props.system])

  useEffect(() => {
    if (!props.projectId) {
      return
    }

    setDynamicProjectId(props.projectId)
  }, [props.projectId])

  return (
    <>
      {(job && dynamicSystem && dynamicProjectId) && (
        <>
          <Button
            component={RouterLink}
            to={'/projects/' + String(dynamicProjectId) + '/systems/' + String(dynamicSystem?.id) + '/jobs/' + String(job?.id) + '/hierarchy'}
            endIcon={<AccountTreeIcon/>}
            variant="outlined"
            size={props.size}
            sx={props.sx || {}}
          >
            {window.gettext('Hierarchy view')}
          </Button>
        </>
      )}
    </>

  )
}

SelfCheckHierarchyButtonLink.propTypes = {
  'job': PropTypes.object,
  'system': PropTypes.object, // If the system is not on 'job' then supply it.
  'projectId': PropTypes.number, // If there is no system with project_id on 'job'
}