import AddIcon from "@mui/icons-material/Add";
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import ConfirmableButton from "../../../../../../layout/ConfirmableButton";
import UserPermission from "../../../../../../user/UserPermission";
import CreateMeasurementButton from "../../../../../self_check/job/measurement/CreateMeasurementButton";
import MeasurementsList from "../../../../../self_check/job/measurement/MeasurementsList";

export function ProjectDetailSelfCheckTabJobRSMeasurements({
  disableCopyCalculatedValuesButton,
  job,
  onCopyCalculatedValuesButtonClick,
  project,
}) {
  return (
    <>
      <Divider />

      <Typography
        variant="h4"
        sx={{
          px: 2,
          py: 1,
        }}
      >
        {window.gettext("Measurements")}
      </Typography>

      <CardContent>
        {job.measurements.length === 0 ? (
          <Alert severity={"info"}>
            {window.gettext("No measurements for this job")}
          </Alert>
        ) : (
          <MeasurementsList
            measurements={job.measurements}
            projectId={project.id}
            systemId={job.system.id}
            jobSpecId={job.spec.id}
            jobId={job.id}
          />
        )}
      </CardContent>
      <CardActions>
        <Grid container spacing={2}>
          <Grid item>
            <CreateMeasurementButton job={job} />
          </Grid>
        </Grid>
      </CardActions>
      <UserPermission
        permission={
          UserPermission.permissions
            .job_copy_all_calculated_values_to_installed_values
        }
      >
        <Divider />
        <CardContent>
          <Stack
            sx={{
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Typography variant={"h4"}>
              {window.gettext("Set installed values")}
            </Typography>

            <Stack>
              <ConfirmableButton
                closeOnConfirm
                callbackAttributes={job}
                confirmColor={"warning"}
                confirmIcon={<AddIcon />}
                cancelColor={"lowtone"}
                body={
                  <>
                    {window.gettext(
                      "This will copy all calculated values and set them as installed values for all nodes on the system"
                    )}
                  </>
                }
              >
                <Button
                  startIcon={<AddIcon />}
                  color={"warning"}
                  variant={"contained"}
                  disabled={disableCopyCalculatedValuesButton}
                  onClick={onCopyCalculatedValuesButtonClick}
                >
                  {window.gettext("Create for all nodes")}
                </Button>
              </ConfirmableButton>
              <FormHelperText>
                {window.gettext(
                  "Copy all calculated values and set as installed values"
                )}
              </FormHelperText>
            </Stack>
          </Stack>
        </CardContent>
      </UserPermission>
    </>
  );
}
