import getProjectRepresentation from "./getProjectRepresentation";

import { FetchedLinkText } from "../../ui";

export default function Project({ color, project, variant }) {
  return (
    <FetchedLinkText
      color={color}
      variant={variant}
      to={!project ? undefined : "/projects/" + String(project?.id) + "/"}
    >
      {getProjectRepresentation(project)}
    </FetchedLinkText>
  );
}
