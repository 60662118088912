import React, {useEffect, useState} from "react";
import BasicModal from "../../layout/BasicModal";
import {Alert, Grid, IconButton} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import RadiatorService from "../../services/RadiatorService";
import Flag from "../../flag/Flag";
import {useSnackbar} from "notistack";

import {
  RadiatorSearchField,
  RadiatorValveSearchField,
  SystemRoomSearchField,
} from "./form_fields";

export default function AddSimpleRadiatorModal(
  {
    projectId,
    systemId,
    parentNodeId,
    button,
    entranceShortCode,
    apartmentNumber,
    onCreated,
  }
) {
  const {enqueueSnackbar} = useSnackbar();

  let [radiatorName, setRadiatorName] = useState(undefined);
  let [radiatorNumber, setRadiatorNumber] = useState(undefined);
  let [radiatorValveId, setRadiatorValveId] = useState(undefined);
  let [radiatorTypeId, setRadiatorTypeId] = useState(undefined);
  let [roomId, setRoomId] = useState(undefined);

  let [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (entranceShortCode && apartmentNumber) {
      setRadiatorName(String(entranceShortCode) + '.' + String(apartmentNumber));
    }
  }, [entranceShortCode, apartmentNumber])

  const handleCreateRadiatorClick = () => {
    RadiatorService.create(
      {
        name: (radiatorName) + (radiatorNumber || ''),
        parent_node: parentNodeId,
        flag: String(Flag.flags.add_alert),
        type: {
          'id': radiatorTypeId
        },
        room_id: roomId,
        valve_type: {
          'id': radiatorValveId
        }
      },
      projectId,
      systemId,
    ).then(
      (response) => {
        setModalOpen(false)

        enqueueSnackbar(
          window.gettext('Radiator created') + ': ' + response.node.name,
          {
            variant: 'success',
          }
        )

        if (onCreated) {
          onCreated(response)
        }
      }
    )
  }

  return (
    <>
      <BasicModal
        toggleButton={button || (<IconButton><AddCircleIcon/></IconButton>)}
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <Grid
          direction={"column"}
          container
          spacing={2}
        >
          <Grid item>
            <Typography variant={"h5"}>
              {window.gettext('Add radiator')}
            </Typography>
          </Grid>
          <Grid item>
            <Alert severity={"info"}>
              <p>
                {window.gettext('Create a radiator, will added to the same source')}
              </p>
              {radiatorName && (
                <p>
                  {window.gettext('New radiator name:')} {radiatorName}{radiatorNumber || '??'}
                </p>
              )}
            </Alert>
          </Grid>
          <Grid item>
            <RadiatorSearchField
              label={window.gettext('Radiator')}
              onChange={(radiatorType) => {
                console.log('radiatorType', radiatorType)
                setRadiatorTypeId(radiatorType?.id)
              }}
            />
          </Grid>
          <Grid item>
            <RadiatorValveSearchField
              label={window.gettext('Radiator valve')}
              onChange={
                (radiatorValve) => {
                  console.log('radiatorValve', radiatorValve)
                  setRadiatorValveId(radiatorValve?.id)
                }
              }
            />
          </Grid>
          <Grid item>
            <SystemRoomSearchField
              label={window.gettext('Room')}
              onChange={
                (room) => {
                  console.log('room', room)
                  setRoomId(room?.id)
                }
              }
              projectId={projectId}
              systemId={systemId}
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<AddCircleIcon/>}
              onClick={handleCreateRadiatorClick}
              variant={"contained"}
              disabled={
                (
                  !radiatorTypeId || !radiatorValveId
                )
              }
            >
              {window.gettext('Add radiator')}
            </Button>
          </Grid>
        </Grid>
      </BasicModal>
    </>
  )
}
