import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import Pressure from "../../../../values/Pressure";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function AvailablePressureColumnDefinition() {
  return {
    field: 'available_pressure',
    width: getColumnWidth('available_pressure'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Available pressure'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'calculation.available_pressure'
      )
    }),
    valueFormatter: paramsValueFormatter(0),
    renderCell: (params => {
      return <Pressure value={params.value}/>
    }),
  }
}
