import {useEffect, useState} from "react";
import PropTypes from "prop-types";


export default function EntityType(props) {
  let [displayValue, setDisplayValue] = useState(null)
  let [entityType, setEntityType] = useState(undefined)

  const valve_entity_type_label = window.gettext('Valve')
  const radiator_entity_type_label = window.gettext('Radiator')
  const address_apartment_type_label = window.gettext('Apartment')
  const address_entrance_type_label = window.gettext('Entrance')

  useEffect(() => {
    if (!props.entityType) {
      return
    }
    setEntityType(props.entityType)
  }, [props.entityType])

  useEffect(() => {
    if (!entityType) {
      setDisplayValue(null)
      return
    }

    if (entityType === 'radiator_node') {
      setDisplayValue(radiator_entity_type_label)
      return
    }

    if (entityType === 'valve_node') {
      setDisplayValue(valve_entity_type_label)
      return
    }

    if (entityType === 'address_apartment') {
      setDisplayValue(address_apartment_type_label)
      return
    }

    if (entityType === 'address_entrance') {
      setDisplayValue(address_entrance_type_label)
      return
    }

    setDisplayValue(null)
  }, [entityType])


  return (
    <>
      {(!displayValue) ? (
        <>
          {entityType}
        </>
      ) : (
        <>
          {displayValue}
        </>
      )}
    </>
  )
}


EntityType.propTypes = {
  'entityType': PropTypes.string
}