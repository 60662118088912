import LoadingIndicator from "../layout/LoadingIndicator";
import {Alert, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DateTime from "../values/DateTime";
import {useIssue} from "./IssueContext";
import {useIssueFiles} from "./IssueFileContext";
import IssueFilesService from "../services/IssueFilesService";
import ConfirmableButton from "../layout/ConfirmableButton";
import {useSnackbar} from "notistack";
import {addHideForPrintStyle} from "../layout/HideForPrint";

export default function IssueFileList({}) {
  const {files, refreshIssueFiles} = useIssueFiles()
  const {issue_id} = useIssue()
  const {enqueueSnackbar} = useSnackbar()

  return (
    <>
      {(files === undefined) ? (
        <>
          <LoadingIndicator/>
        </>
      ) : (
        <>
          {files.length === 0 ? (
            <>
              <Alert severity={'info'}>
                {window.gettext("No files found")}
              </Alert>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {window.gettext("File name")}
                      </TableCell>
                      <TableCell>
                        {window.gettext("Created at")}
                      </TableCell>
                      <TableCell>
                        {window.gettext("Created by")}
                      </TableCell>
                      <TableCell>
                        {window.gettext("Description")}
                      </TableCell>
                      <TableCell
                        sx={
                          addHideForPrintStyle()
                        }
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      files.map((file, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              <a href={file.file}>
                                {file.file_name}
                              </a>
                            </TableCell>
                            <TableCell>
                              <DateTime value={file.created}/>
                            </TableCell>
                            <TableCell>
                              {file.created_by_name}
                            </TableCell>
                            <TableCell>
                              {file.description}
                            </TableCell>
                            <TableCell
                              sx={
                                addHideForPrintStyle()
                              }
                            >
                              <ConfirmableButton
                                closeOnConfirm={true}
                              >
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => {
                                    IssueFilesService.destroy(
                                      issue_id,
                                      file.id
                                    ).then(() => {
                                      enqueueSnackbar(
                                        window.gettext("File deleted"),
                                        {
                                          variant: "success",
                                        }
                                      );
                                      refreshIssueFiles()
                                    })
                                  }}
                                >
                                  {window.gettext("Delete")}
                                </Button>
                              </ConfirmableButton>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  )
}