import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import getColumnWidth from "../GetColumnWidth";

export default function NippleColumnDefinition() {
  return {
    field: 'nipple',
    width: getColumnWidth('nipple'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Nipple'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'calculation.nipple_label'
      )
    })
  }
}
