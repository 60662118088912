import { Paper, Stack } from "@mui/material";

import { useLayoutSize, useLayoutToolbar } from "../hooks";
import { useEffect } from "react";

function LayoutToolbarDesktop({ children, sx }) {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "row",
        borderRadius: 2,
        flexDirection: "row",
        gap: 1,
        padding: 1,
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
}

function LayoutToolbarMobile({ children, sx }) {
  const { bottomHeight } = useLayoutSize();

  return (
    <Stack
      sx={{
        bottom: bottomHeight,
        right: 0,
        position: "absolute",
      }}
    >
      <Stack
        sx={{
          backgroundColor: "background.paper",
          border: "1px solid lightgray",
          borderRadius: 2,
          flexDirection: "column",
          flexGrow: 1,
          gap: 1,
          marginBottom: 1,
          marginRight: 1,
          padding: 1,
          ...sx,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}

export function LayoutToolbar({ children, sx }) {
  const toolbar = useLayoutToolbar();
  const { isMobile } = useLayoutSize();

  useEffect(() => {
    const Component = isMobile ? LayoutToolbarMobile : LayoutToolbarDesktop;

    toolbar.set(<Component sx={sx}>{children}</Component>);
  }, [children, toolbar.showToolbar]);

  return null;
}
