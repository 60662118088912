import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from "prop-types";
import GridOnIcon from '@mui/icons-material/GridOn';

export default function SelfCheckGridViewButtonLink(props) {
  let [job, setJob] = useState()
  let [system, setSystem] = useState(null)
  let [projectId, setProjectId] = useState(null)

  useEffect(() => {
    if (!props.job) {
      return
    }

    setJob(props.job)
  }, [props.job])

  useEffect(
    () => {
      if (!job || system || projectId) {
        return
      }

      if (!system) {
        setSystem(job?.system || null)
      }

      if (!projectId) {
        setProjectId(job?.system?.project_id || null)
      }
    },
    [job, system, projectId]
  )

  useEffect(() => {
    if (!props.system) {
      return
    }

    setSystem(props.system)
  }, [props.system])

  useEffect(() => {
    if (!props.projectId) {
      return
    }

    setProjectId(props.projectId)
  }, [props.projectId])

  return (
    <>
      {job && (
        <>
          <Button
            component={RouterLink}
            to={'/projects/' + String(projectId) + '/systems/' + String(system?.id) + '/jobs/' + String(job.id)}
            endIcon={<GridOnIcon/>}
            variant="outlined"
            size={props.size}
            sx={props.sx || {}}
          >
            {window.gettext('Table view')}
          </Button>
        </>
      )}
    </>

  )
}

SelfCheckGridViewButtonLink.propTypes = {
  'job': PropTypes.object,
  'system': PropTypes.object,
  'projectId': PropTypes.number,
}