class EventDataClass {
  event
  params
  apiRef
  details

  constructor(event, params, apiRef, details) {
    this.event = event
    this.params = params
    this.apiRef = apiRef
    this.details = details
  }
}

export default function EventData(
  event,
  params,
  apiRef,
  details,
) {
  return new EventDataClass(event, params, apiRef, details)
}
