import BaseValidator from "../BaseValidator";
import RowsValidationError from "../RowsValidationError";

export default class LeafNodesAreRadiatorValidator extends BaseValidator {
  getLeafNodeRows = (rows) => {
    // go through all rows, find the ones where no rows has it as parent
    return rows.filter(
      (row) => {
        // Find all children of this row
        const children = rows.filter(
          (possibleChildRow) => {
            // If this node has no parent it is not a child of row
            if (possibleChildRow.node.parent === null || possibleChildRow.node.parent === undefined || possibleChildRow.node.parent.id === undefined) {
              return false
            }

            // If ths node has the parent.id same as row, then row is parent
            return possibleChildRow.node.parent.id === row.node.id
          }
        )

        return children.length === 0
      }
    )
  }
  getRowsErrors = (rows) => {
    // If there are no rows this validator is not applicable
    if (rows === undefined || rows.length === 0) {
      return undefined
    }

    const leaf_node_rows = this.getLeafNodeRows(rows)

    const leaf_node_rows_of_unacceptable_types = leaf_node_rows.filter(
      (leaf_node_row) => {
        // Do not care about types in the list, they are cool
        return ["radiator"].includes(leaf_node_row.node.type) === false
      }
    )

    // In case there is no rows of unacceptable types we are good
    if (leaf_node_rows_of_unacceptable_types.length === 0) {
      return undefined
    }

    return [
      new RowsValidationError(
        this.ERROR_TYPE_ROWS,
        window.gettext('All leaf nodes needs to be radiators'),
        leaf_node_rows_of_unacceptable_types
      )
    ]
  }
}