import AutocompleteInput from "../AutocompleteInput";
import React from "react";

// The item type 'Valve'
export default function EditValveOnRadiator(radiatorValveTypes) {
  return (params) => {
    // Transform the opions
    let options = radiatorValveTypes.map((valveType) => {
      return {
        'id': valveType.id,
        'name': valveType.name,
      }
    })

    // Render clickable Checkbox and autocomplete dropdown
    return (
      <>
        <AutocompleteInput
          {...params}
          options={options}
          selectedOptionId={params.row.node.item.valve_type_id}
          label={window.gettext('Valve type')}
        />
      </>
    )
  }
}
