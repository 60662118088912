import React, {useEffect, useState} from "react";
import {ACTION_COPY_NODE} from "../GridRowAction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import CopyNodeDialog from "../CopyNodeDialog";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import {ButtonGroup, ClickAwayListener, Grow} from "@mui/material";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';


const COPY_NODE_BUTTON_DEFAULT = 1
const COPY_NODE_BUTTON_CHOOSE_PARENT = 2

export function CopyNodeButton(props) {
  let [nodes, setNodes] = useState(undefined);
  const anchorRef = React.useRef(null);
  let [copyShowCopyDialogForRow, setCopyShowCopyDialogForRow] = useState(undefined)
  let [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(1);

  const COPY_NODE_BUTTONS = [
    {
      id: COPY_NODE_BUTTON_DEFAULT,
      test_id: "grid-row-copy-node-default-button",
      label: window.gettext('Copy')
    },
    {
      id: COPY_NODE_BUTTON_CHOOSE_PARENT,
      test_id: "grid-row-copy-node-choose-parent-button",
      label: window.gettext('Choose parent')
    },
  ]

  useEffect(() => {
    setNodes(props.nodes)
  }, [props.nodes])

  useEffect(() => {
    if (props.open === undefined) {
      return
    }
    console.log('set open', props.open)
    setOpen(props.open)
  }, [props.open])

  // Default to false open
  useEffect(() => {
    setOpen(false)
  }, [])

  // Call the callback so we have the correct state in the menu
  useEffect(() => {
    props.openChangedCallback(open)
  }, [open])

  // This function is called from within the dialog to select target node
  const handleDialogCopyButton = function (targetNode) {
    props.dispatchActionCallback(
      ACTION_COPY_NODE,
      {
        parent: targetNode
      }
    )
  }

  const handleToggle = (event) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const dispatchButtonAction = function (index) {
    const button = COPY_NODE_BUTTONS[index];

    console.log('handle', index, button);

    if (button.id === COPY_NODE_BUTTON_DEFAULT) {
      props.dispatchActionCallback(
        ACTION_COPY_NODE,
      )
    }

    if (button.id === COPY_NODE_BUTTON_CHOOSE_PARENT) {
      setCopyShowCopyDialogForRow(props.row)
    }
  }

  const handleDefaultButtonClick = (event) => {
    // Default to first button
    dispatchButtonAction(0);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleExtraActionsMenuItemClick = function (event, index) {
    dispatchButtonAction(index)
    setSelectedIndex(index)
    setOpen(false)
  }

  return (
    <>
      <ButtonGroup variant="contained">
        <Button
          data-test-id='grid-row-copy-node-button'
          onClick={handleDefaultButtonClick}
          startIcon={<ContentCopyIcon/>}
        >
          {window.gettext("Copy")}
        </Button>
        {/* Button to trigger the extra alternatives */}
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select copy strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          ref={anchorRef}
        >
          <ArrowRightIcon/>
        </Button>

      </ButtonGroup>

      {/*
      Popup for the extra options
      */}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={
          {
            zIndex: 999
          }
        }
        transition
        disablePortal
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  autoFocusItem={open}
                  onKeyDown={(event) => {
                    if (event.type === 'keydown') {
                      // In case the user wants to back out
                      if (event.code === "ArrowLeft" || event.code === 'Escape') {
                        setOpen(false)
                      }
                    }
                    event.preventDefault()
                    event.stopPropagation()
                  }}

                >
                  {COPY_NODE_BUTTONS.map((option, index) => (
                    <MenuItem
                      key={option.id}
                      data-test-id={option.test_id}
                      selected={index === selectedIndex}
                      onClick={(event) => handleExtraActionsMenuItemClick(event, index)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      {/*
      Dialog for selecting a target node, should be triggered by selecting something like "Copy to specific node"
      */}
      <CopyNodeDialog
        row={copyShowCopyDialogForRow}
        closeDialogCallback={() => {
          setCopyShowCopyDialogForRow(undefined)
        }}
        copyButtonCallback={handleDialogCopyButton}
        nodes={nodes}
      />
    </>
  )
}