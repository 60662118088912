import BaseService from "./BaseService";

class SelfCheckJobSpecService extends BaseService {

}

export default new SelfCheckJobSpecService('/api/',
  [
    'projects',
    'self-check/spec/jobs',
  ]
)