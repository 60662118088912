import BaseFieldValidator from "../BaseFieldValidator";
import {NODE_TYPE_PIPE, NODE_TYPE_RADIATOR} from "../../../data/DataGetter";

export default class RadiatorValveTypeValidator extends BaseFieldValidator {
  fieldName = 'valve_type'
  nodeTypes = [NODE_TYPE_RADIATOR]

  getFieldErrors = (row) => {
    if (row.node.item === null){
      return [
        this.error(
          row,
          window.gettext('Could not find radiator on this node')
        )
      ]
    }

    // If length is null or undefined
    if (row.node.item.valve_type === null || row.node.item.valve_type === undefined) {
      return [
        this.error(
          row,
          window.gettext('Valve type on radiator must be set')
        )
      ]
    }

    return undefined
  }
}