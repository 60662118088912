import { Button, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { CenteredModal } from "./CenteredModal";

export function ConfirmationModal({
  modalHandler,
  open,
  onConfirm,
  onCancel,
  onClose,
  body,
  minWidth = 250,
  minHeight = 150,
  buttonMinWidth = 100,
  title = window.gettext("Are you sure?"),
  confirmText = window.gettext("Yes"),
  confirmColor = "error",
  confirmIcon = <DeleteIcon />,
  cancelText = window.gettext("No"),
  cancelColor = "primary",
}) {
  function handleConfirm() {
    if (onConfirm) {
      onConfirm();
    }

    if (modalHandler) {
      modalHandler.close();
    }
  }

  return (
    <CenteredModal
      modalHandler={modalHandler}
      open={open}
      onClose={onClose || onCancel}
    >
      <Stack
        sx={{
          gap: 2,
          minWidth,
          minHeight,
        }}
      >
        <Typography variant="h4">{title}</Typography>

        <Stack>{body}</Stack>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "end",
            flexGrow: 1,
            gap: 2,
          }}
        >
          <Button
            variant="contained"
            color={confirmColor}
            startIcon={confirmIcon}
            onClick={handleConfirm}
            sx={{
              minWidth: buttonMinWidth,
            }}
          >
            {confirmText}
          </Button>

          <Button
            variant="contained"
            color={cancelColor}
            onClick={modalHandler?.cancel || onCancel}
            sx={{
              minWidth: buttonMinWidth,
            }}
          >
            {cancelText}
          </Button>
        </Stack>
      </Stack>
    </CenteredModal>
  );
}
