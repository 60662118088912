import EventExecutor from "./EventExecutor";
import EventResult from "./EventResult";
import GetAdjacentColumn from "./utils/GetAdjacentColumn";

export default function ShiftTabEvent() {
  return new EventExecutor(
    'shift-tab',
    (eventData) => {
      // If Tab AND shift is pressed down
      return eventData.event.keyCode === 9 && eventData.event.shiftKey === true
    },
    // EventDataClass
    (eventData) => {
      // Get the previous column
      const adjacentColumn = GetAdjacentColumn(eventData, -1)

      // In case we have a proper adjacent columns
      if(adjacentColumn !== undefined){
        eventData.apiRef.current.setCellFocus(eventData.params.id, adjacentColumn.field)
        return EventResult(true)
      }

      return EventResult(true)
    }
  )
}