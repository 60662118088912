import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "./LoadingIndicator";

export default function Loading(props) {
  let [loadingDone, setLoadingDone] = useState(null)
  let [notNull, setNotNull] = useState([])

  useEffect(() => {
    if (!props.notNull) {
      return
    }
    if (props.notNull.length === 0) {
      return
    }

    setNotNull(props.notNull)
  }, [props.notNull])

  useEffect(() => {
    //
    const not_yet_loaded_count = [
      ...notNull.map((prop) => {
        return prop === null
      })
    ].filter(
      (not_yet_loaded) => (not_yet_loaded === true)
    ).length

    // Show only if there are no more things that are not yet loaded
    setLoadingDone(not_yet_loaded_count === 0)
  }, [notNull])

  return (
    <>
      {(loadingDone !== true) ? (
        <LoadingIndicator/>
      ) : (
        <>
          {props.children}
        </>
      )}
    </>
  )
}

Loading.propTypes = {
  'notNull': PropTypes.array,
}