import React from 'react'
import {Table, TableBody, TableContainer, TableRow} from "@mui/material";

import PropTypes from "prop-types";
import DateTime from "../values/DateTime";

export default function HeatCurveName(props) {
  let {heat_curve, if_empty} = props

  // Just a placeholder
  return (
    <>
      {heat_curve ? (
        <>
          <DateTime value={heat_curve?.created}/>
        </>
      ) : (
        <>
          {if_empty}
        </>
      )
      }
    </>
  )
}

HeatCurveName.props = {
  'heat_curve': PropTypes.shape(
    {
      'created': PropTypes.string
    }
  ),
  'if_empty': PropTypes.string,
}