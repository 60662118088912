import BaseService from "./BaseService";

class SelfCheckJobTaskService extends BaseService {}

export default new SelfCheckJobTaskService("/api/", [
  "projects",
  "systems",
  "self-check/jobs",
  "tasks",
]);
