import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import InstanceManipulator from "../../../../../../../InstanceManipulator";

import {
  EntityBathroomFaucetService,
  EntityDishwasherService,
  EntityKitchenFaucetService,
  EntityShowerService,
  EntityToiletService,
  EntityWashingMachineService,
} from "../../../../../../../services";
import {
  ENTITY_TYPE_BATHROOM_FAUCET,
  ENTITY_TYPE_DISHWASHER,
  ENTITY_TYPE_KITCHEN_FAUCET,
  ENTITY_TYPE_SHOWER,
  ENTITY_TYPE_TOILET,
  ENTITY_TYPE_WASHING_MACHINE,
} from "../../../../entity";
import { getDefaultEntityLabel } from "../../../../utils";
import { useHierarchyContext } from "../../../context";
import {
  HierarchyPWSBathroomFaucetForm,
  HierarchyPWSDishwasherForm,
  HierarchyPWSKitchenFaucetForm,
  HierarchyPWSShowerForm,
  HierarchyPWSToiletForm,
  HierarchyPWSWashingMachineForm,
} from "./components";

const DEFAULT_ENTITY_TYPE = "kitchen_faucet";

export function HierarchyPWSAddEntity({ onSuccess }) {
  const { enqueueSnackbar } = useSnackbar();
  const { jobData, focusEntityData, project, system } = useHierarchyContext();

  const [entityType, setEntityType] = useState(DEFAULT_ENTITY_TYPE);

  const pws_entity_types = {
    [ENTITY_TYPE_DISHWASHER]: window.gettext("Dishwasher"),
    [ENTITY_TYPE_BATHROOM_FAUCET]: window.gettext("Bathroom faucet"),
    [ENTITY_TYPE_KITCHEN_FAUCET]: window.gettext("Kitchen faucet"),
    [ENTITY_TYPE_SHOWER]: window.gettext("Shower"),
    [ENTITY_TYPE_TOILET]: window.gettext("Toilet"),
    [ENTITY_TYPE_WASHING_MACHINE]: window.gettext("Washing machine"),
  };

  function getEntityDefaultName(entityType) {
    const defaultName = getDefaultEntityLabel(entityType);

    // Get names of entities of the same type
    const entityNames = jobData.entities
      .filter(
        (entity) =>
          entity.type === entityType &&
          (entity.data.name === "" || entity.data.name.includes(defaultName))
      )
      .map((entity) =>
        entity.data.name === "" ? defaultName : entity.data.name
      );

    // Get the numbers in the names
    const entityNameNumbers = entityNames.map(
      (name) => name.replace(defaultName, "").trim() || 1
    );

    // Get the highest number in the names, or null if there are no numbers
    const highestNumber =
      entityNameNumbers.length === 0 ? null : Math.max(...entityNameNumbers);

    // Return the default name with the highest number + 1, or just the default name if there are no numbers
    return defaultName + (highestNumber ? " " + (highestNumber + 1) : "");
  }

  function postCreate(entity) {
    if (onSuccess) {
      onSuccess(entity);
    }
    enqueueSnackbar(window.gettext("Entity added successfully"), {
      variant: "success",
    });
  }

  function handleEntityTypeChange(event) {
    setEntityType(event.target.value);
  }

  const formParams = {
    urlParams: [project.id, system.id],
    postCreate: postCreate,
    postData: { apartment: focusEntityData.entity?.id },
    mode: "create",
  };

  switch (entityType) {
    case ENTITY_TYPE_DISHWASHER:
      formParams.service = EntityDishwasherService;
      formParams.form = (
        <HierarchyPWSDishwasherForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_DISHWASHER)}
        />
      );
      break;
    case ENTITY_TYPE_BATHROOM_FAUCET:
      formParams.service = EntityBathroomFaucetService;
      formParams.form = (
        <HierarchyPWSBathroomFaucetForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_BATHROOM_FAUCET)}
        />
      );
      break;
    case ENTITY_TYPE_KITCHEN_FAUCET:
      formParams.service = EntityKitchenFaucetService;
      formParams.form = (
        <HierarchyPWSKitchenFaucetForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_KITCHEN_FAUCET)}
        />
      );
      break;
    case ENTITY_TYPE_SHOWER:
      formParams.service = EntityShowerService;
      formParams.form = (
        <HierarchyPWSShowerForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_SHOWER)}
        />
      );
      break;
    case ENTITY_TYPE_TOILET:
      formParams.service = EntityToiletService;
      formParams.form = (
        <HierarchyPWSToiletForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_TOILET)}
        />
      );
      break;
    case ENTITY_TYPE_WASHING_MACHINE:
      formParams.service = EntityWashingMachineService;
      formParams.form = (
        <HierarchyPWSWashingMachineForm
          defaultName={getEntityDefaultName(ENTITY_TYPE_WASHING_MACHINE)}
        />
      );
      break;
  }

  return (
    <>
      <Typography variant="h5">{window.gettext("Add entity")}</Typography>

      <Stack
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {/* Entity Type */}
        <FormControl
          sx={{
            maxWidth: 600,
            flexBasis: 200,
            flexGrow: 1,
          }}
        >
          <InputLabel variant="standard">
            {window.gettext("Entity type")}
          </InputLabel>
          <Select
            variant="standard"
            value={entityType}
            onChange={handleEntityTypeChange}
          >
            {Object.entries(pws_entity_types).map(([name, label], index) => {
              return (
                <MenuItem key={index} value={name}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {/* Entity Form */}
        {formParams.form ? (
          <Stack
            sx={{
              maxWidth: 600,
              flexBasis: 300,
              flexGrow: 1,
            }}
          >
            <InstanceManipulator {...formParams} />
          </Stack>
        ) : (
          <Alert severity="warning">
            {window.gettext("Missing form for this entity type")}
          </Alert>
        )}
      </Stack>
    </>
  );
}
