import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import ValueGetter from "../ValueGetter";

export default function InformationGridItem(props) {
  let [value, setValue] = useState(props.value)
  let [suffix, setSuffix] = useState("")
  let [element, setElement] = useState(undefined)

  useEffect(() => {
    if (props.component === undefined) {
      return;
    }

    const element = React.cloneElement(
      props.component,
      {
        'value': value
      }
    )

    setElement(element)
  }, [props.component, value])

  useEffect(() => {
    if (props.obj === undefined) {
      return
    }

    setValue(
      ValueGetter().getValueFromNode(
        props.obj,
        props.keyPath
      )
    )
  }, [props.obj])

  useEffect(() => {
    setSuffix(props.valueSuffix || "")
  }, [props.valueSuffix])

  useEffect(() => {
    // When value change, need to update it locally

    if(props.value){
      setValue(props.value)
    }
  }, [props.value])

  return (
    <Grid item xs={4}>
      <Grid item container direction="row" justifyContent="space-between" spacing={2}>
        <Grid item>
          {props.title}:
        </Grid>
        <Grid item textAlign="right">
          {element !== undefined ? (
            <>
              {element}
            </>
          ) : (
            <>
              {value !== undefined && (
                <>
                  {value}{suffix}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  )

}