import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Checkbox, FormControl, FormControlLabel } from "@mui/material";

import JobDatagridPresetMenu from "../datagrid/JopDatagridPresetMenu";
import SelfCheckHierarchyButtonLink from "../utils/SelfCheckHierarchyButtonLink";
import FreezeCalculationsButton from "./FreezeCalculationsButton";

import { LayoutToolbar } from "../../../../../layout";

export default function JobBottomToolbar({
  job,
  onHiddenColumnsChange,
  onOptionsChange,
  options,
  project,
  refreshJobData,
  system,
}) {
  const setOptionSetting = (key, value) => {
    onOptionsChange((prev) => ({ ...prev, [key]: value }));
  };

  const handleOnChangeShowLinks = (e) => {
    setOptionSetting("entity_links", e.target.checked);
  };

  const handleOnChangeShowComments = (e) => {
    setOptionSetting("show_comments", e.target.checked);
  };

  return (
    <LayoutToolbar>
      {/* the left part */}
      <SelfCheckHierarchyButtonLink
        job={job}
        system={system}
        projectId={project.id}
      />

      <JobDatagridPresetMenu onHiddenColumnsChange={onHiddenColumnsChange} />

      <div style={{ flexGrow: 1, height: 30 }} />

      {/* the center part */}
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={options?.entity_links || false}
              onChange={handleOnChangeShowLinks}
            />
          }
          label={window.gettext("Show links")}
        />
      </FormControl>

      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={options?.show_comments || false}
              onChange={handleOnChangeShowComments}
            />
          }
          label={window.gettext("Show comments")}
        />
      </FormControl>

      <div style={{ flexGrow: 1, height: 30 }} />

      {/* The right part*/}
      <Button
        variant={"outlined"}
        startIcon={<RefreshIcon />}
        title={window.gettext("Reload data")}
        onClick={() => {
          refreshJobData({ clearJobData: true });
        }}
      >
        {window.gettext("Reload")}
      </Button>

      <FreezeCalculationsButton
        project={project}
        system={system}
        job={job}
        refreshJobData={refreshJobData}
      >
        {window.gettext("Freeze calculations")}
      </FreezeCalculationsButton>
    </LayoutToolbar>
  );
}
