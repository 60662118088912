import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import InstanceManipulator from "../../../../../../../../../../../components/InstanceManipulator";
import HeatCurve from "../../../../../../../../../../../components/heat_curve/HeatCurve";
import HeatCurveName from "../../../../../../../../../../../components/heat_curve/HeatCurveName";
import LoadingIndicator from "../../../../../../../../../../../components/layout/LoadingIndicator";
import Sheet from "../../../../../../../../../../../components/layout/Sheet";
import MeasurementInfo from "../../../../../../../../../../../components/project/self_check/job/measurement/MeasurementInfo";
import CreateMeasurementOpportunityForm from "../../../../../../../../../../../components/project/self_check/job/measurement/opportunity/CreateMeasurementOpportunityForm";
import OpportunitiesDetailedList from "../../../../../../../../../../../components/project/self_check/job/measurement/opportunity/oportunities_list/OpportunitiesDetailedList";
import ProjectService from "../../../../../../../../../../../components/services/ProjectService";
import SelfCheckJobService from "../../../../../../../../../../../components/services/SelfCheckJobService";
import SystemHeatCurveService from "../../../../../../../../../../../components/services/SystemHeatCurveService";
import SystemService from "../../../../../../../../../../../components/services/SystemService";
import SelfCheckJobMeasurementOpportunityLatestService from "../../../../../../../../../../../components/services/measurement/SelfCheckJobMeasurementOpportunityLatestService";
import SelfCheckJobMeasurementOpportunityService from "../../../../../../../../../../../components/services/measurement/SelfCheckJobMeasurementOpportunityService";
import SelfCheckJobMeasurementService from "../../../../../../../../../../../components/services/measurement/SelfCheckJobMeasurementService";
import SystemDesiredRoomTemperatureForm from "../../../../../../../../../../../components/system/SystemDesiredRoomTemperatureForm";
import UserPermission from "../../../../../../../../../../../components/user/UserPermission";
import Helper from "../../../../../../../../../../../components/utils/Helper";
import Temperature from "../../../../../../../../../../../components/values/Temperature";

import {
  LayoutCard,
  LayoutView,
} from "../../../../../../../../../../../layout";

export default function MeasurementView(props) {
  const { project_id, system_id, job_spec_id, job_id, measurement_id } =
    useParams();

  const [job, setJob] = useState();
  const [project, setProject] = useState(null);
  const [system, setSystem] = useState();

  const [editSystemOpen, setEditSystemOpen] = useState(false);
  const [measurement, setMeasurement] = useState(null);
  const [opportunities, setOpportunities] = useState([]);
  const [latestOpportunity, setLatestOpportunity] = useState(null);
  const [heatCurves, setHeatCurves] = useState([]);

  useEffect(fetchJob, [job_id, job_spec_id, project_id, system_id]);
  useEffect(fetchProject, [project_id]);
  useEffect(fetchSystem, [system_id]);

  useEffect(() => {
    fetchHeatCurves();
    fetchMeasurement();
    fetchMeasurementOpportunities();
  }, []);

  function fetchHeatCurves() {
    if (!project_id || !system_id) {
      return;
    }

    SystemHeatCurveService.list(project_id, system_id).then(setHeatCurves);
  }

  function fetchMeasurement() {
    if (!job_id || !measurement_id || !project_id || !system_id) {
      return;
    }

    SelfCheckJobMeasurementService.fetch(
      project_id,
      system_id,
      job_id,
      measurement_id
    ).then((m) => {
      console.log("setMeasurement", m);
      setMeasurement(m);
    });
  }

  function fetchMeasurementOpportunities() {
    if (!job_id || !project_id || !system_id) {
      return;
    }

    SelfCheckJobMeasurementOpportunityLatestService.fetch(
      project_id,
      system_id,
      job_id
    ).then(setLatestOpportunity);

    SelfCheckJobMeasurementOpportunityService.list(
      project_id,
      system_id,
      job_id,
      measurement_id
    ).then(setOpportunities);
  }

  function fetchJob() {
    if (!job_id || !job_spec_id || !project_id || !system_id) {
      return;
    }

    SelfCheckJobService.fetch(project_id, system_id, job_spec_id, job_id).then(
      setJob
    );
  }

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  function fetchSystem() {
    if (!system_id) {
      return;
    }

    SystemService.fetch(project_id, system_id).then(setSystem);
  }

  function handleOnClickCreateNewHeatCurve(e) {
    SystemHeatCurveService.create({}, project_id, system_id).then(
      (response) => {
        fetchHeatCurves();
      }
    );
  }

  function handleOnMeasurementOpportunityCreated(opportunity) {
    fetchMeasurementOpportunities();
  }

  function postUpdateSystem() {
    setEditSystemOpen(false);
    fetchSystem();
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Measurement")}
    >
      {!job || !measurement || !project || !system ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Measurement info */}
          <MeasurementInfo
            project_id={project_id}
            system={system}
            job={job}
            measurement={measurement}
            heatCurves={heatCurves}
            editable={true}
            onUpdated={(newMeasurement) => {
              setMeasurement(newMeasurement);
            }}
          />

          {/* Settings - Settings related to the measurement. System information for instance */}
          <LayoutCard
            title={
              <>
                {window.gettext("Settings")}

                <UserPermission permission={"change_system"}>
                  <Tooltip
                    title={window.gettext(
                      "Edit desired room temperature for system"
                    )}
                  >
                    <IconButton
                      onClick={() => {
                        setEditSystemOpen(!editSystemOpen);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </UserPermission>
              </>
            }
            noPadding
          >
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {window.gettext("Desired room temperature")}
                    </TableCell>

                    <TableCell>
                      <Temperature
                        value={parseFloat(system?.room_temperature)}
                        precision={1}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            {/* Edit system*/}
            {editSystemOpen && (
              <>
                <Alert severity={"warning"}>
                  {window.gettext(
                    "Note that changing system settings will change them for all measurements on all jobs connected to the system"
                  )}
                </Alert>

                <InstanceManipulator
                  service={SystemService}
                  urlParams={[project_id, system_id]}
                  postUpdate={postUpdateSystem}
                  mode="edit"
                  form={
                    <SystemDesiredRoomTemperatureForm
                      submitButtonText={window.gettext("Save")}
                    />
                  }
                />
              </>
            )}
          </LayoutCard>

          {/* Heat curves */}
          <LayoutCard
            title={
              <>
                {window.gettext("Current measurement opportunities")}

                <Helper
                  title={window.gettext("Opportunities for each measurement")}
                  ml
                >
                  {window.gettext(
                    "Each measurement into one ore more opportunity where each opportunity signifies a point in time where the environmental conditions are at a specific state"
                  )}
                </Helper>
              </>
            }
            noPadding
          >
            <OpportunitiesDetailedList
              editable={true}
              projectId={project_id}
              systemId={system_id}
              jobId={job_id}
              measurementId={measurement_id}
              opportunities={opportunities}
              latestOpportunity={latestOpportunity}
            />
          </LayoutCard>

          <UserPermission
            permission={UserPermission.permissions.measurement_opportunity_add}
          >
            <LayoutCard
              title={window.gettext("Create new measurement opportunity")}
            >
              <CreateMeasurementOpportunityForm
                createButtonText={window.gettext(
                  "Create measurement opportunity"
                )}
                createButtonTooltipText={window.gettext(
                  "Create measurement opportunity"
                )}
                projectId={project_id}
                systemId={system_id}
                jobId={job_id}
                measurementId={measurement_id}
                onCreated={handleOnMeasurementOpportunityCreated}
              />
            </LayoutCard>
          </UserPermission>

          <LayoutCard title={window.gettext("Heat curves")}>
            <Stack>
              {heatCurves.map((heat_curve, i) => (
                <Accordion
                  defaultExpanded={i === 0}
                  key={"heat_curve-" + heat_curve.id}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <HeatCurveName heat_curve={heat_curve} />
                  </AccordionSummary>

                  <AccordionDetails sx={{ p: 0 }}>
                    <HeatCurve
                      heat_curve={heat_curve}
                      system_id={system_id}
                      project_id={project_id}
                      allow_edit={true}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}

              <UserPermission
                permission={UserPermission.permissions.system_heat_curve_add}
              >
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleOnClickCreateNewHeatCurve}
                >
                  {window.gettext("Create new heat curve")}
                </Button>
              </UserPermission>
            </Stack>
          </LayoutCard>
        </>
      )}
    </LayoutView>
  );
}
