import { Avatar, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function MobileMenuItem({ icon, to, onClick, children }) {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith(to)) {
      setOpen(true);
    } else if (open) {
      setOpen(false);
    }
  }, [location]);

  function handleClick(e) {
    if (onClick) {
      onClick(e);
    }

    if (to) {
      navigate(to);
    }
  }

  return (
    <IconButton
      onClick={handleClick}
      sx={{
        t: 1,
        height: "100%",
      }}
    >
      <Avatar
        sx={{
          bgcolor: "primary.main",
          width: 36,
          height: 36,
        }}
      >
        {icon}
      </Avatar>
    </IconButton>
  );
}
