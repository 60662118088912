import { getComponentsHeaders } from "../../helper";
import { HierarchyViewOption } from "./HierarchyViewOption";

export class HierarchyViewOptions {
  show_done_only_checkbox = undefined;
  show_flagged_only_checkbox = undefined;
  show_partly_done_only_checkbox = undefined;
  show_visit_day_select = undefined;

  show_information_option = undefined;

  constructor(
    entityType,
    {
      show_done_only_checkbox = false,
      show_flagged_only_checkbox = false,
      show_partly_done_only_checkbox = false,
      show_visit_day_select = false,
    },
    option_values
  ) {
    this.show_done_only_checkbox = show_done_only_checkbox;
    this.show_flagged_only_checkbox = show_flagged_only_checkbox;
    this.show_partly_done_only_checkbox = show_partly_done_only_checkbox;
    this.show_visit_day_select = show_visit_day_select;

    const SHOW_PREFIX = window.gettext("Show") + " ";

    this.show_information_option = new HierarchyViewOption(
      entityType,
      "show_entity_information",
      SHOW_PREFIX + getComponentsHeaders(entityType).entity_information,
      option_values.show_entity_information
    );
  }

  getCheckboxAdditionalOptions() {
    // List of entity specific checkbox options
    return [];
  }

  getCheckboxOptions() {
    // List of checkbox options, controls order of options in UI
    return [
      // Common options
      this.show_information_option,
      // Entity specific options
      ...this.getCheckboxAdditionalOptions(),
    ];
  }
}
