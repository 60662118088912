import React from "react";
import getRowClassName from "../GetRowClassName";
import getColumnWidth from "../GetColumnWidth";
import Flag from "../../../../flag/Flag";
import ValueGetter from "../../ValueGetter";
import EditFlagCellInput from "../../edit_cell/EditFlagCellInput";

export default function FlagColumnDefinition() {
  return {
    field: 'flag',
    width: getColumnWidth('flag'),
    disableExport: true,
    getRowClassName: getRowClassName,
    headerName: window.gettext('Flag'),
    disableColumnMenu: true,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row, 'node.flag'
      )
    }),
    editable: true,
    renderCell: (params) => {
      return (
        <>
          <Flag type={params.row.node.flag} />
        </>
      )
    },
    renderEditCell: (params) => {
      return <EditFlagCellInput {...params} />
    }
  }
}