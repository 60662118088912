import BaseService from "./BaseService";

class ProjectRowService extends BaseService {
  summary = (project_id) => {
    return this.list(
      ...[
        project_id,
        'summary',
      ]
    )
  }
  unused = (project_id) => {
    return this.list(
      ...[
        project_id,
        'unused-in-task-item-specs',
      ]
    )
  }

  preSendDataTransformer = (data) => {
    if(data.hasOwnProperty('price')){
      // Convert into whole Kronor
      data['price'] = data['price'] * 100
    }
    return data
  }

  preViewDataTransformer = (data) => {
    if(data.hasOwnProperty('price')){
      // Convert into whole Kronor
      data['price'] = parseInt(data['price'] / 100)
    }
    return data
  }
}

export default new ProjectRowService('/api/',
  [
    'project',
    'rows',
  ]
)