import { Typography } from "@mui/material";

import { FetchedLink } from "./FetchedLink";

export function FetchedLinkText({ children, color, to, variant = "body" }) {
  return (
    <FetchedLink
      color={color}
      loadingElement={
        <Typography variant={variant}>
          {window.gettext("Loading") + "..."}
        </Typography>
      }
      to={to}
    >
      <Typography variant={variant}>{children}</Typography>
    </FetchedLink>
  );
}
