import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import JobContext from "../../../../../../../../../../../components/project/self_check/job/JobContext";
import ReportInstallationProfileContext from "../../../../../../../../../../../components/project/self_check/job/ReportInstallationProfileContext";
import JobReportInstallationProfileList from "../../../../../../../../../../../components/project/self_check/job/report/installation/profile/JobReportInstallationProfileList";
import JobReportInstallationProfileManipulator from "../../../../../../../../../../../components/project/self_check/job/report/installation/profile/JobReportInstallationProfileManipulator";
import ProjectService from "../../../../../../../../../../../components/services/ProjectService";
import UserPermission from "../../../../../../../../../../../components/user/UserPermission";
import getUserHasPermission from "../../../../../../../../../../../components/user/getUserHasPermission";
import store from "../../../../../../../../../../../state";

import {
  CenteredModal,
  useModalHandler,
} from "../../../../../../../../../../../components/layout/modal";

import {
  LayoutCard,
  LayoutView,
} from "../../../../../../../../../../../layout";

export default function JobReportInstallationProfileSelectionView() {
  const { project_id } = useParams();

  const navigate = useNavigate();

  const createProfileModalHandler = useModalHandler();

  const [project, setProject] = useState(null);

  useEffect(fetchProject, [project_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  const onCreated = (response) => {
    navigate(window.location.pathname + "profile/" + response.id);
  };

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Installation report")}
      submenuItems={
        getUserHasPermission(
          store.user,
          UserPermission.permissions.installation_report_profile_add
        ) ? (
          <Button variant="outlined" onClick={createProfileModalHandler.open}>
            {window.gettext("Add profile")}
          </Button>
        ) : null
      }
    >
      <JobContext>
        <ReportInstallationProfileContext>
          <LayoutCard
            title={window.gettext("Please select a report profile to use")}
            sx={{
              maxWidth: 616,
            }}
          >
            <JobReportInstallationProfileList />
          </LayoutCard>

          <UserPermission
            permission={
              UserPermission.permissions.installation_report_profile_add
            }
          >
            <CenteredModal modalHandler={createProfileModalHandler}>
              <JobReportInstallationProfileManipulator postCreate={onCreated} />
            </CenteredModal>
          </UserPermission>
        </ReportInstallationProfileContext>
      </JobContext>
    </LayoutView>
  );
}
