import {Button, TextField} from "@mui/material";
import TimeDuration from "../../../components/values/TimeDuration";
import {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import IssueSpentTimeService from "../../../components/services/IssueSpentTimeService";
import {useSnackbar} from "notistack";
import {useIssue} from "../../../components/issue_tracker/IssueContext";
import {useIssueSpentTime} from "../../../components/issue_tracker/IssueSpentTimeContext";

export default function IssueSpentTimeAddEntryForm({}) {
  // current value
  const [value, setValue] = useState(undefined)
  // Stackbar

  const {issue, refreshIssue} = useIssue()
  const {refreshSpentTimeEntries} = useIssueSpentTime()

  const {enqueueSnackbar} = useSnackbar();

  const handleAddButtonClick = (event) => {
    IssueSpentTimeService.create(
      {
        minutes: value
      },
      issue.id
    ).then(
      (response) => {
        enqueueSnackbar(
          window.gettext('Spent time entry added'),
          {
            'variant': 'success'
          }
        )
        setValue(undefined)
        refreshIssue()
        refreshSpentTimeEntries()
      }
    )
  }

  return (
    <>
      <TextField
        label={window.gettext("Spent time in minutes")}
        type="number"
        value={value || ""}
        onChange={
          (event) => {
            setValue(event.target.value)
          }
        }
        InputProps={{
          endAdornment: <TimeDuration minutes={value}/>
        }}
      />
      <Button
        variant="contained"
        disabled={!value || !issue}
        onClick={handleAddButtonClick}
        color="primary"
        endIcon={<AddIcon/>}
      >
        {window.gettext("Add")}
      </Button>
    </>
  )
}