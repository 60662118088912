import { Button, Grid, Typography } from "@mui/material";
import CommentIcon from "@mui/icons-material/Comment";

import BasicModal from "../../../../../../../layout/BasicModal";
import MultilineText from "../../../../../../../layout/MultilineText";
import Sheet from "../../../../../../../layout/Sheet";
import Helper from "../../../../../../../utils/Helper";
import getEntityStatus from "../../../../utils/getEntityStatus";
import EntityCommentsCount from "../../../../entity/EntityCommentsCount";

export function HierarchyEntityComments({ entityData, hideIfNoComments }) {
  const entityStatus = getEntityStatus(entityData, entityData.job);

  return (
    <>
      {hideIfNoComments === true && entityStatus.comments_count() === 0 ? (
        <></>
      ) : (
        <>
          <Grid container alignItems={"center"} spacing={1}>
            <Grid item>
              <BasicModal
                toggleButton={
                  <Button startIcon={<CommentIcon />}>
                    <EntityCommentsCount entityData={entityData} />
                  </Button>
                }
              >
                <Grid container direction={"column"} spacing={2}>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography variant={"h2"}>
                      {window.gettext("Comments")}
                      <Helper
                        title={window.gettext(
                          "Comments on tasks and items connected to this entity"
                        )}
                        ml
                      ></Helper>
                    </Typography>
                  </Grid>
                  {entityStatus.comments().map((commentData, i) => {
                    return (
                      <Grid item key={"comment_" + String(i)}>
                        <Sheet title={commentData.spec.name}>
                          <MultilineText>{commentData.comment}</MultilineText>
                        </Sheet>
                      </Grid>
                    );
                  })}
                </Grid>
              </BasicModal>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
