import React, {useEffect, useState} from "react";

import ChoicesService from "../services/ChoicesService";
import { Form, FormFactory } from "../form";


export default function SelfCheckTaskSpecForm(props) {
  let [taskSpecChoices, setTaskSpecChoices] = useState(null)
  let [formSections, setFormSections] = useState([])

  const submit_button_text = props.submitButtonText || window.gettext("Create job specification")

  const colorChoices = {
    '00B140': window.gettext('Green'),
    'B72136': window.gettext('Red'),
    'FFE16A': window.gettext('Yellow'),
    '4A4E53': window.gettext('Blue'),
  }

  useEffect(() => {
    ChoicesService.selfCheckTaskSpecChoices().then(setTaskSpecChoices)
  }, [])

  useEffect(() => {
    if (!taskSpecChoices) {
      return
    }

    console.log('projectRowChoices', taskSpecChoices)

    colorChoices[taskSpecChoices.unchecked_icon_color__default] = 
      colorChoices[taskSpecChoices.unchecked_icon_color__default] + " (" + window.gettext('Unchecked default') + ")";
    colorChoices[taskSpecChoices.checked_icon_color__default] = 
      colorChoices[taskSpecChoices.checked_icon_color__default] + " (" + window.gettext('Checked default') + ")";

    const formFactory = new FormFactory(props.instance)

    setFormSections(
      [
        formFactory.section(
          window.gettext("General"), 
          [
            // name
            formFactory.textField('name', window.gettext("Name"), true),
            // type
            formFactory.choicesField('type', window.gettext("Type"), true, taskSpecChoices.type__default, {
              choices: taskSpecChoices.type,
            }),
            // target_type
            formFactory.choicesField('target_type', window.gettext("Target entity type"), true, '', {
              choices: taskSpecChoices.target_type,
              helperText: window.gettext('The entity type on which we should perform tasks orienting from this task specification')
            }),
          ]
        ),
        formFactory.section(
          window.gettext("Task options"),
          [
            // enable_commenting
            formFactory.booleanField(
              'enable_commenting',
              window.gettext("Enable commenting"),
              false,
              taskSpecChoices.enable_commenting__default
            ),
          ],
          {}
        ),
        formFactory.section(
          window.gettext("Task automations"), 
          [
            // task_mark_done_when_any_task_item_mark_done
            formFactory.booleanField(
              'task_mark_done_when_any_task_item_mark_done',
              window.gettext("Task is marked as done when any task item is marked as done"),
              false,
              taskSpecChoices.task_mark_done_when_any_task_item_mark_done__default
            ),
            // task_mark_not_done_when_no_task_items_are_marked_done
            formFactory.booleanField(
              'task_mark_not_done_when_no_task_items_are_marked_done',
              window.gettext("Task is marked as not done when no task items are marked as done"),
              false,
              taskSpecChoices.task_mark_not_done_when_no_task_items_are_marked_done__default
            ),
            // task_items_all_not_done_mark_as_not_done
            formFactory.booleanField(
              'task_items_all_not_done_mark_as_not_done',
              window.gettext("If not all task items are marked as done, mark the task as not done"),
              false,
              taskSpecChoices.task_items_all_not_done_mark_as_not_done__default
            ),
            // task_items_all_done_mark_task_as_done
            formFactory.booleanField(
              'task_items_all_done_mark_task_as_done',
              window.gettext("If all task items are marked as done, mark the task as done"),
              false,
              taskSpecChoices.task_items_all_done_mark_task_as_done__default
            ),
            // task_items_mark_not_done_when_task_not_done
            formFactory.booleanField(
              'task_items_mark_not_done_when_task_not_done',
              window.gettext("Task items that previously had been set to done will be set to not done when this task is set to not done"),
              false,
              taskSpecChoices.task_items_mark_not_done_when_task_not_done__default
            ),
            // task_items_mark_done_when_task_done
            formFactory.booleanField(
              'task_items_mark_done_when_task_done',
              window.gettext("Task items will be set to done when this task is set to done"),
              false,
              taskSpecChoices.task_items_mark_done_when_task_done__default
            ),
            // task_items_mark_done_when_task_done_if_no_items_are_done
            formFactory.booleanField(
              'task_items_mark_done_when_task_done_if_no_items_are_done',
              window.gettext('Task items are marked as done when this task is marked as done if no task items are marked as done'),
              false,
              taskSpecChoices.task_items_mark_done_when_task_done_if_no_items_are_done__default
            ).withHelpText(
              window.gettext('Will mark task items as done when this task is marked as done on the condition that there are no task items that are marked as done')
            ),
          ],
        ),
        formFactory.section(
          window.gettext("Icons"),
          [
            formFactory.group(
              window.gettext("Checked icon"), 
              [
                // checked_icon
                formFactory.textField(
                  'checked_icon',
                  window.gettext("Icon"),
                  true,
                  taskSpecChoices.checked_icon__default,
                  {
                    choices: taskSpecChoices.checked_icon,
                    onChange: (e, newValue) => {
                      console.log('onChange new value', e, newValue.label)
                    }
                  }
                ),
                // checked_icon_color
                formFactory.textField(
                  'checked_icon_color',
                  window.gettext("Icon color"),
                  true,
                  taskSpecChoices.checked_icon_color__default,
                  {
                    choices: colorChoices,
                  }
                ),
              ],
              {
                direction: 'row',
                split: [50, 50],
              }
            ),
            formFactory.group(
              window.gettext("Unchecked icon"),
              [
                // unchecked_icon
                formFactory.textField(
                  'unchecked_icon',
                  window.gettext("Icon"),
                  true,
                  taskSpecChoices.unchecked_icon__default,
                  {
                    choices: taskSpecChoices.unchecked_icon,
                    onChange: (e, newValue) => {
                      console.log('onChange new value', e, newValue.label)
                    }
                  }
                ),
                // unchecked_icon_color
                formFactory.textField(
                  'unchecked_icon_color',
                  window.gettext("Icon color"),
                  true,
                  taskSpecChoices.unchecked_icon_color__default,
                  {
                    choices: colorChoices,
                  }
                ),
              ],
              {
                direction: 'row',
                split: [50, 50],
              }
            ),
          ], 
          {
            expanded: false,
          }
        ),
      ]
    )
  }, [props.instance, taskSpecChoices])

  return (
    <>
      {
        formSections.length > 0 && (
          <Form
            header={submit_button_text}
            formTestId="self-check-task-spec-form"
            formSections={formSections}
            submitButtonText={submit_button_text}
            onSubmit={props.onSubmit}
          />
        )
      }
    </>
  )
}
