import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CreateMeasurementOpportunityForm from "../../../../../../../../../../components/project/self_check/job/measurement/opportunity/CreateMeasurementOpportunityForm";
import ProjectService from "../../../../../../../../../../components/services/ProjectService";

import { LayoutCard, LayoutView } from "../../../../../../../../../../layout";

export default function MeasurementCreateView(props) {
  const { project_id, system_id, job_spec_id, job_id } = useParams();

  const navigate = useNavigate();

  const [project, setProject] = useState(null);

  useEffect(fetchProject, [project_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  const handleOnCreatedMeasurementOpportunity = (opportunity) => {
    navigate(
      "/projects/" +
        String(project_id) +
        "/systems/" +
        String(system_id) +
        "/job-specs/" +
        String(job_spec_id) +
        "/job/" +
        String(job_id) +
        "/measurements/" +
        opportunity.measurement.id +
        "/opportunity/" +
        String(opportunity.id) +
        "/"
    );
  };

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Create new measurement")}
    >
      <LayoutCard
        title={window.gettext("Will create new measurement and opportunity")}
      >
        <CreateMeasurementOpportunityForm
          projectId={project_id}
          systemId={system_id}
          jobId={job_id}
          createButtonText={window.gettext("Create")}
          createButtonTooltipText={window.gettext(
            "Create measurement and a measurement opportunity"
          )}
          onCreated={handleOnCreatedMeasurementOpportunity}
        />
      </LayoutCard>
    </LayoutView>
  );
}
