export default function GetAdjacentColumn(eventData, indexModifier) {

  const columns = eventData.apiRef.current.getVisibleColumns()

  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i]

    if (column.field !== eventData.params.field) {
      continue
    }

    const getColumnAtIndex = indexModifier + i;

    // If we are trying to get columns that do not exist
    if (getColumnAtIndex < 0) {
      return undefined
    }

    // After columns end
    if (getColumnAtIndex >= columns.length) {
      return undefined
    }

    return columns[getColumnAtIndex]
  }

}
