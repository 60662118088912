import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import AutocompleteWithCreateInput from "../../edit_cell/AutocompleteWithCreateInput";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function RoomColumnDefinition(systemRooms) {
  return {
    field: 'room',
    width: getColumnWidth('room'),
    getRowClassName: getRowClassName,
    editable: true,
    headerName: window.gettext('Room'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row, 'node.item.room.name',
      )
    }),
    renderEditCell: (params) => {
      return <AutocompleteWithCreateInput
        {...params}
        options={systemRooms}
        label={window.gettext('Room')}
      />
    }
  }
}