import { Alert, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";

import LoadingIndicator from "../../../../../../../layout/LoadingIndicator";

import { LayoutCard } from "../../../../../../../../layout";
import { SelfCheckJobSpecEntityReportService } from "../../../../../../../services";
import { ENTITY_TYPE_ENTRANCE } from "../../../../entity";
import { CustomJobGridToolbar } from "./components";
import { getColumns, getRows, sortRows } from "./functions";

export function JobsReportDetailTable({
  projectId,
  jobSpecId,
  jobIds,
  showEntrances,
}) {
  const [reportData, setReportData] = useState(undefined);

  const [columns, setColumns] = useState(undefined);
  const [rows, setRows] = useState(undefined);

  const [filteredRows, setFilteredRows] = useState(undefined);

  // Fetch jobs when job ids are updated
  useEffect(() => {
    fetchReportData(jobIds);
  }, [jobIds]);

  // Update columns when report data task item specs or task specs are updated
  useEffect(() => {
    if (
      reportData?.task_item_specs === undefined ||
      reportData?.task_specs === undefined
    ) {
      return;
    }

    const columns = getColumns(
      reportData.task_specs,
      reportData.task_item_specs
    );

    setColumns(columns);
  }, [reportData?.task_item_specs, reportData?.task_specs]);

  // Update rows when report data entities is updated
  useEffect(() => {
    if (
      reportData?.jobs === undefined ||
      reportData?.task_item_specs === undefined ||
      reportData?.task_specs === undefined
    ) {
      return;
    }

    const rows = getRows(
      reportData.jobs,
      reportData.task_item_specs,
      reportData.task_specs
    );

    const sortedRows = sortRows([...rows]);

    setRows(sortedRows);
  }, [reportData?.jobs, reportData?.task_item_specs, reportData?.task_specs]);

  // Update filtered rows when rows or show entrances is updated
  useEffect(() => {
    if (rows === undefined) {
      return;
    }

    // Filter rows
    const filteredRows = rows.filter((row) => {
      if (showEntrances) {
        return true;
      } else {
        return row.entity_type !== ENTITY_TYPE_ENTRANCE;
      }
    });

    setFilteredRows(filteredRows);
  }, [rows, showEntrances]);

  function fetchReportData(jobIds) {
    const jobIdsQuery =
      "?" + jobIds.map((jobId) => "job_id=" + jobId).join("&");

    SelfCheckJobSpecEntityReportService.fetch(
      projectId,
      jobSpecId,
      jobIdsQuery
    ).then(setReportData);
  }

  return (
    <>
      {reportData === undefined || filteredRows === undefined ? (
        // Loading
        <LoadingIndicator />
      ) : (
        <LayoutCard noPadding>
          {reportData.length === 0 ? (
            // No jobs found
            <Alert severity="info">
              {window.gettext("Could not find jobs")}
            </Alert>
          ) : (
            <Stack
              sx={{
                height: "calc(100vh - " + 108 + "px)",
                gap: 1,
              }}
            >
              {rows.length === 0 ? (
                // No units found
                <Alert severity="info">
                  {window.gettext("There are no units on this/these job(s)")}
                </Alert>
              ) : (
                // Units found
                <DataGridPro
                  rows={filteredRows}
                  columns={columns}
                  components={{ Toolbar: CustomJobGridToolbar }}
                />
              )}
            </Stack>
          )}
        </LayoutCard>
      )}
    </>
  );
}
