import React, {useEffect, useState} from "react";
import FormFieldFactory from "../form/FormFieldFactory";
import Form from "../form/Form";
import ChoicesService from "../services/ChoicesService";
import ProjectRowService from "../services/ProjectRowService";
import {Alert, AlertTitle} from "@mui/material";
import SelfCheckTaskItemSpecService from "../services/SelfCheckTaskItemSpecService";


export default function SelfCheckTaskItemSpecForm(props) {
  let [formFields, setFormFields] = useState([])
  let [projectRowChoices, setProjectRowChoices] = useState({})
  let [taskItemSpecChoices, setTaskItemSpecChoices] = useState(null)
  let [project, setProject] = useState(null)
  let [jobSpec, setJobSpec] = useState(null)
  let [taskItemSpecId, setTaskItemSpecId] = useState(undefined)
  let [taskItemSpec, setTaskItemSpec] = useState(undefined)
  let [taskSpec, setTaskSpec] = useState(undefined)

  const submit_button_text = props.submitButtonText || window.gettext("Create job specification")

  useEffect(() => {
    if (props.taskItemSpecId === undefined) {
      return
    }

    console.log('TASK ITEM SPEC ID', props.taskItemSpecId)
    setTaskItemSpecId(props.taskItemSpecId)
  }, [props.taskItemSpecId])

  useEffect(() => {
    if (!props.taskSpec) {
      return
    }

    setTaskSpec(props.taskSpec)
  }, [props.taskSpec])


  useEffect(() => {
    if (!project || !taskSpec || !jobSpec || taskItemSpecId === undefined) {
      return
    }

    if (taskItemSpecId === null) {
      setTaskItemSpec(null)
      return
    }

    SelfCheckTaskItemSpecService.fetch(
      project.id,
      jobSpec.id,
      taskSpec.id,
      taskItemSpecId
    ).then(
      (response) => {
        setTaskItemSpec(response)
      }
    )

  }, [project, taskSpec, taskItemSpecId, jobSpec])

  useEffect(() => {
    if (!props.project) {
      return
    }

    setProject(props.project)

  }, [props.project])

  useEffect(() => {
    if (!props.jobSpec) {
      return
    }

    setJobSpec(props.jobSpec)

  }, [props.jobSpec])


  useEffect(() => {
    console.log('FormFields', formFields)

  }, [formFields])

  useEffect(() => {
    if (!project || !jobSpec || taskItemSpec === undefined) {
      return
    }

    ProjectRowService.unused(project.id).then(
      (response) => {
        let choices = Object.fromEntries(
          response.map(
            (project_row) => {
              return [
                parseInt(project_row.id),
                project_row.label
              ]
            }
          )
        )

        console.log('Choices', choices)

        if (taskItemSpec !== null) {
          console.log('taskItemSpec', taskItemSpec)
          choices[taskItemSpec.project_row.id] = taskItemSpec.project_row.label
        }

        setProjectRowChoices(
          choices
        )
      }
    )

    ChoicesService.selfCheckTaskItemSpecChoices().then(
      (response) => {
        setTaskItemSpecChoices(response)
      }
    )
  }, [project, jobSpec, taskItemSpec])

  useEffect(() => {
    if (
      !projectRowChoices ||
      !taskItemSpecChoices ||
      (props.instance && projectRowChoices[props.instance.project_row_id] === undefined)
    ) {
      return;
    }

    const form_field_factory = new FormFieldFactory(props.instance)

    let colorChoices = {
      '00B140': window.gettext('Green'),
      'B72136': window.gettext('Red'),
      'FFE16A': window.gettext('Yellow'),
    }
    colorChoices[taskItemSpecChoices.unchecked_icon_color__default] = window.gettext('Unchecked default')
    colorChoices[taskItemSpecChoices.checked_icon_color__default] = window.gettext('Checked default')

    setFormFields(
      [
        form_field_factory.textField('name', window.gettext("Name"), true),
        //form_field_factory.textField('item_type', window.gettext("Item type"), true, '', {choices: taskItemSpecChoices.item_type}),
        form_field_factory.textField('project_row_id', window.gettext("Project row"), true, '', {
          choices: projectRowChoices, onChange: (e, newValue) => {
            console.log('onChange new value', e, newValue.label)
          }
        }),
        form_field_factory.textField(
          'special_behaviour',
          window.gettext("Special behaviour"),
          false,
          taskItemSpecChoices.special_behaviour__default,
          {
          choices: taskItemSpecChoices.special_behaviour,
        }),
        form_field_factory.booleanField('enable_commenting', window.gettext("Enable commenting"), false,),
        form_field_factory.booleanField('copy_calculated_values_on_done', window.gettext("Copy installed value from calculated value when marked as done"), false,),
        form_field_factory.booleanField('clear_calculated_values_on_not_done', window.gettext("Clear installed value when marked as not done"), false,),
        form_field_factory.textField(
          'type',
          window.gettext("Type"),
          true,
          'boolean', // Set 'boolean' as default
          {
            choices: taskItemSpecChoices.type,
            onChange: (e, newValue) => {
              console.log('onChange new value', e, newValue.label)
            }
          }
        ),
        form_field_factory.textField(
          'checked_icon',
          window.gettext("Checked icon"),
          true,
          taskItemSpecChoices.checked_icon__default,
          {
            choices: taskItemSpecChoices.checked_icon,
            onChange: (e, newValue) => {
              console.log('onChange new value', e, newValue.label)
            }
          }
        ),
        form_field_factory.textField(
          'unchecked_icon',
          window.gettext("Unchecked icon"),
          true,
          taskItemSpecChoices.unchecked_icon__default,
          {
            choices: taskItemSpecChoices.unchecked_icon,
            onChange: (e, newValue) => {
              console.log('onChange new value', e, newValue.label)
            }
          }
        ),
        form_field_factory.textField('unchecked_icon_color', window.gettext("Unchecked icon color"), true, taskItemSpecChoices.unchecked_icon_color__default, {
            choices: colorChoices,
          }
        ),
        form_field_factory.textField('checked_icon_color', window.gettext("Checked icon color"), true, taskItemSpecChoices.checked_icon_color__default,
          {
            choices: colorChoices,
          }
        ),
      ]
    )
  }, [projectRowChoices, taskItemSpecChoices, props.instance])

  return (

    <>
      {formFields ? (
        <>
          {(!Object.keys(projectRowChoices).length) ? (
            <>
              <Alert severity="warning">
                <AlertTitle>
                  {window.gettext('No unused project rows to choose from')}
                </AlertTitle>
                {window.gettext('To be able to create a task item specification there needs to be a free "Project row" on this project')}
              </Alert>
            </>
          ) : (
            <Form
              header={submit_button_text}
              formTestId="self-check-task-spec-form"
              formFields={formFields}
              submitButtonText={submit_button_text}
              onSubmit={props.onSubmit}
            />
          )}
        </>
      ) : (
        <>
          {window.gettext('Loading')}...
        </>
      )}
    </>
  )
}
