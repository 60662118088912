import {Box, Grid, LinearProgress} from "@mui/material";

export default function LoadingIndicator({loadingText}) {
  return (
    <>
      <Box sx={{width: '100%'}}>
        <LinearProgress/>
      </Box>
      <Grid container justifyContent="center" padding={3}>
        <Grid item>
          {loadingText || window.gettext('Loading...')}
        </Grid>
      </Grid>
    </>
  )
}