import { ExpandLess, ExpandMore, HorizontalRule } from "@mui/icons-material";
import {
  Chip,
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

import getEntityStatus from "../../../../../utils/getEntityStatus";

import {
  EntityCommentStatusIcon,
  EntityDoneStatusIcon,
  EntityFlagStatusIcon,
} from "../../../../../entity";
import { HierarchyEntityLink } from "../../components";
import { NavigationApartmentListItem } from "./NavigationApartmentListItem";

export function NavigationEntranceListItem({
  apartmentEntities,
  entranceEntity,
  jobData,
  open = false,
  setOpen,
}) {
  const [entityStatus, setEntityStatus] = useState(undefined);

  useEffect(() => {
    setEntityStatus(
      getEntityStatus({ entity: entranceEntity, job: jobData }, jobData)
    );
  }, [entranceEntity, jobData]);

  return (
    <>
      {entityStatus === undefined ? null : (
        <Stack key={entranceEntity.id}>
          <ListItemButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            <ListItemIcon>
              <EntityDoneStatusIcon entityStatus={entityStatus} />
            </ListItemIcon>

            <ListItemText
              primary={<HierarchyEntityLink entity={entranceEntity} />}
            />

            <Stack
              sx={{
                alignItems: "center",
                flexDirection: "row",
                gap: 1,
                pr: "8px",
              }}
            >
              <Chip label={apartmentEntities.length} />

              {entityStatus.has_checkable() && (
                <>
                  <EntityCommentStatusIcon entityStatus={entityStatus} />

                  <EntityFlagStatusIcon entityStatus={entityStatus} />
                </>
              )}
            </Stack>

            {apartmentEntities.length > 0 ? (
              open ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )
            ) : (
              <HorizontalRule />
            )}
          </ListItemButton>

          <Divider />

          {apartmentEntities.length > 0 && (
            <>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {apartmentEntities.map((apartmentEntity) => (
                    <NavigationApartmentListItem
                      key={apartmentEntity.id}
                      entity={apartmentEntity}
                      jobData={jobData}
                    />
                  ))}
                </List>
              </Collapse>

              {open && <Divider />}
            </>
          )}
        </Stack>
      )}
    </>
  );
}
