// Common Entities
export const ENTITY_TYPE_APARTMENT = "address_apartment";
export const ENTITY_TYPE_ENTRANCE = "address_entrance";
export const ENTITY_TYPE_SYSTEM = "system";

// Potable Water System
export const ENTITY_TYPE_BATHROOM_FAUCET = "bathroom_faucet";
export const ENTITY_TYPE_DISHWASHER = "dishwasher";
export const ENTITY_TYPE_KITCHEN_FAUCET = "kitchen_faucet";
export const ENTITY_TYPE_SHOWER = "shower";
export const ENTITY_TYPE_TOILET = "toilet";
export const ENTITY_TYPE_WASHING_MACHINE = "washing_machine";

// Radiator System
export const ENTITY_TYPE_RADIATOR = "radiator_node";
export const ENTITY_TYPE_VALVE = "valve_node";

// List of common entity types
export const ENTITY_TYPES_COMMON = [
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_SYSTEM,
];

// List of entity types for potable water system
export const ENTITY_TYPES_POTABLE_WATER = [
  ENTITY_TYPE_BATHROOM_FAUCET,
  ENTITY_TYPE_DISHWASHER,
  ENTITY_TYPE_KITCHEN_FAUCET,
  ENTITY_TYPE_SHOWER,
  ENTITY_TYPE_TOILET,
  ENTITY_TYPE_WASHING_MACHINE,
];

// List of entity types for radiator system
export const ENTITY_TYPES_RADIATOR = [
  ENTITY_TYPE_RADIATOR,
  ENTITY_TYPE_VALVE,
];

// List of all entity types
export const ENTITY_TYPES_ALL = [
  ...ENTITY_TYPES_COMMON,
  ...ENTITY_TYPES_POTABLE_WATER,
  ...ENTITY_TYPES_RADIATOR,
];
