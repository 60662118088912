import getRowClassName from "../GetRowClassName";
import getObjectById from "../../../utils/getObjectById";
import ValueGetter from "../../ValueGetter";
import AutocompleteInput from "../../edit_cell/AutocompleteInput";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function ParentColumnDefinition(nodes) {
  return {
    field: 'parent',
    width: getColumnWidth('parent'),
    hideable: false,
    getRowClassName: getRowClassName,
    headerName: window.gettext('Source'),
    valueSetter: (params) => {
      let row = params.row

      // Set parent to the node.
      row.parent = getObjectById(nodes, params.value)

      return row
    },
    editable: true,
    valueGetter: (params) => {
      if (params.row.parent === null) {
        return null
      }

      return ValueGetter().getValueFromNode(
        params.row, 'node.parent.name'
      )
      //return params.row.parent.name
    },
    renderEditCell: (params) => {
      // Get options
      const options = nodes.filter((node) => {
        // Omit nodes that are the same as the current row id
        if (node.id === params.row.id) {
          return false
        }

        return true
      })

      // Get parent Id
      const parentId = ((parent) => {
        // When there is no parent we need to return undefined
        if(!parent){
          return undefined
        }

        // Otherwise, return the ID of parent
        return parent.id
      })(params.row.parent)

      // our own AutocompleteInput element.
      return <AutocompleteInput
        {...params}
        options={options}
        selectedOptionId={parentId}
        label={window.gettext('Source')}
      />
    }
  }
}