import BaseService from "../BaseService";

class SelfCheckJobMeasurementSummaryNodeService extends BaseService {
}

export default new SelfCheckJobMeasurementSummaryNodeService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-summary/node',
  ]
)