import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material"
import {useEffect, useState} from "react";
import {isMedianOutsideRange} from "./OutsideAcceptedRangeTable";
import EntityType from "../../entity_type/EntityType";


export default function OutsideAcceptedRangeSummaryTable({report, reportSettings}) {
  let [allLocations, setAllLocations] = useState([])

  useEffect(() => {
    if (!report) {
      return
    }

    const getDescendants = (r) => {
      return [[r]].concat(
        r?.children.map(getDescendants)
      ).flat()
    }

    setAllLocations(
      getDescendants(report.report)
    )
  }, [report])

  return (
    <>
      {report && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {window.gettext('Type')}
                  </TableCell>
                  <TableCell>
                    {window.gettext('Quantity')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportSettings.outside_threshold_summary_types.map(
                  (entity_type) => {
                    return (
                      <TableRow
                        key={'summary_table_row_' + entity_type}
                      >
                        <TableCell>
                          <EntityType entityType={entity_type}/>
                        </TableCell>
                        <TableCell>
                          {
                            allLocations.filter((location) => {
                              if (location.entity.type !== entity_type) {
                                return false
                              }
                              if (!location.room_temperature.median) {
                                return false
                              }
                              return isMedianOutsideRange(
                                location.room_temperature.median,
                                reportSettings.median_accepted_threshold_low,
                                reportSettings.median_accepted_threshold_high
                              )
                            }).length
                          }
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}
