import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import getColumnWidth from "../GetColumnWidth";

export default function ConnectionColumnDefinition() {
  return {
    field: 'connection',
    width: getColumnWidth('connection'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Connection'),
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('valve', 'node.item.type.name.name'),
          ValueGetterConf('pipe', 'node.item.type.name'),
          ValueGetterConf('radiator', 'node.item.valve_type.name'),
        ]
      ).get(params.row) || null
    })
  }
}