import {proxy} from "valtio";

const store = proxy(
  {
    user: null,
    language: null,
    refresh_token_requests_waiting: [],
    refresh_token_request_in_flight: false,
    drp_error_from_request_response: null,
  }
)

export default store
