import { useEffect } from "react";

import { DesktopMenu } from "../../components/menu";
import { useUser } from "../../context";
import { LayoutToolbarContextProvider } from "../context";
import { useLayoutBottom, useLayoutLeft } from "../hooks";
import { LayoutAppPlacement } from "./placement";

export function LayoutAppDesktop({ children }) {
  const { user } = useUser();

  const layoutLeft = useLayoutLeft();
  const layoutBottom = useLayoutBottom();

  useEffect(() => {
    if (!user) {
      // Remove the menu if the user is not logged in.
      layoutLeft.setElement(null);
      return;
    }

    // Add the menu if the user is logged in.
    layoutLeft.setElement(
      <DesktopMenu
        sx={{
          height: "100%",
        }}
      />
    );
  }, [user]);

  return (
    <LayoutToolbarContextProvider placement={layoutBottom}>
      <LayoutAppPlacement>{children}</LayoutAppPlacement>
    </LayoutToolbarContextProvider>
  );
}
