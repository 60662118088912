class ValueGetterConfClass {
  itemType
  keyPath
  notFoundReturnValue

  constructor(itemType, keyPath, notFoundReturnValue = undefined) {
    this.itemType = itemType
    this.keyPath = keyPath
    this.notFoundReturnValue = notFoundReturnValue
  }
}

export default function ValueGetterConf(itemType, keyPath, notFoundReturnValue) {
  return new ValueGetterConfClass(itemType, keyPath, notFoundReturnValue)
}