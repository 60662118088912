import EventExecutor from "./EventExecutor";
import EventResult from "./EventResult";
import RightArrowEvent from "./RightArrowEvent";
import GetColumnIndex from "./utils/GetColumnIndex";
import IsColumnIndex from "./utils/IsColumnIndex";
import GetColumnAtIndex from "./utils/GetColumnAtIndex";
import GetAdjacentColumn from "./utils/GetAdjacentColumn";

export default function EnterEvent() {
  return new EventExecutor(
    'enter',
    (eventData) => {
      return eventData.event.keyCode === 13
    },
    (eventData) => {
      // Get the next plugin
      console.log('firing the enter event', eventData)

      const cellMode = eventData.params.cellMode
      const currentColumn = GetColumnAtIndex(eventData)

      console.log('current col', currentColumn)

      if(eventData.params.field === "actions"){
        console.log('calling toggle menu', eventData);
        currentColumn.toggleMenu.callback(eventData.params.id, eventData.event)
        return EventResult(true);
      }

      // If the current column we press enter on is not editable, we do not care about the enter key
      if(currentColumn.editable !== true){
        return EventResult(false)
      }

      if (cellMode === 'view') {
        // If in view-mode, set the current cel to edit mode.
        eventData.apiRef.current.setCellMode(eventData.params.id, eventData.params.field, 'edit')

      } else if (cellMode === 'edit') {
        // If in edit mode, find the next column and set focus on that
        let nextColumn = GetAdjacentColumn(eventData, 1)

        if(nextColumn === undefined){
          nextColumn = GetColumnAtIndex(eventData, "first")
        }

        /**
         * The normal behaviour of Enter seems to be unaffected by what we do here.
         * Using a setTimeout trigger this function _after_ the normal behaviour has been executed.
         */
        setTimeout(
          () => {
            eventData.apiRef.current.setCellFocus(
              eventData.params.id,
              nextColumn.field
            )
          },
          100
        )
      }

      return EventResult(true)
    }
  )
}