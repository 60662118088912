import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import React from "react";
import WithSuffix from "../../../../values/WithSuffix";
import ValueSetter from "../../ValueSetter";
import getColumnWidth from "../GetColumnWidth";

export default function EffectPercentageColumnDefinition() {
  return {
    field: 'effect_percentage',
    width: getColumnWidth('effect_percentage'),
    editable: true,
    getRowClassName: getRowClassName,
    headerName: window.gettext('Effect percentage'),
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('radiator', 'node.item.effect_percentage')
        ]
      ).get(params.row)
    }),
    valueSetter: ValueSetter('node.item.effect_percentage'),
    valueParser: (value) => Number(value),
    renderCell: (params) => {
      if(isNaN(params.value) || params.value === null || params.value === 100){
        return ""
      }
      return <WithSuffix value={params.value} suffix="%" spaceBeforeSuffix={false}/>
    }
  }
}
