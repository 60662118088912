import { getComponentsHeaders } from "../../../helper";
import { HierarchyViewOption } from "../HierarchyViewOption";
import { HierarchyViewOptions } from "../HierarchyViewOptions";

export class HierarchyViewCEOptions extends HierarchyViewOptions {
  show_task_interface_linked_option = undefined;
  show_navigation_option = undefined;
  show_navigation_details_option = undefined;

  constructor(entityType, optionValues) {
    super(
      entityType,
      {
        show_done_only_checkbox: true,
        show_flagged_only_checkbox: true,
        show_partly_done_only_checkbox: true,
        show_visit_day_select: true,
      },
      optionValues
    );

    const SHOW_PREFIX = window.gettext("Show") + " ";

    this.show_task_interface_linked_option = new HierarchyViewOption(
      entityType,
      "show_task_interface_linked",
      SHOW_PREFIX + getComponentsHeaders(entityType).task_interface_linked,
      optionValues.show_task_interface_linked
    );

    this.show_navigation_option = new HierarchyViewOption(
      entityType,
      "show_navigation",
      SHOW_PREFIX + " " + getComponentsHeaders(entityType).navigation,
      optionValues.show_navigation
    );

    this.show_navigation_details_option = new HierarchyViewOption(
      entityType,
      "show_navigation_details",
      SHOW_PREFIX + " " + getComponentsHeaders(entityType).navigation_details,
      optionValues.show_navigation_details
    );
  }

  getCheckboxAdditionalOptions() {
    // List of checkbox options, controls order of options in UI
    const options = [
      this.show_task_interface_linked_option,
      this.show_navigation_option,
    ];

    if (this.show_navigation_option.value) {
      options.push(this.show_navigation_details_option);
    }

    return options;
  }
}
