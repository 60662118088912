import BaseService from "../BaseService";

class SelfCheckJobMeasurementOpportunityLatestService extends BaseService {
}

export default new SelfCheckJobMeasurementOpportunityLatestService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'measurement-opportunity-latest',
  ]
)