import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {object} from "prop-types";

import {useSnackbar} from "notistack";
import SelfCheckJobService from "../../../../services/SelfCheckJobService";

export default function FreezeCalculationsButton(props) {
  let [project, setProject] = useState(null)
  let [system, setSystem] = useState(null)
  let [job, setJob] = useState(null)
  let [disabled, setDisabled] = useState(false)

  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    setSystem(props.system)
    setJob(props.job)
    setProject(props.project)
  }, [props.system, props.project, props.job])


  const handleFetchCalculationButtonClick = (e) => {
    setDisabled(true)
    SelfCheckJobService.freeze(
      project.id,
      system.id,
      job.id
    ).then(
      (response) => {
        setDisabled(false)
        enqueueSnackbar(
          window.gettext('Calculation values are frozen and attached to job'),
          {
            'variant': 'success'
          }
        )
        if (props.hasOwnProperty('refreshJobData')) {
          props.refreshJobData()
        }
      },
      (rejection) => {
        setDisabled(false)
        enqueueSnackbar(
          window.gettext('Error while trying to freeze calculation values'),
          {
            'variant': 'error'
          }
        )
      }
    )
  }
  return (
    <>
      {(project && system) && (
        <>
          <Button
            variant="contained"
            onClick={handleFetchCalculationButtonClick}
            disabled={disabled}
          >
            {props.children}
          </Button>
        </>
      )}
    </>
  )
}

FreezeCalculationsButton.propTypes = {
  children: PropTypes.node,
  project: PropTypes.object,
  system: PropTypes.object,
  job: PropTypes.object,
  refreshJobData: PropTypes.func,
}
