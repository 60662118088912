import { COMPONENT_TYPES } from "../../constants";
import { FormComponent } from "./FormComponent";

export class FormField extends FormComponent {
  constructor(fieldType, name, label, value="", required=false, options={}) {
    super(COMPONENT_TYPES.FIELD, fieldType, options);
    this.fieldType = fieldType;
    this.name = name;
    this.label = label;
    this.value = value;
    this.required = required;
  }

  getRules = () => {
    if (this.required === true) {
      return { required: true };
    }
  };

  getLabel = () => {
    return "hello, wrold";
  };

  setOption = (key, value) => {
    if (!this.options) {
      this.options = {};
    }

    this.options[key] = value;
  };

  withHelpText = (text) => {
    this.setOption("helperText", text);
    return this;
  };

  withValue = (value) => {
    this.value = value;

    return this;
  };

  withDefaultValue = (defaultValue) => {
    if (!this.value) {
      this.value = defaultValue;
    }

    return this;
  };

  withChoices = (choices) => {
    /**
     * [
     *  ['value', 'label'],
     * ]
     */
    this.setOption("choices", choices);
    return this;
  };
}
