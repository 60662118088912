import Entity from "../../entity/Entity";
import {LocationLabel} from "./LocationLabel";

export function SelfCheckHierarchyViewLink({project_id, system_id, job_id, entity, locationTree}) {
  return (
    <>
      <Entity
        link={
          '/projects/' + String(project_id) + '/systems/' + String(system_id) + '/jobs/' + String(job_id) + '/hierarchy' +
          '?entity_type=' + entity.type + '&entity_id=' + String(entity.id)
        }
        entityData={entity}
      >
        <LocationLabel
          entity={entity}
          locationTree={locationTree}
        />
      </Entity>
    </>
  )
}