import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";

import ApartmentService from "../../../../services/ApartmentService";
import ChoicesService from "../../../../services/ChoicesService";
import InstalledValueService from "../../../../services/InstalledValueService";
import SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService from "../../../../services/measurement/SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService";
import JobBottomToolbar from "../job_bottom_toolbar/JobBottomDrawer";
import getCompleteEntityListFromJobData from "../utils/getCompleteEntityListFromJobData";
import CustomJobGridToolbar from "./CustomJobGridToolbar";

import { LayoutCard, useLayoutSize } from "../../../../../layout";
import { getCellClassName, getRowClassName, isCellEditable } from "./functions";
import { getColumnDefinitions, getColumnGroupDefinitions } from "./helper";

const LOCAL_STORAGE_KEY = "self_check_job_datagrid";

export const localStorageData = JSON.parse(
  localStorage.getItem(LOCAL_STORAGE_KEY)
) || {
  columnVisibilityModel: {},
  pinnedColumns: {},
};

function saveToLocalStorage(key, value) {
  localStorageData[key] = value;

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageData));
}

export default function JobDatagrid({
  jobData,
  refreshJobData,
  options,
  project,
  system,
  setOptions,
  setJobData,
  taskItemStateChange,
  taskStateChange,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const { mainHeight, isMobile } = useLayoutSize();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    localStorageData.columnVisibilityModel
  );
  const [pinnedColumns, setPinnedColumns] = useState(
    localStorageData.pinnedColumns
  );
  const [taskSpecEntityTypeChoices, setTaskSpecEntityTypeChoices] =
    useState(null);

  const [columns, setColumns] = useState();
  const [columnGroupingModel, setColumnGroupingModel] = useState();
  const [editRowsModel, setEditRowsModel] = useState({});
  const [rows, setRows] = useState();

  const iconSize = options.iconSize;

  useEffect(() => {
    ChoicesService.selfCheckTaskSpecChoices().then((response) => {
      setTaskSpecEntityTypeChoices(response.target_type);
    });
  }, []);

  useEffect(() => {
    if (!jobData || !taskSpecEntityTypeChoices) {
      return;
    }

    function fetchJobData() {
      if (refreshJobData) {
        refreshJobData();
        return true;
      }

      enqueueSnackbar(
        window.gettext("Error while refreshing data, could not refresh"),
        {
          variant: "error",
        }
      );
    }

    setColumnGroupingModel(getColumnGroupDefinitions(jobData));
    setColumns(
      getColumnDefinitions({
        enqueueSnackbar,
        fetchJobData,
        iconSize,
        jobData,
        options,
        project,
        system,
        taskItemStateChange,
        taskSpecEntityTypeChoices,
        taskStateChange,
      })
    );
    setRows(getCompleteEntityListFromJobData(jobData));
  }, [jobData, taskSpecEntityTypeChoices, options]);

  const handleCellClick = useCallback((params) => {
    if (!params.isEditable) {
      return;
    }

    // If the cell is editable, we want to set the editRowsModel to true
    setEditRowsModel({
      [params.id]: {
        [params.field]: true,
      },
    });
  }, []);

  const handleColumnVisibilityModelChange = useCallback((newModel) => {
    saveToLocalStorage("columnVisibilityModel", newModel);

    setColumnVisibilityModel(newModel);
  }, []);

  const handlePinnedColumnsChange = useCallback((newPinnedColumns) => {
    saveToLocalStorage("pinnedColumns", newPinnedColumns);

    setPinnedColumns(newPinnedColumns);
  }, []);

  const handleEditRowsModelChange = useCallback((newModel) => {
    setEditRowsModel(newModel);
  }, []);

  const setHiddenColumns = useCallback((hiddenColumns) => {
    const columnVisibilityModel = {};

    // We want to invert the values, so that we can use the DataGrid columnVisibilityModel
    for (const [key, value] of Object.entries(hiddenColumns)) {
      columnVisibilityModel[key] = !value;
    }

    saveToLocalStorage("columnVisibilityModel", columnVisibilityModel);

    setColumnVisibilityModel(columnVisibilityModel);
  }, []);

  function handleMeasurementDataChange(row, field, value) {
    const key = field.substring("measurement_".length);

    const latestMeasurementInstance =
      row.entity?.latest_measurement_instance || null;

    if (latestMeasurementInstance !== null) {
      const staleValue = latestMeasurementInstance[key];

      if (staleValue === value) {
        // Abort if the stale value is the same as the current value
        return;
      }
    }

    let data = {};
    // Set value that we shall send to the backend
    data[key] = value;

    SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService.update(
      data,
      project.id,
      system.id,
      jobData.job.id,
      row.entity.id,
      "carry_over=true"
    ).then(
      (measurementInstance) => {
        const newEntities = jobData.entities.map((entity) => {
          // go through all entities and for the mathing one, replace latest_measurement_instance
          if (entity.id === row.entity.id && entity.type === row.entity.type) {
            entity.latest_measurement_instance = measurementInstance;
          }

          return entity;
        });

        let newJobData = JSON.parse(JSON.stringify(jobData));

        newJobData.entities = newEntities;

        setJobData(newJobData);
      },
      (rejection) => {
        enqueueSnackbar(window.gettext("Unable to save measurement values"), {
          variant: "error",
        });
      }
    );
  }

  function handleInstalledValuesChange(row, field, newValue) {
    let data = {};
    const key = field.substring("installed_".length);

    console.log(row, field, newValue);

    data[key] = newValue || null;

    InstalledValueService.create(
      data,
      project.id,
      system.id,
      jobData.job.id,
      row.entity.id
    ).then((response) => {
      enqueueSnackbar(window.gettext("Installed value successfully saved"), {
        variant: "success",
      });
    });
  }

  function handleLocalApartmentNumberChange(row, newValue) {
    const entityId = row?.entity?.id;

    ApartmentService.update(
      {
        local_apartment_number: newValue,
      },
      project.id,
      system.id,
      entityId
    ).then((response) => {
      enqueueSnackbar(
        window.gettext("Local apartment number successfully saved"),
        {
          variant: "success",
        }
      );

      const newEntities = jobData.entities.map((entity) => {
        // go through all entities and for the mathing one, replace latest_measurement_instance
        if (entity.id === row.entity.id && entity.type === row.entity.type) {
          entity.data.local_apartment_number = response.local_apartment_number;
        }

        return entity;
      });

      let newJobData = JSON.parse(JSON.stringify(jobData));

      newJobData.entities = newEntities;

      setJobData(newJobData);
    });
  }

  function handleCellEditCommit(params) {
    const newValue = params.value;
    const field = params.field;
    const row = params.row;

    // Note that measurement_comment is saved inside the component that is rendered in the cell
    if (
      [
        "measurement_inflow_temperature",
        "measurement_return_temperature",
        "measurement_room_temperature",
      ].includes(field)
    ) {
      handleMeasurementDataChange(row, field, newValue);
    }

    if (["installed_nipple", "installed_kv"].includes(field)) {
      handleInstalledValuesChange(row, field, newValue);
    }

    if (["local_apartment_number"].includes(field)) {
      handleLocalApartmentNumberChange(row, newValue);
    }
  }

  return (
    <>
      {rows && columns && (
        <LayoutCard
          noPadding
          mb={0}
          sx={{
            height: mainHeight,
            overflow: "hidden",
          }}
        >
          <DataGridPro
            columnGroupingModel={columnGroupingModel}
            columns={columns}
            columnVisibilityModel={columnVisibilityModel}
            components={{ Toolbar: CustomJobGridToolbar }}
            editRowsModel={editRowsModel}
            rows={rows}
            disableSelectionOnClick={true}
            getCellClassName={getCellClassName}
            getRowClassName={getRowClassName}
            isCellEditable={isCellEditable}
            onCellClick={handleCellClick}
            onCellEditCommit={handleCellEditCommit}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onEditRowsModelChange={handleEditRowsModelChange}
            onPinnedColumnsChange={handlePinnedColumnsChange}
            pinnedColumns={pinnedColumns}
            experimentalFeatures={{
              columnGrouping: true,
            }}
            sx={{
              border: "none",
            }}
            {...(isMobile && {
              headerHeight: 30,
              hideFooter: true,
            })}
          />
        </LayoutCard>
      )}

      {project && system && jobData && (
        <JobBottomToolbar
          job={jobData.job}
          onHiddenColumnsChange={setHiddenColumns}
          onOptionsChange={setOptions}
          options={options}
          project={project}
          refreshJobData={refreshJobData}
          system={system}
        />
      )}
    </>
  );
}
