import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import InstanceManipulator from "../InstanceManipulator";
import BasicModal from "../layout/BasicModal";
import ConfirmableButton from "../layout/ConfirmableButton";
import ProjectRowService from "../services/ProjectRowService";
import Currency from "../values/Currency";
import ProjectRowForm from "./ProjectRowForm";

function CustomFooterStatusComponent(props) {
  return <span></span>;
}

export default function ProjectRows(props) {
  const [categoryChoices, setCategoryChoices] = useState();
  const [datagridRows, setDatagridRows] = useState();
  const [projectRowsTotalPrice, setProjectRowsTotalPrice] = useState(undefined);

  const { enqueueSnackbar } = useSnackbar();

  const projectId = props.projectId;

  useEffect(() => {
    setCategoryChoices(props.categoryChoices);
    setDatagridRows(props.rows);
  }, [props.rows, props.categoryChoices]);

  useEffect(() => {
    ProjectRowService.summary(projectId).then((response) => {
      setProjectRowsTotalPrice(response.total_price);
    });
  }, [datagridRows, projectId]);

  const deleteRow = function (id) {
    ProjectRowService.destroy(projectId, id).then((response) => {
      setDatagridRows(
        datagridRows.filter((row) => {
          return row.id !== id;
        })
      );
    });
  };

  const datagridColumns = [
    {
      field: "id",
      headerName: window.gettext("Id"),
    },
    {
      field: "label",
      headerName: window.gettext("Name"),
    },
    {
      field: "category",
      width: 200,
      headerName: window.gettext("Category"),
      renderCell: (params) => {
        let l = "";

        Object.entries(categoryChoices).map(([name, label], index) => {
          if (params.value === name) {
            l = label;
          }
        });

        return l;
      },
    },
    {
      field: "as_needed",
      headerName: window.gettext("As needed"),
      type: "boolean",
    },
    {
      field: "to_install",
      headerName: window.gettext("To install"),
      type: "boolean",
    },
    {
      field: "price",
      headerName: window.gettext("Price"),
      renderCell: (params) => {
        return <Currency value={params.value} precision={0} />;
      },
    },
    {
      field: "count",
      headerName: window.gettext("Count"),
    },
    {
      field: "total_price",
      headerName: window.gettext("Total price"),
      renderCell: (params) => {
        return <Currency value={params.value} precision={0} />;
      },
    },
    {
      field: "delete",
      headerName: window.gettext("Delete"),
      renderCell: (params) => {
        const handleDeleteButtonClicked = function (event) {
          deleteRow(params.row.id);
        };
        return (
          <>
            <ConfirmableButton>
              <IconButton
                color="error"
                aria-label="delete row"
                component="span"
                onClick={handleDeleteButtonClicked}
              >
                <DeleteIcon />
              </IconButton>
            </ConfirmableButton>
          </>
        );
      },
    },
    {
      field: "edit",
      headerName: window.gettext("Edit"),
      renderCell: (params) => {
        return (
          <BasicModal
            toggleButton={
              <IconButton color="primary">
                <EditIcon />
              </IconButton>
            }
            width={"auto"}
          >
            <InstanceManipulator
              service={ProjectRowService}
              preViewTransformer={ProjectRowService.preViewDataTransformer}
              urlParams={[projectId, params.row.id]}
              mode="edit"
              postUpdate={() => {
                enqueueSnackbar(window.gettext("Saved project row"), {
                  variant: "success",
                });
                props.refreshProjectCallback();
              }}
              form={
                <ProjectRowForm
                  submitButtonText={window.gettext("Save")}
                  categoryChoices={categoryChoices}
                />
              }
            />
          </BasicModal>
        );
      },
    },
  ];

  return (
    <>
      {(datagridRows !== undefined) & (categoryChoices !== undefined) && (
        <DataGridPro
          autoHeight
          columns={datagridColumns}
          rows={datagridRows}
          disableSelectionOnClick
          hideFooterRowCount={true}
          hideFooter={true}
          components={{
            Footer: CustomFooterStatusComponent,
          }}
        />
      )}

      <Stack
        sx={{
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          p: 1,
        }}
      >
        <span>{window.gettext("Total price:")}</span>
        <Currency value={projectRowsTotalPrice} precision={0} />
      </Stack>
    </>
  );
}
