import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

export default function KVValue(props) {
  let [value, setValue] = useState(undefined)


  // Set the rounded string value
  useEffect(() => {
    // Convert from input value to millimeters
    if (!props.value) {
      return
    }

    // Display string, fixed points
    setValue(
      props.value
    )
  }, [props.value])

  return (
    <>
      <WithSuffix value={value} precision={3}/>
    </>
  )
}