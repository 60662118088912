const measurementInflowTemperatureClassName =
  "drp-job-datagrid-measurement-cell-inflow-temperature";
const measurementReturnTemperatureClassName =
  "drp-job-datagrid-measurement-cell-return-temperature";
const measurementRoomTemperatureClassName =
  "drp-job-datagrid-measurement-cell-room-temperature";

export function getCellClassName(params) {
  const classNames = [];

  if (["valve_node", "radiator_node"].includes(params?.row?.entity?.type)) {
    if (params?.field === "measurement_room_temperature") {
      classNames.push(measurementRoomTemperatureClassName);
    }
    if (params?.field === "measurement_inflow_temperature") {
      classNames.push(measurementInflowTemperatureClassName);
    }
    if (params?.field === "measurement_return_temperature") {
      classNames.push(measurementReturnTemperatureClassName);
    }
  }

  return classNames;
}
