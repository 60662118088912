import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";

export function FactoryBooleanField({ formField, helperText }) {
  return ({ field }) => (
    <Stack {...formField.options.props}>
      <FormControlLabel
        {...field}
        required={formField.required}
        control={
          <Checkbox
            checked={field.value === true}
            data-test-id={formField.name}
          />
        }
        label={formField.label}
      />
      {helperText && (
        <>
          <FormHelperText>{helperText}</FormHelperText>
        </>
      )}
    </Stack>
  );
}
