import CSVImporter from "../../../../components/import/csv/CSVImporter";

import { LayoutView } from "../../../../layout";

export default function ImportCSVValveTypeView() {
  return (
    <LayoutView header={window.gettext("Import Valve type from CSV document")}>
      <CSVImporter importerApiEndpoint="/api/import/csv/valve-type/" />
    </LayoutView>
  )
}