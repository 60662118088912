import {useEffect, useState} from "react";
import WithSuffix from "./WithSuffix";

/*
* props.value = the value, always in cubic centimeters
* props.precision = how many decimal points we should display
* */
export default function Volume(props) {
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(1)
  const suffix = "l"


  // Set the rounded string value
  useEffect(() => {
    // Convert from input value to litres
    let value = props.value / 1000

    // Display string, fixed points
    setDisplayValue(
      value
    )
  }, [props.value])

  // setting precision
  useEffect(() => {
    let defaultPrecision = 1

    setPrecision(props.precision === undefined ? defaultPrecision : props.precision)
  }, [props.precision])

  return (
    <WithSuffix value={displayValue} suffix={suffix} precision={precision} />
  )
}