import {Button, Grid, TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import BasicModal from "../../../../layout/BasicModal";
import SaveIcon from "@mui/icons-material/Save";
import Typography from "@mui/material/Typography";
import SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService
  from "../../../../services/measurement/SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService";
import {useSnackbar} from "notistack";

export default function JobDatagridMeasurementInstanceCommentCell(props) {
  let {id, field, value, api, row, projectId, systemId, jobId, iconSize, options} = props

  const {enqueueSnackbar} = useSnackbar()

  let [modalOpen, setModalOpen] = useState(false)
  let [disableSaveButton, setDisableSaveButton] = useState(false)
  let [displayValue, setDisplayValue] = useState(value || "")

  const handleOnChange = function (event) {
    // Change the value we display
    setDisplayValue(event.target.value)
  }
  const handleSaveButtonClick = function (event) {
    setDisableSaveButton(true)

    SelfCheckJobMeasurementOpportunityLatestCurrentInstanceForNodeService.update(
      {
        'comment': displayValue
      },
      projectId,
      systemId,
      jobId,
      row.entity.id,
      'carry_over=true',
    ).then(
      (measurementInstance) => {
        setDisableSaveButton(false)

        setDisplayValue(measurementInstance.comment)

        enqueueSnackbar(
          window.gettext("Comment saved"),
          {
            'variant': 'success'
          }
        )

        setModalOpen(false)
      },
      (rejection) => {
        setDisableSaveButton(false)

        enqueueSnackbar(
          window.gettext("Unable to save measurement values"),
          {
            'variant': 'error'
          }
        )

        setModalOpen(false)
      }
    )

  }

  const handleModalClose = function (event) {
    setDisplayValue(value)
  }

  return (
    <>
      <BasicModal
        open={modalOpen}
        setOpen={setModalOpen}
        toggleButton={
          options?.show_comments ? (
            <Typography
              sx={{
                height: displayValue === "" ? "100%" : "auto",
                width: "100%",
              }}
            >
              {displayValue}
            </Typography>
          ) : (
            <IconButton>
              {displayValue === "" ? (
                <>
                  <CommentIcon fontSize={iconSize || null} color="lowtone" />
                </>
              ) : (
                <>
                  {/*options?.show_comments && <Typography>{displayValue}</Typography>*/}

                  <Tooltip title={displayValue}>
                    <CommentIcon
                      fontSize={iconSize || null}
                      color={"success"}
                    />
                  </Tooltip>
                </>
              )}
            </IconButton>
          )
        }
        onOpen
        onClose={
          handleModalClose
        }
      >
        <>
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant={"h4"}>
                {window.gettext('Comment')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                value={displayValue}
                onChange={handleOnChange}
                multiline
                rows={10}
                fullWidth
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                startIcon={<SaveIcon/>}
                variant={"contained"}
                color={"success"}
                disabled={disableSaveButton}
                onClick={handleSaveButtonClick}
                sx={{mr: 2}}
              >
                {window.gettext('Save')}
              </Button>
            </Grid>
          </Grid>

        </>
      </BasicModal>
    </>
  )
}