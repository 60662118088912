import { LayoutCardDesktop } from "./LayoutCardDesktop";

export function LayoutCardMobile({
  children,
  mb = 1,
  menuItems,
  doNotPrint,
  noPadding,
  title,
  titleExtra,
  sx,
  ...props
}) {
  return (
    <LayoutCardDesktop
      borderRadius={0}
      mb={mb}
      menuItems={menuItems}
      doNotPrint={doNotPrint}
      headerVariant={"h4"}
      noPadding={noPadding}
      title={title}
      titleExtra={titleExtra}
      sx={sx}
      {...props}
    >
      {children}
    </LayoutCardDesktop>
  );
}
