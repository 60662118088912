import React, {useEffect, useState} from 'react';

import {Link as RouterLink, useNavigate} from 'react-router-dom';


import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from '@mui/icons-material/Menu';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";

import store from "../../state";
import {subscribe} from "valtio";
import axios from "axios";
import {AccountCircle} from "@mui/icons-material";
import Link from "@mui/material/Link";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import menu_logo from "../../assets/logo/assemblin/assemblin_energioptimering.png"

import LogoutIcon from '@mui/icons-material/Logout';
import {Grid} from "@mui/material";

export const MAIN_MENU_HEIGHT = 70;

export default function Menu(props) {
  let [user, setUser] = useState(store.user)
  let [userMenuOpen, setUserMenuOpen] = useState(false)
  let [sideMenuOpen, setSideMenuOpen] = useState(false)

  let [sideMenuAnchorEl, setSideMenuAnchorEl] = useState(null);
  let [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  let navigate = useNavigate()

  let unsubscribe

  useEffect(() => {
      // Subscribe to store changes
      unsubscribe = subscribe(store, () => {
        setUser(store.user)
      })

      return () => {
        unsubscribe();
      }
    }
  )

  let handleLogoutClick = function () {
    // Null user in the global store
    axios.post(
      '/api/auth/token/blacklist/',
      {
        "refresh": localStorage.getItem('refresh-token')
      }
    ).then(
      (response) => {
        store.user = null;
        localStorage.setItem('refresh-token', "");
        localStorage.setItem('access-token', "");
        navigate('/login');
      },
      (error) => {
        console.error('Error logging out')
        console.error(error)
        localStorage.setItem('refresh-token', "");
        localStorage.setItem('access-token', "");
      }
    );
  }

  let handleClickUserMenuLogout = function () {
    handleCloseUserMenu()
    handleLogoutClick()
  }

  let handleMenuClickAccountButton = function (event) {
    setUserMenuAnchorEl(event.currentTarget)
    setUserMenuOpen(true)
  }

  let handleCloseUserMenu = function () {
    setUserMenuOpen(false)
  }

  let toggleSideMenuDrawer = function (event) {
    setSideMenuAnchorEl(sideMenuAnchorEl ? null : event.currentTarget)
    setSideMenuOpen(!sideMenuOpen)
    setUserMenuOpen(false)
  };


  return (
    <div>
      {/*
      Side menu
      */}
      <div>
        <SwipeableDrawer
          anchor='left'
          open={sideMenuOpen}
          onClose={toggleSideMenuDrawer}
          onOpen={toggleSideMenuDrawer}
        >
          <Box
            sx={{width: 250}}
            data-test-id="main-menu-drawer"
            role="presentation"
            onClick={toggleSideMenuDrawer}
          >
            <List>
              <ListItem button key="projects" component={RouterLink} to="/projects/">
                <ListItemIcon>
                  <AssignmentIcon/>
                </ListItemIcon>
                <ListItemText primary={window.gettext("Projects")}/>
              </ListItem>
            </List>
            <Divider/>
            <List>
              <ListItem button key="profile" component={RouterLink} to="/account/">
                <ListItemIcon>
                  <AccountCircle/>
                </ListItemIcon>
                <ListItemText primary={window.gettext("Profile")}/>
              </ListItem>
            </List>
          </Box>
        </SwipeableDrawer>
      </div>
      {/*
      END side menu
      */}
      <Box
        sx={
          {
            display: 'none',
            displayPrint: 'block',
          }
        }
      >
        <Grid
          container
          alignItems={'center'}
          sx={{mb: 2}}
          spacing={2}
        >
          <Grid item>
            <img src={menu_logo} alt="Logotype" style={{maxWidth: 100}}/>
          </Grid>
          <Grid item>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1, displayPrint: 'none'}}>
        <AppBar position="static" color="secondary">
          <Toolbar>
            {/*
            Login / Profile menu button
            */}
            {user == null ? (
              <></>
            ) : (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                data-test-id="main-menu-button"
                sx={{mr: 2}}
                onClick={toggleSideMenuDrawer}
              >
                <MenuIcon/>
              </IconButton>
            )}
            <Link component={RouterLink} to="/">
              <img src={menu_logo} alt="Logotype" style={{maxHeight: 40}}/>
            </Link>
            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
              {user == null ? (
                <></>
              ) : (
                <></>
              )}
            </Typography>
            {/*
            User menu
            */}
            {user == null ? (
              <Button color="inherit" component={RouterLink} to="/login" data-test-id="menu-login-button">
                {window.gettext("Log in")}
              </Button>
            ) : (
              <div>
                <IconButton
                  size="large"
                  color="inherit"
                  data-test-id="menu-account-button"
                  onClick={handleMenuClickAccountButton}
                  aria-haspopup="true"
                >
                  <AccountCircle/>
                </IconButton>
                <Popper
                  open={userMenuOpen}
                  anchorEl={userMenuAnchorEl}
                  role={undefined}
                  placement="top-start"
                  transition
                  disablePortal
                  style={{zIndex: 10}}
                >
                  {({TransitionProps, placement}) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: 'right top'
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleCloseUserMenu}>
                          <MenuList
                            autoFocusItem={userMenuOpen}
                            id="user-menu"
                            data-test-id="user-menu"
                            aria-labelledby="user-button"
                          >
                            <MenuItem onClick={handleCloseUserMenu} component={RouterLink} to="/account"
                                      data-test-id="user-menu-button-profile">
                              <ListItemIcon>
                                <AccountCircle/>
                              </ListItemIcon>
                              <ListItemText>
                                {window.gettext("Profile")}
                              </ListItemText>
                            </MenuItem>

                            <MenuItem onClick={handleClickUserMenuLogout} data-test-id="user-menu-button-logout">
                              <ListItemIcon>
                                <LogoutIcon/>
                              </ListItemIcon>
                              <ListItemText>
                                {window.gettext("Log out")}
                              </ListItemText>
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            )}
            {/*
            END user menu
            */}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  )
}