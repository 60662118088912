import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Nipple from "../../../../../values/Nipple";

export default function JobCalculationReportSummary({ report }) {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{window.gettext("Nipple")}</TableCell>
            <TableCell>{window.gettext("Number of usages")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(report.by_nipple).map(([nipple_id, entities], id) => {
            return (
              <TableRow key={"nipple_" + String(nipple_id)}>
                <TableCell>
                  <Nipple id={nipple_id} />
                </TableCell>
                <TableCell>{entities.length}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
