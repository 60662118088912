import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import React, {useEffect} from "react";
import IssueCommentService from "../services/IssueCommentService";
import DateTime from "../values/DateTime";
import LoadingIndicator from "../layout/LoadingIndicator";
import MultilineText from "../layout/MultilineText";
import {useIssueComments} from "./IssueCommentListContext";

export default function IssueCommentList({}) {
  const {issueComments} = useIssueComments()

  return (
    <>
      {issueComments === undefined ? (
        <LoadingIndicator/>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {window.gettext("User")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Comment")}
                  </TableCell>
                  <TableCell>
                    {window.gettext("Created")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {issueComments.map(
                  (issueComment) => (
                    <TableRow
                      key={issueComment.id}
                    >
                      <TableCell>
                        {issueComment.user_name}
                      </TableCell>
                      <TableCell>
                        <MultilineText>
                          {issueComment.comment}
                        </MultilineText>
                      </TableCell>
                      <TableCell>
                        <DateTime value={issueComment.created}/>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}