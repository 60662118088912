import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Customer from "../../../components/customer/Customer";
import Project from "../../../components/project/Project";
import ChoicesService from "../../../components/services/ChoicesService";
import ProjectService from "../../../components/services/ProjectService";
import UserService from "../../../components/services/UserService";
import User from "../../../components/user/User";
import UserPermission from "../../../components/user/UserPermission";
import getUserHasPermission from "../../../components/user/getUserHasPermission";
import Choice from "../../../components/values/Choice";

import { useUser } from "../../../context";
import { LayoutCard, LayoutView, useLayoutSize } from "../../../layout";

const QUERY_HAS_ACCESS = "has_access";
const QUERY_SYSTEMS_RADIATOR_COUNT = "systems_radiator_count";

/**
 * Sorts an array of projects based on multiple criteria:
 * 1. Sorts by 'code' in descending order, where 'code' can be null, a number, or a string followed by a number.
 * 2. If 'code' is the same or missing, sorts by 'name' in ascending order.
 *
 * @param {Array} projects - The array of projects to be sorted.
 * @returns {Array} - The sorted array.
 */
export function sortProjects(projects) {
  return projects.sort((a, b) => {
    // Normalize code values for comparison
    const normalizeCode = (code) => {
      if (typeof code === "number") return `${code}`;
      if (typeof code === "string") return code;
      return null; // Handle null values
    };

    const aCode = normalizeCode(a.code);
    const bCode = normalizeCode(b.code);

    // Compare codes if both are present, in descending order
    if (aCode !== null && bCode !== null) {
      if (aCode > bCode) return -1; // Change for descending order
      if (aCode < bCode) return 1; // Change for descending order
    } else if (aCode !== null) {
      // a has a code but b does not
      return -1;
    } else if (bCode !== null) {
      // b has a code but a does not
      return 1;
    }

    // If codes are the same or missing, compare names in ascending order
    return a.name.localeCompare(b.name);
  });
}

function CustomProjectToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarFilterButton />

      <GridToolbarExport
        csvOptions={{
          delimiter: ";",
          fileName: "DRP-Projects",
          allColumns: true,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}

export default function ProjectsOverviewTableView() {
  const navigate = useNavigate();

  const { user } = useUser();
  const { mainHeight, isMobile } = useLayoutSize();

  const [columns, setColumns] = useState(null);
  const [filteredRows, setFilteredRows] = useState(undefined);
  const [rows, setRows] = useState(undefined);

  const [filterUser, setFilterUser] = useState("");
  const [users, setUsers] = useState(undefined);
  const [searchText, setSearchText] = useState("");

  const [showRadiatorCount, setShowRadiatorCount] = useState(false);
  const [showClosed, setShowClosed] = useState(false);

  const [projectChoices, setProjectChoices] = useState(null);

  useEffect(() => {
    if (!projectChoices) {
      return;
    }

    const columns = [
      // Name
      {
        field: "name",
        disableExport: true,
        width: 350,
        disableColumnMenu: true,
        headerName: window.gettext("Name"),
        renderCell: (params) => {
          return <Project project={params.row} />;
        },
      },
      // Stage
      {
        field: "stage",
        width: 200,
        headerName: window.gettext("Stage"),
        renderCell: (params) => {
          return (
            <Choice
              value={params.row.stage}
              choices={projectChoices}
              namespace="stage"
            />
          );
        },
        type: "singleSelect",
        valueOptions: Object.entries(projectChoices?.stage || {}).map(
          ([key, value]) => {
            console.log("key", key, "value", value);
            return {
              label: value,
              value: key,
            };
          }
        ),
      },
      // Customer
      {
        field: "customer",
        width: 200,
        editable: false,
        headerName: window.gettext("Customer"),
        renderCell: (params) => {
          return <Customer customer={params.row.customer} />;
        },
        disableColumnMenu: true,
      },
      // Sales person
      {
        field: "sales_person",
        width: 200,
        editable: false,
        headerName: window.gettext("Sales person"),
        renderCell: (params) => {
          return <User user={params.row.sales_person} />;
        },
        disableColumnMenu: true,
      },
      // Chief designer
      {
        field: "chief_designer",
        width: 200,
        editable: false,
        headerName: window.gettext("Chief designer"),
        valueGetter: (params) => {
          return params.row.chief_designer
        },
        renderCell: (params) => {
          return <User user={params.value} />;
        },
        disableColumnMenu: true,
      },
      // Number of apartments
      {
        field: "number_of_apartments",
        width: 200,
        disableColumnMenu: true,
        headerName: window.gettext("Apartment count"),
      },
      // Number of radiators
      {
        field: "number_of_radiators",
        width: 200,
        disableColumnMenu: true,
        headerName: window.gettext("Radiator count"),
      },
    ];

    if (showRadiatorCount === true) {
      columns.push({
        field: "systems_radiator_count",
        width: 200,
        disableColumnMenu: true,
        headerName: window.gettext("Radiators in systems"),
      });
    }

    setColumns(columns);
  }, [projectChoices, showRadiatorCount]);

  useEffect(() => {
    ChoicesService.projectChoices().then(setProjectChoices);
  }, []);

  useEffect(() => {
    // If the user has permissions to view user, then we list users
    if (getUserHasPermission(user, UserPermission.permissions.auth_user_view)) {
      UserService.list().then(setUsers);
    } else {
      setUsers([]);
    }
  }, [user]);

  useEffect(() => {
    const query_parts = [];

    if (filterUser !== "") {
      query_parts.push(QUERY_HAS_ACCESS + "=" + String(filterUser));
    }

    if (showRadiatorCount !== undefined) {
      // Ask for radiators count
      // This is a quite expensive database call and shall not be used if not needed
      query_parts.push(QUERY_SYSTEMS_RADIATOR_COUNT + "=" + showRadiatorCount);
    }

    const query = query_parts.length > 0 ? "?" + query_parts.join("&") : "";

    ProjectService.list(query).then((response) => {
      if (response === undefined) {
        setRows(undefined);
      } else {
        setRows(sortProjects(response));
      }
    });
  }, [filterUser, showRadiatorCount]);

  useEffect(() => {
    if (rows === undefined) {
      return;
    }

    let filteredRows = rows;

    // Filter out closed projects
    if (!showClosed) {
      filteredRows = filteredRows.filter((row) => row.stage !== "finished");
    }

    // Filter by search text
    if (searchText !== "") {
      const searchTextLower = searchText.toLowerCase();

      filteredRows = filteredRows.filter(
        (row) =>
          // Name
          (row.name !== null &&
            row.name.toLowerCase().includes(searchTextLower)) ||
          // Code
          (row.code !== null &&
            row.code.toLowerCase().includes(searchTextLower)) ||
          // Customer
          (row.customer !== null &&
            row.customer.name.toLowerCase().includes(searchTextLower)) ||
          // Sales person
          (row.sales_person !== null &&
            row.sales_person.label.toLowerCase().includes(searchTextLower))
      );
    }

    setFilteredRows(filteredRows);
  }, [rows, setFilteredRows, searchText, showClosed]);

  const handleOnChangeFilterUser = useCallback((event) => {
    setFilterUser(event.target.value);
  }, []);

  const handleOnChangeSearchText = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const handleOnClickShowClosedProjects = useCallback(() => {
    setShowClosed((prev) => !prev);
  }, []);

  const handleOnClickShowRadiatorCount = useCallback(() => {
    setShowRadiatorCount((prev) => !prev);
  }, []);

  return (
    <LayoutView
      header={window.gettext("Projects")}
      submenuItems={
        <Stack
          sx={{
            flexDirection: "row",
            flexGrow: 1,
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Stack
            sx={{
              flexBasis: 500,
              flexDirection: "row",
              flexGrow: 1,
              maxWidth: 500,
              gap: 1,
            }}
          >
            {/* User */}
            <UserPermission
              permission={
                UserPermission.permissions
                  .project_view_list_of_other_users_projects
              }
            >
              {users !== undefined && (
                <FormControl
                  sx={{
                    flexBasis: 250,
                    flexGrow: 1,
                  }}
                >
                  <InputLabel size="small">
                    {window.gettext("Select user")}
                  </InputLabel>

                  <Select
                    label={window.gettext("Select user")}
                    color="secondary"
                    size="small"
                    value={filterUser}
                    onChange={handleOnChangeFilterUser}
                  >
                    <MenuItem value="">All users</MenuItem>

                    {users.map((u) => {
                      return (
                        <MenuItem key={u.id} value={u.id}>
                          <User user={u} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </UserPermission>

            {/* Searchfield */}
            <TextField
              onChange={handleOnChangeSearchText}
              size="small"
              sx={{
                flexBasis: 250,
                flexGrow: 1,
              }}
              value={searchText}
            />
          </Stack>

          {/* Checkboxes */}
          <Stack
            sx={{
              flexBasis: 350,
              flexDirection: "row",
              flexGrow: 5,
              gap: 3,
            }}
          >
            {/* Show closed projects */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showClosed}
                  onClick={handleOnClickShowClosedProjects}
                  sx={{
                    marginLeft: 2,
                  }}
                />
              }
              label={window.gettext("Closed")}
            />

            {/* Show Radiators in system */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showRadiatorCount}
                  onClick={handleOnClickShowRadiatorCount}
                />
              }
              label={window.gettext("Radiators in system")}
            />
          </Stack>

          <UserPermission
            anyPermission={[UserPermission.permissions.project_add]}
          >
            <Stack
              sx={{
                flexDirection: "row",
                flexGrow: 1,
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <UserPermission
                permission={UserPermission.permissions.project_add}
              >
                <Button
                  variant="outlined"
                  onClick={() => navigate("/projects/create")}
                  sx={{ width: 150 }}
                >
                  {window.gettext("Create project")}
                </Button>
              </UserPermission>
            </Stack>
          </UserPermission>
        </Stack>
      }
    >
      <LayoutCard
        noPadding
        mb={0}
        sx={{
          height: mainHeight,
          overflow: "hidden",
        }}
      >
        {filteredRows !== undefined && (
          <DataGridPro
            columns={columns}
            disableSelectionOnClick
            hideFooter
            rows={filteredRows}
            components={{
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  {window.gettext("No projects found")}
                </Stack>
              ),
              Toolbar: isMobile ? undefined : CustomProjectToolbar,
            }}
          />
        )}
      </LayoutCard>
    </LayoutView>
  );
}
