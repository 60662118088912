import {ListItem, ListItemText, Menu} from "@mui/material";
import React, {useEffect, useState} from "react";
import SystemBuildPresetService from "../../../services/SystemBuildPresetService";
import Button from "@mui/material/Button";
import MenuIcon from '@mui/icons-material/Menu';
import ColumnDefinitions from "../ColumnDefinitions";
import GroupingColumnDefinition from "../column_definitions/columns/GroupingColumnDefinition";

export default function PresetMenu(props) {
  let [systemBuildPresets, setSystemBuildPresets] = useState(undefined);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSystemBuildPresetSelect = function (presetId) {
    handleClose()

    const chosen_preset = systemBuildPresets.find((preset) => {
      return preset.id === presetId;
    })

    if (!chosen_preset) {
      return
    }
    const all_column_definitions = [
      GroupingColumnDefinition(),
    ].concat(
      ColumnDefinitions()
    )

    // Column specific settings, call the callback with new values for column settings
    props.onColumnSettingsChange(
      // Go through all defined queries
      all_column_definitions.map((columnDefinition) => {
        // 
        const preset_column_settings = chosen_preset.column_settings.find(
          (p) => {
            return p.field === columnDefinition.field
          }
        )

        let column_setting = {
          'hide': chosen_preset.default_column_hidden
        }

        // If we specify a default hidden, set that on all columns
        if (preset_column_settings !== undefined) {
          column_setting['hide'] = preset_column_settings.hide

          if (preset_column_settings.width !== null) {
            column_setting['width'] = preset_column_settings.width
          }
        }

        return {
          'field': columnDefinition.field,
          'settings': column_setting
        }
      })
    )
  }

  useEffect(() => {
    SystemBuildPresetService.list().then(setSystemBuildPresets)
  }, [])

  return (
    <>
      {systemBuildPresets !== undefined && systemBuildPresets.length > 0 && (
        <>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="outlined"
            startIcon={<MenuIcon/>}
          >
            {window.gettext('Presets')}
          </Button>
          <Menu
            data-test-id="system-build-preset-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              'sx': {
                'minWidth': '200px'
              }
            }}
          >
            {systemBuildPresets.map((preset, index) => {
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    handleSystemBuildPresetSelect(preset.id)
                  }}
                >
                  <ListItemText
                    primary={preset.name}
                    secondary={preset.description || undefined}
                  />
                </ListItem>
              )
            })}
          </Menu>
        </>
      )}
    </>
  )
}