import BaseService from "./BaseService";

class SystemHeatCurvePointService extends BaseService {}

export default new SystemHeatCurvePointService('/api/',
  [
    'projects',
    'systems',
    'heat-curves',
    'points'
  ]
)