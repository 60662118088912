import checkableGetServiceMethod from "./checkableGetServiceMethod";

export default function sendCheckableRequest(
  project,
  system,
  job,
  service,
  spec,
  entity_type,
  entity_id,
  resource,
  data,
  originates_from_task_spec,
  query,
) {
  /**
   * resource: The Task/TaskItem where we want to update (if null, will create a new)
   * @type {string}
   */

  const spec_id_key = originates_from_task_spec ? 'task_spec_id' : 'task_item_spec_id'

  if (spec === null) {
    console.error('Could not find spec', resource)
  }

  // Setting the task_item_spec/task_spec - id
  data[spec_id_key] = spec.id

  const entity_data = {
    'entity_type': entity_type,
    'entity_id': entity_id,
  }

  // Post/Update
  const method = checkableGetServiceMethod(service, resource)

  // Send
  return method(
    {
      ...data,
      ...entity_data,
    },
    project.id,
    system.id,
    job.id,
    resource?.id || (query ? "?" + query : null),
    resource?.id ? (query ? "?" + query : undefined) : undefined
  );
}