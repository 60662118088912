import { Stack, Typography } from "@mui/material";

import CSVImporter from "./CSVImporter";

export function ImportCSVPotableWaterSystem({
  system_id
}) {
  return (
    <Stack>
      <Typography variant="h3">
        {window.gettext("Import apartments from CSV document")}
      </Typography>

      <CSVImporter
        importerApiEndpoint={
          "/api/import/csv/apartment/system/" + system_id + "/"
        }
      />
    </Stack>
  );
}
