import {Box, Stack, Typography} from "@mui/material";

export default function BaseInfoTabSectionItem({ title, children }) {
  if (!children) {
    return null;
  }

  const doRenderAsBodyText =
    typeof children === "string" || typeof children === "number";

  return (
    <Stack
      direction="row"
      sx={{ flexWrap: "wrap", justifyContent: "space-between" }}
    >
      <Typography variant="h5" sx={{ flexBasis: 300 }}>
        {title}
      </Typography>

      <Box sx={{ maxWidth: 600, flexGrow: 1 }}>
        {doRenderAsBodyText ? (
          <Typography variant="body">{children}</Typography>
        ) : (
          children
        )}
      </Box>
    </Stack>
  );
}