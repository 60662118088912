import { LayoutViewDesktop } from "./LayoutViewDesktop";

export function LayoutViewMobile({
  children,
  header,
  breadcrumbs,
  noPadding,
  noPaddingSubmenu,
  submenuItems,
  sx,
  topMenuItems,
}) {
  return (
    <LayoutViewDesktop
      header={header}
      breadcrumbs={breadcrumbs}
      borderRadius={0}
      headerMargin={0}
      headerVariant={"h3"}
      noPadding={noPadding === undefined ? true : noPadding}
      noPaddingSubmenu={noPaddingSubmenu}
      padding={0}
      topMenuItems={topMenuItems}
      submenuItems={submenuItems}
      submenuBorderBottom={"1px solid gray"}
      sx={sx}
    >
      {children}
    </LayoutViewDesktop>
  );
}
