import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueSetter from "../../ValueSetter";
import getColumnWidth from "../GetColumnWidth";
import EditName from "../../edit_cell/EditName";
import EditNote from "../../edit_cell/EditNote";
import {Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

export default function NoteColumnDefinition() {
  return {
    field: 'note',
    width: getColumnWidth('note'),
    editable: true,
    hideable: false,
    valueGetter: (params => {
      return params.row?.node?.item?.note
    }),
    valueSetter: ValueSetter('node.item.note'),
    renderCell: (params) => {
      return (
        <>
          {params.value}
          {/*
          WIP Tooltip for note but
          {
            (params.value) && (
              <>
                <Tooltip
                  title={params.value}
                >
                  <InfoIcon/>
                </Tooltip>
              </>
            )
          }
          */}
        </>
      )
    },
    getRowClassName: getRowClassName,
    headerName: window.gettext('Note'),
    renderEditCell: EditNote
  }
}