import { Link } from "@mui/material";

import EntityText from "../../../../entity/EntityText";

import { useHierarchyContext } from "../../../context";

export function HierarchyEntityLink({ entity, fontSize }) {
  const { setEntity } = useHierarchyContext();

  return (
    <Link
      onClick={() => setEntity(entity)}
      sx={{
        cursor: "pointer",
      }}
    >
      <EntityText entity={entity} fontSize={fontSize} />
    </Link>
  );
}
