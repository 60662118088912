import {createContext, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SelfCheckJobInstallationReportProfileService from "../../../services/SelfCheckJobInstallationReportProfileService";

export const ReportInstallationProfileContext = createContext({
  listProfiles: undefined,
  getProfile: undefined,
  createProfile: undefined,
  deleteProfile: undefined,
  updateProfile: undefined,
  selfCheckJobInstallationReportProfileService: undefined,
  selfCheckJobInstallationReportProfileServiceParams: undefined,
})
export default function ReportInstallationProfileProvider(props) {
  let {job_id, profile_id} = useParams()

  const listProfiles = () => {
    return SelfCheckJobInstallationReportProfileService.fetch(
      job_id,
    )
  }

  const getProfile = (profileId) => {
    return SelfCheckJobInstallationReportProfileService.fetch(
      job_id,
      profileId,
    )
  }

  const updateProfile = (profileId, data) => {
    return SelfCheckJobInstallationReportProfileService.update(
      data,
      job_id,
      profileId,
    )
  }

  const createProfile = (data) => {
    return SelfCheckJobInstallationReportProfileService.create(
      data,
      job_id,
    )
  }

  const deleteProfile = (profileId) => {
    return SelfCheckJobInstallationReportProfileService.destroy(
      job_id,
      profileId,
    )
  }

  const addToBlacklist = (entity) => {
    console.error('Add to blacklist', entity)
  }

  return (
    <ReportInstallationProfileContext.Provider
      value={
        {
          'listProfiles': listProfiles,
          'updateProfile': updateProfile,
          'createProfile': createProfile,
          'deleteProfile': deleteProfile,
          'getProfile': getProfile,
          'addToBlacklist': addToBlacklist,
          'selfCheckJobInstallationReportProfileService': SelfCheckJobInstallationReportProfileService,
          'selfCheckJobInstallationReportProfileServiceParams': [
            job_id,
            profile_id || null,
          ],
        }
      }
    >
      {props.children}
    </ReportInstallationProfileContext.Provider>
  )
}
