import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Grid, TextField, Tooltip} from "@mui/material";
import SelfCheckJobMeasurementService from "../../../../../services/measurement/SelfCheckJobMeasurementService";
import SelfCheckJobMeasurementOpportunityService
  from "../../../../../services/measurement/SelfCheckJobMeasurementOpportunityService";
import PropTypes from "prop-types";
import {useSnackbar} from "notistack";

export default function CreateMeasurementOpportunityForm(props) {
  let navigate = useNavigate()
  let {enqueueSnackbar} = useSnackbar()

  let {projectId, systemId, jobId, measurementId, createButtonTooltipText, createButtonText, formGridDirection} = props

  let [outsideTemperature, setOutsideTemperature] = useState("")
  let [outflowTemperature, setOutflowTemperature] = useState("")
  let [returnTemperature, setReturnTemperature] = useState("")
  let [pumpFlow, setPumpFlow] = useState("")


  const createOpportunity = (measurementId) => {
    let data = {}
    data['outside_temperature'] = outsideTemperature;
    data['outflow_temperature'] = outflowTemperature;
    data['return_temperature'] = returnTemperature;

    if(pumpFlow !== ""){
      data['pump_displayed_flow'] = pumpFlow;
    }

    SelfCheckJobMeasurementOpportunityService.create(
      data,
      projectId,
      systemId,
      jobId,
      measurementId,
    ).then(
      (opportunity) => {
        console.log('yepp. this is the opportunity')
        setOutflowTemperature("")
        setReturnTemperature("")
        setOutsideTemperature("")
        setPumpFlow("")
        props.onCreated(
          opportunity
        )
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext('Error while trying to create measurement opportunity'),
          {
            variant: 'error'
          }
        )
      }
    )
  }

  const handleClickCreateMeasurementOpportunity = (e) => {
    if (!measurementId) {
      /**
       * If there is no measurement, we shall first create a mesurement, then create the opportunity on that measurement
       */
      SelfCheckJobMeasurementService.create(
        {},
        projectId,
        systemId,
        jobId,
      ).then(
        (measurement) => {
          createOpportunity(measurement.id)
        }
      )
    } else {
      /**
       * Measurement existed, so we create a opportunity on that measurement
       */
      createOpportunity(measurementId)
    }
  }
  const handleOutsideTemperatureChange = (e) => {
    setOutsideTemperature(e.target.value)
  }
  const handleReturnTemperatureChange = (e) => {
    setReturnTemperature(e.target.value)
  }
  const handleOutflowTemperatureChange = (e) => {
    setOutflowTemperature(e.target.value)
  }

  const handlePumpFlowChange = (e) => {
    setPumpFlow(e.target.value || "")
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            id="outlined-number"
            label={window.gettext('Outside temperature')}
            type="number"
            value={outsideTemperature}
            variant={"standard"}
            onChange={handleOutsideTemperatureChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            id="outflow-temperature"
            label={window.gettext('Outflow temperature')}
            type="number"
            value={outflowTemperature}
            variant={"standard"}
            onChange={handleOutflowTemperatureChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            id="return-temperature"
            label={window.gettext('Return temperature')}
            type="number"
            value={returnTemperature}
            variant={"standard"}
            onChange={handleReturnTemperatureChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            fullWidth
            id="pump-flow"
            label={window.gettext('Pump flow')}
            helperText={window.gettext('Flow according to pump, litres per hour')}
            type="number"
            value={pumpFlow}
            variant={"standard"}
            onChange={handlePumpFlowChange}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Tooltip
            title={
              createButtonTooltipText || null
            }>
              <span>
                <Button
                  disabled={(!outsideTemperature || !outflowTemperature || !returnTemperature)}
                  onClick={handleClickCreateMeasurementOpportunity}
                  variant={"outlined"}
                  size={"large"}
                  sx={{mt: 1}}
                >
                  {createButtonText || window.gettext('Create')}
                </Button>
              </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

CreateMeasurementOpportunityForm.props = {
  createButtonText: PropTypes.string,
  createButtonTooltipText: PropTypes.string,
  projectId: PropTypes.number,
  systemId: PropTypes.number,
  jobId: PropTypes.number,
  measurementId: PropTypes.number,
  onCreated: PropTypes.func,
  formGridDirection: PropTypes.string,
}