const MAX_WIDTH = 600;

export const DEFAULT_FORM_PROPS = {
  FORM_TEST_ID: "form",
  SUBMIT_BUTTON_TEST_ID: "form-submit",
  SUBMIT_BUTTON_TEXT: "Save",
  SUBMIT_DISABLED: false,

  HEADER_TYPOGRAPHY_PROPS: {
    variant: "h3",
    sx: {
      mb: 1,
    },
  },

  SECTIONS_CONTAINER_PROPS: {
    sx: {
      gap: 4,
    },
  },

  FIELDS_CONTAINER_PROPS: {
    sx: {
      gap: 2,
      maxWidth: MAX_WIDTH,
    },
  },

  CHILD_PROPS: {
    sx: {},
  },

  UNIQUE_CHILD_PROPS: [],

  SUBMIT_BUTTON_PROPS: {
    variant: "contained",
    color: "primary",
    sx: {
      mt: 2,
      maxWidth: MAX_WIDTH,
    },
  },
};
