import { Stack, Typography } from "@mui/material";

export function FactoryBasicStackContainer({ header, options, children }) {
  return (
    <Stack {...options.props}>
      {/* Header */}
      {header && <Typography {...options.headerProps}>{header}</Typography>}

      {/* Components */}
      <Stack {...options.innerProps}>{children}</Stack>
    </Stack>
  );
}
