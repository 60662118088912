export default function GetCellClassName(params) {
  let class_names = []
  // If there are errors, show those first.
  if(params.row.hasOwnProperty('errors')) {
    // Get errors that match this field
    const fieldErrors = params.row.errors.filter(
      (error) => {
        return error.field === params.field
      }
    )

    if (fieldErrors.length > 0) {
      class_names.push('drp-cell-error')
    }
  }

  // If we have warnings
  if(params.row.hasOwnProperty('warnings')) {
    // Get errors that match this field
    const fieldWarnings = params.row.warnings.filter(
      (warning) => {
        return warning.field === params.field
      }
    )

    if (fieldWarnings.length > 0) {
      class_names.push('drp-cell-warning')
    }
  }

  if(['name'].includes(params.field)){
    class_names.push('drp-cell-accent-1')
  }

  return class_names.join(' ')
}