import { saveOptionToLocalStorage } from "./HierarchyViewOptionsList";

export class HierarchyViewOption {
  constructor(entityType, optionName, label, value) {
    this.entityType = entityType;
    this.optionName = optionName;
    this.label = label;
    this.value = value;
  }

  save() {
    saveOptionToLocalStorage(this.entityType, this.optionName, this.value);
  }

  toggle() {
    this.value = !this.value;
    this.save();
  }
}
