import InstallationReportCommentCard from "./InstallationReportCommentCard";
import {Grid} from "@mui/material";

export default function InstallationReportComments({comments, installationReportProfileId, onSuccess}) {
  return (
    <>
      {(comments || []).length === 0 ? (
        <>
        </>
      ) : (
        <>
          <Grid
            direction={'column'}
            container
            spacing={1}
          >
            {comments.map((comment, comment_number) => {
                return (
                  <Grid
                    key={'comment_' + comment_number}
                    item
                  >
                    <InstallationReportCommentCard
                      comment={comment}
                      installationReportProfileId={installationReportProfileId}
                      onSuccess={onSuccess}
                    />
                  </Grid>
                )
              }
            )}
          </Grid>
        </>
      )}
    </>
  )
}