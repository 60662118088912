export default function getProjectRepresentation(project){
  if(!project) {
    return "";
  }

  if(!project.code) {
    return project.name;
  }

  return String(project.code) + ' - ' + String(project.name);
}