import {
  Button,
  Checkbox,
  List,
  ListItemButton,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { cloneElement, useEffect, useState } from "react";

import LoadingIndicator from "../../../../../layout/LoadingIndicator";
import ProjectService from "../../../../../services/ProjectService";
import SelfCheckJobSpecService from "../../../../../services/SelfCheckJobSpecService";
import getProjectRepresentation from "../../../../getProjectRepresentation";

import { CenteredModal, useModalHandler } from "../../../../../layout/modal";
import {
  hasElementWithId,
  withoutElementWithId,
} from "../../../../../../helper";
import { SelfCheckJobSpecImportService } from "../../../../../services";

export function ModalImportJobSpec({ children, jobSpec, postImport, project }) {
  const { enqueueSnackbar } = useSnackbar();
  const modalHandler = useModalHandler(false, handleModalClose);

  const [projects, setProjects] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(null);

  const [jobSpecs, setJobSpecs] = useState(undefined);
  const [selectedJobSpecs, setSelectedJobSpecs] = useState([]);

  useEffect(() => {
    // Fetch projects
    ProjectService.list().then(setProjects);
  }, []);

  useEffect(() => {
    if (selectedProject === null) {
      if (jobSpecs === undefined) {
        return;
      }

      // Reset job specs
      setJobSpecs(undefined);
      return;
    }

    // Fetch job specs
    SelfCheckJobSpecService.list(selectedProject.id).then(setJobSpecs);
  }, [selectedProject]);

  function clear() {
    // Reset states
    setSelectedProject(null);
    setSelectedJobSpecs([]);
  }

  function close() {
    clear();
    modalHandler.close();
  }

  function importSelectedJobSpecs() {
    // Success callback
    function handleImportSuccess(data) {
      if (postImport !== undefined) {
        postImport(data);
      }

      const newTasksLength = data.task_specs.length - jobSpec.task_specs.length;

      enqueueSnackbar(
        window.gettext(
          window
            .ngettext(
              "Successfully imported %(number)d task specification",
              "Successfully imported %(number)d task specifications",
              newTasksLength
            )
            .replace("%(number)d", String(newTasksLength))
        ),
        {
          variant: "success",
        }
      );

      close();
    }

    // Error callback
    function handleImportError({ response: { data } }) {
      enqueueSnackbar(data.context.message, {
        variant: "error",
      });
    }

    const selectedJobSpecIds = selectedJobSpecs.map(
      (selectedJobSpec) => selectedJobSpec.id
    );

    SelfCheckJobSpecImportService.post(
      {
        source_job_spec_ids: selectedJobSpecIds,
      },
      project.id,
      jobSpec.id
    )
      .then(handleImportSuccess)
      .catch(handleImportError);
  }

  function handleModalClose() {
    clear();
  }

  function handleOnClick() {
    modalHandler.open();
  }

  function handleOnClickBack() {
    setSelectedProject(null);
  }

  function handleOnClickCancel() {
    close();
  }

  function handleOnClickJobSpec(jobSpec) {
    setSelectedJobSpecs((prevJobSpecs) => {
      if (hasElementWithId(jobSpec.id, prevJobSpecs)) {
        // Remove job spec from selected job specs
        return withoutElementWithId(jobSpec.id, selectedJobSpecs);
      }

      // Add job spec to selected job specs
      return [...prevJobSpecs, jobSpec];
    });
  }

  function handleOnClickImport() {
    importSelectedJobSpecs();
  }

  function handleOnClickProject(project) {
    setSelectedProject(project);
  }

  return (
    <>
      {cloneElement(children, { onClick: handleOnClick })}

      <CenteredModal modalHandler={modalHandler} width="100%">
        {/* Header */}
        <Typography variant="h3">
          {window.gettext("Import Job Specification")}
        </Typography>

        {/* List of projects */}
        <List
          sx={{
            overflowY: "scroll",
            maxHeight: 800,
            height: "70vh",
          }}
        >
          {projects === undefined ||
          (selectedProject !== null && jobSpecs === undefined) ? (
            // Loading
            <LoadingIndicator />
          ) : selectedProject !== null ? (
            // List of job specs
            jobSpecs.map((jobSpec) => {
              return (
                <ListItemButton
                  key={jobSpec.id}
                  onClick={() => {
                    handleOnClickJobSpec(jobSpec);
                  }}
                >
                  <Checkbox
                    checked={hasElementWithId(jobSpec.id, selectedJobSpecs)}
                  />
                  {jobSpec.name}
                </ListItemButton>
              );
            })
          ) : (
            // List of projects
            projects.map((project) => {
              return (
                <ListItemButton
                  key={project.id}
                  onClick={() => {
                    handleOnClickProject(project);
                  }}
                >
                  {getProjectRepresentation(project)}
                </ListItemButton>
              );
            })
          )}
        </List>

        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {/* Back/Cancel button */}
          {selectedProject !== null ? (
            // Back button
            <Button variant="outlined" onClick={handleOnClickBack}>
              {window.gettext("Back")}
            </Button>
          ) : (
            // Cancel button
            <Button
              variant="contained"
              color="error"
              onClick={handleOnClickCancel}
            >
              {window.gettext("Cancel")}
            </Button>
          )}

          {/* Import button */}
          <Button
            variant="contained"
            disabled={selectedJobSpecs.length === 0}
            onClick={handleOnClickImport}
          >
            {window.gettext("Import")}{" "}
            {selectedJobSpecs.length ? "(" + selectedJobSpecs.length + ")" : ""}
          </Button>
        </Stack>
      </CenteredModal>
    </>
  );
}
