import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueSetter from "../../ValueSetter";
import Pressure from "../../../../values/Pressure";
import React from "react";
import ValueGetterConf from "../../ValueGetterConf";
import getColumnWidth from "../GetColumnWidth";

export default function AdditionalResistanceColumnDefinition() {
  return {
    field: 'additional_resistance',
    width: getColumnWidth('additional_resistance'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Additional resistance'),
    editable: true,
    valueSetter: ValueSetter('node.item.additional_resistance'),
    valueGetter: (params) => {
      return ValueGetter(
        [
          ValueGetterConf('pipe', 'node.item.additional_resistance'),
          ValueGetterConf('radiator', 'node.item.additional_resistance'),
          ValueGetterConf('valve', 'node.item.additional_resistance'),
        ]
      ).get(params.row)
    },
    renderCell: (params) => {
      return params.value === 0 ? <></> : <Pressure value={params.value} unit="pa"/>
    }
  }
}
