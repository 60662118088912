import BaseService from "./BaseService";

class ValveService extends BaseService {}

export default new ValveService('/api/',
  [
    'projects',
    'systems',
    'valves',
  ]
)