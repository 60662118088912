import BaseService from "./BaseService";

class IssueService extends BaseService {
  /**
   * Filtering possibilities:
   *
   *  As Query param you can supply any number of the following
   *
   *  project_id*
   *  system_id*
   *  status*
   *  apartment_id*
   *  assigned_to*
   *  created_by*
   *  number
   *  description
   *
   *  You can supply one or more the ones marked with a star (*).
   *  Example, you want to find issues that are assigned to User 1 or User 2 in project 1:
   *  /api/issues?assigned_to=1&assigned_to=2&project_id=1
   *
   *  Number and Description are searched for by containing the string you supply in a case-insensitive way.
   *  so number=a will find all issues that have an 'a'/'A' in the number.
   *  /api/issues?number=a
   *  /api/issues?description=abc
   *
   *  The user Fields (assigned_to, created_by) are the user ids but can also be the string 'me' to indicate the requesting user.
   *  Example, you want to find issues that are assigned to the requesting user:
   *  /api/issues?assigned_to=me
   *
   *  Searching:
   *  on top of the filtering you can also search for a string in many of the fields and related fields.
   *  Do this by supplying the search query param (can be many)
   *  If you want to find issues that have the word 'test' in the title, description etc.
   *  /api/issues?search=test
   *
   *  You can further narrow it down by supplying multiple entries for the search param
   *  /api/issues?search=test&search=ProjectName
   */

  // History
  listHistory(issue_id) {
    return this.list(
      issue_id,
      'history',
    );
  }
}

export default new IssueService('/api/',
  [
    'issues'
  ]
)