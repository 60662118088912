import React, {useEffect, useState} from "react";

export default function EditLengthCellInput(props) {
  const { id, value, api, field } = props;
  const precision = 1
  const divide_by = 100

  const getDisplayValue = function () {
    let v = value / divide_by

    return v.toFixed(precision)
  }

  let [displayValue, setDisplayValue] = useState(
    getDisplayValue()
  )

  const getRealValue = function (v) {
    if(v === ""){
      return null
    }

    const realValue = v * 100

    if(isNaN(realValue)){
      return null
    }

    return Math.round(realValue)
  }

  const handleChange = function (event) {
    // Change the value we display
    setDisplayValue(event.target.value)

    // set "real" value
    api.setEditCellValue({id, field, value: getRealValue(event.target.value)}, event)
  }

  return (
    <>
      <input
        type="text"
        value={displayValue}
        onChange={handleChange}
        autoFocus={true}
        onFocus={(event) => {
          // Selects the input value
          event.target.select()
        }}
      />
    </>
  )
}