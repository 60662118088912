import React, {useEffect, useMemo, useState} from "react";
import {Autocomplete, TextField} from "@mui/material";
import RadiatorTypeSearchService from "../../../services/RadiatorTypeSearchService";

export default function AutocompleteRadiatorSearchInput(props) {
  const {id, api, field, activeRadiators, label, selectedOptionId} = props;
  let [options, setOptions] = useState([])
  let [textFieldValue, setTextFieldValue] = useState(undefined)
  let [selectedOption, setSelectedOption] = useState(undefined)

  const handleChange = function (event, newValue) {
    // Change the value we display
    api.setEditCellValue({id, field, value: newValue?.id || null}, event)
  }

  const setOptionsFromActiveRadiators = function (extraOptions) {
    console.log('setOptionsFromActiveRadiators', activeRadiators, extraOptions)

    let possibleOptions = activeRadiators.filter((activeRadiator) => {
      // For some reason there has been null values in the active radiators
      return !!activeRadiator
    })

    console.log(
      'possibleOptions', possibleOptions,
    )

    if(extraOptions !== undefined && extraOptions.length > 0) {
      // Show the extra options last
      possibleOptions = extraOptions.concat(possibleOptions)
    }

    let newOptions = [];
    possibleOptions.map((possibleOption) => {
      if (!possibleOption) {
        return undefined
      }

      // Check that the radiator has not been added to the options
      if (newOptions.map((newOption) => {
        return newOption.id
      }).includes(possibleOption.id)) {
        // RadiatorType with ID same as the current one is in the list, ignore this duplicate
        return undefined
      }

      // Add to the new options
      newOptions.push(possibleOption)

      return possibleOption
    })

    setOptions(newOptions)
  }

  useEffect(() => {
    // If input value is empty or nothing we use the radiators that are active on nodes
    if (!textFieldValue) {
      setOptionsFromActiveRadiators()
      return undefined
    }

    // If we have started typing, we use the search service to find the options
    RadiatorTypeSearchService.search(textFieldValue).then(
      (response_data) => {
        // In the case that the results is just one exact radiator
        // then we shall show the users that radiator
        // as well as all the active radiators
        // This is done so that if the user has the text that is exactly matching a radiator
        // for instance when the form is loaded, we want to display all the active radiators
        if(response_data.length === 1) {
          setOptionsFromActiveRadiators(response_data)
        }else{
          setOptions(response_data)
        }
      }
    )
  }, [textFieldValue])

  useEffect(() => {
    console.log('try to select the selected option', selectedOption, selectedOptionId, options)
    if (selectedOption !== undefined) {
      return
    }

    if(!options || options.length === 0) {
      console.log('no options...')
      return
    }

    const newValue = options.find(
      (option) => {
        return option.id === selectedOptionId
      }
    ) || undefined

    console.log('setting value', newValue)

    setSelectedOption(newValue)
  }, [options, selectedOptionId])


  return (
    <>
      <Autocomplete
        id="auto"
        fullWidth={true}
        options={options}
        value={selectedOption || null}
        onChange={handleChange}
        onInputChange={(event, newInputValue) => {
          console.log('set new text field value', newInputValue)
          console.log('event', event)
          setTextFieldValue(newInputValue)
        }}
        getOptionLabel={(option) => {
          return option.name
        }}
        openOnFocus={true}
        filterOptions={(x) => x}
        renderInput={
          (params) => <TextField
            {...params}
            label={label}
            autoFocus={true}
          />
        }
      />
    </>
  )
}