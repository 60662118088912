import BaseService from "./BaseService";

class StatisticsService extends BaseService {
  radiators = (users = null, start_date = null, end_date = null, time_grouping = null) => {
    const query_params = {
      'users': users,
      'start_date': start_date,
      'end_date': end_date,
      'time_grouping': time_grouping,
    }
    return this.list(
      ...[
        'radiators',
        query_params,
      ]
    )
  }
}

export default new StatisticsService('/api/',
  [
    'statistics',
  ]
)