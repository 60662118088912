import dayjs from 'dayjs'
import {useEffect, useState} from "react";
import LoadingIndicator from "../layout/LoadingIndicator";

const duration = require('dayjs/plugin/duration')

export default function TimeDuration({minutes}) {
  const [totalDuration, setTotalDuration] = useState(null)
  const [durationParts, setDurationParts] = useState(undefined)

  // Extend dayjs with duration plugin
  dayjs.extend(duration)

  useEffect(() => {
    setTotalDuration(
      dayjs.duration(
        minutes || 0,
        'minutes'
      )
    )
  }, [minutes]);

  useEffect(() => {
    if (!totalDuration) {
      return null
    }
    let newDurationParts = []

    const years = totalDuration.years()
    const months = totalDuration.months()
    const days = totalDuration.days()
    const hours = totalDuration.hours()
    const minutes = totalDuration.minutes()

    if (years !== 0) {
      newDurationParts.push(
        years > 1 ? years + window.gettext("years") : years + window.gettext("year")
      )
    }

    if (months !== 0) {
      newDurationParts.push(
        months > 1 ? months + window.gettext("months") : months + window.gettext("month")
      )
    }

    if (days !== 0) {
      newDurationParts.push(
        days > 1 ? days + window.gettext("days") : days + window.gettext("day")
      )
    }

    if (hours !== 0) {
      newDurationParts.push(
        hours + 'h'
      )
    }

    if (minutes !== 0) {
      newDurationParts.push(
        minutes + 'm'
      )
    }

    setDurationParts(newDurationParts)
  }, [totalDuration])

  return (
    <>
      {(durationParts === undefined) ? (
        <LoadingIndicator/>
      ) : (
        <>
          {(!durationParts.length) ? (
            <span>
              -
            </span>
          ) : (
            <>
              {(durationParts).map(
                (part, index) => (
                  <span key={index}>
                    <span>
                      {part}
                    </span>
                    <span>
                      &nbsp;
                    </span>
                  </span>
                )
              )}
            </>
          )}
        </>
      )}
    </>
  )
}