function getObjectDiff(obj, reference) {
  const diff = Object.fromEntries(
    Object.entries(obj)
      .filter(([key, v]) => {
        // Return true if it has changed
        // No original returning true
        if (!reference) {
          return true
        }
        // Does not even exist on original
        if (!reference.hasOwnProperty(key)) {
          return true
        }

        const reference_value = reference[key]

        // If the original has null value and the new is undefined, this is the same.
        if (reference_value === null && v === undefined) {
          return false
        }

        if (key === 'temperature_distribution_types') {
          console.log(reference[key] !== v, 'Ref', reference[key], 'value', v)
        }

        if (Array.isArray(reference_value) && Array.isArray(v)) {
          return reference_value.sort().join(',') !== v.sort().join(',')
        }

        // Values differ, yay, keep this.
        return reference_value !== v
      })
      .map(([k, v]) => [k, v === Object(v) ? objectDiff(v, reference[k]) : v])
  );

  console.info("Diff", diff)
  return diff;
}

export default function objectDiff(obj, reference) {
  return getObjectDiff(obj, reference)
}