import { LayoutCard } from "../../../../../../../layout";
import { useHierarchyContext } from "../../context";
import { getComponentsHeaders } from "../../helper";
import { HierarchyTaskInterfaceTaskList } from "./task_interface";

export function HierarchyTaskInterface() {
  const { entityType, focusEntityData } = useHierarchyContext();

  return (
    <>
      {focusEntityData?.entity.task_specs.length > 0 && (
        <LayoutCard title={getComponentsHeaders(entityType).task_interface}>
          <HierarchyTaskInterfaceTaskList entity={focusEntityData.entity} />
        </LayoutCard>
      )}
    </>
  );
}
