import {createContext, useContext, useEffect, useState} from "react";
import IssueService from "../services/IssueService";
import {useParams} from "react-router-dom";
import ChoicesService from "../services/ChoicesService";
import IssueCommentService from "../services/IssueCommentService";
import IssueWatchersService from "../services/IssueWatchersService";


export const IssueWatchersContext = createContext({
  issueWatchers: undefined,
  refreshIssueWatchers: undefined,
})


export function useIssueWatchers() {
  return useContext(IssueWatchersContext);
}

export default function IssueWatchersProvider(props) {
  const [issueWatchers, setIssueWatchers] = useState(undefined)
  const issue_id = useParams().issue_id

  const refreshIssueWatchers = () => {
    IssueWatchersService.list(issue_id).then(setIssueWatchers)
  }

  useEffect(() => {
    refreshIssueWatchers()
  }, [issue_id]);

  return (
    <IssueWatchersContext.Provider
      value={
        {
          'watchers': issueWatchers,
          'refreshIssueWatchers': () => {
            refreshIssueWatchers()
          },
        }
      }
    >
      {(issueWatchers !== undefined && props.children) && (
        <>
          {props.children}
        </>
      )}
    </IssueWatchersContext.Provider>
  )
}
