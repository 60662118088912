import CircleIcon from "@mui/icons-material/Circle";
import {
  Alert,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";

import InstanceManipulator from "../../../../../InstanceManipulator";
import Link from "../../../../../layout/Link";
import LoadingIndicator from "../../../../../layout/LoadingIndicator";
import MultilineText from "../../../../../layout/MultilineText";
import StyledTableRow from "../../../../../layout/StyledTableRow";
import SelfCheckJobInstallationProfileBlacklistCommentService from "../../../../../services/SelfCheckJobInstallationReportProfileBlacklistCommentService";
import UserPermission from "../../../../../user/UserPermission";
import Date from "../../../../../values/Date";
import InstallationReportComments from "./InstallationReportComments";
import { JobInstallationReportContext } from "./JobInstallationReportContext";
import JobReportInstallationProfileBlacklistedCommentsList from "./profile/JobReportInstallationProfileBlacklistedCommentsList";
import JobReportInstallationProfileManipulator from "./profile/JobReportInstallationProfileManipulator";

import { LayoutCard } from "../../../../../../layout";
import { CenteredModal, useModalHandler } from "../../../../../layout/modal";

function ReportRowCell({ value }) {
  const valueType = typeof value

  return (
    <TableCell>
      <>
        {valueType === "boolean" && (
          <>
            {(value === true) ? (
              <>
                <CircleIcon fontSize={"small"}/>
              </>
            ) : (
              <>
                {/* Boolean value is False  */}
              </>
            )}
          </>
        )
        }
      </>
      <>
        {typeof value === "number" && (
          <>
            {(value === 0) ? (
              <>
                {/* Other value is zero */}
              </>
            ) : (
              <>
                <Chip label={value}/>
              </>
            )}
          </>
        )}
      </>
    </TableCell>

  );
}


function ReportRowCommentsCell({
  comments,
  installationReportProfileId,
  onSuccess,
}) {
  return (
    <TableCell>
      {typeof comments === "number" ? (
        <>
          {comments > 0 && (
            <>
              <Chip label={comments} />
            </>
          )}
        </>
      ) : (
        <InstallationReportComments
          comments={comments}
          installationReportProfileId={installationReportProfileId}
          onSuccess={onSuccess}
        />
      )}
    </TableCell>
  );
}

export default function InstallationReport() {
  const { job_id, job_spec_id, project_id, system_id } = useParams();

  const { report, reloadReport, fetchBlacklistedComments } = useContext(
    JobInstallationReportContext
  );

  const { enqueueSnackbar } = useSnackbar();

  const editProfileModalHandler = useModalHandler();

  const [reportRows, setReportRows] = useState([]);

  useEffect(() => {
    if (!report) {
      return;
    }

    const childrenSummaryOnLevel =
      report.profile?.children_summary_on_level || "address_entrance";

    let rows = getReportRows(
      report.report,
      null,
      childrenSummaryOnLevel,
      [
        "address_apartment",
      ]
    );

    setReportRows(
      rows.filter(
        (reportRow) => {
        if (
          !report.profile.entity_list_row_types.includes(
            reportRow.reportItem.entity.type
          )
        ) {
          return false;
        }

        // Check that the row has the data we specify for each type
        if (reportRow.reportItem.entity.type === "address_apartment") {
          return entityListRowFilter(
            reportRow,
            report.profile.entity_list_apartment_filters
          );
        }

        if (reportRow.reportItem.entity.type === "address_entrance") {
          return entityListRowFilter(
            reportRow,
            report.profile.entity_list_entrance_filters
          );
        }

        return true;
      })
    );
  }, [report]);

  class Row {
    constructor(reportItem, parent) {
      this.reportItem = reportItem;
      this.parent = parent;
    }

    get_ancestor_types = () => {
      let ancestor_types = [];
      let parent = this.parent;

      while (parent) {
        ancestor_types.push(parent.reportItem.entity.type);
        parent = parent.parent;
      }

      return ancestor_types;
    };

    get_label = () => {
      if (this.reportItem.entity.type === "address_apartment") {
        return this.reportItem.entity.data.label;
      }

      if (this.reportItem.entity.type === "address_house_number") {
        return (
          this.parent.get_label() + " " + this.reportItem.entity.data.label
        );
      }

      if (this.reportItem.entity.type === "address_entrance") {
        return (
          this.parent.get_label() + " " + this.reportItem.entity.data.label
        );
      }

      return this.reportItem.entity.data.label;
    };
  }

  function getDescendantsRecursiveProperties(entity, propertyContainer) {
    const reportData = getReportData(entity);

    // Go through each of the keys for the property container
    // and count up with the value from the ReportData on the PropertyContainer
    Object.entries(propertyContainer).map(([key, value]) => {
      if (reportData.hasOwnProperty(key)) {
        let count = 0;
        const reportDataValue = reportData[key];

        // Handle different types of values
        switch (typeof reportDataValue) {
          case "boolean":
            count = reportDataValue ? 1 : 0;
            break;
          case "number":
            count = reportDataValue;
            break;
          case "object":
            // Array of comments for instance.
            count = reportDataValue.length;
            break;
          default:
            console.error('Unknown type', reportDataValue, typeof reportDataValue)
        }

        propertyContainer[key] += count;
      }
    });

    // Go through all children recursively and add their data
    entity.children.map(
      (child) => getDescendantsRecursiveProperties(
        child,
        propertyContainer
      )
    )

    return propertyContainer;
  }

  function getChildrenSummary(reportItem) {
    return getDescendantsRecursiveProperties(
      reportItem,
      {
        dud: 0,
        flagged: 0,
        not_started: 0,
        done: 0,
        comments: 0,
      }
    );
  }

  function getReportData(reportItem) {
    let done = false;

    if (reportItem.task_count > 0) {
      done = reportItem.task_done_count === reportItem.task_count;
    }

    return {
      dud: reportItem?.task_items_done_but_not_task === true,
      flagged: (reportItem?.flagged_task_count || 0) > 0,
      not_started: reportItem?.started === false,
      done: done,
      comments: reportItem?.comments || [],
    };
  }

  function getReportRows(
    reportItem,
    parent,
    children_summary_on_level,
    include_entity_types
  ) {
    let row = new Row(reportItem, parent);
    let rows = [];

    const children_rows = reportItem.children.map((child) => {
      return getReportRows(
        child,
        row,
        children_summary_on_level,
        include_entity_types
      );
    });

    if (reportItem.entity.type === children_summary_on_level) {
      row.data = getChildrenSummary(reportItem);
      rows.push(row);
    }

    if (include_entity_types.includes(reportItem.entity.type)) {
      row.data = getReportData(reportItem);
      rows.push(row);
    }

    rows = rows.concat(children_rows);
    return rows.flat();
  }

  function handleCommentBlacklistHideButtonClick(profile_id, target) {
    let data = {};

    if (target?.task_item) {
      data["task_item_id"] = target.task_item.id;
    }

    if (target?.task) {
      data["task_id"] = target.task.id;
    }

    SelfCheckJobInstallationProfileBlacklistCommentService.create(
      data,
      profile_id
    ).then((response) => {
      enqueueSnackbar(window.gettext("Comment hidden"), {
        variant: "success",
      });

      fetchBlacklistedComments();
    });
  }

  /**
   * Filter the rows based on the filter names
   *
   * if ANY of the filter names correlate to a piece of data that is set in some way
   * we shall accept this row (return true)
   * @param row
   * @param filter_names
   * @returns {boolean}
   */
  function entityListRowFilter(row, filter_names) {
    // If there are no filters present, show all rows

    if (filter_names.length === 0) {
      return true;
    }

    if (filter_names.includes("done")) {
      if (row.data.done) {
        return true;
      }
    }

    if (filter_names.includes("not_started")) {
      if (row.data.not_started) {
        return true;
      }
    }

    if (filter_names.includes("flagged")) {
      if (row.data.flagged) {
        return true;
      }
    }

    if (filter_names.includes("dud")) {
      if (row.data.dud) {
        return true;
      }
    }
    if (filter_names.includes("comments")) {
      if ((row.data?.comments || []).length > 0) {
        return true;
      }
    }

    // No filters matched, so we should hide this row
    return false;
  }

  function onEditProfileUpdate() {
    enqueueSnackbar(window.gettext("Report profile saved"), {
      variant: "success",
    });

    editProfileModalHandler.close();
    reloadReport();
  }

  return (
    <>
      {!report ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Profile */}
          <LayoutCard title={window.gettext("Profile")} doNotPrint noPadding>
            <TableContainer>
              <Table>
                <TableBody>
                  {/* Profile name */}
                  <TableRow>
                    <TableCell>{window.gettext("Name")}</TableCell>

                    <TableCell>{report.profile.name}</TableCell>
                  </TableRow>

                  {/* Profile note */}
                  {report.profile.note !== "" && (
                    <TableRow>
                      <TableCell>{window.gettext("Note")}</TableCell>

                      <TableCell>
                        <MultilineText>{report.profile.note}</MultilineText>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack
              sx={{
                flexDirection: "row",
                gap: 1,
                p: 1,
              }}
            >
              {/* Change profile button */}
              <Button
                component={RouterLink}
                to={
                  "/projects/" +
                  String(project_id) +
                  "/systems/" +
                  String(system_id) +
                  "/job-specs/" +
                  String(job_spec_id) +
                  "/job/" +
                  String(job_id) +
                  "/report/installation/"
                }
                variant="outlined"
              >
                {window.gettext("Change profile")}
              </Button>

              {/* Edit profile button */}
              <UserPermission
                permission={
                  UserPermission.permissions.installation_report_profile_change
                }
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={editProfileModalHandler.open}
                  sx={{
                    maxWidth: 250,
                  }}
                >
                  {window.gettext("Edit profile")}
                </Button>
              </UserPermission>
            </Stack>
          </LayoutCard>

          {/* Information */}
          <LayoutCard title={window.gettext("Information")} noPadding>
            <TableContainer>
              <Table>
                <TableBody>
                  <StyledTableRow>
                    <TableCell>{window.gettext("Project")}</TableCell>

                    <TableCell>{report.project.name}</TableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <TableCell>{window.gettext("System")}</TableCell>

                    <TableCell>{report.system.name}</TableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <TableCell>{window.gettext("Date")}</TableCell>

                    <TableCell>
                      <Date value={report.date} />
                    </TableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </LayoutCard>

          {/* Report showing all project rows */}
          <LayoutCard title={window.gettext("Project rows")} noPadding>
            {!report.project_rows.length ? (
              <>
                <Alert severity={"info"}>
                  {window.gettext("No project data available")}
                </Alert>

                {!report.profile.project_rows.length && (
                  <Alert severity={"warning"}>
                    {window.gettext(
                      "No project rows has been defined for this report profile"
                    )}
                  </Alert>
                )}
              </>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{window.gettext("Project row")}</TableCell>

                      {report.profile.show_offered && (
                        <TableCell>{window.gettext("Offered")}</TableCell>
                      )}

                      {report.profile.show_entities_count && (
                        <TableCell>{window.gettext("Entities")}</TableCell>
                      )}

                      <TableCell>{window.gettext("Done")}</TableCell>

                      <TableCell>{window.gettext("Not done")}</TableCell>

                      {report.profile.show_difference && (
                        <TableCell>{window.gettext("Difference")}</TableCell>
                      )}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {report.project_rows.map((project_row, i) => {
                      const offered_and_done_count_diff =
                        project_row.task_item_done_count - project_row.count;

                      return (
                        <StyledTableRow key={i}>
                          <TableCell>{project_row.label}</TableCell>

                          {report.profile.show_offered && (
                            <TableCell>{project_row.count}</TableCell>
                          )}

                          {report.profile.show_entities_count && (
                            <TableCell>{project_row.entities_count}</TableCell>
                          )}

                          <TableCell>
                            {project_row.task_item_done_count}
                          </TableCell>

                          <TableCell>
                            {project_row.entities_count -
                              project_row.task_item_done_count}
                          </TableCell>

                          {report.profile.show_difference && (
                            <TableCell>
                              {offered_and_done_count_diff < 0 && (
                                <span>{offered_and_done_count_diff}</span>
                              )}

                              {offered_and_done_count_diff > 0 && (
                                <span>+{offered_and_done_count_diff}</span>
                              )}

                              {offered_and_done_count_diff === 0 && (
                                <span>0</span>
                              )}
                            </TableCell>
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </LayoutCard>

          {/* Entity List - Report showing all the addresses and apartments */}
          {reportRows && (
            <LayoutCard title={window.gettext("Summary")} noPadding>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* Entity name */}
                      <TableCell></TableCell>

                      {report.profile.entity_list_visible_columns.includes(
                        "done"
                      ) && <TableCell>{window.gettext("Done")}</TableCell>}

                      {report.profile.entity_list_visible_columns.includes(
                        "dud"
                      ) && <TableCell>{window.gettext("Dud")}</TableCell>}

                      {report.profile.entity_list_visible_columns.includes(
                        "flagged"
                      ) && <TableCell>{window.gettext("Flagged")}</TableCell>}

                      {report.profile.entity_list_visible_columns.includes(
                        "not_started"
                      ) && (
                        <TableCell>{window.gettext("Not started")}</TableCell>
                      )}

                      {report.profile.entity_list_visible_columns.includes(
                        "comments"
                      ) && <TableCell>{window.gettext("Comments")}</TableCell>}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {reportRows.map((reportRow, i) => {
                      return (
                        <StyledTableRow key={"report_row" + i}>
                          <TableCell>{reportRow.get_label()}</TableCell>

                          {report.profile.entity_list_visible_columns.includes(
                            "done"
                          ) && <ReportRowCell value={reportRow.data.done} />}

                          {report.profile.entity_list_visible_columns.includes(
                            "dud"
                          ) && <ReportRowCell value={reportRow.data.dud} />}

                          {report.profile.entity_list_visible_columns.includes(
                            "flagged"
                          ) && <ReportRowCell value={reportRow.data.flagged} />}

                          {report.profile.entity_list_visible_columns.includes(
                            "not_started"
                          ) && (
                            <ReportRowCell value={reportRow.data.not_started} />
                          )}

                          {report.profile.entity_list_visible_columns.includes(
                            "comments"
                          ) && (
                            <ReportRowCommentsCell
                              comments={reportRow.data.comments}
                              installationReportProfileId={report.profile.id}
                              onSuccess={() => {
                                fetchBlacklistedComments();
                              }}
                            />
                          )}
                        </StyledTableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </LayoutCard>
          )}

          {/* Radiators with comments */}
          {report.profile.show_radiators_with_comments && (
            <LayoutCard
              title={window.gettext("Radiators with comments")}
              noPadding
            >
              {!(report?.radiators?.length || 0) ? (
                <Alert severity={"info"}>
                  {window.gettext("No radiators with comments")}
                </Alert>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>{window.gettext("Radiator")}</TableCell>

                        <TableCell>{window.gettext("Room")}</TableCell>

                        <TableCell>{window.gettext("Comments")}</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {report.radiators.map((radiator, i) => {
                        return (
                          <StyledTableRow key={"radiator_" + i}>
                            <TableCell>
                              <Link
                                to={
                                  "/projects/" +
                                  report?.project?.id +
                                  "/systems/" +
                                  report?.system?.id +
                                  "/jobs/" +
                                  report?.job?.id +
                                  "/hierarchy?entity_type=radiator_node&entity_id=" +
                                  radiator.node_id
                                }
                              >
                                {radiator.name}
                              </Link>
                            </TableCell>

                            <TableCell>{radiator?.room?.name || ""}</TableCell>

                            <TableCell>
                              <InstallationReportComments
                                installationReportProfileId={report.profile.id}
                                comments={radiator.comments}
                                onSuccess={() => {
                                  fetchBlacklistedComments();
                                }}
                              />
                            </TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </LayoutCard>
          )}

          {/* Blacklisted entities */}
          <UserPermission
            permission={
              UserPermission.permissions
                .installation_report_profile_blacklisted_comments_view
            }
          >
            <LayoutCard
              title={window.gettext("Blacklisted items")}
              doNotPrint
              noPadding
            >
              <JobReportInstallationProfileBlacklistedCommentsList />
            </LayoutCard>
          </UserPermission>

          {/* Edit profile modal */}
          <UserPermission
            permission={
              UserPermission.permissions.installation_report_profile_change
            }
          >
            <CenteredModal modalHandler={editProfileModalHandler}>
              <JobReportInstallationProfileManipulator
                mode={InstanceManipulator.MODE_EDIT}
                postUpdate={onEditProfileUpdate}
                reportProfileId={report.profile.id}
              />
            </CenteredModal>
          </UserPermission>
        </>
      )}
    </>
  );
}
