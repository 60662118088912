import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import DateTime from "../../../components/values/DateTime";
import TimeDuration from "../../../components/values/TimeDuration";
import {useEffect, useState} from "react";

export default function IssueSpentTimeEntriesByUserTable({entries}) {
  // Entries by User
  const [entriesByUser, setEntriesByUser] = useState(undefined)

  useEffect(() => {
    if (!entries) {
      return null;
    }

    // Group entries by user
    setEntriesByUser(
      entries.reduce((accumulator, entry) => {
        if (!accumulator[entry.user]) {
          accumulator[entry.user] = {
            user: entry.user,
            user_name: entry.user_name,
            minutes: 0,
            entries: []
          }
        }
        accumulator[entry.user].minutes += entry.minutes
        accumulator[entry.user].entries.push(entry)
        return accumulator
      }, {})
    )
  }, [entries]);

  useEffect(() => {
    console.log('entriesByUser', entriesByUser)
    if (!entriesByUser) {
      return null;
    }

    // Sort entries by user
    console.log(entriesByUser)
  }, []);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {window.gettext("Duration")}
            </TableCell>
            <TableCell>
              {window.gettext("User")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(entriesByUser || {}).map(([user_id, entry], index) => (
              <TableRow
                key={index}
              >
                <TableCell>
                  <TimeDuration
                    minutes={entry.minutes}
                  />
                </TableCell>
                <TableCell>
                  {entry.user_name}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}