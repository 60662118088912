import {Link as RouterLink} from 'react-router-dom';
import {Link as MaterialLink} from "@mui/material";

export default function Link({to, children}) {
  return (
    <>
      <MaterialLink component={RouterLink} to={to}>
        {children}
      </MaterialLink>
    </>
  )
}
