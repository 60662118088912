import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import Speed from "../../../../values/Speed";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function SpeedColumnDefinition() {
  return {
    field: 'speed',
    width: getColumnWidth('speed'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Speed'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'calculation.speed'
      )
    }),
    valueFormatter: paramsValueFormatter(3, ','),
    renderCell: (params => {
      return <Speed value={params.value}/>
    }),
  }
}
