import {useEffect, useState} from "react";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import NippleService from "../services/NippleService";

export default function NippleSelector({value, onChange, defaultOpen, autoFocus}) {
  let [nippleOptions, setNippleOptions] = useState([])
  let [selectedNipple, setSelectedNipple] = useState("")

  useEffect(() => {
    NippleService.cache_list().then(setNippleOptions)
  }, [])

  useEffect(() => {
    setSelectedNipple(value === undefined || value === null ? "" : value)
  }, [value])

  const handleOnChange = (event) => {
    setSelectedNipple(event.target.value)
    onChange(event, event.target.value)
  }

  return (
    <>
      <FormControl
        fullWidth
      >
        <InputLabel id={'nipple_selector_label'}>
          {window.gettext('Nipple')}
        </InputLabel>
        <Select
          value={nippleOptions.length === 0 ? "" : selectedNipple}
          onChange={handleOnChange}
          defaultOpen={defaultOpen || false}
          autoFocus={autoFocus || false}
          label={window.gettext('Nipple')}
        >
          <MenuItem
            value={""}
          >
            {window.gettext('None')}
          </MenuItem>
          {nippleOptions.map(
            (nippleOption) => {
              return (
                <MenuItem
                  key={'nipple_' + nippleOption.id}
                  value={nippleOption.id}
                >
                  {nippleOption.label}
                </MenuItem>
              )
            }
          )}
        </Select>
      </FormControl>
    </>
  )
}