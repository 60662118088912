import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ReportMeasurementProfileContext from "../../../../../../../../../../../components/project/self_check/job/ReportMeasurementProfileContext";
import JobReportMeasurementProfileList from "../../../../../../../../../../../components/project/self_check/job/report/measurement/profile/JobReportMeasurementProfileList";
import JobReportMeasurementProfileManipulator from "../../../../../../../../../../../components/project/self_check/job/report/measurement/profile/JobReportMeasurementProfileManipulator";
import ProjectService from "../../../../../../../../../../../components/services/ProjectService";
import UserPermission from "../../../../../../../../../../../components/user/UserPermission";
import getUserHasPermission from "../../../../../../../../../../../components/user/getUserHasPermission";
import store from "../../../../../../../../../../../state";

import {
  CenteredModal,
  useModalHandler,
} from "../../../../../../../../../../../components/layout/modal";
import {
  LayoutCard,
  LayoutView,
} from "../../../../../../../../../../../layout";

export default function JobReportMeasurementProfileSelectionView() {
  const { project_id } = useParams();

  const navigate = useNavigate();

  const createProfileModalHandler = useModalHandler();

  const [project, setProject] = useState(null);

  useEffect(fetchProject, [project_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  const onCreated = (response) => {
    navigate(window.location.pathname + "profile/" + response.id);
  };

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Measurement report")}
      submenuItems={
        getUserHasPermission(
          store.user,
          UserPermission.permissions.measurement_report_profile_add
        ) ? (
          <Button variant="outlined" onClick={createProfileModalHandler.open}>
            {window.gettext("Add profile")}
          </Button>
        ) : null
      }
    >
      <ReportMeasurementProfileContext>
        <LayoutCard
          title={window.gettext("Please select a report profile to use")}
          sx={{
            maxWidth: 616,
          }}
        >
          <JobReportMeasurementProfileList />
        </LayoutCard>

        <UserPermission
          permission={UserPermission.permissions.measurement_report_profile_add}
        >
          <CenteredModal modalHandler={createProfileModalHandler}>
            <JobReportMeasurementProfileManipulator postCreate={onCreated} />
          </CenteredModal>
        </UserPermission>
      </ReportMeasurementProfileContext>
    </LayoutView>
  );
}
