import ExactResistanceTransformer from "./transformers/ExactResistanceTransformer";
import PipeDimensionTransformer from "./transformers/PipeDimensionTransformer";
import PipePipeTypeTransformer from "./transformers/PipePipeTypeTransformer";
import RadiatorRadiatorTransformer from "./transformers/RadiatorRadiatorTransformer";
import RadiatorValveTypeTransformer from "./transformers/RadiatorValveTypeTransformer";
import ValveValveTypeTransformer from "./transformers/ValveValveTypeTransformer";
import ParamsFieldValueGetter from "./getters/ParamsFieldValueGetter";
import ParamsFieldOverrideValueOverrideGetter from "./getters/ParamsFieldOverrideValueOverrideGetter";
import ValveLoopCleaner from "./cleaners/ValveLoopCleaner";
import ValveLoopTransformer from "./transformers/ValveLoopTransformer";
import RoomTransformer from "./transformers/RoomTransformer";
import RadiatorDimensionTransformer from "./transformers/RadiatorDimensionTransformer";
import AdditionalResistanceTransformer from "./transformers/AdditionalResistanceTransformer";
import ParentTransformer from "./transformers/ParentTransformer";
import WhiteSpaceCleaner from "./cleaners/WhiteSpaceCleaner";
import CustomFlowTransformer from "./transformers/CustomFlowTransformer";

export const NODE_TYPE_RADIATOR = 'radiator';
export const NODE_TYPE_VALVE = 'valve';
export const NODE_TYPE_PIPE = 'pipe';

/**
 * First we run the Getters, we get data from params. This returns an object of data
 * Then we run the transformers, this is run in the applyKeyMap function. This should return nicer values for each key
 * Lastly we run cleaners. Clean up any residual crud.
 */
export default class DataGetter {
  transformers = []
  getters = []
  cleaners = []

  constructor() {
    // Transforms the data object
    this.transformers = [
      new ExactResistanceTransformer(),
      new PipeDimensionTransformer(),
      new PipePipeTypeTransformer(),
      new RadiatorRadiatorTransformer(),
      new RadiatorValveTypeTransformer(),
      new ValveValveTypeTransformer(),
      new ValveLoopTransformer(),
      new RoomTransformer(),
      new RadiatorDimensionTransformer(),
      new AdditionalResistanceTransformer(),
      new ParentTransformer(),
      new CustomFlowTransformer(),
    ]

    // getting the data object
    this.getters = [
      new ParamsFieldValueGetter(),
      new ParamsFieldOverrideValueOverrideGetter(),
    ]

    // Cleaners for cleaning up the data
    this.cleaners = [
      new ValveLoopCleaner(),
      new WhiteSpaceCleaner(),
    ]
  }

  getKeyMap = (node) => {
    let keyMap = {}
    // Go through all items in keyMap and return a new one filtered down to the ones that match
    this.transformers.map((transformer) => {
      // If the matcher returns false, abort
      if (transformer.match(node) !== true) {
        return undefined
      }

      // set the transformer on the key
      keyMap[transformer.key] = transformer
      return undefined
    })

    return keyMap
  }
  getData = (node, params) => {
    // Get the applicable keyMap for this node

    const keyMap = this.getKeyMap(node)

    let data = {}

    // Use getter objects getDataObject method with params as the argument
    // This to combine a data object with all information found on the object
    this.getters.map((getter) => {
      const extraData = getter.getDataObject(params)
      if (extraData === undefined) {
        return undefined
      }

      data = {
        ...data,
        ...extraData
      }
      return undefined
    })

    let cleanedData = this.applyKeyMap(data, keyMap)
    this.cleaners.map((cleaner) => {
      cleanedData = cleaner.clean(node, params, cleanedData)
    })

    // Omit all data where the values are undefined
    Object.entries(cleanedData).map(
      ([key, value]) => {
        if (typeof (value) === 'undefined') {
          delete cleanedData[key]
        }
        return undefined
      }
    )

    return cleanedData
  }

  /**
   * Transforms the input object and change they key names to it
   * values in keymap can be strings representing the new key the value should have
   * or functions that return {'key' : 'new_key', 'value': 'the value'}
   *
   * use like this:
   * (new DataTransformer()).applyKeyMap(obj, {
   *  'old_key': 'new_key',
   *  'other_old_key': function(key, value){
   *    return {'key': 'key', 'value':'value'}
   *  }
   * }
   *
   * @param obj
   * @param keyMap
   * @return {{}}
   */
  applyKeyMap = function (obj, keyMap) {
    let newObj = {}

    Object.entries(obj).forEach(([objectKey, value]) => {
      let key = objectKey
      // If there is a keyMap and that the keymap has 'key' as one of its entries
      // Then we shall use the value of that entry as the new key
      if (keyMap !== undefined && keyMap.hasOwnProperty(objectKey)) {
        //  This is the value (sometimes function) that is passed for this key
        const keyMapValue = keyMap[objectKey];

        let keyMapFunc = keyMapValue

        // Make simple function that returns value
        if (typeof (keyMapValue) === 'string') {
          keyMapFunc = function (key, value, keyMapValue) {
            return {
              'key': keyMapValue,
              'value': value,
            }
          }
        }

        // No change, just use originalKey as key, and value as value
        if (typeof (keyMapValue) === 'undefined') {
          keyMapFunc = function (key, value, originalKey) {
            return {
              'key': originalKey,
              'value': value,
            }
          }
        }

        if (typeof (keyMapValue) === 'object') {
          keyMapFunc = keyMapValue.value
        }

        // Get transformed data
        const returnData = keyMapFunc(objectKey, value, keyMapValue)

        key = returnData.key
        value = returnData.value
      }

      newObj[key] = value
    })

    return newObj
  }
}