import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import RadiatorValveTypeService from "../../../services/RadiatorValveTypeService";

import {
  compareOptionValueIds,
  getHandleChangeCallback,
  getOptionLabelName,
} from "./helper";

export function RadiatorValveSearchField({ label, onChange }) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    RadiatorValveTypeService.fetch().then(setOptions);
  }, []);

  const handleChange = useCallback(
    getHandleChangeCallback(setValue, onChange),
    []
  );

  return (
    <Autocomplete
      id="auto"
      fullWidth={true}
      options={options}
      value={value}
      getOptionLabel={getOptionLabelName}
      isOptionEqualToValue={compareOptionValueIds}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
