import BaseService from "./BaseService";

class IssueCommentService extends BaseService {
}

export default new IssueCommentService('/api/',
  [
    'issues',
    'comments',
  ]
)