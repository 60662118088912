import React from "react";
import Form from "../../../../../../form/Form";
import FormFieldFactory from "../../../../../../form/FormFieldFactory";

export default function JobReportMeasurementProfileForm(props) {
  const submit_button_text =
    props.submitButtonText ||
    window.gettext("Create measurement report profile");

  const form_field_factory = new FormFieldFactory(props.instance);

  /**
   *
   *
   *         <InputLabel id="demo-simple-select-autowidth-label">Age</InputLabel>
   *         outside_threshold_show_entity_types=(
   *                 outside_threshold_show_entity_types := [
   *                     TargetTypeChoices.RADIATOR.value, TargetTypeChoices.VALVE.value,
   *                 ]
   *             ),
   *             outside_threshold_summary_types=(
   *                 outside_threshold_summary_types := [
   *                     TargetTypeChoices.CODED_ADDRESS.value, TargetTypeChoices.SYSTEM.value,
   *                 ]
   *             ),
   *             temperature_distribution_types=(
   *                 temperature_distribution_types := [
   *                     TargetTypeChoices.APARTMENT.value, TargetTypeChoices.HOUSE_NUMBER.value
   *                 ]
   *             )
   * */

  const form_fields = [
    form_field_factory.textField("name", window.gettext("Name"), true),
    form_field_factory.textArea("note", window.gettext("Note")),
    form_field_factory
      .selectMultiple(
        "outside_threshold_show_entity_types",
        window.gettext("Outside threshold types")
      )
      .withChoices([
        ["system", window.gettext("System")],
        ["address_coded_address", window.gettext("Address")],
        ["address_house_number", window.gettext("House number")],
        ["address_entrance", window.gettext("Entrance")],
        ["address_apartment", window.gettext("Apartment")],
        ["radiator_node", window.gettext("Radiator")],
        ["valve_node", window.gettext("Valve")],
      ])
      .withHelpText(
        window.gettext(
          "Entity types to show in the list of entities that are outside accepted threshold"
        )
      ),
    form_field_factory
      .selectMultiple(
        "outside_threshold_summary_types",
        window.gettext("Outside threshold summary types")
      )
      .withChoices([
        ["system", window.gettext("System")],
        ["address_coded_address", window.gettext("Address")],
        ["address_house_number", window.gettext("House number")],
        ["address_entrance", window.gettext("Entrance")],
        ["address_apartment", window.gettext("Apartment")],
        ["radiator_node", window.gettext("Radiator")],
        ["valve_node", window.gettext("Valve")],
      ])
      .withHelpText(
        window.gettext(
          "Entity types to show in the summary for outside accepted threshold"
        )
      ),
    form_field_factory
      .selectMultiple(
        "temperature_distribution_types",
        window.gettext("Temperature distribution types")
      )
      .withChoices([
        ["system", window.gettext("System")],
        ["address_coded_address", window.gettext("Address")],
        ["address_house_number", window.gettext("House number")],
        ["address_entrance", window.gettext("Entrance")],
        ["address_apartment", window.gettext("Apartment")],
        ["radiator_node", window.gettext("Radiator")],
        ["valve_node", window.gettext("Valve")],
      ])
      .withHelpText(
        window.gettext("Entity types to show in the temperature distribution")
      ),
    form_field_factory
      .integerField(
        "median_accepted_threshold_low",
        window.gettext("Lowest acceptable median temperature")
      )
      .withHelpText(
        window.gettext(
          "Lowest temperature that is accepted, will default to desired room temperature for system -1C"
        )
      )
      .withDefaultValue(""),
    form_field_factory
      .integerField(
        "median_accepted_threshold_high",
        window.gettext("Highest acceptable median temperature")
      )
      .withHelpText(
        window.gettext(
          "Highest temperature that is accepted, will default to desired room temperature for system +1C"
        )
      )
      .withDefaultValue(""),
    form_field_factory
      .selectMultiple(
        "median_temperature_entity_types",
        window.gettext("Median temperature entity types")
      )
      .withChoices([
        ["system", window.gettext("System")],
        ["address_coded_address", window.gettext("Address")],
        ["address_house_number", window.gettext("House number")],
        ["address_entrance", window.gettext("Entrance")],
        ["address_apartment", window.gettext("Apartment")],
        ["radiator_node", window.gettext("Radiator")],
        ["valve_node", window.gettext("Valve")],
      ])
      .withHelpText(
        window.gettext(
          "Entity types visible in median temperature tree view list, if this is set users will not be able to toggle child entities"
        )
      ),
    form_field_factory
      .textField(
        "report_row_expanded_indentation_level",
        window.gettext("Median temperature tree expanded default")
      )
      .withHelpText(window.gettext("Will expand down to this entity"))
      .withChoices(
        Object.fromEntries([
          [0, window.gettext("Not expanded")],
          [1, window.gettext("System")],
          [2, window.gettext("Address")],
          [3, window.gettext("House number")],
          [4, window.gettext("Entrance")],
          [5, window.gettext("Apartment")],
        ])
      )
      .withDefaultValue(3),
    form_field_factory
      .integerField(
        "temperature_distribution_step",
        window.gettext("Temperature distribution step size")
      )
      .withHelpText(
        window.gettext(
          "How big the steps are in the median temperature distribution curve"
        )
      )
      .withDefaultValue(""),
    form_field_factory
      .integerField(
        "temperature_distribution_low",
        window.gettext("Temperature distribution minimum temperature")
      )
      .withHelpText(
        window.gettext(
          "Lowest temperature to show median temperature distribution for"
        )
      )
      .withDefaultValue(""),
    form_field_factory
      .integerField(
        "temperature_distribution_high",
        window.gettext("Temperature distribution maximum temperature")
      )
      .withHelpText(
        window.gettext(
          "Highest temperature to show median temperature distribution for"
        )
      )
      .withDefaultValue(""),
  ];

  return (
    <Form
      formTestId="project-form"
      formFields={form_fields}
      header={props.header || window.gettext("Create new profile")}
      onSubmit={props.onSubmit}
      submitButtonText={submit_button_text}
      submitButtonTestId="project-form-submit"
    />
  );
}
