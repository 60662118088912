import {useContext} from "react";
import {Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {JobMeasurementReportContext} from "../JobMeasurementReportContext";
import EntityType from "../../../entity_type/EntityType";
import Helper from "../../../../../../utils/Helper";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import IconButton from "@mui/material/IconButton";
import {useSnackbar} from "notistack";

export default function JobReportMeasurementProfileBlacklistedEntitiesList() {
  let {blacklistedEntities, removeBlacklistedEntity} = useContext(JobMeasurementReportContext)
  let {enqueueSnackbar} = useSnackbar()

  const handleRemoveFromBlacklistButtonClick = (blacklistedEntity) => {
    removeBlacklistedEntity(blacklistedEntity).then(
      () => {
        enqueueSnackbar(
          window.gettext('Entity removed from blacklist'),
          {
            'variant': 'success'
          }
        )
      }
    )
  }

  return (
    <>
      {blacklistedEntities !== null && blacklistedEntities.length === 0 ? (
        <>
          <Alert severity={'info'}>
            {window.gettext('No blacklisted entities could be found')}
          </Alert>
        </>
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {window.gettext('Type')}
                  </TableCell>
                  <TableCell>
                    {window.gettext('Label')}
                  </TableCell>
                  <TableCell>
                    {window.gettext('Remove')}
                    <Helper
                      title={window.gettext('Remove from the blacklist')}
                      ml
                    >
                      {window.gettext('If removed this entity will be used in the calculation for this report')}
                    </Helper>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(blacklistedEntities || []).map(
                  (blacklistedEntity, i) => {
                    return (
                      <TableRow
                        key={'blacklisted_entity_' + i}
                      >
                        <TableCell>
                          <EntityType entityType={blacklistedEntity.target_type}/>
                        </TableCell>
                        <TableCell>
                          {blacklistedEntity.label}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={
                              () => {
                                handleRemoveFromBlacklistButtonClick(blacklistedEntity)
                              }
                            }
                          >
                            <RestoreFromTrashIcon color={"success"}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>

  )
}