import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField, Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import AutocompleteInput from "./edit_cell/AutocompleteInput";

/*
* This is not used at the moment, code to make it work might be in BuildTable
* */
export default function CopyNodeDialog(
  props,
) {
  let [row, setRow] = useState(undefined)
  let [options, setOptions] = useState(undefined)
  let [selectedNode, setSelectedNode] = useState(undefined)
  let [value, setValue] = useState(undefined)

  useEffect(() => {
    setRow(props.row)
    setSelectedNode(undefined)
  }, [props.row])

  useEffect(() => {
    setOptions(
      props.nodes
    )
  }, [props.nodes])

  useEffect(() => {
    if (options === undefined) {
      console.log('no ptions')
      return;
    }

    if (row === undefined || row.node.parent === undefined) {
      return;
    }

    const selectedOption = options.find((option) => {
      if (option.id === row.node.parent.id) {
        return true
      }
    });

    setValue(selectedOption);

    // set the selected node now so users can click the 'Copy' button
    setSelectedNode(selectedOption);
  }, [options, row])

  const handleChange = function (event, newValue) {
    console.log('change', newValue)
    setSelectedNode(newValue)
    setValue(newValue)
  }

  const handleClickCopyButton = function () {
    props.copyButtonCallback(selectedNode);
  }

  return (
    <Dialog
      open={row !== undefined}
      data-test-id="copy-node-dialog"
    >
      <DialogTitle>
        <span>{window.gettext('Copy')}</span>
        <span> </span>{/* space */}
        <span data-test-id="copy-node-dialog-node-name">{row !== undefined && (row.name)}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body">
            {window.gettext("Copy a node and all its children, select an existing node where the copy of this node should be placed")}
          </Typography>
        </DialogContentText>
        {options !== undefined && (
          <Autocomplete
            options={options}
            fullWidth={true}
            onChange={handleChange}
            getOptionLabel={(option) => {
              return option.name
            }}
            value={value}
            autoHighlight
            renderInput={
              (params) => <TextField
                {...params}
                label={window.gettext('Node')}
                autoFocus
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'copy-node-dialog-parent-selector-input'
                }}
              />
            }
            data-test-id="copy-node-dialog-parent-node-selector"
          />
        )}

      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.closeDialogCallback()
          }}
          color="warning"
          variant="outlined"
          data-test-id="copy-node-dialog-cancel-button"
        >
          {window.gettext('Cancel')}
        </Button>

        <Button
          onClick={handleClickCopyButton}
          disabled={!selectedNode}
          variant="contained"
          data-test-id="copy-node-dialog-copy-button"
        >
          {window.gettext('Copy')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}