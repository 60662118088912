import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import DumbWrap from "../layout/DumbWrap";
import SystemHeatCurvePointService from "../services/SystemHeatCurvePointService";
import SystemHeatCurveService from "../services/SystemHeatCurveService";
import UserPermission from "../user/UserPermission";

export default function HeatCurveTable(props) {
  const { heat_curve, project_id, system_id, edit_mode } = props;

  const { enqueueSnackbar } = useSnackbar();

  const [dirtyHeatCurve, setDirtyHeatCurve] = useState({});
  const [hasDirtyValues, setHasDirtyValues] = useState(false);
  const [newPointOutsideTemperature, setNewPointOutsideTemperature] =
    useState(null);
  const [newPointOutflowTemperature, setNewPointOutflowTemperature] =
    useState(null);
  const [disableAddingNewPoint, setDisableAddingNewPoint] = useState(true);
  const [newPointOutsideTemperatureTaken, setNewPointOutsideTemperatureTaken] =
    useState(false);

  useEffect(() => {
    if (JSON.stringify(heat_curve) !== JSON.stringify(dirtyHeatCurve)) {
      setHasDirtyValues(true);
    } else {
      setHasDirtyValues(false);
    }
  }, [dirtyHeatCurve]);

  useEffect(() => {
    if (!heat_curve) {
      return;
    }

    // Reset the dirty heat curve to values of heat_curve
    // now the dirtyHeatCurve is the same as the baseline (heat_curve)
    setDirtyHeatCurve(JSON.parse(JSON.stringify(heat_curve)));
  }, [heat_curve]);

  useEffect(() => {
    setDisableAddingNewPoint(false);
    setNewPointOutsideTemperatureTaken(false);

    if (!newPointOutsideTemperature || !newPointOutflowTemperature) {
      setDisableAddingNewPoint(true);
    }

    if (!dirtyHeatCurve?.points) {
      return;
    }

    if (
      dirtyHeatCurve.points
        .map((point) => parseFloat(point.outside_temperature))
        .includes(parseFloat(newPointOutsideTemperature))
    ) {
      setNewPointOutsideTemperatureTaken(true);
      setDisableAddingNewPoint(true);
    }
  }, [newPointOutsideTemperature, newPointOutflowTemperature]);

  function handleOutflowTemperatureChange(e, pointReference) {
    let newValue = e.target.value;

    if (newValue === "") {
      newValue = null;
    }

    let newDirtHeatCurve = JSON.parse(JSON.stringify(dirtyHeatCurve));

    newDirtHeatCurve.points.forEach((point) => {
      if (point.id === pointReference.id) {
        point.outflow_temperature = newValue;
      }
    });

    setDirtyHeatCurve(newDirtHeatCurve);
  }

  function handleOutsideTemperatureChange(e, pointReference) {
    const newValue = e.target.value;

    let newDirtHeatCurve = JSON.parse(JSON.stringify(dirtyHeatCurve));

    newDirtHeatCurve.points.forEach((point) => {
      if (point.id === pointReference.id) {
        point.outside_temperature = newValue;
      }
    });

    setDirtyHeatCurve(newDirtHeatCurve);
  }

  function fetchHeatCurve() {
    SystemHeatCurveService.fetch(project_id, system_id, heat_curve.id).then(
      (newHeatCurve) => {
        props.onUpdate(newHeatCurve);
      }
    );
  }

  function handleDeleteButtonClick(e, point) {
    SystemHeatCurvePointService.destroy(
      project_id,
      system_id,
      heat_curve.id,
      point.id
    ).then((response) => {
      fetchHeatCurve();
    });
  }

  function getDirtyPoints() {
    return dirtyHeatCurve.points.filter((dirtyPoint) => {
      const originalPoint = heat_curve.points.find((point) => {
        return point.id === dirtyPoint.id;
      });

      return (
        dirtyPoint.outside_temperature !== originalPoint.outside_temperature ||
        dirtyPoint.outflow_temperature !== originalPoint.outflow_temperature
      );
    });
  }

  function saveDirtyPoints() {
    const points_data = getDirtPointsSaveData();

    if (points_data.length === 0) {
      return new Promise((resolve, reject) => {
        resolve(null);
      });
    }

    return new Promise((resolve, reject) => {
      SystemHeatCurveService.update(
        {
          points: points_data,
        },
        project_id,
        system_id,
        heat_curve.id
      ).then(
        (response) => {
          resolve(response);
        },
        (reason) => {
          reject(reason);
        }
      );
    });
  }

  function getDirtPointsSaveData() {
    const dirtyPoints = getDirtyPoints();

    return dirtyPoints.map((point) => {
      return {
        id: point.id,
        outside_temperature: point.outside_temperature,
        outflow_temperature: point.outflow_temperature,
      };
    });
  }

  function handleSaveButtonClick(e) {
    saveDirtyPoints().then((success) => {
      if (success === null) {
        // Noep. nothing here.
        return null;
      }

      enqueueSnackbar(window.gettext("Points saved"), { variant: "success" });
      props.onUpdate(success);
    });
  }

  function handleAddNewButtonClick(e) {
    // Be sure to save before we add the new one.

    saveDirtyPoints().then((updatedHeatCurve) => {
      SystemHeatCurvePointService.create(
        {
          outflow_temperature: newPointOutflowTemperature,
          outside_temperature: newPointOutsideTemperature,
        },
        project_id,
        system_id,
        heat_curve.id
      ).then(
        (response) => {
          setNewPointOutflowTemperature(null);
          setNewPointOutsideTemperature(null);
          fetchHeatCurve();
          enqueueSnackbar(window.gettext("Point added"), {
            variant: "success",
          });
        },
        (error) => {
          enqueueSnackbar(window.gettext("Error while saving point"), {
            variant: "error",
          });
        }
      );
    });
  }

  return (
    <>
      {hasDirtyValues && (
        <Alert severity={"warning"}>
          {window.gettext(
            "Unsaved values, press save to commit data to database"
          )}
        </Alert>
      )}

      {dirtyHeatCurve?.points && (
        <>
          <UserPermission
            permission={UserPermission.permissions.system_heat_curve_point_add}
          >
            {edit_mode && (
              <>
                <Box
                  sx={{
                    p: 1,
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    sx={{
                      mb: 2,
                    }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {window.gettext("Add new point")}
                      </Typography>

                      <Alert severity={"info"} sx={{ mt: 1 }}>
                        {window.gettext("Add new point to this heat curve")}
                      </Alert>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        value={newPointOutsideTemperature || ""}
                        type={"number"}
                        fullWidth
                        onChange={(e) => {
                          setNewPointOutsideTemperature(e.target.value || "");
                        }}
                        label={window.gettext("Outside temperature")}
                      />

                      {newPointOutsideTemperatureTaken && (
                        <Alert severity={"warning"}>
                          {window.gettext(
                            "A point with this outside temperature is already present in this curve"
                          )}
                        </Alert>
                      )}
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        value={newPointOutflowTemperature || ""}
                        type={"number"}
                        fullWidth
                        onChange={(e) => {
                          setNewPointOutflowTemperature(e.target.value);
                        }}
                        label={window.gettext("Outflow temperature")}
                      />
                    </Grid>
                  </Grid>

                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    disabled={disableAddingNewPoint}
                    onClick={handleAddNewButtonClick}
                  >
                    {window.gettext("Add point")}
                  </Button>
                </Box>

                <Divider />
              </>
            )}
          </UserPermission>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <DumbWrap>{window.gettext("Outside temperature")}</DumbWrap>
                  </TableCell>

                  <TableCell>
                    <DumbWrap>{window.gettext("Outflow temperature")}</DumbWrap>
                  </TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dirtyHeatCurve?.points.map((point, i) => (
                  <TableRow key={"point_" + i}>
                    {!edit_mode === true ? (
                      <>
                        <TableCell>{point.outside_temperature}</TableCell>

                        <TableCell>
                          {point.outflow_temperature || "-"}
                        </TableCell>

                        <TableCell></TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>
                          <TextField
                            type={"number"}
                            onChange={(e) => {
                              handleOutsideTemperatureChange(e, point);
                            }}
                            value={point.outside_temperature}
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            type={"number"}
                            onChange={(e) => {
                              handleOutflowTemperatureChange(e, point);
                            }}
                            value={point.outflow_temperature || ""}
                          />
                        </TableCell>

                        <TableCell>
                          <IconButton
                            color={"error"}
                            onClick={(e) => {
                              handleDeleteButtonClick(e, point);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {edit_mode && (
            <>
              <Box
                sx={{
                  p: 2,
                }}
              >
                <Button
                  variant={"contained"}
                  startIcon={<SaveIcon />}
                  onClick={handleSaveButtonClick}
                >
                  {window.gettext("Save points")}
                </Button>
              </Box>

              <Divider />
            </>
          )}
        </>
      )}
    </>
  );
}

HeatCurveTable.props = {
  heat_curve: PropTypes.shape({
    points: PropTypes.array,
  }),
  edit_mode: PropTypes.bool,
};
