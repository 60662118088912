// material
import {useTheme} from '@mui/material/styles';
import {GlobalStyles as GlobalThemeStyles} from '@mui/material';
import React from "react";

// ----------------------------------------------------------------------

export default function GlobalStyles() {
  const theme = useTheme();

  const jobDataGridEntranceRowBackgroundColor = '#999d9d'
  const jobDataGridApartmentRowBackgroundColor = '#6fe399'
  const jobDataGridCodedAddressRowBackgroundColor = '#ffc5c5'
  const jobDataGridHouseNumberRowBackgroundColor = '#e2c0ff'
  const jobDataGridRoomRowBackgroundColor = '#a7b3ee'
  const jobDataGridRadiatorRowBackgroundColor = '#e0e9ff'
  const jobDataGridValveRowBackgroundColor = '#efffe0'

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box'
        },
        html: {
          width: '100%',
          height: '100%',
        },
        body: {
          width: '100%',
          height: '100%'
        },
        '#root': {
          width: '100%',
          height: '100%'
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none'
            }
          }
        },
        textarea: {
          '&::-webkit-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::-moz-placeholder': {
            opacity: 1,
            color: theme.palette.text.disabled
          },
          '&:-ms-input-placeholder': {
            color: theme.palette.text.disabled
          },
          '&::placeholder': {
            color: theme.palette.text.disabled
          }
        },

        img: {display: 'block', maxWidth: '100%'},

        // Lazy Load Img
        '.blur-up': {
          WebkitFilter: 'blur(5px)',
          filter: 'blur(5px)',
          transition: 'filter 400ms, -webkit-filter 400ms'
        },
        '.blur-up.lazyloaded ': {
          WebkitFilter: 'blur(0)',
          filter: 'blur(0)'
        },
        '.datagrid-default': {
          fontSize: '10px',
        },
        '.job-datagrid-row-entrance': {
          backgroundColor: jobDataGridEntranceRowBackgroundColor + ' !important',
        },
        '.job-datagrid-row-coded_address': {
          backgroundColor: jobDataGridCodedAddressRowBackgroundColor + ' !important',
        },
        '.job-datagrid-row-house_number': {
          backgroundColor: jobDataGridHouseNumberRowBackgroundColor + ' !important',
        },
        '.job-datagrid-row-apartment': {
          backgroundColor: jobDataGridApartmentRowBackgroundColor + ' !important',
        },
        '.job-datagrid-row-radiator': {
          backgroundColor: jobDataGridRadiatorRowBackgroundColor + ' !important',
        },
        '.job-datagrid-row-valve': {
          backgroundColor: jobDataGridValveRowBackgroundColor + ' !important',
        },

        /*
        '.job-datagrid-row-room': {
          backgroundColor: jobDataGridRoomRowBackgroundColor + ' !important',
          marginTop: '10px',
        },
                 */
      }}
    />
  );
}
