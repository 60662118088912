import EntityStatus from "../entity/EntityStatus";

export default function getEntityStatus(entityData, jobData) {
  const task_specs = entityData.entity.task_specs
  const entity_task_references = entityData.entity.tasks
  const task_spec_ids = task_specs.map((task_spec) => task_spec.id)
  const entity_task_ids = entity_task_references.map((task) => task.id)
  const task_items = entityData.entity.task_items
  const tasks = entity_task_references.map(
    (entity_task_reference) => {
      return jobData?.tasks.find(
        (task) => (task.id === entity_task_reference.id)
      )
    }
  )


  const tasks_done = jobData?.tasks.filter(
    (task) => {
      // If this task originates from a task spec that is Not in the
      // list of tasks on the entity
      if (!task_spec_ids.includes(task.spec_id)) {
        return false
      }

      // if this task is Not part of the ones on the entity
      if (!entity_task_ids.includes(task.id)) {
        return false
      }

      return task.done === true
    }
  )

  const flagged_tasks = tasks.filter(
    (task) => {
      return task?.flag !== null
    }
  )

  const commented_tasks = tasks.filter(
    (task) => {
      return !!task?.comment
    }
  )

  const task_item_specs = entityData.entity.task_item_specs

  const task_items_done = task_items.filter(
    (task_item) => {
      return task_item.done === true
    }
  )

  const commented_task_items = task_items.filter(
    (task_item) => {
      return !!task_item?.comment
    }
  )

  return new EntityStatus(
    task_specs,
    tasks,
    tasks_done,
    flagged_tasks,
    commented_tasks,
    task_item_specs,
    task_items,
    task_items_done,
    commented_task_items,
    entityData.entity.measurement_status,
    entityData,
  )
}