import Button from "@mui/material/Button";
import SummarizeIcon from '@mui/icons-material/Summarize';
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from 'react-router-dom'
import PropTypes from "prop-types";

export default function SelfCheckJobInstallationReportButtonLink({job, size, sx}) {
  return (
    <>
      {
        job && (
          <>
            <Button
              component={RouterLink}
              to={'/projects/' + String(job?.system.project_id) + '/systems/' + String(job.system?.id) + '/job-specs/' + String(job?.spec.id) + '/job/' + String(job?.id) + '/report/installation/'}
              endIcon={<SummarizeIcon/>}
              variant="outlined"
              size={size}
              sx={sx || {}}
            >
              {window.gettext('Installation report')}
            </Button>
          </>
        )
      }
    </>
  )
}

SelfCheckJobInstallationReportButtonLink.propTypes = {
  'job': PropTypes.object,
}