import {useEffect, useState} from "react";
import {LayoutCard} from "../../../../layout";
import LoadingIndicator from "../../../../components/layout/LoadingIndicator";
import IssueService from "../../../../components/services/IssueService";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table, TableBody, TableCell,
  TableContainer,
  TableHead, TableRow,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateTime from "../../../../components/values/DateTime";
import UserService from "../../../../components/services/UserService";
import UserChoice from "../../../../components/values/UserChoice";
import IssueBaseInfo from "../../../../components/issue_tracker/IssueBaseInfo";
import ChoicesService from "../../../../components/services/ChoicesService";
import {useIssue} from "../../../../components/issue_tracker/IssueContext";
import IssueCategoryService from "../../../../components/services/IssueCategoryService";
import IssueHistoryInfo from "../../../../components/issue_tracker/IssueHistoryInfo";
import {useIssueFiles} from "../../../../components/issue_tracker/IssueFileContext";
import IssueFileList from "../../../../components/issue_tracker/IssueFileList";
import IssueFileAdd from "../../../../components/issue_tracker/IssueFileAdd";

export default function IssueFilesTab({}) {
  return (
    <>
      <LayoutCard
        title={window.gettext("Files")}
      >
        <IssueFileList/>
      </LayoutCard>

      <LayoutCard
        title={window.gettext("Add file")}
        doNotPrint
      >
        <IssueFileAdd/>
      </LayoutCard>
    </>
  )
}