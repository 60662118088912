import BaseService from "./BaseService";

class EntityUnitService extends BaseService {}

const EntityBathroomFaucetService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "bathroom-faucets",
]);

const EntityDishwasherService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "dishwashers",
]);

const EntityKitchenFaucetService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "kitchen-faucets",
]);

const EntityShowerService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "showers",
]);

const EntityToiletService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "toilets",
]);

const EntityWashingMachineService = new EntityUnitService("/api/", [
  "projects",
  "systems",
  "washing-machines",
]);

export {
  EntityBathroomFaucetService,
  EntityDishwasherService,
  EntityKitchenFaucetService,
  EntityShowerService,
  EntityToiletService,
  EntityWashingMachineService,
};
