import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import getEntityStatus from "../utils/getEntityStatus";
import DoneIcon from '@mui/icons-material/Done';

export default function EntityStatusTaskItems(props) {
  let {entityStatus, entityData, showDoneIcon} = props
  let [taskItemsDone, setTaskItemsDone] = useState(null)
  let [taskItemSpecs, setTaskItemSpecs] = useState(null)

  useEffect(() => {
    if (!entityData) {
      return
    }

    if (!entityStatus) {
      entityStatus = getEntityStatus(entityData)
    }

  }, [entityData, getEntityStatus])

  useEffect(() => {
    if (!entityStatus) {
      return
    }

    setTaskItemsDone(entityStatus.task_items_done)
    setTaskItemSpecs(entityStatus.task_items_specs)
  }, [entityStatus])


  return (
    <>
      {taskItemSpecs?.length === 0 ? (
        <>
        </>
      ) : (
        <>
          <span>
            {taskItemsDone?.length} / {taskItemSpecs?.length}
          </span>
          {showDoneIcon === true && (
            <>
              <DoneIcon />
            </>
          )}
        </>
      )}
    </>
  )
}

EntityStatusTaskItems.propTypes = {
  entityData: PropTypes.object,
  jobData: PropTypes.object,
  showDoneIcon: PropTypes.bool,
}