import { useEffect } from "react";

import { MobileMenu } from "../../components/menu";
import { useUser } from "../../context";
import {
  LayoutToolbarContextProvider,
  useLayoutBottom,
  useLayoutRight,
} from "../../layout";
import { LayoutAppPlacement } from "./placement";

export function LayoutAppMobile({ children }) {
  const { user } = useUser();

  const layoutBottom = useLayoutBottom();
  const layoutRight = useLayoutRight({ initialHidden: true });

  useEffect(() => {
    if (!user) {
      // Remove the menu if the user is not logged in.
      layoutBottom.setElement(null);
      return;
    }

    // Add the menu if the user is logged in.
    layoutBottom.setElement(<MobileMenu user={user} />);
  }, [user]);

  return (
    <LayoutToolbarContextProvider placement={layoutRight}>
      <LayoutAppPlacement padding={0}>{children}</LayoutAppPlacement>
    </LayoutToolbarContextProvider>
  );
}
