import {TextField} from "@mui/material";

export default function AdaptableMultilineTextField(props) {
  let {value} = props

  return (
    <>
      <TextField
        multiline
        fullWidth
        rows={(value.match(/\n/g) || [null,]).length + 1}
        value={value}
        {...props}
      />
    </>
  )
}