import { useHierarchyContext } from "../../context";
import { HierarchyTaskInterface } from "../components";
import { HierarchyNavigation } from "../components/HierarchyNavigation";
import { HierarchyEntityRSSystem } from "./radiator_system";

export function HierarchyEntitySystem() {
  const { focusEntityData, system } = useHierarchyContext();

  return (
    <>
      {system.type === "radiator" ? <HierarchyEntityRSSystem /> : <div></div>}

      {!!focusEntityData && (
        <HierarchyTaskInterface />
      )}

      <HierarchyNavigation />
    </>
  );
}
