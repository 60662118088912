import BaseValidator from "../BaseValidator";
import FieldValidationError from "../FieldValidationError";

export default class BaseFieldValidator extends BaseValidator{
  fieldName = undefined
  nodeTypes = undefined

  // If this validator is a match
  match = (row) => {
    if(this.nodeTypes === undefined){
      return true
    }

    return this.nodeTypes.indexOf(row.node.type) >= 0
  }

  getFieldErrors = (row) => {
    return []
  }

  getFieldWarnings = (row) => {
    return []
  }


  /**
   * Blocks calculation
   *
   * @param row
   * @param errorMessage
   * @return {FieldValidationError}
   */
  error = (row, errorMessage) => {
    return new FieldValidationError(
      this.ERROR_TYPE_FIELD,
      this.fieldName,
      row,
      errorMessage
    )
  }

  /**
   * Does not block calculation but should be shown as a warning
   * @param row
   * @param warningMessage
   * @return {FieldValidationError}
   */
  warning = (row, warningMessage) => {
    return new FieldValidationError(
      this.ERROR_TYPE_FIELD,
      this.fieldName,
      row,
      warningMessage
    )
  }
}