import {cloneElement, useEffect, useState} from "react";
import {Box, Grid, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';

export default function ConfirmableButton(props) {
  let {callbackAttributes, closeOnConfirm, title, body, confirmIcon, confirmText, confirmColor, cancelText, cancelColor} = props

  let [open, setOpen] = useState(false)
  let [width, setWidth] = useState('auto')
  let [style, setStyle] = useState(null)

  useEffect(() => {
    if (!props.width) {
      return
    }

    setWidth(props.width)
  }, [props.width])


  useEffect(() => {
    setOpen(!!props.open)
  }, [props.open])


  useEffect(() => {
    setStyle(
      {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width || 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
    )
  }, [width])

  useEffect(() => {
    if (!props.children) {
      return
    }
  }, [props.children])


  const handleOnClick = (e) => {
    setOpen(true)
  }

  const handleClose = (e) => {
    setOpen(false)
    if (props.hasOwnProperty('setClose')) {
      props.onClose()
    }
  }

  function handleDeleteButtonClick() {
    props.children.props.onClick(callbackAttributes || undefined)

    if(closeOnConfirm === true){
      setOpen(false)
    }
  }

  return (
    <>
      <>
        {cloneElement(props.children, {onClick: handleOnClick})}
      </>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Typography variant="h2">
                {title || window.gettext("Are you sure?")}
              </Typography>
            </Grid>
            {body && (
              <>
                <Grid item>
                  {body}
                </Grid>
              </>
            )}
            <Grid item>
              <Button
                variant="contained"
                color={confirmColor || "error"}
                sx={{mr: 2}}
                startIcon={confirmIcon || <DeleteIcon/>}
                onClick={handleDeleteButtonClick}
              >
                {confirmText || window.gettext('Yes')}
              </Button>
              <Button
                variant="contained"
                color={cancelColor || "primary"}
                onClick={handleClose}
              >
                {cancelText || window.gettext('No')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  )
}