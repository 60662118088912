import React, {useEffect, useState} from "react";
import SystemCodedAddressService from "../../services/SystemCodedAddressService";
import {Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";

export default function CodedAddressEditDialog(props) {
  let [is_open, setIsOpen] = useState(false)
  let [address, setAddress] = useState(undefined)
  let [streetName, setStreetName] = useState('')
  let [shortCode, setShortCode] = useState('')

  useEffect(() => {
    if (!props.system || !props.addressId) {
      return
    }

    console.log('props', props.system, props.addressId)

    SystemCodedAddressService.fetch(
      props.system.project_id,
      props.system.id,
      props.addressId
    ).then(
      setAddress
    )
  }, [props.system, props.addressId])

  useEffect(() => {
    if (!props.addressId) {
      setIsOpen(true)
      return
    }

    setIsOpen(true)
  }, [props.addressId])

  useEffect(() => {
    console.log('addr', address)
    if (!address) {
      return
    }

    setStreetName(address.street_name || '')
    setShortCode(address.short_code)
  }, [address])

  const handleClose = function () {
    setIsOpen(false)
    setAddress(undefined)
    props.handleClose()
  }

  const handleSave = function () {
    console.info('Saving', address)
    SystemCodedAddressService.update(
      {
        'short_code' : shortCode,
        'street_name': streetName,
      },
      props.system.project_id,
      props.system.id,
      props.addressId
    ).then(
      (response) => {
        console.log('response')
        handleClose()
        props.afterSave()
      }
    )
  }

  return (
    <>
      <Dialog
        open={is_open}
      >
        <>
          {address !== undefined && (
            <>
              <DialogTitle>
                {window.gettext('Edit address')} "{address.street_name || address.short_code}"
              </DialogTitle>

              <DialogContent>
                <Stack direction="column" spacing={2}>
                  <TextField
                    autoFocus
                    onChange={(event) => setStreetName(event.target.value)}
                    label={window.gettext('Street name')}
                    value={streetName}
                  />
                  <TextField
                    label={window.gettext('Short code')}
                    disabled={true}
                    onChange={(event) => setShortCode(event.target.value)}
                    value={shortCode}
                  />
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={handleClose} color="error">{window.gettext('Cancel')}</Button>
                <Button variant="contained" onClick={handleSave}>{window.gettext('Save')}</Button>
              </DialogActions>
            </>
          )}
        </>
      </Dialog>
    </>
  )
}