import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

export function FactorySelectMultipleField({
  formField,
  hasError,
  helperText,
  validationErrors,
}) {
  return ({ field }) => {
    return (
      <Stack {...formField.options.props}>
        <FormControl>
          {formField.label && (
            <InputLabel
              variant="standard"
              id="demo-simple-select-autowidth-label"
            >
              {formField.label}
            </InputLabel>
          )}

          <Select
            {...field}
            variant="standard"
            multiple
            displayEmpty
            value={field.value || []}
            checked={field.value === true}
            data-test-id={formField.name}
            label={formField.label}
            required={formField.required}
            error={hasError}
          >
            {(formField.options?.choices || []).map(([key, value]) => {
              return (
                <MenuItem key={"select_option_" + key} value={key}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {validationErrors !== null ? (
          <>Errors.</>
        ) : (
          helperText && (
            <>
              <FormHelperText>{helperText}</FormHelperText>
            </>
          )
        )}
      </Stack>
    );
  };
}
