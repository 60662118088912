import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import ProjectService from "../../../../../../components/services/ProjectService";
import SystemLocationsService from "../../../../../../components/services/SystemLocationsService";
import SystemService from "../../../../../../components/services/SystemService";
import CodedAddressEditDialog from "../../../../../../components/system/locations/CodedAddressEditDialog";

import { LayoutCard, LayoutView } from "../../../../../../layout";

export default function SystemLocationsView() {
  const [system, setSystem] = useState(null);
  const [project, setProject] = useState(null);

  const [editAddress, setEditAddress] = useState(undefined);
  const [codedAddresses, setCodedAddresses] = useState([]);

  let { system_id, project_id } = useParams();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // Fetch systems for project
    SystemService.fetch(project_id, system_id).then(setSystem);
    ProjectService.fetch(project_id).then(setProject);
  }, [project_id, system_id]);

  const refreshCodedAddresses = useCallback(
    function () {
      SystemLocationsService.list(system.project_id, system.id).then(
        setCodedAddresses
      );
    },
    [system]
  );

  useEffect(() => {
    if (!project || !system) {
      return;
    }

    refreshCodedAddresses();
  }, [refreshCodedAddresses, project, system]);

  const handleClickAddress = function (address) {
    setEditAddress(address);
  };

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 1,
        system: system,
      }}
      header={window.gettext("Locations")}
    >
      <LayoutCard
        title={window.gettext("Locations")}
        noPadding
        sx={{
          maxWidth: 616,
        }}
      >
        {codedAddresses.length === 0 ? (
          <Alert severity="info">
            {window.gettext("No locations defined for this system")}
          </Alert>
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                {codedAddresses.map((address) => {
                  return (
                    <TableRow key={address.id}>
                      <TableCell>{address.street_name || ""}</TableCell>
                      <TableCell>{address.short_code}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            handleClickAddress(address);
                          }}
                        >
                          {window.gettext("Edit")}
                        </Button>
                      </TableCell>
                      <TableCell>
                        {!address.street_name && (
                          <>
                            <Tooltip
                              title={window.gettext(
                                "Street name of address is not defined"
                              )}
                            >
                              <WarningAmberIcon color="warning" />
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {editAddress !== undefined && (
          <CodedAddressEditDialog
            system={system}
            addressId={editAddress.id}
            handleClose={() => {
              setEditAddress(undefined);
            }}
            afterSave={() => {
              enqueueSnackbar(window.gettext("Address saved"), {
                variant: "success",
              });
              setCodedAddresses([]);
              refreshCodedAddresses();
            }}
          />
        )}
      </LayoutCard>
    </LayoutView>
  );
}
