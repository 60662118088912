import { findElementById } from "../../../../../../../../../helper";
import {
  getAddress,
  getApartmentNumber,
  getEntityName,
  getFieldNameTask,
  getFieldNameTaskItem,
  getId,
  getObjectNumber,
} from "./helper";
import { getFieldNameComment } from "./helper/getFieldNameComment";

// Will be imported from constant file in the future
const TASK_SPEC_TYPE_ITEM_GROUP = "item_group";
const TASK_SPEC_TYPE_ITEM_OPTION_LIST = "item_option_list";

// Will be imported from constant file in the future
const TASK_ITEM_SPEC_TYPE_BOOLEAN = "boolean";
const TASK_ITEM_SPEC_TYPE_TEXT = "text-filled-marks-done";

const DONE_VALUE = "X";
const NOT_DONE_VALUE = undefined;
const NO_COMMENT_VALUE = undefined;
const NO_OPTION_VALUE = undefined;
const OPTION_SEPARATOR = "\n";

export function getRows(jobs, taskItemSpecs, taskSpecs) {
  const rows = [];

  // Loop through jobs
  jobs.forEach((job) => {
    // Loop through job entities
    job.entities.forEach((entity) => {
      // Create row with values for default columns
      const row = {
        id: getId(entity),
        address: getAddress(entity),
        apartment_number: getApartmentNumber(entity),
        entity_name: getEntityName(entity),
        object_number: getObjectNumber(entity),
        entity_type: entity.type,
      };

      // Loop through entity tasks
      entity.tasks.forEach((task) => {
        // Find task specification
        const taskSpec = findElementById(task.spec_id, taskSpecs);

        const taskFieldName = getFieldNameTask(taskSpec);

        // Only add task value if task specification is of type item group
        if (taskSpec.type === TASK_SPEC_TYPE_ITEM_GROUP) {
          // Add task
          row[taskFieldName] = task.done ? DONE_VALUE : NOT_DONE_VALUE;
        } else if (taskSpec.type === TASK_SPEC_TYPE_ITEM_OPTION_LIST) {
          // Add no option value, will be replaced later if there is an option
          row[taskFieldName] = NO_OPTION_VALUE;
        }

        if (taskSpec.enable_commenting) {
          const taskCommentFieldName = getFieldNameComment(taskFieldName);
          // Add comment
          row[taskCommentFieldName] =
            task.comment !== "" ? task.comment : NO_COMMENT_VALUE;
        }
      });

      entity.task_items.forEach((taskItem) => {
        if (taskItem.done) {
          // Find task item specification
          const taskItemSpec = findElementById(taskItem.spec_id, taskItemSpecs);

          // Find task specification
          const taskSpec = findElementById(
            taskItemSpec.task_spec_id,
            taskSpecs
          );

          if (taskSpec.type === TASK_SPEC_TYPE_ITEM_GROUP) {
            const taskItemFieldName = getFieldNameTaskItem(taskItemSpec);

            // Add task item
            if (taskItemSpec.type === TASK_ITEM_SPEC_TYPE_BOOLEAN) {
              row[taskItemFieldName] = taskItem.done
                ? DONE_VALUE
                : NOT_DONE_VALUE;
            } else if (taskItemSpec.type === TASK_ITEM_SPEC_TYPE_TEXT) {
              row[taskItemFieldName] = taskItem.text;
            }

            if (taskItemSpec.enable_commenting) {
              const taskItemCommentFieldName =
                getFieldNameComment(taskItemFieldName);

              // Add comment
              row[taskItemCommentFieldName] =
                taskItem.comment !== "" ? taskItem.comment : NO_COMMENT_VALUE;
            }
          } else if (taskSpec.type === TASK_SPEC_TYPE_ITEM_OPTION_LIST) {
            // Get current value
            let rowValue = row[getFieldNameTask(taskSpec)];

            // Add task item specification name
            if (rowValue === NO_OPTION_VALUE) {
              rowValue = taskItemSpec.name;
            } else {
              rowValue += OPTION_SEPARATOR + taskItemSpec.name;
            }

            row[getFieldNameTask(taskSpec)] = rowValue;
            row[getFieldNameTaskItem(taskItemSpec)] = NOT_DONE_VALUE;
          }
        }
      });

      rows.push(row);
    });
  });

  return rows;
}
