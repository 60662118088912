import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardContent, Chip, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";

import Sheet from "../../../components/layout/Sheet";
import JobName from "../../../components/project/self_check/job/JobName";
import MeasurementsList from "../../../components/project/self_check/job/measurement/MeasurementsList";
import SelfCheckGridViewButtonLink from "../../../components/project/self_check/job/utils/SelfCheckGridViewButtonLink";
import SelfCheckHierarchyButtonLink from "../../../components/project/self_check/job/utils/SelfCheckHierarchyButtonLink";
import ProjectService from "../../../components/services/ProjectService";

import { LayoutView } from "../../../layout";

export default function ProjectsSelfCheckFocusView(props) {
  let [projects, setProjects] = useState(null)

  useEffect(() => {
    ProjectService.list('?system_jobs=true').then(
      (response) => {
        setProjects(response)
      }
    )
  }, [])

  return (
    <LayoutView header={window.gettext('Projects with jobs')}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
      >
        <Grid item xs={12}>
          {projects && (
            <>
              {projects.map(
                (project, index) => {
                  const jobs = project.systems.map((system) => (system.jobs)).flat()

                  if (jobs.length === 0) {
                    return (
                      <Box
                        key={'project_' + project.id}
                      >
                      </Box>
                    )
                  }

                  return (
                    <Box
                      key={'project_' + project.id}
                    >
                      <Sheet
                        mt
                      >
                        <Typography variant="h2">
                          <span>
                            {project.name}
                          </span>
                          <Chip sx={{ ml: 1 }} label={jobs.length} />
                        </Typography>

                        {project.systems.map((system, system_i) =>
                        /*
                        * Each system on that project
                        * */
                        (
                          <Card
                            key={'project_' + project.id + '_system_' + system.id + '_i_' + system_i}
                          >
                            <CardContent>
                              <Grid
                                container
                                direction="column"
                                spacing={2}
                              >
                                <Grid item xs={12}>
                                  <Typography variant="h3">
                                    <span>{system.name}</span>
                                    <Chip sx={{ ml: 1 }} label={system.jobs.length} />
                                  </Typography>
                                </Grid>
                                {system.jobs.map((job) =>
                                /*
                                * Each job on that system
                                * */
                                (
                                  <Grid
                                    item
                                    key={'project_' + project.id + '_system_' + system.id + '_job_' + String(job.id)}
                                  >
                                    <Grid
                                      container
                                      spacing={1}
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        md={4}
                                      >
                                        <JobName
                                          system={system}
                                          job={job}
                                          link
                                        />
                                      </Grid>
                                      <Grid item>
                                        <SelfCheckHierarchyButtonLink
                                          projectId={system.project}
                                          system={system}
                                          job={job}
                                          size={'small'}
                                          sx={{ ml: 1 }}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <SelfCheckGridViewButtonLink
                                          projectId={system.project}
                                          system={system}
                                          job={job}
                                          size={'small'}
                                          sx={{ ml: 1 }}
                                        />
                                      </Grid>
                                      {
                                        job?.measurements.length > 0 && (
                                          <>
                                            <Grid item xs={12}>
                                              <Accordion>
                                                <AccordionSummary
                                                  expandIcon={<ExpandMoreIcon />}
                                                >

                                                  <Typography variant={"h7"}>
                                                    {window.gettext('Measurements')}
                                                  </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <MeasurementsList
                                                    measurements={job.measurements}
                                                    projectId={system.project}
                                                    systemId={system.id}
                                                    jobId={job.id}
                                                    jobSpecId={job.spec_id}
                                                  />
                                                </AccordionDetails>
                                              </Accordion>
                                            </Grid>
                                          </>
                                        )
                                      }
                                    </Grid>
                                  </Grid>
                                ))}
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                      </Sheet>
                    </Box>
                  )
                }
              )}
            </>
          )}
        </Grid>
      </Grid>
    </LayoutView>
  )
}