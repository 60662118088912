import { Chip, Select, Stack } from "@mui/material";

import { HierarchyTaskItemOption } from "../task_items";

export function HierarchyTaskTypeItemOptionList({
  entity,
  hasError,
  isSaving,
  taskItems,
  updateData,
}) {
  const selected = [];

  const labels = {};

  const options = taskItems.map(({ task_item, task_item_spec }) => {
    const isDone = task_item?.done === true;

    if (isDone) {
      selected.push(task_item_spec.id);
    }

    labels[task_item_spec.id] = task_item_spec.name;

    return {
      isDone,
      onClick: () =>
        updateData(task_item_spec, false, entity.type, entity.id, task_item, {
          done: !isDone,
        }),
      value: task_item_spec.id,
    };
  });

  return (
    <Select
      multiple
      value={selected}
      renderValue={(selected) => (
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 0.5,
          }}
        >
          {selected.map((value, label) => (
            <Chip key={value} label={labels[value]} />
          ))}
        </Stack>
      )}
    >
      {options.map(({ isDone, onClick, value }) => {
        return (
          <HierarchyTaskItemOption
            isSaving={isSaving}
            hasError={hasError}
            key={value}
            value={value}
            checked={isDone}
            label={labels[value]}
            onClick={onClick}
          />
        );
      })}
    </Select>
  );
}
