export default function PreferredView(user, view_name) {
  // Some basic sanity checks
  if(!user){
    return []
  }

  let preferred_views = {};

  // generate preferred views
  user.teams.map((team) => {
      for (let [key, value] of Object.entries(team.preferred_views)) {
        if (key !== view_name) {
          continue
        }

        const preferred_view_name = value['value']

        // If the preferred views object
        // does not have a key that represent the preferred view name
        // create an object here.
        if (!preferred_views.hasOwnProperty(preferred_view_name)) {
          preferred_views[preferred_view_name] = {
            'preference': value,
            'view_name': view_name,
            'sources': []
          }
        }

        preferred_views[preferred_view_name]['sources'].push(
          {
            'type': 'team',
            'data': team
          }
        )

      }

      return team
    }
  )

  return Object.values(preferred_views);
}