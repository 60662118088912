import BaseService from "../BaseService";

class TaskService extends BaseService {

}

export default new TaskService('/api/',
  [
    'projects',
    'systems',
    'self-check/jobs',
    'tasks',
  ]
)