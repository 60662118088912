import { FormContainerItem } from "../../components";

export function renderContainer(
  formContainer,
  control,
  validationErrors,
  key,
) {
  return (
    <FormContainerItem
      formContainer={formContainer}
      key={key}
      control={control}
      validationErrors={validationErrors}
    />
  );
}
