import { useContext, useEffect, useState } from "react";

import IssueCategoryService from "../services/IssueCategoryService";
import ProjectService from "../services/ProjectService";
import SystemService from "../services/SystemService";
import UserService from "../services/UserService";
import { useIssueChoices } from "./IssueChoicesContext";
import { IssueFilterContext } from "./IssueFilterContext";

import { Form, FormFieldFactory } from "../form";

export default function IssueListFiltersForm({ onSubmit }) {
  const { issueListFilters, setIssueListFilters } =
    useContext(IssueFilterContext);

  // Issue options
  const { issueChoices } = useIssueChoices();

  // Issue categories
  const [issueCategories, setIssueCategories] = useState(undefined);
  // Projects
  const [projects, setProjects] = useState(undefined);
  // Systems
  const [systems, setSystems] = useState(undefined);
  // Users
  const [users, setUsers] = useState(undefined);

  const [formFieldFactory] = useState(new FormFieldFactory());

  const [formFields, setFormFields] = useState(undefined);

  const handleSubmit = (data) => {
    setIssueListFilters(
      Object.fromEntries(
        Object.entries({
          ...issueListFilters,
          ...data,
        }).filter(([_, v]) => {
          return v !== "";
        })
      )
    );

    if (onSubmit) {
      onSubmit();
    }
  };

  useEffect(() => {
    ProjectService.list().then(setProjects);
    SystemService.listAll().then(setSystems);
    IssueCategoryService.list().then(setIssueCategories);
    UserService.list().then(setUsers);
  }, []);

  useEffect(() => {
    if (!issueChoices || !issueCategories || !projects || !systems || !users) {
      return null;
    }

    setFormFields([
      formFieldFactory.textField(
        "search",
        window.gettext("Text search"),
        false,
        ""
      ),
      formFieldFactory
        .selectMultiple(
          "status",
          window.gettext("Status"),
          false,
          issueListFilters.status
        )
        .withChoices(Object.entries(issueChoices.status)),
      formFieldFactory.choicesField(
        "category",
        window.gettext("Category"),
        false,
        "",
        {
          choices: formFieldFactory.getChoicesFromObjectList(issueCategories),
          blankOptionEnabled: true,
        }
      ),
      formFieldFactory.choicesField(
        "project",
        window.gettext("Project"),
        false,
        "",
        {
          choices: formFieldFactory.getChoicesFromObjectList(projects),
          blankOptionEnabled: true,
        }
      ),
      formFieldFactory.choicesField(
        "system",
        window.gettext("System"),
        false,
        "",
        {
          choices: formFieldFactory.getChoicesFromObjectList(systems),
          blankOptionEnabled: true,
        }
      ),
      // Assigned to
      formFieldFactory.choicesField(
        "assigned_to",
        window.gettext("Assigned to"),
        false,
        issueListFilters.assigned_to,
        {
          choices: {
            ...{
              me: window.gettext("Me"),
            },
            ...formFieldFactory.getChoicesFromObjectList(users, "label"),
          },
          blankOptionEnabled: true,
        }
      ),
      // Assigned to
      formFieldFactory.choicesField(
        "watcher",
        window.gettext("Watcher"),
        false,
        issueListFilters.watcher,
        {
          choices: {
            ...{
              me: window.gettext("Me"),
            },
            ...formFieldFactory.getChoicesFromObjectList(users, "label"),
          },
          blankOptionEnabled: true,
        }
      ),
    ]);
  }, [
    formFieldFactory,
    issueCategories,
    issueChoices,
    issueListFilters,
    projects,
    systems,
    users,
  ]);

  return (
    <>
      {formFields && (
        <Form
          formFields={formFields}
          onSubmit={handleSubmit}
          submitButtonText={window.gettext("Search")}
        />
      )}
    </>
  );
}
