import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid-pro";

export function CustomJobGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarFilterButton />

      <GridToolbarExport
        csvOptions={{
          delimiter: ";", // Cell delimiter
          fileName: window.gettext("Detailed report"),
          allColumns: false,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}
