import { createContext } from "react";
import { useProvideHierarchyRSContext } from "./hooks";

export const HierarchyRSContext = createContext(null);

export function HierarchyRSContextProvider({ children, ...props }) {
  const context = useProvideHierarchyRSContext(props);

  return (
    <HierarchyRSContext.Provider value={context}>
      {children}
    </HierarchyRSContext.Provider>
  );
}