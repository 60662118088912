import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@mui/material";
import DateTime from "../../../../../../values/DateTime";
import Temperature from "../../../../../../values/Temperature";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import VerifiedIcon from '@mui/icons-material/Verified';
import Flow from "../../../../../../values/Flow";
import Helper from "../../../../../../utils/Helper";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditMeasurementOpportunityModal from "../EditMeasurementOpportunityModal";
import EditIcon from "@mui/icons-material/Edit";
import {useSnackbar} from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import UserPermission from "../../../../../../user/UserPermission";
import SelfCheckJobMeasurementOpportunityService
  from "../../../../../../services/measurement/SelfCheckJobMeasurementOpportunityService";
import ConfirmableButton from "../../../../../../layout/ConfirmableButton";

export default function OpportunitiesCollapsableList(props) {
  const {enqueueSnackbar} = useSnackbar()

  let {projectId, systemId, jobId, measurementId, opportunities, latestOpportunity} = props

  let [editOpportunity, setEditOpportunity] = useState(undefined)
  let [opportunitiesList, setOpportunitiesList] = useState([])

  const handleOpportunityChanged = (changedOpportunity) => {
    enqueueSnackbar(
      window.gettext('Measurement opportunity saved'),
      {
        variant: 'success'
      }
    )

    const newOpportunities = opportunities.map(
      (opportunity) => {
        if (opportunity.id === changedOpportunity.id) {
          return changedOpportunity
        }
        return opportunity
      }
    )

    setOpportunitiesList(newOpportunities)
    setEditOpportunity(undefined)
  }

  const handleDeleteButtonClicked = (opportunityToDelete) => {
    SelfCheckJobMeasurementOpportunityService.destroy(
      projectId,
      systemId,
      jobId,
      opportunityToDelete.measurement.id,
      opportunityToDelete.id,
    ).then(
      (response) => {
        enqueueSnackbar(
          window.gettext('Measurement opportunity deleted'),
          {
            variant: 'success'
          }
        )

        setOpportunitiesList(
          opportunitiesList.filter((opportunity) => {
            return opportunity.id !== opportunityToDelete.id
          })
        )
      }
    )
  }

  useEffect(() => {
    setOpportunitiesList(opportunities)
  }, [opportunities])

  return (
    <>
      {opportunitiesList.length === 0 && (
        <>
          <Alert
            severity={"info"}
          >
            {window.gettext('No opportunities on this measurement')}
          </Alert>
        </>
      )}
      {opportunitiesList.map(
        (opportunity, index) => (
          <Accordion
            key={"accordion_opportunity_" + index}
            defaultExpanded={opportunity.id === latestOpportunity?.id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon/>}
            >
              {latestOpportunity?.id === opportunity.id && (
                <>
                  <Tooltip
                    title={window.gettext('Latest opportunity')}
                    sx={{mr: 1}}
                  >
                    <VerifiedIcon/>
                  </Tooltip>
                </>
              )}
              <Typography
                variant={"h6"}
              >
                <DateTime value={opportunity.created}/>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Created')}
                      </TableCell>
                      <TableCell>
                        <DateTime value={opportunity.created}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Outside temperature')}
                      </TableCell>
                      <TableCell>
                        <Temperature value={parseFloat(opportunity.outside_temperature)} precision={1}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Outflow temperature')}
                      </TableCell>
                      <TableCell>
                        <Temperature value={parseFloat(opportunity.outflow_temperature)} precision={1}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Return temperature')}
                      </TableCell>
                      <TableCell>
                        <Temperature value={parseFloat(opportunity.return_temperature)} precision={1}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Pump flow')}
                      </TableCell>
                      <TableCell>
                        <Flow value={opportunity.pump_displayed_flow} precision={0}/>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('Start')}
                        <Helper
                          title={window.gettext('Time of first measurement instance')}
                          ml
                        >
                          {window.gettext('Time when the first measurement instance was added to this measurement opportunity')}
                        </Helper>
                      </TableCell>
                      <TableCell>
                        {(opportunity.start) ? (
                          <DateTime value={opportunity.start}/>
                        ) : (
                          <>
                            <span>-</span>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        {window.gettext('End')}
                        <Helper
                          title={window.gettext('Time of last measurement instance')}
                          ml
                        >
                          {window.gettext('Time when the last measurement instance was added to this measurement opportunity')}
                        </Helper>
                      </TableCell>
                      <TableCell>
                        {(opportunity.end) ? (
                          <DateTime value={opportunity.end}/>
                        ) : (
                          <>
                            <span>-</span>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{mt: 2}}
              >
                <UserPermission permission={UserPermission.permissions.measurement_opportunity_change}>
                  <Button
                    onClick={() => {
                      setEditOpportunity(opportunity)
                    }}
                    startIcon={<EditIcon/>}
                    variant={'contained'}
                    color={'success'}
                  >
                    {window.gettext('Edit')}
                  </Button>
                </UserPermission>
                <UserPermission permission={UserPermission.permissions.measurement_opportunity_delete}>
                  <ConfirmableButton
                    callbackAttributes={opportunity}
                    closeOnConfirm
                    body={window.gettext('Do you want to delete this measurement opportunity, this can not be undone.')}
                  >
                    <Button
                      onClick={handleDeleteButtonClicked}
                      sx={{ml: 2}}
                      startIcon={<DeleteIcon/>}
                      color={'error'}
                      variant={'outlined'}
                    >
                      {window.gettext('Delete')}
                    </Button>
                  </ConfirmableButton>
                </UserPermission>
              </Box>
            </AccordionDetails>
          </Accordion>
        )
      )}
      <>
        <EditMeasurementOpportunityModal
          projectId={projectId}
          systemId={systemId}
          jobId={jobId}
          measurementId={measurementId}
          opportunity={editOpportunity}
          onClose={() => {
            setEditOpportunity(undefined)
          }}
          onChanged={handleOpportunityChanged}
        />
      </>
    </>
  )
}

OpportunitiesCollapsableList.propTypes = {
  'opportunities': PropTypes.array,
  'latestOpportunity': PropTypes.object,
}