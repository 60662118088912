class EventResultClass {
  preventDefault

  constructor(preventDefault) {
    this.preventDefault = preventDefault
  }
}

export default function EventResult(preventDefault){
  return new EventResultClass(preventDefault)
}