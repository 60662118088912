export default function ValueSetter(path) {
  const path_parts = path.split('.')

  return function (params) {
    let row = params.row

    let value = row

    // Get the next-to-last item in the path
    for(let i = 0; i + 1 < path_parts.length; i += 1){
      value = value[path_parts[i]]
    }

    // Set value on the last item
    value[path_parts[path_parts.length - 1]] = params.value

    return row
  }
}