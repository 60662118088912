import BaseService from "./BaseService";

class IssueWatchersService extends BaseService {
  add_me = (issue_id) => {
    return this.post(
      {},
      ...[
        issue_id,
        'me'
      ]
    )
  }

  get_me(issue_id) {
    return this.fetch(
      ...[
        issue_id,
        'me'
      ]
    )
  }

  remove_me = (issue_id) => {
    return this.destroy(
      ...[
        issue_id,
        'me'
      ]
    )

  }
}

export default new IssueWatchersService('/api/',
  [
    'issues',
    'watchers',
  ]
)