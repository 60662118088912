import {useEffect, useState} from "react";

export default function WithSuffix(props) {
  const additionalSuffix = props.additionalSuffix || ''
  const spaceBeforeSuffix = props.spaceBeforeSuffix === undefined ? true : props.spaceBeforeSuffix === true
  let [thousandSeparator, setThousandSeparator] = useState('')

  let [suffix, setSuffix] = useState(props.suffix)
  let [displayValue, setDisplayValue] = useState(undefined)
  let [precision, setPrecision] = useState(undefined)

  useEffect(() => {
    if (!props.thousandSeparator) {
      return
    }

    if(props.thousandSeparator === true){
      setThousandSeparator(',')
    }else{
      setThousandSeparator(props.thousandSeparator)
    }
  }, [props.thousandSeparator])

  useEffect(() => {
    setPrecision(props.precision)
  }, [props.precision])

  useEffect(() => {
    setSuffix(props.suffix)
  }, [props.suffix])

  useEffect(() => {
    let valueFromProps = props.value;

    // If there is no value, do not display number or suffix
    if (valueFromProps === undefined) {
      setDisplayValue(undefined)
      setSuffix(undefined)
      return
    }

    if (valueFromProps === null) {
      setDisplayValue(props.nullString || '')
      setSuffix(undefined)
      return
    }

    if (isNaN(valueFromProps)) {
      setDisplayValue(props.nanString || '')
      setSuffix(undefined)
      return
    }

    // If we are tasked with displaying a string, do not display any suffix.
    if (typeof valueFromProps === 'string') {
      setDisplayValue(valueFromProps)
      setSuffix(undefined)
      return;
    }

    if (precision === undefined || precision === null) {
      setDisplayValue(String(valueFromProps))
    } else {
      try {
        setDisplayValue(
          valueFromProps.toFixed(
            precision
          )
        )
        setSuffix(props.suffix)
      } catch (err) {
        console.info('value', valueFromProps)
        console.error(err)
      }
    }

    setDisplayValue(
      String(precision === undefined ? String(valueFromProps) : valueFromProps.toFixed(precision)).replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
    )
  }, [props.value, precision, props.nanString, props.nullString, props.suffix, thousandSeparator])


  return (
    <>
      {
        displayValue !== undefined && (
          <>
            <span>{displayValue}</span>
            {
              suffix !== undefined && (
                <>
                  {
                    spaceBeforeSuffix === true && (
                      <span>&nbsp;</span>
                    )
                  }
                  <span>{suffix}{additionalSuffix || ""}</span>
                </>
              )
            }
          </>
        )
      }
    </>
  )
}