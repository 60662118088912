import React from "react";
import {useIssue} from "./IssueContext";
import {useSnackbar} from "notistack";
import {Button} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UserPermission from "../user/UserPermission";
import {useNavigate} from "react-router-dom";
import IssueService from "../services/IssueService";
import {TAB_EDIT} from "../../views/issues/id/tabs";

export default function CreateIssueButton({}) {
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const [disableButton, setDisableButton] = React.useState(false)

  const handleCreateButtonClick = (issue) => {
    setDisableButton(true)
    // After a issue is created
    // Toast save success
    IssueService.create().then((issue) => {
        enqueueSnackbar(
          window.gettext("Created"),
          {
            variant: "success",
          }
        )
        setDisableButton(false)
        navigate("/issues/" + String(issue.id) + "/?tab=" + TAB_EDIT)
      }
    )
  }

  return (
    <UserPermission
      permission={UserPermission.permissions.issue_add}
    >
      <Button
        variant="contained"
        disabled={disableButton}
        color="primary"
        startIcon={<AddIcon/>}
        onClick={handleCreateButtonClick}
      >
        {window.gettext("Create")}
      </Button>
    </UserPermission>
  )
}
