import { ENTITY_TYPES_COMMON, ENTITY_TYPES_RADIATOR } from "../../../entity";

import { DEFAULT_OPTION_VALUES } from "../../constants";
import { HierarchyViewCEOptions } from "./common_entities";
import { HierarchyViewRSOptions } from "./radiator_system";

const LOCAL_STORAGE_KEY = "hierarchy_view_options";
let localStorageOptions;

export function saveOptionToLocalStorage(entityType, optionName, optionValue) {
  if (localStorageOptions === null) {
    localStorageOptions = {};
  }

  if (localStorageOptions?.[entityType] === undefined) {
    localStorageOptions[entityType] = { ...DEFAULT_OPTION_VALUES[entityType] };
  }

  localStorageOptions[entityType][optionName] = optionValue;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageOptions));
}

export class HierarchyViewOptionsList {
  constructor() {
    localStorageOptions = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));

    for (const entityType of ENTITY_TYPES_COMMON) {
      this[entityType] = new HierarchyViewCEOptions(
        entityType,
        localStorageOptions?.[entityType] || DEFAULT_OPTION_VALUES[entityType]
      );
    }

    for (const entityType of ENTITY_TYPES_RADIATOR) {
      this[entityType] = new HierarchyViewRSOptions(
        entityType,
        localStorageOptions?.[entityType] || DEFAULT_OPTION_VALUES[entityType]
      );
    }
  }
}
