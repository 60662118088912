import BaseService from "./BaseService";

class SelfCheckJobInstallationProfileService extends BaseService {
  preSendDataTransformer = (data) => {
    console.log('PRE SEND', data)

    if (data?.project_row_ids) {
      data['project_row_ids'] = Object.values(data.project_row_ids)
    }

    if(data?.entity_list_row_types) {
      data['entity_list_row_types'] = Object.values(data.entity_list_row_types)
    }

    if(data?.entity_list_visible_columns) {
      data['entity_list_visible_columns'] = Object.values(data.entity_list_visible_columns)
    }

    if(data?.entity_list_entrance_filters) {
      data['entity_list_entrance_filters'] = Object.values(data.entity_list_entrance_filters)
    }

    if(data?.entity_list_apartment_filters) {
      data['entity_list_apartment_filters'] = Object.values(data.entity_list_apartment_filters)
    }

    return data
  }
  postFetchDataTransformer = (data) => {
    if (data?.project_rows) {
      data['project_row_ids'] = data.project_rows.map(
        (project_row) => {
          return project_row.id
        }
      )
    }

    return data
  }
}

export default new SelfCheckJobInstallationProfileService('/api/',
  [
    'job',
    'installation-report-profiles',
  ]
)