import React, { cloneElement, useEffect, useState } from "react";

import InstanceManipulator from "../../../../../InstanceManipulator";
import SelfCheckTaskSpecService from "../../../../../services/SelfCheckTaskSpecService";
import SelfCheckTaskSpecForm from "../../../../../self_check/SelfCheckTaskSpecForm";
import { CenteredModal, useModalHandler } from "../../../../../layout/modal";

import { useSnackbar } from "notistack";

export function ModalCreateTaskSpec(props) {
  const { enqueueSnackbar } = useSnackbar();
  const modalHandler = useModalHandler();

  let [jobSpec, setJobSpec] = useState(null);
  let [project, setProject] = useState(null);
  let [taskSpecId, setTaskSpecId] = useState(undefined);
  let [submitButtonText, setSubmitButtonText] = useState(undefined);

  useEffect(() => {
    if (!taskSpecId) {
      setSubmitButtonText(window.gettext("Create task specification"));
      return;
    }

    setSubmitButtonText(window.gettext("Save task specification"));
  }, [taskSpecId]);

  useEffect(() => {
    if (props.taskSpecId === undefined) {
      return;
    }
    setTaskSpecId(props.taskSpecId);
  }, [props.taskSpecId]);

  useEffect(() => {
    setJobSpec(props.jobSpec);
  }, [props.jobSpec]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  const handleOnClick = (e) => {
    modalHandler.open();
  };

  let postUpdate = (data) => {
    console.log("post update", data);

    enqueueSnackbar(
      window.gettext('Task specification "' + data.name + '" saved'),
      {
        variant: "success",
      }
    );

    modalHandler.close();

    if (props.hasOwnProperty("postUpdate")) {
      props.postUpdate();
    }
  };

  let postCreate = (data) => {
    console.log("post create", data);

    enqueueSnackbar(
      window.gettext('Task specification "' + data.name + '" created'),
      {
        variant: "success",
      }
    );

    modalHandler.close();

    if (props.hasOwnProperty("postCreate")) {
      props.postCreate();
    }
  };

  return (
    <>
      {jobSpec !== null &&
        project !== null &&
        taskSpecId !== undefined &&
        submitButtonText !== undefined && (
          <>
            {cloneElement(props.children, { onClick: handleOnClick })}

            <CenteredModal modalHandler={modalHandler}>
              <InstanceManipulator
                service={SelfCheckTaskSpecService}
                urlParams={[project.id, jobSpec.id, taskSpecId]}
                postCreate={postCreate}
                postUpdate={postUpdate}
                mode={taskSpecId === null ? "create" : "edit"}
                form={
                  <SelfCheckTaskSpecForm
                    project={project}
                    jobSpec={jobSpec}
                    submitButtonText={submitButtonText}
                  />
                }
              />
            </CenteredModal>
          </>
        )}
    </>
  );
}
