import {createContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import SelfCheckJobService from "../../../../../services/SelfCheckJobService";
import SelfCheckJobMeasurementReportProfileBlacklistService
  from "../../../../../services/SelfCheckJobMeasurementReportProfileBlacklistService";

export const JobMeasurementReportContext = createContext({
  report: undefined,
  opportunitiesReport: undefined,
  reloadReport: undefined,
  getEntityId: undefined,
  getHideEntityId: undefined,
  blacklistedEntities: undefined,
  removeBlacklistedEntity: undefined,
})

export default function JobMeasurementReportProvider({children}) {
  let {project_id, system_id, job_id, profile_id} = useParams()
  let [report, setReport] = useState(null)
  const [opportunitiesReport, setOpportunitiesReport] = useState(undefined)
  let [blacklistedEntities, setBlacklistedEntities] = useState(null)
  let [hiddenIds, setHiddenIds] = useState({})

  useEffect(() => {
    if (!project_id || !system_id || !job_id) {
      return
    }

    fetchReport()

  }, [project_id, system_id, job_id])

  const fetchReport = () => {
    setReport(null)

    SelfCheckJobService.report_measurement(
      project_id,
      system_id,
      job_id,
      profile_id,
    ).then(
      (response) => {
        // Set hidden ids object
        setHiddenIds(
          Object.fromEntries(
            response.profile.hidden_entities.map(
              (hidden_entity) => {
                return [getHiddenEntityId(hidden_entity), hidden_entity.id]
              }
            )
          )
        )

        // Set report
        setReport(response)
      }
    )

    SelfCheckJobService.report_measurement_opportunities(
      project_id,
      system_id,
      job_id,
      profile_id,
    ).then(
      setOpportunitiesReport
    )

    SelfCheckJobMeasurementReportProfileBlacklistService.fetch(
      project_id,
      system_id,
      job_id,
      profile_id,
    ).then(
      setBlacklistedEntities
    )
  }

  const getHiddenEntityId = (entity) => {
    return (entity.target_type) + '_' + (entity.system || entity.address || entity.house_number || entity.entrance || entity.apartment || entity.room || entity.node)
  }

  const getEntityId = (entity) => {
    return (entity.type) + '_' + entity.id
  }

  const getHideEntityId = (entity) => {
    return hiddenIds[getEntityId(entity)] || null
  }

  const removeBlacklistedEntity = (blacklistedEntity) => {
    return new Promise(
      (resolve, reject) => {
        SelfCheckJobMeasurementReportProfileBlacklistService.destroy(
          project_id,
          system_id,
          job_id,
          profile_id,
          blacklistedEntity.id,
        ).then(
          () => {
            fetchReport()
            resolve(true)
          },
          () => {
            reject(false)
          }
        )
      }
    )
  }
  return (
    <JobMeasurementReportContext.Provider
      value={
        {
          'report': report,
          'opportunitiesReport': opportunitiesReport,
          'reloadReport': () => {
            fetchReport()
          },
          'getEntityId': getEntityId,
          'getHideEntityId': getHideEntityId,
          'blacklistedEntities': blacklistedEntities,
          'removeBlacklistedEntity': removeBlacklistedEntity,
        }
      }
    >
      {children}
    </JobMeasurementReportContext.Provider>
  )
}
