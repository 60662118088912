import BaseService from "./BaseService";

class CustomerService extends BaseService {

}

export default new CustomerService('/api/',
  [
    'customers'
  ]
)