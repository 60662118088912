import PropTypes from "prop-types";

/**
 * Changes to this component should be reflected in the following files:
 * JobDataGrid valueGetter for name (label) column
 *
 * @param apartmentData
 * @param showPrefix
 * @param showLocalApartmentNumber
 * @returns {JSX.Element}
 * @constructor
 */
export default function ApartmentName({apartmentData, showPrefix, showLocalApartmentNumber}) {
  return (
    <>
      {showPrefix && (
        <>
          <>
            {(apartmentData?.surveying_apartment_number || "").endsWith('00') ? (
              <>
                {window.gettext('SPC:')}
              </>
            ) : (
              <>
                {window.gettext('APT:')}
              </>
            )}
          </>
          {/* Add a non breaking space*/}
          <>
            &nbsp;
          </>
        </>
      )}
      {
        (
          (apartmentData?.surveying_apartment_number)
        ) ? (
          <>
            <span>
              {apartmentData.surveying_apartment_number}
            </span>
            {
              /**
               If there is one, and we should show the local apartment number, then show it.
               */
              (showLocalApartmentNumber && apartmentData.local_apartment_number) && (
                <>
                  <span
                    style={{
                      marginLeft: '0.5em',
                    }
                    }
                  >
                    <span>(</span>
                    <span>
                      {apartmentData.local_apartment_number}
                    </span>
                    <span>)</span>
                  </span>
                </>
              )
            }
          </>
        ) : (apartmentData?.local_apartment_number) ? (
          <>
            {apartmentData.local_apartment_number}
          </>
        ) : (
          <>
            {apartmentData?.label || window.gettext('Unknown')}
          </>
        )
      }
    </>
  )
}

ApartmentName.propTypes = {
  'apartmentData': PropTypes.object,
  'showPrefix': PropTypes.bool,
}