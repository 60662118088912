import { Controller } from "react-hook-form";

import { FIELD_TYPES } from "../constants";
import {
  FactoryBooleanField,
  FactoryChoicesField,
  FactorySelectMultipleField,
  FactoryTextField,
} from "./factory_fields";
import {FactoryAutocompleteField} from "./factory_fields/FactoryAutocompleteField";

export function FormFieldItem({ formField, validationErrors, control }) {
  function hasError() {
    return getErrors(formField).length > 0;
  }

  function getErrors() {
    const errors = [];

    const fieldValidationError = validationErrors[formField.name];

    if (fieldValidationError !== undefined) {
      switch (fieldValidationError.type) {
        case "required":
          errors.push(
            window
              .gettext('"%(label)s" is a required field')
              .replace("%(label)s", formField.label)
          );
          break;
        default:
          errors.push(window.gettext("Unknown validation error"));
          break;
      }
    }

    return errors;
  }

  function getHelperText() {
    if (hasError(formField)) {
      return getErrors(formField).join(", ");
    }
    if (formField?.options?.helperText) {
      return formField.options.helperText;
    }
  }

  function renderField() {
    const props = {
      formField,
      hasError: hasError(formField),
      helperText: getHelperText(formField),
      validationErrors: validationErrors[formField.name] || null,
    };

    switch (formField.fieldType) {
      case FIELD_TYPES.BOOLEAN_FIELD:
        return FactoryBooleanField(props);

      case FIELD_TYPES.CHOICES_FIELD:
        return FactoryChoicesField(props);

      case FIELD_TYPES.SELECT_MULTIPLE_FIELD:
        return FactorySelectMultipleField(props);

      case FIELD_TYPES.CURRENCY_FIELD:
      case FIELD_TYPES.DECIMAL_FIELD:
      case FIELD_TYPES.INTEGER_FIELD:
      case FIELD_TYPES.TEXT_FIELD:
      case FIELD_TYPES.TEXT_AREA:
        return FactoryTextField(props);
      case FIELD_TYPES.AUTOCOMPLETE_FIELD:
        return FactoryAutocompleteField(props);
    }

    console.error("Unknown field type", formField);
    return () => <></>;
  }

  return (
    <Controller
      name={formField.name}
      defaultValue={formField.value}
      control={control}
      rules={formField.getRules()}
      render={renderField()}
    />
  );
}
