import { useContext } from "react";

import InstanceManipulator from "../../../../../../InstanceManipulator";
import { ReportInstallationProfileContext } from "../../../ReportInstallationProfileContext";
import JobReportMeasurementProfileForm from "./JobReportInstallationProfileForm";

export default function JobReportInstallationProfileManipulator({
  mode = InstanceManipulator.MODE_CREATE,
  postCreate,
  postUpdate,
  reportProfileId,
}) {
  const {
    selfCheckJobInstallationReportProfileService,
    selfCheckJobInstallationReportProfileServiceParams,
  } = useContext(ReportInstallationProfileContext);

  return (
    <>
      <InstanceManipulator
        form={
          <JobReportMeasurementProfileForm
            header={
              mode === InstanceManipulator.MODE_CREATE
                ? window.gettext("Create profile")
                : window.gettext("Edit profile")
            }
            submitButtonText={
              mode === InstanceManipulator.MODE_CREATE
                ? window.gettext("Create profile")
                : window.gettext("Save profile")
            }
            reportProfileId={reportProfileId}
          />
        }
        mode={mode}
        service={selfCheckJobInstallationReportProfileService}
        urlParams={selfCheckJobInstallationReportProfileServiceParams}
        postCreate={postCreate}
        postUpdate={postUpdate}
      />
    </>
  );
}
