import BaseService from "./BaseService";

class NodeService extends BaseService {}

export default new NodeService('/api/',
  [
    'projects',
    'systems',
    'nodes',
  ]
)