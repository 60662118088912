import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";

// Toolbar above table
export default function CustomJobGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />

      <GridToolbarFilterButton />

      <GridToolbarExport
        csvOptions={{
          delimiter: ";", // Cell delimiter
          fileName: "DRP-Export",
          allColumns: true,
          utf8WithBom: true,
        }}
      />
    </GridToolbarContainer>
  );
}
