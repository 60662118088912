import React, { cloneElement, useEffect, useState } from "react";

import InstanceManipulator from "../../../../../InstanceManipulator";
import SelfCheckTaskItemSpecService from "../../../../../services/SelfCheckTaskItemSpecService";
import SelfCheckTaskItemSpecForm from "../../../../../self_check/SelfCheckTaskItemSpecForm";
import SelfCheckTaskItemOptionListSpecForm from "../../../../../self_check/SelfCheckTaskItemOptionListSpecForm";
import { CenteredModal, useModalHandler } from "../../../../../layout/modal";

import { useSnackbar } from "notistack";

export function ModalCreateTaskItemSpec(props) {
  const { enqueueSnackbar } = useSnackbar();
  const modalHandler = useModalHandler();

  let [jobSpec, setJobSpec] = useState(null);
  let [taskSpec, setTaskSpec] = useState(null);
  let [project, setProject] = useState(null);
  let [taskItemSpecId, setTaskItemSpecId] = useState(undefined);
  let [submitButtonText, setSubmitButtonText] = useState(undefined);

  useEffect(() => {
    if (taskItemSpecId === undefined) {
      return;
    }

    // Creating new task item
    if (taskItemSpecId === null) {
      if (taskSpec.type === "item_option_list") {
        setSubmitButtonText(window.gettext("Add option"));
      } else {
        setSubmitButtonText(window.gettext("Create task item specification"));
      }
      // Editing existing task item
    } else {
      if (taskSpec.type === "item_option_list") {
        setSubmitButtonText(window.gettext("Change option"));
      } else {
        setSubmitButtonText(window.gettext("Save task item specification"));
      }
    }
  }, [taskItemSpecId]);

  useEffect(() => {
    if (props.taskItemSpecId === undefined) {
      return;
    }

    setTaskItemSpecId(props.taskItemSpecId);
  }, [props.taskItemSpecId]);

  useEffect(() => {
    setJobSpec(props.jobSpec);
  }, [props.jobSpec]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  useEffect(() => {
    setTaskSpec(props.taskSpec);
  }, [props.taskSpec]);

  const handleOnClick = (e) => {
    modalHandler.open();
  };

  let postUpdate = (data) => {
    console.log("post update", data);

    enqueueSnackbar(
      window.gettext('Task item specification "' + data.name + '" saved'),
      {
        variant: "success",
      }
    );

    modalHandler.close();

    if (props.hasOwnProperty("postUpdate")) {
      props.postUpdate();
    }
  };

  let postCreate = (data) => {
    console.log("post create", data);

    enqueueSnackbar(
      window.gettext('Task item specification "' + data.name + '" created'),
      {
        variant: "success",
      }
    );

    modalHandler.close();

    if (props.hasOwnProperty("postCreate")) {
      props.postCreate();
    }
  };

  return (
    <>
      {jobSpec !== null &&
        project !== null &&
        taskSpec !== null &&
        submitButtonText !== undefined &&
        taskItemSpecId !== undefined && (
          <>
            {cloneElement(props.children, { onClick: handleOnClick })}

            <CenteredModal modalHandler={modalHandler} width="100%">
              <InstanceManipulator
                service={SelfCheckTaskItemSpecService}
                urlParams={[
                  project.id,
                  jobSpec.id,
                  taskSpec.id,
                  taskItemSpecId,
                ]}
                postCreate={postCreate}
                postUpdate={postUpdate}
                mode={taskItemSpecId === null ? "create" : "edit"}
                form={
                  taskSpec.type === "item_option_list" ? (
                    <SelfCheckTaskItemOptionListSpecForm
                      taskItemSpecId={taskItemSpecId}
                      project={project}
                      jobSpec={jobSpec}
                      taskSpec={taskSpec}
                      submitButtonText={submitButtonText}
                    />
                  ) : (
                    <SelfCheckTaskItemSpecForm
                      taskItemSpecId={taskItemSpecId}
                      project={project}
                      jobSpec={jobSpec}
                      taskSpec={taskSpec}
                      submitButtonText={submitButtonText}
                    />
                  )
                }
              />
            </CenteredModal>
          </>
        )}
    </>
  );
}
