import Transformer from "./Transformer";

export default class TransformerEmptyStringToNull extends Transformer {
  valueTransformer = (value) => {
    if (value === "") {
      return null
    }

    return value
  }
}