export default function GetRowClassName(params) {
  let classNames = []

  // If the row has errors.
  if (params.row.hasOwnProperty('errors')) {
    // Get errors that match this field
    const rowsErrors = params.row.errors.filter(
      (error) => {
        return error.type === 'rows'
      }
    )

    if (rowsErrors.length > 0) {
      classNames.push('drp-row-error')
    }
  }

  // If there is a flag ad both the base class and the specific class for that flag
  if (params.row.node.flag) {
    classNames.push('drp-row-flag')
    classNames.push('drp-row-flag-' + String(params.row.node.flag))
  }

  return classNames.join(" ")
}