import { LayoutCardDesktop, LayoutCardMobile } from "./card";
import { useLayoutSizeTrackerContext } from "./context";

export function LayoutCard({
  children,
  doNotPrint,
  mb,
  menuItems,
  noPadding,
  title,
  titleExtra,
  sx,
  ...props
}) {
  const { isMobile } = useLayoutSizeTrackerContext();

  const params = {
    doNotPrint,
    mb,
    menuItems,
    noPadding,
    title,
    titleExtra,
    sx,
    ...props,
  };

  return (
    <>
      {!isMobile ? (
        <LayoutCardDesktop {...params}>{children}</LayoutCardDesktop>
      ) : (
        <LayoutCardMobile {...params}>{children}</LayoutCardMobile>
      )}
    </>
  );
}
