import BaseFieldValidator from "../BaseFieldValidator";
import {NODE_TYPE_PIPE, NODE_TYPE_RADIATOR} from "../../../data/DataGetter";

export default class RadiatorDimensionValidator extends BaseFieldValidator {
  fieldName = 'dimension'
  nodeTypes = [NODE_TYPE_RADIATOR]

  getFieldWarnings = (row) => {
    if (row.node.item === null){
      return [
        this.error(
          row,
          window.gettext('Could not find radiator on this node')
        )
      ]
    }

    // If length is null or undefined
    if (row.node.item.valve_dimension === null || row.node.item.valve_dimension === undefined) {
      return [
        this.warning(
          row,
          window.gettext('Dimension on radiator should be set')
        )
      ]
    }

    return undefined
  }
}