import { List, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import UserService from "../services/ProjectService";
import { LayoutCard } from "../../layout";

export default function ProjectList() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch the data
    UserService.list().then(setProjects);
  }, []);

  return (
    <LayoutCard>
      <List>
        {projects.map((project) => {
          return (
            <ListItemButton
              data-test-id={"project-list-item-" + String(project.id) + "-name"}
              key={project.id}
              component={RouterLink}
              to={"/projects/" + project.id + "/"}
            >
              {project.name}
            </ListItemButton>
          );
        })}
      </List>
    </LayoutCard>
  );
}
