import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import Energy from "../../../../values/Energy";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function EffectColumnDefinition() {
  return {
    field: 'effect',
    width: getColumnWidth('effect'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Effect'),
    valueFormatter: paramsValueFormatter(0),
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('all', 'calculation.effect')
        ]
      ).get(params.row)
    }),
    renderCell: (params) => {
      return <Energy value={params.value} unit="w"/>
    }
  }
}
