import { LinearProgress, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const AUTO_SAVE_DELAY = 500;

export function HierarchyTaskItemText({
  entity,
  hasError,
  isSaving,
  onSave,
  originalText,
}) {
  const [text, setText] = useState(undefined);
  const [autoSaveTimeout, setAutoSaveTimeout] = useState(null);

  const [focusEntity, setFocusEntity] = useState(undefined);

  useEffect(() => {
    if (
      text === undefined ||
      focusEntity === undefined ||
      !(entity.id === focusEntity.id && entity.type === focusEntity.type)
    ) {
      // Text or focus entity is not set yet, set them to original value.
      // Or focus entity is not the same as the entity we are rendering,
      // this means that the entity has changed and we should update the text.
      setFocusEntity(entity);
      setText(originalText);
    } else if (text !== originalText) {
      // Text has changed during save, save new value.
      save();
    }
  }, [originalText]);

  useEffect(() => {
    if (text === originalText) {
      // Text has not changed
      return;
    }

    // Save after delay
    const timeout = setTimeout(save, AUTO_SAVE_DELAY);
    // Store timeout so we can cancel it if text changes again.
    setAutoSaveTimeout(timeout);

    return () => {
      // Cancel timeout on cleanup
      clearTimeout(timeout);

      if(timeout === autoSaveTimeout) {
        // Timeout is the same as the one we stored, remove it.
        setAutoSaveTimeout(null);
      }
    };
  }, [text]);

  function handleTextOnChange(event) {
    setText(event.target.value);
  }

  function handleTextOnBlur() {
    if(isSaving || text === originalText) {
      // Text is being saved, don't save again.
      return;
    }

    save();
  }

  function save() {
    if (autoSaveTimeout !== null) {
      clearTimeout(autoSaveTimeout);
      setAutoSaveTimeout(null);
    }

    if (text === originalText) {
      // Text has not changed
      return;
    }

    onSave(text);
  }

  return (
    <Stack
      sx={{
        overflow: "hidden",
        maxWidth: 250,
        borderRadius: 1,
      }}
    >
      {/* Textfield */}
      <TextField
        size="small"
        multiline
        value={text || ""}
        onBlur={handleTextOnBlur}
        onChange={handleTextOnChange}
        sx={{
          flexGrow: 1,
        }}
        InputProps={{
          sx: {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      />

      {/* Saving indicator */}
      <LinearProgress
        variant={isSaving ? "indeterminate" : "determinate"}
        value={isSaving ? undefined : 100}
        color={hasError ? "error" : "primary"}
      />
    </Stack>
  );
}
