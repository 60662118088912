import { Alert, Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SelfCheckJobService from "../../../../services/SelfCheckJobService";

import { LayoutCard } from "../../../../../layout";
import { ProjectDetailSelfCheckTabJobListItem } from "./components/ProjectDetailSelfCheckTabJobListItem";

export function ProjectDetailSelfCheckTabJobs({ jobs, project }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectMode, setSelectMode] = useState(false);
  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [selectedJobSpecId, setSelectedJobSpecId] = useState(null);

  const [
    disableCopyCalculatedValuesButton,
    setDisableCopyCalculatedValuesButton,
  ] = useState(false);

  function handleCopyCalculatedValuesButtonClick(job) {
    setDisableCopyCalculatedValuesButton(true);
    SelfCheckJobService.copy_all_calculated_values_to_installed_values(
      project.id,
      job.system.id,
      job.id
    ).then((response) => {
      setDisableCopyCalculatedValuesButton(false);
      enqueueSnackbar(window.gettext("Successfully created installed values"), {
        variant: "success",
      });
    });
  }

  function handleSelectJob(job) {
    if (selectedJobIds.includes(job.id)) {
      setSelectedJobIds(selectedJobIds.filter((id) => id !== job.id));
      if (selectedJobIds.length === 1) {
        setSelectedJobSpecId(null);
      }
    } else {
      setSelectedJobIds([...selectedJobIds, job.id]);
      if (selectedJobIds.length === 0) {
        setSelectedJobSpecId(job.spec.id);
      }
    }
  }

  function handleSelectModeButtonClick() {
    setSelectMode(true);
  }

  function handleCancelSelectModeButtonClick() {
    setSelectedJobSpecId(null);
    setSelectedJobIds([]);
    setSelectMode(false);
  }

  function handleDetailedReportButtonClick() {
    navigate(
      "self-check/job-specs/" +
        selectedJobSpecId +
        "/report/detail/?" +
        selectedJobIds.map((id) => "job_id=" + id).join("&")
    );
  }

  return (
    <>
      {/* Create report */}
      <LayoutCard title={window.gettext("Create report")}>
        <Stack
          sx={{
            flexDirection: "row",
            gap: 1,
          }}
        >
          {!selectMode ? (
            <Button variant="contained" onClick={handleSelectModeButtonClick}>
              {window.gettext("Select jobs")}
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancelSelectModeButtonClick}
              >
                {window.gettext("Cancel")}
              </Button>

              <Button
                variant="contained"
                disabled={selectedJobIds?.length === 0}
                onClick={handleDetailedReportButtonClick}
              >
                {window.gettext("Detailed report")}
              </Button>
            </>
          )}
        </Stack>
      </LayoutCard>

      {jobs === null || jobs.length === 0 ? (
        // Has no jobs
        <LayoutCard title={window.gettext("Jobs")}>
          <Alert severity="info">
            {window.gettext("No jobs have currently been started")}
          </Alert>
        </LayoutCard>
      ) : (
        // Has jobs
        <>
          {jobs.map((job) => (
            <ProjectDetailSelfCheckTabJobListItem
              key={job.id}
              job={job}
              project={project}
              disableCopyCalculatedValuesButton={
                disableCopyCalculatedValuesButton
              }
              onCopyCalculatedValuesButtonClick={
                handleCopyCalculatedValuesButtonClick
              }
              selectMode={selectMode}
              selectable={
                selectedJobSpecId === null || job.spec.id === selectedJobSpecId
              }
              selected={selectedJobIds.includes(job.id)}
              onSelectJob={handleSelectJob}
            />
          ))}
        </>
      )}
    </>
  );
}
