import {createContext, useContext, useEffect, useState} from "react";
import IssueService from "../services/IssueService";
import {useParams} from "react-router-dom";
import ChoicesService from "../services/ChoicesService";
import IssueCommentService from "../services/IssueCommentService";


export const IssueCommentListContext = createContext({
  issueComments: undefined,
  refreshIssueComments: undefined,
})


export function useIssueComments() {
  return useContext(IssueCommentListContext);
}

export default function IssueCommentsProvider(props) {
  const [issueComments, setIssueComments] = useState(undefined)
  const issue_id = useParams().issue_id

  const refreshIssueComments = () => {
    IssueCommentService.list(issue_id).then(setIssueComments)
  }

  useEffect(() => {
    refreshIssueComments()
  }, [issue_id]);

  return (
    <IssueCommentListContext.Provider
      value={
        {
          'issueComments': issueComments,
          'refreshIssueComments': () => {
            refreshIssueComments()
          },
        }
      }
    >
      {(issueComments !== undefined && props.children) && (
        <>
          {props.children}
        </>
      )}
    </IssueCommentListContext.Provider>
  )
}
