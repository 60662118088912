import React, {useEffect, useState} from "react";
import {Form, FormFactory, FormFieldFactory} from "../form";
import ChoicesService from "../services/ChoicesService";
import IssueCategoryService from "../services/IssueCategoryService";
import UserService from "../services/UserService";
import ApartmentService from "../services/ApartmentService";
import ApartmentSearchService from "../services/ApartmentSearchService";
import ProjectService from "../services/ProjectService";
import SystemService from "../services/SystemService";

export default function IssueCommentForm(
  {
    instance,
    onSubmit,
  }
) {

  const form_factory = new FormFactory(instance);

  return (
    <Form
      formSections={[
        form_factory.section(
          "",
          [
            // Comment
            form_factory.textArea("comment", window.gettext("Comment"), true),
          ]
        ),
      ]}
      onSubmit={onSubmit}
    />
  )
}
