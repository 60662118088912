import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";

import LoadingIndicator from "../../../../../../../layout/LoadingIndicator";
import AddSimpleRadiatorModalButton from "../../../../../../../node/radiator/AddSimpleRadiatorModalButton";
import SelfCheckJobService from "../../../../../../../services/SelfCheckJobService";

import { ENTITY_TYPE_RADIATOR } from "../../../../entity";
import { useHierarchyContext } from "../../../context";

export function HierarchyRSAddRadiator({ parentNodeId }) {
  const { enqueueSnackbar } = useSnackbar();

  const { focusEntityData, jobData, system, setEntity, project } =
    useHierarchyContext();

  const entranceShortCode =
    focusEntityData.entity?.location?.entrance?.short_code;
  const apartmentNumber =
    focusEntityData.entity?.location?.apartment?.surveying_apartment_number;

  const [newRadiator, setNewRadiator] = useState(null);
  const [lockingCalculationsInProgress, setLockingCalculationsInProgress] =
    useState(false);

  function handleNavigateToNewRadiator() {
    setEntity({
      id: newRadiator.node.id,
      type: ENTITY_TYPE_RADIATOR,
    });
    /*
    old code with absolute url, not sure if we need this
    navigate(
      "/projects/" +
        String(project.id) +
        "/systems/" +
        String(system.id) +
        "/jobs/" +
        jobData.job.id +
        "/hierarchy?entity_type=radiator_node&entity_id=" +
        newRadiator.node.id +
        (visit_day ? "&visit_day=" + visit_day : "")
    );
    */
  }

  const handleLockCalculationsAndGoToNewRadiatorClick = () => {
    setLockingCalculationsInProgress(true);

    SelfCheckJobService.freeze(project.id, system.id, jobData.job.id).then(
      (response) => {
        enqueueSnackbar(
          window.gettext("Calculation values are frozen and attached to job"),
          {
            variant: "success",
          }
        );

        handleNavigateToNewRadiator();
      },
      (rejection) => {
        setLockingCalculationsInProgress(false);
        enqueueSnackbar(
          window.gettext("Error while trying to freeze calculation values"),
          {
            variant: "error",
          }
        );
      }
    );
  };

  return (
    <>
      {newRadiator && (
        <>
          {lockingCalculationsInProgress && (
            <LoadingIndicator
              loadingText={window.gettext("Locking calculations...")}
            />
          )}
          <Alert
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                onClick={handleLockCalculationsAndGoToNewRadiatorClick}
                disabled={lockingCalculationsInProgress}
              >
                {window.gettext("Lock calculations and go to new radiator")}
              </Button>
            }
          >
            <p>{window.gettext("Radiator added successfully.")}</p>
            <p>
              {window.gettext("Radiator name:")}{" "}
              <strong>{newRadiator.node.name}</strong>
            </p>
          </Alert>
        </>
      )}

      <AddSimpleRadiatorModalButton
        projectId={project.id}
        systemId={system.id}
        parentNodeId={parentNodeId}
        entranceShortCode={entranceShortCode}
        apartmentNumber={apartmentNumber}
        onCreated={(newRadiator) => {
          setNewRadiator(newRadiator);
        }}
      >
        <Button endIcon={<AddCircleIcon />} size="large" variant="contained">
          {window.gettext("Add radiator")}
        </Button>
      </AddSimpleRadiatorModalButton>
    </>
  );
}
