import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import DoneIcon from "@mui/icons-material/Done";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import {
  Box,
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import Helper from "../../../../../../utils/Helper";
import Nipple from "../../../../../../values/Nipple";
import EntityCommentsCount from "../../../entity/EntityCommentsCount";
import EntityStatusTaskItems from "../../../entity/EntityStatusTaskItems";
import EntityStatusTasks from "../../../entity/EntityStatusTasks";
import EntityTaskFlagsCount from "../../../entity/EntityTaskFlagsCount";
import EntityType from "../../../entity_type/EntityType";
import { HierarchyEntityComments, HierarchyEntityLink } from "./components";

import { useHierarchyContext } from "../../context";

export function HierarchyEntity({ entityData, entityStatus }) {
  const { hierarchyViewOptions } = useHierarchyContext();

  return (
    <Grid container direction="column">
      <Grid item>
        <>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
          >
            <Grid item xs={4}>
              <Grid container direction={"row"} spacing={1}>
                <Grid item>
                  <>
                    {entityStatus?.measurement_status?.has_values === true && (
                      <>
                        <Helper
                          fullOpacity
                          icon={<ThermostatIcon color={"success"} />}
                          title={window.gettext("Has measurement values")}
                        />
                      </>
                    )}
                  </>
                </Grid>
                <Grid item>
                  <>
                    {entityStatus.has_checkable() && (
                      <>
                        {entityStatus.is_done() ? (
                          <Helper
                            fullOpacity
                            icon={<DoneIcon color="primary" />}
                            title={window.gettext("Done")}
                          />
                        ) : (
                          <>
                            {entityStatus.is_some_task_items_done_but_not_tasks() && (
                              <>
                                <Helper
                                  title={window.gettext(
                                    "Task has been started but not finished"
                                  )}
                                  fullOpacity
                                  icon={
                                    <AssignmentLateIcon color={"warning"} />
                                  }
                                >
                                  {window.gettext(
                                    "One or more task items has been completed but task remains not done on this entity"
                                  )}
                                </Helper>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {/* Mark entity as done in the list of entities */}
                  </>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h5">
                <HierarchyEntityLink entity={entityData.entity} />
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <HierarchyEntityComments
                entityData={entityData}
                hideIfNoComments
              />
            </Grid>
            <Grid item xs={3}>
              <Box display={"flex"} justifyContent={"flex-end"}>
                {entityData?.entity?.frozen_calculation?.nipple?.id && (
                  <>
                    <Tooltip title={window.gettext("Nipple")}>
                      <Chip
                        label={
                          <Nipple
                            id={
                              entityData.entity.frozen_calculation.nipple?.id ||
                              null
                            }
                          />
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      </Grid>
      {hierarchyViewOptions.show_navigation_details === true && (
        <>
          <Grid item>
            <TableContainer>
              <Table>
                <TableBody>
                  {/* Type */}
                  <TableRow>
                    <TableCell>{window.gettext("Type")}</TableCell>
                    <TableCell>
                      <EntityType entityType={entityData.entity.type} />
                    </TableCell>
                  </TableRow>

                  {/* Tasks done */}
                  <TableRow>
                    <TableCell>{window.gettext("Tasks done")}</TableCell>
                    <TableCell>
                      <EntityStatusTasks entityStatus={entityStatus} />
                    </TableCell>
                  </TableRow>

                  {/* Task items done */}
                  <TableRow>
                    <TableCell>{window.gettext("Task items done")}</TableCell>
                    <TableCell>
                      <EntityStatusTaskItems entityStatus={entityStatus} />
                    </TableCell>
                  </TableRow>

                  {/* Flagged tasks */}
                  <TableRow>
                    <TableCell>{window.gettext("Flagged tasks")}</TableCell>
                    <TableCell>
                      <EntityTaskFlagsCount entityData={entityData} />
                    </TableCell>
                  </TableRow>

                  {/* Commented tasks */}
                  <TableRow>
                    <TableCell>{window.gettext("Comments")}</TableCell>
                    <TableCell>
                      <EntityCommentsCount entityData={entityData} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </Grid>
  );
}
