import store from "../../state";
import {useEffect, useState} from "react";
import {subscribe} from "valtio";
import PropTypes from "prop-types";
import getUserHasPermission from "./getUserHasPermission";
import {DjangoPermissions} from "../utils/DjangoPermissions";

export default function UserPermission(props) {
  /**
   * permissions and permissions are a list or a string representing one or more REQURIED permissions
   * if ANY of these are missing, we will not render children.
   *
   * anyPermission is a list of permissions where (if defined) we check if there is one or more that the
   * user has, if NONE of these permissions fail we will not render children.
   *
   * Examples:
   * permissions=['view_thing']
   * anyPermission=['delete_thing', 'change_thing']
   *
   * Result given user permissions
   * False    ['view_thing']
   * False    ['delete_thing']
   * True     ['view_thing', 'delete_thing']
   * True     ['view_thing', 'change_thing']
   * True     ['view_thing', 'change_thing', 'delete_thing']
   */

  let [user, setUser] = useState(store.user)
  let [hasPermission, setHasPermission] = useState(false)
  let {permissions, permission, anyPermission} = props

  if (!anyPermission) {
    anyPermission = []
  }

  let [requiredPermissions, setRequiredPermissions] = useState(null)

  // Combine permission and permissions to a requiredPermissions list
  useEffect(() => {
    let newRequiredPermissions = permissions || []

    if (permission) {
      newRequiredPermissions.push(permission)
    }

    setRequiredPermissions(newRequiredPermissions)
  }, [permissions, permission])

  useEffect(() => {
    // Subscribe to store changes
    let unsubscribe = subscribe(store, () => {
      setUser(store.user)
    })

    return () => {
      unsubscribe();
    }
  })

  useEffect(() => {
    if (!user || !requiredPermissions) {
      return
    }

    // Default to false
    setHasPermission(false)

    // List of permissions that the user misses.
    const failedRequiredPermissions = requiredPermissions.filter(
      (requiredPermission) => {
        // The user does NOT have this permission, we shall then return True.
        if (!getUserHasPermission(user, requiredPermission)) {
          return true
        }

        // This permission is on the user, we are good.
        return false
      }
    )

    // If we have any failed permissions, abort now.
    if (failedRequiredPermissions.length > 0) {
      return
    }

    // Find the permissions that the user has
    const anyPermissionSuccess = anyPermission.filter(
      (permission) => {
        return getUserHasPermission(user, permission)
      }
    )

    // If AnyPermission is defined AND Zero of the anyPermissions were on the user
    if (anyPermission.length > 0 && anyPermissionSuccess.length === 0) {
      return
    }

    // If staff requirement and the user does not have the required staff status
    if (props.is_staff !== undefined && user.is_staff !== props.is_staff) {
      return;
    }

    // If superuser requirement and the user does not have the required superuser status
    if (props.is_superuser !== undefined && user.is_superuser !== props.is_superuser) {
      return;
    }

    // If there are 0 failed permissions we are good and the user has permission.
    setHasPermission(true)
  }, [user, requiredPermissions, anyPermission])

  return (
    <>
      {hasPermission === true ? (
        <>
          {props.children}
        </>
      ) : (
        <>
          {/* User does NOT have permission, do not show children. */}
          {props.else || ""}
        </>
      )}
    </>
  )
}

UserPermission.propTypes = {
  is_staff: PropTypes.bool,
  is_superuser: PropTypes.bool,
  permissions: PropTypes.array,
  permission: PropTypes.string,
  anyPermission: PropTypes.array,
  else: PropTypes.any,
}

UserPermission.permissions = DjangoPermissions