import { CONTAINER_TYPES } from "../constants";
import { FormContainer } from "./form_components";
import { FormFieldFactory } from "./FormFieldFactory";

export class FormFactory extends FormFieldFactory {
  constructor(instance) {
    super(instance);
  }

  container = function (containerType, header, components, options) {
    return new FormContainer(containerType, header, components, options);
  };

  section = (header, components, options) => {
    return this.container(CONTAINER_TYPES.SECTION, header, components, options);
  };

  group = (header, components, options) => {
    return this.container(CONTAINER_TYPES.GROUP, header, components, options);
  };
}
