import React from "react";
import EditCustomFlowCellField from "./EditCustomFlow/EditCustomFlowField";

export default function EditCustomFlowCell({id, value, field, api}) {
  const handleValueChange = function (event) {
    const newValue = event.target.value;
    api.setEditCellValue({id, field, value: newValue})
  }

  return (
    <>
      <EditCustomFlowCellField
        value={value}
        autoFocus={true}
        onChange={handleValueChange}
      />
    </>
  )
}