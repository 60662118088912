export class FormComponent {
  constructor(type, baseOptions, options = {}) {
    this.type = type;
    this.options = {
      ...JSON.parse(JSON.stringify(baseOptions)),
      ...options,
    };
  }

  addProps(props) {
    this.options.props = {
      ...props,
      ...this.options.props,
      sx: { ...props?.sx, ...this.options.props?.sx },
    };
  }

  addPropsOverride(props) {
    this.options.props = {
      ...this.options.props,
      ...props,
      sx: { ...this.options.props?.sx, ...props?.sx },
    };
  }
}
