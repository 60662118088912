import React from "react";
import EditNameTextField from "./EditName/EditNameTextField";

export default function EditName(
  nodes,
) {
  return (params) => {
    const {id, value, field} = params

    const handleValueChange = function (event){
      const newValue = event.target.value;
      params.api.setEditCellValue({id, field, value: newValue})
    }

    return (
      <>
        <EditNameTextField
          id={id}
          nodes={nodes}
          value={value}
          autoFocus={true}
          onChange={handleValueChange}
        />
      </>
    )
  }
}