import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import store from "../../state";
import ConfirmableButton from "../layout/ConfirmableButton";
import ProjectMemberService from "../services/ProjectMemberService";
import User from "../user/User";
import UserPermission from "../user/UserPermission";
import Helper from "../utils/Helper";
import AddProjectMember from "./AddProjectMember";

import { LayoutCard } from "../../layout";

export default function ProjectMembers({ project }) {
  const { enqueueSnackbar } = useSnackbar();
  const [memberships, setMemberships] = useState(null);

  useEffect(fetchMembers, [project.id]);

  function fetchMembers() {
    ProjectMemberService.list(project.id).then(setMemberships);
  }

  function handleDeleteClick(membership) {
    ProjectMemberService.destroy(project.id, membership.id).then((success) => {
      enqueueSnackbar(
        window.gettext("User removed as member for this project"),
        {
          variant: "success",
        }
      );
      fetchMembers();
    });
  }

  return (
    <>
      {/* Project members */}
      <LayoutCard title={window.gettext("Project members")}>
        {memberships !== null && (
          <>
            {memberships.length === 0 ? (
              <Alert severity={"info"}>
                {window.gettext("No project members yet on this project")}
              </Alert>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{window.gettext("User")}</TableCell>

                      <TableCell>{window.gettext("Username")}</TableCell>

                      <TableCell>{window.gettext("First name")}</TableCell>

                      <TableCell>{window.gettext("Last name")}</TableCell>

                      <TableCell>{window.gettext("Email")}</TableCell>

                      <TableCell>&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {memberships.map((member) => {
                      return (
                        <TableRow key={"membership-" + member.id}>
                          <TableCell>
                            <User user={member.user_data} />
                          </TableCell>

                          <TableCell>{member.user_data.username}</TableCell>

                          <TableCell>{member.user_data.first_name}</TableCell>

                          <TableCell>{member.user_data.last_name}</TableCell>

                          <TableCell>{member.user_data.email}</TableCell>

                          <TableCell>
                            <UserPermission
                              anyPermission={[
                                UserPermission.permissions
                                  .project_membership_delete,
                              ]}
                            >
                              <ConfirmableButton
                                callbackAttributes={member}
                                closeOnConfirm
                                body={
                                  <>
                                    <p>
                                      {window.gettext(
                                        "Do you want remove this user from this project"
                                      )}
                                    </p>

                                    <p>
                                      {window.gettext("User: ")}{" "}
                                      <User user={member.user_data} />
                                    </p>

                                    {member.user_data.id === store.user.id && (
                                      <Alert severity={"warning"}>
                                        {window.gettext(
                                          "The user you are about to remove from the project is you, this means your privileges to this project and all its systems will be revoked."
                                        )}
                                      </Alert>
                                    )}
                                  </>
                                }
                              >
                                <IconButton
                                  onClick={handleDeleteClick}
                                  color="error"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </ConfirmableButton>
                            </UserPermission>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </LayoutCard>

      {/* Add project member */}
      <UserPermission
        permissions={[
          UserPermission.permissions.project_membership_add,
          UserPermission.permissions.auth_user_view,
        ]}
      >
        {memberships !== null && (
          <LayoutCard
            title={
              <>
                {window.gettext("Add project member")}

                <Helper title={window.gettext("Add member to this project")} ml>
                  {window.gettext(
                    "This will grant the user access to this project"
                  )}
                </Helper>
              </>
            }
          >
            <AddProjectMember
              project={project}
              memberships={memberships}
              onAdded={fetchMembers}
            />
          </LayoutCard>
        )}
      </UserPermission>
    </>
  );
}
