import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import AutocompleteInput from "../../edit_cell/AutocompleteInput";
import React from "react";
import getColumnWidth from "../GetColumnWidth";

export default function PipeTypeColumnDefinition(pipeTypes) {
  return {
    field: 'pipe_type',
    width: getColumnWidth('pipe_type'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Pipe type'),
    editable: true,
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('pipe', 'node.item.type.name'),
        ]
      ).get(params.row) || null
    }),
    renderEditCell: (params) => {
      return <AutocompleteInput
        {...params}
        options={pipeTypes}
        label={window.gettext('Pipe type')}
      />
    }
  }
}