/**
 * Not used as an actual column in ColumnDefinition, used as a special one
 * in the DataGrid component
 *
 * @return {{headerName, field: string}}
 * @constructor
 */
export default function GroupingColumnDefinition() {
  return {
    hide: false,
    field: '__tree_data_group__',
    headerName: window.gettext('Tree'),
  }
}