import EventExecutor from "./EventExecutor";
import EventResult from "./EventResult";
import IsColumnIndex from "./utils/IsColumnIndex";
import GetColumnAtIndex from "./utils/GetColumnAtIndex";

export default function RightArrowEvent() {
  return new EventExecutor(
    'right-arrow',
    (eventData) => {
      // Do not trigger for anything but view mode so that navigation
      // inside input elements work as expected, ie moving the cursor in side an <input>
      if(eventData.params.cellMode !== 'view'){
        return false
      }

      // only trigger for Arrow Right event
      return eventData.event.key === "ArrowRight"
    },
    (eventData) => {
      const isLastColumn = IsColumnIndex(eventData, 'last')

      // If this is the last column, wrap around to the first column
      if (isLastColumn === true) {
        const firstColumn = GetColumnAtIndex(eventData, 'first')
        eventData.apiRef.current.setCellFocus(
          eventData.params.id,
          firstColumn.field
        )
        eventData.apiRef.current.scrollToIndexes(
          {
            'colIndex': 0,
            'rowIndex': eventData.params.row.rowIndex,
          }
        )
        return EventResult(true)
      }

      return EventResult(true)
    }
  )
}