import { Chip, IconButton, Tooltip } from "@mui/material";

import { IconComment } from "../icons";

export function ChipEnableCommenting({ taskItemSpec, taskSpec }) {
  return (
    <>
      {(taskItemSpec || taskSpec).enable_commenting && (
        <Tooltip
          title={
            taskItemSpec
              ? window.gettext("Commenting is enabled for this task item")
              : window.gettext("Commenting is enabled for this task")
          }
        >
          <IconButton>
            <IconComment fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
