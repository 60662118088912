import valueRounder from "../../values/ValueRounder";

export default function paramsValueRounder(decimals){
  return function (params) {
    if(params.value === undefined){
      return undefined
    }

    return valueRounder(params.value, decimals)
  }
}