import {NODE_TYPE_RADIATOR} from "../DataGetter";
import TransformerEmptyStringToNull from "../TransformerEmptyStringToNull";

export default class RadiatorRadiatorTransformer extends TransformerEmptyStringToNull {
  key = 'radiator'
  nodeTypes = [NODE_TYPE_RADIATOR]
  newKeyName = 'type_id'

  valueTransformer = (value) => {
    value = (new TransformerEmptyStringToNull()).valueTransformer(value)

    // try to parse all values to int (that are no null values, since null values are allowed and means that the field is not set, or shall be set to null)
    if (value !== null) {
      value = parseInt(
        value, 10
      )
    }

    // In case the parsing did not work out, we return undefined to skip this field
    if (isNaN(value)) {
      console.info('Not a valid integer for radiator type, returning undefined to skip this field')
      return undefined
    }

    return value
  }
}