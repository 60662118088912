export default function GetColumnIndex(eventData) {
  const columns = eventData.apiRef.current.getVisibleColumns()

  for (let i = 0; i < columns.length; i += 1) {
    const column = columns[i]

    if (column.field === eventData.params.field) {
      return i
    }
  }

  return undefined
}
