import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import EntityFrozenCalculationValuesTable, { VALUE_EFFECT, VALUE_FLOW, VALUE_VOLUME } from "../../../../../entity/EntityFrozenCalculationValuesTable";

import { useHierarchyContext, useHierarchyRSContext } from "../../../../context";
import Pressure, { UNIT_AUTO } from "../../../../../../../../values/Pressure";


export function AccordionCalculatedValues() {
  const {
    project,
    system,
    jobData,
  } = useHierarchyContext();

  const {
    systemFrozenCalculationValues,
    systemUserDefinedCalculationValues,
    setSystemUserDefinedCalculationValues,
  } = useHierarchyRSContext();
  
  const [visible, setVisible] = useState(false);

  return (
    <Accordion
      expanded={visible}
      onChange={() => {
        setVisible(!visible);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h3">
          {window.gettext("Calculated values")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <EntityFrozenCalculationValuesTable
              project={project}
              system={system}
              job={jobData.job}
              frozenValues={systemFrozenCalculationValues}
              showUserDefinedValues={true}
              userDefinedValues={systemUserDefinedCalculationValues}
              onChangedUserDefinedValues={setSystemUserDefinedCalculationValues}
              columHeadings={[
                window.gettext("Value"),
                window.gettext("From calculation"),
                window.gettext("Defined by user"),
              ]}
              displayValues={[VALUE_FLOW, VALUE_EFFECT, VALUE_VOLUME]}
              extraData={[
                {
                  title: window.gettext("Highest resistance"),
                  component: (
                    <Pressure
                      value={systemFrozenCalculationValues.highest_resistance}
                      unit={UNIT_AUTO}
                    />
                  ),
                  userDefinedValue:
                    systemUserDefinedCalculationValues?.highest_resistance,
                  userDefinedValueComponent: (
                    <Pressure
                      value={
                        systemUserDefinedCalculationValues?.highest_resistance
                      }
                      unit={UNIT_AUTO}
                    />
                  ),
                  type: "pressure",
                  name: "highest_resistance",
                },
                {
                  title: window.gettext("Least favorable node"),
                  component:
                    systemFrozenCalculationValues.least_favorable_node_name,
                  userDefinedValue:
                    systemUserDefinedCalculationValues?.least_favorable_node_name,
                  name: "least_favorable_node_name",
                },
                {
                  title: window.gettext("Pump type"),
                  component: systemFrozenCalculationValues.pump_type,
                  userDefinedValue:
                    systemUserDefinedCalculationValues?.pump_type,
                  name: "pump_type",
                },
                {
                  title: window.gettext("Pump operation setting"),
                  component:
                    systemFrozenCalculationValues.pump_operation_setting,
                  userDefinedValue:
                    systemUserDefinedCalculationValues?.pump_operation_setting,
                  name: "pump_operation_setting",
                },
                {
                  title: window.gettext("Pump pressure"),
                  component: (
                    <Pressure
                      value={systemFrozenCalculationValues.pump_pressure}
                    />
                  ),
                  userDefinedValue:
                    systemUserDefinedCalculationValues?.pump_pressure,
                  userDefinedValueComponent: (
                    <Pressure
                      value={systemUserDefinedCalculationValues?.pump_pressure}
                    />
                  ),
                  name: "pump_pressure",
                },
              ]}
              userDefinedValuesEditable
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
