import getRowClassName from "../GetRowClassName";
import Flow from "../../../../values/Flow";
import ValueGetter from "../../ValueGetter";
import ValueGetterConf from "../../ValueGetterConf";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function FlowColumnDefinition() {
  return {
    field: 'flow',
    width: getColumnWidth('flow'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Flow'),
    renderCell: (params) => {
      return <Flow value={params.value}/>
    },
    valueFormatter: paramsValueFormatter(0),
    valueGetter: (params => {
      return ValueGetter(
        [
          ValueGetterConf('all', 'calculation.flow')
        ]
      ).get(params.row)
    })
  }
}
