import { FormFieldItem } from "../../components";

export function renderField(formField, control, validationErrors) {
  return (
    <FormFieldItem
      formField={formField}
      key={formField.name}
      control={control}
      validationErrors={validationErrors}
    />
  );
}
