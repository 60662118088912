import { Box, Modal, Paper, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { cloneElement, useEffect, useState } from "react";

import InstanceManipulator from "../../../../../InstanceManipulator";
import SelfCheckJobForm from "../../../../../self_check/SelfCheckJobForm";
import SelfCheckJobService from "../../../../../services/SelfCheckJobService";
import SystemSelector from "../../../../../system/SystemSelector";

export function ModalStartJob(props) {
  const { enqueueSnackbar } = useSnackbar();
  let [jobSpec, setJobSpec] = useState(null);
  let [project, setProject] = useState(null);
  let [system, setSystem] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setJobSpec(props.jobSpec);
  }, [props.jobSpec]);

  useEffect(() => {
    setProject(props.project);
  }, [props.project]);

  const handleOnClick = (e) => {
    setOpen(!open);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    backgroundColor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  let postCreate = (data) => {
    console.log("post create", data);

    enqueueSnackbar(window.gettext("Created new job"), {
      variant: "success",
    });

    setOpen(false);

    if (props.hasOwnProperty("postCreate")) {
      props.postCreate();
    }
  };

  useEffect(() => {
    if (!system) {
      return;
    }

    console.log("system changed", system);
  }, [system]);

  return (
    <>
      {jobSpec !== null && project !== null && (
        <>
          {cloneElement(props.children, { onClick: handleOnClick })}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box style={style}>
              <Paper sx={{ p: 1 }}>
                <Typography id="modal-modal-title" variant="h2" sx={{ mb: 2 }}>
                  {window.gettext("Create a job")}
                </Typography>

                <SystemSelector
                  project={project}
                  onChange={setSystem}
                  helperText={window.gettext(
                    "Choose what system to create this job for"
                  )}
                />

                <InstanceManipulator
                  service={SelfCheckJobService}
                  urlParams={[
                    project.id,
                    system === null ? null : system.id || null,
                    jobSpec.id,
                  ]}
                  postCreate={postCreate}
                  mode="create"
                  form={
                    <SelfCheckJobForm
                      project={project}
                      submitDisabled={!system}
                      jobSpec={jobSpec}
                      submitButtonText={window.gettext("Create job")}
                    />
                  }
                />
              </Paper>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
