import { useSearchParams } from "react-router-dom";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";

import BottomDrawerToolbar from "../../../../../layout/BottomDrawerToolbar";
import SelfCheckGridViewButtonLink from "../../utils/SelfCheckGridViewButtonLink";

import { useHierarchyContext } from "../context";
import { HierarchyRSBottomDrawer } from "./components/radiator_system";
import { VisitDayService } from "../../../../../services";
import { useEffect, useState } from "react";

const TOOLBAR_ITEM_PROPS = {
  sx: {
    maxWidth: 400,
  },
};

export function HierarchyBottomDrawer() {
  const {
    hierarchyViewOptions,
    updateHierarchyViewOptionsState,
    jobData,
    project,
    system,
    setDoneOnly,
    setFlaggedOnly,
    setPartlyDoneOnly,
    setVisitDay,
  } = useHierarchyContext();

  const [visitDays, setVisitDays] = useState(null);
  const [searchParams] = useSearchParams();

  const doneOnly = searchParams.get("done_only");
  const flaggedOnly = searchParams.get("flagged_only");
  const partlyDoneOnly = searchParams.get("partly_done_only");
  const visitDayId = searchParams.get("visit_day");

  function fetchVisitDays() {
    VisitDayService.list(jobData.job.id).then(setVisitDays);
  }

  const handleVisitDayItemClick = (visitDay) => {
    if (visitDay === null) {
      setVisitDay(null);
    } else if (visitDay) {
      setVisitDay(visitDay.id);
    }
  };

  useEffect(() => {
    fetchVisitDays();
  }, []);

  return (
    <BottomDrawerToolbar height={65}>
      <Stack
        sx={{
          flexWrap: "wrap",
          maxHeight: "70vh",
          width: "90%",
          py: 2,
          gap: 1,
        }}
      >
        {/* Hierarchy view checkbox options */}
        {hierarchyViewOptions?.getCheckboxOptions().map((option) => (
          <FormControl key={option.label} {...TOOLBAR_ITEM_PROPS}>
            <FormControlLabel
              label={option.label}
              control={
                <Checkbox
                  checked={option.value}
                  onChange={() => {
                    option.toggle();
                    updateHierarchyViewOptionsState();
                  }}
                />
              }
            />
          </FormControl>
        ))}

        {/* Done only */}
        {hierarchyViewOptions?.show_done_only_checkbox && (
          <FormControl {...TOOLBAR_ITEM_PROPS}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={doneOnly === "true"}
                  onClick={() => {
                    setDoneOnly(doneOnly === "true" ? null : true);
                  }}
                />
              }
              label={window.gettext("Only apartments marked as done")}
            />
          </FormControl>
        )}

        {/* Flagged only */}
        {hierarchyViewOptions?.show_flagged_only_checkbox && (
          <FormControl {...TOOLBAR_ITEM_PROPS}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={flaggedOnly === "true"}
                  onClick={() => {
                    setFlaggedOnly(flaggedOnly === "true" ? null : true);
                  }}
                />
              }
              label={window.gettext("Only apartments marked as flagged")}
            />
          </FormControl>
        )}

        {/* Partly done only */}
        {hierarchyViewOptions?.show_partly_done_only_checkbox && (
          <FormControl {...TOOLBAR_ITEM_PROPS}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={partlyDoneOnly === "true"}
                  onClick={() => {
                    setPartlyDoneOnly(partlyDoneOnly === "true" ? null : true);
                  }}
                />
              }
              label={window.gettext(
                "Only apartments that are partially finished"
              )}
            />
          </FormControl>
        )}

        {/* Visit day */}
        {hierarchyViewOptions?.show_visit_day_select && (
          <FormControl {...TOOLBAR_ITEM_PROPS}>
            <InputLabel size="small">{window.gettext("Visit day")}</InputLabel>

            <Select
              label={window.gettext("Visit day")}
              size="small"
              value={visitDayId || "all_days"}
              sx={{
                height: 49,
              }}
            >
              <MenuItem
                value="all_days"
                onClick={() => {
                  handleVisitDayItemClick(null);
                }}
              >
                {window.gettext("All days")}
              </MenuItem>

              {visitDays?.map((visitDay) => (
                <MenuItem
                  key={visitDay.id}
                  value={visitDay.id}
                  onClick={() => handleVisitDayItemClick(visitDay)}
                >
                  <Stack
                    sx={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography variant="body">{visitDay.date}</Typography>

                    <Chip
                      label={
                        visitDay.apartments_count +
                        " " +
                        window.gettext("Apartments")
                      }
                    />
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* System specific */}
        {system.type === "radiator" && <HierarchyRSBottomDrawer />}

        {/* Table view button */}
        <Stack {...TOOLBAR_ITEM_PROPS}>
          <SelfCheckGridViewButtonLink
            job={jobData.job}
            projectId={project.id}
            system={{ id: jobData.job.system_id }}
          />
        </Stack>
      </Stack>
    </BottomDrawerToolbar>
  );
}
