import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import SystemRoomService from "../../../services/SystemRoomService";

import {
  compareOptionValueIds,
  getHandleChangeCallback,
  getOptionLabelName,
} from "./helper";

export function SystemRoomSearchField({
  label,
  onChange,
  projectId,
  systemId,
}) {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (!projectId || !systemId) {
      return;
    }

    SystemRoomService.fetch(projectId, systemId).then(setOptions);
  }, [projectId, systemId]);

  const handleChange = useCallback(
    getHandleChangeCallback(setValue, onChange),
    []
  );

  return (
    <>
      <Autocomplete
        id="auto"
        fullWidth={true}
        options={options}
        value={value}
        getOptionLabel={getOptionLabelName}
        isOptionEqualToValue={compareOptionValueIds}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
}
