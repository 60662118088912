import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export function FactoryExpandableStackContainer({ header, options, children }) {
  const [isOpen, setIsOpen] = useState(options.expanded);

  function handleExpandMoreClick() {
    setIsOpen(true);
  }

  function handleExpandLessClick() {
    setIsOpen(false);
  }

  return (
    <Stack {...options.props}>
      {/* Header */}
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography {...options.headerProps}>{header}</Typography>
        {!isOpen ? (
          <ExpandMoreIcon
            onClick={handleExpandMoreClick}
            sx={{ cursor: "pointer" }}
          />
        ) : (
          <ExpandLessIcon
            onClick={handleExpandLessClick}
            sx={{ cursor: "pointer" }}
          />
        )}
      </Stack>

      {/* Components */}
      {isOpen && <Stack {...options.innerProps}>{children}</Stack>}
    </Stack>
  );
}
