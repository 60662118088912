import Customer from "../customer/Customer";
import LoadingIndicator from "../layout/LoadingIndicator";
import User from "../user/User";

import BaseInfoTabSectionItem from "../layout/information/base_info/BaseInfoTabSectionItem";
import BaseInfoTabSection from "../layout/information/base_info/BaseInfoTabSection";
import MultilineText from "../layout/MultilineText";

export default function ProjectDetailProjectBaseInfoTabContent({project}) {
  if (!project) {
    return <LoadingIndicator/>;
  }

  return (
    <>
      {!project ? (
        <LoadingIndicator/>
      ) : (
        <>
          <BaseInfoTabSection title={window.gettext("Project information")}>
            <BaseInfoTabSectionItem title={window.gettext("Sales person")}>
              <User user={project.sales_person}/>
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Chief designer")}>
              <User user={project.chief_designer} showPhoneNumber/>
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Budgeted time construction")}
            >
              {project.budgeted_time_construction}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Budgeted time installation")}
            >
              {project.budgeted_time_installation}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Desired indoor temperature")}
            >
              {project.desired_indoor_temperature}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Desired project start")}
            >
              {project.desired_project_start}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Radiators in systems")}
            >
              {project?.systems_radiator_count || undefined}
            </BaseInfoTabSectionItem> </BaseInfoTabSection>

          <BaseInfoTabSection title={window.gettext("Other information")}>
            <BaseInfoTabSectionItem title={window.gettext("Note")}>
              <MultilineText>
                {project.note}
              </MultilineText>
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          <BaseInfoTabSection title={window.gettext("Contact information")}>
            <BaseInfoTabSectionItem title={window.gettext("Customer")}>
              <Customer customer={project.customer}/>
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Property manager")}>
              {project.property_manager}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("City")}>
              {project.city}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("ZIP code")}>
              {project.zip_code}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Street address")}>
              {project.street_address}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Municipality")}>
              {project.municipality}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Contact person sales")}
            >
              {project.contact_person_sales_name}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Contact person sales email")}
            >
              {project.contact_person_sales_email}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Contact person phone")}
            >
              {project.contact_person_sales_phone}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Contact installation name")}
            >
              {project.contact_person_installation_name}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Contact person phone")}
            >
              {project.contact_person_installation_phone}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>

          <BaseInfoTabSection title={window.gettext("Building information")}>
            <BaseInfoTabSectionItem
              title={window.gettext("Property designation")}
            >
              {project.property_designation}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Entry code")}>
              {project.entry_code}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Key management")}>
              {project.key_management}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Parking information")}
            >
              {project.parking_info}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem title={window.gettext("Notification info")}>
              {project.notification_info}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Place of establishment")}
            >
              {project.place_of_establishment}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Building addresses")}
            >
              {project.building_addresses}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Substation addresses")}
            >
              {project.substation_addresses}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Number of apartments")}
            >
              {project.number_of_apartments}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Number of radiators")}
            >
              {project.number_of_radiators}
            </BaseInfoTabSectionItem>

            <BaseInfoTabSectionItem
              title={window.gettext("Number of substations")}
            >
              {project.number_of_substations}
            </BaseInfoTabSectionItem>
          </BaseInfoTabSection>
        </>
      )}
    </>
  );
}
