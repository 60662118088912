import {TextField, Tooltip} from "@mui/material";
import {useState} from "react";
import WarningIcon from '@mui/icons-material/Warning';

export default function EditCustomFlowCellField({
  autoFocus,
  onChange,
  value
}) {
  let [isValid, setIsValid] = useState(true)
  let [validationErrors, setValidationErrors] = useState([])

  const getValidationErrors = function (name) {
    // Can only have one decimal
    // Needs to be numeric
  }

  const handleOnChange = function (event) {
    const validationErrors = getValidationErrors(event.target.value)

    // Set valid or not
    setIsValid(!validationErrors)

    // set errors
    setValidationErrors(validationErrors)

    // Trigger onChange method
    onChange(event)
  }

  return (
    <>
      {!isValid && (
        <>
          <Tooltip
            open={true}
            title={validationErrors.join("\n")}
          >
            <WarningIcon
              color={"warning"}
            />
          </Tooltip>
        </>
      )}
      <>
        <TextField
          value={!value ? '' : value}
          autoFocus={autoFocus}
          onChange={handleOnChange}
        />
      </>
    </>
  )
}