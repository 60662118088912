import BaseService from "./BaseService";

class SelfCheckTaskSpecService extends BaseService {
  setOrder = (project_id, job_spec_id, task_spec_ids) => {
    return this.post(
      task_spec_ids,
      ...[
        project_id,
        job_spec_id,
        'ordering',
      ]
    )
  }
}

export default new SelfCheckTaskSpecService('/api/',
  [
    'projects',
    'self-check/spec/jobs',
    'tasks'
  ]
)