import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import JobContext from "../../../../../../../../../../../../../components/project/self_check/job/JobContext";
import ReportMeasurementProfileContext from "../../../../../../../../../../../../../components/project/self_check/job/ReportMeasurementProfileContext";
import JobMeasurementReport from "../../../../../../../../../../../../../components/project/self_check/job/report/measurement/JobMeasurementReport";
import JobMeasurementReportContext from "../../../../../../../../../../../../../components/project/self_check/job/report/measurement/JobMeasurementReportContext";
import ProjectService from "../../../../../../../../../../../../../components/services/ProjectService";

import { LayoutView } from "../../../../../../../../../../../../../layout";

export default function JobReportMeasurementView() {
  const { project_id } = useParams();

  const [project, setProject] = useState(null);

  useEffect(fetchProject, [project_id]);

  function fetchProject() {
    if (!project_id) {
      return;
    }

    ProjectService.fetch(project_id).then(setProject);
  }

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 3,
      }}
      header={window.gettext("Measurement report")}
    >
      <JobContext>
        <JobMeasurementReportContext>
          <ReportMeasurementProfileContext>
            <JobMeasurementReport />
          </ReportMeasurementProfileContext>
        </JobMeasurementReportContext>
      </JobContext>
    </LayoutView>
  );
}
