import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import store from "../../state";
import SystemService from "../services/SystemService";
import UserPermission from "../user/UserPermission";
import getUserHasPermission from "../user/getUserHasPermission";
import SystemCopyButton from "./SystemCopyButton";

export default function SystemList(props) {
  const [systems, setSystems] = useState([]);

  useEffect(() => {
    // Fetch the data
    SystemService.list(props.project_id).then(setSystems);
  }, [props.project_id]);

  return (
    <>
      <List data-test-id="systems-list">
        {systems.length === 0 ? (
          <Typography>{window.gettext("No systems on project")}</Typography>
        ) : (
          <>
            {systems.map((system) => {
              return (
                <ListItem
                  key={system.id}
                  secondaryAction={
                    <>
                      {/* System details button */}
                      <Tooltip title={window.gettext("See system details")}>
                        <IconButton
                          edge="end"
                          aria-label="details"
                          data-test-id={
                            "system-list-item-" + String(system.id) + "-details"
                          }
                          component={RouterLink}
                          disabled={!system}
                          to={
                            "/projects/" +
                            props.project_id +
                            "/systems/" +
                            String(system.id) +
                            "/"
                          }
                        >
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Edit system */}
                      {getUserHasPermission(store.user, "change_system") && (
                        <Tooltip title={window.gettext("Edit system")}>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            data-test-id={
                              "system-list-item-" + String(system.id) + "-edit"
                            }
                            component={RouterLink}
                            disabled={!system}
                            to={
                              "/projects/" +
                              props.project_id +
                              "/systems/" +
                              String(system.id) +
                              "/edit/"
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      )}

                      {/* Copy system */}
                      <SystemCopyButton system={system} />
                    </>
                  }
                >
                  {/* Main button */}
                  <UserPermission
                    permission={
                      UserPermission.permissions.system_view_calculation
                    }
                    else={<>{system.name}</>}
                  >
                    <ListItemButton
                      data-test-id={
                        "system-list-item-" + String(system.id) + "-name"
                      }
                      component={RouterLink}
                      to={
                        "/projects/" +
                        props.project_id +
                        "/systems/" +
                        String(system.id) +
                        "/build/table/" +
                        (system.type === "potable_water"
                          ? "potable-water/"
                          : "")
                      }
                    >
                      {system.name}
                    </ListItemButton>
                  </UserPermission>
                </ListItem>
              );
            })}
          </>
        )}
      </List>
    </>
  );
}
