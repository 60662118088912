import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import ApartmentName from "../../../../apartment/ApartmentName";

import { getEntityLabel } from "../utils";

export default function EntityText(props) {
  let {entity, fontSize} = props

  if (!entity.data) {
    console.error('No data on entity', entity)
  }

  return (
    <>
      <Typography
        component={"span"}
        fontSize={fontSize}
      >
        {
          (entity.type === 'address_entrance') ? (
            // For Entrances show address label
            <span>
              {entity.location?.address_label || entity.data.label}
            </span>
          ) : (entity.type === 'address_apartment') ? (
            // For Apartments
            <ApartmentName
              apartmentData={entity.data}
              showPrefix
              showLocalApartmentNumber={false}
            />
          ) : (
            // Default to show label
            <span>
              {getEntityLabel(undefined, entity)}
            </span>
          )
        }
      </Typography>
    </>
  )
}

EntityText.propTypes = {
  entity: PropTypes.object,
}