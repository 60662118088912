import React, {useEffect, useState} from "react";
import {subscribe} from "valtio";
import store from "./state";

const DEFAULT_LANGUAGE = 'sv'

export default function TranslationsWrapper(props) {
  let [displayChildren, setDisplayChildren] = useState(false)
  let [language, setLanguage] = useState(localStorage.getItem('language') ?? store.language ?? DEFAULT_LANGUAGE)

  const loadJavascriptCatalog = function (lang) {
    const javascript_i18n_catalog_script_element_id = "javascripti18ncatalog";

    let scriptTagDjangoJavascriptI18nCatalog = document.getElementById(javascript_i18n_catalog_script_element_id);

    if (scriptTagDjangoJavascriptI18nCatalog !== null) {
      //Remove old element
      scriptTagDjangoJavascriptI18nCatalog.remove();
    }

    // In case there is a django object, remove that.
    // This is because if there is a django object already the catalog will not register its methods.
    if (typeof window.django !== 'undefined') {
      delete window.django;
    }

    // Install the Django Javascript Catalog
    scriptTagDjangoJavascriptI18nCatalog = document.createElement('script');
    scriptTagDjangoJavascriptI18nCatalog.id = javascript_i18n_catalog_script_element_id;
    scriptTagDjangoJavascriptI18nCatalog.type = 'text/javascript';
    scriptTagDjangoJavascriptI18nCatalog.src = '/' + lang + '/resources/i18n.js';

    scriptTagDjangoJavascriptI18nCatalog.onload = function () {
      // Re-render view with the new lang codes
      setDisplayChildren(false);
      // Show the children
      setDisplayChildren(true);
    }

    // If we have an error loading the lang, re-try with default lang
    scriptTagDjangoJavascriptI18nCatalog.onerror = function (language) {
      return () => {
        // The code that did not work to load is the same as the default lang, will not retry.
        if (language === DEFAULT_LANGUAGE) {
          return;
        }

        // calling the change lang function without lang. Hoping default lang will work better.
        setLanguage(DEFAULT_LANGUAGE)
      }
    }(lang)

    // Add script tag to head
    document.head.appendChild(scriptTagDjangoJavascriptI18nCatalog);
  }

  useEffect(() => {
    loadJavascriptCatalog(language)
    // Set lang in local storage
    localStorage.setItem('language', language)
  }, [language])

  useEffect(() => {
    const unsubscribe = subscribe(store, () => {
      setLanguage(language)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <>
      {!displayChildren ? (
        <>
          Loading...
        </>
      ) : (
        <>
          {props.children}
        </>
      )}
    </>
  )
  /*
  let [displayChildren, setDisplayChildren] = useState(localStorage.getItem('language') ?? store.language)
  let [language, setLanguage] = useState(undefined)

  const loadJavascriptCatalog = function (language) {
    console.log('Load js catalgo...')
    const javascript_i18n_catalog_script_element_id = "javascripti18ncatalog";

    let scriptTagDjangoJavascriptI18nCatalog = document.getElementById(javascript_i18n_catalog_script_element_id);

    if (scriptTagDjangoJavascriptI18nCatalog !== null) {
      //Remove old element
      scriptTagDjangoJavascriptI18nCatalog.remove();
    }

    // In case there is a django object, remove that.
    // This is because if there is a django object already the catalog will not register its methods.
    if (typeof window.django !== 'undefined') {
      delete window.django;
    }

    // Install the Django Javascript Catalog
    scriptTagDjangoJavascriptI18nCatalog = document.createElement('script');
    scriptTagDjangoJavascriptI18nCatalog.id = javascript_i18n_catalog_script_element_id;
    scriptTagDjangoJavascriptI18nCatalog.type = 'text/javascript';
    scriptTagDjangoJavascriptI18nCatalog.src = '/' + language + '/resources/i18n.js';

    scriptTagDjangoJavascriptI18nCatalog.onload = function (setDisplayChildren, setStateLanguageCode, language) {
      return function () {
        // Re-render view with the new language codes
        setDisplayChildren(false);
        // Show the children
        setDisplayChildren(true);
        // Set new language code
        setStateLanguageCode(language)
      }
    }(setStateDisplayChildren, setStateLanguage, language)

    // If we have an error loading the language, re-try with default language
    scriptTagDjangoJavascriptI18nCatalog.onerror = function (changeLanguage, language) {
      return () => {
        // The code that did not work to load is the same as the default language, will not retry.
        if (language === DEFAULT_LANGUAGE) {
          return;
        }

        // calling the change language function without language. Hoping default language will work better.
        changeLanguage(DEFAULT_LANGUAGE)
      }
    }(changeLanguage, language)

    // Add script tag to head
    document.head.appendChild(scriptTagDjangoJavascriptI18nCatalog);

    // Set language in local storage
    localStorage.setItem('language', language)
  }

  const setStateDisplayChildren = function (displayChildren) {
    setDisplayChildren(displayChildren)
  }

  // gets called when the language changes
  const setStateLanguage = function (language) {
    localStorage.setItem('language', language)
    setLanguage(language)
  }

  const changeLanguage = function (language) {
    if (!language) {
      language = DEFAULT_LANGUAGE
    }

    loadJavascriptCatalog(language)
  }

  // On load, load some language
  // componentDidMount()
  // {
  //   let language = localStorage.getItem('language') ?? store.language
  //
  //   this.changeLanguage(language);
  //
  //   // Subscribe to changes in store
  //   this.unsubscribe = subscribe(store, () => {
  //     this.changeLanguage(store.language)
  //   })
  // }
  //
  // componentWillUnmount()
  // {
  //   this.unsubscribe()
  // }
  //

  useEffect(() => {
    console.log('display children change..', displayChildren)
  }, [displayChildren])

  useEffect(() => {
    console.log('language', language)
    if(language === undefined){
      setLanguage(DEFAULT_LANGUAGE)
    }else{
      changeLanguage(language)
    }
  }, [language])

  console.log('return!')

  return (
    <>
      {!displayChildren ? (
        <>
          <div style={{'textAlign': "left", 'color': '#CCC'}}>
            Loading languages....
          </div>
        </>
      ) : (
        <>
          load children...
        </>
      )
      }
    </>
  )
  */
}