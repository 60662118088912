export default class Transformer
{
  nodeTypes = undefined

  newKeyName = undefined

  match = (node) => {
    if(this.nodeTypes === undefined){
      return true
    }

    if(this.nodeTypes.indexOf(node.type) >= 0){
      return true
    }

    return false
  }

  keyTransformer = (key) => {
    if(this.newKeyName !== undefined){
      return this.newKeyName
    }

    return key
  }

  valueTransformer = function (value){
    return value
  }

  /**
   * Main function to get results from the transformation
   *
   * @param key
   * @param value
   * @return {{value, key: *}}
   */
  value = (key, value) => {
    return {
      'key': this.keyTransformer(key),
      'value': this.valueTransformer(value)
    }
  }
}