import React, {useEffect, useState} from "react";
import {useIssue} from "./IssueContext";
import {Autocomplete, Button, Stack, TextField} from "@mui/material";
import {useIssueWatchers} from "./IssueWatchersContext";
import {useSnackbar} from "notistack";
import UserService from "../services/UserService";
import IssueWatchersService from "../services/IssueWatchersService";

export default function IssueWatcherAdd({}) {
  const {issue} = useIssue()
  // description

  const [users, setUsers] = useState(undefined)
  const [possibleWatchers, setPossibleWatchers] = useState(undefined)
  const [selectedUser, setSelectedUser] = useState(undefined)
  const {watchers, refreshIssueWatchers} = useIssueWatchers()
  const {issue_id} = useIssue()
  const {enqueueSnackbar} = useSnackbar()


  useEffect(() => {
    UserService.list().then(setUsers)
  }, []);

  useEffect(() => {
    if (users === undefined || watchers === undefined) {
      return
    }

    setPossibleWatchers(
      users.filter(
        (user) => {
          return !watchers.map((watcher) => watcher.id).includes(user.id)
        }
      )
    )
  }, [watchers, users]);

  if (!issue) {
    return null
  }

  return (
    <>
      <Stack spacing={1}>
        <Stack>
          {possibleWatchers && (
            <>
              <Autocomplete
                renderInput={
                  (params) => (
                    <TextField
                      {...params}
                      label={window.gettext("User")}
                    />
                  )
                }
                onChange={
                  (e, value) => {
                    setSelectedUser(value)
                  }
                }
                options={possibleWatchers}
              />
            </>
          )}
        </Stack>
        <Stack>
          <>
            <Button
              variant="contained"
              color={'primary'}
              disabled={!selectedUser}
              onClick={() => {
                IssueWatchersService.create(
                  selectedUser,
                  issue_id,
                ).then(() => {
                  refreshIssueWatchers()
                  setSelectedUser(null)
                  enqueueSnackbar(window.gettext("Watcher added"), {variant: "success"})
                })
              }}
            >
              {window.gettext("Add watcher")}
            </Button>
          </>
        </Stack>
      </Stack>
    </>
  )
}