import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";

import ReportIcon from '@mui/icons-material/Report';
import RefreshIcon from '@mui/icons-material/Refresh';
import {IconButton, Popover, Tooltip} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

export default function FetchCalculationsButton(props) {
  let [validationErrors, setValidationErrors] = useState(undefined)
  let [disableButton, setDisableButton] = useState(true)
  let [errorMessagesPopoverAnchorElement, setErrorMessagesPopoverAnchorElement] = useState(null)

  useEffect(() => {
    if (
      props.validationResults === undefined ||
      props.validationResults.errors === undefined
    ) {
      setValidationErrors(undefined)
    } else {
      setValidationErrors(props.validationResults.errors)
    }
  }, [props.validationResults])

  useEffect(() => {
    let shouldDisableButton;

    if (validationErrors === undefined) {
      shouldDisableButton = true
    } else {
      shouldDisableButton = validationErrors.length > 0
    }

    setDisableButton(shouldDisableButton)
  }, [validationErrors])

  useEffect(() => {
    if(props.disabled === true){
      setDisableButton(true)
      return
    }

    setDisableButton(false)
  }, [props.disabled])

  return (
    <>
      {/* Fetch calculation button */}
      {
        (validationErrors === undefined || validationErrors.length > 0) ? (
          <>
            <Tooltip
              title={window.gettext('Validation errors inhibit the fetching of calculation results, click to see more details')}>
              <IconButton
                onClick={(event) => {
                  setErrorMessagesPopoverAnchorElement(event.currentTarget)
                }}>
                <ReportIcon/>
              </IconButton>
            </Tooltip>

            <Popover
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              open={errorMessagesPopoverAnchorElement !== null}
              anchorEl={errorMessagesPopoverAnchorElement}
              onClose={() => {
                setErrorMessagesPopoverAnchorElement(null)
              }}
            >
              {
                (validationErrors === undefined) ? (
                  <span>
                    {window.gettext('Could not find validation results')}
                  </span>
                ) : (
                  <List>
                    {validationErrors.map((error, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemText>
                            {error.message}
                          </ListItemText>
                        </ListItem>
                      )
                    })}
                  </List>
                )}
            </Popover>
          </>
        ) : (
          <>
          </>
        )
      }
      <Button
        color="primary"
        variant="contained"
        onClick={props.refreshButtonOnClickCallback}
        data-test-id="fetch-calculation-button"
        disabled={disableButton}
      >
        {window.gettext('Fetch calculation')}
      </Button>
    </>
  )
}