import PropTypes from "prop-types";
import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import {useEffect, useState} from "react";
import KVValue from "../../../../values/KVValue";
import Nipple from "../../../../values/Nipple";
import Flow from "../../../../values/Flow";
import Speed from "../../../../values/Speed";
import Pressure, {UNIT_AUTO} from "../../../../values/Pressure";
import Energy from "../../../../values/Energy";
import Volume from "../../../../values/Volume";
import Typography from "@mui/material/Typography";
import EditIcon from '@mui/icons-material/Edit';
import EntityEditableUserDefinedCalculationValue from "./EntityEditableUserDefinedCalculationValue";
import SelfCheckJobService from "../../../../services/SelfCheckJobService";

class TableData {
  name = null
  title = null
  component = null
  userDefinedValue = null

  constructor(name, title, component, userDefinedValue, userDefinedValueComponent) {
    this.name = name
    this.title = title
    this.component = component
    this.userDefinedValue = userDefinedValue
    this.userDefinedValueComponent = userDefinedValueComponent
  }
}

export const VALUE_KV = 'kv'
export const VALUE_NIPPLE = 'nipple'
export const VALUE_FLOW = 'flow'
export const VALUE_AVAILABLE_PRESSURE = 'available_pressure'
export const VALUE_ACCUMULATED_PRESSURE_DROP = 'accumulated_pressure_drop'
export const VALUE_EFFECT = 'effect'
export const VALUE_PRESSURE_DROP = 'pressure_drop'
export const VALUE_PRESSURE_DROP_PER_METER = 'pressure_drop_per_meter'
export const VALUE_SPEED = 'speed'
export const VALUE_VOLUME = 'volume'

export default function EntityFrozenCalculationValuesTable(props) {
  const {project, system, job} = props
  let [frozenValues, setFrozenValues] = useState(null)
  let [userDefinedValues, setUserDefinedValues] = useState(null)
  let [showUserDefinedValues, setShowUserDefinedValues] = useState(false)
  let [columnHeadings, setColumnHeadings] = useState([])
  let [displayValues, setDisplayValues] = useState([VALUE_KV, VALUE_NIPPLE, VALUE_FLOW])
  let [tableData, setTableData] = useState([])
  let [combinedTableData, setCombinedTableData] = useState([])
  let [extraData, setExtraData] = useState([])
  let [userDefinedValuesEditable, setUserDefinedValuesEditable] = useState(false)

  useEffect(() => {
    if (!props.showUserDefinedValues) {
      return
    }

    setShowUserDefinedValues(props.showUserDefinedValues)
  }, [props.showUserDefinedValues])

  useEffect(() => {
    if (!props.userDefinedValuesEditable) {
      return
    }

    setUserDefinedValuesEditable(props.userDefinedValuesEditable)
  }, [props.userDefinedValuesEditable])


  useEffect(() => {
    if (!props.userDefinedValues) {
      return
    }

    setUserDefinedValues(props.userDefinedValues)
  }, [props.userDefinedValues])

  useEffect(() => {
    if (!frozenValues) {
      return
    }

    let newTableData = []
    // Speed
    if (displayValues.includes(VALUE_SPEED)) {
      newTableData.push(
        new TableData(
          'speed',
          window.gettext('Speed'),
          <Speed value={frozenValues.speed}/>
        )
      )
    }

    // Pressure drop per meter
    if (displayValues.includes(VALUE_PRESSURE_DROP_PER_METER)) {
      newTableData.push(
        new TableData(
          'pressure_drop_per_meter',
          window.gettext('Pressure drop per meter'),
          <Pressure value={frozenValues.pressure_drop_per_meter} unit="auto"/>
        )
      )
    }

    // Pressure drop
    if (displayValues.includes(VALUE_PRESSURE_DROP)) {
      newTableData.push(
        new TableData(
          'pressure_drop',
          window.gettext('Pressure drop'),
          <Pressure value={frozenValues.pressure_drop} unit="auto"/>
        )
      )
    }

    // Effect
    if (displayValues.includes(VALUE_EFFECT)) {
      newTableData.push(
        new TableData(
          'effect',
          window.gettext('Effect'),
          <Energy value={frozenValues.effect}/>,
          userDefinedValues?.effect,
          <Energy value={userDefinedValues?.effect}/>,
        )
      )
    }

    // Accumulated pressure
    if (displayValues.includes(VALUE_ACCUMULATED_PRESSURE_DROP)) {
      newTableData.push(
        new TableData(
          'accumulated_pressure_drop',
          window.gettext('Accumulated pressure drop'),
          <Pressure value={frozenValues.accumulated_pressure_drop} unit="auto"/>
        )
      )
    }

    // Available pressure
    if (displayValues.includes(VALUE_AVAILABLE_PRESSURE)) {
      newTableData.push(
        new TableData(
          'available_pressure',
          window.gettext('Available pressure'),
          <Pressure value={frozenValues.available_pressure} unit="auto"/>
        )
      )
    }

    // Flow
    if (displayValues.includes(VALUE_FLOW)) {
      newTableData.push(
        new TableData(
          'flow',
          window.gettext('Flow'),
          <Flow value={frozenValues.flow}/>,
          userDefinedValues?.flow,
          <Flow value={userDefinedValues?.flow}/>
        )
      )
    }

    // KV
    if (displayValues.includes(VALUE_KV)) {
      newTableData.push(
        new TableData(
          'kv',
          window.gettext('KV'),
          <KVValue value={frozenValues.kv}/>
        )
      )
    }

    // Nipple
    if (displayValues.includes(VALUE_NIPPLE)) {
      newTableData.push(
        new TableData(
          'nipple',
          window.gettext('Nipple'),
          <Nipple value={frozenValues.nipple}/>
        )
      )
    }

    if (displayValues.includes(VALUE_VOLUME)) {
      newTableData.push(
        new TableData(
          'volume',
          window.gettext('Volume'),
          <Volume value={frozenValues.volume}/>,
          userDefinedValues?.volume,
          <Volume value={userDefinedValues?.volume}/>,
        )
      )
    }

    setTableData(newTableData)
  }, [displayValues, frozenValues, userDefinedValues])

  useEffect(() => {
    setFrozenValues(props.frozenValues)
  }, [props.frozenValues])

  useEffect(() => {
    if (!props.displayValues) {
      return
    }

    setDisplayValues(props.displayValues)
  }, [props.displayValues])

  useEffect(() => {
    if (!props.extraData) {
      setExtraData([])
      return
    }

    setExtraData(props.extraData)
  }, [props.extraData])


  useEffect(() => {
    setCombinedTableData(
      extraData.concat(
        tableData
      )
    )
  }, [tableData, extraData])

  useEffect(() => {
    if (!props.columHeadings) {
      return
    }

    setColumnHeadings(props.columHeadings)
  }, [props.columHeadings])

  const handleOnUserDefinedValuesChange = (changedValuePayload) => {
    let patch_payload = {}
    patch_payload[changedValuePayload.originalData.name] = changedValuePayload.newValue

    SelfCheckJobService.userDefinedValues(
      project.id,
      system.id,
      job.id,
      patch_payload,
    ).then(
      (response) => {
        props.onChangedUserDefinedValues(response)
      }
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          {columnHeadings.length > 0 && (
            <>
              <TableHead>
                <TableRow>
                  {columnHeadings.map((val, index) => {
                    return (
                      <TableCell
                        key={'table_header_cell-' + String(index)}
                      >
                        <Typography sx={{fontWeight: 'bold'}}>
                          {val}
                        </Typography>
                      </TableCell>
                    )
                  })}
                </TableRow>
              </TableHead>
            </>
          )}
          <TableBody>
            {combinedTableData.map((data, index) => {
              return (
                <TableRow
                  key={"row_" + String(index)}
                >
                  <TableCell>
                    {data.title}
                  </TableCell>
                  <TableCell>
                    {data.component}
                  </TableCell>
                  {showUserDefinedValues && (
                    <>
                      <TableCell>
                        <EntityEditableUserDefinedCalculationValue
                          data={data}
                          editable={userDefinedValuesEditable}
                          onChange={handleOnUserDefinedValuesChange}
                        />
                      </TableCell>
                    </>
                  )}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

EntityFrozenCalculationValuesTable.propTypes = {
  'project': PropTypes.object,
  'system': PropTypes.object,
  'job': PropTypes.object,
  'frozenValues': PropTypes.object,
  'displayValues': PropTypes.array,
  'extraData': PropTypes.array,
  'userDefinedValues': PropTypes.object,
  'showUserDefinedValues': PropTypes.bool,
  'columHeadings': PropTypes.array,
  'userDefinedValuesEditable': PropTypes.bool,
  'onChangedUserDefinedValues': PropTypes.func,
}