import Paper from "@mui/material/Paper";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Helper from "../utils/Helper";
import {Grid} from "@mui/material";

export default function Sheet({mt, title, titleVariant, children, doNotPrint, titleHelperText}) {
  let [marginTop, setMarginTop] = useState(0)

  useEffect(() => {
    if (mt === true) {
      setMarginTop(2)
      return
    }

    if (mt !== undefined) {
      setMarginTop(mt)
    }
  }, [mt])

  return (
    <Paper
      sx={
        {
          p: 1,
          mt: marginTop,
          displayPrint: doNotPrint ? 'none' : ''
        }
      }
    >
      <Grid
        container
        direction={"column"}
        spacing={1}
      >
        {
          (title) && (
            <>

              <Grid item>
                <Typography variant={titleVariant || "h3"}>
                  {title}
                  {
                    titleHelperText !== undefined && (
                      <Helper
                        title={titleHelperText}
                        ml
                      />
                    )
                  }
                </Typography>
              </Grid>
            </>
          )
        }
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

Sheet.propTypes = {
  'mt': PropTypes.bool,
}