import Box from "@mui/material/Box";

export function addHideForPrintStyle(styles = {}) {
  return {
    ...styles,
    displayPrint: 'none'
  }
}

export default function HideForPrint({children}) {
  return (
    <Box
      sx={
        addHideForPrintStyle()
      }
    >
      {children}
    </Box>
  )
}