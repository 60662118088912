class ValueGetterClass {
  valueGetterConfs = [];

  constructor(valueGetterConfs) {
    this.valueGetterConfs = valueGetterConfs;
  }

  getValueFromNode = function (obj, keyPath, notFoundReturnValue = undefined) {
    let value = obj;

    const keys = keyPath.split('.')

    let currentPath = "";
    for (let i = 0; i < keys.length; i += 1) {
      let key = keys[i]

      currentPath += "." + key

      if (typeof (value) !== 'object' || value === null || !value.hasOwnProperty(key)) {
        /*
        console.log('Could not traverse path to get value',
          {
            'value': value,
            'key': key,
            'errorReturnValue': notFoundReturnValue,
            'currentPath': currentPath,
            'obj': obj,
          }
        )
         */
        return notFoundReturnValue
      }

      value = value[key]
    }

    return value;
  }

  get = function (row) {
    for (let i = 0; i < this.valueGetterConfs.length; i += 1) {
      let valueGetterConf = this.valueGetterConfs[i];
      if (valueGetterConf.itemType === row.node.type || valueGetterConf.itemType === 'all') {
        return this.getValueFromNode(
          row,
          valueGetterConf.keyPath,
          valueGetterConf.notFoundReturnValue,
        )
      }
    }
    return null
  }
}

export default function ValueGetter(valueGetterConfs) {
  return new ValueGetterClass(valueGetterConfs)
}