import React, {useState} from "react";
import {useIssue} from "./IssueContext";
import {useIssueFiles} from "./IssueFileContext";
import IssueFilesService from "../services/IssueFilesService";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Button, Stack, styled, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function IssueFileAdd({}) {
  const {issue} = useIssue()
  // description
  const [description, setDescription] = useState("")
  const [file, setFile] = useState(undefined)

  // refesh Issue FIles
  const {refreshIssueFiles} = useIssueFiles()

  if (!issue) {
    return null
  }

  return (
    <>
      <Stack spacing={1}>
        <Stack>
          <TextField
            label={window.gettext('Description')}
            multiline
            value={description}
            onChange={
              (e) => {
                setDescription(e.target.value)
              }
            }
          />
        </Stack>
        <Stack>
          <>
            {file ? (
              <>
                <Stack direction={'row'}>
                  <Stack>
                    {file.name}
                  </Stack>
                  <Stack sx={{ml: 2}}>
                    <IconButton
                      onClick={() => {
                        setFile(undefined)
                      }}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Stack>
                </Stack>
              </>
            ) : (
              <>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon/>}
                >
              <span>
                {window.gettext("Choose file")}
              </span>
                  <VisuallyHiddenInput type="file" onChange={(e) => {
                    setFile(e.target.files[0])
                  }}/>
                </Button>
              </>
            )}
          </>
        </Stack>
        <Stack>
          <>
            {file && (
              <>
                <Button
                  variant="contained"
                  color={'primary'}
                  onClick={() => {
                    let data = new FormData()
                    data.append('description', description)
                    data.append('file', file)

                    IssueFilesService.create(
                      data,
                      issue.id,
                    ).then(() => {
                      refreshIssueFiles()
                      setFile(undefined)
                      setDescription("")
                    })
                  }}
                >
                  {window.gettext("Upload")}
                </Button>
              </>
            )}
          </>
        </Stack>
      </Stack>
    </>
  )
}