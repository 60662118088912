import { Save } from "@mui/icons-material";
import {
  Alert,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";

import NippleSelector from "../../../../../../../self_check/NippleSelector";
import InstalledValueService from "../../../../../../../services/InstalledValueService";
import UserPermission from "../../../../../../../user/UserPermission";
import DateTime from "../../../../../../../values/DateTime";
import KVValue from "../../../../../../../values/KVValue";
import Nipple from "../../../../../../../values/Nipple";

import { useHierarchyContext, useHierarchyRSContext } from "../../../context";

export function HierarchyRSNippleKV({ entity }) {
  const { enqueueSnackbar } = useSnackbar();

  const { jobData, project, refreshJobData, system } = useHierarchyContext();

  const {
    installedValues,
    measurementCalculation,
    measurementLastInstance,
    latestOpportunity,
  } = useHierarchyRSContext();

  const [tableData, setTableData] = useState([]);

  const [currentInstalledKv, setCurrentKv] = useState(undefined);
  const [currentNippleId, setCurrentNippleId] = useState(undefined);
  const [previousInstalledKv, setPreviousInstalledKv] = useState(undefined);
  const [previousNippleId, setPreviousNippleId] = useState(undefined);

  useEffect(() => {
    setTableData([
      {
        name: window.gettext("Calculated"),
        dateTime: entity?.frozen_calculation?.created,
        kv: entity?.frozen_calculation?.kv,
        nippleId: entity?.frozen_calculation?.nipple?.id,
      },
      {
        name: window.gettext("Recommended"),
        dateTime: undefined,
        kv: measurementCalculation?.suggested_kv,
        nippleId: measurementCalculation?.suggested_nipple?.id,
      },
      {
        name: window.gettext("Installed"),
        dateTime: installedValues[0]?.created,
        kv: installedValues[0]?.kv,
        nippleId: installedValues[0]?.nipple,
      },
    ]);
  }, [entity, installedValues, measurementCalculation]);

  useEffect(() => {}, [installedValues]);

  useEffect(() => {
    if (installedValues && installedValues.length > 0) {
      // There is a installed value present, set the current values to the installed values
      const nipple = installedValues[0].nipple;
      const kv = installedValues[0].kv;

      setCurrentNippleId(nipple);
      setCurrentKv(kv);
      setPreviousNippleId(nipple);
      setPreviousInstalledKv(kv);

      return;
    }

    // There is no installed value present, set the current values to undefined
    setCurrentNippleId(undefined);
    setCurrentKv(undefined);
    setPreviousNippleId(undefined);
    setPreviousInstalledKv(undefined);
  }, [installedValues]);

  function handleSubmit(event) {
    event.preventDefault();

    const data = {};

    // If there is a current kv and the current kv is not the same as the previous kv
    if (
      currentInstalledKv !== undefined &&
      currentInstalledKv !== previousInstalledKv
    ) {
      data.kv = currentInstalledKv;
    }

    // If there is a current nipple and the current nipple is not the same as the previous nipple
    if (currentNippleId !== undefined && currentNippleId !== previousNippleId) {
      data.nipple = currentNippleId;
    }

    if (Object.keys(data).length === 0) {
      enqueueSnackbar("No changes to save", {
        variant: "warning",
      });

      return;
    }

    InstalledValueService.post(
      data,
      project.id,
      system.id,
      jobData.job.id,
      entity.id
    ).then((response) => {
      enqueueSnackbar(window.gettext("Installed value successfully saved"), {
        variant: "success",
      });
      console.log("InstalledValueService response", response);

      refreshJobData();
    });
  }

  function handleOnNippleChange(event, newNippleId) {
    setCurrentNippleId(newNippleId);
  }

  function handleOnKVChange(event) {
    setCurrentKv(event.target.value);
  }

  return (
    <>
      {!measurementCalculation && measurementLastInstance ? (
        /* No measurement calculation but there is a last measurement instance */
        /* This means there is a MeasurementInstance defined but the calculation is not valid.*/
        <Alert severity={"info"}>
          {window.gettext("Currently there are not valid calculation")}
        </Alert>
      ) : !measurementCalculation && latestOpportunity ? (
        /* No measurement calculation and no last measurement instance but there is a opportunity  */
        /* This means there is no values saved, user needs to supply these. */
        <Alert severity={"warning"}>
          {window.gettext("No measurement values present")}
        </Alert>
      ) : null}

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>

              <TableCell>{window.gettext("Date and time")}</TableCell>

              <TableCell>{window.gettext("KV")}</TableCell>

              <TableCell>{window.gettext("Nipple")}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.name}>
                <TableCell>{row.name}</TableCell>

                <TableCell>
                  {row.dateTime === undefined || row.dateTime === null ? (
                    <span></span>
                  ) : (
                    <DateTime value={row.dateTime} />
                  )}
                </TableCell>

                <TableCell>
                  {row.kv === undefined || row.kv === null ? (
                    <span>-</span>
                  ) : (
                    <KVValue value={row.kv} />
                  )}
                </TableCell>

                <TableCell>
                  {row.nippleId === undefined || row.nippleId === null ? (
                    <span>-</span>
                  ) : (
                    <Nipple id={row.nippleId} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UserPermission
        permission={UserPermission.permissions.installed_value_add}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            sx={{
              mt: 1,
              gap: 2,
            }}
          >
            <Typography variant={"h5"}>
              {window.gettext("Set installed values")}
            </Typography>

            <Stack
              sx={{
                flexDirection: "row",
                gap: 2,
                justifyContent: "space-between",
              }}
            >
              <TextField
                label={window.gettext("KV")}
                inputProps={{ inputMode: "decimal" }}
                onChange={handleOnKVChange}
                type={"number"}
                value={currentInstalledKv || ""}
              />

              <NippleSelector
                onChange={handleOnNippleChange}
                value={currentNippleId || ""}
              />
            </Stack>

            <Button
              endIcon={<Save />}
              size={"large"}
              type="submit"
              variant={"contained"}
            >
              {window.gettext("Save values")}
            </Button>
          </Stack>
        </form>
      </UserPermission>
    </>
  );
}
