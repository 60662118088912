import React, {useEffect, useState} from "react";
import Form from "../../../../../../form/Form";
import FormFieldFactory from "../../../../../../form/FormFieldFactory";
import SelfCheckJobInstallationProfileOptionsService from "../../../../../../services/SelfCheckJobInstallationReportProfileOptionsService";

export default function JobReportInstallationProfileForm(props) {
  const [options, setOptions] = useState(null)
  const [formFields, setFormFields] = useState([])

  const submit_button_text = props.submitButtonText || window.gettext("Create measurement report profile")

  const form_field_factory = new FormFieldFactory(props.instance)

  useEffect(() => {
    if(!props.reportProfileId) {
      return
    }

    SelfCheckJobInstallationProfileOptionsService.fetch(
      props.reportProfileId
    ).then(
      setOptions
    )
  }, [props.reportProfileId])

  useEffect(() => {
      let form_fields = [
        form_field_factory.textField('name', window.gettext("Name"), true),
        form_field_factory.textArea('note', window.gettext('Note')),
        form_field_factory.booleanField('show_offered', window.gettext('Show offered'), false, true),
        form_field_factory.booleanField('show_difference', window.gettext('Show difference between done and offered'), false, true),
        form_field_factory.booleanField('show_entities_count', window.gettext('Show entities count')),
        form_field_factory.booleanField('show_radiators_with_comments', window.gettext('Show radiators with comments')),
      ]

      if (options) {
        form_fields.push(
          form_field_factory.selectMultiple('project_row_ids', window.gettext('Project rows')).withChoices(
            options.project_rows.map((projectRowOption) => {
                return [
                  projectRowOption.id,
                  projectRowOption.label,
                ]
              }
            )
          ).withHelpText(
            window.gettext('Project rows to be included in the report')
          )
        )

        form_fields.push(
          form_field_factory.selectMultiple(
            'entity_list_row_types',
            window.gettext('Entity list row types')
          ).withChoices(
            Object.entries(options.entity_list_row_types)
          ).withHelpText(
            window.gettext('Row types to be shown in the entity list')
          )
        )

        form_fields.push(
          form_field_factory.selectMultiple(
            'entity_list_visible_columns',
            window.gettext('Entity list visible columns')
          ).withChoices(
            Object.entries(options.entity_list_visible_columns)
          ).withHelpText(
            window.gettext('Columns to be shown in the entity list')
          )
        )

        form_fields.push(
          form_field_factory.selectMultiple(
            'entity_list_entrance_filters',
            window.gettext('Entity list entrance filters')
          ).withChoices(
            Object.entries(options.entity_list_entrance_filters)
          ).withHelpText(
            window.gettext('Entrances with data in any of the selected columns shall be shown in the entity list')
          )
        )

        form_fields.push(
          form_field_factory.selectMultiple(
            'entity_list_apartment_filters',
            window.gettext('Entity list apartment filters')
          ).withChoices(
            Object.entries(options.entity_list_apartment_filters)
          ).withHelpText(
            window.gettext('Apartments with data in any of the selected data shall be shown in the entity list')
          )
        )
      }

      setFormFields(form_fields)
    },
    [options]
  )


  return (
    <Form
      header={props.header}
      formTestId="project-form"
      formFields={formFields}
      onSubmit={props.onSubmit}
      submitButtonText={submit_button_text}
      submitButtonTestId="project-form-submit"
    />
  )
}