import { getComponentsHeaders } from "../../../helper";
import { HierarchyViewOption } from "../HierarchyViewOption";
import { HierarchyViewOptions } from "../HierarchyViewOptions";

export class HierarchyViewRSOptions extends HierarchyViewOptions {
  show_temperature_measurements_option = undefined;
  show_nipple_and_kv_option = undefined;

  constructor(entityType, optionValues) {
    super(entityType, {}, optionValues);

    const SHOW_PREFIX = window.gettext("Show") + " ";

    this.show_temperature_measurements_option = new HierarchyViewOption(
      entityType,
      "show_temperature_measurements",
      SHOW_PREFIX + getComponentsHeaders(entityType).temperature_measurements,
      optionValues.show_temperature_measurements
    );

    this.show_nipple_and_kv_option = new HierarchyViewOption(
      entityType,
      "show_nipple_and_kv",
      SHOW_PREFIX + getComponentsHeaders(entityType).nipple_and_kv,
      optionValues.show_nipple_and_kv
    );
  }

  getCheckboxAdditionalOptions() {
    // List of checkbox options, controls order of options in UI
    const options = [
      this.show_temperature_measurements_option,
      this.show_nipple_and_kv_option,
    ];

    return options;
  }
}
