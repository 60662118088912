import React, {useEffect, useState} from "react";
import FormFieldFactory from "../form/FormFieldFactory";
import Form from "../form/Form";


export default function SelfCheckJobForm(props) {
  let [submitDisabled, setSubmitDisabled] = useState(false)

  const submit_button_text = props.submitButtonText || window.gettext("Start job")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = []

  useEffect(() => {
    setSubmitDisabled(props.submitDisabled === true)
  }, [props.submitDisabled])

  return (
    <>
      <Form
        formTestId="self-check-job-form"
        formFields={form_fields}
        submitButtonText={submit_button_text}
        submitDisabled={submitDisabled}
        onSubmit={props.onSubmit}
      />
    </>
  )
}
