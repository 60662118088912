import UserPermission from "../../../../../../../user/UserPermission";
import Entity from "../../../../entity/Entity";
import EntityFrozenCalculationValuesTable from "../../../../entity/EntityFrozenCalculationValuesTable";

import { LayoutCard } from "../../../../../../../../layout";
import { useHierarchyContext, useHierarchyRSContext } from "../../../context";
import { getComponentsHeaders } from "../../../helper";
import {
  HierarchyEntitySiblings,
  HierarchyTaskInterface,
} from "../../components";
import {
  HierarchyRSAddRadiator,
  HierarchyRSMeasurements,
  HierarchyRSNippleKV,
} from "../../components/radiator_system";

export function HierarchyEntityRSRadiatorNode() {
  const { focusEntityData, hierarchyViewOptions, hasTaskSpecifications } =
    useHierarchyContext();

  const { latestOpportunity } = useHierarchyRSContext();

  return (
    <>
      {/* Entity Siblings */}
      <HierarchyEntitySiblings>
        {/* Will be shown in the middle. */}
        <Entity entityData={focusEntityData} />
      </HierarchyEntitySiblings>

      {/* Information */}
      {hierarchyViewOptions?.show_information_option.value && (
        <LayoutCard
          title={
            getComponentsHeaders(focusEntityData?.entity.type)
              .entity_information
          }
          noPadding
        >
          <EntityFrozenCalculationValuesTable
            frozenValues={focusEntityData.entity?.frozen_calculation}
            extraData={[
              {
                title: window.gettext("Room"),
                component: focusEntityData?.entity?.data?.room?.name || "",
              },
              {
                title: window.gettext("Radiator"),
                component:
                  focusEntityData?.entity?.data?.radiator?.type?.name || "",
              },
            ]}
          />
        </LayoutCard>
      )}

      {/* Measurement */}
      {hierarchyViewOptions?.show_temperature_measurements_option.value &&
        latestOpportunity && (
          <LayoutCard
            title={
              getComponentsHeaders(focusEntityData?.entity.type)
                .temperature_measurements
            }
          >
            <HierarchyRSMeasurements
              entity={focusEntityData.entity}
              showNextEntityButton
            />
          </LayoutCard>
        )}

      {/* Nipple and KV */}
      {hierarchyViewOptions?.show_nipple_and_kv_option.value && (
        <LayoutCard
          title={
            getComponentsHeaders(focusEntityData?.entity.type).nipple_and_kv
          }
        >
          <HierarchyRSNippleKV entity={focusEntityData.entity} />
        </LayoutCard>
      )}

      {/* Task interface */}
      {hasTaskSpecifications && <HierarchyTaskInterface />}

      {/* Add radiator */}
      <UserPermission
        permissions={[
          UserPermission.permissions.radiator_add,
          UserPermission.permissions.node_add,
        ]}
      >
        <LayoutCard title={window.gettext("Add radiator")}>
          <HierarchyRSAddRadiator
            parentNodeId={focusEntityData.entity?.data?.parent_node?.id}
          />
        </LayoutCard>
      </UserPermission>
    </>
  );
}
