import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import WithSuffix from "../../../../values/WithSuffix";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";
import KVValue from "../../../../values/KVValue";

export default function KVValueColumnDefinition() {
  return {
    field: 'kv_value',
    width: getColumnWidth('kv_value'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('KV Value'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'calculation.kv'
      )
    }),
    valueFormatter: paramsValueFormatter(3, ','),
    renderCell: (params => {
      return <KVValue value={params.value}/>
    })
  }
}
