import BaseService from "./BaseService";

class ApartmentService extends BaseService {

}

export default new ApartmentService('/api/',
  [
    'projects',
    'systems',
    'apartments',
  ]
)