import React from "react";
import IssueProvider from "../../../components/issue_tracker/IssueContext";
import IssueDetailPage from "./IssueDetailPage";

export default function IssueDetailView() {
  return (
    <IssueProvider>
      <IssueDetailPage/>
    </IssueProvider>
  )
}
