import React from "react";
import FormFieldFactory from "../form/FormFieldFactory";
import Form from "../form/Form";


export default function SystemDesiredRoomTemperatureForm(props) {

  const submit_button_text = props.submitButtonText || window.gettext("Save")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = [
    form_field_factory.integerField("room_temperature", window.gettext("Desired room temperature"), false, null),
  ]

  return (
    <Form
      formTestId="system-room-temperature-form"
      formFields={form_fields}
      submitButtonText={submit_button_text}
      onSubmit={props.onSubmit}
    />
  )
}