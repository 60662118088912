import {
  Checkbox,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";

import { LayoutCard } from "../../../../../../layout";
import { ProjectDetailSelfCheckTabJobPWSButtons } from "./potable_water_system";
import {
  ProjectDetailSelfCheckTabJobRSButtons,
  ProjectDetailSelfCheckTabJobRSMeasurements,
} from "./radiator_system";

export function ProjectDetailSelfCheckTabJobListItem({
  disableCopyCalculatedValuesButton,
  job,
  onCopyCalculatedValuesButtonClick,
  project,
  selectMode,
  selectable,
  selected,
  onSelectJob,
}) {
  return (
    <Stack
      sx={{
        flexDirection: "row",
        alignItems: "center",
        gap: 3,
      }}
    >
      <LayoutCard
        title={
          <>
            {job.system.name}

            <Chip
              label={job.spec.name + " #" + String(job.number)}
              sx={{
                ml: 2,
              }}
            />
          </>
        }
        noPadding
        sx={{
          flexGrow: 1,
        }}
      >
        <Stack
          sx={{
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
            p: 1,
          }}
        >
          {job.system.type === "potable_water" ? (
            <ProjectDetailSelfCheckTabJobPWSButtons job={job} />
          ) : (
            <ProjectDetailSelfCheckTabJobRSButtons job={job} />
          )}
        </Stack>

        {/* Measurements */}
        {job.system.type !== "potable_water" && (
          <ProjectDetailSelfCheckTabJobRSMeasurements
            job={job}
            project={project}
            disableCopyCalculatedValuesButton={
              disableCopyCalculatedValuesButton
            }
            onCopyCalculatedValuesButtonClick={
              onCopyCalculatedValuesButtonClick
            }
          />
        )}
      </LayoutCard>

      {selectMode && (
        <Checkbox
          checked={selected}
          onClick={() => onSelectJob(job)}
          disabled={!selectable}
          sx={{
            transform: "scale(1.5)",
          }}
        />
      )}
    </Stack>
  );
}
