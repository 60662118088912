import {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function DateTime(props) {
  let {value} = props;

  let dateObject = new Date(value)

  let [showTime, setShowTime] = useState(true)
  let [showSeconds, setShowSeconds] = useState(false)

  useEffect(() => {
    if (props.showTime === undefined || props.showTime === true) {
      setShowTime(true)
    }

    setShowTime(false)
  }, [props.showTime])

  useEffect(() => {
    if (props.showSeconds === undefined || props.showSeconds === true) {
      setShowTime(true)
    }

    setShowSeconds(false)
  }, [props.showSeconds])


  return (
    <>
      {(dateObject !== null) && (
        <>
          <span>{dateObject.getFullYear()}-{("0" + String(dateObject.getMonth() + 1)).slice(-2)}-{("0" + String(dateObject.getDate())).slice(-2)}</span>
          {(showTime === true) && (
            <>
              <span>&nbsp;</span>
              <span>{("0" + String(dateObject.getHours())).slice(-2)}</span>
              <span>:</span>
              <span>{("0" + String(dateObject.getMinutes())).slice(-2)}</span>
              {showSeconds && (
                <>
                  <>:</>
                  <span>{("0" + dateObject.getSeconds()).slice(-2)}</span>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

DateTime.propTypes = {
  value: PropTypes.string,
  showTime: PropTypes.bool,
  showSeconds: PropTypes.bool,
}