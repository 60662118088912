import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import LoadingIndicator from "../../../../../../../components/layout/LoadingIndicator";
import ProjectService from "../../../../../../../components/services/ProjectService";
import SystemService from "../../../../../../../components/services/SystemService";

import { SystemBuildRadiatorTable } from "../../../../../../../components/system/build";
import { LayoutCard, LayoutView } from "../../../../../../../layout";

export default function SystemBuildTableView() {
  const { system_id, project_id } = useParams();

  const [system, setSystem] = useState(undefined);
  const [project, setProject] = useState(undefined);

  useEffect(() => {
    ProjectService.fetch(project_id).then(setProject);
  }, [project_id]);

  useEffect(() => {
    SystemService.fetch(project_id, system_id).then(setSystem);
  }, [system_id, project_id]);

  return (
    <LayoutView
      breadcrumbs={{
        project: project,
        projectTab: 1,
        system: system,
      }}
      header={window.gettext("System Build")}
    >
      {!system || !project ? (
        <LoadingIndicator />
      ) : (
        <LayoutCard noPadding>
          <SystemBuildRadiatorTable
            system={system}
            setSystem={setSystem}
            project={project}
          />
        </LayoutCard>
      )}
    </LayoutView>
  );
}
