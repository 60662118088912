export default function get_class_name_for_target_type(target_type) {
  if (target_type === 'address_entrance') {
    return 'job-datagrid-row-entrance'
  }

  if (target_type === 'address_coded_address') {
    return 'job-datagrid-row-coded_address'
  }

  if (target_type === 'address_house_number') {
    return 'job-datagrid-row-house_number'
  }

  if (target_type === 'address_apartment') {
    return 'job-datagrid-row-apartment'
  }

  if (target_type === 'room') {
    return 'job-datagrid-row-room'
  }

  if (target_type === 'radiator_node') {
    return 'job-datagrid-row-radiator'
  }

  if (target_type === 'valve_node') {
    return 'job-datagrid-row-valve'
  }
}