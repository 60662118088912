import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

import AdaptableMultilineTextField from "../../../../form/AdaptableMultilineTextField";
import HeatCurveName from "../../../../heat_curve/HeatCurveName";
import MultilineText from "../../../../layout/MultilineText";
import SelfCheckJobMeasurementService from "../../../../services/measurement/SelfCheckJobMeasurementService";
import UserPermission from "../../../../user/UserPermission";
import DateTime from "../../../../values/DateTime";
import SelfCheckHierarchyButtonLink from "../utils/SelfCheckHierarchyButtonLink";

import { LayoutCard } from "../../../../../layout";

export default function MeasurementInfo(props) {
  const { project_id, system, job, measurement, editable, heatCurves } = props;

  const [editMeasurementOpen, setEditMeasurementOpen] = useState(false);
  const [measurementHeatCurve, setMeasurementHeatCurve] = useState(null);
  const [measurementComment, setMeasurementComment] = useState("");
  const [measurementPumpSetting, setMeasurementPumpSetting] = useState("");

  useEffect(() => {
    if (!measurement) {
      return;
    }

    setMeasurementComment(String(measurement.comment));
    setMeasurementPumpSetting(measurement.pump_setting);
    setMeasurementHeatCurve(measurement.heat_curve);
  }, [measurement]);

  const handleSaveMeasurement = () => {
    let data = {};
    if (measurementHeatCurve !== (measurement?.heat_curve || null)) {
      data["heat_curve"] = measurementHeatCurve;
    }

    if (measurementComment !== (measurement?.comment || "")) {
      data["comment"] = measurementComment;
    }

    if (measurementPumpSetting !== (measurement?.pump_setting || "")) {
      data["pump_setting"] = measurementPumpSetting;
    }

    SelfCheckJobMeasurementService.update(
      data,
      project_id,
      system.id,
      job.id,
      measurement.id
    ).then((newMeasurement) => {
      setEditMeasurementOpen(false);
      props.onUpdated(newMeasurement);
    });
  };

  return (
    <LayoutCard
      title={
        <>
          {window.gettext("Measurement information")}

          <UserPermission permission={"change_measurement"}>
            <IconButton
              onClick={() => {
                setEditMeasurementOpen(!editMeasurementOpen);
              }}
            >
              <EditIcon />
            </IconButton>
          </UserPermission>
        </>
      }
    >
      {measurement && (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{window.gettext("Created")}</TableCell>

                <TableCell>
                  <DateTime value={measurement?.created} />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  {window.gettext("Link to hierarchy view for job")}
                </TableCell>

                <TableCell>
                  <SelfCheckHierarchyButtonLink
                    job={job}
                    system={system}
                    projectId={parseInt(project_id)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{window.gettext("Comment")}</TableCell>

                <TableCell>
                  {!editMeasurementOpen ? (
                    <MultilineText>{measurement?.comment}</MultilineText>
                  ) : (
                    <>
                      <AdaptableMultilineTextField
                        value={measurementComment}
                        label={window.gettext("Comment")}
                        onChange={(e) => {
                          setMeasurementComment(e.target.value);
                        }}
                      />

                      <Button onClick={handleSaveMeasurement}>
                        {window.gettext("Save")}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{window.gettext("Pump setting")}</TableCell>

                <TableCell>
                  {!editMeasurementOpen ? (
                    <MultilineText>{measurement?.pump_setting}</MultilineText>
                  ) : (
                    <>
                      <AdaptableMultilineTextField
                        value={measurementPumpSetting}
                        label={window.gettext("Pump setting")}
                        onChange={(e) => {
                          setMeasurementPumpSetting(e.target.value);
                        }}
                      />

                      <Button onClick={handleSaveMeasurement}>
                        {window.gettext("Save")}
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{window.gettext("Heat curve")}</TableCell>

                <TableCell>
                  {!editMeasurementOpen ? (
                    <HeatCurveName
                      heat_curve={
                        heatCurves.find(
                          (hc) => hc.id === measurement?.heat_curve
                        ) || null
                      }
                      if_empty={"-"}
                    />
                  ) : (
                    <Select
                      fullWidth
                      value={measurementHeatCurve}
                      onChange={(e) => {
                        setMeasurementHeatCurve(e.target.value);
                        handleSaveMeasurement();
                        setEditMeasurementOpen(false);
                      }}
                    >
                      {heatCurves.map((heatCurve) => (
                        <MenuItem
                          value={heatCurve.id}
                          key={"select_heat_curve" + heatCurve.id}
                        >
                          <HeatCurveName heat_curve={heatCurve} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </LayoutCard>
  );
}
