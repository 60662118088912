import { Thermostat } from "@mui/icons-material";

import Helper from "../../../../../utils/Helper";

export function EntityMeasurementStatusIcon({ entityStatus }) {
  return (
    <>
      {entityStatus?.measurement_status?.has_values === true && (
        <Helper
          fullOpacity
          icon={<Thermostat color={"success"} />}
          title={window.gettext("Has measurement values")}
        />
      )}
    </>
  );
}
