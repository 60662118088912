import {
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { subscribe } from "valtio";

import ProjectList from "../../components/project/ProjectList";
import PreferredView from "../../components/user/PreferredView";
import UserPermission from "../../components/user/UserPermission";
import getUserHasPermission from "../../components/user/getUserHasPermission";
import store from "../../state";

import { LayoutCard, LayoutView } from "../../layout";

export default function ProjectsView() {
  const navigate = useNavigate();
  const [user, setUser] = useState(store.user);
  const [preferredViews, setPreferredViews] = useState(undefined);

  useEffect(() => {
    // Subscribe to store changes
    let unsubscribe = subscribe(store, () => {
      setUser(store.user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user === undefined) {
      return;
    }
    setPreferredViews(PreferredView(user, "project_list"));
  }, [user]);

  useEffect(() => {
    if (preferredViews === undefined) {
      return;
    }

    // If there is only one preferred view, yeah, go with that.
    if (preferredViews.length === 1) {
      navigate("/projects/" + String(preferredViews[0].preference.value) + "/");
    }
  }, [navigate, preferredViews]);

  return (
    <LayoutView
      header={window.gettext("Projects")}
      submenuItems={
        getUserHasPermission(
          store.user,
          UserPermission.permissions.project_add
        ) && (
          <Button
            data-test-id="project-create-link"
            variant="contained"
            component={RouterLink}
            to="/projects/create/"
          >
            {window.gettext("Create project")}
          </Button>
        )
      }
    >
      {preferredViews !== undefined && preferredViews.length > 1 && (
        <LayoutCard title={window.gettext("Project list views")}>
          <Typography variant="body">
            {window.gettext(
              "These are the view types that are configured for you due to team membership"
            )}
          </Typography>

          <List>
            {preferredViews.map((preferredView, index) => {
              return (
                <ListItemButton
                  key={index}
                  component={RouterLink}
                  to={"/projects/" + preferredView.preference.value + "/"}
                >
                  {/* Show why the user was suggested this view */}
                  <ListItemText
                    primary={preferredView.preference.choice_label}
                    secondary={
                      <>
                        <Typography variant="body">
                          {window.gettext("Suggested through")}

                          <span>: </span>
                        </Typography>

                        {preferredView.sources.map((source, index) => {
                          return (
                            <Typography key={index} variant="body">
                              {source.data.name}
                            </Typography>
                          );
                        })}
                      </>
                    }
                  />
                </ListItemButton>
              );
            })}
          </List>
        </LayoutCard>
      )}

      <ProjectList />
    </LayoutView>
  );
}
