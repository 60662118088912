import HomeIcon from "@mui/icons-material/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, IconButton } from "@mui/material";

import { LayoutCard } from "../../../../../../layout";
import { useHierarchyContext } from "../context";
import { HierarchyEntityLink } from "./components/components";

export function HierarchyBreadcrumbs() {
  const { crumbs, setEntity } = useHierarchyContext();

  return (
    <LayoutCard>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <IconButton
          color="primary"
          onClick={() => {
            setEntity(null);
          }}
        >
          <HomeIcon
            sx={{
              fontSize: "24px",
            }}
          />
        </IconButton>

        {crumbs.map((crumb) => {
          return (
            <HierarchyEntityLink
              key={crumb.entity.id}
              entity={crumb.entity}
              fontSize="16px"
            />
          );
        })}
      </Breadcrumbs>
    </LayoutCard>
  );
}
