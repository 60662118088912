import { findElementById } from "../../../../../../../../../helper";
import { getDefaultEntityLabel } from "../../../../../utils";
import {
  getFieldNameComment,
  getFieldNameTask,
  getFieldNameTaskItem,
  hasColumn,
} from "./helper";

// Will be imported from constant file in the future
const TASK_SPEC_TYPE_ITEM_GROUP = "item_group";

const GENERATED_COLUMN_WIDTH = 200;
const GENERATED_COLUMN_COMMENT_WIDTH = 200;

export function getColumns(taskSpecs, taskItemSpecs) {
  const columns = [
    {
      field: "entity_type",
      headerName: window.gettext("Type"),
      width: 150,
      valueGetter: ({ row }) => getDefaultEntityLabel(row.entity_type),
    },
    {
      field: "address",
      headerName: window.gettext("Address"),
      width: 150,
    },
    {
      field: "apartment_number",
      headerName: window.gettext("Apartment number"),
      width: 150,
    },
    {
      field: "entity_name",
      headerName: window.gettext("Unit"),
      width: 150,
    },
    {
      field: "object_number",
      headerName: window.gettext("Object number"),
      width: 150,
    },
  ];

  Object.values(taskItemSpecs).forEach((taskItemSpec) => {
    // Get task specification from taskSpecs
    const taskSpec = findElementById(taskItemSpec.task_spec_id, taskSpecs);

    // Get task column "field"
    const taskFieldName = getFieldNameTask(taskSpec);

    // Only add task column if it does not exist yet
    if (!hasColumn(columns, taskFieldName)) {
      // Add task column
      columns.push({
        field: taskFieldName,
        headerName: taskSpec.name,
        width: GENERATED_COLUMN_WIDTH,
      });
    }

    // Only add task comment column if task has commenting enabled
    if (taskSpec.enable_commenting) {
      const taskCommentFieldName = getFieldNameComment(taskFieldName);

      // Only add comment column if it does not exist yet
      if (!hasColumn(columns, taskCommentFieldName)) {
        //Add task comment column
        columns.push({
          field: taskCommentFieldName,
          headerName: taskSpec.name + " " + window.gettext("comment"),
          width: GENERATED_COLUMN_COMMENT_WIDTH,
        });
      }
    }

    // Only add task item column if task is of type item group
    if (taskSpec.type === TASK_SPEC_TYPE_ITEM_GROUP) {
      const taskItemFieldName = getFieldNameTaskItem(taskItemSpec);

      // Only add task item column if it does not exist yet
      if (!hasColumn(columns, taskItemFieldName)) {
        // Add task item column
        columns.push({
          field: taskItemFieldName,
          headerName: taskItemSpec.name,
          width: GENERATED_COLUMN_WIDTH,
        });
      }

      // Only add task item comment column if task item has commenting enabled
      if (taskItemSpec.enable_commenting) {
        const taskItemCommentFieldName = getFieldNameComment(taskItemFieldName);

        // Only add comment column if it does not exist yet
        if (!hasColumn(columns, taskItemCommentFieldName)) {
          //Add task item comment column
          columns.push({
            field: taskItemCommentFieldName,
            headerName: taskItemSpec.name + " " + window.gettext("comment"),
            width: GENERATED_COLUMN_COMMENT_WIDTH,
          });
        }
      }
    }
  });

  return columns;
}
