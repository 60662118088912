import React, {useEffect} from 'react';
import {
  CartesianGrid,
  Legend, ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import LoadingIndicator from "../../../../../layout/LoadingIndicator";

export default function OpportunitiesTemperaturesChart(
  {
    opportunities,
    referenceOutflowTemperature,
    referenceReturnTemperature,
  }
) {
  const [returnTemperatures, setReturnTemperatures] = React.useState([])
  const [outflowTemperatures, setOutflowTemperatures] = React.useState([])
  const [maxRange, setMaxRange] = React.useState(1)
  const [lowestTemperature, setLowestTemperature] = React.useState(referenceReturnTemperature || undefined)
  const [highestTemperature, setHighestTemperature] = React.useState(referenceOutflowTemperature || undefined)

  useEffect(() => {
    let newReturnTemperatures = []
    let newOutflowTemperatures = []

    opportunities.forEach(
      (opportunity) => {
        setLowestTemperature(
          Math.min(
            lowestTemperature || opportunity.return_temperature,
            parseFloat(opportunity.return_temperature),
            parseFloat(opportunity.outflow_temperature),
          )
        )

        setHighestTemperature(
          Math.max(
            highestTemperature || opportunity.return_temperature,
            parseFloat(opportunity.return_temperature),
            parseFloat(opportunity.outflow_temperature),
          )
        )

        const foundInstanceForOutsideTemperature = newReturnTemperatures.find(
          (returnTemperature) => {
            return (
              returnTemperature.y === parseFloat(opportunity.outside_temperature) &&
              returnTemperature.x === parseFloat(opportunity.return_temperature)
            )
          }
        )

        if (foundInstanceForOutsideTemperature) {
          foundInstanceForOutsideTemperature.z += 1
          setMaxRange(Math.max(maxRange, foundInstanceForOutsideTemperature.z))
        } else {
          newReturnTemperatures.push({
            x: parseFloat(opportunity.return_temperature),
            y: parseFloat(opportunity.outside_temperature),
            z: 1,
          })
        }
        const foundInstanceForOutflowTemperature = newOutflowTemperatures.find(
          (returnTemperature) => {
            return (
              returnTemperature.y === parseFloat(opportunity.outside_temperature) &&
              returnTemperature.x === parseFloat(opportunity.outflow_temperature)
            )
          }
        )

        if (foundInstanceForOutflowTemperature) {
          foundInstanceForOutflowTemperature.z += 1
          setMaxRange(Math.max(maxRange, foundInstanceForOutflowTemperature.z))
        } else {
          newOutflowTemperatures.push({
            x: parseFloat(opportunity.outflow_temperature),
            y: parseFloat(opportunity.outside_temperature),
            z: 1,
          })
        }
      }
    )

    setReturnTemperatures(newReturnTemperatures)
    setOutflowTemperatures(newOutflowTemperatures)
  }, [opportunities]);

  return (
    <>
      {opportunities === undefined ? (
        <>
          <LoadingIndicator/>
        </>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={400}>
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
              }}
            >
              <CartesianGrid/>
              <XAxis
                type="number"
                dataKey="x"
                name={window.gettext("Measured value")}
                unit="C"
                domain={
                  [
                    lowestTemperature - 5,
                    highestTemperature + 5,
                  ]
                }
              />
              <YAxis
                type="number"
                dataKey="y"
                name={window.gettext("Outside temperature")}
                unit="C"
              />
              <ZAxis
                type="number"
                dataKey="z"
                range={
                  [
                    1, (maxRange + 100)
                  ]
                }
                name={window.gettext("Measurement points")}
                unit=""
              />
              <ReferenceLine
                x={referenceReturnTemperature}
                stroke={"#0546a1"}
                label={
                  window.gettext('sys. return')
                }
              />
              <ReferenceLine
                x={referenceOutflowTemperature}
                stroke={"#a12905"}
                label={
                  window.gettext('sys. outflow')
                }
              />
              <Tooltip cursor={{strokeDasharray: '3 3'}}/>
              <Legend/>
              <Scatter
                name={window.gettext('Return temperature')}
                data={returnTemperatures}
                fill={"#3a76ce"}
              />
              <Scatter
                name={window.gettext('Outflow temperature')}
                data={outflowTemperatures}
                fill={"#de6e3e"}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  )
}