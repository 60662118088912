import BaseService from "./BaseService";

class NippleService extends BaseService {
  cache_list = () => {
    return new Promise(
      (resolve, reject) => {
        const nipples = localStorage.getItem('nipples') || undefined

        if(nipples !== undefined){
          resolve(JSON.parse(nipples))
          return
        }

        this.list().then(
          (response) => {
            localStorage.setItem('nipples', JSON.stringify(response))
            resolve(response)
          },
          (reason) => {
            reject(reason)
          }
        )
      }
    )
  }
}

export default new NippleService('/api/',
  [
    'nipples',
  ]
)