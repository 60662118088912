import {Checkbox} from "@mui/material";
import UserPermission from "../user/UserPermission";
import SystemService from "../services/SystemService";
import {useSnackbar} from "notistack";

export default function SystemReadOnlyCheckbox({system, onSystemChanged}) {
  let {enqueueSnackbar} = useSnackbar()

  const handleOnCheckboxClick = (e) => {
    SystemService.update(
      {
        'write_lock': !system.write_lock
      },
      system.project_id,
      system.id
    ).then(
      (response) => {
        if(!response.write_lock){
          enqueueSnackbar(
            window.gettext('Write lock removed'),
            {
              'variant': 'success'
            }
          )
        }else{
          enqueueSnackbar(
            window.gettext('Write lock added'),
            {
              'variant': 'success'
            }
          )
        }
        onSystemChanged(response)
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext('Could not change write lock status'),
          {
            'variant': 'error'
          }
        )
      }
    )
  }

  return (
    <>
      <UserPermission
        permission={UserPermission.permissions.system_remove_write_lock}
        else={
          <>
            <Checkbox
              checked={system?.write_lock === true}
              disabled
            />
          </>
        }
      >
        <Checkbox
          color='error'
          checked={system?.write_lock === true}
          onClick={handleOnCheckboxClick}
        />
      </UserPermission>
    </>
  )
}