import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import ProjectForm from "../../../components/project/ProjectForm";
import { removeKeysWithValue } from "../../../components/services/BaseService";
import ProjectService from "../../../components/services/ProjectService";
import MissingPermissionMessage from "../../../components/user/MissingPermissionMessage";
import UserPermission from "../../../components/user/UserPermission";
import { LayoutCard, LayoutView } from "../../../layout";

export default function ProjectCreateView() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  function handleSubmit(values) {
    removeKeysWithValue(values, ["", null]);

    console.log("Trying to create project with values:", values);
    ProjectService.create(values).then(
      (response) => {
        enqueueSnackbar(window.gettext("Project created"), {
          variant: "success",
        });
        console.log("Successfully created project");
        navigate("/projects/" + String(response.id) + "/");
      },
      (rejection) => {
        enqueueSnackbar(
          window.gettext("Error while trying to create project"),
          {
            variant: "error",
          }
        );

        if (
          rejection.hasOwnProperty("response") &&
          rejection.response.hasOwnProperty("data")
        ) {
          console.error("Failed to created project\n", rejection.response.data);
        } else {
          console.error("Failed to created project");
        }
      }
    );
  }

  return (
    <LayoutView header={window.gettext("Create project")}>
      <UserPermission
        permission={UserPermission.permissions.project_add}
        else={<MissingPermissionMessage />}
      >
        <LayoutCard>
          <ProjectForm onSubmit={handleSubmit} />
        </LayoutCard>
      </UserPermission>
    </LayoutView>
  );
}
