import ApartmentName from "../../../../apartment/ApartmentName";
import React from "react";

export default function JobDatagridEntityName({entity}) {
  return (
    <>
      {
        (
          entity.type === 'address_apartment'
        ) ? (
          <ApartmentName
            apartmentData={entity.data}
            showLocalApartmentNumber={false}
            showPrefix={false}
          />
        ) : (
          <>
            {(entity.data.label === null) ? (
              <span>
                {window.gettext('Unknown')}
              </span>
            ) : (
              <span>
                {entity.data.label}
              </span>
            )}
          </>
        )
      }
    </>
  )
}