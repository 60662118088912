import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import getColumnWidth from "../GetColumnWidth";

export default function AddressColumnDefinition() {
  return {
    field: 'address',
    width: getColumnWidth('address'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Address'),
    editable: false,
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row, 'node.item.location.address'
      )
    })
  }
}