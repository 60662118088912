import PropTypes from "prop-types";
import {Grid, IconButton, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Button from "@mui/material/Button";

export default function EntityEditableUserDefinedCalculationValue(props) {
  let [data, setData] = useState(null)
  let [isEditable, setIsEditable] = useState(false)
  let [editMode, setEditMode] = useState(false)
  let [value, setValue] = useState("")

  useEffect(() => {
    if (!props.data) {
      return
    }

    setData(props.data)
  }, [props.data])

  const getValueFromData = (d) => {
    let newValue = d?.userDefinedValue

    if (d?.name === 'volume') {
      newValue = newValue / 1000
    }

    return newValue
  }

  useEffect(() => {
    if (!data) {
      return
    }

    setValue(getValueFromData(data) || "")
  }, [data])

  useEffect(() => {
    if (!props.editable) {
      return
    }

    setIsEditable(props.editable)
  }, [props.editable])

  const handleOnChange = (e) => {
    let newValue = e.target.value

    setValue(newValue)
  }

  const handleOnClickSave = (e) => {
    let newValue = value

    if(data?.name === 'volume'){
      newValue = parseInt(newValue) * 1000
    }

    // Populate change, should send API call etc.
    props.onChange(
      {
        'originalData': data,
        'newValue': newValue,
      }
    )

    // Exit edit mode
    setEditMode(false)
  }

  const handleOnClickCancel = (e) => {
    setEditMode(false)
    setValue(getValueFromData(data) || "")
  }

  return (
    <>
      <Grid
        container
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item>
          {(!editMode) ? (
            <>
              {(data?.userDefinedValueComponent) ? (
                <>
                  {data?.userDefinedValueComponent}
                </>
              ) : (
                <>
                  {value}
                </>
              )}
            </>
          ) : (
            <>
              <TextField
                size="small"
                value={value}
                variant="outlined"
                onChange={handleOnChange}
              />
            </>
          )}
        </Grid>
        {isEditable && (
          <>
            {(!editMode) ? (
              <>
                <Grid item>
                  <IconButton
                    size={"small"}
                    sx={{ml: 1}}
                    onClick={() => {
                      setEditMode(true)
                    }}
                  >
                    <EditIcon fontSize={"inherit"}/>
                  </IconButton>
                </Grid>
              </>
            ) : (
              <>
                <Grid item>
                  <Button
                    startIcon={<SaveIcon/>}
                    variant="contained"
                    color="primary"
                    onClick={handleOnClickSave}
                  >
                    {window.gettext('Save')}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleOnClickCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

EntityEditableUserDefinedCalculationValue.propTypes = {
  'editable': PropTypes.bool,
  'data': PropTypes.object,
  'onChange': PropTypes.func,
}