import User from "../user/User";

export default function UserChoice({id, users}) {
  // Returns the user with the given id from the users list.
  // Returns null if the user is not found.
  if (!users) {
    return null;
  }

  const user = users.find((user) => user.id === id);

  return (
    <>
      <User user={user}/>
    </>
  )
}
