import {
  Box,
  Checkbox,
  CircularProgress,
  ListItemText,
  MenuItem,
} from "@mui/material";

export function HierarchyTaskItemOption({
  checked,
  hasError,
  isSaving,
  label,
  onClick,
  value,
}) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* Checkbox */}
      <MenuItem value={value} onClick={onClick}>
        <Checkbox checked={checked} />
        <ListItemText primary={label} />
      </MenuItem>

      {/* Saving indicator */}
      <Box
        sx={{
          position: "absolute",
          top: 11,
          left: 21,
          zIndex: 0,
        }}
      >
        {/* Saving indicator */}
        {isSaving || hasError ? (
          // Saving indicator
          <CircularProgress
            variant={hasError ? "determinate" : "indeterminate"}
            value={hasError ? 100 : undefined}
            color={hasError ? "error" : "primary"}
            size={32}
          />
        ) : (
          // Placeholder
          <Box
            sx={{
              width: 32,
              height: 32,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
