import {useEffect, useState} from "react";
import {Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import get_class_name_for_target_type from "../../../../../utils/get_class_name_for_entity_type";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {LocationLabel} from "./LocationLabel";
import Temperature from "../../../../../values/Temperature";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from "@mui/material/Box";

export function MedianTemperatureReportTable({report, reportSettings}) {
  let [rows, setRows] = useState([])
  let [openRowIds, setOpenRowIds] = useState([])

  const getRowId = (r) => {
    return r.entity.type + '_' + String(r.entity.id)
  }
  const getRows = (r, parent, ancestors, indentationLevel) => {
    if (!report || !reportSettings) {
      return
    }
    let localAncestors = JSON.parse(JSON.stringify(ancestors || []))

    if (parent) {
      localAncestors.push(parent)
    }

    return [
      [
        {
          'id': getRowId(r),
          'children_ids': (r.children || []).map(getRowId),
          'data': r,
          'indentationLevel': localAncestors.length,
          'parent': parent,
          'ancestors': localAncestors
        }
      ]
    ].concat(
      (r.children || []).map(
        (child) => {
          return getRows(child, r, localAncestors, indentationLevel + 1)
        }
      )
    ).flat()
  }

  useEffect(() => {
    const newRows = getRows(report, null, 0)

    // Create list of open entitis (ids)
    const newOpenRowIds = newRows.filter(
      (row) => {
        // If we have defined a set of types to be visible, always show.
        if (reportSettings.median_temperature_entity_types) {
          return true
        }

        return row.indentationLevel <= reportSettings.report_row_expanded_indentation_level
      }
    ).map(
      (row) => {
        return row.id
      }
    )

    setOpenRowIds(newOpenRowIds)

    // Show only rows with the types we allow.
    setRows(newRows.filter(
      (row) => {
        // Do not show if this is not included in the list (if a list s set and not empty)
        if (reportSettings.median_temperature_entity_types.length > 0 && !reportSettings.median_temperature_entity_types.includes(row.data.entity.type)) {
          return false
        }

        return true
      }
    ))

  }, [report])

  const toggleChildren = (row) => {
    let newOpenRowIds = []

    // At least one child of this row is open, so we shall close them all
    if (openRowIds.find((rowId) => {
      return row.children_ids.includes(rowId)
    })) {
      // Get list of all descendants
      const descendantIds = rows.filter(
        (r) => {
          return (r.ancestors.map(getRowId)).includes(row.id)
        }
      ).map(
        (descendant) => {
          return descendant.id
        }
      )

      // Filter out the descendants
      newOpenRowIds = openRowIds.filter(
        (openRowId) => {
          return !descendantIds.includes(openRowId)
        }
      )
    } else {
      // None of the children was open
      newOpenRowIds = openRowIds.map((row) => {
        return row
      }).concat(
        row.children_ids
      )
    }

    // New list of row ids to have open
    setOpenRowIds(
      newOpenRowIds
    )
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={
                  {
                    'displayPrint': 'none',
                  }
                }
              >&nbsp;</TableCell>
              <TableCell>
                {window.gettext('Location')}
              </TableCell>
              <TableCell>
                {window.gettext('Median')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.filter(
                (row) => {
                  return openRowIds.includes(row.id)
                }
              ).map(
                (row) => {
                  const isOpen = !!row.children_ids.find(
                    (child_id) => {
                      return openRowIds.includes(child_id)
                    }
                  )

                  return (
                    <TableRow
                      key={
                        row.id
                      }
                      className={get_class_name_for_target_type(row.data.entity.type)}
                    >
                      <TableCell
                        sx={
                          {
                            displayPrint: 'none'
                          }
                        }
                      >
                        {(row.data.children.length > 0 && reportSettings.median_temperature_entity_types.length == 0) && (
                          <>
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => toggleChildren(row)}
                              sx={{ml: row.indentationLevel * 2}}
                            >
                              {!isOpen ? <KeyboardArrowRightIcon/> : <KeyboardArrowDownIcon/>}
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Grid
                          container
                        >
                          <Grid
                            item
                            sx={{ml: row.indentationLevel}}
                          >
                            &nbsp;
                          </Grid>
                          <Grid item>
                            <LocationLabel
                              entity={row.data.entity}
                              locationTree={row.ancestors.map((ancestor) => {
                                return ancestor.entity
                              })}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Temperature value={row.data?.room_temperature?.median} precision={1}/>
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}