export default class RowsValidationError {
  constructor(
    errorType,
    errorMessage,
    rows,
  ) {
    this.errorType = errorType
    this.errorMessage = errorMessage
    if(rows === undefined){
      rows = []
    }
    this.rows = rows
  }

  repr = () => {
    return {
      'type' : this.errorType,
      'message': this.errorMessage,
      'rows': this.rows,
    }
  }
}