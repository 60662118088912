import { Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { Link } from "../../ui";

import getProjectRepresentation from "../project/getProjectRepresentation";

export function Breadcrumbs({
  current,
  linkColor,
  project,
  projectTab,
  system,
}) {
  const [crumbs, setCrumbs] = useState(undefined);

  useEffect(() => {
    if (!project || system === null) {
      setCrumbs(undefined);
      return;
    }

    const crumbs = [
      {
        label: getProjectRepresentation(project),
        path: "/projects/" + project.id,
      },
    ];

    // Add Project tab number to the path if it's defined
    if (projectTab !== undefined) {
      crumbs[0].path += "?tab=" + projectTab;
    }

    if (system) {
      crumbs.push({
        label: system.name,
        path: "/projects/" + project.id + "/systems/" + system.id,
      });
    }

    if (current) {
      crumbs.push(current);
    }

    setCrumbs(crumbs);
  }, [current, project?.id, projectTab, system?.id]);

  if (!crumbs) {
    return null;
  }

  return (
    <Stack
      sx={{
        flexDirection: "row",
        gap: 1,
      }}
    >
      {crumbs?.map((crumb, index) => (
        <Stack
          key={index}
          sx={{
            flexDirection: "row",
            gap: 1,
          }}
        >
          {index > 0 && <Typography color={linkColor}>/</Typography>}

          {crumb.path ? (
            <Link to={crumb.path} color={linkColor}>
              {crumb.label}
            </Link>
          ) : (
            <Typography color={linkColor}>{crumb.label}</Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );
}
