import { useHierarchyContext } from "../../context";
import {
  HierarchyEntityInformation,
  HierarchyTaskInterface,
  HierarchyTaskInterfaceLinkedEntities,
} from "../components";
import { HierarchyNavigation } from "../components/HierarchyNavigation";
import { HierarchyEntityRSAddressEntrance } from "./radiator_system";

export function HierarchyEntityAddressEntrance() {
  const { system } = useHierarchyContext();

  return (
    <>
      {system.type === "radiator" ? (
        <HierarchyEntityRSAddressEntrance />
      ) : (
        <div></div>
      )}

      <HierarchyEntityInformation />

      <HierarchyTaskInterface />

      <HierarchyTaskInterfaceLinkedEntities />

      <HierarchyNavigation />
    </>
  );
}
