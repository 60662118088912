import { useReducer } from "react";
import {Button, FormGroup, Grid, TextField, Typography} from "@mui/material";

import { copyObject, trimStringValues } from "../services/BaseService";

// To add a new Form Field:
// 1. Add KEY and init-value to newCustomerInitValues.
//    ex: new_field_value : ""
// 2. Add CASE in reducer-function.
//    ex: changed_new_field_value
// 3. Add field in ProjectForm's return:
//    value = {KEY}
//    onChange = {(e) => dispatch({type:"CASE", value:e.target.value})}

// For formatting that should be applied live do this in reducer-function.
// ex: toUpperCase
// For formatting that should be applied on submit do this in ProjectForm's handleSubmit.
// ex: trim, if this is done live then the user will not be allowed to add spacing between words.

// All fields will be trimmed on submit.

const CUSTOMER_INIT_VALUES = {
  /* -------------------- Customer Information  ---------------------- */
  name: "",
  organization_number: "",
}

function reducer(state, action) {
  switch(action.type) {
    /* -------------------- Customer Information  -------------------- */
    case "changed_name":
      return {...state, name: action.value};
    case "changed_organization_number":
      return {...state, organization_number : action.value};
  }
  throw Error('Unknown action: ' + action.type);
}

export default function CustomerForm({onSubmit, initValues, submitButtonText}) {
  // Use initValues and fill missing values with CUSTOMER_INIT_VALUES-template
  const [form, dispatch] = useReducer(reducer, {...CUSTOMER_INIT_VALUES, ...initValues});

  function handleSubmit(event) {
    event.preventDefault();

    const values = copyObject(form, Object.keys(CUSTOMER_INIT_VALUES));
    trimStringValues(values);

    onSubmit(values);
  }

  return (
    <Grid
      container
      component="form"
      onSubmit={handleSubmit}
      spacing={4}
      sx={{width:"100%", alignItems:"center"}}
      columns={{ xs: 12 }}
    >
      {/* -------------------- Customer Information  -------------------- */}
      <Grid item xs={12}>
        <FormGroup sx={{gap:1}}>
          <Typography variant="h4">{window.gettext("Customer information")}</Typography>
          {/* -------------- Name ------------------------------------- */}
          <TextField
            required
            variant="standard"
            label={window.gettext("Name")}
            inputProps={{ maxLength: undefined }}
            sx={{flex:1}}
            value={form.name}
            onChange={(e) => dispatch({type:"changed_name", value:e.target.value})}
          />
          {/* -------------- Organization number ---------------------- */}
          <TextField 
            variant="standard"
            label={window.gettext("Organization number")}
            inputProps={{ maxLength: undefined }}
            sx={{flex:1}}
            value={form.organization_number}
            onChange={(e) => dispatch({type:"changed_organization_number", value:e.target.value})}
          />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sx={{display:"flex", justifyContent:"end"}}>
        <Button variant="contained" type="submit">{submitButtonText || window.gettext("Create Customer")}</Button>
      </Grid>
    </Grid>
  )
}