export const FIELD_TYPES = {
  BOOLEAN_FIELD: {},
  CHOICES_FIELD: {},
  CURRENCY_FIELD: {
    endAdornmentText: "kr",
    props: {
      type: "number",
    },
  },
  DECIMAL_FIELD: {
    props: {
      type: "number",
    },
  },
  INTEGER_FIELD: {
    props: {
      type: "number",
    },
  },
  SELECT_MULTIPLE_FIELD: {},
  TEXT_AREA: {
    props: {
      multiline: true,
    }
  },
  TEXT_FIELD: {},
  AUTOCOMPLETE_FIELD: {},
};