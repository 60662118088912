import BaseService from "./BaseService";

class ProjectService extends BaseService {

}

export default new ProjectService('/api/',
  [
    'projects'
  ]
)