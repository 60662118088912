import React from "react";
import FormFieldFactory from "../../form/FormFieldFactory";
import Form from "../../form/Form";


export default function PipeForm(props) {
  const submit_button_text = props.submitButtonText || window.gettext("Create pipe")

  const form_field_factory = new FormFieldFactory(props.instance)

  const form_fields = [
    form_field_factory.textField('name', window.gettext("Name"), false),
    form_field_factory.integerField('length', window.gettext("Length"), false, 0),
  ]

  return (
    <Form
      formTestId="pipe-form"
      formFields={form_fields}
      submitButtonText={submit_button_text}
      onSubmit={props.onSubmit}
    />
  )
}
