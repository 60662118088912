import { useContext } from "react";

import { HierarchyRSContext } from "../HierarchyRSContext";

export function useHierarchyRSContext() {
  const context = useContext(HierarchyRSContext);

  if (!context) {
    throw new Error(
      "useHierarchyRSContext has to be used within <HierarchyRSContextProvider>"
    );
  }
  return context;
}
