import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import ConfirmableButton from "../../../../../layout/ConfirmableButton";
import SelfCheckTaskSpecService from "../../../../../services/SelfCheckTaskSpecService";
import SortableButtons from "../../../../../utils/SortableButtons";

import {
  ChipEnableCommenting,
  ChipTaskSpecTargetType,
  ChipTaskSpecTaskItemSpecCount,
} from "../chips";
import { IconAdd, IconDelete, IconEdit, IconTask } from "../icons";
import { ModalCreateTaskItemSpec, ModalCreateTaskSpec } from "../modals";
import { TaskItemsTypeItemGroup } from "./components";

export function ListItemTaskSpec({
  index,
  jobSpec,
  onDeleteTaskItemSpecIconClick,
  onDeleteTaskSpecIconClick,
  project,
  postCreate,
  postUpdate,
  sortSuccessCallback,
  taskSpec,
}) {
  return (
    <>
      {/*
      For each Task Spec
      * show some information and have the secondary action (to the right) be create a new task spec for the job
      */}
      <ListItem
        key={taskSpec.id}
        secondaryAction={
          <>
            {/* Sortable buttons */}
            <SortableButtons
              index={index}
              items={jobSpec.task_specs}
              currentItem={taskSpec}
              service={SelfCheckTaskSpecService}
              serviceParams={[project.id, jobSpec.id]}
              successCallback={sortSuccessCallback}
            />

            {/* Create task item spec */}
            <ModalCreateTaskItemSpec
              project={project}
              jobSpec={jobSpec}
              taskSpec={taskSpec}
              taskItemSpecId={null}
              postCreate={postCreate}
            >
              <IconButton edge="end">
                <Tooltip
                  title={window.gettext("Create new task item specification")}
                >
                  <IconAdd />
                </Tooltip>
              </IconButton>
            </ModalCreateTaskItemSpec>

            {/* Edit task spec */}
            <ModalCreateTaskSpec
              project={project}
              jobSpec={jobSpec}
              taskSpecId={taskSpec.id}
              postUpdate={postUpdate}
            >
              <IconButton edge="end">
                <IconEdit />
              </IconButton>
            </ModalCreateTaskSpec>

            {/* Delete task spec */}
            <ConfirmableButton>
              <IconButton
                edge="end"
                onClick={() => {
                  onDeleteTaskSpecIconClick(taskSpec);
                }}
              >
                <IconDelete color="error" />
              </IconButton>
            </ConfirmableButton>
          </>
        }
      >
        {/* Icon */}
        <ListItemIcon>
          <IconTask />
        </ListItemIcon>

        {/* Info */}
        <ListItemText>
          <Stack
            sx={{
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {/* Name of task spec */}
            <Typography component="span">{taskSpec.name}</Typography>

            {/* Number of item specs */}
            <ChipTaskSpecTaskItemSpecCount taskSpec={taskSpec} />

            {/* Target type */}
            <ChipTaskSpecTargetType taskSpec={taskSpec} />

            {/* Enable commenting */}
            <ChipEnableCommenting taskSpec={taskSpec} />
          </Stack>
        </ListItemText>
      </ListItem>

      {/* List of 'task item spec's for this 'task spec' */}
      <TaskItemsTypeItemGroup
        jobSpec={jobSpec}
        onDeleteTaskItemSpecIconClick={onDeleteTaskItemSpecIconClick}
        project={project}
        postUpdate={postUpdate}
        sortSuccessCallback={sortSuccessCallback}
        taskSpec={taskSpec}
      />
    </>
  );
}
