import { ENTITY_TYPE_APARTMENT } from "../../../../../entity";

/**
 * Sorts an array of rows based on multiple criteria, with special consideration for rows where 'apartment_number' is undefined:
 * 1. Sorts by the 'address' field, considering the alphabetic portion that comes before any digits.
 * 2. If the alphabetic portions are the same, sorts by the numerical portion of the 'address'.
 * 3. If the numerical portions are also the same, sorts by the last word in the 'address'.
 * 4. If all the parts of the 'address' are the same, sorts by 'apartment_number', prioritizing undefined values over numeric ones.
 * 5. Lastly, if 'apartment_number' is defined and equal, prioritizes rows where 'entity.type' is 'Apartment'.
 *
 * @param {Array} rows - The array of rows to be sorted.
 * @returns {Array} - The sorted array.
 */
export function sortRows(rows) {
  return rows.sort((a, b) => {
    // Extract the digits and the remaining alphabetic parts from the address
    const aDigits = a.address.match(/\d+/g) || [];
    const bDigits = b.address.match(/\d+/g) || [];
    const aN = parseInt(aDigits.join(""), 10);
    const bN = parseInt(bDigits.join(""), 10);

    // Extract the alphabetic part that comes before any digits
    const aFirst = a.address
      .substring(0, a.address.indexOf(aDigits[0]) || a.address.length)
      .trim();
    const bFirst = b.address
      .substring(0, b.address.indexOf(bDigits[0]) || b.address.length)
      .trim();

    // Comparison logic
    if (aFirst === bFirst) {
      if (aN === bN) {
        const aLast = a.address.split(" ").pop();
        const bLast = b.address.split(" ").pop();

        if (aLast === bLast) {
          // Prioritize undefined apartment_number over defined ones
          if (
            typeof a.apartment_number === "undefined" &&
            typeof b.apartment_number !== "undefined"
          )
            return -1;
          if (
            typeof a.apartment_number !== "undefined" &&
            typeof b.apartment_number === "undefined"
          )
            return 1;

          // If apartment numbers are both defined or undefined, sort numerically or by ENTITY_TYPE_APARTMENT
          if (a.apartment_number === b.apartment_number) {
            const aIsApartment = a.entity_type === ENTITY_TYPE_APARTMENT;
            const bIsApartment = b.entity_type === ENTITY_TYPE_APARTMENT;
            return aIsApartment && !bIsApartment
              ? -1
              : !aIsApartment && bIsApartment
              ? 1
              : 0;
          } else {
            // If apartment_number is not undefined, sort numerically
            return (
              parseInt(a.apartment_number, 10) -
              parseInt(b.apartment_number, 10)
            );
          }
        } else {
          return aLast.localeCompare(bLast);
        }
      } else {
        return aN - bN;
      }
    } else {
      return aFirst.localeCompare(bFirst);
    }
  });
}
