import {cloneElement, useEffect, useState} from "react";
import {Box, Modal} from "@mui/material";

export default function BasicModal(props) {
  let {toggleButton} = props

  let [open, setOpen] = useState(false)
  let [width, setWidth] = useState()
  let [style, setStyle] = useState(null)

  useEffect(() => {
    if (!props.width) {
      return
    }

    setWidth(props.width)
  }, [props.width])


  useEffect(() => {
    setOpen(!!props.open)
  }, [props.open])


  useEffect(() => {
    setStyle(
      {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: width || 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      }
    )
  }, [width])


  const handleOnClick = (e) => {
    if (props.hasOwnProperty('setOpen')) {
      props.setOpen(!open)
    }
    setOpen(true)
  }

  const handleClose = (e) => {
    setOpen(false)
    if (props.hasOwnProperty('onClose')) {
      props.onClose()
    }
  }

  return (
    <>
      {toggleButton && (
        <>
          {cloneElement(toggleButton, {onClick: handleOnClick})}
        </>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {props.children}
        </Box>
      </Modal>
    </>
  )
}