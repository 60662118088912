export function isCellEditable(params) {
  if (
    [
      "measurement_inflow_temperature",
      "measurement_return_temperature",
      "measurement_room_temperature",
    ].includes(params.field)
  ) {
    return ["radiator_node", "valve_node"].includes(params?.row?.entity?.type);
  }

  return true;
}
