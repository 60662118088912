import getRowClassName from "../GetRowClassName";
import ValueGetter from "../../ValueGetter";
import Pressure from "../../../../values/Pressure";
import React from "react";
import getColumnWidth from "../GetColumnWidth";
import paramsValueFormatter from "../../ParamsValueFormatter";

export default function PressureDropPerMeterColumnDefinition() {
  return {
    field: 'pressure_drop_per_meter',
    width: getColumnWidth('pressure_drop_per_meter'),
    getRowClassName: getRowClassName,
    headerName: window.gettext('Pressure drop / m'),
    valueGetter: (params => {
      return ValueGetter().getValueFromNode(
        params.row,
        'calculation.pressure_drop_per_meter'
      )
    }),
    valueFormatter: paramsValueFormatter(0),
    renderCell: (params => {
      return params.value === 0 ? <></> : <Pressure value={params.value} unit="auto" additionalSuffix="/m"/>
    }),
  }
}
