import React, {useEffect, useState} from "react";

import store from "../../state";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import SystemService from "../services/SystemService";
import {useNavigate} from "react-router-dom";
import {useSnackbar} from "notistack";
import getUserHasPermission from "../user/getUserHasPermission";
import UserPermission from "../user/UserPermission";

export default function SystemCopyButton(props) {
  let [system, setSystem] = useState(undefined)
  let [open, setOpen] = useState(false)
  let [name, setName] = useState("")
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    // Fetch systems for project
    if (!props.system) {
      return;
    }

    setSystem(props.system)
  }, [props.system])


  const handleClose = function () {
    setOpen(false)
  }

  const handleCopyClick = function () {
    SystemService.copy_system(
      {
        'name': name,
      },
      system.project_id,
      system.id,
    ).then(
      (system_copy) => {
        enqueueSnackbar(
          window.gettext('System copied'),
          {
            'variant': 'success'
          }
        )
        navigate('/projects/' + String(system_copy.project_id) + '/systems/' + String(system_copy.id))
      }
    )
  }

  return (
    <>
      <UserPermission
        permission={UserPermission.permissions.system_copy}
      >
        <Tooltip
          title={window.gettext('Create a copy of this system')}
        >
          <IconButton
            edge="end" aria-label="edit"
            data-test-id={"system-list-item-" + String(system?.id) + "-edit"}
            onClick={() => setOpen(true)}
            disabled={!system}
            to={"/projects/" + props.project_id + "/systems/" + String(system?.id) + "/edit/"}
          >
            <ContentCopyIcon/>
          </IconButton>
        </Tooltip>
      </UserPermission>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {window.gettext('Copy system')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {window.gettext('Make a copy of this system, complete with all nodes and settings the original system has. This new system will not be connected to the original in any way')}
          </DialogContentText>
          <TextField
            autoFocus
            label={window.gettext('Name')}
            required={true}
            type="text"
            fullWidth
            variant="standard"
            onChange={(event) => {
              console.log('event', event)
              setName(event.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
          >
            {window.gettext('Cancel')}
          </Button>
          <Button
            onClick={handleCopyClick}
            variant='contained'
            disabled={!name}
          >
            {window.gettext('Create copy of system')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}