import React, {useEffect, useState} from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import IconButton from "@mui/material/IconButton";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PropTypes from "prop-types";

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';

import { CenteredModal, ConfirmationModal, useModalHandler } from "../../../../layout/modal";
import { Alert, Button, Stack, TextField, Typography } from "@mui/material";

function CheckboxContainer(props) {
  let [clickable, setClickable] = useState(false)
  let [title, setTitle] = useState()

  useEffect(() => {
    if (!props.title) {
      return
    }

    setTitle(props.title)
  }, [props.title])


  useEffect(() => {
    if (props.clickable === null) {
      return
    }

    setClickable(props.clickable)
  }, [props.clickable])


  return (
    <span
      onClick={props.clickable ? props.onClick : undefined}
      style={{
        cursor: clickable ? "pointer" : "default",
      }}
    >
      <IconButton disabled={!props.clickable}>
        {props.children}
      </IconButton>
      
      <span style={{ userSelect: "none" }}>{title}</span>
    </span>
  );
}

function CheckBox(props) {
  let {fontSize} = props
  let [spec, setSpec] = useState(null)
  let [checked, setChecked] = useState(null)
  let [iconName, setIconName] = useState(null)
  let [iconColor, setIconColor] = useState(null)

  useEffect(() => {
    if (!props.spec) {
      return
    }

    setSpec(props.spec)
  }, [props.spec])

  useEffect(() => {
    if (props.checked === null) {
      return
    }

    setChecked(props.checked)
  }, [props.checked])


  useEffect(() => {
    if (checked === null || spec === null) {
      return
    }

    if (checked === true) {
      setIconName(spec.checked_icon)
      setIconColor('#' + spec.checked_icon_color)
    } else {
      setIconName(spec.unchecked_icon)
      setIconColor('#' + spec.unchecked_icon_color)
    }

  }, [spec, checked])

  return (
    <>
      {(iconName !== null && iconColor !== null) && (
        <>
          {iconName === 'checkbox' && (
            <CheckBoxIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'checkbox_outlined' && (
            <CheckBoxOutlineBlankIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'check_circle' && (
            <CheckCircleOutlineIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'check_circle_outlined' && (
            <RadioButtonUncheckedIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'circle_outlined' && (
            <CircleOutlinedIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'cancel' && (
            <CancelIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
          {iconName === 'flag_circle' && (
            <FlagCircleIcon fontSize={fontSize} style={{color: iconColor}}/>
          )}
        </>
      )}
    </>
  )
}

export function ChooseVisitDayModal({ defaultDate, modalHandler, onSubmit }) {
  const [date, setDate] = useState(defaultDate || (new Date()).toJSON().slice(0, 10));
  
  function handleSubmit() {
    onSubmit(date);
    modalHandler.close();
  }

  return (
    <CenteredModal modalHandler={modalHandler}>
      <Stack sx={{ gap: 2 }}>
        <Typography variant="h4">
          {window.gettext("Set new notification date")}
        </Typography>
        <TextField
          variant="standard"
          type="date"
          label={window.gettext("New notification date")}
          InputLabelProps={{ shrink: true }}
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={handleSubmit}
          sx={{ mt: 10 }}
        >
          {window.gettext("Save")}
        </Button>
      </Stack>
    </CenteredModal>
  );
}

export default function JobCheckableCheckbox(props) {
  let [clickable, setClickable] = useState(false)
  let [fontSize, setFontSize] = useState("small")
  let [checked, setChecked] = useState(null)
  let [visible, setVisible] = useState(false)
  let [title, setTitle] = useState(null)
  let [spec, setSpec] = useState(null)

  useEffect(() => {
    if (!props.spec) {
      return
    }

    setSpec(props.spec)
  }, [props.spec])

  useEffect(() => {
    if (!props.fontSize) {
      return
    }

    setFontSize(props.fontSize)
  }, [props.fontSize])

  useEffect(() => {
    if (props.checked === null) {
      return
    }

    setChecked(props.checked)
  }, [props.checked])

  useEffect(() => {
    if (props.clickable === null) {
      return
    }

    setClickable(props.clickable)
  }, [props.clickable])

  useEffect(() => {
    if (props.visible === null) {
      return
    }

    setVisible(props.visible)
  }, [props.visible])

  useEffect(() => {
    if (!props.title) {
      return
    }

    setTitle(props.title)
  }, [props.title])

  const chooseVisitDayModalHandler = useModalHandler();
  const confirmationModalHandler = useModalHandler();

  function handleChooseVisitDayModalSubmit(date) {
    const data = {
      visit_day_date: date,
    }

    chooseVisitDayModalHandler.close();
    props.onClick(data)
  }

  function handleConfirmationModalConfirm() {
    const data = {
      visit_day_date: null,
    }

    confirmationModalHandler.close();
    props.onClick(data)
  }

  function handleClick() {
    if(spec.special_behaviour === "choose_visit_day_for_apartment_on_done") {
      if(!checked) {
        chooseVisitDayModalHandler.open();
      } else {
        confirmationModalHandler.open();
      }
    } else {
      props.onClick()
    }
  }

  return (
    <>
      <>
        {visible !== true ? (
          <>
          </>
        ) : (
          <>
            <CheckboxContainer
              clickable={clickable}
              title={title}
              onClick={handleClick}
            >
              <CheckBox
                checked={checked}
                spec={spec}
                fontSize={fontSize}
              />
            </CheckboxContainer>

            {spec.special_behaviour === "choose_visit_day_for_apartment_on_done" && (
              <>
                <ChooseVisitDayModal
                  modalHandler={chooseVisitDayModalHandler}
                  onSubmit={handleChooseVisitDayModalSubmit}
                /> 
                <ConfirmationModal
                  modalHandler={confirmationModalHandler}
                  onConfirm={handleConfirmationModalConfirm}
                  body={window.gettext("Are you sure you want uncheck") + " " + title}
                />
              </>
            )}
          </>
        )}
      </>
    </>
  )
}

JobCheckableCheckbox.propTypes = {
  fontSize: PropTypes.string,
  clickable: PropTypes.bool,
  checked: PropTypes.bool,
  title: PropTypes.string,
  spec: PropTypes.object,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
}