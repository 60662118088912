import {
  ENTITY_TYPES_POTABLE_WATER,
  ENTITY_TYPES_RADIATOR,
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_SYSTEM,
} from "../../entity";

// Potable Water System
const PWS_DEFAULT_OPTION_VALUES = {};

ENTITY_TYPES_POTABLE_WATER.forEach((entityType) => {
  PWS_DEFAULT_OPTION_VALUES[entityType] = {
    show_entity_information: false,
  };
});

// Radiator System
const RS_DEFAULT_OPTION_VALUES = {};

ENTITY_TYPES_RADIATOR.forEach((entityType) => {
  RS_DEFAULT_OPTION_VALUES[entityType] = {
    show_entity_information: true,
    show_temperature_measurements: true,
    show_nipple_and_kv: true,
  };
});

export const DEFAULT_OPTION_VALUES = {
  // Apartment View
  [ENTITY_TYPE_APARTMENT]: {
    show_entity_information: false,
    show_task_interface_linked: true,
    show_navigation: false,
    show_navigation_details: false,
  },

  //Entrance View
  [ENTITY_TYPE_ENTRANCE]: {
    show_entity_information: false,
    show_task_interface_linked: false,
    show_navigation: true,
    show_navigation_details: false,
  },

  // System View
  [ENTITY_TYPE_SYSTEM]: {
    show_entity_information: false,
    show_task_interface_linked: false,
    show_navigation: true,
    show_navigation_details: false,
  },

  // Potable Water System
  ...PWS_DEFAULT_OPTION_VALUES,

  // Radiator System
  ...RS_DEFAULT_OPTION_VALUES,
};
