import { Alert, Stack } from "@mui/material";

import {
  ENTITY_TYPE_APARTMENT,
  ENTITY_TYPE_ENTRANCE,
  ENTITY_TYPE_RADIATOR,
  ENTITY_TYPE_SYSTEM,
  ENTITY_TYPE_VALVE,
} from "../../entity";
import { useHierarchyContext } from "../context";
import {
  HierarchyEntityAddressApartment,
  HierarchyEntityAddressEntrance,
  HierarchyEntitySystem,
} from "./entity_views";
import {
  HierarchyEntityRSRadiatorNode,
  HierarchyEntityRSValveNode,
} from "./entity_views/radiator_system";

export function HierarchyContent() {
  const { focusEntityData } = useHierarchyContext();

  return (
    <Stack>
      {!!focusEntityData ||
      focusEntityData?.entity?.type === ENTITY_TYPE_SYSTEM ? (
        <>
          {focusEntityData.entity?.type === ENTITY_TYPE_SYSTEM ? (
            <HierarchyEntitySystem />
          ) : focusEntityData.entity?.type === ENTITY_TYPE_ENTRANCE ? (
            <HierarchyEntityAddressEntrance />
          ) : focusEntityData.entity?.type === ENTITY_TYPE_APARTMENT ? (
            <HierarchyEntityAddressApartment />
          ) : focusEntityData.entity?.type === ENTITY_TYPE_RADIATOR ? (
            <HierarchyEntityRSRadiatorNode />
          ) : focusEntityData.entity?.type === ENTITY_TYPE_VALVE ? (
            <HierarchyEntityRSValveNode />
          ) : (
            <Alert severity="warning">
              {"Hierarchy view does not support entities of type"}:{" "}
              {focusEntityData.entity?.type}
            </Alert>
          )}
        </>
      ) : (
        // For system without any task
        <HierarchyEntitySystem />
      )}
    </Stack>
  );
}
