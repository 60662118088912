import BaseService from "./BaseService";

class SystemService extends BaseService {
  nodes = (project_id, system_id) => {
    return this.list(
      ...[
        project_id,
        system_id,
        'nodes',
      ]
    )
  }
  nodes_search = (project_id, system_id, query_params) => {
    return this.list(
      ...[
        project_id,
        system_id,
        'nodes',
        'search',
        query_params,
      ]
    )
  }
  node = (project_id, system_id, node_id) => {
    return this.list(
      ...[
        project_id,
        system_id,
        'nodes',
        node_id,
      ]
    )
  }
  calculations = (project_id, system_id) => {
    return this.list(
      ...[
        project_id,
        system_id,
        'calculation'
      ]
    )
  }
  delete_node = (project_id, system_id, node_id, extra_data) => {
    return this.destroy(
      ...[
        project_id,
        system_id,
        'nodes',
        node_id,
        extra_data
      ]
    )
  }
  update_node = (data, project_id, system_id, node_id) => {
    return this.update(
      data,
      ...[
        project_id,
        system_id,
        'nodes',
        node_id
      ]
    )
  }
  update_descendants = (data, project_id, system_id, node_id, include_self) => {
    return this.update(
      data,
      ...[
        project_id,
        system_id,
        'nodes',
        node_id,
        'children',
        "?include_self=" + (include_self ? "true" : "false")
      ]
    )
  }
  copy_node = (data, project_id, system_id, node_id) => {
    return this.post(
      data,
      ...[
        project_id,
        system_id,
        'nodes',
        node_id,
        'copy'
      ]
    )
  }
  create_item = (project_id, system_id, node_id) => {
    return this.post(
      {},
      ...[
        project_id,
        system_id,
        'nodes',
        node_id,
        'create-item'
      ]
    )
  }
  copy_system = (data, project_id, system_id) => {
    return this.post(
      data,
      ...[
        project_id,
        system_id,
        'copy',
      ]
    )
  }
  tree_history = (project_id, system_id) => {
    return this.list(
      ...[
        project_id,
        system_id,
        'tree',
        'history',
      ]
    )
  }

  listAll = () => {
    return new BaseService('/api/', ['systems']).list()
  }
}

export default new SystemService('/api/',
  [
    'projects',
    'systems',
  ]
)