import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import ProjectMemberService from "../services/ProjectMemberService";
import UserService from "../services/UserService";
import MenuItem from "@mui/material/MenuItem";
import User from "../user/User";
import {Button, Grid, InputLabel, Select} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

export default function AddProjectMember({project, memberships, onAdded}) {
  const {enqueueSnackbar} = useSnackbar();
  let [projectMemberships, setProjectMemberships] = useState([])
  let [users, setUsers] = useState([])
  let [projectMembershipUserIds, setProjectMembershipUserIds] = useState([])
  let [selectedUser, setSelectedUser] = useState("")
  const fetchUsers = () => {
    UserService.list().then(
      setUsers
    )
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    setProjectMemberships(memberships)
  }, [memberships])

  const handleChange = (e) => {
    setSelectedUser(e.target.value)
  }

  useEffect(() => {
    setProjectMembershipUserIds(
      projectMemberships.map((membership) => {
        return membership.user_data.id
      })
    )
  }, [projectMemberships])

  const handleAddButtonClick = () => {
    ProjectMemberService.post(
      {
        'user': selectedUser,
      },
      project.id
    ).then(
      (success) => {
        if (onAdded !== undefined) {
          onAdded()
        }
        setSelectedUser("")

        enqueueSnackbar(
          window.gettext('Member added to project'),
          {
            variant: 'success',
          }
        )
      }
    )
  }

  return (
    <>
      <Grid
        container
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={4}
      >
        <Grid item xs={6}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedUser}
            label={window.gettext('Select user')}
            onChange={handleChange}
            fullWidth
          >
            {users.map(user => {
              return (
                <MenuItem
                  key={'user_' + user.id}
                  value={user.id}
                  disabled={projectMembershipUserIds?.includes(user.id)}
                >
                  <User user={user}/>
                </MenuItem>
              )
            })
            }
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Button
            startIcon={<AddIcon/>}
            variant={"contained"}
            color={"success"}
            onClick={handleAddButtonClick}
            disabled={!selectedUser}
          >
            {window.gettext('Add user to project')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}