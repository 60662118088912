export class SelfCheckOptions {
  ignore_entrances = false
  ignore_apartments = false
  ignore_rooms = true
  ignore_nodes = false

  ignore_potable_water_entities=false
  ignore_bathroom_faucets=false
  ignore_kitchen_faucets=false
  ignore_showers=false
  ignore_toilets=false

  apartment_visit_day = null

  sorting_algorithm = null

  iconSize = 'large'

  constructor(
    ignore_rooms,
    sorting_algorithm,
    notice_feedback,
  ) {
    this.ignore_rooms = ignore_rooms === undefined ? true : ignore_rooms
    this.sorting_algorithm = sorting_algorithm || 'top_to_bottom_and_left_to_right'
    this.notice_feedback_task_done_changed = notice_feedback === undefined ? false : notice_feedback
  }

  get_query_params = (entityType, entityId) => {
    let params = [
      'apartment_sorting_algorithm=' + this.sorting_algorithm,
    ]

    if(this.apartment_visit_day) {
      params.push('apartment_visit_day=' + this.apartment_visit_day)
    }

    if (entityType && entityId) {
      params.push('start_point_' + entityType + '=' + String(entityId))
    }

    const ignore_params = [
      ['ignore_entrances', this.ignore_entrances],
      ['ignore_apartments', this.ignore_apartments],
      ['ignore_rooms', this.ignore_rooms],
      // Radiator
      ['ignore_nodes', this.ignore_nodes],
      // Potable water
      ['ignore_potable_water_entities', this.ignore_potable_water_entities],
      ['ignore_bathroom_faucets', this.ignore_bathroom_faucets],
      ['ignore_kitchen_faucets', this.ignore_kitchen_faucets],
      ['ignore_showers', this.ignore_showers],
      ['ignore_toilets', this.ignore_toilets],
    ].map(([key, value]) => {
      if (!value) {
        return null
      }
      return key + '=' + (value ? 'true' : 'false')
    }).filter((value) => (value !== null))

    params = [
      ...params,
      ...ignore_params
    ]

    return '?' + params.join('&')
  }
}