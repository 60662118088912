import getTaskItemSpecFieldId from "../../utils/getTaskItemSpecFieldId";
import getTaskSpecFieldId from "../../utils/taskSpecFieldId";

function getTaskSpecGroupId(taskSpec) {
  return taskSpec.id + "_" + taskSpec.name;
}

export function getColumnGroupDefinitions(job) {
  // Contains grouping of the task items by their spec.
  return [
    ...[
      {
        groupId: "general",
        headerName: window.gettext("General"),
        children: [
          {
            field: "tools",
          },
          {
            field: "entity_type",
          },
          {
            field: "label",
          },
          {
            field: "room",
          },
          {
            field: "radiator",
          },
          {
            field: "address_label",
          },
          {
            field: "local_apartment_number",
          },
          {
            field: "surveying_apartment_number",
          },
          {
            field: "note",
          },
        ],
      },
    ],
    ...[
      {
        groupId: "frozen_calculations",
        headerName: window.gettext("Calculation"),
        children: [
          {
            field: "flow",
          },
          {
            field: "kv",
          },
          /*
          Temp remove, might have later
          {
            'field': 'effect',
          },
           */
          {
            field: "nipple",
          },
        ],
      },
    ],
    ...[
      {
        groupId: "installed_values",
        headerName: window.gettext("Installed"),
        children: [
          {
            field: "installed_kv",
          },
          {
            field: "installed_nipple",
          },
        ],
      },
    ],
    // Task and task-item columns
    ...job.task_specs.map((task_spec) => {
      return {
        groupId: getTaskSpecGroupId(task_spec),
        headerName: task_spec.name,
        description: window.gettext("Task"),
        children: [
          ...job.task_item_specs
            .filter(
              (task_item_spec) => task_item_spec.task_spec_id === task_spec.id
            )
            .map((task_item_spec) => {
              return {
                field: getTaskItemSpecFieldId(task_item_spec),
              };
            }),
          ...[
            {
              field: getTaskSpecFieldId(task_spec, "done"),
            },
          ],
        ],
      };
    }),
    ...[
      {
        groupId: "measurement",
        headerName: window.gettext("Measurement"),
        children: [
          {
            field: "measurement_inflow_temperature",
          },
          {
            field: "measurement_return_temperature",
          },
          {
            field: "measurement_room_temperature",
          },
          {
            field: "measurement_comment",
          },
        ],
      },
    ],
  ];
}
