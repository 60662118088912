import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { IconButton, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { EntitySiblingsService } from "../../../../../../services";

import { LayoutCard } from "../../../../../../../layout";
import { useHierarchyContext } from "../../context";

export function HierarchyEntitySiblings({ children }) {
  const {
    focusEntityData,
    nextEntity,
    setNextEntity,
    setEntity,
    system,
    project,
    fetchOptions,
  } = useHierarchyContext();

  const [previousEntity, setPreviousEntity] = useState(null);

  const setSiblingEntities = useCallback(({ next, previous }) => {
    setPreviousEntity(
      !previous
        ? previous
        : { id: previous.entity_id, type: previous.entity_type }
    );

    setNextEntity(
      !next ? next : { id: next.entity_id, type: next.entity_type }
    );
  }, []);

  useEffect(() => {
    if (!focusEntityData?.entity || !project || !system) {
      if (previousEntity !== null) {
        setPreviousEntity(null);
      }

      if (nextEntity !== null) {
        setNextEntity(null);
      }

      return;
    }

    EntitySiblingsService.fetch(
      project.id,
      system.id,
      focusEntityData.entity.type,
      focusEntityData.entity.id,
      fetchOptions.get_query_params()
    ).then(setSiblingEntities);
  }, [focusEntityData?.entity, project, system]);

  function handleNextEntityClick() {
    setEntity(nextEntity);
  }

  function handlePreviousEntityClick() {
    setEntity(previousEntity);
  }

  return (
    <LayoutCard>
      <Stack
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: 34,
        }}
      >
        <Stack
          sx={{
            width: 50,
            alignItems: "flex-start",
          }}
        >
          {previousEntity !== null && (
            <IconButton color="primary" onClick={handlePreviousEntityClick}>
              <ArrowCircleLeftIcon
                sx={{
                  fontSize: 24,
                }}
              />
            </IconButton>
          )}
        </Stack>

        {children}

        <Stack
          sx={{
            width: 50,
            alignItems: "flex-end",
          }}
        >
          {nextEntity !== null && (
            <IconButton color="primary" onClick={handleNextEntityClick}>
              <ArrowCircleRightIcon
                sx={{
                  fontSize: 24,
                }}
              />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </LayoutCard>
  );
}
