import { useEffect, useState } from "react"
import { Stack } from "@mui/material"

import { Form, FormFactory } from "../form/form_factory"
import ChoicesService from "../services/ChoicesService"


export default function SystemForm(props) {
  const [systemChoices, setSystemChoices] = useState(null)
  const [formSections, setFormSections] = useState([])

  const submit_button_text = props.submitButtonText || window.gettext("Create system")

  useEffect(() => {
    ChoicesService.systemChoices().then(setSystemChoices)
  }, [])

  useEffect(() => {
    if (!systemChoices) {
      return;
    }

    const form_factory = new FormFactory(props.instance);

    setFormSections([
      form_factory.section(window.gettext("System information"), [
        // Name
        form_factory.textField("name", window.gettext("Name"), true),
        // Type
        form_factory.textField(
          "type",
          window.gettext("Type"),
          true,
          systemChoices.type__default,
          {
            choices: systemChoices.type,
          }
        ),
        // Note
        form_factory.textArea("note", window.gettext("Note"))
      ]),
      form_factory.section(window.gettext("Pump information"), [
        // Pump Pressure
        form_factory.integerField(
          "pump_pressure",
          window.gettext("Pump pressure"),
          false,
          null
        ),
        // Pump Type
        form_factory.textField("pump_type", window.gettext("Pump type")),
        // Pump Operation Setting
        form_factory.textField(
          "pump_operation_setting",
          window.gettext("Pump operation setting")
        ),
        form_factory.group(
          "",
          [
            // Outflow Temperature
            form_factory.integerField(
              "outflow_temperature",
              window.gettext("Outflow temperature"),
              false,
              null
            ),
            // Return Temperature
            form_factory.textField(
              "return_temperature",
              window.gettext("Return temperature"),
              false,
              null
            ),
          ],
          {
            direction: "row",
            split: [50, 50]
          }
        ),
        // Additional Resistance
        form_factory.integerField(
          "additional_resistance",
          window.gettext("Additional resistance"),
          false,
          0
        ),
      ]),
      form_factory.section(window.gettext("Other information"), [
        // Radiator Valve KVS Value
        form_factory.textField(
          "radiator_valve_kvs_value",
          window.gettext("Radiator valve KVS value")
        ),
        // Room Temperature
        form_factory.integerField(
          "room_temperature",
          window.gettext("Desired room temperature"),
          false,
          null
        ),
      ]),
    ]);
  }, [props.instance, systemChoices]);

  return (
    <Stack
      sx={{
        maxWidth: 600,
      }}
    >
      {systemChoices === null ? (
        <>{window.gettext("Loading")}...</>
      ) : (
        <Form
          formTestId="system-form"
          header={props.header}
          formSections={formSections}
          submitButtonText={submit_button_text}
          onSubmit={props.onSubmit}
        />
      )}
    </Stack>
  );
}