import EventExecutor from "./EventExecutor";
import EventResult from "./EventResult";
import GetAdjacentColumn from "./utils/GetAdjacentColumn";

export default function TabEvent() {
  return new EventExecutor(
    'tab',
    (eventData) => {
      return eventData.event.keyCode === 9 && eventData.event.shiftKey === false
    },
    (eventData) => {
      // Get the next plugin
      const adjacentColumn = GetAdjacentColumn(eventData, 1)

      if (adjacentColumn !== undefined) {
        eventData.apiRef.current.setCellFocus(eventData.params.id, adjacentColumn.field)
      }

      return EventResult(true)
    }
  )
}