import {LayoutCard, LayoutView} from "../../../layout";
import {useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import StatisticsService from "../../../components/services/StatisticsService";
import Loading from "../../../components/layout/Loading";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

import dayjs from 'dayjs';

import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Checkbox, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

export default function StatisticsRadiatorsView() {
  // Chart data
  const [chartData, setChartData] = useState(null)

  // Total number
  const [totalNumber, setTotalNumber] = useState(null)

  const [radiatorStatistics, setRadiatorStatistics] = useState(null)
  // Get parameters from URL Query Params
  const [searchParams, setSearchParams] = useSearchParams()

  const timeGroupingOptions = {
    'day': window.gettext('Day'),
    'month': window.gettext('Month'),
    'year': window.gettext('Year'),
  }

  useEffect(() => {
    // Make request, here we set the default values, the form will be updated.
    StatisticsService.radiators(
      searchParams.get('users') === null ? null : searchParams.get('users').split(','),
      searchParams.get('start_date') || dayjs().format('YYYY-MM-01'),
      searchParams.get('end_date'),
      searchParams.get('time_grouping') || 'month',
    ).then(
      setRadiatorStatistics
    )
  }, [searchParams]);

  useEffect(() => {
    if (!radiatorStatistics) {
      return
    }

    changeSearchParams(
      {
        'time_grouping': radiatorStatistics['query']['time_grouping'],
        'start_date': radiatorStatistics['query']['start_date'],
        'end_date': radiatorStatistics['query']['end_date'],
      }
    )

    let newTotalNumber = 0

    setChartData(
      radiatorStatistics['result'].map(
        (radiatorStatistic) => {
          newTotalNumber += radiatorStatistic.count

          const time = dayjs(radiatorStatistic.time)

          let label = time.format(
            {
              'day': 'YYYY-MM-DD',
              'month': 'YYYY-MM',
              'year': 'YYYY',
            }[radiatorStatistics.query.time_grouping]
          )

          return {
            'label': label,
            'count': radiatorStatistic.count,
          }
        }
      )
    )

    setTotalNumber(newTotalNumber)
  }, [radiatorStatistics]);

  const changeSearchParams = (data) => {
    /**
     * data contains key and value
     * */

    let newValue = {}

    const previousValues = Object.fromEntries(
      ['start_date', 'end_date', 'users', 'time_grouping'].map(
        (searchParamsKey) => {
          if (data[searchParamsKey] !== undefined) {
            // The data we pass in has the key, then we shall not use the URL search params
            // instead we will use the data we passed in later when we combine the two data objects.
            newValue[searchParamsKey] = data[searchParamsKey]
            return undefined
          }

          const searchParamValue = searchParams.get(searchParamsKey)

          if (!searchParamValue) {
            return undefined
          }

          return [searchParamsKey, searchParamValue]
        }
      ).filter(
        (value) => {
          return value !== undefined
        }
      )
    )


    setSearchParams(
      {
        ...previousValues,
        ...newValue,
      }
    )
  }

  return (
    <LayoutView
      header={window.gettext("Radiator statistics")}
    >
      <LayoutCard
        title={window.gettext("Filters and options")}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={window.gettext('Start date')}
            name={'start_date'}
            slotProps={
              {
                field: {
                  clearable: true,
                }
              }
            }
            value={(searchParams.get('start_date') || 'null' ) === 'null' ? null : dayjs(searchParams.get('start_date'))}
            format={'YYYY-MM-DD'}
            onChange={(value) => {
              changeSearchParams(
                {
                  'start_date': value === null ? 'null' : dayjs(value).format('YYYY-MM-DD')
                }
              )
            }}
          />

          <DatePicker
            label={window.gettext('End date')}
            name={'end_date'}
            slotProps={
              {
                field: {
                  clearable: true,
                }
              }
            }
            value={searchParams.get('end_date') === null ? null : dayjs(searchParams.get('end_date'))}
            format={'YYYY-MM-DD'}
            onChange={(value) => {
              changeSearchParams(
                {
                  'end_date': value === null ? '' : dayjs(value).format('YYYY-MM-DD')
                }
              )
            }}
          />
        </LocalizationProvider>

        <Select
          variant="standard"
          displayEmpty
          value={searchParams.get('time_grouping') || 'month'}
          label={window.gettext('Time grouping')}
          onChange={(event) => {
            changeSearchParams(
              {
                'time_grouping': event.target.value
              }
            )
          }}
        >
          {Object.entries(timeGroupingOptions).map(([key, value]) => {
            return (
              <MenuItem key={"select_option_" + key} value={key}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </LayoutCard>
      <LayoutCard
        title={window.gettext('Graph')}
      >
        <Loading notNull={[radiatorStatistics, chartData]}>
          <ResponsiveContainer
            width={'100%'} height={500}
          >
            {(!chartData || chartData.length === 0) ? (
              <>
                no data.
              </>
            ) : (
              <LineChart
                data={chartData}
              >
                <CartesianGrid strokeDasharray="3"/>
                <XAxis dataKey="label" height={80}/>
                <YAxis orientation={'left'} width={90} interval={1}/>
                <Line type="monotone" dataKey="count" label={window.gettext('Quantity')}/>
                <Tooltip
                  formatter={
                    (value, name, props) => {
                      let formattedName = name

                      if (name === 'count') {
                        formattedName = window.gettext('Quantity')
                      }

                      return [
                        value,
                        formattedName,
                        props
                      ]
                    }
                  }
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </Loading>
      </LayoutCard>
      <LayoutCard
        title={window.gettext('Numbers')}
        noPadding
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  {window.gettext('Total number')}
                </TableCell>
                <TableCell>
                  {totalNumber}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </LayoutCard>
    </LayoutView>
  )
}
