import { useEffect, useReducer, useState } from "react";
import {Autocomplete, Box, Button, FormGroup, Grid, TextField, Typography} from "@mui/material";

import CreateCustomerModal from "../customer/CreateCustomerModal";
import UserService from "../services/UserService";
import CustomerService from "../services/CustomerService";
import getUserRepresentation from "../user/getUserRepresentation";
import { copyObject, removeKeys, replaceValues, trimStringValues } from "../services/BaseService";
import getCustomerRepresentation from "../customer/getCustomerRepresentation";

// To add a new Form Field:
// 1. Add KEY and init-value to newProjectInitValues.
//    ex: new_field_value : ""
// 2. Add CASE in reducer-function.
//    ex: changed_new_field_value
// 3. Add field in ProjectForm's return:
//    value = {KEY}
//    onChange = {(e) => dispatch({type:"CASE", value:e.target.value})}

// For formatting that should be applied live do this in reducer-function.
// ex: toUpperCase
// For formatting that should be applied on submit do this in ProjectForm's handleSubmit.
// ex: trim, if this is done live then the user will not be allowed to add spacing between words.

// All fields will be trimmed on submit.

const REPLACE_EMPTY_WITH_NULL_ON_SUBMIT = [
  "code",
  "sales_person_id",
  "chief_designer_id",
  //  "budgeted_time_construction",
  //  "budgeted_time_installation",
  "desired_indoor_temperature",
  "customer_id",
  "number_of_apartments",
  "number_of_radiators",
  "number_of_substations",
]

const EXCLUDE_ON_SUBMIT = [
  "chief_designer",
  "sales_person",
  "customer"
]

const PROJECT_INIT_VALUES = {
  /* -------------------- Project Information  --------------------- */
  code: "",
  name: "",
  // budgeted_time_construction: "",
  // budgeted_time_installation: "",
  desired_indoor_temperature: "",
  desired_project_start: "",

  /* -------------------- Other Information  ----------------------- */
  note: "",
  // plan_base_client: "",
  // plan_base_municipality: "",
  // plan_base_server: "",


  /* -------------------- Contact Information  --------------------- */
  customer: null,
  customer_id: "",
  property_manager: "",
  city: "",
  zip_code: "",
  street_address: "",
  municipality: "",
  contact_person_sales_name: "",
  contact_person_sales_email: "",
  contact_person_sales_phone: "",
  contact_person_installation_name: "",
  contact_person_installation_phone: "",

  /* -------------------- Building Information  -------------------- */
  property_designation: "",
  entry_code: "",
  key_management: "",
  parking_info: "",
  notification_info: "",
  place_of_establishment: "",
  building_addresses: "",
  substation_addresses: "",
  number_of_apartments: "",
  number_of_radiators: "",
  number_of_substations: "",

  /* -------------------- User connections -------------------- */
  sales_person: null,
  sales_person_id: "",
  chief_designer: null,
  chief_designer_id: "",
}

function reducer(state, action) {
  switch(action.type) {
    /* -------------------- Project Information  --------------------- */
    case "changed_code":
      return {...state, code: action.value.toUpperCase()};
    case "changed_name":
      return {...state, name: action.value};
    case "changed_sales_person":
      // Update sales_person and sales_person_id.
      return {
        ...state,
        sales_person: action.value,
        sales_person_id: action.value
          ? action.value.id.toString()
          : PROJECT_INIT_VALUES.sales_person_id,
      };
    case "changed_chief_designer":
      return {
        ...state,
        chief_designer: action.value,
        chief_designer_id: action.value
          ? action.value.id.toString()
          : PROJECT_INIT_VALUES.chief_designer_id,
      }
    // case "changed_budgeted_time_construction":
    //   return {...state, budgeted_time_construction: action.value};
    // case "changed_budgeted_time_installation":
    //   return {...state, budgeted_time_installation: action.value};
    case "changed_desired_indoor_temperature":
      return {...state, desired_indoor_temperature: action.value};
    case "changed_desired_project_start":
      return {...state, desired_project_start: action.value};

    /* -------------------- Other Information  ----------------------- */
    case "changed_note":
      return {...state, note: action.value};
    // case "changed_plan_base_client":
    //   return {...state, plan_base_client: action.value};
    // case "changed_plan_base_municipality":
    //   return {...state, plan_base_municipality: action.value};
    // case "changed_plan_base_server":
    //   return {...state, plan_base_server: action.value};

    /* -------------------- Contact Information  --------------------- */
    case "changed_customer":
      // Update customer and customer_id.
      return {
        ...state,
        customer: action.value,
        customer_id: action.value
          ? action.value.id.toString()
          : PROJECT_INIT_VALUES.customer_id
      };
    case "changed_property_manager":
      return {...state, property_manager: action.value};
    case "changed_city":
      return {...state, city: action.value};
    case "changed_zip_code":
      // Only allow numbers and spaces.
      return {...state, zip_code: action.value.replace(/[^\d ]/g, '')};
    case "changed_street_address":
      return {...state, street_address: action.value};
    case "changed_municipality":
      return {...state, municipality: action.value};
    case "changed_contact_person_sales_name":
      return {...state, contact_person_sales_name: action.value};
    case "changed_contact_person_sales_email":
      return {...state, contact_person_sales_email: action.value};
    case "changed_contact_person_sales_phone":
      // Only allow +, numbers and spaces.
      return {...state, contact_person_sales_phone: action.value.replace(/[^\d+ ]/g, '')};
    case "changed_contact_person_installation_name":
      return {...state, contact_person_installation_name: action.value};
    case "changed_contact_person_installation_phone":
      // Only allow +, numbers and spaces.
      return {...state, contact_person_installation_phone: action.value.replace(/[^\d+ ]/g, '')};
    
    /* -------------------- Building Information  -------------------- */
    case "changed_property_designation":
      return {...state, property_designation: action.value};
    case "changed_entry_code":
      return {...state, entry_code: action.value};
    case "changed_key_management":
      return {...state, key_management: action.value};
    case "changed_parking_info":
      return {...state, parking_info: action.value};
    case "changed_notification_info":
      return {...state, notification_info: action.value};
    case "changed_place_of_establishment":
      return {...state, place_of_establishment: action.value};
    case "changed_building_addresses":
      return {...state, building_addresses: action.value};
    case "changed_substation_addresses":
      return {...state, substation_addresses: action.value};
    case "changed_number_of_apartments":
      return {...state, number_of_apartments: action.value};
    case "changed_number_of_radiators":
      return {...state, number_of_radiators: action.value};
    case "changed_number_of_substations":
      return {...state, number_of_substations: action.value};
  }
  throw Error('Unknown action: ' + action.type);
}

export default function ProjectForm({onSubmit, initValues, submitButtonText}) {
  // Use initValues and fill missing values with PROJECT_INIT_VALUES-template
  const [form, dispatch] = useReducer(reducer, {...PROJECT_INIT_VALUES, ...initValues});

  const [users, setUsers] = useState([])

  const [customers, setCustomers] = useState([]);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);
  const [customerInputValue, setCustomerInputValue] = useState("");

  function fetchUsers() {
    UserService.list().then(setUsers)
  }

  function fetchCustomers() {
    // TODO: Fetch only values for id and name.
    CustomerService.fetch().then(setCustomers)
  }

  useEffect(() => {
    // Fetch users and customers
    fetchUsers();
    fetchCustomers();
  }, [])

  function showCustomerModal() {
    setCustomerModalOpen(true);
  }

  function hideCustomerModal() {
    setCustomerModalOpen(false);
  }

  function handleOnCreateCustomer(customer) {
    fetchCustomers();
    dispatch({type:"changed_customer", value:customer});
    hideCustomerModal();
  }

  function handleSubmit(event) {
    event.preventDefault();

    const values = copyObject(form, Object.keys(PROJECT_INIT_VALUES));
    removeKeys(values, EXCLUDE_ON_SUBMIT)
    trimStringValues(values);
    replaceValues(values, "", null, REPLACE_EMPTY_WITH_NULL_ON_SUBMIT);

    onSubmit(values);
  }

  // TODO: Fix responsiveness, remove minWidth?. Change xs to 12 and mb to 6?
  return (
    <>
      <Grid
        container
        component="form"
        onSubmit={handleSubmit}
        spacing={4}
        sx={{width:"100%"}}
        columns={{ xs: 12 }}
      >
        {/* -------------------- Project Information  --------------------- */}
        <Grid item xs={6} sx={{minWidth:600}}>
          <FormGroup sx={{gap:1}}>
            <Typography variant="h4">{window.gettext("Project information")}</Typography>
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Number ----------------------------------- */}
              <TextField 
                variant="standard"
                label={window.gettext("Project code")}
                inputProps={{ maxLength: 16 }}
                sx={{width:80}} 
                value={form.code}
                onChange={(e) => dispatch({type:"changed_code", value:e.target.value})}
              />
              {/* -------------- Name ------------------------------------- */}
              <TextField
                required
                variant="standard"
                label={window.gettext("Name")}
                inputProps={{ maxLength: 120 }}
                sx={{flex:1}}
                value={form.name}
                onChange={(e) => dispatch({type:"changed_name", value:e.target.value})}
              />
            </FormGroup>
            {/* ---------------- Sales person ----------------------------- */}
            <Autocomplete
              variant="standard"
              options={users}
              getOptionLabel={(user) => getUserRepresentation(user)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={
                (params) => <TextField {...params} label={window.gettext('Sale person')} variant="standard" />
              }
              value={form.sales_person}
              onChange={(e, value) => {dispatch({type:"changed_sales_person", value})}}
            />

            <Autocomplete
              variant="standard"
              options={users}
              getOptionLabel={(user) => getUserRepresentation(user)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={
                (params) => <TextField {...params} label={window.gettext('Chief designer')} variant="standard" />
              }
              value={form.chief_designer}
              onChange={(e, value) => {dispatch({type:"changed_chief_designer", value})}}
            />
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Desired indoor temperature --------------- */}
              <TextField
                variant="standard"
                type="number"
                label={window.gettext('Desired indoor temperature')}
                sx={{flex:1}}
                value={form.desired_indoor_temperature}
                onChange={(e) => dispatch({type:"changed_desired_indoor_temperature", value:e.target.value})}
              />
              {/* -------------- Desired project start -------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Desired project start')}
                inputProps={{ maxLength: 64 }}
                sx={{flex:1}}
                value={form.desired_project_start}
                onChange={(e) => dispatch({type:"changed_desired_project_start", value:e.target.value})}
              />
            </FormGroup>
          </FormGroup>
        </Grid>
        {/* -------------------- Other Information  ----------------------- */}
        <Grid item xs={6} sx={{ minWidth: 600, height: 224.7 }}>
          <FormGroup sx={{ gap: 1, height: "100%" }}>
            <Typography variant="h4">
              {window.gettext("Other information")}
            </Typography>
            <TextField
              label={window.gettext("Note")}
              multiline
              rows={5}
              sx={{mt: 1.4}}
              value={form.note}
              onChange={(e) =>
                dispatch({
                  type: "changed_note",
                  value: e.target.value,
                })
              }
            />
          </FormGroup>
        </Grid>
        {/* -------------------- Contact Information  --------------------- */}
        <Grid item xs={6} sx={{minWidth:600}}>
          <FormGroup sx={{gap:1}}>
            <Typography variant="h4">{window.gettext('Contact information')}</Typography>
            {/* -------------- Customer --------------------------------- */}
            <Autocomplete
              variant="standard"
              options={customers}
              getOptionLabel={(customer) => getCustomerRepresentation(customer)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              noOptionsText={
                <Box sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                  <Typography variant="p">{window.gettext('Can\'t find customer')}: {customerInputValue}</Typography>
                  <Button variant="outlined" onClick={showCustomerModal}>{window.gettext('Add')}</Button>
                </Box>
              }
              renderInput={
                (params) => <TextField {...params} label={window.gettext('Customer')} variant="standard" />
              }
              inputValue={customerInputValue}
              onInputChange={(e, value) => setCustomerInputValue(value)}
              value={form.customer}
              onChange={(e, value) => {dispatch({type:"changed_customer", value})}}
            />
            {/* ---------------- Property manager --------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Property manager')}
              value={form.property_manager}
              onChange={(e) => dispatch({type:"changed_property_manager", value:e.target.value})}
            />
            <FormGroup row sx={{gap:2}}>
              {/* -------------- City ------------------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('City')}
                inputProps={{ maxLength: 128 }}
                sx={{flex:1}}
                value={form.city}
                onChange={(e) => dispatch({type:"changed_city", value:e.target.value})}
              />
              {/* -------------- ZIP code --------------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('ZIP code')}
                inputProps={{ maxLength: 10 }}
                sx={{flex:1}}
                value={form.zip_code}
                onChange={(e) => dispatch({type:"changed_zip_code", value:e.target.value})}
              />
            </FormGroup>
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Street address --------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Street address')}
                inputProps={{ maxLength: 64 }}
                sx={{flex:1}}
                value={form.street_address}
                onChange={(e) => dispatch({type:"changed_street_address", value:e.target.value})}
              />
              {/* -------------- Municipality ----------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Municipality')}
                inputProps={{ maxLength: 128 }}
                sx={{flex:1}}
                value={form.municipality}
                onChange={(e) => dispatch({type:"changed_municipality", value:e.target.value})}
              />
            </FormGroup>
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Contact person sales name ---------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Contact person sales')}
                inputProps={{ maxLength: 64 }}
                sx={{width:190}}
                value={form.contact_person_sales_name}
                onChange={(e) => dispatch({type:"changed_contact_person_sales_name", value:e.target.value})}
              />
              {/* -------------- Contact person sales mail ---------------- */}
              <TextField
                variant="standard"
                type="email"
                label={window.gettext('Email')}
                inputProps={{ maxLength: 256 }}
                sx={{flex:1}}
                value={form.contact_person_sales_email}
                onChange={(e) => dispatch({type:"changed_contact_person_sales_email", value:e.target.value})}
              />
              {/* -------------- Contact person sales phone --------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Phone')}
                inputProps={{ maxLength: 24 }}
                sx={{width: 135}}
                value={form.contact_person_sales_phone}
                onChange={(e) => dispatch({type:"changed_contact_person_sales_phone", value:e.target.value})}
              />
            </FormGroup>
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Contact person installation name --------- */}
              <TextField
                variant="standard"
                label={window.gettext('Contact person installation')}
                inputProps={{ maxLength: 64 }}
                sx={{flex:1}}
                value={form.contact_person_installation_name}
                onChange={(e) => dispatch({type:"changed_contact_person_installation_name", value:e.target.value})}
              />
              {/* -------------- Contact person installation phone -------- */}
              <TextField
                variant="standard"
                label={window.gettext('Phone')}
                inputProps={{ maxLength: 24 }}
                sx={{flex:1}}
                value={form.contact_person_installation_phone}
                onChange={(e) => dispatch({type:"changed_contact_person_installation_phone", value:e.target.value})}
              />
            </FormGroup>
          </FormGroup>
        </Grid>
        {/* -------------------- Building Information  -------------------- */}
        <Grid item xs={6} sx={{minWidth:600}}>
          <FormGroup sx={{gap:1}}>
            <Typography variant="h4">{window.gettext('Building information')}</Typography>
            {/* ---------------- Property designation --------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Property designation')}
              inputProps={{ maxLength: 64 }}
              value={form.property_designation}
              onChange={(e) => dispatch({type:"changed_property_designation", value:e.target.value})}
            />
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Entry code ------------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Entry code')}
                inputProps={{ maxLength: 64 }}
                sx={{flex:1}}
                value={form.entry_code}
                onChange={(e) => dispatch({type:"changed_entry_code", value:e.target.value})}
              />
              {/* -------------- Key management --------------------------- */}
              <TextField
                variant="standard"
                label={window.gettext('Key management')}
                inputProps={{ maxLength: 64 }}
                sx={{flex:3}}
                value={form.key_management}
                onChange={(e) => dispatch({type:"changed_key_management", value:e.target.value})}
              />
            </FormGroup>
            {/* ---------------- Parking info ----------------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Parking information')}
              value={form.parking_info}
              onChange={(e) => dispatch({type:"changed_parking_info", value:e.target.value})}
            />
            {/* ---------------- Notification info ------------------------ */}
            <TextField
              variant="standard"
              label={window.gettext('Notification info')}
              value={form.notification_info}
              onChange={(e) => dispatch({type:"changed_notification_info", value:e.target.value})}
            />
            {/* ---------------- Place of establishment ------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Place of establishment')}
              value={form.place_of_establishment}
              onChange={(e) => dispatch({type:"changed_place_of_establishment", value:e.target.value})}
            />
            {/* ---------------- Building addresses ----------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Building addresses')}
              multiline
              rows={1}
              value={form.building_addresses}
              onChange={(e) => dispatch({type:"changed_building_addresses", value:e.target.value})}
            />
            {/* ---------------- Substation addresses --------------------- */}
            <TextField
              variant="standard"
              label={window.gettext('Substation addresses')}
              multiline
              rows={1}
              value={form.substation_addresses}
              onChange={(e) => dispatch({type:"changed_substation_addresses", value:e.target.value})}
            />
            <FormGroup row sx={{gap:2}}>
              {/* -------------- Number of apartments --------------------- */}
              <TextField
                variant="standard"
                type="number"
                label={window.gettext('Number of apartments')}
                sx={{flex:1}}
                value={form.number_of_apartments}
                onChange={(e) => dispatch({type:"changed_number_of_apartments", value:e.target.value})}
              />
              {/* -------------- Number of radiators ---------------------- */}
              <TextField 
                variant="standard"
                type="number"
                label={window.gettext('Number of radiators')}
                sx={{flex:1}}
                value={form.number_of_radiators}
                onChange={(e) => dispatch({type:"changed_number_of_radiators", value:e.target.value})}
              />
              {/* -------------- Number of substations -------------------- */}
              <TextField 
                variant="standard"
                type="number"
                label={window.gettext('Number of substations')}
                sx={{flex:1}}
                value={form.number_of_substations}
                onChange={(e) => dispatch({type:"changed_number_of_substations", value:e.target.value})}
              />
            </FormGroup>
          </FormGroup>
        </Grid>
        <Grid item xs={12} sx={{display:"flex", justifyContent:"end"}}>
          <Button variant="contained" type="submit">{submitButtonText || window.gettext("Create project")}</Button>
        </Grid>
      </Grid>
      <CreateCustomerModal
        open={customerModalOpen}
        onClose={hideCustomerModal}
        onCreate={handleOnCreateCustomer}
        initValues={{name:customerInputValue}}
      />
    </>
  )
}