import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

import getEntityStatus from "../../../../../utils/getEntityStatus";

import {
  EntityCommentStatusIcon,
  EntityDoneStatusIcon,
  EntityFlagStatusIcon,
  EntityMeasurementStatusIcon,
} from "../../../../../entity";
import { HierarchyEntityLink } from "../../components";

export function NavigationApartmentListItem({ entity, jobData }) {
  const [entityStatus, setEntityStatus] = useState(undefined);

  useEffect(() => {
    setEntityStatus(getEntityStatus({ entity: entity, job: jobData }, jobData));
  }, [entity, jobData]);

  return (
    <>
      {entityStatus === undefined ? null : (
        <ListItemButton key={entity.id}>
          <ListItemIcon>
            <EntityDoneStatusIcon entityStatus={entityStatus} />
          </ListItemIcon>

          <ListItemText primary={<HierarchyEntityLink entity={entity} />} />

          <Stack
            sx={{
              flexDirection: "row",
              gap: 1,
            }}
          >
            <EntityMeasurementStatusIcon entityStatus={entityStatus} />

            <EntityCommentStatusIcon entityStatus={entityStatus} />

            <EntityFlagStatusIcon entityStatus={entityStatus} />
          </Stack>
        </ListItemButton>
      )}
    </>
  );
}
