import BaseService from "./BaseService";

class SelfCheckJobInstallationProfileBlacklistCommentService extends BaseService {
  preSendDataTransformer = (data) => {
    if (data?.project_row_ids) {
      data['project_row_ids'] = Object.values(data.project_row_ids)
    }
    return data
  }
}

export default new SelfCheckJobInstallationProfileBlacklistCommentService('/api/',
  [
    'installation-report-profiles',
    'blacklist/comments'
  ]
)