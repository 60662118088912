import { Chip, Tooltip } from "@mui/material";

export function ChipTaskSpecTargetType({ taskSpec }) {
  return (
    <Tooltip
      title={window.gettext("Is applicable for entities of of this type")}
    >
      <Chip label={taskSpec.target_type_label} />
    </Tooltip>
  );
}
