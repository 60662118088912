import BaseService from "./BaseService";

class ChoicesService extends BaseService {
  systemChoices = () => {
    return this.list(
      ...[
        'systems',
        'choices',
      ]
    )
  }

  projectChoices = () => {
    return this.list(
      ...[
        'projects',
        'choices',
      ]
    )
  }

  projectRowChoices = () => {
    return this.list(
      ...[
        'project-row',
        'choices',
      ]
    )
  }

  projectAddressChoices = () => {
    return this.list(
      ...[
        'project-address',
        'choices',
      ]
    )
  }

  selfCheckTaskSpecChoices = () => {
    return this.list(
      ...[
        'self-check',
        'task-spec',
        'choices',
      ]
    )
  }

  selfCheckTaskItemSpecChoices = () => {
    return this.list(
      ...[
        'self-check',
        'task-item-spec',
        'choices',
      ]
    )
  }

  issueChoices = () => {
    return this.list(
      ...[
        'issues',
        'choices',
      ]
    )
  }
}

export default new ChoicesService('/api/',
  []
)