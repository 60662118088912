import Box from "@mui/material/Box";
import React from "react";

export function TabPanel(props) {
  const {children, value, index, name, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      data-test-id={'project-details-tab-' + String(name)}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}