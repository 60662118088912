import axios from "axios";

export function copyObject(obj, whitelist = undefined) {
  /**
   * Creates and returns a shallow copy of an object.
   * @param {object} obj - The object to be copied.
   * @param {array} [whitelist] - An optional array of keys.
   * @returns {object} - The shallow copy of the object.
   */
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (whitelist === undefined || whitelist.includes(key)) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}

export function trimStringValues(obj, whitelist = undefined) {
  /**
   * Trims leading and trailing spaces from string values in an object.
   * @param {object} obj - The object to be processed.
   * @param {array} [whitelist] - An optional array of keys.
   */
  Object.keys(obj).forEach((key) => {
    if (
      typeof (obj[key]) === "string" &&
      (whitelist === undefined || whitelist.includes(key))
    ) {
      obj[key] = obj[key].trim();
    }
  });
}

export function removeKeys(obj, keysToRemove) {
  /**
   * Removes specified keys from an object.
   * @param {object} obj - The object to be processed.
   * @param {array} keysToRemove - An array of keys to be removed.
   */
  keysToRemove.forEach((key) => {
    delete (obj[key]);
  });
}

export function removeKeysWithValue(obj, valuesToRemove, whitelist = undefined) {
  /**
   * Removes keys with specific values from an object.
   * @param {object} obj - The object to be processed.
   * @param {*} valuesToRemove - The value(s) to be removed. Can be a single value or an array of values.
   * @param {array} [whitelist] - An optional array of keys.
   */
  Object.keys(obj).forEach((key) => {
    const shouldRemove = Array.isArray(valuesToRemove)
      ? valuesToRemove.includes(obj[key])
      : obj[key] === valuesToRemove;

    if (shouldRemove && (whitelist === undefined || whitelist.includes(key))) {
      delete (obj[key]);
    }
  });
}

export function removeKeysWithUnchangedValue(obj, comparisonObj, whitelist = undefined) {
  /**
   * Removes keys with unchanged values from an object.
   * @param {object} obj - The object to be processed.
   * @param {object} comparisonObj - The object used for value comparison.
   * @param {array} [whitelist] - An optional array of keys to exclude from removal.
   */
  Object.keys(obj).forEach((key) => {
    if (
      (obj[key] === comparisonObj[key] || (obj[key] === null && !comparisonObj[key])) &&
      (whitelist === undefined || whitelist.includes(key))
    ) {
      delete (obj[key]);
    }
  });
}

export function replaceValues(obj, valueToReplace, replaceWith, whitelist = undefined) {
  /**
   * Replaces specific values with a new value in an object.
   * @param {object} obj - The object to be processed.
   * @param {*} valueToReplace - The value to be replaced.
   * @param {*} replaceWith - The replacement value.
   * @param {array} [whitelist] - An optional array of keys.
   */
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] === valueToReplace &&
      (whitelist === undefined || whitelist.includes(key))
    ) {
      obj[key] = replaceWith;
    }
  });
}

class BaseService {
  constructor(base_api_url, url_params = {}) {
    this.base_api_url = base_api_url
    this.url_params = url_params
  }

  object_to_query_string = (obj) => {
    console.log('obj', obj)

    return Object.entries(
      obj
    ).filter(
      ([key, value]) => {
        return value !== null
      }
    ).map(
      ([key, value]) => {
        // If this is an array, then we shall return multiple entries in the array
        // so that we can flatten the array and get multiple entries in the query string
        // for each of the values in the array.
        if (Array.isArray(value)) {
          return value.map(
            (array_value) => {
              return [key, array_value]
            }
          )
        }

        return [[key, value]]
      }
    ).flat().map(
      ([key, value]) => {
        return String(key) + '=' + String(value)
      }
    ).join('&')

    return Object.entries(
      obj
    ).map(
      ([key, value]) => {
        return String(key) + '=' + String(value)
      }
    ).join('&')
  }

  // Getting url to resource
  // uses any input parameters given and adds them to the url
  url = (...args) => {
    let url = this.base_api_url

    let used_parameters_count = 0;

    // Go through each of the parameters configured.
    for (const [index, parameter_name] of this.url_params.entries()) {
      const value = args[index]

      console.log('value', value, args[index])

      let parameterName = String(parameter_name)

      // If this does Not include a ?x=y port
      if (!String(parameterName).includes('?') && !String(parameterName).includes('=')) {
        parameterName = String(parameterName) + '/'
      }

      // Add the parameter name to the url
      url += parameterName

      // If value of this part is not set, return early
      if (value === undefined || value === null) {
        return url
        // todo: maybe break?
      }

      if (typeof (value) === 'object') {
        return url + '?' + this.object_to_query_string(value)
      }

      // add parameter value to url if it is not null
      url += value

      if (typeof (value) !== 'string' || !value.includes('=')) {
        url += '/'
      }

      used_parameters_count += 1;
    }

    // Add the additional parts of the args to the url
    // Start at how many parameters we used.
    for (let i = used_parameters_count; i < args.length; i += 1) {
      // Add the arg part
      let extra = args[i]

      if (extra === undefined) {
        continue
      }

      // If this is an object, treat it as a query string object
      if (typeof (extra) === 'object') {
        extra = this.object_to_query_string(extra)
      }

      if (extra === '') {
        continue
      }

      // If it looks like a query string with params, add the question mark to indicate it is a query string
      if (typeof (extra) === 'string' && extra.includes('=') && !extra.startsWith('?') && !url.includes('?')) {
        extra = '?' + extra
      }

      url = url + String(extra)

      // Do not add slash if this extra part has a = in it (if it is a query string)
      if (typeof (extra) === 'string' && extra.includes('=')) {
        continue
      }

      url += '/'
    }

    return url
  }

  fetch = (...args) => {
    return new Promise((resolve, reject) => {
      axios.get(
        this.url(...args)
      ).then(
        (response) => {
          let data = response.data

          if (this.hasOwnProperty('postFetchDataTransformer')) {
            data = this.postFetchDataTransformer(data)
          }

          resolve(data)
        },
        (reason) => {
          reject(reason)
        }
      )
    })
  }

  list = (...args) => {
    return new Promise((resolve, reject) => {
      axios.get(
        this.url(...args)
      ).then(
        (response) => {
          resolve(response.data)
        },
        (reason) => {
          reject(reason)
        }
      )
    })
  }
  post = (data, ...args) => {
    return new Promise((resolve, reject) => {
      axios.post(
        this.url(...args),
        data
      ).then(
        (response) => {
          // redirect users to the project page
          resolve(response.data)
        },
        (rejection) => {
          reject(rejection)
        }
      );
    })
  }

  create = (data, ...args) => {
    return this.post(data, ...args)
  }

  update = (data, ...args) => {
    return new Promise((resolve, reject) => {
      axios.patch(
        this.url(...args),
        data,
      ).then((response) => {
        // redirect users to the project page
        resolve(response.data)
      }, (rejection) => {
        reject(rejection)
      });
    })
  }

  put = (data, ...args) => {
    return new Promise((resolve, reject) => {
      axios.put(
        this.url(...args),
        data,
      ).then((response) => {
        // redirect users to the project page
        resolve(response.data)
      }, (rejection) => {
        reject(rejection)
      });
    })
  }


  destroy = (...args) => {
    return new Promise((resolve, reject) => {
      axios.delete(
        this.url(...args),
      ).then((response) => {
        // redirect users to the project page
        resolve(response.data)
      }, (rejection) => {
        reject(rejection)
      });
    })
  }

  history_revert = (instance_id, version_id, ...args) => {
    args = args.concat(
      [
        instance_id,
        'history',
        'revert',
        version_id
      ]
    )

    return this.post(
      {},
      ...args,
    )
  }
}

export default BaseService