import { Stack } from "@mui/material";

import { HierarchyTaskInterfaceTaskListItem } from "./HierarchyTaskInterfaceTaskListItem";

export function HierarchyTaskInterfaceTaskList({ entity }) {
  return (
    <Stack>
      {/** Render all tasks. */}
      {entity.task_specs.map((entity_task_spec, index) => (
        <HierarchyTaskInterfaceTaskListItem
          key={index}
          entity={entity}
          entityTaskSpec={entity_task_spec}
        />
      ))}
    </Stack>
  );
}
