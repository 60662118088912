import BaseFieldValidator from "../BaseFieldValidator";
import {NODE_TYPE_PIPE, NODE_TYPE_RADIATOR} from "../../../data/DataGetter";

export default class RadiatorTypeValidator extends BaseFieldValidator {
  fieldName = 'radiator'
  nodeTypes = [NODE_TYPE_RADIATOR]

  getFieldErrors = (row) => {
    // If item is null or undefined
    if (!row.node.item){
      return [
        this.error(
          row,
          window.gettext('Could not find radiator on this node')
        )
      ]
    }

    // If item has flow specified, we shall not care about radiator type
    if (row.node.item.flow !== null) {
      return undefined
    }


    if (row.node.item.type === null || row.node.item.type === undefined) {
      return [
        this.error(
          row,
          window.gettext('Radiator type on radiator must be set')
        )
      ]
    }

    return undefined
  }
}