import React, {useEffect, useState} from "react";
import {useIssue} from "./IssueContext";
import {Button} from "@mui/material";
import {useIssueWatchers} from "./IssueWatchersContext";
import {useSnackbar} from "notistack";
import IssueWatchersService from "../services/IssueWatchersService";
import LoadingIndicator from "../layout/LoadingIndicator";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";


export default function IssueWatcherAddMeButton({}) {
  const {issue_id} = useIssue()
  const [isIssueWatcher, setIsIssueWatcher] = useState(undefined)
  // description

  const {watchers, refreshIssueWatchers} = useIssueWatchers()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    IssueWatchersService.get_me(issue_id).then(
      (response) => {
        setIsIssueWatcher(true)
      },
      (error) => {
        setIsIssueWatcher(false)
      })
  }, [watchers]);
  return (
    <>
      {(isIssueWatcher === undefined) ? (
        <>
          <LoadingIndicator/>
        </>
      ) : (
        <>
          {(isIssueWatcher === false) ? (
            <Button
              variant="contained"
              color={'primary'}
              startIcon={<AddIcon/>}
              onClick={() => {
                IssueWatchersService.add_me(
                  issue_id,
                ).then(() => {
                    refreshIssueWatchers()
                    enqueueSnackbar(window.gettext("You are added as watcher"), {variant: "success"})
                  },
                  (error) => {
                    enqueueSnackbar(window.gettext("Error adding yourself as watcher"), {variant: "error"})
                  })
              }}
            >
              {window.gettext("Add yourself watcher")}
            </Button>
          ) : (
            <Button
              variant="outlined"
              color={'error'}
              startIcon={<DeleteIcon/>}
              onClick={() => {
                IssueWatchersService.remove_me(
                  issue_id,
                ).then(() => {
                    refreshIssueWatchers()
                    enqueueSnackbar(window.gettext("You are removed as watcher"), {variant: "success"})
                  },
                  (error) => {
                    enqueueSnackbar(window.gettext("Error removing yourself as watcher"), {variant: "error"})
                  })
              }}
            >
              {window.gettext("Remove yourself as watcher")}
            </Button>
          )}
        </>
      )}
    </>
  )
}