import { createContext } from "react";
import { useProvideHierarchyContext } from "./hooks";

export const HierarchyContext = createContext(null);

export function HierarchyContextProvider({ children, ...props }) {
  const context = useProvideHierarchyContext(props);

  return (
    <HierarchyContext.Provider value={context}>
      {children}
    </HierarchyContext.Provider>
  );
}
