import {
  Alert,
  AlertTitle,
  Divider,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { LayoutCard } from "../../../../../../../layout";
import { getEntityLabel } from "../../../utils";
import { useHierarchyContext } from "../../context";
import { getComponentsHeaders } from "../../helper";
import { HierarchyTaskInterfaceTaskList } from "./task_interface";
import { ENTITY_TYPES_RADIATOR } from "../../../entity";
import { HierarchyRSTaskInterfaceLinkedEntities } from "./radiator_system";

export function HierarchyTaskInterfaceLinkedEntities({ newEntityForm }) {
  const { entityListLinkEntities, entityType, hasLinks, hierarchyViewOptions } =
    useHierarchyContext();

  const [entity, setEntity] = useState();
  const [entityTab, setEntityTab] = useState(0);
  const [entityNote, setEntityNote] = useState(null);

  useEffect(() => {
    setEntity(entityListLinkEntities[entityTab]?.entity);
  }, [entityListLinkEntities, entityTab]);

  function handleEntityTabChange(event, newValue) {
    setEntityTab(newValue);
  }

  useEffect(() => {
    setEntityNote(entity?.data?.item?.note || null);
  }, [entity]);

  return (
    <>
      {hierarchyViewOptions?.show_task_interface_linked_option.value && (
        <LayoutCard
          title={getComponentsHeaders(entityType).task_interface_linked}
          noPadding
        >
          <Stack>
            {hasLinks && (
              <Tabs
                variant="scrollable"
                value={entityTab}
                onChange={handleEntityTabChange}
                sx={{
                  width: "100%",
                }}
              >
                {/* Generate a tab for every entity */}
                {entityListLinkEntities.map((entityData) => (
                  <Tab key={entityData.id} label={getEntityLabel(entityData)} />
                ))}

                {/* Tab for adding new entity */}
                {newEntityForm !== null && <Tab label="+" />}
              </Tabs>
            )}
            <Stack sx={{ m: 1 }}>
              {!!entityNote && (
                <>
                  <Alert severity="info">
                    <AlertTitle>{window.gettext("Note")}</AlertTitle>
                    <p>{entityNote}</p>
                  </Alert>
                </>
              )}
            </Stack>

            {!entity ? (
              // Add entity (+)
              <>{newEntityForm}</>
            ) : (
              // Linked entity
              <LinkedEntity entity={entity} />
            )}
          </Stack>
        </LayoutCard>
      )}
    </>
  );
}

export function LinkedEntitySectionCard({
  children,
  noPadding,
  noTopDivider,
  title,
}) {
  return (
    <Stack
      sx={{
        gap: 1,
      }}
    >
      {!noTopDivider && <Divider />}

      {title && (
        <Typography
          variant={"h5"}
          sx={{
            pt: 1,
            px: 1,
          }}
        >
          {title}
        </Typography>
      )}

      <Stack
        sx={{
          pb: noPadding ? 0 : 1,
          pt: noPadding || title ? 0 : 1,
          px: noPadding ? 0 : 1,
        }}
      >
        {children}
      </Stack>
    </Stack>
  );
}

function LinkedEntity({ entity }) {
  return (
    <>
      {entity.task_specs.length > 0 ? (
        // Has task specifications
        <>
          <LinkedEntitySectionCard
            noTopDivider
            title={getComponentsHeaders(entity.type).task_interface}
          >
            <HierarchyTaskInterfaceTaskList entity={entity} />
          </LinkedEntitySectionCard>

          {ENTITY_TYPES_RADIATOR.includes(entity.type) && (
            <HierarchyRSTaskInterfaceLinkedEntities entity={entity} />
          )}
        </>
      ) : (
        // No task specifications
        <Alert severity="info">
          {window.gettext("This entity has no tasks specified")}
        </Alert>
      )}
    </>
  );
}
