import getUserRepresentation from "./getUserRepresentation";
import {Chip} from "@mui/material";

export default function User({user, showPhoneNumber = false}) {
  return (
    <>
      {user !== undefined && user !== null && (
        <>
          <>
            {
              getUserRepresentation(user)
            }
          </>
          <>
            {(showPhoneNumber && !!(user?.profile?.phone_number)) && (
              <Chip
                label={user?.profile?.phone_number}
                size="small"
                component={'a'}
                href={`tel:${user?.profile?.phone_number}`}
                variant="outlined"
                color="primary"
                sx={
                  {
                    marginLeft: 1
                  }
                }
              />
            )}
          </>
        </>
      )}
    </>
  )
}