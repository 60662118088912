import CreateIssueButton from "../../components/issue_tracker/CreateIssueButton";
import IssueChoicesProvider from "../../components/issue_tracker/IssueChoicesContext";
import IssueFilterProvider from "../../components/issue_tracker/IssueFilterContext";
import IssueList from "../../components/issue_tracker/IssueList";
import IssueListFiltersButton from "../../components/issue_tracker/IssueListFiltersButton";

import { LayoutCard, LayoutView } from "../../layout";

export default function IssuesIndexView() {
  // Get filters from IssueFilterContext

  return (
    <IssueFilterProvider>
      <IssueChoicesProvider>
        <LayoutView
          header={window.gettext("Issues")}
          submenuItems={<CreateIssueButton />}
        >
          <LayoutCard>
            <IssueListFiltersButton />
          </LayoutCard>

          <IssueList />
        </LayoutView>
      </IssueChoicesProvider>
    </IssueFilterProvider>
  );
}
